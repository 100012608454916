/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import styles from './NoResults.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import FileMagnifyingGlassLight from '../Icons/FileMagnifyingGlassLight.js'
function NoResults (props) {
  function getText () {
    let errorMessages = []
    props.data.forEach((msg, index) => {
      errorMessages.push(<div key={'msg-' + index} role='heading' aria-level='1' aria-label={msg}>{msg}</div>)
    })
    return <div>{errorMessages}</div>
  }
  return (
    <div className={styles.noresultcontainer} style={props.containerHeight}>
      <div className={styles.noresult}>
        <div className={styles[props.iconStyles]}>
          {props.iconName === 'fileMagnifyingGlass' ? <FileMagnifyingGlassLight /> : <FontAwesomeIcon icon={props.iconName} />}
        </div>
        <div className={styles[props.msgStyles]}>{getText()}</div>
        <div aria-level='2' aria-label={props.optionalTextmsg} className={styles[props.optionalTextClass]}>{props.optionalText && <div>{props.optionalTextmsg}</div>}</div>
      </div>
    </div>
  )
}
NoResults.propTypes = {
  data: PropTypes.array,
  iconName: PropTypes.array,
  iconStyles: PropTypes.string,
  msgStyles: PropTypes.string,
  optionalTextmsg: PropTypes.string,
  optionalText: PropTypes.bool,
  optionalTextClass: PropTypes.string,
  containerHeight: PropTypes.object
}
export default NoResults
