/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import PropTypes from 'prop-types'
import styles from '../Modal.module.scss'
export default function ModalHeader (props) {
  function stylesToRender (argModalName) {
    switch (argModalName) {
      case 'openScreenModal':
        return styles.openModalheaderContainer
      case 'ViewShareClassModal':
        return styles.viewShareClassModalheaderContainer
      default:
        return styles.headerContainer
    }
  }
  return (
    <div className={stylesToRender(props.modalName)} calledby={props.modalName}>
      { props.children }
    </div>
  )
}

ModalHeader.propTypes = {
  children: PropTypes.node,
  modalName: PropTypes.string
}
