import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './ESGOverallScore.module.scss'
import DataUnavailable from '../../../../../Lib/DataUnavailable/DataUnavailable'
import ChartistChart from '../../../../../Lib/Chartist/ChartistChart'
import { formatNumber } from '../../../../../../utils/formatter'
import { NXI_COLOR_DEFAULT_SET } from "../../../../../../utils/appConstants";
import Loader from '../../../../../Lib/common/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ESGOverallScore ({xid, isNXI=false, analystRatingsData={}}) {
  const [dataPoints, setDataPoints] = useState({})
  const [chartData, setChartData] = useState({labels: [], series: []})
  const [isReady, setIsReady] = useState(false || !xid)

  useEffect(() => {
    analystRatingsData && Object.keys(analystRatingsData).length > 0 && getData()
  }, [analystRatingsData])

  const checkData = (data) => {
    if (data && data.fund && data.fund.rating && data.fund.score && data.category && data.category.score && data.category.score.coverage) {
      return true
    } else {
      return false
    }
  }

  const getData = () => {
      setIsReady(false)
      if (!analystRatingsData?.isError) {
        let data = analystRatingsData?.data;
        if (checkData(data)) {
          let categoryAvgData = {}
          categoryAvgData['Environmental'] = formatNumber(data.category.score.enviornmentalAverage)
          categoryAvgData['Social'] = formatNumber(data.category.score.socialAverage)
          categoryAvgData['Governance'] = formatNumber(data.category.score.governanceAverage)
          let modelData = {}
          modelData['Environmental'] = formatNumber(data.fund.score.enviornmentalScore)
          modelData['Social'] = formatNumber(data.fund.score.socialScore)
          modelData['Governance'] = formatNumber(data.fund.score.governanceScore)
          let tableData = {
            unAllocatedScore: formatNumber(data.fund.score.unallocatedESGRisk),
            globalCategory: data.category.score.coverage.numberOfFunds
          }
          setDataPoints(tableData)
          setChartData({labels: ['Environmental', 'Social', 'Governance'],
            series: [
              [modelData['Environmental'], modelData['Social'], modelData['Governance']],
              [categoryAvgData['Environmental'], categoryAvgData['Social'], categoryAvgData['Governance']]
            ]})
        }
      } else {
        setDataPoints({})
      }
      setIsReady(true)
  }

  const chartOptions = {
    seriesBarDistance: 27,
    axisY: {
      position: 'end',
      scaleMinSpace: 35,
      labelOffset: {
        x: -17,
        y: 2.5
      }
    },
    axisX: {
      showGrid: false
    },
    chartPadding: {
      bottom: 0,
      left: 15,
      top: 15,
      right: 10
    }
  }

  return (
    <Loader ready={isReady} spinnerSize='2x' >
      <div data-testid='ESGOverallScoreContainer' className={isNXI? styles.NXIESGOverallScoreContainer:styles.ESGOverallScoreContainer} >
        <div className={styles.headerLabel}>
          ESG Overall Score
        </div>
        {isNXI? (<div className={styles.bodyPart}>
          <div className={styles.upperSection}>
            {Object.keys(dataPoints).length === 0 ? (
              <DataUnavailable />
            ) : (
              <div className={styles.chartContainer}>
                <ChartistChart
                  data={chartData}
                  options={chartOptions}
                  type="Bar"
                />
              </div>
            )}
          </div>
          <div className={styles.tableSection}>
            <div className={`${styles.tableRow}`}>
              <div className={styles.rowLeftItem}>Unallocated Score</div>
              <div className={styles.rowrigtItem}>
                {dataPoints["unAllocatedScore"]
                  ? dataPoints["unAllocatedScore"]
                  : "--"}
              </div>
            </div>
            <div className={`${styles.tableRow} ${styles.lineBorder}`}>
              <div className={`${styles.rowLeftItem}`}>
                No. of Funds in global category
              </div>
              <div className={styles.rowrigtItem}>
                {dataPoints["globalCategory"]
                  ? formatNumber(dataPoints["globalCategory"], { precision: 0 })
                  : "--"}
              </div>
            </div>
          </div>
          <div className={styles.lowerSection}>
            <span>Key</span>
            <div className={styles.categoryLabels}>
              <div className={styles.chartLabels}>
                <div
                  className={styles.circle}
                  style={{ background: NXI_COLOR_DEFAULT_SET[0].value }}
                />
                <span>This Model</span>
              </div>
              <div className={styles.chartLabels}>
                <div
                  className={styles.circle}
                  style={{ background: NXI_COLOR_DEFAULT_SET[1].value }}
                />
                <span>Category Average</span>
              </div>
            </div>
          </div>
        </div>)
        :(<div className={styles.bodyPart}>
          <div className={styles.upperSection}>
            <div className={styles.categoryLabels}>
              <div className={styles.chartLabels}>
                <FontAwesomeIcon icon={['fas', 'circle']} color={'#004480'} className={`${styles.icon}`} />
                This Model
              </div>
              <div className={styles.chartLabels}>
                <FontAwesomeIcon icon={['fas', 'circle']} color={'#0095FF'} className={`${styles.icon}`} />
                Category Average
              </div>
            </div>
            <div className={styles.headerLabel2}>
              <span className={styles.star}>*</span>
              <span className={styles.label2}>Low scores = lower risk</span>
            </div>
            {Object.keys(dataPoints).length === 0 ? <DataUnavailable />
              : <div className={styles.chartContainer}>
                <ChartistChart data={chartData} options={chartOptions} type='Bar' />
              </div>
            }
          </div>
          <div className={styles.tableSection}>
            <div className={`${styles.tableRow} ${styles.lineBorder}`}>
              <div className={styles.rowLeftItem}>
                Unallocated Score
              </div>
              <div className={styles.rowrigtItem}>
                {dataPoints['unAllocatedScore'] ? dataPoints['unAllocatedScore'] : '--'}
              </div>
            </div>
            <div className={styles.tableRow}>
              <div className={`${styles.rowLeftItem}`}>
                No. of Funds in global category
              </div>
              <div className={styles.rowrigtItem}>
                {dataPoints['globalCategory'] ? formatNumber(dataPoints['globalCategory'], {precision: 0}) : '--'}
              </div>
            </div>
          </div>
        </div>)
}
      </div>
    </Loader>
  )
}

ESGOverallScore.propTypes = {
  xid: PropTypes.number.isRequired,
  isNXI: PropTypes.bool,
  analystRatingsData: PropTypes.object
}
