import React from 'react'
import styles from './CompanyDetailsHeader.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

export default function CompanyDetailsHeader (props) {
  return (
    <div className={styles.companyDescriptionModalHeader}>
      <div className={styles.companyDescriptionHeaderText}>{props.companyName}</div>
      <button type='button' data-value='companyDescriptionPopUpClose' className={styles.companyDescriptionPopUpClose}
        alt='Close' id='closeButton' onClick={props.onClose}>
        <FontAwesomeIcon title='Close' icon={['fal', 'times']} className={styles.companyDescriptionPopUpClose} id='closeButtonFontAwsome' />
      </button>
    </div>
  )
}

CompanyDetailsHeader.propTypes = {
  companyName: PropTypes.string,
  onClose: PropTypes.func
}
