/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-onchange */
import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './BasicDropDownStyles.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getScrollPositionsFromTop, deviceType, getDeviceType, handleWindowResize} from '../../../../../../utils/utilities'

const BasicDropDownFixed = forwardRef((props, ref) => {
  const [containerStyle, setContainerStyle] = useState({})
  const [device, setDevice] = useState(getDeviceType())
  const [guid] = useState(CreateUUID())
  const dropdownRef = useRef(null)
  const clickedFromDropdown = useRef(null)
  const clickedFromOutside = useRef(null)
  useOutsideAlerter(dropdownRef)
  function useOutsideAlerter (ref) {
    useEffect(() => {
      /**
         * Alert if clicked on outside of element
         */
      function handleOutsideEvents (event) {
        if (isListOpenRef.current && ref.current && !ref.current.contains(event.target)) {
          clickedFromDropdown.current = (event.target.firstChild && event.target.firstChild.className) || event.target.firstChild
          isListOpenRef.current = false
          setListOpen(false)
        } else {
          clickedFromDropdown.current = false
        }
      }
      function handleScrollEvents (event) {
        isListOpenRef.current = false
        setListOpen(false)
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleOutsideEvents)
      document.addEventListener('scroll', handleScrollEvents, true) // Added scroll event to close dropdown on scroll
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleOutsideEvents)
        document.removeEventListener('scroll', handleScrollEvents, true)
        // document.removeEventListener('scroll', handleScrollEvents)
      }
    }, [ref])
  }
  useImperativeHandle(
    ref,
    () => ({
      resetDropdown () {
        setSelection('Open & Save')
      }
    })
  )
  useEffect(() => {
    handleWindowResize((deviceType) => {
      setDevice(deviceType)
    })
  })

  useEffect(() => {
    setSelectedValue(props.selectedValue)
  }, [props.selectedValue])

  function CreateUUID () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0
      let v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  }

  bindEvents()
  let {data, id} = props
  let formattedData
  let selectedValueText
  let containsImages
  let caretColor = props.caretColor ? props.caretColor : 'black'
  if (data && data.length > 0 && typeof data[0].valueOf() === 'string') {
    containsImages = false
    selectedValueText = props.selectedValue
  } else {
    containsImages = true
    formattedData = data.map(item => item.Icon)
    const fetchedValue = props.selectedValue && formattedData && formattedData.includes(props.selectedValue) ? props.selectedValue : formattedData[0]
    selectedValueText = <FontAwesomeIcon icon={['fas', fetchedValue]} />
  }
  let liIndex = -1
  const span = props.label && props.label.length > 0 ? (<span value={props.label}>{props.label} </span>) : null
  const [isListOpen, setListOpen] = useState(false)
  const isListOpenRef = useRef(false)
  const [selectedValue, setSelectedValue] = useState(selectedValueText)
  let valueViaUpDownKeys = selectedValue
  let isMobileView = props.isMobileView
  const isMobile = device === deviceType.Mobile
  useEffect(() => {
    const onScrollLi = () => scrollLiStyle(guid)
    window.removeEventListener('scroll', onScrollLi)
    window.addEventListener('scroll', onScrollLi)
    return () => window.removeEventListener('scroll', onScrollLi)
  }, [])
  useEffect(() => {
    const onResizeLi = () => scrollLiStyle(guid)
    // clean up code
    window.removeEventListener('resize', onResizeLi)
    window.addEventListener('resize', onResizeLi)
    return () => window.removeEventListener('resize', onResizeLi)
  }, [])
  return (
    <React.Fragment>
      {span}
      <ul className={`${styles['basicDropDownContainer']} ${isMobileView ? styles['noBorder'] : ''}
            ${isListOpen ? styles['open'] : ''} ${props.onlyBottomBorder ? styles['dropdownWithBottomBorder'] : ''} ${props.isBlueBackground ? styles['dropdownWithBlueBackground'] : ''} DropDownUL`} style={props.styles} id={guid}>

        <li tabIndex='0' onClick={(e) => openCloseMenu(e)} onKeyDown={handleKeys} >
          {!isMobileView && <React.Fragment>
            <span className={`${styles['name']}`} title={(containsImages ? '' : selectedValue)} value={selectedValue}>
              {selectedValue}
            </span>
            <span className={`${styles['icon']} ${styles['float-right']}`} >
              {
                isListOpen ? <FontAwesomeIcon icon={['fas', 'caret-up']} color={caretColor} className={styles.caretIcon} />
                  : <FontAwesomeIcon icon={['fas', 'caret-down']} color={caretColor} className={styles.caretIcon} />
              }
            </span></React.Fragment>}
          {isMobileView && <React.Fragment>
            <span className={`${styles['icon']} ${styles['float-right']}`} >
              {<FontAwesomeIcon icon={['fal', 'ellipsis-v']} className={styles.clickableicon} />
              }
            </span></React.Fragment>}
        </li>
        <li ref={dropdownRef} data-x={isListOpen} id={'li-' + guid} className={`${isListOpen ? '' : styles['hide']} ${styles['basicDropDownValueContainer']} ${styles['positionAbs']} dropDownList`} style={{...containerStyle, ...props.liStyle}}>
          <ul style={{height: 'auto', width: 'auto'}}>
            {
              BindDropDownValues(data, containsImages, id)

            }
          </ul>
        </li>
      </ul>
    </React.Fragment>
  )
  function bindEvents () {
    // document.addEventListener('click', handleClickOutside)
  }

  function openCloseMenu (e) {
    if (e) {
      // Hack - if dropdown opened, and clicking on dropdown again, it keeps open, since event outside click and this event is fired one after another
      clickedFromOutside.current = (e.target && e.target.firstChild && e.target.firstChild.className) || e.target.firstChild
      if (clickedFromDropdown.current === clickedFromOutside.current) {
        return
      }
      e.stopPropagation()
    }

    !isListOpen && getDropDownPosition()
    props.dropDownListOpen && props.dropDownListOpen(!isListOpen)
    valueViaUpDownKeys = selectedValue
    scrollLiStyle(guid)
    isListOpenRef.current = !isListOpen
    setListOpen(!isListOpen)
  }

  function scrollLiStyle (guid) {
    let elem = document.getElementById(guid)
    let liElement = document.getElementById('li-' + guid)
    if (elem) {
      let topPosition = elem.getBoundingClientRect().top
      let leftPosition = elem.getBoundingClientRect().left
      if (liElement) {
        if (isMobile) {
          topPosition = topPosition + 14
        } else {
          topPosition = topPosition + 38
        }
        liElement.style.transform = `translate3d(${leftPosition}px, ${topPosition}px, 0px)`
      }
    }
  }

  function getDropDownPosition () {
    let elem = document.getElementById(guid)
    if (elem) {
      let ulDimensions = elem.getBoundingClientRect()
      let heightdropDownContainer = props.data.length * elem.firstElementChild.offsetHeight
      heightdropDownContainer = heightdropDownContainer > 450 ? 450 : heightdropDownContainer

      if ((((document.documentElement.clientHeight - ulDimensions.bottom - heightdropDownContainer) < heightdropDownContainer) || (document.documentElement.clientHeight < ulDimensions.bottom)) && (ulDimensions.top + elem.offsetHeight + getScrollPositionsFromTop(elem)) >= (heightdropDownContainer + 5)) {
        // setContainerStyle({
        //   'top': (-1 * (heightdropDownContainer + elem.offsetHeight))
        // })
      } else {
        setContainerStyle({})
      }
    }
  }

  function setSelection (value) {
    if (containsImages) {
      formattedData = data.filter(item => {
        if (item.Text === value) {
          return item.Icon
        }
      })
      const selectedText = formattedData && formattedData.length > 0 && formattedData[0] && formattedData[0].Icon
      selectedValueText = <FontAwesomeIcon icon={['fas', selectedText]} />
      setSelectedValue(selectedValueText)
      valueViaUpDownKeys = selectedValueText
    } else {
      setSelectedValue(value)
      valueViaUpDownKeys = value
    }
  }

  function handleKeys (e) {
    // To fix - When switching between dropdowns with Tab and Shift+Tab keys last dropdown is kept opened
    if (e.key === 'Shift' || e.key === 'Tab') {
      setListOpen(false)
      return
    }
    let sibling = e.currentTarget.nextElementSibling
    let renderedLi = sibling.querySelectorAll('li')
    let lenrenderedLi = renderedLi.length - 1
    if (e.key === 'Enter') {
      const value = renderedLi[liIndex] && renderedLi[liIndex].getAttribute('data-value')
      if (value && value !== selectedValue) {
        setSelection(value)
        props.changeHandler && props.changeHandler(renderedLi[liIndex])
      }
      openCloseMenu(e)
    } else {
      let keyCode = e.which
      let isFound = 0
      renderedLi && renderedLi.forEach((item, idx) => {
        item.classList.remove(styles['selected'])
        if (valueViaUpDownKeys === item.getAttribute('data-value') && !isFound) {
          liIndex = idx
          isFound = 1
        }
      })
      if (keyCode === 40) {
        liIndex++
        if (liIndex > lenrenderedLi)liIndex = 0
      } else if (keyCode === 38) {
        liIndex--
        if (liIndex < 0) liIndex = lenrenderedLi
      }
      let ci = renderedLi[liIndex]
      ci && ci.classList.add(styles['selected'])
      valueViaUpDownKeys = (ci && ci.getAttribute('data-value'))
    }
  }

  function handleChangeEvent (e) {
    const value = e.currentTarget.getAttribute('data-value')
    setSelection(value)
    if (props.changeHandler) {
      props.changeHandler(e.currentTarget)
    }
    if (e.key === 'Enter') {
      handleKeys()
    } else { openCloseMenu() }
  }

  function BindDropDownValues (dropdownData, containsImages, id) {
    const dropdownBody = (dropdownData && dropdownData.length > 0 ? dropdownData.map(
      (item, index) => (
        <li tabIndex='0'
          key={index}
          onClick={handleChangeEvent}
          onKeyDown={handleChangeEvent}
          className={`
              ${isListOpen ? styles['lineHeight'] : styles['hide']}
              ${selectedValue === (containsImages ? item.Text : item) ? styles['selected'] : ''}`}
          data-value={(containsImages ? item.Text : item)}
          id={id || ''}>
          <span className={`${styles['name']}`} title={(containsImages ? item.Text : item)}>
            {/* only Text array */}
            {!containsImages && item }
            {/* image only */}
            {containsImages && item.Onlyvisual && <FontAwesomeIcon icon={['fas', item.Icon]} />}
            {/* image and text */}
            {containsImages && !item.Onlyvisual && item.Text}
            {containsImages && !item.Onlyvisual && <FontAwesomeIcon icon={['fas', item.Icon]} />}
          </span>
        </li>
      )
    ) : null)
    return dropdownBody
  }

  // function handleClickOutside (e) {
  //   if (isListOpen && !e.target.closest('ul.DropDownUL')) {
  //     openCloseMenu(e)
  //   }
  // }
})

BasicDropDownFixed.propTypes = {
  changeHandler: PropTypes.func,
  data: PropTypes.array,
  selectedValue: PropTypes.string,
  label: PropTypes.string,
  styles: PropTypes.object,
  liStyle: PropTypes.object,
  onlyBottomBorder: PropTypes.bool,
  id: PropTypes.string,
  isBlueBackground: PropTypes.bool,
  caretColor: PropTypes.string,
  isMobileView: PropTypes.bool,
  dropDownListOpen: PropTypes.func
}

export default BasicDropDownFixed
