import React from 'react'
import PropTypes from 'prop-types'
import SparkChart from '../../Shared/SparkChart/SparkChart'

import styles from './PerformanceChart.module.scss'

const PerformanceChart = (props) => {
  let performanceChartInput = {
    'data': {
      'days': props.days,
      'interval': 5,
      'period': 'minute'
    },
    'instruments': [
      {
        'venueXid': props.symbol
      }
    ],
    'height': 72,
    'width': 218,
    'styles': {
      'colors': [
        '',
        '#5F6064',
        '#C0202D',
        '#1D8343',
        '#FF1C1C',
        '#008338',
        '#FFFFFF',
        '#FFFFFF'
      ],
      'background': {
        'colorIndex': 0
      },
      'markers': {
        'price': {
          'primary': {
            'colorIndex': 1,
            'colorIndexNeg': 2,
            'colorIndexPos': 3,
            'colorMode': 'single',
            'width': 1
          },
          'fill': {
            'colorGradientTopPos': 5,
            'colorGradientBottomPos': 7,
            'colorGradientTopNeg': 4,
            'colorGradientBottomNeg': 6,
            'fillType': 'baseLine'
          }
        }
      }
    }
  }
  return (
    <div className={styles.performanceChartContainer} >
      <SparkChart sparkChartInput={performanceChartInput} symbol={props.symbol} spinnerStyle={props.spinnerStyle} />
    </div>
  )
}
export default PerformanceChart
PerformanceChart.propTypes = {
  symbol: PropTypes.number,
  days: PropTypes.number,
  spinnerStyle: PropTypes.object
}
