import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import styles from './FilterBar.module.scss'
import LeftFilterSection from './LeftFilterSection/LeftFilterSection'
import RightFilterSection from './RightFilterSection/RightFilterSection'
import { FilterConstants } from './FilerConstants'
import {EVENT_NAMES} from '../../../utils/f2Constants'
import {emitF2Event} from '../../../utils/f2Methods'
import {makeEntitlementsCall} from '../../../services/EntitlementsAPICall.js'
export default function Filters (props) {
  const {data, defaultFilters, filterOptions, entitlementProviders} = props
  const [selectedFilters, setSelectedFilters] = useState(SetInitialState(data))
  const [isClearCanlendar, setIsClearCanlendar] = useState(false)
  useEffect(() => {
    let filterData = Object.assign({}, selectedFilters)
    setDefaultValuesIfEmpty(filterData)
    emitF2Event(EVENT_NAMES.NEWS_FILTER_CHANGED_EVENT, filterData)
  }, [selectedFilters])

  useEffect(() => {
    makeEntitlementsCall(entitlementAPICallBack)
  }, [])

  function entitlementAPICallBack (response) {
    if (response.isError) {
    } else if (response && response.data) {
      if (response.data.entList && response.data.entList.includes('128')) {
        data.Provider = entitlementProviders
        setSelectedFilters(SetInitialState(data))
      }
    }
  }

  function onClearClick (e) {
    const filterType = e.currentTarget.getAttribute('tag')
    const indexToBeremoved = selectedFilters[filterType].findIndex(x => x.text === e.currentTarget.getAttribute('value'))
    if (indexToBeremoved > -1) {
      const latestSelectedselected = [...selectedFilters[filterType]]
      latestSelectedselected.splice(indexToBeremoved, 1)
      setSelectedFilters({...selectedFilters, [filterType]: latestSelectedselected})
    }
  }
  function clearState (e) {
    e && e.stopPropagation()
    setIsClearCanlendar(true)
    setSelectedFilters(SetInitialState(data))
  }
  function clearStateOnEnter (e) {
    e && e.key === 'Enter' && clearState(e)
  }
  function handleFilter (dataFromapply, type) {
    if (type === FilterConstants.RESET) {
      setSelectedFilters(SetInitialState(data))
    } else {
      setSelectedFilters({...dataFromapply})
    }
  }
  function SetInitialState (data) {
    if (data === undefined || data == null) {
      return {}
    }
    const keys = Object.keys(data)
    let findFilters = {}
    if (data) {
      keys.forEach((keyName) => {
        if (data[keyName].type && data[keyName].type === FilterConstants.CALENDAR) {
          const customDate = defaultFilters['Custom Date']
          const {startDate, endDate} = customDate
          findFilters[keyName] = {
            'startDate': data[keyName].startDate || startDate,
            'endDate': data[keyName].endDate || endDate,
            'isExcludedFromBar': true
          }
        } else {
          data[keyName] && data[keyName].forEach((item) => {
            if (item['checked']) {
              if (!findFilters.hasOwnProperty(keyName)) {
                findFilters[keyName] = []
              }
              findFilters[keyName].push({
                text: item.text,
                value: item.value
              })
            }
          })
        }
      })
    }
    findFilters['FreeText'] = [{'text': '', 'value': ''}]
    return findFilters
  }

  function setDefaultValuesIfEmpty (filterData) {
    if (!filterData['Provider']) {
      filterData['Provider'] = [{
        text: defaultFilters['Provider'].text,
        value: defaultFilters['Provider'].text
      }]
    }
  }

  const idFilterBar = props.id || 'filterBarId'
  return (<div id={idFilterBar} data-testid={idFilterBar} className={` ${styles.filterContainer} newsFilterContaner`} style={props.style}>

    <LeftFilterSection filterData={data} handleClear={isClearCanlendar} selectedValues={selectedFilters} handleFilter={handleFilter} filterOptions={filterOptions} />
    <div className={styles.divider} />
    <div className={styles.filterLabel}>Filters:</div>
    <RightFilterSection idFilterBar={idFilterBar} selectedValues={selectedFilters} onClearClick={onClearClick} />
    <span tabIndex='0' className={styles.clearButton} data-testid={'clearButtonFilter'} role='button' aria-label='Clear Button Filter' onClick={clearState} onKeyDown={clearStateOnEnter}>Clear</span>
  </div>)
}

Filters.propTypes = {
  data: PropTypes.object,
  defaultFilters: PropTypes.object,
  id: PropTypes.string,
  style: PropTypes.object,
  filterOptions: PropTypes.object,
  entitlementProviders: PropTypes.object
}
