import React from 'react'
import PropTypes from 'prop-types'
import styles from './Toggle.module.scss'
const Toggle = props => {
  const [checked, setChecked] = React.useState(props.isDefaultChecked || false)
  const onKeyPress = (e) => {
    if (e.charCode === 13) {
      setChecked(!checked)
      if (props.onKeyPress) {
        props.onKeyPress(e)
      }
    }
  }
  const onChange = (e) => {
    setChecked(!checked)
  }
  return (
    <div className={styles.toggle}>
      <input
        id={props.id || 'lib_Toggle_Id'}
        type='checkbox'
        onClick={props.onClick}
        name={props.id || 'lib_Toggle_name'}
        checked={checked}
        onKeyPress={onKeyPress}
        tabIndex='0'
        onChange={onChange}
      />
      <label htmlFor={props.id || 'lib_Toggle_Id'} >
        {!props.isLabelLast && props.label}
        <span
          className={
            props.isLabelLast ? styles.leftControl : styles.rightControl
          }
        />
        {props.isLabelLast && props.label}
      </label>
    </div>
  )
}
export default Toggle

Toggle.propTypes = {
  label: PropTypes.string,
  isLabelLast: PropTypes.string,
  isDefaultChecked: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  id: PropTypes.string
}
