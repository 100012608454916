import { getSelectedSecurity } from '../../utils/utilities'

export const setAccessToken = () => {
  let token = sessionStorage.getItem('modAccessToken')

  setTimeout(() => {
    sessionStorage.setItem('modAccessToken', token)
    window.F2.Events.emit(
      'NETX_WEALTH_ACCESS_TOKEN_UPDATED'
    )
  }, 500)

  sessionStorage.removeItem('modAccessToken')
}

export const changeTabHandler = (tab) => {
  switch (tab) {
    case 'Fundamentals':
      location.href = location.origin + '/quotes-research/fundamentals'
      break
    case 'Earnings & Estimates':
      location.href = location.origin + '/quotes-research/earnings-estimates'
      break
    case 'Snapshot':
      location.href = location.origin + '/quotes-research/snapshot'
      break
    case 'Charting':
      location.href = location.origin + '/quotes-research/charting'
      break
    case 'Options':
      location.href = location.origin + '/quotes-research/options'
      break
    case 'News & Research':
      location.href = location.origin + '/quotes-research/stock-news-research'
      break
    case 'Valuations':
      location.href = location.origin + '/quotes-research/valuations'
      break
    case 'Dividends':
      location.href = location.origin + '/quotes-research/dividends'
      break
    case 'Ownership':
      location.href = location.origin + '/quotes-research/ownership'
      break
    default:
      location.href = location.origin + '/quotes-research'
      break
  }
}

export const getSymbolFromPath = (symbol) => {
  let selectedSecurity = getSelectedSecurity()

  return (symbol && decodeURIComponent(symbol)) || selectedSecurity.eqSymbol || ''
}
