import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ModalPopUp from '../../Lib/ModalPopUp/ModalPopUp'
import PrimaryButton from '../../Lib/Button/PrimaryButton'
import SecondryButton from '../../Lib/Button/SecondryButton'
import WatchlistPopupHeader from '../WatchlistPopupHeader/WatchlistPopupHeader'
import AddToExistingWatchlists from '../AddToExistingWatchlists/AddToExistingWatchlists'
import Watchlist from '../Watchlist'
import { WATCHLIST } from '../WatchlistsConstant'
import styles from './AddToWatchlists.module.scss'

function AddToWatchlists ({symbol, watchListData, xid, onClose, isEdit, isOption = false}) {
  const [toggleAddtoExisting, setToggleAddtoExisting] = useState(false)
  const [toggleCreateNew, setToggleCreateNew] = useState(false)
  const createNewWatchlist = (action) => {
    if (action === 'Yes') {
      setToggleAddtoExisting(false)
      setToggleCreateNew(false)
      onClose()
    } else if (action === 'CreateNew') {
      setToggleAddtoExisting(false)
      setToggleCreateNew(true)
    } else {
      setToggleAddtoExisting(false)
    }
  }
  return (
    <div className={styles.addToWatchlistsContainer}>
      <ModalPopUp stylesContent={{'height': 'auto'}} stylesPopUp={{'minHeight': '202px', width: '480px'}} hideClose customHeader customBody addEventListener >
        <WatchlistPopupHeader headerText='Watchlist' onClose={onClose} />
        <React.Fragment>
          <div className={styles.watchlistsPopupContent} data-testid='addToWatchlist_body'>
            {WATCHLIST.WOULD_YOU_LIKE}
          </div>
          <footer className={styles.footerContainer}>
            <PrimaryButton tabIndex='0' buttonText='Add to Existing' data-testid='addToWatchlistButton' clickHandler={() => { setToggleAddtoExisting(true) }} />
            <SecondryButton tabIndex='0' buttonText='Create New' data-testid='createNewWatchlistButton' clickHandler={() => { setToggleCreateNew(true) }} />
          </footer>
        </React.Fragment>
      </ModalPopUp>
      {toggleAddtoExisting &&
      <AddToExistingWatchlists isOption={isOption} symbol={symbol} xid={xid} onClose={createNewWatchlist} />
      }
      {toggleCreateNew &&
        <Watchlist watchListHoldingData={isEdit ? watchListData : []} action={'Create'} symbol={symbol} onClose={createNewWatchlist} />
      }
    </div>
  )
}

export default AddToWatchlists

AddToWatchlists.propTypes = {
  symbol: PropTypes.string,
  watchListData: PropTypes.array,
  xid: PropTypes.number,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
  isOption: PropTypes.bool
}
