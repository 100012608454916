import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './ESGOverAllScore.module.scss'
import { useXref } from '../../../../../../hooks/useXref'
import Loader from '../../../../../Lib/common/Loader'
import { COLOR_DEFAULT_SET } from '../../../../../../utils/appConstants'
import ScoreBar from '../ESGScores/ESGScoreBar/ScoreBar'
import { formatNumber } from '../../utils/formatter'
import { getRefinedData, TYPE_OF_SCORE } from './ESGCommonMethods'
import Nodata from '../../../../../Lib/NoData/NoData'

const OVERALL_ESG_CONSTANTS = {
  CURRENT_SCORE: {
    label: 'Current Score'
  },
  HISTORICAL_AVERAGE: {
    label: '12 Mon Historical Average'
  },
  CATEGORY_AVERAGE: {
    label: 'Category Avg.'
  }
}
function ESGOverAllScore ({symbol, analystRatingsData={}}) {
  const [scoreOverAll, setScoreOverAll] = useState(getInitialState(true))
  const xref = useXref(symbol)

  useEffect(() => {
    xref && xref.callData(symbol)
  }, [symbol])

  useEffect(() => {
    xref.isError && setScoreOverAll(getInitialState(false))
  }, [xref.venueXid, xref.isError])

  useEffect(() => {
    analystRatingsData && Object.keys(analystRatingsData).length > 0 && getData()
  }, [analystRatingsData])

  function getInitialState (loadingState) {
    return {
      isLoading: loadingState,
      data: {}
    }
  }
  const getData = (xid) => {
      let refinedData = {}
      if (!analystRatingsData?.isError) {
        let data = analystRatingsData?.data
        if (data) {
          refinedData = getRefinedData(data)
        }
      }
      setScoreOverAll({
        isLoading: false,
        data: refinedData
      })
  }

  function getCorportateSoverignData (key) {
    let resultObj = {}
    const resultObjLength = 3
    if (scoreOverAll.data && scoreOverAll.data[key]) {
      resultObj = scoreOverAll.data[key].reduce((accumulator, val, idx) => {
        accumulator['score' + idx] = formatNumber(val['score'])
        accumulator['score' + idx + '_color'] = val['color']
        return accumulator
      }, {})
      resultObj.ariaLabel = {
        ariaLabel0: OVERALL_ESG_CONSTANTS.CURRENT_SCORE.label,
        ariaLabel1: OVERALL_ESG_CONSTANTS.HISTORICAL_AVERAGE.label,
        ariaLabel2: OVERALL_ESG_CONSTANTS.CATEGORY_AVERAGE.label
      }
      resultObj.minMax = {
        low: 0,
        high: '50',
        lowLabel: 'Low Risk',
        highLabel: 'High Risk'
      }
      resultObj.label = key
    }
    return (<div className={styles.scoreBarContainer}>
      <ScoreBar data={resultObj} length={resultObjLength} />
    </div>)
  }
  return (<div className={styles.esgOverAllScoreContainer} id='esgOverAllScoreContainer'>
    {scoreOverAll && scoreOverAll.isLoading ? <Loader />
      : <div>
        <title className={styles.title}>Overall ESG Score</title>
        {Object.keys(scoreOverAll.data).length > 0
          ? <React.Fragment>
            <Header />
            <div>
              {getCorportateSoverignData(TYPE_OF_SCORE.CORPORATE)}
              {getCorportateSoverignData(TYPE_OF_SCORE.SOVERIGN)}
            </div>
          </React.Fragment>
          : <Nodata />
        }
      </div>
    }
  </div>)
}

function Header () {
  return (<div className={styles.headerContainer}>
    {Object.keys(OVERALL_ESG_CONSTANTS).map((key, idx) => {
      return <div className={styles.labelContainer}>
        <div className={styles.circle} style={{background: COLOR_DEFAULT_SET[idx].value}} />
        <div className={styles.labelDesc} id={`esgLabel${idx}`} >{OVERALL_ESG_CONSTANTS[key].label}</div>
      </div>
    })}
  </div>)
}
export default React.memo(ESGOverAllScore)

ESGOverAllScore.propTypes = {
  symbol: PropTypes.string.isRequired,
  analystRatingsData: PropTypes.object
}
