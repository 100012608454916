import { DEFAULT_VALUE } from '../../utils/appConstants'

export const FUND_QUOTE_DEFAULT_STATE = {
  categoryType: null,
  DelayedQuoteDate: {
    label: 'Delayed Quote as of',
    value: null
  },
  LastNAV: {
    label: 'Last NAV',
    value: DEFAULT_VALUE
  },
  Change: {
    label: 'Change',
    value: DEFAULT_VALUE,
    percentChange: DEFAULT_VALUE
  },
  YTDChange: {
    label: 'YTD Change',
    value: DEFAULT_VALUE
  },
  LastTradeDate: {
    label: 'As of',
    value: DEFAULT_VALUE
  },
  FundNetAssets: {
    label: 'Fund Net Assets',
    value: DEFAULT_VALUE
  },
  GrossExpenseRatio: {
    label: 'Gross Expense Ratio',
    value: DEFAULT_VALUE
  },
  Trailing1YrDistributionYield: {
    label: 'Trailing 1 Yr Distribution Yield',
    value: DEFAULT_VALUE
  },
  isLoading: true,
  isError: false
}
