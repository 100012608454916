import React, {useEffect, useState, useRef} from 'react'
import styles from './SubMenu.module.module.scss'
import PropTypes from 'prop-types'
import RangeSlider from '../../RangeSlider/RangeSlider'
import RangeSelector from '../../RangeSelector/RangeSelector'
import RangeSelectorCheckbox from '../../RangeSelector/RangeSelectorCheckbox'
import PureRadioButton from '../../RadioButton/PureRadioButton'
import { addDays } from 'date-fns'
import moment from 'moment-timezone'
import TechnicalIndicator from '../../TechnicalIndicator/TechnicalIndicator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const handleOnChange = (e, item, selectedParent, handleClick) => {
  if (item.control === 'slider' || item.control === 'rangeSelector' || item.control === 'rangeSelectorCheckbox') {
    item.isSelected = e.isValid == null ? true : e.isValid
    item.min = e.min
    item.max = e.max
    item.val = `${addBrackets(e.min)}-${addBrackets(e.max)}`
    handleClick(e, item, selectedParent)
  } else {
    item.isSelected = item.control !== 'checkbox' ? (!item.isSelected) : e.target.checked
    handleClick(e, item, selectedParent)
  }
}
const addBrackets = (value) => {
  let val = Number(value)
  if (val < 0) return `(${val})`
  return val
}
function handleFocusOfDifferentFilters (index, item, focusDiv) {
  if (index === 0 && item.control !== 'slider' && item.control !== 'rangeSelector' && item.control !== 'radio') focusDiv.current.focus()
  if (item.control === 'radio') { focusDiv.current.firstElementChild.firstElementChild.firstElementChild.firstElementChild.firstElementChild.focus() }
}

function subItemClick (e, item, selectedParent, handleClick, index, focusDiv) {
  if (e.key === 'Enter' && item.control === 'checkbox') {
    let htmlElementId = `chk-${item.parentVal}-${item.val}`
    if (document.getElementById(htmlElementId).checked) {
      document.getElementById(htmlElementId).checked = false
      item.isSelected = false
    } else {
      document.getElementById(htmlElementId).checked = true
      item.isSelected = true
    }
    handleClick(e, item, selectedParent)
  } else if (e.keyCode === KEYCODE.RIGHT && (item.control === 'slider' || item.control === 'rangeSelector' || item.control === 'radio')) {
    handleFocusOfDifferentFilters(index, item, focusDiv)
  } else {
    let nextSibling = e.currentTarget.nextElementSibling
    let previousSibling = e.currentTarget.previousElementSibling

    switch (e.keyCode) {
      case (KEYCODE.DOWN):
        e.preventDefault()
        if (nextSibling) { nextSibling.focus() }
        break
      case (KEYCODE.UP):
        e.preventDefault()
        if (previousSibling) { previousSibling.focus() }
        break
      case (KEYCODE.LEFT):
        document.getElementById(`li-${selectedParent.parentVal}-${item.parentVal}`)
          ? document.getElementById(`li-${selectedParent.parentVal}-${item.parentVal}`).focus()
          : document.getElementById(`li-${item.parentVal}-${item.val}`)
            ? document.getElementById(`li-${item.parentVal}-${item.val}`).focus()
            : e.preventDefault()
        break
    }
  }
}

const itemsHtml = (item, isChecked, selectedDates, parent, handleClick, index, onSubMenuStyleChange, onMultiDateChange, selectedValues) => {
  let htmlElementIdTemplate = `${item.parentVal}-${item.val}`
  switch (item.control) {
    case 'checkbox':
      return (
        <div>
          <label className={styles.checkboxLabel} htmlFor={`chk-${htmlElementIdTemplate}`} >
            <input id={`chk-${htmlElementIdTemplate}`} type='checkbox' checked={!!item.isSelected} onChange={(e) => handleOnChange(e, item, parent, handleClick)} value={item.val} isChecked={!!item.isSelected} label={item.val} />
            <span className={(item.isGlobeIcons) ? `${styles['checkmark']} ${styles['setTopMargin']}` : `${styles['checkmark']}`} />
            {(item.isGlobeIcons)
              ? <span className={`${styles.chkLabel} ${styles.globeIcon}`}>{
                [...Array(item.label)].map((el, idx) =>
                  <FontAwesomeIcon key={idx} icon={['fas', 'globe']} />)
              }</span>
              : <span className={styles.chkLabel}>{item.label}</span>}
          </label>
        </div>
      )
    case 'label':
      return <div id={`lbl-${htmlElementIdTemplate}`} role='button' > {item.val} </div>
    case 'slider':
      onSubMenuStyleChange()
      return (<RangeSlider selectedParent={parent} item={item} val={item.val} parentVal={item.parentVal} max={Math.ceil(item.max)} min={Math.floor(item.min)} highest={Math.ceil(item.highest)} lowest={Math.floor(item.lowest)} header={item.header} onChange={(range) => handleOnChange(range, item, parent, handleClick)} />)
    case 'rangeSelector':
      onSubMenuStyleChange()
      return <RangeSelector selectedParent={parent} item={item} selectedValue={item.selectedValue} handleClick={handleClick} max={item.max} min={item.min} onSubMenuStyleChange={onSubMenuStyleChange} onChange={(e) => handleOnChange(e, item, parent, handleClick)} />
    case 'technicalIndicator':
      return <TechnicalIndicator selectedParent={parent} item={item} handleClick={handleClick} />
    case 'rangeSelectorCheckbox':
      return <RangeSelectorCheckbox selectedParent={parent} item={item} selectedValue={item.selectedValue} handleClick={handleClick} max={item.max} min={item.min} onSubMenuStyleChange={onSubMenuStyleChange} onChange={(e) => handleOnChange(e, item, parent, handleClick)} />
    case 'radio':
      return <PureRadioButton onMultiDateChange={onMultiDateChange} selectedDates={selectedDates} selectedParent={parent} item={item} handleClick={handleClick} />
    case 'button':
      break
  }
}

const SubMenuListItem = ({item, isSelected, handleClick, selectedParent, reset, index, onSubMenuStyleChange, selectedValues}) => {
  const focusDiv = useRef()
  useEffect(() => {
    handleFocusOfDifferentFilters(index, item, focusDiv)
  })
  const [selectedDates, setSelectedDates] = useState('')
  function onMultiDateChange (selectedValue, e) {
    if (!selectedValue[1]) {
      var previousDate = new Date()
      previousDate.setDate(previousDate.getDate() - 2)
      selectedValue[0] = previousDate
      selectedValue[1] = addDays(new Date(), -1)
    }
    let selectedDateRange = {
      'startDate': selectedValue[0],
      'endDate': selectedValue[1],
      'isExcludedFromBar': true
    }
    setSelectedDates({ ...selectedDateRange })
    item.isCalender = true
    item.startDate = selectedValue[0]
    item.endDate = selectedValue[1]
    item.val = moment(addDays(item.startDate, -1), 'MM/DD/YYYY').toOADate() + '-' + moment(item.endDate, 'MM/DD/YYYY').toOADate()
    handleClick(e, item, selectedParent)
  }
  function setDefaultDates () {
    if (item.control === 'checkbox' && item.isCalender && !selectedDates) {
      let selectedDateRange = {
        'startDate': item.startDate,
        'endDate': item.endDate
      }
      setSelectedDates({ ...selectedDateRange })
    }
  }
  setDefaultDates()
  let customStyle = (item.control === 'rangeSelector' || item.control === 'slider' || item.control === 'technicalIndicator' || item.control === 'rangeSelectorCheckbox') ? {borderBottom: 'none', backgroundColor: '#ffffff'} : {}
  return (
    <li className={styles.sxSubListItem} style={customStyle}
      key={`subItem-${item.parentVal}-${item.val}`} tabIndex='-1' ref={focusDiv} role='menuitem'
      onKeyDown={(e) => subItemClick(e, item, selectedParent, handleClick, index, focusDiv)} aria-label={item.label}>
      {itemsHtml(item, item.isSelected, selectedDates, selectedParent, handleClick, index, onSubMenuStyleChange, onMultiDateChange, selectedValues)}
    </li>)
}

const KEYCODE = {
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
  TAB: 9,
  ENTER: 13
}

SubMenuListItem.propTypes = {
  item: PropTypes.object,
  isSelected: PropTypes.bool,
  handleClick: PropTypes.func,
  selectedParent: PropTypes.oneOfType([
    PropTypes.string, PropTypes.object]),
  reset: PropTypes.func,
  index: PropTypes.number,
  selectedValues: PropTypes.object,
  onSubMenuStyleChange: PropTypes.func
}
const nonUpdatableControls = ['slider', 'rangeSelector']
const areEqual = (prevProps, nextProps) => {
  if (prevProps.item !== nextProps.item) {
    return false
  }
  if (((prevProps.isSelected !== nextProps.isSelected) && !nonUpdatableControls.includes(nextProps.item.control))) { return false }
  return true
}

export default React.memo(SubMenuListItem, areEqual)
