/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './CompareSearchResult.module.scss'
import { keyEvents, onClick } from '../../Helpers/accessibilityUtilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import IndicatorData from '../../utils/RecentSearchData.json'
import CompareSearchResultRows from './CompareSearchResultRows'
import Loader from '../../../../../Lib/common/Loader'
// import lodash from 'lodash'
// import ResultBarRowRecent from './RecentRowBar'
// import NoResults from '../../../Shared/NoResults/NoResults'

function CompareSearchResult (props) {
  // const [mockdata, setMockData] = useState(
  //   JSON.parse(JSON.stringify(IndicatorData.SearchData)).slice(0, 5)
  // )
  let [showLoader, setShowLoader] = useState(false)
  let [noResultsData, setNoResultsData] = useState(false)
  let variableName = props.searchType ? `${props.searchType}_search-items` : 'search-items'
  let recentSearchItems = JSON.parse(sessionStorage.getItem(variableName) || '[]')
  recentSearchItems.forEach(element => {
    element.symbol = element.Symbol
    element.venueXid = element.VenueXID
    element.xid = element.VenueXID
    element.name = element.Name
    element.Category = element.CategoryName ? element.CategoryName : '--'
    element.searchType = getClassification(element.Classification) // === 'Mutual Fund' ? 'MF' : 'ETF'
  })
  function getClassification (classification) {
    switch (classification) {
      case 'Mutual Fund':
        return 'MF'
      case 'Alternative':
        return 'AI'
      case 'Stock':
        return 'EQ'
      case 'Model':
        return 'MOD'
      default:
        return 'ETF'
    }
  }
  function getSearchType (item) {
    if (item.Type !== 'Model') {
      return item.searchType
    }
    return item.PERMStrategyType === 'Traditional SMA' ? (item.isLocked ? 'SMAL' : 'SMA') : (item.isLocked ? 'ModL' : 'MOD')
  }
  const searchedList = props.data && props.data.slice(0, 5).map((item, index) => (
    <CompareSearchResultRows
      tag={index}
      index={index}
      item={{...item, searchType: getSearchType(item)}}
      key={index}
      searchTableResult={props.searchTableResult}
    />
  ))

  const recentSearchedList = recentSearchItems && recentSearchItems.slice(0, 3).map((item, index) => (
    <CompareSearchResultRows
      tag={index}
      index={index}
      item={{...item, searchType: getSearchType(item)}}
      key={index}
      searchTableResult={props.searchTableResult}
    />
  ))

  useEffect(() => {
    if (props.data[0] === 'loader') {
      setShowLoader(true)
    } else if (props.data === []) {
      setNoResultsData(true)
    } else {
      setShowLoader(false)
    }
  }, [props.data])
  return (
    <div>
      <React.Fragment>
        <div className={styles.recentsearchForm} id='recentSearch'>
          {/*  changes for search in */}
          <div className={styles.pillsFormRecent}>
            {showLoader && <div className={styles.searchInFormRecent}>
              <Loader spinnerSize={'2x'} msg={'Loading'} />
            </div>}
            {!showLoader && <div className={styles.searchInFormRecent}>
              <div className={styles.topPanelRecent}>
                <div aria-label='Search Results' className={styles.pillsBarRecent}>
                  Search Results
                  <div className={styles.productsRecent}>
                    { props.data && props.data.length > 0 ? searchedList : 'No Results'}
                  </div>
                </div>
              </div>
              { recentSearchItems && recentSearchItems.length > 0
                ? <div className={styles.topPanelRecent}>
                  <div aria-label='Based on your Search' className={styles.pillsBarRecent}>
                    Based on your Search
                    <div className={styles.productsRecent}>
                      {recentSearchedList}
                      {props.searchType === 'ai' && <div className={styles.bottomBorder}>
                        <div className={styles.resultsRow}>
                          <FontAwesomeIcon icon={['fas', 'info-circle']} style={{marginRight: '8px'}} />Only Non-Traded REITs are avilable to compare.
                        </div>
                      </div>
                      }
                    </div>
                  </div>
                </div> : ''
              }
            </div>}
          </div>
        </div>
      </React.Fragment>
    </div>
  )
}

CompareSearchResult.propTypes = {
  data: PropTypes.array,
  searchTableResult: PropTypes.func,
  searchType: PropTypes.string
}

export default CompareSearchResult
