/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
'use strict'
import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './ToolTip.module.scss'
import {getDeviceType, handleWindowResize} from '../../../../../../utils/utilities'
import { emitF2Event } from '../../../../../../utils/f2Methods'
import { MOD_CONTEXT_ENABLE_EVENTS } from './ToolTipConstants'
import { AISearchType } from '../../utils/appConstants'
import { isSnapshot, setRecentSearchedData } from '../../utils/utilities'
import { EVENT_NAMES } from '../../../../../../utils/f2Constants'
import { fireToastMessage } from '../../../../../Lib/CustomToast.js/CustomToast'
function ToolTip (props) {
  const [isOpen, setIsOpen] = useState(false)
  const [width, setWidth] = useState('225px')
  const [leftMargin, setLeftMargin] = useState('-243px')
  const [optionArr, setOptionArr] = useState([])
  const iconRef = useRef(null)
  const overlayRef = useRef(null)
  const [toggleAddtoWatchlist, setToggleAddtoWatchlist] = useState(false)
  const [device, setDevice] = useState(getDeviceType())
  useEffect(() => {
    handleWindowResize((deviceType) => {
      setDevice(deviceType)
      setOptionsWidth(props.type)
    })
  }, [])
  function setOptions (type) {
    let options = []
    switch (type) {
      case 'MOC':
      case 'TPMOC':
        if (isSnapshot(props.moduleTypeName)) {
          options = [
            {key: 'View Profile', href: EVENT_NAMES.MOD_GOTO, activeTabKey: 'profile', gotoPage: 'model-marketplace'},
            {key: 'Add to Compare', href: EVENT_NAMES.SYMBOL_CHANGED_EVENT, activeTabKey: 'compare', gotoPage: 'model-marketplace'}
          ]
        } else {
          options = [{key: 'View Profile', href: EVENT_NAMES.MOD_GOTO, activeTabKey: 'profile', gotoPage: 'model-marketplace'},
            {key: 'Remove', href: EVENT_NAMES.REMOVE_MODEL}
          ]
        }
        setOptionArr(options)
        break
      case 'EQ' :
        if (props.moduleTypeName === 'EquityScreener') {
          options = [
            {key: 'View Profile', href: EVENT_NAMES.MOD_GOTO, activeTabKey: 'profile', gotoPage: 'equities'},
            {key: 'Add to Watchlist', href: EVENT_NAMES.ADD_TO_WATCHLIST}
          ]
        } else {
          options = [
            {key: 'View Profile', href: EVENT_NAMES.MOD_GOTO, activeTabKey: 'profile', gotoPage: 'equities'},
            {key: 'Add to Watchlist', href: EVENT_NAMES.ADD_TO_WATCHLIST},
            {key: 'Add to Compare', href: EVENT_NAMES.MOD_GOTO, activeTabKey: 'compare', gotoPage: 'investment-center-equities'}
          ]
        }
        setOptionArr(options)
        break
      case 'ETF':
      case 'CEF':
      case 'MF':
        /** ETF Research Snapshot ->  Similar fund section -> Added options  */
        if (isSnapshot(props.moduleTypeName)) {
          options = [
            {key: 'View Profile', href: EVENT_NAMES.SYMBOL_CHANGED_EVENT, activeTabKey: 'profile', gotoPage: 'funds'},
            {key: 'Add to Watchlist', href: EVENT_NAMES.ADD_TO_WATCHLIST},
            {key: 'Add to Compare', href: EVENT_NAMES.SYMBOL_CHANGED_EVENT, activeTabKey: 'compare', gotoPage: 'funds'}
          ]
        } else if (props.moduleTypeName === 'fundCompare') {
          options = [
            {key: 'View Profile', href: EVENT_NAMES.SYMBOL_CHANGED_EVENT, activeTabKey: 'profile', gotoPage: 'funds'},
            {key: 'View Share Classes', href: EVENT_NAMES.SYMBOL_CHANGED_EVENT, activeTabKey: 'compare', gotoPage: 'funds'},
            {key: 'Remove', href: EVENT_NAMES.REMOVE_MODEL}
          ]
        } else {
          options = [
            {key: 'View Profile', href: EVENT_NAMES.MOD_GOTO, activeTabKey: 'profile', gotoPage: 'funds'}, // MOD_GOTO event to be used from search only
            {key: 'Add to Watchlist', href: EVENT_NAMES.ADD_TO_WATCHLIST},
            {key: 'Add to Compare', href: EVENT_NAMES.MOD_GOTO, activeTabKey: 'compare', gotoPage: 'funds'}
          ]
        }
        setOptionArr(options)
        break
      case 'FSCMF':
      case 'FSCETF':
        options = [
          {key: 'View Profile', href: EVENT_NAMES.SYMBOL_CHANGED_EVENT, activeTabKey: 'profile', gotoPage: 'funds'},
          {key: 'View Share Classes', href: EVENT_NAMES.SYMBOL_CHANGED_EVENT, activeTabKey: 'compare', gotoPage: 'funds'},
          {key: 'Remove', href: EVENT_NAMES.REMOVE_MODEL}
        ]
        setOptionArr(options)
        break
      case 'AI':
        options = [
          {key: 'View Profile', href: EVENT_NAMES.MOD_GOTO, activeTabKey: 'profile', gotoPage: 'alternative-investments'}
        ]
        setOptionArr(options)
        break
      case 'AIN':
        options = [
          {key: 'View Profile', href: EVENT_NAMES.MOD_GOTO, activeTabKey: 'profile', gotoPage: 'alternative-investments'},
          {key: 'Add to Compare', href: EVENT_NAMES.MOD_GOTO, activeTabKey: 'compare', gotoPage: 'alternative-investments'}
        ]
        setOptionArr(options)
        break
      case 'AINSnapshot':
        options = [
          {key: 'View Profile', href: EVENT_NAMES.SYMBOL_CHANGED_EVENT, activeTabKey: 'profile', gotoPage: 'alternative-investments'},
          {key: 'Add to Compare', href: EVENT_NAMES.SYMBOL_CHANGED_EVENT, activeTabKey: 'compare', gotoPage: 'alternative-investments'}
        ]
        setOptionArr(options)
        break
      case 'AIScreener':
        options = [
          {key: 'View Profile', href: EVENT_NAMES.SYMBOL_CHANGED_EVENT, activeTabKey: 'profile', gotoPage: 'alternative-investments'}
        ]
        setOptionArr(options)
        break
      case 'MFSnapshot':
        options = [
          {key: 'Order Prospectus', href: EVENT_NAMES.MOD_ORDER_PROSPECTUS, activeTabKey: '', gotoPage: 'funds'},
          {key: 'View Prospectus', href: EVENT_NAMES.MOD_VIEW_PROSPECTUS, activeTabKey: '', gotoPage: 'funds'}
        ]
        if (!(props.productAccess && props.productAccess.hasViewProspectusAccess)) {
          options = options.filter(x => x.key !== 'View Prospectus')
        }
        setOptionArr(options)
        break
      case 'AICOMPARE':
        options = [
          {key: 'View Profile', href: EVENT_NAMES.SYMBOL_CHANGED_EVENT, activeTabKey: 'profile', gotoPage: 'alternative-investments'},
          {key: 'Remove', href: EVENT_NAMES.REMOVE_MODEL}
        ]
        setOptionArr(options)
        break
      // default:
    }
    if (props.optional !== null && props.optional !== undefined) {
      options.shift()
      setOptionArr([...options, ...props.optional])
    }
    return options
  }

  function setOptionsWidth (type) {
    switch (type) {
      case 'MOC':
      case 'TPMOC':
        setWidth('124px')
        props.openRight ? setLeftMargin('10px') : setLeftMargin('-106px')
        break
      case 'EQ' :
      case 'ETF':
      case 'CEF':
      case 'MF':
      case 'FSCMF':
      case 'FSCETF':

        /** ETF Research Snapshot -> Similar fund section -> Emits data on click of View Profile, Add to compare and Add to Watchlist  */
        if (['ETF', 'MF'].includes(props.type) && isSnapshot(props.moduleTypeName)) {
          setWidth('124px')
          props.openRight ? setLeftMargin('10px') : setLeftMargin('-106px')
          return
        }
        setWidth('146px')
        props.openRight ? setLeftMargin('10px') : setLeftMargin('-128px')
        break
      case 'AI':
        setWidth('138px')
        props.openRight ? setLeftMargin('10px') : setLeftMargin('-120px')
        break
      case 'AIN':
      case 'AINSnapshot':
        setWidth('138px')
        props.openRight ? setLeftMargin('10px') : setLeftMargin('-120px')
        break
      case 'AIScreener':
        setWidth('120px')
        props.openRight ? setLeftMargin('10px') : setLeftMargin('-120px')
        break
      case 'MFSnapshot':
        setWidth('212px')
        props.openRight ? setLeftMargin('10px') : setLeftMargin('-120px')
        break
      case 'AICOMPARE':
        setWidth('138px')
        props.openRight ? setLeftMargin('10px') : setLeftMargin('-120px')
        break
    }
  }

  useEffect(() => {
    setOptionsWidth(props.type)
  }, [optionArr])

  useEffect(() => {
    if (isOpen) {
      // overlayRef.current.firstChild.firstChild.focus()
    }
  }, [isOpen])

  useOutsideAlerter(iconRef)
  function useOutsideAlerter (ref) {
    useEffect(() => {
      /**
         * Alert if clicked on outside of element
         */
      function handleOutsideEvents (event) {
        window.setTimeout(function () {
          if (ref.current && !ref.current.contains(event.target) && (Boolean(ref.current.getAttribute('data-x')) || event.key === 'Escape')) {
            setIsOpen(false)
          }
        }, 0)
      }
      function handleScrollEvents (event) {
        setIsOpen(false)
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleOutsideEvents)
      document.addEventListener('scroll', handleScrollEvents)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleOutsideEvents)
        document.removeEventListener('scroll', handleScrollEvents)
      }
    }, [ref])
  }

  function handleOverlayStatus (e) {
    setOptions(props.type)
    if (!isOpen) {
      if (props.icon === '') {
        setIsOpen(false)
      } else {
        setIsOpen(true)
      }
    } else {
      setIsOpen(false)
    }
    setTimeout(() => {
      if (document.getElementById(props.id) && document.getElementById(props.id).getAttribute('data-x') === 'true') {
        // overlayRef.current.firstChild.firstChild.focus()
      }
    }, 200)
  }

  let setFocusOnIcon = () => {
    let id = props.id.replace('@2nd', '')
    if (document.getElementById(id)) {
      document.getElementById(id).focus()
    }
  }
  function handleKeyEvents (e) {
    if (e.key === 'Escape') {
      setOptions(props.type)
      setIsOpen(false)
      setFocusOnIcon()
    } else if (e.key === 'Enter') {
      handleOverlayStatus(e)
    }
  }

  function handleOverlay (e) {
  }

  function handleOverlayKey (e) {
    let tempArr = optionArr.map(a => a.key)
    if (tempArr.length > 0) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.stopPropagation()
        e.preventDefault()
        if (e.key === 'ArrowDown' && e.target.innerText !== tempArr[tempArr.length - 1]) {
          e.target.parentElement.nextElementSibling && e.target.parentElement.nextElementSibling.firstChild.focus()
        } else if (e.key === 'ArrowUp' && e.target.innerText !== tempArr[0]) {
          e.target.parentElement.previousElementSibling && e.target.parentElement.previousElementSibling.firstChild.focus()
        }
      }
      if (e.shiftKey) {
        if (e.target.innerText === tempArr[0]) {
          setIsOpen(false)
        }
      }
      if (e.key === 'Tab') {
        if (e.shiftKey) {
          if (e.target.innerText === tempArr[0]) {
            setIsOpen(false)
          }
        } else {
          if (e.target.innerText === tempArr[tempArr.length - 1]) {
            setIsOpen(false)
          }
        }
      }
    }
    if (e.key === 'Escape') {
      setIsOpen(false)
      setFocusOnIcon()
    }
  }
  function iconHandler (icon) {
    let span = ''
    switch (icon) {
      case 'ActionDropDown':
        span = <span className={styles.ActionDropdown} id={'DropDown'}>Actions<span className={styles.dropdownsvg}><FontAwesomeIcon icon={['fas', 'caret-down']} className={styles.clickableicon} /></span></span>
        break
      case 'downCircle':
        span = <span className={styles.alignRight}>
          <FontAwesomeIcon icon={['far', 'caret-circle-down']} className={styles.clickableicon} />
        </span>
        break
      case '':
        span = <span style={{display: 'none'}} />
        break
      default:
        span = <span className={props.data && props.data.data && props.data.data.moduleName === 'SimilarModels' ? `${styles.alignR} ${styles.setZindex}` : `${styles.alignR}`}>
          <FontAwesomeIcon icon={['far', 'ellipsis-v']} className={styles.clickableicon} style={{fontSize: '16px'}} />
        </span>
        break
    }
    return span
  }
  function styleHandler () {
    let style = ''
    style = { width: width, marginLeft: leftMargin }
    if (props.moduleTypeName === 'fundCompare') {
      style.top = '-7px'
      style.marginLeft = '-132px'
    }
    return style
  }

  function overlayKeyHandler (argVenueXid, key) {
    if (key === 'Remove') {
      let venueXid = argVenueXid.split('_')[1]
      props.overlayKeyHandler(venueXid)
    } else if (key === 'Add to Watchlist') {
      // if addToWatchlistHandler passed than add to watchlist component exists on parent so no need to handle that here. if not passed than add to watchlist component from this page will work
      if (props.addToWatchlistHandler) {
        props.addToWatchlistHandler()
      } else {
        props.showAddToWatchlist(true, props.venueXid, props.symbol)
      }
    }
  }

  let selectUserAction = (item) => {
    let dataEmit = {}
    if (props.data) {
      if (item.activeTabKey === 'profile' && (props.data.isFromSearch)) {
        setRecentSearchedData(props.data).then(function (result) {})
      }
      let strategyType = getStrategyType()
      let symbol = AISearchType.indexOf(props.data.searchType) > -1 || props.data['Offshore Flex'] === 'Yes' ? props.data.CUSIP : getSymbolName()
      dataEmit = {
        gotoPage: item.gotoPage, // Option can be funds, model-marketplace, alternative-investments  ---  3rd Level Navigation tabs own by Pershing
        symbol: symbol || props.data.symbol || props.data.Name || props.data.name,
        issueType: props.data.Type ? props.data.Type : props.data.type,
        activeTabKey: item.activeTabKey
      }
      addModContextToApplicable(dataEmit, item.href)
    }

    const equitiesCompare = JSON.parse(sessionStorage.getItem('equityCompareData') || '[]')
    if (dataEmit.issueType === 'EQ' && item.key === 'Add to Compare' && equitiesCompare.length >= 10) {
      fireToastMessage({
        message: 'You can only compare up to 10 investments at one time.\n Please remove an investment before searching.'
      })
    } else {
      console.log(dataEmit)
      emitF2Event(item.href, dataEmit)
      // Changes for PRW-1499 , close search modal after clicking view proile
      let searchOverlay = document.getElementById('mainInner')
      if (searchOverlay) {
        searchOverlay.parentNode.click()
      }
    }
  }
  const getSymbolName = () => {
    if (props.xrefData) { return props.xrefData.symbol } else if (props.symbol) { return props.symbol } else if (props.data.Ticker) { return props.data.Ticker } else if (props.data.Symbol) { return props.data.Symbol }
  }

  const getStrategyType = () => {
    return props.data.StrategyType ? props.data.StrategyType : (props.data['Product Type'] ? props.data['Product Type'] : '')
  }

  const addModContextToApplicable = (dataEmit, eventName) => {
    if (eventName && MOD_CONTEXT_ENABLE_EVENTS.hasOwnProperty(eventName)) {
      dataEmit.modContext = {
        CUSIP: props.data.CUSIP ? props.data.CUSIP : props.data.symbol
    }
  }
}

  // const hideToolTipForTypes = ['ModL', 'SMAL', 'MOD', 'SMA', 'MOC', 'TPMOC', 'EQ', 'ETF', 'CEF', 'MF', 'FSCMF', 'FSCETF', 'AI', 'AIN'] // PRW-1948 Handle locked model snapshot page navigation using tooltip
  let [isHover, setIsHover] = useState(false)
  return (
    <React.Fragment>
      {
        <div className={`${styles.tooltipContainer}`} data-x={isOpen} id={props.id} ref={iconRef} tabIndex={props.index ? props.index : '-1'} aria-controls={props.id + '@'} aria-label={'Overlay menu for ' + props.type} aria-disabled={'false'} aria-expanded={isOpen} role='menu' onClick={handleOverlayStatus} onKeyDown={handleKeyEvents} data-individual='1'>
          <span aria-label={props.icon === 'ActionDropDown' ? 'Actions Dropdown' : 'icon'} role='button' tabIndex={'0'} className={styles.alignR}>
            {iconHandler(props.icon)}
          </span>
          <div id={props.id + '@'} role='none' data-x={isOpen} ref={overlayRef} style={styleHandler()} className={(isOpen && props.icon === 'ActionDropDown') ? `${styles.overlayDesign} ${styles.aligndropdown}` : isOpen ? styles.overlayDesign : styles.displayNone} onBlur={handleOverlay}>
            {optionArr && optionArr.length > 0 ? optionArr.map((item, index) => {
            return <div className={styles.menuItem} id={props.id + 'Inner' + index} onKeyDown={handleOverlayKey} key={index + '_key' + props.id} role='none'>
              { item.key === 'Remove' || item.key === 'Add to Watchlist'
                ? <button id={index + item.key} role='menuitem' className={styles.buttonsOverlay} onClick={() => overlayKeyHandler(props.id, item.key)} tabIndex='0'>{item.key}</button>
                : item.key === 'View Share Classes'
                  ? <button id={index + item.key} role='menuitem' className={styles.buttonsOverlay} onClick={() => props.viewShareClassModalHandler((props.venueXid ? props.venueXid : ''), props.symbol)} tabIndex='0'>{item.key}</button>
                  : <button id={index + item.key} role='menuitem' className={styles.buttonsOverlay}
                      onBlur={() => setIsHover(item.key !== 'View Profile')}
                      onMouseOver={() => setIsHover(item.key === 'View Profile')}
                      onMouseOut={() => setIsHover(item.key !== 'View Profile')} onClick={() => selectUserAction(item)} tabIndex='0'>{item.key} {item.key === 'Download PDF' && <FontAwesomeIcon icon={['fal', 'file-pdf']} className={styles.clickableicon} />}</button>
              }
            </div>
          }) : <div role='menuitem' />
          }
          </div>
        </div>
      }
    </React.Fragment>
  )
}

export default ToolTip

ToolTip.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  openRight: PropTypes.bool,
  moduleTypeName: PropTypes.string,
  index: PropTypes.number,
  optional: PropTypes.array,
  overlayKeyHandler: PropTypes.func,
  viewShareClassModalHandler: PropTypes.func,
  addToWatchlistHandler: PropTypes.func,
  data: PropTypes.object,
  venueXid: PropTypes.number,
  symbol: PropTypes.string,
  xrefData: PropTypes.object,
  productAccess: PropTypes.object,
  isFromSearch: PropTypes.bool,
  showAddToWatchlist: PropTypes.func
}
