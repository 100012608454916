import React from 'react'
import styles from './WatchlistCreateHeader.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

function WatchlistCreateHeader (props) {
  const handleExit = (e) => {
    props.onClose(e)
  }

  return (
    <div className={styles.watchlistModalHeader}>
      <React.Fragment>
        <div className={styles.watchlistDescriptionHeaderText}>{props.isEdit ? 'Edit Watchlist' : 'Create a Watchlist'}</div>
        <button className={styles.watchlistDescriptionPopUpClose} alt='Close' id='closeButton' data-testid='closeButton' onClick={handleExit}>
          <FontAwesomeIcon icon={['fal', 'times']} className={styles.watchlistDescriptionPopUpClose} id='closeButtonFontAwsome' />
        </button>
      </React.Fragment>
    </div>
  )
}

WatchlistCreateHeader.propTypes = {
  onClose: PropTypes.func,
  isEdit: PropTypes.bool
}
export default WatchlistCreateHeader
