import React from 'react'
import PropTypes from 'prop-types'
import styles from './FundVestTag.module.scss'

export default function FundVestTag (props) {
  return (
    <div id={'buttonFund'} name={'buttonFund' + props.value} className={`${styles.MF} ${styles.adjustWidth}`} aria-label={props.value} tabIndex='0' role='button' style={(props.value === 'FundVest ETF') ? {width: '95px'} : {}}>
      {props.value}
    </div>
  )
}

FundVestTag.propTypes = {
  value: PropTypes.string
}
