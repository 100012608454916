import DataRequest from '../../../../../../services/DataRequest'
import { URLS } from '../../utils/appConstants'
import { COLOR_DEFAULT_SET, NXI_COLOR_DEFAULT_SET } from '../../../../../../utils/appConstants'

export const TYPE_OF_SCORE = {
  CORPORATE: 'Corporate Score',
  SOVERIGN: 'Sovereign Score'
}

export const getAnalystRatings = (xid, callBackFunc) => {
  DataRequest.execute(
    `${URLS.PLATFORM.MANAGEDFUNDS_MORNINGSTAR_OVERALL_ANALYST_RATING}`,
    {
      params: {
        venueXid: xid
      }
    },
    callBackFunc,
    null
  )
}

export const getLevelTwoRiskRatings = (xid, callBackFunc) => {
  DataRequest.execute(
    `${URLS.PLATFORM.MANAGEDFUNDS_MORNINGSTAR_ESG_LEVEL_TWO_RISK_RATING}`,
    {
      params: {
        venueXid: xid
      }
    },
    callBackFunc,
    null
  )
}

export function getRefinedData (data) {
  let valueObj = null
  let result = {}
  result[TYPE_OF_SCORE.CORPORATE] = []
  result[TYPE_OF_SCORE.SOVERIGN] = []
  if (data.fund && data.fund.score) {
    valueObj = data.fund.score
    result[TYPE_OF_SCORE.CORPORATE].push({
      score: valueObj.corporate && valueObj.corporate.overallScore,
      color: COLOR_DEFAULT_SET[0].value

    })
    result[TYPE_OF_SCORE.SOVERIGN].push({
      score: valueObj.sovereign && valueObj.sovereign.overallScore,
      color: COLOR_DEFAULT_SET[0].value
    })
    result[TYPE_OF_SCORE.CORPORATE].push({
      score: valueObj.corporate && valueObj.histAvgSustainabilityScore,
      color: COLOR_DEFAULT_SET[1].value
    })
    result[TYPE_OF_SCORE.SOVERIGN].push({
      score: valueObj.sovereign && valueObj.sovereign.historicalScore,
      color: COLOR_DEFAULT_SET[1].value
    })
  }
  if (data.category && data.category.score) {
    valueObj = data.category.score
    result[TYPE_OF_SCORE.CORPORATE].push({
      score: valueObj.corporate && valueObj.corporate.averageScore,
      color: COLOR_DEFAULT_SET[2].value
    })
    result[TYPE_OF_SCORE.SOVERIGN].push({
      score: valueObj.sovereign && valueObj.sovereign.averageScore,
      color: COLOR_DEFAULT_SET[2].value
    })
  }
  return result
}

export function getRefinedNXIData (data) {
  let valueObj = null
  let result = {}
  result[TYPE_OF_SCORE.CORPORATE] = []
  result[TYPE_OF_SCORE.SOVERIGN] = []
  if (data.fund && data.fund.score) {
    valueObj = data.fund.score
    result[TYPE_OF_SCORE.CORPORATE].push({
      score: valueObj.corporate && valueObj.corporate.overallScore,
      color: NXI_COLOR_DEFAULT_SET[0].value

    })
    result[TYPE_OF_SCORE.SOVERIGN].push({
      score: valueObj.sovereign && valueObj.sovereign.overallScore,
      color: NXI_COLOR_DEFAULT_SET[0].value
    })
    result[TYPE_OF_SCORE.CORPORATE].push({
      score: valueObj.corporate && valueObj.histAvgSustainabilityScore,
      color: NXI_COLOR_DEFAULT_SET[1].value
    })
    result[TYPE_OF_SCORE.SOVERIGN].push({
      score: valueObj.sovereign && valueObj.sovereign.historicalScore,
      color: NXI_COLOR_DEFAULT_SET[1].value
    })
  }
  if (data.category && data.category.score) {
    valueObj = data.category.score
    result[TYPE_OF_SCORE.CORPORATE].push({
      score: valueObj.corporate && valueObj.corporate.averageScore,
      color: NXI_COLOR_DEFAULT_SET[2].value
    })
    result[TYPE_OF_SCORE.SOVERIGN].push({
      score: valueObj.sovereign && valueObj.sovereign.averageScore,
      color: NXI_COLOR_DEFAULT_SET[2].value
    })
  }
  return result
}
