import Axios from 'axios'

const AxiosWrapper = Axios.create()

AxiosWrapper.interceptors.response.use(response => {
  if (!response) {
    return response
  }

  return response
}, error => Promise.reject(error))

AxiosWrapper.CancelToken = Axios.CancelToken
AxiosWrapper.isCancel = Axios.isCancel

export default AxiosWrapper
