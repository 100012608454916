/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import PropTypes from 'prop-types'
import styles from './NavTabControl.module.scss'
import { keyEvents, onClick } from '../../Helpers/accessibilityUtilities'

export default function NavTabControl (props) {
  function executeScroll (key) {
    props.onClick(key)
  }
  return (
    <React.Fragment>
      <div className={styles.tabControl} role='tablist'>
        {props.itemList.map((item, index) =>
          <div id={'nav' + item} key={index + item} role='tab' className={`${styles.tabLink} ${styles.tabItems} ${props.activeItem === item ? styles.tabHighlight : ''}`} tabIndex={index === 0 ? '0' : '-1'} onClick={key => onClick(key, executeScroll, item)} onKeyDown={e => keyEvents(e, executeScroll, null, item)}>{item}</div>
        )}
      </div>
      <div>
        { props.children }
      </div>
    </React.Fragment>
  )
}

NavTabControl.propTypes = {
  children: PropTypes.node,
  itemList: PropTypes.array,
  activeItem: PropTypes.string,
  onClick: PropTypes.func
}
