import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Tag from '../Tag/Tag'
import styles from './RightFilterSection.module.scss'

function RightFilterSection (props) {
  bindEvents()
  const {selectedValues} = props
  const [widthFilterBar, setWidthOfFilterBar] = useState(0)
  useEffect(() => {
    setFilterBarCount()
  }, [])
  function GetSelectedValues () {
    const selectedKeys = Object.keys(selectedValues)
    let tags = []
    let moreChildren = {}
    let counter = 0
    let widthCovered = 0
    let overFlow = false
    let noOfbars = 0
    selectedValues && selectedKeys && selectedKeys.forEach((key) => {
      if (selectedValues[key].length > 0) {
        selectedValues[key].forEach((item) => {
          // eslint-disable-next-line no-unused-expressions
          if (item.isExcludedFromBar) {
            return
          }
          const itemRefined = item.text.length > 30 ? item.text.substring(0, 25) + ' ...' : item.text
          if (!overFlow) {
            widthCovered += itemRefined.length * 8 + 40
            overFlow = (widthFilterBar - 70) < widthCovered
          }
          if (item.text.length > 0) {
            if (overFlow) {
              counter++
              if (!moreChildren.hasOwnProperty(key)) {
                moreChildren[key] = []
              }
              moreChildren[key].push(item.text)
            } else {
              tags.push(<Tag key={counter} idx={counter} filterType={key} value={item.text} name={itemRefined} onClear={props.onClearClick} />)
              noOfbars = ++counter
            }
          }
        })
      }
    })
    if (moreChildren && Object.keys(moreChildren).length > 0) {
      tags.push(<Tag key={noOfbars} idx={noOfbars} value={`+ ${counter - noOfbars} More`} name={`+ ${counter - noOfbars} More`} moreChildren={moreChildren} onClear={props.onClearClick} />)
    }
    return tags
  }
  function bindEvents () {
    window.addEventListener('resize', setFilterBarCount)
  }
  function setFilterBarCount () {
    let el = document.querySelector(`#${props.idFilterBar} .${styles.rightFilterSectionContainer}`)
    let rfSecWidth = el && el.offsetWidth

    setWidthOfFilterBar(props.widthOfBar || rfSecWidth)
  }
  return (<div className={styles.rightFilterSectionContainer}>
    {widthFilterBar && GetSelectedValues()}
  </div>)
}
export default RightFilterSection
RightFilterSection.propTypes = {
  selectedValues: PropTypes.object,
  onClearClick: PropTypes.func,
  idFilterBar: PropTypes.string,
  widthOfBar: PropTypes.number
}
