import React, {useState, useRef, useEffect} from 'react'
import styles from './SymbolWithPopover.module.scss'
import PropTypes from 'prop-types'
import QuoteCard from '../../../News/QuoteCard/QuoteCard'
import Popover from '../../../Lib/Popover/Popover'
import { getDeviceType, deviceType } from '../../../../utils/utilities'

export default function SymbolWithPopover ({symbol, xid, isFixedPos, isPopover = false, isAutoPosition = false, symbolDescription = ''}) {
  const ref = useRef(null)
  const quoteCardRef = useRef(null)
  const [showQuoteCard, setShowQuoteCard] = useState(false)
  const [device, setDevice] = useState(getDeviceType())

  useEffect(() => {
    window.addEventListener('resize', function () {
      setDevice(getDeviceType())
    })
  }, [])
  const symbolHoverHandler = () => {
    setShowQuoteCard(true)
  }

  const symbolLeaveHandler = () => {
    setShowQuoteCard(false)
  }
  const quoteCardData = () => {
    return (<QuoteCard id='quoteCardWatchlist' showOnMobile venueXid={xid} showViewQuoteCardButton />
    )
  }
  function setCardPosition () {
    const quoteCard = quoteCardRef.current && quoteCardRef.current.getBoundingClientRect()
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
    if (quoteCard) {
      if (quoteCard.bottom > windowHeight) {
        quoteCardRef.current.style.marginTop = `-${quoteCard.bottom - windowHeight}px`
      } else if (quoteCard.top < 0) {
        quoteCardRef.current.style.marginTop = `${-quoteCard.top}px`
      }
    }
  }
  return (<React.Fragment>
    <div className={styles.symbolWithPopoverContainer} onMouseLeave={() => symbolLeaveHandler()} data-testid={`divSymbolWithPopover_${symbol}`}>
      <button className={styles.symbol} data-value={'label-symbol'}
        ref={ref}
        onMouseOver={() => symbolHoverHandler()}
        onFocus={() => void 0}>
        {symbol}
        {symbolDescription &&<span className={styles.searchDescription}>{symbolDescription}</span>}
      </button>
      {(!isPopover && showQuoteCard) || (showQuoteCard && device === deviceType.Ipad) ? <div ref={quoteCardRef} className={`${isFixedPos ? styles.fixedPopupPosition : ''} ${symbolDescription ? styles.searchDescriptionPopupPosition : ''}`} id='quote-card-popover' referenceElement={ref}>
        <QuoteCard updateQuoteCardPosition={setCardPosition} id='quoteCardWatchlist' showOnMobile venueXid={xid} showViewQuoteCardButton isAutoPosition />
      </div> : showQuoteCard && <Popover
        id='bottom-popover'
        closePopover={symbolLeaveHandler}
        body={quoteCardData()}
        referenceElement={ref.current}
        showPointingArrow={false}
        placement={isAutoPosition ? 'auto' : 'bottom'}
        isNoPadding
      />}
    </div>
  </React.Fragment>)
}

SymbolWithPopover.propTypes = {
  symbol: PropTypes.string,
  xid: PropTypes.number,
  isFixedPos: PropTypes.bool,
  isPopover: PropTypes.bool,
  isAutoPosition: PropTypes.bool,
  symbolDescription: PropTypes.string
}
