
export const PershingIBDCode = ['511', 'PERS']
export const PershingPropFeeIndicator = 'PROP'
export const DefaultProductFeeIndicator = ['LPPP', 'NULL', 'PASS', 'PROP', 'BANC', 'NTFP']
export const EquityStyleMapping = ['Large Value', 'Large Core', 'Large Growth', 'Mid Value', 'Mid Core', 'Mid Growth', 'Small Value', 'Small Core', 'Small Growth']
export const yesNoMapping = ['No', 'Yes']
export const ListingExchangeDictionary = {
  'NYQ': 'NYSE',
  'ASQ': 'NYSE American',
  'NAQ': 'NASDAQ',
  'NMQ': 'NASDAQ',
  'NSQ': 'NASDAQ',
  'BTQ': 'CBOE',
  'OBB': 'OTCMarkets',
  'PCQ': 'OTCMarkets',
  'PNK': 'OTCMarkets',
  'OTC': 'OTCMarkets'
}
export const BASICS_FILTER_OPTIONS = [
  {
    'val': 'PERAIProductFeeIndicator',
    'label': 'Fee',
    'parentVal': 'PERAIProductFeeIndicator',
    'rootFilterId': 'basics-select',
    'level': 'L1',
    'items': [
      {
        'val': ['LPPP', 'PASS', 'BANC'],
        'label': 'Fee',
        'pillLabel': 'Fee',
        'control': 'checkbox',
        'level': 'L2'
      },
      {
        'val': 'NTFP',
        'label': 'No Fee',
        'pillLabel': 'Fee',
        'control': 'checkbox',
        'level': 'L2'
      }
    ]
  }
]

export const VALUATIONS_FILTER_OPTIONS = [
  {
    'val': 'SMPriceBookMRQ',
    'label': 'Price/Book (MRQ)',
    'parentVal': 'valuation-select',
    'rootFilterId': 'valuation-select',
    'level': 'L1',
    'isDynamicData': 'true',
    'items': [
      {
        'val': 'SMPriceBookMRQ',
        'selectedValue': [],
        'label': '',
        'pillLabel': 'P/Book MRQ',
        'rootFilterId': 'valuation-select',
        'parentVal': 'SMPriceBookMRQ',
        'isDynamicData': 'true',
        'control': 'rangeSelectorCheckbox',
        'max': '',
        'min': '',
        'highest': 20000000000000000000000,
        'lowest': 0,
        'level': 'L2',
        'isSelected': false,
        'headerRangeSelector': 'P/Book Ratio',
        'headerManualEntry': 'P/Book Ratio',
        'arrayList': ['<=1', '1-2', '2-3', '3-5', '>=5']
      }
    ]
  },
  {
    'val': 'SMPriceSalesTTM',
    'label': 'Price to Sales (TTM)',
    'parentVal': 'valuation-select',
    'rootFilterId': 'valuation-select',
    'level': 'L1',
    'isDynamicData': 'true',
    'items': [
      {
        'val': 'SMPriceSalesTTM',
        'selectedValue': [],
        'label': '',
        'pillLabel': 'P/Sales TTM',
        'rootFilterId': 'valuation-select',
        'parentVal': 'SMPriceSalesTTM',
        'isDynamicData': 'true',
        'control': 'rangeSelectorCheckbox',
        'max': '',
        'min': '',
        'highest': 20000000000000000000000,
        'lowest': 0,
        'level': 'L2',
        'isSelected': false,
        'headerRangeSelector': 'P/Book Ratio',
        'headerManualEntry': 'P/Book Ratio',
        'arrayList': ['<=1', '1-2', '2-3', '3-5', '>=5']
      }
    ]
  }
]
export const MarketCap = {
  Mega: 'Mega',
  Large: 'Large',
  Mid: 'Mid',
  Small: 'Small',
  Micro: 'Micro'
}

export const actionList = [
  'Download Selected Tab',
  'Download All Tabs'
]

export const TabKeysEquity = ['Basics', 'Valuation', 'Growth', 'Fundamentals', 'Performance', 'Technicals']
export const TabKeysModel = ['Key Measures', 'Operational', 'Basics', 'Allocation', 'Performance', 'Characteristics', 'Ratings', 'Risk']
export const TabKeysFund = ['Key Measures', 'Operational', 'Profile', 'ESG Rating', 'ESG Carbon Metrics', 'Asset Allocation', 'Income', 'Performance', 'Portfolio Characteristics', 'Ratings', 'Risk', 'Technical Indicators']
