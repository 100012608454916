'use strict'

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { enterKeyEvent } from '../../../utils/utilities'
import styles from './CheckBoxStyles.module.scss'

export default function CheckBox ({id, index, value, isChecked, label, onCheck, isDisabled, errorMsg, ariaLabel}) {
  const [isHover, setIsHover] = useState(false)
  const onKeyDown = (e) => {
    enterKeyEvent(e, onCheck)
  }
  function handleFocus () {
    if (isDisabled) {
      setIsHover(true)
    }
  }
  return (
    <div className={styles.checkBoxContainer}
      onFocus={() => handleFocus()}
      onBlur={() => setIsHover(false)}
      onMouseOver={() => isDisabled ? setIsHover(true) : ''}
      onMouseOut={() => setIsHover(false)}>
      <label className={styles.checkboxLabel} htmlFor={id}>
        <input id={id} tabIndex={-1} disabled={isDisabled} data-testid={label} value={value} type='checkbox' checked={isChecked} name={label} onChange={onCheck} onKeyDown={onKeyDown} />
        {(isHover) && errorMsg}
        <span className={isDisabled ? styles.disabled : `${styles['checkmark']}`} aria-label={label || ariaLabel} tabIndex={0} role='checkbox' aria-checked={isChecked} />
      </label>
      {label &&
      <div className={styles.name} data-value={'label-checkBox'} title={label}>
        {label}
      </div>
      }
    </div>
  )
}
CheckBox.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  value: PropTypes.string,
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  onCheck: PropTypes.func,
  isDisabled: PropTypes.bool,
  errorMsg: PropTypes.element,
  ariaLabel: PropTypes.string
}
