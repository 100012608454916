/* eslint-disable no-tabs */
const toastId = 'Toast_Markit_Pershing'

const TOAST_ALIGNMENT = {
  TOP_LEFT: 'justify-content:unset;left: 20px;',
  TOP_RIGHT: 'justify-content:unset;right: 20px',
  BOTTOM_RIGHT: 'top:unset;justify-content:unset;bottom: 20px;right: 20px;',
  BOTTOM_LEFT: 'top:unset;justify-content:unset;bottom: 20px;left: 20px;'
}

export const fireToastMessage = (dataObj, timer = 1500) => {
  checkAndRemoveToast()
  document.body.appendChild(getToastBody(dataObj))
  addClickEvent()
  addTimer(timer)
}

function checkAndRemoveToast (elem) {
  const element = elem || document.getElementById(toastId)
  if (element) {
    element.removeEventListener('click', null)
    element.parentElement.removeChild(element)
  }
}
function getToastBody (dataObj) {
  addAnimation()

  const parentToast = document.createElement('div')
  parentToast.id = toastId
  parentToast.style.cssText = getParentCss() + (TOAST_ALIGNMENT[dataObj.position] || 'width: 100%;')

  const notificationContainer = document.createElement('button')
  notificationContainer.style.cssText = gettoastMsgContainerCss()
  parentToast.appendChild(notificationContainer)

  const childContent = document.createElement('div')
  childContent.innerText = dataObj.message
  notificationContainer.appendChild(childContent)

  return parentToast
}

function addAnimation () {
  const css = window.document.styleSheets[0]
  css.insertRule(`
    @keyframes toast-in-right {
	    from {
	        transform: translateY(-100%);
	    }
	    to {
	        transform: translateY(0);
	    }
}`, css.cssRules.length)
}
function addTimer (timer) {
  setTimeout(() => {
    checkAndRemoveToast()
  }, timer)
}
function addClickEvent () {
  let element = document.getElementById(toastId)
  element && element.addEventListener('click', () => {
    checkAndRemoveToast(element)
  })
}

function getParentCss () {
  let parentCss = 'display:flex;'
  parentCss += 'justify-content:center;'
  parentCss += 'align-items: center;'
  parentCss += 'position: fixed;'
  parentCss += 'top: 20px;'
  parentCss += 'z-index: 9999999;'
  parentCss += 'transition: transform .6s ease-in;'
  parentCss += 'animation: toast-in-right .7s;'

  return parentCss
}

function gettoastMsgContainerCss () {
  let toastMsgContainerCss = 'font-size: 14px;'
  toastMsgContainerCss += 'box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);'
  toastMsgContainerCss += 'border-radius: 3px;'
  toastMsgContainerCss += 'box-sizing: border-box;'
  toastMsgContainerCss += 'z-index: 1000000;'
  toastMsgContainerCss += 'background: #000;'
  toastMsgContainerCss += 'color: #fff;'
  toastMsgContainerCss += 'border: none;'
  toastMsgContainerCss += 'padding: 15px;'
  toastMsgContainerCss += 'font-family: "Roboto-Regular",Arial,sans-serif;'
  toastMsgContainerCss += 'line-height: 20px;'
  toastMsgContainerCss += 'display: flex;'
  toastMsgContainerCss += 'justify-content: space-between;'

  return toastMsgContainerCss
}
