import React, {useState, useRef, useEffect} from 'react'
import styles from './search.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { keyEvents } from '../../Helpers/accessibilityUtilities'
import lodash from 'lodash'
import PropTypes from 'prop-types'
// import { SAVED_SCREENERS } from '../../utils/appconstants'
import CompareSearchResult from '../../Shared/CompareSearchResult/CompareSearchResult'
import NoResults from '../../Shared/NoResults/NoResults'
export default function Search (props) {
  const [searchbarFocus, setSearchbarFocus] = useState(false)
  const [freeTextSearch, setFreeTextSearch] = useState('')
  const [clearLabel, setClearLabel] = useState(false)
  // const mockdata = JSON.parse(JSON.stringify(SAVED_SCREENERS.SCREENERS))
  useEffect(() => {
    document.addEventListener('click', function (event) {
    // If user either clicks X button OR clicks outside the modal window, then close modal/overlay by calling handleOnClick()
      if (!event.target.closest('#mainInner')) {
        handleOnClick()
      }
    }, false)
    document.addEventListener('keydown', function (event) {
      if (event.key === 'Escape') {
        handleOnClick()
      }
    }, false)
    return () => {
      document.removeEventListener('click', function (event) {
        if (!event.target.closest('#mainInner')) {
          handleOnClick()
        }
      }, false)
      document.removeEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
          handleOnClick()
        }
      }, false)
    }
  }, [])

  const searchOpen = () => {
    return searchbarFocus || !searchbarFocus
  }
  const setSearchFocus = (rec, freeTextSearch) => {
    if (props.rowsAdded || props.rowsAdded === 0) {
      let totalCardCount = props.rowsAdded + props.cardCount
      if (totalCardCount === 10) {
        props.modelComparisonErrorPopup()
        return
      }
    }
    setClearLabel(true && freeTextSearch.length === 0)
    setSearchbarFocus(rec && freeTextSearch.length > 0)
  }
  const searchTextValueChanged = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
    if (e.target.value.length > 0) {
      setTextValueDebounced.current(e.target.value)
      props.searchTextChanged(e.target.value)
      setFreeTextSearch(e.target.value)
      setClearLabel(false)
      removeFocus()
    } else {
      clearInput()
      setClearLabel(true)
    }
    setSearchFocus(true, e.target.value)
  }

  const clearInput = () => {
    setFreeTextSearch('')
    props.setNoResultsSearch(false)
    // props.setSearchText(mockdata)
  }

  const removeFocus = () => {
    const results = Array.from(document.querySelectorAll('[id^=result]'))
    results.forEach(item => {
      item.setAttribute('style', '')
    })
  }

  const setTextValueDebounced = useRef(lodash.debounce(handleTextChange, 1000))

  function handleTextChange (value) {
  }

  function handleKeyPress (e) {
    if (e.key === 'Enter') {
      clearInput()
    }
  }

  function showTableResult (item) {
    setSearchFocus(false, '')
    props.searchTableResult(item)
  }

  const handleOnClick = () => {
    setSearchbarFocus(false)
  }
  function getStylesForNoResultsScreen () {
    let style = {'height': '207px', 'position': 'absolute', 'width': '97.5%'}
    return style
  }

  return (
    <div className={`${styles.searchForm}`} id='main'>
      <div className={styles.mainInner} id='mainInner'>
        <form id='searchbox' role='search' className={searchOpen() ? styles.searchStyle : ''} onSubmit={(e) => e.preventDefault()}>
          <div className={styles.searchIcon} >
            <FontAwesomeIcon icon={['fal', 'search']} data-icon-style='fa-input-compare-search' />
          </div>
          <div>
            <div className={styles.searchIconWrapper}>
              <input autoComplete='off'
                type='text'
                id='search_text'
                focusable='true'
                className={styles.searchInput}
                onKeyUp={(e) => keyEvents(e, null, false, 'results0', null, true)}
                onChange={searchTextValueChanged}
                value={freeTextSearch}
                placeholder={props.searchTextLabel}
                aria-label={props.searchTextLabel}
                data-individual='1'
                tabIndex='0'
                onClick={() => setSearchFocus(true, freeTextSearch)}
              />
              {!clearLabel && !(freeTextSearch.length === 0) && <React.Fragment>
                <span role='button' tabIndex='0' onKeyDown={handleKeyPress} title='clear' data-individual='1' onClick={clearInput} className={styles.clearButton}>Clear</span>
              </React.Fragment>
              }
            </div>
          </div>
        </form>
        {props.data && props.data[0] !== 'loader' && props.data.length === 0 && searchbarFocus && props.view === 'Compare' && <NoResults view={props.view} iconName={['fal', 'search']} data={['There are no results for your search ']} iconStyles={'searchIcon'} msgStyles={'searchmsg'} containerHeight={getStylesForNoResultsScreen()} optionalText='true' optionalTextmsg={props.optionalTextmsg || 'Try searching again'} optionalTextClass={'optionaltext'} />}
        {searchbarFocus && props.data && props.data.length > 0 && props.view === 'Compare' && <CompareSearchResult searchTableResult={showTableResult} data={props.data} searchType={props.searchType} />}
      </div>
    </div>
  )
}

Search.propTypes = {
  searchTextLabel: PropTypes.string,
  searchTextChanged: PropTypes.func,
  setNoResultsSearch: PropTypes.func,
  view: PropTypes.string,
  data: PropTypes.array,
  searchTableResult: PropTypes.func,
  rowsAdded: PropTypes.func,
  modelComparisonErrorPopup: PropTypes.func,
  cardCount: PropTypes.number,
  searchType: PropTypes.string,
  optionalTextmsg: PropTypes.string
}
