import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  NewsPreferencesModalBodySubTitle,
  NewsPreferencesModalBodyMainTitle,
  NewsPreferencesModalTitle,
  AllCategories,
  ClearAllPrefrences,
  SelectedCount,
  MaxLimit,
  ClearAll
} from "./Contants";
import styles from "./NewsPreferencesModal.module.scss";
import NewsPreferenceJSON from "./NewsPreference.json";
import {
  deviceType,
  getDeviceType,
  handleWindowResize,
  sortByKey
} from "../../../../utils/utilities";
import CheckBox from "../../../Lib/CheckBox/CheckBox";
import ModalPopUp from "../../../Lib/ModalPopUp/ModalPopUp";
import PrimaryButton from "../../../Lib/Button/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MultiSelectDropDown from "../../../Lib/Dropdown/multi-select-dropdown/MultiSelectDropDown";
import TopCategories from "../TopCategories/TopCategories";

function NewsPreferencesModal (props) {
  const [selectedPreferences, setSelectedPreferences] = useState(
    props.savedPreferences || []
  );
  const [device, setDevice] = useState(getDeviceType());

  const isMobile = deviceType.Mobile === device;

  const closeBtn = document.getElementById('closeButton')
  const cancelButton = document.getElementsByName('cancelButton')

  useEffect(() => {
    handleWindowResize((response) => {
      setDevice(response);
    });
  }, []);

  const handleChangeEvent = (event) => {
    const value = event.target.value;
    if (selectedPreferences.some((x) => x.category === value)) {
      const index = selectedPreferences.findIndex(
        (obj) => obj.category === value
      );

      if (index !== -1) {
        selectedPreferences[index].isSelected =
          !selectedPreferences[index].isSelected;
      }
      setSelectedPreferences([...selectedPreferences]);
    } else {
      const currentPreference = {
        category: value,
        isSelected: true
      };
      setSelectedPreferences([...selectedPreferences, currentPreference]);
    }
  };

  const clearPreferences = () => {
    const updatedPreferences = selectedPreferences.map(item => {
      if (item.isSelected) {
      return { ...item, isSelected: false };
      } else {
        return item;
      }
    });
  setSelectedPreferences([...updatedPreferences])
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      clearPreferences()
    }
  }

  const handleTabFocus = (event) => {
    const clearPreferences = document.getElementById('clearPreferences')
    if (!(getSelectedCount() > 0)) {
      event.preventDefault()
      event.shiftKey ? clearPreferences.focus() : closeBtn.focus()
    }
  }

  const isSelectedCategory = (value) => {
    return selectedPreferences.find((x) => x.category === value)?.isSelected
  }

  const checkIsDisabled = (value) => {
    return !isSelectedCategory(value) && getSelectedCount() > 9
  }

  const getColumns = (groupHeaderName, groupHeaderIndex) => {
    let checkboxItems = [];
    NewsPreferenceJSON[groupHeaderName].map((newsItem, newsIndex) => {
      const { text, value } = newsItem;
      checkboxItems.push(
        <div
          className={`${styles.eachNewsItemCheckbox} ${checkIsDisabled(value) ? styles.checkDisabled : ''}`}
          title={value}
          key={`${groupHeaderIndex}_${newsIndex}`}
        >
          <CheckBox
            id={`chk_${groupHeaderIndex}_${newsIndex}`}
            label={text}
            onCheck={(e) => handleChangeEvent(e)}
            isChecked={
              isSelectedCategory(value)
            }
            isDisabled={checkIsDisabled(value)}
            value={value}
          />
        </div>
      );
    });
    return checkboxItems;
  };

  const getColumnGroups = (groupHeaderName, groupHeaderIndex) => {
    const columns = NewsPreferenceJSON[groupHeaderName];
    let checkboxLists = [];
    if (columns && columns.length > 0) {
      let columnsCount = 3;
      if (device === deviceType.Desktop) columnsCount = 3;
      if (device === deviceType.Ipad) columnsCount = 2;
      const itemsPerColumn = Math.ceil(columns.length / columnsCount);
      for (let i = 0; i < columnsCount; i++) {
        checkboxLists.push(
          <div className={styles.columnGroup} key={`${groupHeaderIndex}_${i}`}>
            {getColumns(groupHeaderName, groupHeaderIndex).slice(
              i * itemsPerColumn,
              (i + 1) * itemsPerColumn
            )}
          </div>
        );
      }
    }
    return checkboxLists;
  };

  const onApplyMultiSelect = (valuesFromDropDown, groupHeaderName) => {
    const selected = valuesFromDropDown.length
    ? valuesFromDropDown.split(",").map((s) => s.trim())
    : [];
    const allNewsitems = NewsPreferenceJSON[groupHeaderName].map(
      (x) => x.value
    );
    allNewsitems.forEach((el) => {
      const index = selectedPreferences.findIndex(
        (obj) => obj.category === el
      );
      const isSelectedValue = selected?.includes(el)
      if (index !== -1) {
        if (isSelectedValue) {
          selectedPreferences[index].isSelected = true
        } else {
          selectedPreferences[index].isSelected = false
        }
      } else {
        const currentPreference = {
          category: el,
          isSelected: isSelectedValue
        };
        selectedPreferences.push(currentPreference)
      }
      setSelectedPreferences([...selectedPreferences]);
    })
  };

  const getMobileView = () => {
    const body = (
      <div className={styles.modalInnerContent}>
        {Object.keys(NewsPreferenceJSON).map(
          (groupHeaderName, groupHeaderIndex) => {
            const items = NewsPreferenceJSON[groupHeaderName].map(
              (x) => x.value
            );
            const selectedValues = selectedPreferences
              .filter((x) => items.includes(x.category) && x.isSelected === true)
              .map((el) => el.category)
              .join(',');
            return (
              <div
                className={styles.groupHeaderItemContainer}
                key={`${groupHeaderIndex}`}
              >
                <div className={styles.groupHeadersText}>{groupHeaderName}</div>
                <div
                  key={groupHeaderIndex}
                  className={`${styles.multiDropDownContentContainer} multidropDownContent`}
                  value={groupHeaderIndex}
                >
                  <MultiSelectDropDown
                    data={NewsPreferenceJSON[groupHeaderName]}
                    onApply={(valuesFromDropDown) =>
                      onApplyMultiSelect(valuesFromDropDown, groupHeaderName)
                    }
                    changeHandler={(valuesFromDropDown) => onApplyMultiSelect(valuesFromDropDown, groupHeaderName)}
                    placeholder={`Select ${groupHeaderName}`}
                    showApplyResetButtons
                    disableAllSelection
                    selectedValue={selectedValues}
                  />
                </div>
              </div>
            );
          }
        )}
      </div>
    );
    return body;
  };

  const getTopCategories = () => {
    const viewedCategories = selectedPreferences.filter(
      (it) => it.articleCount > 0
    );
    const topMostCategories =
      viewedCategories?.length > 0 &&
      sortByKey(viewedCategories, "articleCount", true);
    const htmlBody =
      topMostCategories?.length > 0 ? (
        <TopCategories
          topMostCategories={topMostCategories.slice(0, 4)}
          handleChangeEvent={handleChangeEvent}
          checkIsDisabled={checkIsDisabled}
        />
      ) : (
        ""
      );
    return htmlBody;
  };

  const getDesktopIpadView = () => {
    const body = (
      <div className={styles.modalInnerContent}>
        {Object.keys(NewsPreferenceJSON).map(
          (groupHeaderName, groupHeaderIndex) => {
            return (
              <div
                className={styles.groupHeaderItemContainer}
                key={`${groupHeaderIndex}`}
              >
                <div className={styles.groupHeadersText}>{groupHeaderName}</div>
                <div className={styles.groupHeadersCheckboxContainer}>
                  {getColumnGroups(groupHeaderName, groupHeaderIndex)}
                </div>
              </div>
            );
          }
        )}
      </div>
    );
    return body;
  };

  const getSelectedCount = () => {
    const count = selectedPreferences.filter(
      (it) => it.isSelected === true
    );
    return count.length
  }

  return (
    <div className={styles.newsPreferencesModalContainer}>
      <ModalPopUp customHeader customBody hideClose>
        <div className={styles.modalHeader}>
          <div className={styles.modalHeaderText}>
            <div
              className={`${styles["modalCloseIcon"]} ${styles["modalHeaderCloseIcon"]}`}
            >
              <div>{NewsPreferencesModalTitle}</div>
              {props.isEdit ? (
                <button
                  className={styles.btnPopUpClose}
                  alt="Close"
                  id="closeButton"
                  aria-label="Close"
                  onClick={props.onClose}
                  tabIndex={0}
                >
                  <FontAwesomeIcon
                    icon={["fal", "times"]}
                    className={styles.btnPopUpClose}
                    id="closeButtonFontAwsome"
                  />
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className={styles.modalContentContainer}>
          <div className={styles.modalInnerContentContainer}>
            <div className={styles.modalContentMainTitle}>
              {NewsPreferencesModalBodyMainTitle}
            </div>
            <div
              className={styles.modalContentSubTitle}
              dangerouslySetInnerHTML={{
                __html: NewsPreferencesModalBodySubTitle
              }}
            />
            {getTopCategories()}
            <div className={styles.allCategoryLabel}>{AllCategories}</div>
            {!isMobile && getDesktopIpadView()}
            {isMobile && getMobileView()}
          </div>
          <footer className={styles.footerContainer}>
            <div className={`${styles.selectedCount} ${getSelectedCount() > 10 ? styles.errorCount : ''}`}>{`${getSelectedCount()}${getSelectedCount() < 10 ? SelectedCount : MaxLimit}`}</div>
            <div className={styles.buttonLabels}>
              <div className={`${styles.clearButton} ${getSelectedCount() > 0 ? styles.active : styles.disabled}`} role="button" tabIndex={0} id='clearPreferences' onClick={clearPreferences} onKeyDown={handleKeyDown}>
                {isMobile ? ClearAll : ClearAllPrefrences}
                </div>
              <div className={styles.buttons}>
              <button
                  className={styles.cancelButton}
                  alt="Cancel"
                  id="closeButton"
                  name="cancelButton"
                  onClick={props.onClose}
                  onKeyDown={(e) => e.key ==='Tab' ? handleTabFocus(e) : props.onClose}
                  tabIndex={0}
                >Cancel</button>
                <PrimaryButton
                  buttonText={"Apply"}
                  clickHandler={() =>
                    props.applyPreferences(selectedPreferences)
                  }
                  tabIndex={0}
                  closeBtn={closeBtn}
                  cancelBtn={cancelButton}
                  disabled={!(getSelectedCount() > 0) || getSelectedCount() > 10}
                />
              </div>
            </div>
          </footer>
        </div>
      </ModalPopUp>
    </div>
  );
}

export default NewsPreferencesModal;
NewsPreferencesModal.propTypes = {
  savedPreferences: PropTypes.array,
  applyPreferences: PropTypes.func,
  isEdit: PropTypes.bool,
  onClose: PropTypes.func
};
