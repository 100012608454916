import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Loader from '../../../../../Lib/common/Loader'
import Nodata from '../../../../../Lib/NoData/NoData'
import styles from './CarbonRiskScore.module.scss'
import CarbonRiskScoreBreakdownChart from './CarbonRiskScoreBreakdownChart/CarbonRiskScoreBreakdownChart'
import CarbonRiskScoreHeader from './CarbonRiskScoreHeader/CarbonRiskScoreHeader'
import CarbonRiskScoreRating from './CarbonRiskScoreRating/CarbonRiskScoreRating'
import { getESGCarbonRiskData } from './commonMethods'

export default function CarbonRiskScore ({xid, isNXI=false}) {
  const [isReady, setIsReady] = useState(false || !xid)
  const [riskData, setRiskData] = useState()

  useEffect(() => {
    setESGCarbonMetricsData(xid)
  }, [xid])

  const setESGCarbonMetricsData = (xid) => {
    let callback = (response) => {
      setIsReady(false)
      if (!response.isDataRequestComplete) {
        return
      }
      if (!response.isError && response.data) {
        const fundRiskData = response.data
        setRiskData(fundRiskData)
      } else {
        setRiskData()
      }
      setIsReady(true)
    }
    getESGCarbonRiskData(xid, callback)
  }

  return (<div className={isNXI?styles.NXICarbonRiskScoreContainer:styles.carbonRiskScoreContainer}>
    <Loader spinnerSize={'2x'} ready={isReady} >
      {riskData ? <div>
        <div className={styles.heading}>{'Carbon Risk Score'}</div>
        <CarbonRiskScoreHeader data={riskData} isNXI={isNXI} />
        <CarbonRiskScoreRating data={riskData} isNXI={isNXI} />
        <CarbonRiskScoreBreakdownChart data={riskData} isNXI={isNXI} />
      </div> : <Nodata />}
    </Loader>
  </div>
  )
}

CarbonRiskScore.propTypes = {
  xid: PropTypes.number,
  isNXI: PropTypes.bool
}
