/* eslint-disable no-unused-vars */
/**
 * f2Constants.js defines constant values used for F2
 */

// Defines F2 app ids

// These are defined in parent and if changes/modifications are required then please update in the parent
import {APP_IDS as PARENT_APP_IDS, EVENT_NAMES as PARENT_EVENT_NAMES,
  TEST_CENTER_F2_APPS as PARENT_TEST_CENTER_F2_APPS} from '../../../../../utils/f2Constants'

export const APP_IDS = {
  COM_PERSHING_NETXWEALTH_NEWS_HEADERTOPSTORIES: 'com_pershing_netxwealth_news_headertopstories',
  COM_PERSHING_NETXWEALTH_RESEARCH_LIST: 'com_pershing_netxwealth_research_list',
  COM_PERSHING_NETXWEALTH_NEWS_ARTICLELIST: 'com_pershing_netxwealth_news_articlelist',
  COM_PERSHING_NETXWEALTH_NEWS_FILTER: 'com_pershing_netxwealth_news_filter',
  COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR: 'com_pershing_netxwealth_stock_quote_bar',
  COM_PERSHING_NETXWEALTH_STOCK_HIGHLIGHTS: 'com_pershing_netxwealth_stock_highlights',
  COM_PERSHING_NETXWEALTH_TODAY_EVENTS_NEWS_RESEARCH: 'com_pershing_netxwealth_today_events_news_research',
  COM_PERSHING_NETXWEALTH_MARKETS_UNUSUAL_VOLUME: 'com_pershing_netxwealth_markets_unusual_volume',
  COM_PERSHING_NETXWEALTH_MARKETS_GAINERS_AND_LOSERS: 'com_pershing_netxwealth_markets_gainers_and_losers',
  COM_PERSHING_NETXWEALTH_MARKET_MOSTACTIVE: 'com_pershing_netxwealth_markets_mostactive',
  COM_PERSHING_NETXWEALTH_MARKETS_PERFORMANCE: 'com_pershing_netxwealth_markets_performance',
  COM_PERSHING_NETXWEALTH_MARKETS_HIGHLIGHTS: 'com_pershing_netxwealth_markets_highlights',
  COM_PERSHING_NETXWEALTH_STOCK_NEWS_ARTICLELIST: 'com_pershing_netxwealth_stock_news_articlelist',
  COM_PERSHING_NETXWEALTH_STOCK_PEER_NEWS: 'com_pershing_netxwealth_stock_peer_news',
  COM_PERSHING_NETXWEALTH_DIVIDEND_DETAILS: 'com_pershing_netxwealth_dividend_details',
  COM_PERSHING_NETXWEALTH_YEARLY_PAYMENT_TREND: 'com_pershing_netxwealth_yearly_payment_trend',
  COM_PERSHING_NETXWEALTH_DIVIDEND_GROWTH: 'com_pershing_netxwealth_dividend_growth',
  COM_PERSHING_NETXWEALTH_STOCK_PERFORMANCE: 'com_pershing_netxwealth_stock_performance',
  COM_PERSHING_NETXWEALTH_STOCK_COMPANY_DESCRIPTION: 'com_pershing_netxwealth_stock_company_description',
  COM_PERSHING_NETXWEALTH_STOCK_PRICE_CHARTS: 'com_pershing_netxwealth_stock_price_charts',
  COM_PERSHING_NETXWEALTH_KEY_MARKETS_TREASURY_BILL: 'com_pershing_netxwealth_key_markets_treasury_bill',
  COM_PERSHING_NETXWEALTH_MARKET_EVENTS_CALENDAR: 'com_pershing_netxwealth_market_events_calendar',
  COM_PERSHING_NETXWEALTH_STOCK_REVENUE: 'com_pershing_netxwealth_stock_revenue',
  COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH: 'com_pershing_netxwealth_universalsearch',
  COM_PERSHING_NETXWEALTH_MARKET_EVENTS_CURRENCY_COVERSION: 'com_pershing_netxwealth_market_events_currency_conversion',
  COM_PERSHING_NETXWEALTH_STOCK_VALUATION: 'com_pershing_netxwealth_stock_valuation',
  COM_PERSHING_NETXWEALTH_HISTORICAL_PRICES: 'com_pershing_netxwealth_key_historical_prices',
  COM_PERSHING_NETXWEALTH_STOCK_EARNINGS_AND_ESTIMATES: 'com_pershing_netxwealth_stock_earnings_and_estimates',
  COM_PERSHING_NETXWEALTH_STOCK_DIVIDEND_PAYOUT: 'com_pershing_netxwealth_stock_dividend_payout',
  COM_PERSHING_NETXWEALTH_ETF_SNAPSHOT: 'com_pershing_netxwealth_etf_snapshot',
  COM_PERSHING_NETXWEALTH_STOCK_HISTORICAL_DIVIDEND_CHART: 'com_pershing_netxwealth_stock_historical_dividend_chart',
  COM_PERSHING_NETXWEALTH_PEER_ANNUAL_YIELDS: 'com_pershing_netxwealth_peer_annual_yields',
  COM_PERSHING_NETXWEALTH_DIVIDEND_PAYOUT_CALENDAR_YEAR: 'com_pershing_netxwealth_dividend_payout_calendar_year',
  COM_PERSHING_NETXWEALTH_STOCK_PROFIT_MARGINS: 'com_pershing_netxwealth_stock_profit_margins',
  COM_PERSHING_NETXWEALTH_STOCK_EFFECTIVENESS: 'com_pershing_netxwealth_stock_effectiveness',
  COM_PERSHING_NETXWEALTH_STOCK_PEER_VALUATIONS: 'com_pershing_netxwealth_stock_peer_valuations',
  COM_PERSHING_NETXWEALTH_STOCK_LEVERAGERATIOS: 'com_pershing_netxwealth_stock_leverageratios',
  COM_PERSHING_NETXWEALTH_STOCK_VALUATION_MEASURES: 'com_pershing_netxwealth_stock_valuation_measures',
  COM_PERSHING_NETXWEALTH_STOCK_PEER_GROWTH_RATES: 'com_pershing_netxwealth_stock_peer_growth_rates',
  COM_PERSHING_NETXWEALTH_STOCK_PEER_PE: 'com_pershing_netxwealth_stock_peer_pe',
  COM_PERSHING_NETXWEALTH_STOCK_GROWTH_MEASURES: 'com_pershing_netxwealth_stock_growth_measures',
  COM_PERSHING_NETXWEALTH_STOCK_CASHFLOW_TRENDS: 'com_pershing_netxwealth_stock_cashflow_trends',
  COM_PERSHING_NETXWEALTH_STOCK_ONE_YEAR_TARGET_PRICE: 'com_pershing_netxwealth_stock_one_year_target_price',
  COM_PERSHING_NETXWEALTH_LONG_TERM_GROWTH_RATES: 'com_pershing_netxwealth_long_term_growth_rates',
  COM_PERSHING_NETXWEALTH_STOCK_EARNINGS_ESTIMATES: 'com_pershing_netxwealth_stock_earnings_estimates',
  COM_PERSHING_NETXWEALTH_EARNINGS_ESTIMATES_ACTUAL: 'com_pershing_netxwealth_earnings_estimates_actual',
  COM_PERSHING_NETXWEALTH_REVENUE_ESTIMATES_AND_ACTUALS: 'com_pershing_netxwealth_revenue_estimates_and_actuals',
  COM_PERSHING_NETXWEALTH_STOCK_CASH_FLOW: 'com_pershing_netxwealth_stock_cash_flow',
  COM_PERSHING_NETXWEALTH_STOCK_BALANCE_SHEET: 'com_pershing_netxwealth_stock_balance_sheet',
  COM_PERSHING_NETXWEALTH_SEC_FILINGS: 'com_pershing_netxwealth_sec_filings',
  COM_PERSHING_NETXWEALTH_STOCK_BALANCE_SHEET_TOTAL_ASSETS: 'com_pershing_netxwealth_stock_balance_sheet_total_assets',
  COM_PERSHING_NETXWEALTH_STOCK_BALANCE_SHEET_TOTAL_LIABILITIES: 'com_pershing_netxwealth_stock_balance_sheet_total_liabilities',
  COM_PERSHING_NETXWEALTH_STOCK_BALANCE_SHEET_TOTAL_SHAREHOLDER_EQUITY: 'com_pershing_netxwealth_stock_balance_sheet_total_shareholder_equity',
  COM_PERSHING_NETXWEALTH_STOCK_CASH_FLOW_CASH_DIVIDEND_PAID: 'com_pershing_netxwealth_stock_cash_flow_cash_dividend_paid',
  COM_PERSHING_NETXWEALTH_STOCK_CASH_FLOW_FREE_CASH_FLOW: 'com_pershing_netxwealth_stock_cash_flow_free_cash_flow',
  COM_PERSHING_NETXWEALTH_STOCK_CASH_FLOW_OPERATING_CASH_FLOW: 'com_pershing_netxwealth_stock_cash_flow_operating_cash_flow',
  COM_PERSHING_NETXWEALTH_STOCK_INCOME_STATEMENT_TOTAL_NET_INCOME: 'com_pershing_netxwealth_stock_income_statement_total_net_income',
  COM_PERSHING_NETXWEALTH_STOCK_INCOME_STATEMENT_TOTAL_OPERATING_INCOME: 'com_pershing_netxwealth_stock_income_statement_total_operating_income',
  COM_PERSHING_NETXWEALTH_STOCK_INCOME_STATEMENT_TOTAL_REVENUE: 'com_pershing_netxwealth_stock_income_statement_total_revenue',
  COM_PERSHING_NETXWEALTH_FUND_ESG_SCORES: 'com_pershing_netxwealth_fund_esg_scores'
}

// Test Center F2 Apps
export const TEST_CENTER_F2_APPS = {
  ...PARENT_TEST_CENTER_F2_APPS,
  'WorkstreamII': {
    'com_pershing_netxwealth_universalsearch': {
      context: {},
      rootId: 'com_pershing_netxwealth_universalsearch',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_etf_snapshot': {
      context: {symbol: 'IVV', issueType: 'ETF', exchange: 'NASDAQ', xid: '171184', isETF: true, shareName: 'Ishares S&P 500'},
      rootId: 'com_pershing_netxwealth_etf_snapshot',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_fund_esg_scores': {
      context: {symbol: ''},
      rootId: 'com_pershing_netxwealth_fund_esg_scores',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    }
  }
}

// Defines F2 event names
export const EVENT_NAMES = {
  ...PARENT_EVENT_NAMES,
  MOD_GOTO: 'MOD_GOTO'
}
