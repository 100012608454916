/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styles from '../Modal.module.scss'
import { handleWindowResize, getDeviceType } from '../../../../../../../utils/utilities'
export default function ModalBody (props) {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    handleWindowResize((deviceType) => {
      setIsMobile(deviceType === 'MOBILE')
    })
    setIsMobile(getDeviceType() === 'MOBILE')
  }, [])
  return (
    <div className={styles.searchMainModal} style={(props.modalName === 'saveScreenModal' && isMobile) ? {'height': 'calc(100vh - 114px)'} : {}}>
      { props.children }
    </div>
  )
}

ModalBody.propTypes = {
  children: PropTypes.node,
  modalName: PropTypes.string
}
