
export const ETF_RATINGS_CONSTANTS = {
  LABEL_URL_MAPPING: {
    'Overall Rating': {
      API_URL: '',
      INPUT_PARAMS: '',
      IS_RATING: true
    },
    'Overall Risk Rating': {
      API_URL: '',
      INPUT_PARAMS: ''
    },
    'Morningstar Medalist Rating': {
      API_URL: '',
      INPUT_PARAMS: ''
    },
    'Overall Sustainability': {
      API_URL: '',
      INPUT_PARAMS: '',
      IS_RATING: true
    }
  },
  HEADER: 'Ratings'
}
export const OVERALL_MEDALIST_RATING = 'Morningstar Medalist Rating'
