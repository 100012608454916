import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import SparkChart from '../../../Lib/SparkChart/SparkChart'
import styles from './PerformanceChart.module.scss'
import { ChartTimeFrames } from '../../../../utils/appConstants'

const PerformanceChart = (props) => {
  const [performanceChartInput, setPerformanceChartInput] = useState(getChartInputs())

  useEffect(() => {
    const currentData = {...performanceChartInput}
    currentData.instruments[0].venueXid = props.symbol
    setPerformanceChartInput(currentData)
  }, [props.symbol])

  function getChartInputs () {
    return {
      'data': {
        'days': props.days,
        'interval': 5,
        'period': props.isMF && props.days === ChartTimeFrames.Week ? 'day' : 'minute'
      },
      'instruments': [
        {
          'venueXid': props.symbol
        }
      ],
      'height': 72,
      'width': 218,
      'styles': {
        'colors': [
          '',
          '#5F6064',
          '#C0202D',
          '#1D8343',
          '#FF1C1C',
          '#008338',
          '#FFFFFF',
          '#FFFFFF'
        ],
        'background': {
          'colorIndex': 0
        },
        'markers': {
          'price': {
            'primary': {
              'colorIndex': 1,
              'colorIndexNeg': 2,
              'colorIndexPos': 3,
              'colorMode': 'single',
              'width': 1
            },
            'fill': {
              'colorGradientTopPos': 5,
              'colorGradientBottomPos': 7,
              'colorGradientTopNeg': 4,
              'colorGradientBottomNeg': 6,
              'fillType': 'baseLine'
            }
          }
        }
      }
    }
  }
  return (
    <div className={styles.performanceChartContainer} data-value={'spark_chart_container'}>
      <SparkChart sparkChartInput={performanceChartInput} venueXid={props.symbol} isRealTime={props.isRealTime} />
    </div>
  )
}
export default PerformanceChart
PerformanceChart.propTypes = {
  symbol: PropTypes.number,
  days: PropTypes.number,
  isRealTime: PropTypes.bool,
  isMF: PropTypes.bool
}
