/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import PropTypes from 'prop-types'
import styles from '../Modal.module.scss'
import PopupAccessibilty from '../../CustomHook/PopupAccessibilty'
export default function Modal (props) {
  PopupAccessibilty(props)

  return (
    <div id={props.id} className={`${styles[props.id]}`}>
      <div>
        <div className={styles.modalPopUp} style={props.styles}>
          { props.children }
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.object,
  id: PropTypes.string
}
