/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './NewsForYou.module.scss'
import { deviceType, getDeviceType, handleWindowResize, closeModalPopup, hideScroll } from '../../../utils/utilities'
import Carousel from '../../Lib/Carousal/Carousel'
import { formatDate } from '../../../utils/formatter'
import DataRequest from '../../../services/DataRequest'
import { URLS } from '../../../utils/appConstants'
import { registerAccessTokenUpdatedF2OnEvent, emitF2Event } from '../../../utils/f2Methods'
import { getQuoteData } from '../../../shared/dataMethods'
import ModalPopUp from '../../Lib/ModalPopUp/ModalPopUp'
import NewsArticle from '../../News/NewsArticle/NewsArticle'
import Nodata from '../../Lib/NoData/NoData'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { EVENT_NAMES } from '../../../utils/f2Constants'

function NewsForYouSection (props) {
  const articleNewsDetailsAnchor = []
  const [device, setDevice] = useState(getDeviceType())
  const [isDataAvailable, setIsDataAvailable] = useState(props.data.length > 0)
  const [xRefDataList, setXRefDataList] = useState([])
  const [quoteData, setQuoteData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [peerNewsArticle, setPeerNewsArticle] = useState({
    articleNewsModalItem: {},
    availableSymbolList: [],
    articleId: 0,
    isOpenModal: false
  })

  useEffect(() => {
    setIsDataAvailable(props.data.length > 0)
  }, [props.data.length])

  useEffect(() => {
    handleWindowResize((response) => { setDevice(response) })
  }, [])
  useEffect(() => {
    loadNewsData()
    registerAccessTokenUpdatedF2OnEvent(() => {
      loadNewsData()
    })
  }, [props.data])

  const loadNewsData = () => {
    let xrefSymbols = []
    const symbolsList = props.data.map(item => {
      let newsAllSymbols = item.WSODIssue.split('|')

      let counter = 1
      for (let symbol of newsAllSymbols) {
        if (counter > 3) {
          break
        }
        if (!isNaN(symbol) && symbol !== '') {
          xrefSymbols.push(symbol)
          counter++
        }
      }
      return item.WSODIssue
    }).join()
    const symbolsListFiltered = symbolsList.split('|').filter(item => (!isNaN(item) && (item !== ''))).join()
    makeXrefCall(xrefSymbols.join())
    getQuoteSymbolsData(symbolsListFiltered)
  }

  const getSymbolDetailList = (symbolList, issueList, quoteData) => {
    let list = []
    if (symbolList && symbolList.length > 0) {
      const wsodIssues = issueList.split('|')
      wsodIssues.forEach(function (wsodIssue) {
        if (wsodIssue !== '') {
          const itemQuoteData = quoteData.find(item => (item.data && !item.error) && item.data.venueXid === parseInt(wsodIssue))
          if (itemQuoteData) {
            const xrefItem = symbolList.find(item => item.xids && item.xids.venue === parseInt(wsodIssue))
            xrefItem && list.push({ venueXid: parseInt(wsodIssue), Symbol: xrefItem && xrefItem.symbol })
          }
        }
      })
    }
    return list
  }
  const makeXrefCall = (symbols) => {
    let xrefCallback = (response) => {
      if (!response.isError) {
        const responseData = response.data || {}
        setXRefDataList(responseData.items)
      }
    }
    DataRequest.execute(
      URLS.PLATFORM.XREF,
      {
        params: {
          venueXids: symbols
        }
      },
      xrefCallback,
      null
    )
  }

  const getQuoteSymbolsData = (symbols) => {
    let callback = (response) => {
      if (!response.isError) {
        const responseData = response.data || {}
        setQuoteData(responseData.quotes)
      }
      setIsLoading(false)
    }
    getQuoteData(symbols, callback)
  }
  const readNewsArticle = async (article, availableSymbolList, index) => {
    if (!peerNewsArticle.isOpenModal) {
      setPeerNewsArticle({isOpenModal: true, articleNewsModalItem: article, availableSymbolList: availableSymbolList, articleId: article.articleId})
      setExpandedFalse(index)
      hideScroll(true)
    }
  }
  const setExpandedFalse = (index) => {
    if (articleNewsDetailsAnchor[index] && articleNewsDetailsAnchor[index].state) {
      articleNewsDetailsAnchor[index].state.expanded = false
    }
  }
  const closePopup = (e) => {
    if (closeModalPopup(e)) {
      setPeerNewsArticle({isOpenModal: false})
      hideScroll(false)
    }
  }
  const getBody = () => {
    const body = props.data.map((item, idx) => {
      const symbolDL = getSymbolDetailList(xRefDataList, item.WSODIssue, quoteData)
      return (
        <React.Fragment key={item.Headline + idx}>
          <div className={styles.researchContainer}>
            <div className={styles.researchProvider}>
              {`${item.Provider} | ${formatDate(item.DocumentDate, {UTCtoEST: true, postFix: 'ET'})}`}
            </div>
            <div className={styles.researchName} data-testid='newsHeadline'>{item.Headline}
            </div>
            <button className={styles.fotterText} onClick={() => readNewsArticle(item, symbolDL, idx)}>Read Article
            </button>
          </div>
        </React.Fragment>
      )
    })
    return (<div>
      {device === deviceType.Mobile && isDataAvailable &&
      <button className={`${styles.actionButton} ${styles.viewAllButton}`} onClick={handleViewAllClick}>
        <FontAwesomeIcon icon={['far', 'external-link-square-alt']} className={styles.viewAllIcon} />
      </button> }
      {isDataAvailable && body}
      {!isDataAvailable && <Nodata />}
      <div className={styles.editPreferencesButtonContainer}>
        <button className={`${styles.actionButton} ${styles.editPreferencesButton}`} onClick={props.openEditPreferencesModal}>Edit Preferences</button>
      </div>
    </div>)
  }

  const handleViewAllClick = () => {
    emitF2Event(EVENT_NAMES.NEWS_AND_RESEARCH_VIEW_ALL, {isPersonalizedNews: true})
  }

  const getHeader = () => {
    const header =
      <div className={styles.header}>
        News for You
        {isDataAvailable &&
        <button aria-label='View All' className={`${styles.actionButton} ${styles.viewAllButton}`} onClick={handleViewAllClick}>
          {device === deviceType.Desktop && <div className={styles.viewAllLabel}>View All</div> }
          {device === deviceType.Ipad && <FontAwesomeIcon icon={['far', 'external-link-square-alt']} className={styles.viewAllIcon} />}
        </button>}
      </div>
    return header
  }

  return (
    !isLoading && <div className={styles.research}>
      {device === deviceType.Desktop || device === deviceType.Ipad
        ? <div data-testid='newsForyouList'>
          {getHeader()}
          {getBody()}
        </div>
        : <div id='researchCaraousal' className={styles.researchCaraousal} >
          <Carousel id={styles.researchCarouselHeader} label={'News for You'} isTiltEnable>
            {getBody()}</Carousel>
        </div>}
      {peerNewsArticle.isOpenModal &&
        <ModalPopUp
          onClose={closePopup}>
          <NewsArticle articleNewsDetailsData={peerNewsArticle.articleNewsModalItem} availableSymbolList={peerNewsArticle.availableSymbolList} />
        </ModalPopUp>
      }
    </div>
  )
}
NewsForYouSection.propTypes = {
  data: PropTypes.array,
  openEditPreferencesModal: PropTypes.func
}
export default NewsForYouSection
