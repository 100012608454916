import { TYPE_OF_VALUE } from '../../../../utils/appConstants'

export const TOP_FUND_HOLDER_CONSTANTS = {
  FILTERS: [
    {
      key: 'ETF%2CMF',
      value: 'All'
    },
    {
      key: 'ETF',
      value: 'ETF'
    },
    {
      key: 'MF',
      value: 'MF'
    }
  ],
  HEADERS_TABLE: [{
    label: 'Holder',
    map: 'name',
    defaultValue: 'N/A',
    isLink: true,
    isFixed: true
  },
  {
    label: 'fundXid',
    map: 'fundXid',
    isExcluded: true
  },
  {
    label: 'fundType',
    map: 'fundType',
    isExcluded: true
  },
  {
    label: '% of Fund',
    map: 'percentOfPortfolio',
    isDownward: true,
    type: TYPE_OF_VALUE.PERCENTAGE
  },
  {
    label: '% of Shares Outstanding',
    map: 'netPercentOfSharesOut',
    type: TYPE_OF_VALUE.PERCENTAGE,
    isFixedWidth: true
  },
  {
    label: 'Shares Held',
    map: 'numberOfShares',
    type: TYPE_OF_VALUE.NUMBER
  },
  {
    label: 'Changes in Shares Held',
    map: 'shareChange',
    type: TYPE_OF_VALUE.NUMBER,
    isFixedWidth: true,
    isNegative: true
  },
  {
    label: 'Total Value',
    map: 'value',
    type: TYPE_OF_VALUE.NUMBER
  }
  ],
  LIMIT_RECORDS: 10,
  PRECISION: 2,
  HEADER: 'Top Fund Holders'
}

export const API_INPUT_PARAM = {
  COUNTRY: 'US',
  NET_ASSETS: 'Net assets'
}
