'use strict'

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './CheckBox.module.scss'
import ErrorMsg from '../ErrorMsg/ErrorMsg'

export default function CheckBox ({id, index, value, isChecked, label, onCheck, isDisabled, errorMsg, xid, itemIndex, type, moduleName, moduleTypeName}) {
  let [isHover, setIsHover] = useState(false)
  function handleCheckboxChange (e) {
    if (!isDisabled) {
      onCheck(e)
    }
    if (isDisabled && moduleName === 'ViewShareClassModal') {
      onCheck(e)
    }
  }
  // On Focus hide hover message after 1 sec, else it was waiting earlier till anywhere outside we click
  function handleFocus () {
    if (isDisabled) {
      setIsHover(true)
      setTimeout(() => {
        setIsHover(false)
      }, 1000)
    }
  }
  return (
    <div className={styles.checkBoxContainer}
      onFocus={() => handleFocus()}
      onBlur={() => setIsHover(false)}
      onMouseOver={() => isDisabled ? setIsHover(true) : ''}
      onMouseOut={() => setIsHover(false)}
      aria-live='polite' aria-atomic='true' role='log' aria-hidden='false' >
      <label id={'label' + id} className={styles.checkboxLabel} htmlFor={id}>
        <input xid={xid} id={id} data-testid={label} tabIndex={index} value={value} type='checkbox' checked={isChecked} name={label} onChange={(e) => handleCheckboxChange(e)} />
        {(isHover && errorMsg && errorMsg.msg) && <ErrorMsg data={errorMsg.msg} width={errorMsg.width} moduleTypeName={moduleTypeName} iindex={itemIndex} type={type} />}
        <span className={isDisabled ? styles.disabled : ` ${styles['checkmark']}`} />
        <span className={styles.chkLabel}>{label}</span>
      </label>
      {/* <div className={styles.name}>
        {label}
      </div> */}
    </div>
  )
}
CheckBox.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  value: PropTypes.string,
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  onCheck: PropTypes.func,
  moduleTypeName: PropTypes.string,
  isDisabled: PropTypes.bool,
  errorMsg: PropTypes.object,
  xid: PropTypes.number,
  itemIndex: PropTypes.number,
  moduleName: PropTypes.string,
  type: PropTypes.string
}
