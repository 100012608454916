import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './Glossary.module.scss'
import Term from './Term'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NoResults from '../../NoResults/NoResults'

export default function GlossarySearch ({ glossary }) {
  const [query, setQuery] = useState('')
  const [searchedTerm, setSearchedTerm] = useState()
  const [showSearchedTerm, setShowSearchedTerm] = useState(false)
  const [clearLabel, setClearLabel] = useState(false)
  const [showResults, setShowResults] = useState(true)

  function handleKeyPress (e) {
    if (e.key === 'Enter') {
      clearInput()
    }
  }

  const clearInput = () => {
    setQuery('')
  }

  function getStylesForNoResultsScreen () {
    let style = {'height': '264px'}
    return style
  }

  const searchTextValueChanged = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
    if (e.target.value.length > 0) {
      setQuery(e.target.value)
      setShowResults(true)
      setShowSearchedTerm(false)
      setClearLabel(false)
    } else {
      clearInput()
      setClearLabel(true)
    }
  }

  const showSearchedGlossary = (item) => {
    setSearchedTerm(item)
    setShowSearchedTerm(true)
    setShowResults(false)
  }

  const searchResults = glossary.filter(item => {
    if (query === '') {
      return item
    } else if (item.header.toLowerCase().includes(query.toLowerCase())) {
      return item
    }
  })

  const getBody = () => {
    if (searchResults.length > 0) {
      if (query !== '' && showResults) {
        return <div className={styles.searchResultsContainer}><div className={styles.searchResults}>Search Results</div>
          {searchResults.sort((a, b) => a.header < b.header ? -1 : 1).map((item, idx) => {
            return <div role={'button'} tabIndex={-1} onClick={(e) => showSearchedGlossary(item)} onKeyDown={(e) => showSearchedGlossary(item)}
              className={`${(searchResults.length - 1 === idx) ? styles.lastResult : styles.result}`}>{item.header}</div>
          })}
        </div>
      } else if (query === '') {
        return searchResults.sort((a, b) => a.header < b.header ? -1 : 1).map((item) => {
          return <Term term={item} />
        })
      }
    } else {
      return <NoResults iconName={['fal', 'search']} data={['There are no results for your search ']} iconStyles={'searchIcon'} msgStyles={'searchmsg'} optionalText='true' optionalTextmsg={'Try searching again'} optionalTextClass={'optionaltext'} containerHeight={getStylesForNoResultsScreen()} />
    }
  }

  return <div className={styles.esgGlossaryContainer}>
    <div className={styles.searchIconWrapper}>
      <div className={styles.searchIcon} >
        <FontAwesomeIcon icon={['fal', 'search']} data-icon-style='fa-input-compare-search' />
      </div>
      <input autoComplete='off'
        type='text'
        id='search_glossary'
        focusable='true'
        className={styles.searchInput}
        onClick={(e) => searchTextValueChanged(e)}
        onKeyUp={(e) => searchTextValueChanged(e)}
        onKeyPress={(e) => searchTextValueChanged(e)}
        onChange={(e) => searchTextValueChanged(e)}
        onInput={(e) => searchTextValueChanged(e)}
        value={query}
        placeholder={'Search ESG Terms'}
        aria-label={'Search ESG Terms'}
      />
      {!clearLabel && !(query.length === 0) && <React.Fragment>
        <span role='button' tabIndex='0' onKeyDown={handleKeyPress} title='clear' data-individual='1' onClick={clearInput} className={styles.clearButton}>Clear</span>
      </React.Fragment>
      }
    </div>
    {getBody()}
    {showSearchedTerm && <Term term={searchedTerm} />}
  </div>
}

GlossarySearch.propTypes = {
  glossary: PropTypes.array
}
