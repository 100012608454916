import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './EsgGlossary.module.scss'
import LinkButton from '../../../../../Lib/LinkButton/LinkButton'
import ModalPopUp from '../../../../../Lib/ModalPopUp/ModalPopUp'
import CompanyDetailsHeader from '../../../../../Stocks/CompanyDescription/CompanyDetailsHeader/CompanyDetailsHeader'
import { closeModalPopup, hideScroll, getDeviceType, deviceType, handleWindowResize } from '../../../../../../utils/utilities'
import GlossarySearch from './Glossary/GlossarySearch'

export default function EsgGlossary ({glossary, showGlossary, settingShowGlossary, module}) {
  const [openGlossary, setOpenGlossary] = useState(false)
  const [device, setDeviceType] = useState(getDeviceType())

  useEffect(() => {
    handleWindowResize((response) => {
      setDeviceType(response)
    })
  }, [device])

  useEffect(() => {
    if (showGlossary && device === deviceType.Mobile) {
      setOpenGlossary(true)
    }
  }, [showGlossary])

  const closePopup = (e) => {
    if (closeModalPopup(e)) {
      setOpenGlossary(false)
      settingShowGlossary(false)
      hideScroll(false)
    }
  }
  return (
    <div className={styles.glossaryContainer}>
      { (device === deviceType.Mobile && module === 'Screener') ? '' : <div className={styles.glossaryBtn}>
        <LinkButton label={'ESG Glossary'} onClick={() => { setOpenGlossary(true) }} dataTestId={'glossaryBtn'} />
      </div>}
      { openGlossary &&
      <ModalPopUp onClose={closePopup} customHeader addEventListener >
        <CompanyDetailsHeader companyName={'Glossary of ESG Terms'} onClose={closePopup} />
        <GlossarySearch glossary={glossary} />
      </ModalPopUp>
      }
    </div>
  )
}
EsgGlossary.propTypes = {
  glossary: PropTypes.array,
  showGlossary: PropTypes.bool,
  settingShowGlossary: PropTypes.func,
  module: PropTypes.string
}
