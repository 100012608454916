import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ModalPopUp from '../../Lib/ModalPopUp/ModalPopUp'
import PrimaryButton from '../../Lib/Button/PrimaryButton'
import ExitAlert from '../../Lib/ExitAlert/ExitAlert'
import Loader from '../../Lib/common/Loader'
import DataRequest from '../../../services/DataRequest'
import { URLS } from '../../../utils/appConstants'
import WatchlistPopupHeader from '../WatchlistPopupHeader/WatchlistPopupHeader'
import WatchlistTable from '../WatchlistTable/WatchlistTable'
import { WATCHLIST } from '../WatchlistsConstant'
import styles from './AddToExistingWatchlists.module.scss'
import { emitF2Event } from '../../../utils/f2Methods'
import { EVENT_NAMES } from '../../../utils/f2Constants'
import { fireToastMessage } from '../../Lib/CustomToast.js/CustomToast'
function AddToExistingWatchlists ({ symbol, xid, onClose, isOption }) {
  const initialState = () => {
    return {
      isReady: false,
      isError: false,
      watchlists: [],
      selectedWatchlist: []
    }
  }
  const [existingWatchlist, setExistingWatchlist] = useState(initialState())
  let hasDuplicateSymbol = false

  const handleWatchlistSelection = (event) => {
    const watchlistId = event.target.id
    if (event.target.checked) {
      setExistingWatchlist({ ...existingWatchlist, selectedWatchlist: [...existingWatchlist.selectedWatchlist, watchlistId] })
    } else {
      const temp = existingWatchlist.selectedWatchlist.filter((value) => { return value !== watchlistId })
      const updatedList = updateWatchlistWhichHasSymbol(watchlistId, false)

      setExistingWatchlist({ ...existingWatchlist, selectedWatchlist: [...temp], watchlists: [...updatedList] })
    }
  }

  const getAllWatchlists = () => {
    let callback = (response) => {
      if (response.data) {
        const responseData = response.data || {}
        if (responseData) {
          return setExistingWatchlist({ isReady: response.isDataRequestComplete, isError: response.isError, watchlists: [...responseData.items], selectedWatchlist: [] })
        }
      }
      setExistingWatchlist({isReady: response.isDataRequestComplete, isError: response.isError})
    }

    DataRequest.execute(
      URLS.PLATFORM.WATCHLIST,
      null,
      callback
    )
  }

  const updateWatchlistWhichHasSymbol = (watchlistId, hasSymbol) => {
    return existingWatchlist.watchlists.map(watchlist => {
      if (watchlist.id === watchlistId) {
        watchlist.hasSymbol = hasSymbol
      }
      return watchlist
    })
  }

  const addHoldingToWatchlist = (watchlistId) => {
    return new Promise((resolve) => {
      let callback = (response) => {
        if (response.isDataRequestComplete && !response.isError) {
          let msg = `${symbol} added successfully.`
          fireToastMessage({
            message: msg
          })
          emitF2Event(EVENT_NAMES.WATCHLIST_UPDATED, msg)
        }
        if (response.isError) {
          const errorMessage = response.error && response.error.data && response.error.data.errors.length && response.error.data.errors[0].message
          if (errorMessage.includes('already exists')) {
            const updatedList = updateWatchlistWhichHasSymbol(watchlistId, true)
            setExistingWatchlist({ ...existingWatchlist, watchlists: [...updatedList] })
            hasDuplicateSymbol = true
          }
        }
        resolve()
      }

      DataRequest.execute(
        `${URLS.PLATFORM.WATCHLIST}/${watchlistId}/holdings`,
        {
          method: 'POST',
          postData: {
            'holdings': [
              {
                'description': symbol,
                'xid': xid
              }
            ]
          }
        },
        callback
      )
    })
  }

  const addToWatchlistHandler = async () => {
    const promiseArray = []
    await existingWatchlist.selectedWatchlist.forEach((watchlistId) => {
      promiseArray.push(addHoldingToWatchlist(watchlistId))
    })
    Promise.all(promiseArray).then((res) => {
      if (!hasDuplicateSymbol) {
        onClose('Yes')
      }
    })
  }

  useEffect(() => {
    getAllWatchlists()
  }, [])

  if (existingWatchlist.isError) {
    return (
      <ExitAlert confirmation={() => onClose('No')} yesLabelName='Close' message={WATCHLIST.ERROR_MESSAGE} />
    )
  }
  if (existingWatchlist.isReady) {
    return (
      existingWatchlist.watchlists.length
        ? <ModalPopUp hideClose customHeader customBody addEventListener stylesContent={{ 'height': 'auto' }} stylesPopUp={{ width: '480px' }}>
          <WatchlistPopupHeader headerText={WATCHLIST.ADD_TO_EXISTING_WATCHLIST} onClose={onClose} />
          <React.Fragment>
            <WatchlistTable isOption={isOption} watchlists={existingWatchlist.watchlists} handleWatchlistSelection={handleWatchlistSelection} />
            <footer className={styles.footerContainer}>
              <PrimaryButton buttonText={WATCHLIST.ADD_TO_WATCHLIST} clickHandler={addToWatchlistHandler} disabled={existingWatchlist.selectedWatchlist.length === 0} />
            </footer>
          </React.Fragment>
        </ModalPopUp>
        : <ExitAlert confirmation={(e) => (e === 'Yes') ? onClose('CreateNew') : onClose(e)} yesLabelName={WATCHLIST.CREATE_A_NEW_WATCHLIST} noLabelName={'Cancel'} message={WATCHLIST.NO_EXISTING_WATCHCLIST} />

    )
  }
  return (<ModalPopUp hideClose stylesContent={{ 'height': 'auto' }} stylesPopUp={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '480px', minHeight: '200px' }}>
    <Loader ready={existingWatchlist.isReady}
      spinnerSize={'2x'}
      msg={'Loading'}
    />
  </ModalPopUp>)
}

export default AddToExistingWatchlists

AddToExistingWatchlists.propTypes = {
  symbol: PropTypes.string,
  xid: PropTypes.number,
  onClose: PropTypes.func,
  isOption: PropTypes.bool
}
