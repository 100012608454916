/* eslint-disable react/react-in-jsx-scope */
import React, {useState, useEffect, useRef} from 'react'
import Modal from '../Modal/components/Modal'
import ModalBody from '../Modal/components/ModalBody'
import ModalHeader from '../Modal/components/ModalHeader'
import ModalFooter from '../Modal/components/ModalFooter'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from '../../Shared/Modal/Modal.module.scss'

export default function SaveScreenPopup (props) {
  const [errorMsgTxt, setErrorMsgTxt] = useState(props.errorMsg)
  const screenNameText = useRef(null)

  useEffect(() => {
    screenNameText.current.focus()
  }, [])
  useEffect(() => {
    if (props.screenData && props.screenData.isEditMode) {
      document.getElementById('screenNameText').value = props.screenData.defaultName
      document.getElementById('screenDescriptionText').value = props.screenData.defaultDescription
    }
  }, [props.screenData && props.screenData.isEditMode])
  useEffect(() => {
    setErrorMsgTxt(props.errorMsg)
  }, [props.errorMsg])
  return (
    <Modal close={props.close} id='saveScreenModal'>
      <ModalHeader>
        <div className={styles.header}>
          <div className={styles.headerText}>{props.header}</div>
        </div>
        <div className={styles.closeSection}>
          <button className={styles.btnPopUpClose} aria-label='Close' onClick={props.close}>
            <FontAwesomeIcon icon={['fal', 'times']} className={styles.btnPopUpClose} id='closeButtonFontAwsome' />
          </button>
        </div>
      </ModalHeader>
      <ModalBody modalName={'saveScreenModal'}>
        <div className={styles.searchText}>
          <div id='screenLabel' className={styles.lblTemplate}> {props.bodyLabel} </div>
          <input
            ref={screenNameText}
            onChange={props.textChange}
            type='text'
            id='screenNameText'
            aria-labelledby='screenLabel'
            autoComplete='off'
            maxLength='50'
            placeholder='Name (max 50 characters)'
            className={errorMsgTxt !== '' ? styles.templateNameErrorText : styles.templateNameText}
          />
        </div>
        <div className={errorMsgTxt === '' ? styles.errroPlaceholer : styles.errroMsg} aria-live='assertive'>{errorMsgTxt === '' ? '' : <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className={styles.ErrorIcon} />}{errorMsgTxt}</div>
        <input id='isEditMode' type='hidden' value={(props.screenData && props.screenData.isEditMode) || false} />
        <input id='defaultscreenNameText' type='hidden' value={(props.screenData && props.screenData.defaultName) || ''} />
        <textarea id='defaultScreenDescriptionText' style={{display: 'none'}} value={(props.screenData && props.screenData.defaultDescription) || ''} />
        {props.bodyDescription &&
        <div className={styles.searchText}>
          <div id='screenDescriptionLabel' className={styles.lblDescriptionTemplate}> {props.bodyDescription} </div>
          <textarea
            placeholder='Description (max 150 characters)'
            onChange={props.textChange}
            id={'screenDescriptionText'}
            name='screenDescriptionText'
            label={'Description'}
            maxLength='150'
            rows='3'
            className={styles.templateDescriptionText} />
        </div>
        }
      </ModalBody>
      <ModalFooter>
        <div className={styles.footer} >
          <button className={styles.saveBtn} id='doneBtn' onClick={props.saveBtn} >
            Done
          </button>
          <button className={styles.cancelBtn} id='cancelBtn' onClick={props.close} >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

SaveScreenPopup.propTypes = {
  close: PropTypes.func,
  errorMsg: PropTypes.string,
  header: PropTypes.string,
  bodyLabel: PropTypes.string,
  saveBtn: PropTypes.func,
  textChange: PropTypes.func,
  bodyDescription: PropTypes.string,
  screenData: PropTypes.object
}
