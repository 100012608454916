import React, { useState, useEffect} from 'react'
import Table from '../../Shared/Table/Table'
import { formatNumber } from '../../../../../../utils/formatter'
import styles from './AssestAllocationTable.module.scss'
import { URLS, ModuleName, TAB_NAMES } from '../../../../../../utils/appConstants'
import PropTypes from 'prop-types'
import Loader from '../../../../../Lib/common/Loader'
import Table1 from '../../Shared/SimpleTable/Table'
import {deviceType, getDeviceType} from '../../../../../../utils/utilities'
import Nodata from '../../../../../Lib/NoData/NoData'
import { getAPIData } from '../../utils/utilities'
import { EVENT_NAMES } from '../../../../../../utils/f2Constants'
import LinkButton from '../../../../../Lib/LinkButton/LinkButton'
import { emitF2Event } from '../../utils/f2Methods'

const AssetAllocationTable = (props) => {
  const [device, setDevice] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [assetAllocationData, setAssetAllocation] = useState([{
    Asset: undefined,
    long: undefined,
    short: undefined,
    net: undefined,
    longChart: undefined,
    shortChart: undefined
  }])
  const [ipadassetAllocationData, setIpadAssetAllocation] = useState([{
    Asset: undefined,
    long: undefined,
    short: undefined,
    net: undefined,
    longChart: undefined,
    shortChart: undefined
  }])
  const [asset, setAsset] = useState([{

    longChart: undefined,
    shortChart: undefined
  }])
  let color = ['#004480', '#0095FF', '#D97E07', '#00A695', '#AA01AA', '#FF573F', '#9085FF', '#BF360C', '#00AC00']

  function resetStates () {
    setIsLoading(true)
    setIsError(false)
    setAssetAllocation([{
      Asset: undefined,
      long: undefined,
      short: undefined,
      net: undefined,
      longChart: undefined,
      shortChart: undefined
    }])
    setIpadAssetAllocation([{
      Asset: undefined,
      long: undefined,
      short: undefined,
      net: undefined,
      longChart: undefined,
      shortChart: undefined
    }])
    setAsset([{
      longChart: undefined,
      shortChart: undefined
    }])
  }
  useEffect(() => {
    // Whenever fund changes - resets states of each sub-component
    resetStates()
    setDevice(getDeviceType())
    getAssetAllocationTableData(props.xid)
  }, [props.xid])

  let getAssetAllocationTableData = (xid) => {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        const responseData = response.data.items
        let data = responseData.map((item, index) => {
          return {
            color: color[index],
            Asset: formatString(item.type),
            long: item.value.hasOwnProperty('long') ? formatNumber(item.value.long, 0) : '--',
            short: item.value.hasOwnProperty('short') ? formatNumber(item.value.short, 0) : '--',
            net: item.value.net === 0 ? formatNumber(item.value.net, 0) : item.value.net
          }
        })
        let longMax = data.reduce(function (a, b) { return (a.long > b.long) ? a : b })
        let shortMax = data.reduce(function (a, b) { return (a.short > b.short) ? a : b })
        let netMax = data.reduce(function (a, b) { return (a.net > b.net) ? a : b })
        let maximum = Math.max(longMax.long, netMax.net)
        let maximumShortValue = Math.max(shortMax.short, netMax.net)
        let data1 = responseData.map((item, index) => {
          return {
            longChart: item.value.hasOwnProperty('long') ? aggregateLongBar(item.value.long, item.value.net, index, maximum) : aggregateLongBar(0, item.value.net, index),
            shortChart: item.value.hasOwnProperty('short') ? aggregateShortBar(item.value.short, item.value.net, index, maximumShortValue) : aggregateShortBar(0, item.value.net, index)
          }
        })

        setAssetAllocation(data)
        setAsset(data1)
        setIsLoading(false)
        let ipadData = responseData.map((item, index) => {
          return {
            color: color[index],
            Asset: formatString(item.type),
            long: item.value.hasOwnProperty('long') ? formatNumber(item.value.long, 0) : '--',
            short: item.value.hasOwnProperty('short') ? formatNumber(item.value.short, 0) : '--',
            net: item.value.net,
            longChart: item.value.hasOwnProperty('long') ? aggregateLongBar(item.value.long, item.value.net, index, maximum) : aggregateLongBar(0, item.value.net, index),
            shortChart: item.value.hasOwnProperty('short') ? aggregateShortBar(item.value.short, item.value.net, index, maximumShortValue) : aggregateShortBar(0, item.value.net, index)
          }
        })
        setIpadAssetAllocation(ipadData)
      } else {
        setIsError(true)
      }
    }

    getAPIData(xid, URLS.PLATFORM.ASSET_ALLOCATION, callback)
  }

  const formatString = (string) => {
    const word1 = ['usstock', 'usbond']
    const word2 = ['nonusstock', 'nonusbond']
    if (word1.includes(string)) {
      return string[0].toUpperCase() + string[1].toUpperCase() + ' ' + string[2].toUpperCase() + string.slice(3)
    }
    if (word2.includes(string)) {
      return string[0].toUpperCase() + string.substring(1, 3).toLowerCase() + '-' + string.substring(3, 5).toUpperCase() + ' ' + string[5].toUpperCase() + string.slice(6)
    } else {
      return string[0].toUpperCase() + string.slice(1)
    }
  }
  const chartHeader = [
    {
      'name': 'Short',
      'value': 'shortChart',
      'textAlign': 'right'
    },
    {
      'name': 'Long',
      'value': 'longChart',
      'textAlign': 'right'
    }
  ]
  const ipadHeader = [
    {
      'name': '',
      'value': 'Color'
    },
    {
      'name': 'Asset Class',
      'value': 'Asset'
    },
    {
      'name': 'Long (%)',
      'value': 'long'
    },
    {
      'name': 'Short (%)',
      'value': 'short'
    },
    {
      'name': 'Net (%)',
      'value': 'net'
    },
    {
      'name': 'Short',
      'value': 'shortChart'
    },
    {
      'name': 'Long',
      'value': 'longChart'
    }
  ]

  const headers = [
    {
      'name': '',
      'value': 'Color'
    },
    {
      'name': 'Asset Class',
      'value': 'Asset'
    },
    {
      'name': 'Long (%)',
      'value': 'long'
    },
    {
      'name': 'Short (%)',
      'value': 'short'
    },
    {
      'name': 'Net (%)',
      'value': 'net'
    }
  ]

  const aggregateShortBar = (percent, net, index, maximumShortValue) => {
    let shortValue = percent > 0 ? formatNumber(getDynamicWidth(maximumShortValue, percent), { precision: 2, postFix: true, postFixValue: '%' }) : '0%'
    let netValue = net < 0 ? formatNumber(Math.abs(getDynamicWidth(maximumShortValue, net)), { precision: 2, postFix: true, postFixValue: '%' }) : '0%'
    return net < 0
      ? shortNetChart(shortValue, netValue, index, percent)
      : shortChart(shortValue, netValue, index, percent)
  }
  const shortNetChart = (shortValue, netValue, index, percent) => {
    return <div className={styles.leftSection}>
      <div className={styles.netShort} style={{ width: netValue, background: color[index] }} />
      {percent > 0 && <div className={styles.short} style={{ width: shortValue, borderColor: color[index] }} />}
    </div>
  }
  const shortChart = (shortValue, netValue, index, percent) => {
    return <div className={styles.leftSection}>
      {percent > 0 && <div className={styles.short} style={{ width: shortValue, borderColor: color[index] }} />}
    </div>
  }
  const getDynamicWidth = (max, value) => {
    if (max === value) return 100
    if (max >= value) {
      return (value / max) * 100
    }
    return 0
  }
  const aggregateLongBar = (percent, net, index, maximum) => {
    let LongValue = percent > 0 ? formatNumber(getDynamicWidth(maximum, percent), { precision: 2, postFix: true, postFixValue: '%' }) : '0%'
    let netValue = net > 0 ? formatNumber(getDynamicWidth(maximum, net), { precision: 2, postFix: true, postFixValue: '%' }) : '0%'
    return net > 0
      ? longNetChart(LongValue, netValue, index)
      : longChart(LongValue, netValue, index)
  }
  const longNetChart = (LongValue, netValue, index) => {
    return <div className={styles.rightSection}>
      <div className={styles.netLong} style={{ width: netValue, background: color[index] }} />
      <div className={styles.long} style={{ width: LongValue, background: color[index], opacity: '0.25' }} />
    </div>
  }
  const longChart = (LongValue, netValue, index) => {
    return <div className={styles.rightSection}>
      <div className={styles.long} style={{ width: LongValue, background: color[index], opacity: '0.25' }} />
    </div>
  }

  const portfolioHandler = () => {
    emitF2Event(EVENT_NAMES.GOTO_FUND_PROFILE_TAB, {activeTabKey: TAB_NAMES.PORTFOLIO})
  }

  const htmlBody = () => {
    return <div className={styles.tableContainer}>
      <div className={styles.header} id='AssetAllocation' >Asset Allocation</div>
      <div className={styles.chartDiv}> <Table _data={asset} headers={chartHeader} isETF={props.isETF} isOnlyChart fixedColumnCount={1} moduleName={ModuleName.ETF_RESEARCH_SNAPSHOT_ASSET_ALLOCATION} /></div>
      <div className={styles.tablediv}> <Table1 data={assetAllocationData} headers={headers} isOnlyChart={false} isETF={props.isETF} fixedColumnCount={1} /></div>
      <div className={styles.linkButton}>
        <LinkButton label={'Go to ESG Metrics'} onClick={portfolioHandler} />
      </div>
    </div>
  }
  const IpadBody = () => {
    return <div className={styles.tableContainer}>
      <div className={styles.header} id='AssetAllocation' >Asset Allocation</div>
      <Table className={styles.chartDiv}_data={ipadassetAllocationData} headers={ipadHeader} isETF={props.isETF} maxSelectionLength={5} fixedColumnCount={1} moduleName={ModuleName.ETF_RESEARCH_SNAPSHOT_ASSET_ALLOCATION} />
      <div className={styles.linkButton}>
        <LinkButton label={'Go to ESG Metrics'} onClick={portfolioHandler} />
      </div>
    </div>
  }
  if (isError) {
    return (
      <div className={styles.tableContainer} >
        <div className={styles.header} id='AssetAllocation'>Asset Allocation</div>
        <Nodata />
      </div>
    )
  }
  if (isLoading) {
    return (<div className={styles.tableContainer} >
      <div className={styles.header} id='AssetAllocation'>Asset Allocation</div>
      <Loader ready={false} msg={'Loading'} spinnerSize={'2x'} />
    </div>
    )
  }
  return (
    <React.Fragment>
      {(device === deviceType.Ipad)
        ? IpadBody()
        : htmlBody() }
    </React.Fragment>
  )
}
AssetAllocationTable.propTypes = {
  xid: PropTypes.any,
  isETF: PropTypes.bool
}
export default AssetAllocationTable
