import DataRequest from '../../../../../../services/DataRequest'
import { URLS } from '../../utils/appConstants'

export const getESGCarbonMetrics = (xid, callback) => {
  if (xid) {
    DataRequest.execute(
      `${URLS.PLATFORM.MANAGEDFUNDS_MORNINGSTAR_ESG_CARBON_METRICS}`,
      {
        params: {
          venueXid: xid
        }
      },
      callback,
      null
    )
  }
}

export const getESGCarbonRiskData = (xid, callback) => {
  if (xid) {
    DataRequest.execute(
      `${URLS.PLATFORM.MANAGEDFUNDS_MORNINGSTAR_ESG_CARBON_RISK}`,
      {
        params: {
          venueXid: xid
        }
      },
      callback,
      null
    )
  }
}
