import React, { Fragment, useEffect, useState } from 'react'
import DataRequest from '../../../../../../services/DataRequest'
import { DEFAULT_VALUE, URLS, DATE_FORMAT } from '../../utils/appConstants'
import { formatDate } from '../../utils/formatter'
import styles from './SnapshotProfile.module.scss'
import Table from '../../Shared/SimpleTable/Table'
import SimpleModal from '../../Shared/Modal/components/SimpleModal'
import Loader from '../../../../../Lib/common/Loader'
import NoData from '../../../../../Lib/NoData/NoData'
import InvestmentStrategyDecription from './InvestmentStrategyDecription/InvestmentStrategyDecription'
import PropTypes from 'prop-types'
import { getAPIData } from '../../utils/utilities'

const SnapshotProfile = ({xid, isMF = false}) => {
  let [showReadMore, setShowMore] = useState(false)
  let [showModal, setShowModal] = useState(false)
  let [showStrategyModal, setShowStrategyModal] = useState(false)
  const [isLoadingAndErrorForManager, setIsLoadingAndErrorForManager] = useState({ isLoading: true, isError: false })
  const [isLoadingAndErrorForCompanyInfo, setIsLoadingAndErrorForCompanyInfo] = useState({ isLoading: true, isError: false })
  const [isLoadingAndErrorForStrategy, setIsLoadingAndErrorForStrategy] = useState({ isLoading: true, isError: false })
  const [isLoadingAndErrorForPhone, setIsLoadingAndErrorForPhone] = useState({ isLoading: true, isError: false })
  const tableHeaders = [{ name: 'Manager', seprator: true }, { name: 'Tenure' }]
  const spinnerStyle = { 'minWidth': 'auto', 'height': 'auto' }

  const [managerList, setManagerList] = useState({
    managers: [],
    selectedManager: {}
  })
  const [companyInfo, setCompanyInfo] = useState(
    { phone: DEFAULT_VALUE, url: DEFAULT_VALUE }
  )
  const [strategy, setStrategy] = useState('')

  const readMorebuttonhandling = () => {
    let elem = document.getElementsByClassName('strategy')[0]
    if (elem) {
      let str = elem.firstElementChild.firstElementChild.innerText.replace(/\n/g, ' ')
      let len = str.length
      if (len < strategy.length) { setShowMore(true) } else { setShowMore(false) }
    }
  }
  const setDefaultValue = () => {
    setManagerList({
      managers: [],
      selectedManager: {}
    })
    setCompanyInfo({ phone: DEFAULT_VALUE, url: DEFAULT_VALUE })
    setStrategy('')
    setIsLoadingAndErrorForManager({ isLoading: true, isError: false })
    setIsLoadingAndErrorForCompanyInfo({ isLoading: true, isError: false })
    setIsLoadingAndErrorForPhone({isLoading: true, isError: false})
    setIsLoadingAndErrorForStrategy({isLoading: true, isError: false})
  }
  useEffect(() => {
    setDefaultValue()
    getManagerList()
    getStrategy()
    getCompanyInfo()
    // todo PRW-1018 todo will be removed getPhone() after getting phone from company information
    getPhone()
  }, [xid])
  const getManagerList = () => {
    let callbackFunction = (response) => {
      if (!response.isError && response.data) {
        let data = response.data
        // data.managers = []
        if (data.managers && data.managers.length > 0) {
          let managers = data.managers.filter(item => item.managerRole === 'lead manager')
          let selectedManager = managers.length > 0 ? managers[0] : data.managers[0]
          setManagerList({ managers: [...data.managers], selectedManager: selectedManager })
          setIsLoadingAndErrorForManager({ isError: false, isLoading: false })
        } else {
          setIsLoadingAndErrorForManager({ isError: true, isLoading: false })
        }
      } else {
        setIsLoadingAndErrorForManager({ isError: true, isLoading: false })
      }
    }
    getAPIData(xid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_FUND_MANAGEMENT, callbackFunction)
  }
  const getPhone = () => {
    let callbackFunction = (response) => {
      if (!response.isError && response.data) {
        let data = response.data
        if (data.salesArea && data.salesArea.tollFreePhone) {
          companyInfo.phone = data.salesArea.tollFreePhone || DEFAULT_VALUE
          setCompanyInfo({ ...companyInfo })
          setIsLoadingAndErrorForPhone({ isError: false, isLoading: false })
        } else {
          setCompanyInfo({...companyInfo, phone: DEFAULT_VALUE})
          setIsLoadingAndErrorForPhone({ isError: true, isLoading: false })
        }
      } else {
        setCompanyInfo({...companyInfo, phone: DEFAULT_VALUE})
        setIsLoadingAndErrorForPhone({ isError: true, isLoading: false })
      }
    }
    getAPIData(xid, URLS.PLATFORM.MANAGEDFUNDS_MORNINGSTAR_PURCHASE_DETAILS, callbackFunction)
  }
  const getCompanyInfo = () => {
    let callbackFunction = (response) => {
      if (!response.isError && response.data) {
        let data = response.data
        if (data.providerCompanyUrl) {
          companyInfo.url = data.providerCompanyUrl || DEFAULT_VALUE
        }
        //* PRW-1018 data.primaryProvideCompany phone is not availabe
        //* after getting data code will be uncommented.
        // if (data.primaryProviderCompany) {
        //   companyInfo.phone = data.primaryProviderCompany.phone || DEFAULT_VALUE
        // }
        setCompanyInfo({ ...companyInfo })
        setIsLoadingAndErrorForCompanyInfo({ isError: false, isLoading: false })
      } else {
        setCompanyInfo({...companyInfo, url: DEFAULT_VALUE})
        setIsLoadingAndErrorForCompanyInfo({ isError: true, isLoading: false })
      }
    }
    DataRequest.execute(`${URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_COMPANY_INFORMATION}/${xid}`,
      {},
      callbackFunction,
      null)
  }
  const getStrategy = () => {
    let callbackFunction = (response) => {
      if (!response.isError && response.data) {
        let data = response.data
        // data.principalInvestmentObjective = ''
        if (data.principalInvestmentObjective) {
          setStrategy(data.principalInvestmentObjective)
          setIsLoadingAndErrorForStrategy({ isError: false, isLoading: false })
        } else {
          setIsLoadingAndErrorForStrategy({ isError: true, isLoading: false })
        }
      } else {
        setIsLoadingAndErrorForStrategy({ isError: true, isLoading: false })
      }
    }
    DataRequest.execute(`${URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_STRATEGY}/${xid}`,
      {},
      callbackFunction,
      null)
  }

  const clickHandler = (e) => {
    if (e.type === 'click' || e.keyCode === 'Enter') {
      e && e.preventDefault()
      setShowModal(true)
    }
  }
  const modalClose = (e) => {
    e && e.preventDefault()
    setShowModal(false)
  }
  const strategyModalClose = (e) => {
    e && e.preventDefault()
    setShowStrategyModal(false)
  }
  const getTableData = () => {
    return managerList.managers.map(manager => {
      return {
        Manager: manager.givenName + ' ' + manager.familyName, Tenure: getDateFormat(manager.dateStartFund)
      }
    })
  }

  const getDateFormat = (date) => {
    return formatDate(date, { format: DATE_FORMAT.MONTH_DAY_YEAR_DATE })
  }
  const OpenStratedyModal = (e) => {
    if (e.type === 'click' || e.keyCode === 'Enter') {
      e && e.preventDefault()
      setShowStrategyModal(true)
    }
  }

  if (isLoadingAndErrorForManager.isLoading || isLoadingAndErrorForCompanyInfo.isLoading || isLoadingAndErrorForStrategy.isLoading || isLoadingAndErrorForPhone.isLoading) {
    return (
      <div className={styles.profileContainer}>
        <div className={styles.header}>Profile</div>
        <Loader spinnerStyle={spinnerStyle} ready={false} msg={'Loading'} spinnerSize={'2x'} />
      </div>
    )
  }
  if (isLoadingAndErrorForManager.isError && isLoadingAndErrorForCompanyInfo.isError && isLoadingAndErrorForStrategy.isError && isLoadingAndErrorForPhone.isError) {
    return (
      <div className={styles.profileContainer}>
        <div className={styles.header}>Profile</div>
        <NoData />
      </div>
    )
  }

  return (
    <div className={styles.profileContainer}>
      <Fragment>
        <span className={styles.header}>Profile</span>
        <div className={styles.rowContainer}>
          <div className={`${styles.row} ${styles.border}`} style={{ paddingTop: '0' }}>
            <div>
              <div className={styles.fieldName} id='Manager' >
                <div>{isMF ? 'Management' : 'Manager'}</div>
                {!isLoadingAndErrorForManager.isError && managerList.managers.length > 0 && <button className={styles.viewAll} onClick={clickHandler} onKeyDown={clickHandler} aria-label={`View All (${managerList.managers.length})`}>{`View All (${managerList.managers.length})`}</button>}
              </div>
              {!isLoadingAndErrorForManager.isError &&
                <div className={styles.fieldValue}>
                  <div> {`${managerList.selectedManager.givenName} ${managerList.selectedManager.familyName}`} </div>
                  <div className={styles.sinceDate} > {`Since ${getDateFormat(managerList.selectedManager.dateStartFund)}`} </div>
                </div>
              }
              {isLoadingAndErrorForManager.isError &&
                <div className={styles.fieldValue}>
                  <div> {DEFAULT_VALUE} </div>
                </div>
              }
            </div>
          </div>
          <div className={`${styles.row} ${styles.border}`}>
            <div>
              <div className={styles.fieldName} style={{ width: '50%' }} id='investmentStrategy' >
                <div>Investment Strategy</div>
                {!isLoadingAndErrorForStrategy.isError && showReadMore && <button className={`${styles['read-full-story']}`} onClick={OpenStratedyModal} onKeyDown={OpenStratedyModal} >Read More</button>}
              </div>
              {!isLoadingAndErrorForStrategy.isError && strategy.length > 0 &&
                <div className={`${styles.fieldValue} ${styles.fieldValueDisplay}`} style={{ width: '50%' }}>
                  <InvestmentStrategyDecription investmentStrategyDecription={strategy} readMoreHandle={readMorebuttonhandling} />
                </div>
              }
              {isLoadingAndErrorForStrategy.isError &&
                <div className={styles.fieldValue} style={{ width: '50%' }}> {DEFAULT_VALUE}</div>}
            </div>
          </div>

          <div className={`${styles.row} ${styles.border}`}>
            <div className={styles.fieldName} style={{ width: '40%' }} id='phoneNumber' >Phone Number</div>
            <div className={styles.fieldValue} style={{ width: '60%' }}> {`${companyInfo.phone || DEFAULT_VALUE}`} </div>
          </div>
          <div className={styles.row} style={{ paddingBottom: '0' }}>
            <div>
              <div className={styles.fieldName} id='url' >URL</div>
              <div className={styles.fieldValue}>{`${companyInfo.url || DEFAULT_VALUE}`} </div>
            </div>
          </div>
        </div>
        {
          showModal && <SimpleModal header='Management' close={modalClose} id='Management' >
            <Table headers={tableHeaders} data={getTableData()} className={styles.table} />
          </SimpleModal>
        }
        {
          showStrategyModal && <SimpleModal header='Investment Strategy' close={strategyModalClose} id='InvestmentStrategy'>
            <p className={styles.strategy}>{strategy}</p>
          </SimpleModal>
        }
      </Fragment>

    </div>)
}

SnapshotProfile.propTypes = {
  xid: PropTypes.any,
  isMF: PropTypes.bool
}
export default SnapshotProfile
