import React from "react";
import PropTypes from "prop-types";
import styles from "./Circle.scss";

function Circle ({ color }) {
  return (
    <React.Fragment><svg
    className={styles.icon}
    width="10"
    height="10"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      id="Ellipse"
      cx="5.5"
      cy="5.5"
      r="5.5"
      fill={color||"#004480"}
    />
  </svg>
    </React.Fragment>
  );
}

Circle.propTypes = {
  color: PropTypes.string
};
export default Circle;
