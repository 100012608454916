/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ESGScoreBar.module.scss";
import { NXI_COLOR_DEFAULT_SET } from "../../../../utils/appConstants";
import Popover from "../../../Lib/Popover/Popover";
import NoData from "../../../Lib/NoData/NoData";
import Polygone from "../../../../assets/@Icons/Polygone"
import Circle from "../../../../assets/@Icons/Circle"


function ESGScoreBar({ data, length, id, noHeader }) {
  const [factor, setFactor] = useState(0);
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const popoverRef = useRef(null);
  const [scoreArr, setScoreArr] = useState([]);
  const [scoreData, setScoreData] = useState({});
  const [indexType, setIndexType] = useState(undefined);
  const [scoreBarWidth, setScoreBarWidth] = useState(0);
  let scoreId;
  let idsChecked = [];
  const minScoreSpace = 15;
  let prvScorePosition = -1;
  const scoreIconWidth = 38;
  if (!Object.keys(data).length) {
    data.minMax = {
      low: "--",
      high: "--",
      lowLabel: "Low Risk",
      highLabel: "High Risk",
    };
  }
  useEffect(() => {
    setWidth(ref.current.clientWidth);
    setFactor(ref.current.clientWidth / (data.minMax ? data.minMax.high : 100));
    setScoreData(data);
    setScoreArr(
      Object.values(data)
        .filter((item) => !isNaN(parseFloat(item)))
        .sort((a, b) => a - b)
    );
    UpdateZIndexType();
    setScoreBarWidth(width - scoreIconWidth);
  }, [width]);

  const getWidth = (score, idx) => {
    let fromLeft;
    if (data.minMax && data.minMax.low === data.minMax.high) {
      fromLeft = scoreBarWidth / 2 + minScoreSpace * idx;
    } else {
      fromLeft = score * factor * 0.977;
      // Position score pointer
      if (prvScorePosition !== -1) {
        if (fromLeft - prvScorePosition < minScoreSpace) {
          fromLeft += minScoreSpace - (fromLeft - prvScorePosition);
        } else if (
          scoreBarWidth - fromLeft <
          minScoreSpace * (length - (idx + 1))
        ) {
          fromLeft -= minScoreSpace * (length - 1) - (scoreBarWidth - fromLeft);
        }
      }
      // Checking if 'current score' is overlapping or not
      if (
        (indexType === true && idx === 1) ||
        (indexType === false && idx === length - 1)
      ) {
        Math.abs(fromLeft - prvScorePosition) > scoreIconWidth &&
          setIndexType(undefined);
      }
      prvScorePosition = fromLeft;
    }
    return fromLeft;
  };

  const getScoreId = (score) => {
    const keysArr = Object.keys(scoreData).filter((item) =>
      item.match(/^score[\d]$/)
    );
    // eslint-disable-next-line no-unused-vars
    for (const item of keysArr) {
      const _temp = item.match(/\d/g)[0];
      if (scoreData[item] === score && idsChecked.indexOf(_temp) === -1) {
        scoreId = _temp;
        break;
      }
    }
    idsChecked.push(scoreId);
  };

  const UpdateZIndexType = () => {
    if (scoreArr && scoreArr.length > 0) {
      if (scoreArr[0] === scoreData["score0"]) {
        // Check if first score is 'current score'
        setIndexType(true);
      } else if (scoreArr[length - 1] === scoreData["score0"]) {
        // Check if last score is 'current score'
        setIndexType(false);
      }
    }
  };

  const getZIndex = (el, idx) => {
    getScoreId(el);
    let index;

    if (typeof indexType !== "undefined") {
      // Set higher zindex of overlapped score if 'current score' overlapped
      if (indexType) {
        index = length - idx;
      } else {
        index = idx + 1;
      }
    } else {
      // Set default zindex if 'current score' not overlapped
      index = length - scoreId;
    }
    return index;
  };

  function getLabel(value, isHigh) {
    return (
      <span className={styles.minMaxLabel}>
        {!isHigh && " - "}
        {value}
        {isHigh && " - "}
      </span>
    );
  }

  function getValue(value) {
    return (
      <span className={value && value.trim() === "--" && styles.noValue}>
        {value}
      </span>
    );
  }

  function getMinMax() {
    const isMinMaxAvailable = data.minMax && true;
    return (
      <div className={styles.minMaxContainer}>
        <span className={styles.minValue}>
          {isMinMaxAvailable ? getValue(`${data.minMax.low} `) : 0}
          {isMinMaxAvailable &&
            data.minMax.lowLabel &&
            getLabel(data.minMax.lowLabel)}
        </span>
        <span className={styles.maxValue}>
          {isMinMaxAvailable &&
            data.minMax.highLabel &&
            getLabel(data.minMax.highLabel, true)}
          {isMinMaxAvailable ? getValue(`${data.minMax.high}`) : 100}
        </span>
      </div>
    );
  }
  return (
    <div className={styles.scoreBarContainer}>
      <div
        className={`${styles.label} ${noHeader ? styles.hideLabel : ""}`}
        id={data && data.label && data.label.replace(/[^a-zA-Z]/g, "")}
      >
        {data.label}
      </div>
      <div className={styles.bar}>
        {scoreArr.map((el, idx) => (
          <span
            key={idx}
            id={scoreId}
            ref={popoverRef}
            style={{
              left: getWidth(el, idx) + "px",
              marginLeft: (el === 0 || el === "0.00") && -13,
              zIndex: getZIndex(el, idx),
            }}
            className={[styles.valueBar]}
          >
            {scoreId == 0 && (
              <div
                role={"listbox"}
                className={styles.value}
              >
                {el}
              </div>
            )}

            {scoreId == 1 && (
              <span className={styles.icon2} ><Polygone /></span>
            )}
            {scoreId == 2 && <span className={styles.icon3} />}
            {scoreId == 0 && (
              <React.Fragment>
                <Circle />
                <FontAwesomeIcon
                  icon={["fas", "comment-alt"]}
                  color={
                    data["score" + scoreId + "_color"] ||
                    (id === "ESGScores" && scoreId === 1
                      ? NXI_COLOR_DEFAULT_SET[2].value
                      : NXI_COLOR_DEFAULT_SET[scoreId].value)
                  }
                  className={`${styles.icon}`}
                />
              </React.Fragment>
            )}
          </span>
        ))}
      </div>
      {(!length || !scoreArr.length) && <NoData />}
      <hr
        ref={ref}
        className={`${styles.scoreBar} ${
          length && scoreArr.length && styles.scoreBarMarin
        }`}
      />
      {getMinMax()}
    </div>
  );
}

ESGScoreBar.propTypes = {
  data: PropTypes.object,
  length: PropTypes.number,
  id: PropTypes.string,
  noHeader: PropTypes.bool,
};
export default ESGScoreBar;
