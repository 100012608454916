import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './SxSelect.module.module.scss'
import SubMenu from './SubMenu/SubMenu'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {deviceType, getDeviceType} from '../../../../../../utils/utilities'
import { getDistinctValuesFromScreener, getDistinctValuesFromPFScreener } from '../../../../../../shared/dataMethods'
import { convertFromOADate } from '../../utils/formatter'
import {handleAsterisk, updateMarginLeftForScreenerFilters} from '../../Helpers/CommonFunctions'
import moment from 'moment-timezone'
import { SCREENER_TYPES, externalScrollToFiltersList } from '../../../components/utils/appConstants'
class SxSelect extends Component {
  constructor (props) {
    super(props)
    this.state = {
      listOpen: false,
      placeholder: this.props.title,
      showSubMenu: false,
      subMenuData: {selectedParent: props.list[0], items: props.list[0].items, parents: props.list, selectedLevel: 'L2'},
      selectedItems: this.props.resetFilters,
      itemsToBeScrolled: {}
    }
    this.selectRef = React.createRef()
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleSubMenuMouseExit = this.handleSubMenuMouseExit.bind(this)
    this.selectItem = this.selectItem.bind(this)
    this.selectSubItem = this.selectSubItem.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.resetSelectedItems = this.resetSelectedItems.bind(this)
    this.scrollToFilter = this.scrollToFilter.bind(this)
  }

  componentDidMount () {
    let totalSelectedCount = this.props.list.map(item => item.selectedItemCount).reduce((prev, next) => prev + next)
    this.setState({itemsToBeScrolled: {}, placeholder: totalSelectedCount && totalSelectedCount !== '' ? this.props.title + ' (' + totalSelectedCount + ')' : this.props.title})
    if (this.props.list[0] && this.props.list[0].items.length > 0) {
      let items = this.props.list[0].items.filter(x => x.isSelected === true)
      if (items.length > 0) {
        const element = document.getElementById('li-' + this.props.list[0].val)
        const listItem = this.props.list[0]
        const selItem = items
        this.styleSelectedElement(element, listItem, selItem)
      }
    }
  }

  styleSelectedElement (element, listItem, selItem) {
    if (element) {
      element.classList.add('sxSelected')
      element.style.backgroundColor = '#f6f6f6'
      const listNode = element.childNodes
      let listItemToUpdate = 0
      if (this.props.deviceType !== deviceType.Desktop && listNode.length === 2 && listNode[0].tagName === 'svg') {
        listItemToUpdate = 1
      }
      listNode[listItemToUpdate].innerHTML = handleAsterisk(listItem.label) + ' (' + selItem.length + ')'
    }
  }

  selectItem = (event, item) => {
    let nextSibling = event.currentTarget.nextElementSibling
    let previousSibling = event.currentTarget.previousElementSibling
    switch (event.keyCode) {
      case (KEYCODE.DOWN):
        event.preventDefault()
        if (nextSibling) { nextSibling.focus() }
        break
      case (KEYCODE.UP):
        if (previousSibling) { previousSibling.focus() }
        break
      case (KEYCODE.RIGHT):
      case (KEYCODE.ENTER):
        let hasSubMenu = item.items.filter(f => f.control === 'submenu').length > 0
        this.handleMouseEnter(item)
        hasSubMenu && this.focusSubMenuItem(event)
        let hascheckboxorradio = item.items.some(f => f.control === 'checkbox' || f.control === 'radio')
        !hasSubMenu && hascheckboxorradio && this.focusFirstElement(event, item.level)
        break
      case (KEYCODE.LEFT):
        let issubmenu = item.control === 'submenu'
        issubmenu && this.focusParentMenuItem(item)
        break
      default:
        this.handleMouseEnter(item)
    }
    if ((this.props.deviceType === deviceType.Ipad || this.props.deviceType === deviceType.Mobile) && event.currentTarget.parentElement.closest('ul')) {
      event.currentTarget.parentElement.closest('ul').style.display = 'none'
      let parent = event.currentTarget.closest("div[name='sxSelectListsWrapper'")
      if (item.level === 'L1') {
        parent.querySelector('[name=mobileHeaderText]').style.display = ''
        if (parent.querySelector('[name=L2]')) { parent.querySelector('[name=L2]').style.display = '' } else if (parent.querySelector('[name=L3]')) { parent.querySelector('[name=L3]').style.display = '' }
      }
    }
  };

  focusFirstElement = (e, level) => {
    let parentDiv
    if (level === 'L2') {
      parentDiv = e.currentTarget.parentElement.nextElementSibling
      parentDiv && parentDiv.firstElementChild.firstElementChild.focus()
    } else {
      parentDiv = e.currentTarget.parentElement.parentElement.nextElementSibling
      parentDiv && parentDiv.firstElementChild.firstElementChild.focus()
    }
  }

  focusSubMenuItem = (e) => {
    let parentDiv = e.currentTarget.parentElement.parentElement
    parentDiv.nextElementSibling.firstElementChild.focus()
  }
  focusParentMenuItem = (item) => {
    let liElem = document.getElementById(`li-${item.rootFilterId}-${item.parentVal}`)
    liElem && liElem.focus()
  }

  selectSubItem = (event, item, parent, showPill) => {
    // L2/L3 count depending on the levels of filters
    let selectedItems = (item.control === 'rangeSelectorCheckbox' && Array.isArray(item.selectedValue)) ? item.selectedValue.length : parent.items.filter(x => x.isSelected).length
    this.updateSelectedCount(parent, selectedItems)

    // L2 count depending on the levels of filters
    let itemCount = 0
    let l2FilterCount = 0
    // eslint-disable-next-line no-unused-vars
    for (let [index, value] of this.state.subMenuData.parents.entries()) {
      let l3FiltersSelected = 0
      let isSubMenu = false
      // eslint-disable-next-line no-unused-vars
      for (let [index2, subMenu] of value.items.filter(x => x.control === 'submenu').entries()) {
        l3FiltersSelected = l3FiltersSelected + subMenu.items.filter(x => x.isSelected).length
        isSubMenu = true
      }

      if (isSubMenu) {
        this.updateSelectedCount(value, l3FiltersSelected)
        itemCount = itemCount + l3FiltersSelected
      }
      l2FilterCount = l2FilterCount + value.items.filter(x => x.isSelected).length
    }

    itemCount = itemCount + l2FilterCount
    if (event.isValid === false) { showPill = 'no' }
    if (showPill !== 'no') { this.handleChange(item, parent, this.state.subMenuData.parents) }
  }
  updateSelectedCount =(parent, selectedItemCount) => {
    if (parent.pillLabel) {
      parent.pillDisplayLabel = `${parent.pillLabel}`
    } else {
      parent.pillDisplayLabel = `${parent.label}`
    }
    let elementId = `li-${parent.parentVal}-${parent.val}`
    this.UpdateFilterCount(selectedItemCount, elementId, parent.pillDisplayLabel)
  }
  UpdateFilterCount=(selectedItemCount, elementId, label) => {
    if (document.getElementById(elementId) && this.props.deviceType === deviceType.Desktop) {
      document.getElementById(elementId).childNodes[0].innerHTML = handleAsterisk(label) // PRW-1024 & PRW-974

      if (selectedItemCount > 0) {
        document.getElementById(elementId).childNodes[0].innerHTML = `${handleAsterisk(label)} (${selectedItemCount})` // PRW-1024 & PRW-974
      }
    }
  }
  resizeSelectFilters () {
    if (this.selectRef.current) {
      var x = this.selectRef.current
      if (x.style.display !== 'none') {
        if (this.props.deviceType === deviceType.Ipad || this.props.deviceType === deviceType.Mobile) {
          x.style.display = 'flex'
          x.style['flex-direction'] = 'column'
        } else {
          x.style.display = 'flex'
          x.style['flex-direction'] = 'row'
        }
      }
    }
  }

 updateScreenerFiltersContainer = (showContainer) => {
   var screenerFiltersContainer = document.querySelector('#screenerFiltersContainer')
   var filterSectionAlignDiv = document.querySelector('#filterSectionAlign')
   if (screenerFiltersContainer) {
     if (showContainer) {
       if (screenerFiltersContainer.ariaLabel === SCREENER_TYPES.FUND_SCREENER.ARIA_LABEL || screenerFiltersContainer.ariaLabel === SCREENER_TYPES.EQUITY_SCREENER.ARIA_LABEL) {
         screenerFiltersContainer.style.height = '263px'
       }
       if (screenerFiltersContainer.ariaLabel === SCREENER_TYPES.AI_SCREENER.ARIA_LABEL) {
         filterSectionAlignDiv.style.height = '275px'
       }
     } else {
       if (screenerFiltersContainer.ariaLabel === SCREENER_TYPES.FUND_SCREENER.ARIA_LABEL || screenerFiltersContainer.ariaLabel === SCREENER_TYPES.EQUITY_SCREENER.ARIA_LABEL) {
         screenerFiltersContainer.style.height = '68px'
       }
       if (screenerFiltersContainer.ariaLabel === SCREENER_TYPES.AI_SCREENER.ARIA_LABEL) {
         filterSectionAlignDiv.style.height = '68px'
       }
     }
   }
 }

  toggleList = (e, forceOpen) => {
    if (this.selectRef.current) {
      this.selectRef.current.children[0].children[0].children[0].focus()
      var x = this.selectRef.current
      if (forceOpen || x.style.display === 'none' || x.style.display === '') {
        this.updateScreenerFiltersContainer(true)
        if (this.props.deviceType === deviceType.Ipad || this.props.deviceType === deviceType.Mobile) {
          x.style.display = 'flex'
          x.style['flex-direction'] = 'column'
        } else {
          x.style.display = 'flex'
          x.style['flex-direction'] = 'row'
        }
        document.getElementsByName('sxSelectListsWrapper').forEach((child, index) => {
          if (child !== x) {
            child.style.display = 'none'
            child.parentElement.firstElementChild.style.border = ''
          }
        })
        x.parentElement.firstElementChild.style.border = '1px solid #2FDAFF'
        updateMarginLeftForScreenerFilters()
      } else {
        x.parentElement.firstElementChild.style.border = '1px solid #B2B2B2'
        if (e.keyCode === KEYCODE.ENTER || !e.keyCode) {
          x.style.display = 'none'
          this.updateScreenerFiltersContainer(false)
        }
      }
      // codition to load 'Broard Asset Class' sub items on load, while user click of Basic filter first time
      let keysToCallAtUpper = ['PERMFundAssetClassGroup', 'PERMMStarOverrideFundManagerName', 'PERMWeightingTop10Holdings', 'PERMTrailingGrossReturnYTD', 'SPCFNumberofHoldingsNet', 'SPCFAvgCoupon', 'SPCFNAVChgPct', 'SPCFWeightingTop10Holdings', 'PERMNumberofHoldingsNet', 'PERAIAssetType', 'PEREQMarketCap', 'PEREQGrossMarginTTM', 'PEREQPERatio', 'PEREQBeta']
      if (this.state.subMenuData.selectedParent && (keysToCallAtUpper.indexOf(this.state.subMenuData.selectedParent.val) > -1)) {
        this.selectItem(e, this.state.subMenuData.selectedParent)
      }
    }
    if (this.props.list && this.props.list.length > 0) {
      this.props.list.forEach(listItem => {
        let selItem = listItem.items.filter(x => x.isSelected === true)
        if (selItem.length > 0) {
          if (selItem[0].control === 'rangeSelectorCheckbox' && Array.isArray(selItem[0].selectedValue)) {
            selItem = selItem[0].selectedValue
          }
          const element = document.getElementById('li-' + listItem.parentVal + '-' + listItem.val)
          this.styleSelectedElement(element, listItem, selItem)
        }
      })
    }
  }

  resetSelectedItems () {
    this.setState({selectedItems: [], placeholder: this.state.placeholder})
  }

  handleChange (item, selectedParent, parents) {
    this.props.addPill(item, selectedParent, parents, this.props.label)
  }

  handleSubMenuMouseExit () {
    // this.setState({showSubMenu: false})
  }

  handleMouseEnter (item) {
    var selectedItemParents = this.state.subMenuData.parents

    if (item.isDynamicData) {
      let isDynamicDataFromPF = item.control === 'multi-select' || false
      let screenerCallback = (response) => {
        if (response.data) {
          const responseData = response.data && response.data.items
          if (responseData) {
            let minData = responseData[0].min
            let maxData = responseData[0].max
            item.min = responseData[0].min
            item.max = responseData[0].max
            if (item.val === 'PERMInceptionMsDate' || item.val === 'SPCFInceptionDate') {
              maxData = moment().diff(convertFromOADate(responseData[0].min), 'years')
              minData = moment().diff(convertFromOADate(responseData[0].max), 'years')
            }
            if (this.props.updateFilterDynamicData) this.props.updateFilterDynamicData(item, selectedItemParents, minData, maxData, responseData)
            this.setState({subMenuData: {selectedParent: null, items: []}, showSubMenu: false})
            this.updateSelectItemState(item, selectedItemParents)
          }
        }
      }
      let isDataAvailable = item.checkIfDynamicDataFetched && Object.keys(item.items[0]).length > 0

      // skip the api call for 'Broad Asset Class' only, if dynamic data items already avaiable
      let keysToCallAtUpper = ['PERMFundAssetClassGroup', 'PERMMStarOverrideFundManagerName', 'SPCFFundAssetClassGroup']
      if (!isDataAvailable && ((item.isDynamicData === 'true' && !(keysToCallAtUpper.indexOf(item.val) > -1)) ||
          (item.isDynamicData === 'true' && (keysToCallAtUpper.indexOf(item.val) > -1) && Object.keys(item.items[0]).length === 0))) {
        let field = item.val
        if (isDynamicDataFromPF) {
          getDistinctValuesFromPFScreener(field, this.props.screenId, screenerCallback)
        } else {
          getDistinctValuesFromScreener(field, this.props.screenType, screenerCallback)
        }
      } else {
        this.setState({subMenuData: {selectedParent: null, items: []}, showSubMenu: false})
        this.updateSelectItemState(item, selectedItemParents)
      }
    } else {
      this.setState({subMenuData: {selectedParent: null, items: []}, showSubMenu: false})
      this.updateSelectItemState(item, selectedItemParents)
    }
  }

  updateSelectItemState (item, selectedItemParents) {
    if (item.items && item.items.length) {
      this.setState({
        subMenuData: { selectedParent: item, items: item.items, parents: selectedItemParents, selectedLevel: item.level },
        showSubMenu: true
      })
    }
  }

  // Call resizeSelectFilters on device responsiveness
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.deviceType !== this.props.deviceType) {
      this.resizeSelectFilters()
    }
    this.scrollToFilter()
  }

  scrollToFilter () {
    const dict = this.state.itemsToBeScrolled
    for (var key in dict) {
      if (document.getElementById(dict[key])) {
        if ((document.getElementById(dict[key]).parentNode.offsetHeight - 10) < document.getElementById(dict[key]).offsetTop) {
          const topPos = document.getElementById(dict[key]).offsetTop
          document.getElementById(dict[key]).parentNode.scrollTop = topPos - 10
        }
      }
    }
  }

  static getDerivedStateFromProps (props, currentState) {
    let subData = currentState.subMenuData.selectedParent && currentState.subMenuData.selectedParent.val
    const getList = (tlist, state) => {
      let subdata = state.subMenuData
      let parent = state.subMenuData.selectedParent
      if (parent && parent.control && parent.control === 'submenu') {
        let child = tlist.find(x => x.val === subdata.selectedParent.parentVal)
        return child.items.filter(x => x.val === subdata.selectedParent.val)
      }
      return parent && tlist.filter(x => x.val === parent.val)
    }
    let list = getList(props.list, currentState)
    if (subData && list && list[0] && list[0].items !== currentState.subMenuData.items) {
      return {
        listOpen: false,
        placeholder: props.title,
        showSubMenu: false,
        subMenuData: {selectedParent: list[0], items: list[0].items, parents: currentState.subMenuData.parents},
        selectedItems: props.resetFilters,
        device: getDeviceType()
      }
    }
    return null
  }
  getSubMenu=(items, selectedParent) => {
    return items.map((item, index) => {
      let displayLable = item.label
      let selectedClass = selectedParent && (selectedParent.val === item.val || selectedParent.parentVal === item.val) ? `${styles.sxSelected}` : ''
      if (externalScrollToFiltersList.includes(item.rootFilterId) && selectedClass !== '' && item.val !== selectedParent.val) {
        // Keeping a dictionary else so many items keeps on adding
        // Here, maintaining now only 1 latest item selected for particular filter
        this.state.itemsToBeScrolled[item.rootFilterId] = `li-${item.val}-${selectedParent.val}`
      }
      let classes = `${styles.sxListItem} ${selectedClass}`

      if (item.selectedItemCount > 0) displayLable = `${item.label} (${item.selectedItemCount})`
      return <li
        className={classes}
        role='menuitem'
        key={index}
        aria-label={item.label}
        aria-haspopup='true'
        tabIndex='-1'
        id={`li-${item.parentVal}-${item.val}`}
        onKeyDown={(e) => this.selectItem(e, item)}
        onClick={(e) => this.selectItem(e, item)}
        data-individual='1'
      ><span className={styles.sxListItemLabel}><span> { displayLable.includes('**') ? <React.Fragment>{displayLable.replace('**', '')}<sup>**</sup></React.Fragment> : displayLable.includes('*') ? <React.Fragment>{displayLable.replace('*', '')}<sup>*</sup></React.Fragment> : displayLable} </span> </span>
        {(item.items && item.items.length)
          ? <FontAwesomeIcon icon={['far', 'chevron-right']} className={styles.gt} /> : null}
      </li>
    })
  }

  getMobileSelectedLabelView=(items, selectedParent) => {
    let item = {}
    let displayLabel = ''
    let parentlabel = ''
    if (selectedParent.control === 'submenu') {
      item = selectedParent
      let parent = this.state.subMenuData.parents.filter(x => x.val === selectedParent.parentVal)
      displayLabel = `${parent[0].label}  / ${item.label}`
      if (parent.length > 0) parentlabel = parent[0].label + (parent[0].selectedItemCount > 0 ? ` (${parent[0].selectedItemCount})` : '')
      if (item.selectedItemCount > 0) { displayLabel = `${parentlabel} / ${item.label} (${item.selectedItemCount})` } else if (parent.length > 0) { displayLabel = parentlabel }
    } else {
      item = selectedParent
      displayLabel = item.label
      if (item.selectedItemCount > 0) displayLabel = `${item.label} (${item.selectedItemCount})`
    }
    return (
      <li
        className={`${styles.sxListItem}`}
        role='menuitem'
        key={`li-${item.parentVal}-${item.val}`}
        aria-label={item.label}
        aria-haspopup='true'
        tabIndex='-1'
        id={`li-${item.parentVal}-${item.val}`}
        onKeyDown={(e) => this.showFilterItems(e, item, item.level)}
        onClick={(e) => this.showFilterItems(e, item, item.level)}
        data-individual='1'
      >
        <FontAwesomeIcon icon={['far', 'chevron-left']} className={styles.lt} />
        <span> { displayLabel.includes('**') ? <React.Fragment>{displayLabel.replace('**', '')}<sup>**</sup></React.Fragment> : displayLabel.includes('*') ? <React.Fragment>{displayLabel.replace('*', '')}<sup>*</sup></React.Fragment> : displayLabel} </span>
      </li>)
  }

  showFilterItems=(event, item, level) => {
    let parent = event.currentTarget.closest("div[name='sxSelectListsWrapper'")
    if (level === 'L1') {
      parent.querySelector('[name=L1]').style.display = ''
      if (parent.querySelector('[name=L2]')) { parent.querySelector('[name=L2]').style.display = 'none' }
      if (parent.querySelector('[name=L3]')) { parent.querySelector('[name=L3]').style.display = 'none' }
      event.currentTarget.parentElement.closest('ul').style.display = 'none'
    } else if (level === 'L2') {
      parent.querySelector('[name=L1]').style.display = 'none'
      if (parent.querySelector('[name=L2]')) { parent.querySelector('[name=L2]').style.display = '' }
      if (parent.querySelector('[name=L3]')) { parent.querySelector('[name=L3]').style.display = 'none' }
      item = this.state.subMenuData.parents.filter(x => x.val === item.parentVal)[0]
      if (item.items && item.items.length) {
        this.setState({
          subMenuData: {selectedParent: item, items: item.items, parents: this.state.subMenuData.parents, selectedLevel: 'L2'},
          showSubMenu: true
        })
      }
    } else if (level === 'L3') {
      parent.querySelector('[name=L1]').style.display = 'none'
      if (parent.querySelector('[name=L2]')) { parent.querySelector('[name=L2]').style.display = 'none' }
      if (parent.querySelector('[name=L3]')) { parent.querySelector('[name=L3]').style.display = '' }
    }
  }

  getFilterControls=(items, level) => {
    return (<div name='L3' className={styles.sxSubMenuWrapper} style={{ display: 'block',
    // Setting height for technical indicators only as they have dropdown, in mobile setting additional height, so that, all opened options are visible
      height: items && Array.isArray(items.items) && items.items.length > 0 && items.items[0].rootFilterId === 'technicalIndicators-select' ? '250px' : 'auto',
      width: level === 'L3' ? '100%' : '100%'}}><SubMenu key='sub-filter-list' deviceTypeItem={this.props.deviceType} data={items} reset={this.resetSelectedItems} selectChildRef={this.selectChildRef} handleMouseLeave={this.handleSubMenuMouseExit} handleClick={this.selectSubItem} /></div>)
  }
  itemsHtml = (data, handleMouseLeave, handleClick, reset) => {
    if (data.items.some(e => e.control === 'submenu')) {
      return (<ul name='L2' className={styles.sxDropdownList} >
        {this.getSubMenu(data.items, data.selectedParent)}
      </ul>
      )
    } else if (data.selectedParent && data.selectedParent.control && data.selectedParent.control === 'submenu') {
      var filters = data.parents.find(parent => parent.val === data.selectedParent.parentVal)
      return (<div role='presentation' className={styles.sxSubMenuWrapper} style={{width: '100%'}}><ul name='L2' style={(this.props.deviceType === deviceType.Mobile || this.props.deviceType === deviceType.Ipad) ? {display: 'none'} : {display: ''}} className={styles.sxDropdownList} >{this.getSubMenu(filters.items, data.selectedParent)}</ul>
        {this.getFilterControls(data, 'L3')}
      </div>
      )
    } else if (data.items.some(e => e.control !== 'submenu' && e.level === 'L2' && (this.props.deviceType === deviceType.Mobile || this.props.deviceType === deviceType.Ipad))) {
      return this.getFilterControls(this.state.subMenuData, 'L3')
    }

    return this.getFilterControls(this.state.subMenuData)
  }

  render () {
    const {list} = this.props

    return (
      <div className={styles.sxSelectMain} key={this.props.title}>
        <div className={styles.sxSelectWrapper} name={this.props.label}>
          <div className={styles.sxSelectHeader} name={'sxSelectHeader'} aria-label={this.props.label} aria-haspopup='true'onClick={(e) => this.toggleList(e)} onKeyDown={(e) => this.toggleList(e)} role='button' tabIndex='0'>
            <div id={this.props.label} className={styles.sxSelectPlaceholder}>{this.state.placeholder}</div>
            <div className={styles.separatorWrapper} >
              <FontAwesomeIcon icon={['fas', 'caret-down']} />
            </div>
          </div>
          <div className={styles.sxSelectListsWrapper} style={{display: 'none'}} name={'sxSelectListsWrapper'} ref={this.selectRef}>
            <div className={styles.sxSubMenuWrapper}>
              {((this.props.deviceType === deviceType.Ipad || this.props.deviceType === deviceType.Mobile) && this.state.subMenuData.items.length > 0) ? <ul name='mobileHeaderText' style={{height: '38px'}}> {this.getMobileSelectedLabelView(list, this.state.subMenuData.selectedParent)}</ul> : ''}
              {<ul name='L1' className={styles.sxDropdownList} style={(this.props.deviceType === deviceType.Mobile || this.props.deviceType === deviceType.Ipad) ? {display: 'none'} : {display: ''}}>{this.getSubMenu(list, this.state.subMenuData.selectedParent)}</ul>}</div>
            {this.itemsHtml(this.state.subMenuData)}
          </div>
        </div>
      </div>
    )
  }
}
const KEYCODE = {
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
  TAB: 9,
  ENTER: 13
}

SxSelect.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  resetFilters: PropTypes.array,
  label: PropTypes.string,
  addPill: PropTypes.func,
  updateFilterDynamicData: PropTypes.func,
  screenType: PropTypes.string,
  screenId: PropTypes.string,
  deviceType: PropTypes.string
}

export default SxSelect
