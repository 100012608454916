import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './SustainabilityScore.module.scss'
import { formatDate, formatNumber } from '../../../../../../utils/formatter'
import Loader from '../../../../../Lib/common/Loader'
import SustainabilityHeader from './SustainabilityHeader'
import ScoreBar from '../../Shared/ESGScores/ESGScoreBar/ScoreBar'
import { COLOR_DEFAULT_SET } from '../../../../../../utils/appConstants'
import Divider from '../../../../../Lib/Divider/Divider'
import { TYPE_OF_SCORE, getRefinedData } from '../../Shared/CompareProgressBar/ESGCommonMethods'
import Nodata from '../../../../../Lib/NoData/NoData'
import DataRequest from '../../../../../../services/DataRequest'
import { URLS, sustainabilityRating, sustainableInvestment } from '../../utils/appConstants'
// import { propTypes } from 'react-bootstrap/esm/Image'

const SUSTAINABILITY_CONSTANT = {
  CURRENT_SCORE: {
    label: 'Current Corporate Score'
  },
  HISTORICAL_AVERAGE: {
    label: '12 Mon Historical Avg.'
  },
  CATEGORY_AVERAGE: {
    label: 'Category Avg.'
  }
}
export default function SustainabilityScore ({xid, analystRatingsData={}}) {
  const [headerData, setHeaderData] = useState(null)
  const [isReady, setIsReady] = useState(false || !xid)
  const [scoreData, setScoreData] = useState(null)

  useEffect(() => {
    analystRatingsData && Object.keys(analystRatingsData).length > 0 && getData()
  }, [analystRatingsData])

  useEffect(() => {
    scoreData && getSustainableScore(xid)
  }, [scoreData])

  function getSustainableScore (venueXid) {
    function callBack (response) {
      const headerNew = {...headerData}
      headerNew.sustained = '--'
      if (response.data && response.data.sustainableAttribute && response.data.sustainableAttribute.length > 0) {
        headerNew.sustained = response.data.sustainableAttribute
      }
      setHeaderData(headerNew)
    }

    DataRequest.execute(
      `${URLS.CUSTOM.SUSTAINABLE_SCORE}`,
      {
        params: {
          wsodIssue: venueXid
        }
      },
      callBack,
      null
    )
  }

  const getData = () => {
      setIsReady(false)
      if (!analystRatingsData?.isError) {
        let data = analystRatingsData?.data
        const header = {}
        header.rating = data?.fund?.rating?.sustainabilityRating
        header.date = formatDate(data.ratingDate, {format: 'MM/DD/YYYY'})
        setHeaderData(header)
        setScoreData(getRefinedData(data))
      } else {
        setScoreData(null)
      }
      setIsReady(true)
  }

  const getTopBarData = () => {
    let topData = {
      value1: '',
      value2: '',
      value4: ''
    }
    if (headerData) {
      topData = {
        value1: headerData.rating,
        value2: headerData.date,
        value4: headerData.sustained
      }
    }
    return topData
  }
  const getTopBarHeaders = () => {
    return {
      header1: {
        header: 'Sustainability Rating',
        headerDesc: sustainabilityRating
      },
      header2: {
        header: 'Sustainability Rating Date',
        style: {'borderBottom': 'none'}
      },
      header3: {
        header: 'Sustainable Investment',
        headerDesc: sustainableInvestment
      }
    }
  }

  function getHeader (isSoverign) {
    const headerData = JSON.parse(JSON.stringify(SUSTAINABILITY_CONSTANT))
    headerData.CURRENT_SCORE.label = isSoverign ? 'Current Sovereign Score' : headerData.CURRENT_SCORE.label
    return (<div className={styles.headerContainer}>
      {Object.keys(headerData).map((key, idx) => {
        return <div className={styles.labelContainer} key={idx}>
          <div className={styles.circle} style={{background: COLOR_DEFAULT_SET[idx].value}} />
          <div className={styles.labelDesc}>{headerData[key].label}</div>
        </div>
      })}
    </div>)
  }
  function getCorportateSoverignData (key, isSoverign) {
    let resultObj = {}
    const resultObjLength = 3
    if (scoreData && scoreData[key]) {
      resultObj = scoreData[key].reduce((accumulator, val, idx) => {
        accumulator['score' + idx] = formatNumber(val['score'])
        accumulator['score' + idx + '_color'] = val['color']
        return accumulator
      }, {})
      resultObj.ariaLabel = {
        ariaLabel0: isSoverign ? 'Current Sovereign Score' : SUSTAINABILITY_CONSTANT.CURRENT_SCORE.label,
        ariaLabel1: SUSTAINABILITY_CONSTANT.HISTORICAL_AVERAGE.label,
        ariaLabel2: SUSTAINABILITY_CONSTANT.CATEGORY_AVERAGE.label
      }
      resultObj.minMax = {
        low: 0,
        high: '50',
        lowLabel: 'Low Risk',
        highLabel: 'High Risk'
      }
      resultObj.label = key
    }
    return (<div className={styles.scoreBarContainer}>
      <ScoreBar data={resultObj} length={resultObjLength} noHeader />
    </div>)
  }

  function bindCorporateSoverign (key, isSoverign) {
    return <div className={styles.corporateSoverign}>
      {getHeader(isSoverign)}
      {getCorportateSoverignData(key, isSoverign)}
    </div>
  }

  return (
    <Loader ready={isReady} spinnerSize='2x' >
      <div className={styles.sustainabilityScoreContainer}>
        <div className={styles.headerLabel}>
          Sustainability Score
        </div>
        {!scoreData ? <Nodata />
          : <React.Fragment>
            <header className={styles.upperSection}>
              <SustainabilityHeader showGlobe headers={getTopBarHeaders()} data={getTopBarData()} />
            </header>
            <main>
              <div className={styles.corporateSoverignContainer}>
                {bindCorporateSoverign(TYPE_OF_SCORE.CORPORATE, false)}
                <Divider style={{'marginTop': '20px', 'marginBottom': '20px'}} />
                {bindCorporateSoverign(TYPE_OF_SCORE.SOVERIGN, true)}
              </div>
            </main>
          </React.Fragment>
        }
      </div>
    </Loader>

  )
}

SustainabilityScore.propTypes = {
  xid: PropTypes.number.isRequired,
  analystRatingsData: PropTypes.object
}
