import React from 'react'
import PropTypes from 'prop-types'
import styles from './StockHighlightsItemStyles.module.scss'

const StockHighlightsItem = (props) => {
  return (
    <React.Fragment>
      <div className={`${props.hideBorder ? styles.noBorder: ''} ${styles.stockHighlightDataDiv}`}>
        <div className={`${props.hideBorder ? styles.nxiStockHighlightCol1: styles.stockHighlightCol1}`} style={props.keyStyling} id={props.label && props.label.replace(/[^a-zA-Z]/g, '')} >{props.label}</div>
        <div className={styles.stockHighlightCol2} style={props.valueStyling}>{props.value}</div>
      </div>
    </React.Fragment>
  )
}
export default StockHighlightsItem
StockHighlightsItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  valueStyling: PropTypes.object,
  keyStyling: PropTypes.object,
  hideBorder: PropTypes.bool
}
