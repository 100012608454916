/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import 'babel-polyfill'
import React from 'react'
import F2AppContainer from '../../../../F2AppContainer'
import { APP_IDS, EVENT_NAMES } from '../../../../../../utils/f2Constants'
import TokenEvents from '../../../TokenEvents'
import Tabs from '../../../../../Lib/Tabs/Tabs'
import styles from '../../../../ClientApplication/F2ContainerPage.module.scss'
import stylesQuoteResearch from './StockNewsAndResearch.module.scss'
import SymbolSearch from '../../../../../Lib/SymbolSearch/SymbolSearch'
import { emitF2Event, registerF2OnEvent, removeAllApps } from '../../../../../../utils/f2Methods'
import { changeTabHandler, getSymbolFromPath } from '../../../../utils'

class StockNewsAndResearch extends React.Component {
  constructor (props) {
    super(props)
    this.symbolRecvd = this.props.match.params.symbol
    this.state = {
      symbol: 'MSFT',
      period: 'Annual'
    }
  }

  componentDidMount () {
    registerF2OnEvent(EVENT_NAMES.MOD_GOTO, (modGotoContext) => {
      emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, {symbol: modGotoContext.symbol})
    })
  }
  render () {
    return (
      <div data-page='F2AppContainer'>
        <div className={stylesQuoteResearch.header}>
          <span>Quotes & Research</span>

        </div>
        <div>
          <F2AppContainer
            appId={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH}
            rootId={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH} />
        </div>
        <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR} >
          <F2AppContainer
            removeApp
            appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR}
            context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
            rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR} />
        </div>
        <div className={stylesQuoteResearch.quoteAndResearch}>
          <Tabs id={'MainPage_tab'} ariaLabel={'Finanace tabs'} activeTab={'News & Research'} onClick={(k) => { changeTabHandler(k) }}>
            <div key='Snapshot' label='Snapshot' />
            <div key='charts' label='Charting' />
            <div key='Options' label='Options' />
            <div key='dividends' label='Dividends' />
            <div key='NewsAndResearch' label='News & Research'>
              <div className={stylesQuoteResearch.newsResearchContainer}>
                <div className={stylesQuoteResearch.leftSectionContainer}>
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_NEWS_ARTICLELIST} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_NEWS_ARTICLELIST}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd), filter: true, pagination: true }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_NEWS_ARTICLELIST} />
                  </div>
                </div>
                <div className={stylesQuoteResearch.rightSectionContainer} >
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PEER_NEWS} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PEER_NEWS}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PEER_NEWS} />
                  </div>
                </div>
              </div>
            </div>
            <div key='Valuations' label='Valuations' />
            <div key='EarningsAndEstimates' label='Earnings & Estimates' />
            <div key='Fundamentals' label='Fundamentals' />
            <div key='Ownership' label='Ownership' />
          </Tabs>
        </div>
        <TokenEvents />
      </div>
    )
  }
}

export default StockNewsAndResearch
