/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { Children, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './Carousel.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Carousel (props) {
  const [activeIndex, setActiveIndex] = useState(0)
  const iconType = props.isLightIcon ? 'fal' : 'far'
  function goToPrevSlide (e) {
    if (e.cancelable) { e.preventDefault() }
    e && e.stopPropagation()

    let index = activeIndex
    let slidesLength = props.children.length

    if (index < 1) {
      index = slidesLength
    }

    --index

    setActiveIndex(index)
  }

  function goToNextSlide (e) {
    if (e.cancelable) { e.preventDefault() }
    e && e.stopPropagation()
    let index = activeIndex
    let slidesLength = props.children.length - 1

    if (index === slidesLength) {
      index = -1
    }

    ++index

    setActiveIndex(index)
  }
  function handleKeyDownForPrevSlide (e) {
    if (e.key === 'Enter') { goToPrevSlide(e) }
  }
  function handleKeyDownForNextSlide (e) {
    if (e.key === 'Enter') { goToNextSlide(e) }
  }
  function renderCarouselLeftArrow (rightArrowLabel) {
    let leftArrowStyle
    if (!props.isTiltEnable) {
      leftArrowStyle = styles.leftArrow
    }
    if (activeIndex === 0) {
      leftArrowStyle = ' ' + styles.disableArrow
    } else {
      leftArrowStyle = ' ' + styles.enableArrow
    }
    if (props.children) {
      return <span role='button' tabIndex='0' className={leftArrowStyle}
        onClick={goToPrevSlide} onKeyDown={handleKeyDownForPrevSlide} title={rightArrowLabel} >
        <FontAwesomeIcon icon={[iconType, 'chevron-left']} color={activeIndex === 0 ? '#b9b9b9' : '#333333'} />
      </span>
    } else {
      return null
    }
  }

  function renderCarouselRightArrow (label) {
    let rightArrowStyle = { 'paddingLeft': '12px' }
    const rightArrow = (props.children ? <span role='button' tabIndex='0' className={activeIndex === props.children.length - 1 ? styles.disableArrow : styles.enableArrow}
      onClick={goToNextSlide} onKeyDown={handleKeyDownForNextSlide} title={label} style={rightArrowStyle}>
      <FontAwesomeIcon icon={[iconType, 'chevron-right']} color={activeIndex === props.children.length - 1 ? '#b9b9b9' : '#333333'} />
    </span> : null)
    return rightArrow
  }
  function renderCarouselSlide (key, index, currentIndex, slide) {
    const isActive = index === currentIndex
    return (
      <li key={'slide_' + key} active={isActive ? 'true' : null} className={isActive ? `${styles.slider__slide} ${styles.slider__slide__active} ${styles.activeElement} activeCarouselElement` : `${styles.slider__slide} ${styles.inActiveElement}`}>
        {slide}
      </li>
    )
  }
  function renderItems () {
    if (!props.children) {
      return []
    }

    return Children.map(props.children, (slide, index) => {
      if (index === activeIndex) { return renderCarouselSlide(index, index, activeIndex, slide) }
    })
  }

  const label = (props.label ? <span className={styles.headercarousel} style={props.labelStyle}>{props.label}</span> : null)
  const id = props.id
  return (
    <div id={id} className={`${styles.carousel}`} style={props.style} >
      <div data-custom-carousel='custom-carousel-icons-container'>
        {label}
        <span className={`${styles.arrowContainer}`}>
          {renderCarouselLeftArrow('Left')}
          {props.isTiltEnable && <span className={styles.carouseldivider} data-value='customDivider'>|</span>}
          {renderCarouselRightArrow('Right')}
        </span>
      </div>
      <div className={`${styles.slider}`} >
        <ul className={`${styles.slider__slides}`}
        >
          {renderItems()}
        </ul>

      </div>
    </div>
  )
}

export default Carousel
Carousel.propTypes = {
  transitionTime: PropTypes.number,
  children: PropTypes.node,
  label: PropTypes.string,
  labelStyle: PropTypes.object,
  style: PropTypes.object,
  id: PropTypes.string,
  isTiltEnable: PropTypes.bool,
  isLightIcon: PropTypes.bool
}
