import React from 'react'
import FilterBar from './FilterBar'
import NewsFilterJson from '../../../data/NewsFilter.json'

class NewsFilterBar extends React.Component {
  constructor (props) {
    super(props)
    let date = new Date()
    NewsFilterJson.DefaultValuesFilter['Custom Date'].startDate = new Date(date.setDate(date.getDate() - 3))
    NewsFilterJson.DefaultValuesFilter['Custom Date'].endDate = new Date()
    this.state = {
      defaultFilters: NewsFilterJson.DefaultValuesFilter
    }
  }
  render () {
    return <FilterBar data={NewsFilterJson.FilterDataNews} defaultFilters={this.state.defaultFilters} id={'FilterId'} filterOptions={NewsFilterJson} entitlementProviders={NewsFilterJson.ProvidersForEntitlement_128.Provider} />
  }
}

export default NewsFilterBar
