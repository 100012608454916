import React, {useState} from 'react'
import styles from './SymbolSearch.module.scss'
import { emitF2Event } from '../../../utils/f2Methods'
import { EVENT_NAMES } from '../../../utils/f2Constants'

const SymbolSearch = (props) => {
  const [symbol, setSymbol] = useState('')

  const onChange = (e) => {
    setSymbol(e.target.value)
  }

  const emitSymbolChangedEvent = () => {
    emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, {
      symbol: symbol })
  }

  return <div className={styles.symbolSearchContainer}>
    <input type='text' placeholder='Enter Symbol...' value={symbol} onChange={onChange} />
    <input type='button' value='Submit' onClick={emitSymbolChangedEvent} />
  </div>
}

export default SymbolSearch
