import React from 'react'
import styles from './DataUnavailable.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

function DataUnavailable (props) {
  return (
    <div className={styles.dataUnavailableContainer}>
      <div className={styles.iconContainer}><FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className={props.iconClass || `${styles.iconDefault}`} color={props.color || styles.defaultColorIcon} /></div>
      <div className={props.textClass ? props.textClass : styles.dataUnavailableText} data-value={'chartUnavailable'}>{props.text || 'Chart unavailable'}</div>
    </div>

  )
}

export default DataUnavailable
DataUnavailable.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  iconClass: PropTypes.string,
  textClass: PropTypes.string

}
