import React, {useState} from 'react'
import styles from './ChartSection.module.scss'
import ChartistGraph from 'react-chartist'
import Chartist from 'chartist'
import { deviceType, getDeviceType } from '../../utils/utilities'
import PropTypes from 'prop-types'

function ChartSection (props) {
  const [customYAxis, setCustomYAxis] = useState(null)
  let chartData = {
    data: {
      labels: props.chartData && props.chartData.chartLabels,
      series: [
        props.chartData && props.chartData.chartValues
      ]
    },
    options: setchartOptions(),
    listener: setListener()
  }
  let yAxisLabels = []
  function setchartOptions () {
    return {
      seriesBarDistance: 12,
      showLine: true,
      width: '100%',
      height: '100%',
      offset: 0,
      axisX: {
        showGrid: false,
        showLabel: true,
        labelOffset: {
          x: 0,
          y: 0
        }
      },
      axisY: {
        showGrid: true,
        showLabel: true,
        scaleMinSpace: 30,
        offset: 50,
        labelOffset: {
          x: 0,
          y: 0
        },
        labelInterpolationFnc: (value) => {
          yAxisLabels.push(value)
          return '$' + '' + value
        },
        position: 'end'
      },
      chartPadding: {
        bottom: 0,
        left: 0,
        top: 20,
        right: 5
      }
    }
  }
  function setListener () {
    return {
      draw: (data) => {
        if (data.type === 'bar') {
          let barHorizontalCenter, barVerticalCenter, label, value
          barHorizontalCenter = data.x1 + (data.element.width() * 0.5)
          barVerticalCenter = data.y1 + (data.element.height() * -1) - 5
          value = data.element.attr('ct:value')
          if (value !== '0') {
            label = new Chartist.Svg('text')
            label.text(value)
            label.addClass('ct-barlabel')
            label.attr({
              x: barHorizontalCenter,
              y: barVerticalCenter,
              'text-anchor': 'middle'
            })
            return data.group.append(label)
          }
        }
      },
      created: () => {
        deviceType.Mobile === getDeviceType() && yAxisLabels.length > 0 && setCustomYAxis(yAxisLabels.reverse())
      }
    }
  }

  function genrateYAxis () {
    return (<div className={styles.yAxisContainer}>
      {customYAxis.map(value => {
        return (<div className={styles.label}>{`$${value}`}</div>)
      })}
    </div>)
  }

  return (
    <div className={styles.chartSectionMaster}>
      <div data-testid='gBarChart' className={styles.chartSectionContainer}>
        {<ChartistGraph
          data={chartData.data}
          options={chartData.options}
          listener={chartData.listener}
          type='Bar'
          style={{ width: '100%', height: '100%' }}
          className={styles.chartContainer}
        />
        }
        <div />
      </div>
      {customYAxis && genrateYAxis()}
    </div>
  )
}

ChartSection.propTypes = {
  chartData: PropTypes.object
}
export default ChartSection
