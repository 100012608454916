import format from '@markit/format'
import numeral from 'numeral'
import moment from 'moment-timezone'

export const TEXT_NULL_VALUE = '--'

function isNull (val) {
  return typeof val === 'undefined' || val === null || val === '-32768' || val === -32768
}

function isPrecise (val) {
  return !isNull(val) && String(val).split('.')?.[1]?.length === 2;
}

export const convertFromOADate = (date) => {
  if (date) {
    const utc = format().date(date, 'MM/DD/yyyy')
    // return format().moment.tz(utc, 'America/New_York')
    return utc
  }
  return ''
}

export const renderColorClass = (priceChange) => {
  if (isNaN(priceChange) || priceChange === 0) {
    return ''
  }
  return priceChange < 0 ? 'negative' : 'positive'
}

export const convertFromOADateDisplay = (date) => {
  if (date) {
    const utc = moment.fromOADate(date).format('MM/DD/yyyy') // Changes for PRW-999
    // return format().moment.tz(utc, 'America/New_York')
    return utc
  }
  return ''
}

export const convertUTCToEST = (date) => {
  if (date) {
    const utc = format().moment.utc(date).toISOString()
    return format().moment.tz(utc, 'America/New_York')
  }
  return ''
}

export const formatDate = (date, options = {}) => {
  const DAY_MILLISECONDS = 86400000
  const MS_DAY_OFFSET = 25569
  let formatOptions = {
    format: options.format || 'MM/DD/YYYY, hh:mm A',
    postFix: options.postFix || '',
    preFix: options.preFix || '',
    UTCtoEST: options.UTCtoEST || false,
    isoDateString: options.isoDateString || false,
    timeOnlyFormat: options.timeOnlyFormat,
    isMsDate: options.isMsDate || false
  }
  if (formatOptions.UTCtoEST) {
    date = convertUTCToEST(date)
  }
  if (formatOptions.isoDateString) {
    date = format().moment(date, formatOptions.format)
  }
  if (formatOptions.isMsDate) {
    let jO = new Date(((date - MS_DAY_OFFSET) * DAY_MILLISECONDS))
    let tz = jO.getTimezoneOffset()
    jO = new Date(((date - MS_DAY_OFFSET + (tz / (60 * 24))) * DAY_MILLISECONDS))
    date = moment(jO).format(formatOptions.format)
  }
  let formattedDateTime
  if (formatOptions.timeOnlyFormat) {
    formattedDateTime = format().time(date, formatOptions.timeOnlyFormat)
  } else {
    formattedDateTime = format().date(date, formatOptions.format)
  }
  if (formattedDateTime !== TEXT_NULL_VALUE) {
    if (options.preFix) {
      formattedDateTime = `${options.preFix} ${formattedDateTime}`
    }
    if (options.postFix) {
      formattedDateTime = `${formattedDateTime} ${formatOptions.postFix}`
    }
  }
  return formattedDateTime
}

export const numberFormatter = (val, formatString) => {
  let formattedValue = numeral(isPrecise(val) ? val : Math.floor(val * 100) / 100).format(formatString) // PRW-1284 we want to trim to 2 decimal values and not round off

  return isNull(val)
    ? TEXT_NULL_VALUE
    : (formattedValue === 'NaN' ? TEXT_NULL_VALUE : formattedValue)
}

export const textFormatter = (val) => {
  if (!val) {
    return '--'
  }
  let formattedValue = val

  return isNull(val)
    ? TEXT_NULL_VALUE
    : (formattedValue === undefined ? TEXT_NULL_VALUE : formattedValue)
}

export const formatSortNumber = (val) => {
  if (!val) {
    return '-1'
  }
  return val
}

export const convertToMsDate = (val) => {
  if (!val) {
    return '-1'
  } else {
    return moment(val).toOADate()
  }
}
export const getAIRealEstateAssetSizeIdentifier = (val) => {
  if (!val) {
    return '--'
  }
  if (val === 'Square Feet') {
    return 'Sq Ft'
  } else {
    return val
  }
}
export const convertToInternationalCurrencySystem = (labelValue) => {
  if (!labelValue) {
    return '--'
  }
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9
    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + 'B'
  // Six Zeroes for Millions
    : Math.abs(Number(labelValue)) >= 1.0e+6
      ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + 'M'
    // Three Zeroes for Thousands
      : Math.abs(Number(labelValue)) >= 1.0e+3
        ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + 'K'
        : Math.abs(Number(labelValue))
}
// Changes for PRW-1011
export const convertAvgMarketCapCurrencySystem = (labelValue) => {
  if (!labelValue) {
    return '--'
  }
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+3
    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + 'B'
    : Math.abs(Number(labelValue)) + 'M'
}

export const formatNumber = (number, options = {}) => {
  let inputs = {
    number: options,
    currencyPreFix: options.currencyPreFix || false,
    currencyPreFixValue: options.currencyPreFixValue || '$',
    postFix: options.postFix,
    postFixValue: options.postFixValue || '%',
    negSign: options.negSign || false,
    posSign: options.posSign || false,
    precision: options.precision !== undefined ? options.precision : 2,
    parentheses: options.parentheses,
    color: options.color,
    invalidDataDefaultValue: options.invalidDataDefaultValue || TEXT_NULL_VALUE,
    showMagnitude: options.showMagnitude || false
  }

  let preFix = ''
  let postFix = ''
  let formatString = `0,0.${Array(inputs.precision + 1).join('0')}`

  let formattedValue = numberFormatter(number, formatString)

  if (formattedValue === '--') {
    return inputs.invalidDataDefaultValue
  }

  formattedValue = formattedValue.replace('-', '')

  if (inputs.negSign && number < 0) {
    preFix += '-'
  } else if (inputs.posSign && number !== 0) {
    preFix += '+'
  }

  if (inputs.currencyPreFix) {
    preFix += inputs.currencyPreFixValue
  }

  if (inputs.postFix) {
    postFix += inputs.postFixValue
  }

  if (inputs.showMagnitude) {
    formattedValue = format().number(formattedValue, inputs.precision, {showMagnitude: inputs.showMagnitude}).replace(' ', '').toUpperCase()
  }
  formattedValue = preFix + formattedValue + postFix

  if (inputs.parentheses) {
    formattedValue = '(' + formattedValue + ')'
  }

  return formattedValue
}

export const getRatingDescription = (value) => {
  if (value) {
    switch (value.toString()) {
      case '1':
        return 'Low'
      case '2':
        return 'Below Average'
      case '3':
        return 'Average'
      case '4':
        return 'Above Average'
      case '5':
        return 'High'
      default:
        return TEXT_NULL_VALUE
    }
  }
  return TEXT_NULL_VALUE
}

export const getCustomRatingDescription = (value) => {
  if (value) {
    switch (value.toString()) {
      case '1':
        return 'Low'
      case '2':
        return 'Below Avg.'
      case '3':
        return 'Avg.'
      case '4':
        return 'Above Avg.'
      case '5':
        return 'High'
      default:
        return null
    }
  }
  return null
}

export const getYesNoStatus = (value) => {
  if (value) {
    switch (value.toString()) {
      case 'Y':
        return 'Yes'
      case 'N':
        return 'No'
      default:
        return TEXT_NULL_VALUE
    }
  }
  return TEXT_NULL_VALUE
}

export const isBankCustody = (value) => {
  switch (value) {
    case 'Y':
      return 'No'
    case 'N':
      return 'Yes'
    default:
      return TEXT_NULL_VALUE
  }
}
