import React, { forwardRef, useState, useEffect } from 'react'
import { renderToString } from 'react-dom/server'
import DatePicker from 'react-datepicker'
import { addDays } from 'date-fns'
import { formatDate } from '../../utils/formatter'
import styles from './Calendar.module.scss'
import PropTypes from 'prop-types'
import Button from '../Button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DownCaret = renderToString(<FontAwesomeIcon icon={['fas', 'caret-down']} />)
const CustomInput = forwardRef(
  ({ onClick, startDate, endDate, selectDateRange, dateFormatCalendar }, ref) => {
    if (selectDateRange) {
      startDate = startDate || addDays(new Date(), -2)
      endDate = endDate || addDays(new Date(), -1)
      let start = formatDate(startDate, {format: dateFormatCalendar || 'MM/DD/YYYY'})
      let end = endDate === null ? '' : ' - ' + formatDate(endDate, {format: 'MM/DD/YYYY'})
      return (<div className={styles.inputIcons}><input type='text' onClick={onClick} onFocus={onClick} tabIndex='0' ref={ref} data-testid='multidatetext' value={start + end} className={`${styles.datebox}`} readOnly /><FontAwesomeIcon icon={['fal', 'calendar-alt']} onClick={onClick} data-icon-style='calendar-alt-black' /></div>)
    } else {
      startDate = startDate || new Date()
      let start = formatDate(startDate, {format: dateFormatCalendar || 'MMM DD'})
      return (<div className={styles.inputIcons}><input type='text' onClick={onClick} onFocus={onClick} tabIndex='0' ref={ref} data-testid='singledatetext' value={start} className={styles.datebox} readOnly /><FontAwesomeIcon icon={['fal', 'calendar-alt']} data-icon-style='calendar-alt-black' onClick={onClick} /></div>)
    }
  }
)
const Calendar = (props) => {
  let calendar = null
  // To show by default last 2 days data
  let [startDate, setStartDate] = useState(props.startDate || addDays(new Date(), -2))
  let [endDate, setEndDate] = useState(props.endDate || addDays(new Date(), -1))
  let [isApplyButtonActive, setIsApplyButtonActive] = useState(true)
  let maxDate = props.maxDate || new Date()
  const [myRef, setMyRef] = useState(false)

  useEffect(() => {
    if (props.handleClear) {
      setStartDate(new Date())
      setEndDate(null)
    }
  }, [props.handleClear])

  useEffect(() => {
    const onScroll = () => setCalendarPosition()
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    const onResize = () => setCalendarPosition()
    // clean up code
    window.removeEventListener('resize', onResize)
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const closeDatepicker = () => {
    myRef.setOpen(false)
  }
  const multiDateChangeHandler = (dates) => {
    const [start, end] = dates
    setIsApplyButtonActive(dates[1] !== null)
    setStartDate(start)
    setEndDate(end)
  }
  const singleDateChangeHandler = (date) => {
    setStartDate(date)
    props.noApplyButton && props.onChange(date)
    props.noApplyButton && closeDatepicker()
  }
  const applySingleDateHandler = () => {
    props.onChange(startDate)
    closeDatepicker()
  }
  const applyMultiDateHandler = (e) => {
    if (!isApplyButtonActive) {
      e.preventDefault()
      return
    }
    props.onChange([startDate, endDate], e)
    closeDatepicker()
  }
  const resetSingleDateHandler = () => {
    props.onChange(startDate)
    setStartDate(new Date())
    closeDatepicker()
  }
  const resetMultiDateHandler = (e) => {
    props.onChange([new Date(), null], e)
    setStartDate(new Date())
    setEndDate(null)
    closeDatepicker()
  }
  const trimMonthName = () => {
    let monthyear = document.querySelectorAll('.react-datepicker__month-year-read-view--selected-month-year')
    monthyear.forEach(el => {
      let year = el.innerHTML.split(' ')[1]
      let month = el.innerHTML.slice(0, 3).toUpperCase()
      el.innerHTML = month.concat(' ', year)
    })
  }
  const arrowClickHandler = (e) => {
    if (e && e.type === 'click') {
      setTimeout(() => {
        updateDropdown()
      }, 100)
    } else {
      let arrow = document.querySelector('.react-datepicker__month-year-read-view')
      arrow.click()
      updateDropdown()
    }
  }
  const updateDropdown = () => {
    let options = document.querySelectorAll('.react-datepicker__month-year-option')
    options.forEach(elem => {
      elem.tabIndex = '0'
      elem.textContent = elem.textContent.toUpperCase()
    })
    let option = document.getElementsByClassName('--selected_month-year')[0]
    if (option) {
      option.focus()
      option.onkeydown = handleKeys
    }
    selectCurrentMonth()
  }
  const selectCurrentMonth = () => {
    let elem = document.getElementsByClassName('--selected_month-year')[0]
    elem && elem.focus()
  }

  const handleKeys = (e) => {
    if (e.key === 'Tab') {
      btnOnclick()
      let nextday = document.querySelectorAll('.react-datepicker__day--selected')[0] || document.querySelectorAll('.react-datepicker__day--keyboard-selected')[0]

      nextday.focus()
    }
    if (e.key === 'Enter') {
      e.currentTarget.click()
    } else {
      let keyCode = e.which
      if (keyCode === 40) {
        let nextElementSibling = e.currentTarget.nextElementSibling
        if (nextElementSibling && nextElementSibling.className.includes('react-datepicker__month-year-option')) {
          nextElementSibling.onkeydown = handleKeys
          nextElementSibling.focus()
        }
      } else if (keyCode === 38) {
        let prevElementSibling = e.currentTarget.previousElementSibling
        if (prevElementSibling && prevElementSibling.className.includes('react-datepicker__month-year-option')) {
          prevElementSibling.onkeydown = handleKeys
          prevElementSibling.focus()
        }
      }
    }
  }
  const btnOnclick = (e) => {
    let arrow = document.querySelector('.react-datepicker__month-year-read-view')
    arrow.click()
  }
  const handleCalendarOpen = () => {
    let downArrow = document.querySelector('.react-datepicker__month-year-read-view--down-arrow')
    downArrow.tabIndex = '0'
    downArrow.innerHTML = DownCaret
    let dateText = document.getElementsByClassName('react-datepicker__month-year-read-view--selected-month-year')[0]
    downArrow.addEventListener('click', arrowClickHandler)
    dateText.addEventListener('click', arrowClickHandler)
    downArrow.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        arrowClickHandler(e)
      }
    })

    changeMonthYear()
  }
  const changeMonthYear = () => {
    trimMonthName()
    setCalendarPosition()
  }
  const setCalendarPosition = () => {
    let noOfWeeksInMonth = document.querySelectorAll('.react-datepicker__week')
    let topPosition = document.querySelectorAll('.react-datepicker__input-container')[0].getBoundingClientRect().top
    let leftPosition = document.querySelectorAll('.react-datepicker__input-container')[0].getBoundingClientRect().left
    let datePickerDiv = document.querySelector('.react-datepicker-popper')

    if (datePickerDiv) {
      datePickerDiv.style.position = 'fixed'
      if (noOfWeeksInMonth.length > 5) {
        document.querySelector('.react-datepicker').style.height = props.noApplyButton ? '309px' : '369px'
        setTimeout(() => {
          document.querySelector('.react-datepicker-popper').style.transform = 'translate3d(' + (leftPosition - 96) + 'px, ' + (topPosition + 20) + 'px' + ', 0px)'
        }, 1)
      } else {
        document.querySelector('.react-datepicker').style.height = props.noApplyButton ? '289px' : '329px'
        setTimeout(() => {
          document.querySelector('.react-datepicker-popper').style.transform = 'translate3d(' + (leftPosition - 96) + 'px, ' + (topPosition + 20) + 'px' + ', 0px)'
        }, 1)
      }
    }
  }
  if (props.selectDateRange) {
    calendar = (
      <DatePicker
        selected={startDate}
        // closeOnScroll
        onChange={multiDateChangeHandler}
        startDate={startDate}
        endDate={endDate}
        dateFormatCalendar={'MMM yyyy'}
        minDate={new Date(1950, 10, 1)}
        maxDate={maxDate}
        onCalendarOpen={handleCalendarOpen}
        onMonthChange={changeMonthYear}
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
        showMonthYearDropdown
        ref={(r) => { setMyRef(r) }}
        shouldCloseOnSelect={false}
        showPopperArrow={false}
        nextMonthButtonLabel={<FontAwesomeIcon icon={['far', 'chevron-right']} />}
        previousMonthButtonLabel={<FontAwesomeIcon icon={['far', 'chevron-left']} />}
        customInput={<CustomInput {...props} />}
        selectsRange >
        <div className={styles.buttons}>
          <hr />
          <Button clickHandler={applyMultiDateHandler} buttonDataTestId={props.parentVal} className={isApplyButtonActive ? styles.apply : styles.disabledApplyBtn} labelText='Apply' />
          <Button clickHandler={resetMultiDateHandler} buttonDataTestId={props.parentVal} className={styles.reset} labelText='Reset' />
        </div>
      </DatePicker>
    )
  } else {
    calendar = (<DatePicker
      selected={startDate}
      onChange={singleDateChangeHandler}
      minDate={new Date(2015, 10, 1)}
      maxDate={maxDate}
      dateFormatCalendar='MMM yyyy'
      showMonthYearDropdown
      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
      onCalendarOpen={handleCalendarOpen}
      ref={(r) => { setMyRef(r) }}
      monthCellContentRender={value => value.format('MMM')}
      onMonthChange={changeMonthYear}
      showPopperArrow={false}
      shouldCloseOnSelect={false}
      nextMonthButtonLabel={<FontAwesomeIcon icon={['far', 'chevron-right']} />}
      previousMonthButtonLabel={<FontAwesomeIcon icon={['far', 'chevron-left']} />}
      customInput={<CustomInput {...props} />} >
      {!props.noApplyButton && <div className={styles.buttons}>
        <hr />
        <Button clickHandler={applySingleDateHandler} buttonDataTestId={props.parentVal} className={styles.apply} labelText='Apply' />
        <Button clickHandler={resetSingleDateHandler} buttonDataTestId={props.parentVal} className={styles.reset} labelText='Reset' />
      </div>}
    </DatePicker>)
  }
  return (
    <div className={styles.calendarcontainer}>{calendar}</div>
  )
}
Calendar.propTypes = {
  selectDateRange: PropTypes.bool,
  handleClear: PropTypes.bool,
  onChange: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  maxDate: PropTypes.object,
  dateFormatCalendar: PropTypes.string,
  noApplyButton: PropTypes.bool,
  parentVal: PropTypes.string
}

CustomInput.propTypes = {
  selectDateRange: PropTypes.bool,
  onClick: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  dateFormatCalendar: PropTypes.string
}
export default Calendar
