import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './Symbol.module.scss'
import { formatNumber } from '../../../utils/formatter'

const Symbol = (props) => {
  const change = Math.sign(props.item.changePercent)
  const articleNewsSymbolColor = change === -1 ? 'negativePercent' : 'positivePercent'
  return (
    <div className={`${styles['articleNewsSymbol']} ${styles[articleNewsSymbolColor]}`}>
      <span className={styles.symbol}>{props.item.symbol}</span>
      <span className={styles.upDownIcon}>
        {
          change === 0 ? '-' : change === -1 ? <FontAwesomeIcon icon={['fas', 'caret-down']} className={`${styles['negativePercent']} ${styles['imgpart']}`} />
            : <FontAwesomeIcon icon={['fas', 'caret-up']} className={`${styles['positivePercent']} ${styles['imgpart']}`} />
        }
      </span>
      <span className={styles.percent}>{formatNumber(props.item.changePercent)}%</span>
    </div>
  )
}
export default Symbol
Symbol.propTypes = {
  item: PropTypes.object
}
