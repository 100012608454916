const chartOptions = {
  height: 250,
  axisY: {
    low: -100,
    high: 100,
    scaleMinSpace: 49,
    position: 'end',
    labelOffset: {
      x: 0,
      y: 8
    },
    labelInterpolationFnc: null
  },
  axisX: {
    showGrid: false
  }
}
export const getChartConfig = (chartType, min, max, type) => {
  let chartConfig = {...chartOptions}
  chartConfig.axisY.high = max
  chartConfig.axisY.low = min
  chartConfig.axisY.labelInterpolationFnc = (value, index) => {
    let yLabel = isNaN(value) ? '0.0' : (value).toFixed(1).toString()
    if (type === 'MF' || type === 'MODEL') {
      return index % 2 === 1 ? '' : yLabel
    } else {
      if ((chartType === 'DefaultChart4' || chartType === 'BipolarChartMinus') && yLabel === '1.0') { return yLabel } else { return index % 2 === 1 ? '' : yLabel }
    }
  }
  return chartConfig
}
export const BetaRiskChartClass = {
  'DefaultChart': 'DefaultChart',
  'DefaultChart4': 'BartChartWithoutfor4',
  'BipolarChart': 'BartChartWithoutDashedLine',
  'LongChart': 'BartChartWithoutDashedLine',
  'BipolarChartMinus': 'BarChartWithMinus2'
}
