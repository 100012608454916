import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TabControl from '../Shared/TabControl/TabControl'
import styles from './ETFResearch.module.scss'
import TopHolding from '../Shared/TopTenHolding/TopHolding'
import SnapshotProfile from '../Shared/SnapshotProfile/SnapshotProfile'
import QuoteBar from '../Shared/QuoteBar/QuoteBar'
import SnapshotPerformance from '../Shared/SnapshotPerformance/SnapshotPerformance'
import BetaRisk from '../Shared/BetaRisk/BetaRisk'
import SnapshotStrategy from '../Shared/SnapshotStrategy/SnapshotStrategy'
import SnapshotHighlights from '../Shared/SnapshotHighlights/SnapshotHighlights'
import StyleBox from '../Shared/EquityAndFixedIncome/EquityAndFixedIncome'
import SimilarFunds from '../Shared/SimilarFunds/SimilarFunds'
import AssetAllocationTable from '../Shared/AssestAllocationChart/AssestAllocationTable'
import SnapshotRatings from '../Shared/SnapshotRatings/SnapshotRatings'
import SnapshotNewsList from '../Shared/SnapshotNewsList/SnapshotNewsList'
import ImportantDisclosure from '../Shared/Disclosures/ImportantDisclosures/ImportantDisclosure'
import PerformanceDisclosure from '../Shared/Disclosures/PerformanceDisclosures/PerformanceDisclosure'
import Footer from '../Shared/Disclosures/Footer/Footer'
import {moveScrollToTopById} from '../Helpers/CommonFunctions'
import ESGScores from '../Shared/ESGScores/ESGScores'
import ESGOverAllScore from '../Shared/CompareProgressBar/ESGOverAllScore'
import FossilFuelInvolvement from '../Portfolio/FossilFuelInvolvement/FossilFuelInvolvement'
// import IndexData from '../Portfolio/IndexData/IndexData'
// import MarketCap from '../Portfolio/MarketCap/MarketCap'
import Divider from '../../../../Lib/Divider/Divider'
// import RegionalAllocation from '../Portfolio/RegionalAllocation/RegionalAllocation'
import ESGOverallScore from '../Portfolio/ESGOverallScore/ESGOverallScore'
import AUMControversies from '../Portfolio/AUMControversies/AUMControversies'
import SustainabilityScore from '../Portfolio/SustainabilityScore/SustainabilityScore'
import EsgAUMRiskScore from '../Portfolio/EsgAUMRiskScore/EsgAUMRiskScore'
import CarbonRiskScore from '../Portfolio/CarbonRiskScore/CarbonRiskScore'
import CarbonRiskHighlights from '../Portfolio/CarbonRiskHighlights/CarbonRiskHighlights'
import { registerF2OnEvent } from '../utils/f2Methods'
import { EVENT_NAMES } from '../../../../../utils/f2Constants'
import {getAnalystRatings} from "../Shared/CompareProgressBar/ESGCommonMethods";

const ETFResearch = (props) => {
  const [etfResearchData, setETFResearchData] = useState({
    symbol: props.symbol,
    xid: props.xid,
    shareName: props.name,
    exchange: props.exchange,
    isFundvestEtf: props.IsFVETF,
    isETF: true,
    riskTitle: 'Beta Risk',
    type: 'ETF'
  })

  const [showStyleBox, setShowStyleBox] = useState(true)
  const [activeTabKey, setActiveTabKey] = useState('Snapshot')
  const [analystRatingsData, setAnalystRatingsData] = useState({});

  useEffect(() => {
    setETFResearchData({
      ...etfResearchData,
      symbol: props.symbol,
      xid: props.xid,
      shareName: props.name,
      exchange: props.exchange,
      isFundvestEtf: props.IsFVETF,
      isETF: true,
      riskTitle: 'Beta Risk',
      type: 'ETF'
    })
    setShowStyleBox(true)
    // Move scroll bar to top whenever ETF changes
    moveScrollToTopById('fundmarketplace')
    if (props?.xid) {
      let callback = (response) => {
        if (!response.isDataRequestComplete) {
          return;
        }
        setAnalystRatingsData({...response});
      }
      getAnalystRatings(props?.xid, callback)
    }
  }, [props.xid])

  const showStyleBoxHandle = (value) => {
    setShowStyleBox(value)
  }
  const changeHandlerTab = (e) => {
    setActiveTabKey(e)
  }

  useEffect(() => {
    registerF2OnEvent(EVENT_NAMES.GOTO_FUND_PROFILE_TAB, (data) => {
      setActiveTabKey(data.activeTabKey)
      window.scrollTo(0, 0)
    })
  }, [])

  return (
    <div className={styles.etfResearchContainer} id='main'>
      <div className={styles.quoteBarContainer}>
        <QuoteBar {...etfResearchData} />
      </div>
      <div className={`${styles.etfResearch} ${styles.removeMargin}`}>
        <TabControl id={'MainPage_tab'} ariaLabel={'Snapshot tabs'} activeTab={activeTabKey} changeHandler={(e) => changeHandlerTab(e)} isStickyTabControl={false}>
          <div key={'Snapshot'} label='Snapshot'>
            {
              activeTabKey === 'Snapshot' &&
              <div key='Snapshot' label='Snapshot'>
                <div className={styles.snapshotContainer}>
                  <div className={styles.leftSectionContainer} >
                    <SnapshotPerformance symbol={etfResearchData.symbol} />
                  </div>
                  <div className={styles.mobilePerformance}>
                    <PerformanceDisclosure />
                  </div>
                  <div className={styles.rightSectionContainer}>
                    <div className={styles.ratingsContainer} >
                      <SnapshotRatings xid={etfResearchData.xid} analystRatingsData={analystRatingsData} />
                    </div>
                  </div>
                </div>
                <div className={styles.desktopPerformance}>
                  <PerformanceDisclosure />
                </div>
                <div className={styles.appGap} />
                <div className={styles.appGap} />
                <div className={styles.holdingAndAllocationContainer}>
                  <div className={`${styles.leftSectionContainer} ${styles.leftSectionAdjust}`}>
                    <div>
                      <SnapshotHighlights {...etfResearchData} type='ETF' header='Highlights' />
                    </div>
                  </div>
                  <div className={styles.rightSectionContainer} >
                    <div className={styles.profile} >
                      <SnapshotProfile xid={etfResearchData.xid} key={etfResearchData.xid} />
                    </div>
                    <div className={styles.fundStrategy} >
                      <SnapshotStrategy xid={etfResearchData.xid} />
                    </div>
                  </div>
                </div>

                <div className={styles.appGap} />

                <div className={styles.holdingAndAllocationContainer}>
                  <div className={styles.leftSectionContainer} >
                    <div>
                      <TopHolding xid={etfResearchData.xid} isPortfolioEnabled />
                    </div>
                  </div>
                  <div className={styles.rightSectionContainer}>
                    <div className={styles.assetAllocation} >
                      <AssetAllocationTable xid={etfResearchData.xid} isETF={etfResearchData.isETF} />
                    </div>
                  </div>
                </div>
                <div className={styles.appGap} />
                <div className={styles.stockValuationContainer}>
                  <div className={styles.leftSectionContainer} style={{'minHeight': '320px'}}>
                    <BetaRisk {...etfResearchData} />
                  </div>
                  <div className={styles.rightSectionContainer} >
                    <div className={styles.styleBoxContainer}>
                      {showStyleBox &&
                      <div className={styles.styleBox} >
                        <StyleBox xid={etfResearchData.xid} showStyleBoxHandle={showStyleBoxHandle} />
                      </div>
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.appGap} />
                <div className={styles.newRatingContainer}>
                  <div className={styles.leftSectionContainer} >
                    <ESGScores symbol={etfResearchData.symbol} analystRatingsData={analystRatingsData} />
                    <SnapshotNewsList xid={etfResearchData.xid} symbol={etfResearchData.symbol} />
                  </div>
                  <div className={styles.rightSectionContainer} >
                    <ESGOverAllScore symbol={etfResearchData.symbol} analystRatingsData={analystRatingsData} />
                  </div>
                </div>
                <div className={showStyleBox ? styles.appGap : styles.appGapOnlyBorder} />
                <div className={styles.similarFundsContainer}>
                  <SimilarFunds xid={etfResearchData.xid} header={'Similar Funds'} textLine={'Based on category, 3Yr RSquared, and expense ratio. '} moduleName={'ETFResearch'} />
                </div>
              </div>
            }
          </div>
          <div key={'ESG Metrics'} label='ESG Metrics'>
            {activeTabKey === 'ESG Metrics' &&
            <div key='ESG Metrics' label='ESG Metrics'>
              <div className={styles.portfolioContainer}>
                {/* <div className={styles.container}>
                    <div className={`${styles.leftSectionContainer}`}>
                      {
                      // SectorWeighting
                      }
                      <div>
                        <MarketCap />
                      </div>
                    </div>
                    <div className={styles.rightSectionContainer}>
                      {
                        <IndexData symbol={etfResearchData.symbol} />
                      }
                    </div>
                  </div>
                  <Divider />
                  <div className={styles.container}>
                    <div className={`${styles.leftSectionContainer}`}>
                      {
                      //
                      }
                    </div>
                    <div className={styles.rightSectionContainer}>
                      {
                        <RegionalAllocation />
                      }
                    </div>
                  </div>
                  <Divider /> */}
                <div className={styles.container}>
                  <div className={`${styles.leftSectionContainer}`}>
                    {
                      <SustainabilityScore xid={etfResearchData.xid} analystRatingsData={analystRatingsData} />
                    }
                  </div>
                  <div className={styles.rightSectionContainer}>
                    {
                      <ESGOverallScore xid={etfResearchData.xid} analystRatingsData={analystRatingsData} />
                    }
                  </div>
                </div>
                <Divider />
                <div className={styles.container}>
                  <div className={`${styles.leftSectionContainer}`}>
                    {
                      <EsgAUMRiskScore xid={etfResearchData.xid} analystRatingsData={analystRatingsData} />
                    }
                  </div>
                  <div className={styles.rightSectionContainer}>
                    {
                      <AUMControversies xid={etfResearchData.xid} analystRatingsData={analystRatingsData} />
                    }
                  </div>
                </div>
                <Divider />
                <div className={styles.container}>
                  <div className={`${styles.leftSectionContainer}`}>
                    {
                      <CarbonRiskScore xid={etfResearchData.xid} />
                    }
                  </div>
                  <div className={styles.rightSectionContainer}>
                    {
                      <React.Fragment><CarbonRiskHighlights xid={etfResearchData.xid} /><FossilFuelInvolvement xid={etfResearchData.xid} /></React.Fragment>
                    }
                  </div>
                </div>

              </div>
            </div>
            }
          </div>
        </TabControl>
        <footer>
          <ImportantDisclosure />
          <Footer />
        </footer>
      </div>
    </div>
  )
}
ETFResearch.propTypes = {
  symbol: PropTypes.string,
  xid: PropTypes.number,
  name: PropTypes.string,
  exchange: PropTypes.string,
  isETF: PropTypes.bool,
  IsFVETF: PropTypes.bool
}

export default ETFResearch
