import React, {useEffect, useState} from 'react'
import { URLS, ESgCarbonKeys, fossilFuleDesc } from '../../utils/appConstants'
import { COLOR_DEFAULT_SET, NXI_COLOR_DEFAULT_SET } from '../../../../../../utils/appConstants'
import DataRequest from '../../../../../../services/DataRequest'
import Loader from '../../../../../Lib/common/Loader'
import styles from './FossilFuelInvolvement.module.scss'
import PropTypes from 'prop-types'
import ScoreBar from '../../Shared/ESGScores/ESGScoreBar/ScoreBar'
import ESGScoreBar from '../../../../../NXIEsgComponents/ESG/ESGScoreBar/ESGScoreBar'
import Nodata from '../../../../../Lib/NoData/NoData'
import { formatNumber } from '../../utils/formatter'
import Polygone from '../../../../../../assets/@Icons/Polygone'

export default function FossilFuelInvolvement ({xid, isNXI= false}) {
  const [isReady, setIsReady] = useState(false)
  const FOSSIL_FUEL_CONSTANTS = {
    CURRENT_SCORE: {
      label: 'Current Score'
    },
    HISTORICAL_AVERAGE: {
      label: '12 Mon Historical Average'
    },
    CATEGORY_AVERAGE: {
      label: 'Category Avg.'
    }
  }

  const GLOBAL_CATEGORY_RANGE = {
    label: 'Global Category Range'
  }

  const [fossilData, setFossilData] = useState()

  useEffect(() => {
    xid && xid > 0 ? getFossilData(xid) : setIsReady(true)
  }, [xid])

  const getFossilData = (xid) => {
    let callback = (response) => {
      setIsReady(false)
      if (!response.isDataRequestComplete) {
        return
      }
      if (!response.isError) {
        getFossilFuelScoreChart(response.data)
      } else {
        setFossilData()
      }
      setIsReady(true)
    }
    DataRequest.execute(
      `${URLS.PLATFORM.MANAGEDFUNDS_MORNINGSTAR_ESG_CARBON_INVOLVEMENT}`,
      {
        params: {
          venueXid: xid
        }
      },
      callback,
      null
    )
  }

  function Header () {
    return (<div className={styles.headerContainer}>
      {Object.keys(FOSSIL_FUEL_CONSTANTS).map((key, idx) => {
        return <div className={`${styles.labelContainer} ${styles['labelIpad' + `${idx}`]}`} key={idx}>
          <div className={styles.circle} style={{background: COLOR_DEFAULT_SET[idx].value}} />
          <div className={styles.labelDesc}>{FOSSIL_FUEL_CONSTANTS[key].label}</div>
        </div>
      })}
      <div className={`${styles.labelContainer} ${styles['labelIpad3']}`}>
        <div className={styles.line} />
        <div className={styles.labelDesc}>{GLOBAL_CATEGORY_RANGE.label}</div>
      </div>
    </div>)
  }

  function Footer () {
    return (
      <div className={styles.headerContainer}>
        {Object.keys(FOSSIL_FUEL_CONSTANTS).map((key, idx) => {
          return (
            <div
              className={`${styles.labelContainer} ${
                styles["labelIpad" + `${idx}`]
              }`}
              key={idx}
            >
              {idx === 1 && (
                <span className={styles.icon2}><Polygone /></span>
              )}
              {idx === 2 && <span className={styles.icon3} />}
              {idx === 0 && (
                <div
                  className={styles.circle}
                  style={{ background: NXI_COLOR_DEFAULT_SET[idx].value }}
                />
              )}
              <div className={styles.labelDesc}>
                {FOSSIL_FUEL_CONSTANTS[key].label}
              </div>
            </div>
          );
        })}
        <div className={`${styles.labelContainer} ${styles["labelIpad3"]}`}>
          <div className={styles.line} />
          <div className={styles.labelDesc}>{GLOBAL_CATEGORY_RANGE.label}</div>
        </div>
      </div>
    );
  }


  const getFossilFuelScoreChart = (data) => {
    setIsReady(false)
    let resObj = {}
    const currentScorePercents = data?.fund?.fossilFuel?.items?.find(x => x.dataPoint === ESgCarbonKeys.CoveredPortfolioPercentInvolved)?.percents
    const twelveMonthHistoricalPercents = data?.fund?.fossilFuel?.items?.find(x => x.dataPoint === ESgCarbonKeys.HistoricalCoveredPortfolioPercentInvolved)?.percents
    const categoryDataPoint = data?.category?.fossilFuel?.items?.find(x => x.dataPoint === ESgCarbonKeys.CoveredPortfolioPercentInvolved)
    if (data?.fund && data?.category) {
      resObj = {label: '', minMax: {}}
      resObj.minMax = {
        high: formatNumber(categoryDataPoint?.highestInvolvedInCategory),
        highLabel: 'High Risk',
        low: formatNumber(categoryDataPoint?.lowestInvolvedInCategory),
        lowLabel: 'Low Risk'
      }

      if (currentScorePercents?.percentInvolved != null) {
        resObj.score0 = formatNumber(currentScorePercents.percentInvolved)
      }
      if (twelveMonthHistoricalPercents?.percentInvolved != null) {
        resObj.score1 = formatNumber(twelveMonthHistoricalPercents.percentInvolved)
      }
      if (categoryDataPoint?.averages?.percentInvolved != null) {
        resObj.score2 = formatNumber(categoryDataPoint?.averages?.percentInvolved)
      }
      resObj.ariaLabel = {
        ariaLabel0: FOSSIL_FUEL_CONSTANTS.CURRENT_SCORE.label,
        ariaLabel1: FOSSIL_FUEL_CONSTANTS.HISTORICAL_AVERAGE.label,
        ariaLabel2: FOSSIL_FUEL_CONSTANTS.CATEGORY_AVERAGE.label
      }
    }
    setFossilData(resObj)
    setIsReady(true)
  }

  return (<div data-testid='fossilFuelContainer' className={isNXI?styles.NXIFossilFuelContainer:styles.fossilFuelContainer}>
    <span className={styles.header}>
      Fossil Fuel Involvement
      <span className={`${styles.popup}`}>
        <span>{fossilFuleDesc}</span>
      </span>
    </span>
    <Loader spinnerSize={'2x'} ready={isReady} >
      {!isNXI && <Header />}
      {fossilData ? <div className={styles.scoreBarContainer}>
      {isNXI?<ESGScoreBar data={fossilData} length={3} />
      :<ScoreBar data={fossilData} length={3} />}
      </div> : <Nodata />}
      {isNXI && <Footer />}
    </Loader>
  </div>)
}

FossilFuelInvolvement.propTypes = {
  xid: PropTypes.number,
  isNXI: PropTypes.bool
}
