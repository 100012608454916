export const WATCHLIST = {
  ADD_TO_WATCHLIST: 'Add to Watchlist',
  ADD_TO_EXISTING_WATCHLIST: 'Add to Existing Watchlist',
  CREATE_A_NEW_WATCHLIST: 'Create a New Watchlist',
  WATCHLIST_ALREADY_EXISTS: 'A watchlist with the same name already exists. Please pick a different name.',
  'Watchlist already exists': true,
  NO_EXISTING_WATCHCLIST: 'There is no existing watchlist.',
  ERROR_MESSAGE: 'Watchlist couldn’t be loaded. Please try again later.',
  WOULD_YOU_LIKE: 'Would you like to add the selected security to an existing watchlist or create a new one?'
}
export const DROPDOWNDATA = ['Search', 'Import Portfolio']
export const CLASSIFICATION_ID = {
  EQUITY: 3,
  ETF: 18,
  MF: 5,
  OPTION: 2
}
