import React from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './WatchlistPopupHeader.module.scss'

function WatchlistPopupHeader ({headerText, onClose}) {
  return (<header className={styles.watchlistsPopupHeader}>
    <span className={styles.headerText}>{headerText}</span>
    <button className={styles.closeIcon} alt='Close' id='closeButton' onClick={onClose}>
      <FontAwesomeIcon icon={['fal', 'times']} className={styles.closeIcon} id='closeButtonFontAwsome' />
    </button>
  </header>)
}

export default WatchlistPopupHeader

WatchlistPopupHeader.propTypes = {
  headerText: PropTypes.string,
  onClose: PropTypes.func
}
