import React from 'react'
import styles from './ExitAlert.module.scss'
import PrimaryButton from '../Button/PrimaryButton'
import SecondryButton from '../Button/SecondryButton'
import ModalPopUp from '../ModalPopUp/ModalPopUp'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { enterKeyEvent } from '../../../utils/utilities'

function ExitAlert (props) {
  const handleCancel = e => {
    props.confirmation('No')
  }
  const handleCancelKeyDownHandler = e => {
    props.confirmation('No')
  }
  const handleYes = e => {
    props.confirmation('Yes')
  }
  const handleYesKeyDownHandler = e => {
    props.confirmation('Yes')
  }
  return (
    <div className={styles.popupScreenAlert}>
      <ModalPopUp hideClose stylesPopUp={{width: '444px'}} onClose={props.onClose}>
        <div className={styles.ExitAlertContainer}>
          <div className={styles.content}>
            <div>
              <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className={styles.iconNoData} />
            </div>
            <div className={styles.headerText}>{props.message}</div>
          </div>
          <div className={`${styles['button-container']}`}>

            <PrimaryButton
              clickHandler={handleYes}
              keyDownHandler={(e) => { enterKeyEvent(e, handleYesKeyDownHandler) }}
              buttonText={props.yesLabelName}
              tabIndex='0'
            />
            {props.noLabelName &&
            <SecondryButton
              clickHandler={handleCancel}
              keyDownHandler={(e) => { enterKeyEvent(e, handleCancelKeyDownHandler) }}
              buttonText={props.noLabelName}
              tabIndex='0'
            />
            }
          </div>
        </div>
      </ModalPopUp>
    </div>
  )
}

ExitAlert.propTypes = {
  confirmation: PropTypes.func,
  yesLabelName: PropTypes.string,
  noLabelName: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func
}
export default ExitAlert
