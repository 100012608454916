import React, { useRef, useState, useEffect } from 'react'
import styles from './Slider.module.scss'
import PropTypes from 'prop-types'
import { convertToInternationalCurrencySystem } from '../../utils/formatter'

const Slider = props => {
  let [minValue, setMinValue] = useState(props.min)
  let [maxValue, setMaxValue] = useState(props.max)
  useEffect(() => {
    let mi = props.min < props.lowest || (props.max != null && props.min > props.max) || props.min > props.highest ? props.lowest : props.min
    if (props.max > props.lowest && props.max > props.min && props.max < props.highest) { setMaxValue(Math.round(props.max)) }
    if (mi !== undefined && mi !== null) {
      setMinValue(Math.round(mi))
    } else {
      setMinValue(Math.round(props.lowest))
    }
  }, [props.min, props.lowest])
  useEffect(() => {
    let ma = props.max < props.lowest || (props.min != null && props.max < props.min) || props.max > props.highest ? props.highest : props.max
    if (props.min > props.lowest && props.min < props.max && props.min < props.highest) { setMinValue(Math.round(props.min)) }
    if (ma !== undefined && ma !== null) {
      setMaxValue(Math.round(ma))
    } else {
      setMaxValue(Math.round(props.highest))
    }
  }, [props.max, props.highest])
  let minRef = useRef()
  let maxRef = useRef()
  const highestValue = props.highest
  const lowestValue = props.lowest
  let minvalue = (100 / (parseInt(highestValue) - parseInt(lowestValue))) * parseInt(minValue) - (100 / (parseInt(highestValue) - parseInt(lowestValue))) * parseInt(lowestValue)
  let maxvalue = (100 / (parseInt(highestValue) - parseInt(lowestValue))) * parseInt(maxValue) - (100 / (parseInt(highestValue) - parseInt(lowestValue))) * parseInt(lowestValue)
  let inverseleft = minvalue + '%'
  let inverseright = (100 - maxvalue) + '%'
  let rangeleft = minvalue + '%'
  let rangeright = (100 - maxvalue) + '%'
  let thumb1 = minvalue + '%'
  let thumb2 = maxvalue + '%'
  let sign1 = minvalue + '%'
  let sign2 = `max(0px , (${100 - maxvalue}%))`
  const onKeyDown = e => {
    let isMinhandling = e.target.dataset.value === 'min'
    let currentRef = isMinhandling ? minRef.current : maxRef.current
    let event = {
      target: {
        parentNode: currentRef.parentNode,
        max: currentRef.max,
        min: currentRef.min
      }
    }
    switch (e.keyCode) {
      case KEYCODE.LEFT:
        event.target.value = parseInt(currentRef.value) <= lowestValue ? lowestValue : parseInt(currentRef.value) - 1
        break
      case KEYCODE.RIGHT:
        event.target.value = parseInt(currentRef.value) >= highestValue ? highestValue : parseInt(currentRef.value) + 1
        break
      default:
        return
    }
    isMinhandling ? minValueChangeHandler(event) : maxValueChangeHandler(event)
    currentRef.value = event.target.value
  }

  const minValueChangeHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let th = e.target
    let mivalue = ''
    if (th.parentNode.childNodes[1].value === th.parentNode.childNodes[2].value) {
      mivalue = Math.min(th.value, th.parentNode.childNodes[2].value)
    } else {
      mivalue = Math.min(th.value, th.parentNode.childNodes[2].value - 1)
    }
    let value = (100 / (parseInt(th.max) - parseInt(th.min))) * parseInt(mivalue) - (100 / (parseInt(th.max) - parseInt(th.min))) * parseInt(th.min)
    let children = th.parentNode.childNodes[0].childNodes
    children[0].style.width = value + '%'
    children[2].style.left = value + '%'
    children[3].style.left = value + '%'
    children[5].style.left = value + '%'
    setMinValue(mivalue)
    props.onChangeHandler(mivalue, maxValue)
  }
  const maxValueChangeHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let th = e.target
    let miValue = minValue
    let maValue = maxValue
    if (minValue.toString() === maxValue.toString()) {
      if (th.value > maxValue) {
        maValue = th.value
      } else {
        miValue = th.value
      }
    } else if (th.parentNode.childNodes[1].value === props.highest.toString() || th.parentNode.childNodes[1].value === th.parentNode.childNodes[2].value) {
      maValue = Math.max(th.value, th.parentNode.childNodes[1].value)
      miValue = th.value
    } else {
      maValue = Math.max(th.value, th.parentNode.childNodes[1].value - (-1))
    }
    var value = (100 / (parseInt(th.max) - parseInt(th.min))) * parseInt(maValue) - (100 / (parseInt(th.max) - parseInt(th.min))) * parseInt(th.min)
    var children = th.parentNode.childNodes[0].childNodes
    children[1].style.width = (100 - value) + '%'
    children[2].style.right = (100 - value) + '%'
    children[4].style.left = value + '%'
    children[6].style.right = `max(0px , (${100 - value}%))`

    setMaxValue(maValue)
    setMinValue(miValue)
    props.onChangeHandler(miValue, maValue)
  }

  function getFormattedValue (numFormatterReqd, value) {
    return (numFormatterReqd && value !== 0) ? convertToInternationalCurrencySystem(value) : value
  }
  return <div className={styles.slider} id='slider-distance'>
    <div>
      <div className={styles.inverseleft} style={{ width: inverseleft }} > </div>
      <div className={styles.inverseright} style={{ width: inverseright }} > </div>
      <div className={styles.range} style={{ left: rangeleft, right: rangeright }} > </div>
      <button className={styles.thumb} style={{left: thumb1, marginLeft: '-5.5px', fontSize: '6px'}} data-value='min' onKeyDown={(e) => onKeyDown(e)} aria-label={`${props.header} min ${minValue}`}> </button>
      <button className={styles.thumb} style={{left: thumb2, marginLeft: '-5.5px', fontSize: '6px'}} data-value='max' onKeyDown={(e) => onKeyDown(e)} aria-label={`${props.header} max ${maxValue}`}> </button>
      <div className={styles.sign} style={{left: sign1}}>
        <span id='minvalue'>{getFormattedValue(props.numFormatterReqd, minValue)}</span>
      </div>
      <div className={styles.sign} style={{right: sign2, transform: 'translate(50%, 0%)', width: 'auto'}}>
        <span id='maxvalue'>{getFormattedValue(props.numFormatterReqd, maxValue)}</span>
      </div>
    </div>
    <input type='range' ref={minRef} tabIndex='-1' value={minValue} max={highestValue} aria-label={`${props.header} min ${minValue}`} min={lowestValue} step='1' onInput={minValueChangeHandler} />
    <input type='range' ref={maxRef} tabIndex='-1' value={maxValue} max={highestValue} aria-label={`${props.header} max ${maxValue}`} min={lowestValue} step='1' onInput={maxValueChangeHandler} />
    <span className={styles.spanLeft} >{getFormattedValue(props.numFormatterReqd, props.lowest)}</span>
    <span className={styles.spanRight} >{getFormattedValue(props.numFormatterReqd, props.highest)}</span>
  </div>
}
const KEYCODE = {
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
  TAB: 9,
  ENTER: 13
}
Slider.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  highest: PropTypes.number,
  lowest: PropTypes.number,
  header: PropTypes.string,
  onChangeHandler: PropTypes.func,
  numFormatterReqd: PropTypes.bool
}
export default Slider
