import React from 'react'
import PropTypes from 'prop-types'
import styles from './Glossary.module.scss'

export default function Term ({term}) {
  return (
    <div className={styles.terms}>
      <div className={styles.header}>
        {term.header}
      </div>
      <div className={styles.definition}>
        {term.definition}
      </div>
    </div>
  )
}
Term.propTypes = {
  term: PropTypes.object
}
