import React, { useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import styles from './SimilarFunds.module.scss'
import QuoteCard from '../../Shared/QuoteCardWithStockHighlights/QuoteCardWithStockHighlights'
import {URLS, ModuleName, DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS} from '../../utils/appConstants'
import DataRequest from '../../../../../../services/DataRequest'
import Loader from '../../../../../Lib/common/Loader'
import { handleWindowResize, deviceType, getDeviceType } from '../../../../../../utils/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Nodata from '../../../../../Lib/NoData/NoData'

const cardWidthDict = {
  'AISnapshot': {
    mobile: 249,
    desktop: 253,
    spinnerWidth: 228
  },
  'MFResearch': {
    mobile: 249,
    desktop: 253,
    spinnerWidth: 228
  },
  'ETFResearch': {
    mobile: 248,
    desktop: 252,
    spinnerWidth: 227
  },
  'MFSimilarFunds': {
    mobile: 248,
    desktop: 228,
    spinnerWidth: 227
  },
  'SimilarModels': {
    mobile: 248,
    desktop: 228,
    spinnerWidth: 227
  }
}
const SimilarFunds = ({ xid, header, textLine, moduleName, data }) => {
  const [isReady, setIsReady] = useState(false)
  const [peerObj, setPeersObj] = useState()
  const [peers, setPeers] = useState([])
  const [isLeftDisabe, setLeftDisabe] = useState(true)
  const [isRightDisabe, setRightDisabe] = useState(false)
  const [peerNumbers, setPeersNumbers] = useState()
  const [isErr, setIsErr] = useState(false)
  const containerWidth = useRef()
  const [CARD_WIDTH, setCardWidth] = useState(252)

  const divRef = useRef()
  const spinnerStyle = {'minWidth': cardWidthDict[moduleName].spinnerWidth, 'height': '225px'}
  useEffect(() => {
    handleWindowResize(() => {
      getPeerNumbers()
    })
    getPeerNumbers()
  }, [])
  useEffect(() => {
    if (peers && peers.length < peerNumbers) {
      setRightDisabe(true)
    }
  }, [peers])

  const getPeerNumbers = () => {
    let width = containerWidth.current && containerWidth.current.offsetWidth
    let device = getDeviceType()
    let margin = 0
    let cardwidth = cardWidthDict[moduleName].desktop

    switch (device) {
      case deviceType.Desktop:
        margin = 40
        break
      case deviceType.Ipad:
        margin = 30
        cardwidth = cardWidthDict[moduleName].mobile
        break
      case deviceType.Mobile:
        margin = 20
        cardwidth = cardWidthDict[moduleName].mobile
        break
    }
    let actualWidth = width - margin
    let peernum = Math.ceil(actualWidth / cardwidth)
    setPeersNumbers(peernum)
    setCardWidth(cardwidth)
  }
  useEffect(() => {
    if (divRef.current) {
      let current = divRef.current
      disableScroll(current)
      return () => enableScroll(current)
    }
  }, [divRef.current])
  useEffect(() => {
    setRightDisabe(false)
    setLeftDisabe(true)
  }, [xid])
  useEffect(() => {
    if (['AISnapshot', 'MFResearch', 'SimilarModels'].includes(moduleName)) {
      getGenericPeersData()
    } else if (moduleName === 'ETFResearch' || moduleName === 'MFSimilarFunds') {
      getPeersData()
    }
  }, [data, xid, peerNumbers])
  const getPeersData = () => {
    const callback = response => {
      if (!response.isError && response.data.items) {
        let data = response.data.items
        let promiseArray = []
        promiseArray.push(getMFETFSymbol(data))
        Promise.all(promiseArray)
          .then(responseArray => {
            let symbolDict = [...responseArray[0]]
            data.map(element => {
              let symbolData = symbolDict.filter(x => x.SPCFWSODIssue === element.venueXid)
              element.symbol = (symbolData[0] && symbolData[0].SPCFticker) || ''
            })
            setPeers(data)
            setPeersObj(data.slice(0, peerNumbers))
            setIsErr(false)
            setIsReady(true)
          })
      } else {
        setIsErr(true)
        setIsReady(true)
      }
    }
    DataRequest.execute(
      `${URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_PEERS}/${xid}`,
      {
        params: {
          shareClassType: moduleName === 'MFSimilarFunds' ? 'allShareClasses' : 'sameShareClassOnly',
          newInvestors: 'openOnly',
          fundType: moduleName === 'MFSimilarFunds' ? 'MF' : 'ETF',
          numberOfResults: '10'
        }
      },
      callback,
      null
    )
  }
  function getMFETFSymbol (relatedShareclasses) {
    return new Promise((resolve) => {
      const screenDataCallback = (response) => {
        if (!response.isDataRequestComplete) return
        if (!response.isError) {
          const responseData = response.data || {}
          return resolve(responseData.items)
        }
      }
      let xidValues = []
      relatedShareclasses.map(item => {
        xidValues.push(item.venueXid.toString())
      })
      let screenerArguments = []
      let newArgument =
    {
      field: 'SPCFWSODIssue',
      conditions: [
        {
          operator: 'Like',
          values: xidValues
        }
      ]
    }
      screenerArguments.push(newArgument)
      DataRequest.execute(
        `${URLS.CUSTOM.SCREEN + '?screenType=MF_ETF'}`,
        buildScreenInputs(0, false, screenerArguments),
        screenDataCallback
      )
    })
  }
  const buildScreenInputs = (_offset, isExportToCsv, screenerArguments, sortArguments) => {
    const screenInputs = DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS
    screenInputs.offset = _offset
    screenInputs.arguments = screenerArguments || []
    screenInputs.sortArguments = sortArguments || [
      {
        direction: 'A',
        field: 'SPCFticker'
      }
    ]
    screenInputs.limit = 100
    screenInputs.resultFields = ['SPCFticker', 'SPCFWSODIssue']
    return {
      method: 'POST',
      postData: screenInputs
    }
  }
  const getGenericPeersData = () => {
    if (data && data.length > 0) {
      setPeers(data)
      setPeersObj(data.slice(0, peerNumbers))
      setIsErr(false)
      setIsReady(true)
    } else {
      setIsReady(true)
      setIsErr(true)
    }
  }

  const leftClickHandler = (e) => {
    e.preventDefault()
    divRef.current.scrollLeft -= (CARD_WIDTH - 1)
    divRef.current.scrollLeft <= 0 && setLeftDisabe(true)
  }
  const rightClickHandler = (e) => {
    e.preventDefault()
    let current = divRef.current
    let currentCardlength = peerObj.length
    let totalCard = peers.length
    let scrollLeft = Math.ceil(current.scrollLeft)
    if ((current.clientWidth + scrollLeft < current.scrollWidth)) {
      current.scrollLeft += CARD_WIDTH
      return
    }
    if (currentCardlength < totalCard) {
      setPeersObj([...peerObj, peers[currentCardlength]])
      setTimeout(() => {
        current.scrollLeft += CARD_WIDTH
      }, 20)
    }
  }

  const scrolled = (o) => {
    let e = o.target
    o.preventDefault()
    let scrollLeft = Math.ceil(e.scrollLeft)
    if (e.clientWidth + scrollLeft >= e.scrollWidth && peers.length === peerObj.length) {
      setRightDisabe(true)
    } else {
      setRightDisabe(false)
    }
    if (scrollLeft === 0) {
      setLeftDisabe(true)
    } else {
      setLeftDisabe(false)
    }
  }
  const setCardType = (moduleName) => {
    let type = ''
    if (moduleName === 'AISnapshot') {
      type = 'AINSnapshot'
    } else if (moduleName === 'MFResearch') {
      type = 'MFSnapshot'
    } else if (moduleName === 'SimilarModels') {
      type = 'SimilarModels'
    }
    return type
  }
  const getQuoteCard = (item) => {
    switch (moduleName) {
      case 'AISnapshot':
      case 'MFResearch':
        return <QuoteCard moduleType={ModuleName.MF_RESEARCH_SNAPSHOT} type={setCardType(moduleName)} id={'quoteCard_' + item.venueXid} key={'quoteCard_' + item.venueXid} data={item} style={{width: '228px', backgroundColor: 'transparent'}} spinnerStyle={spinnerStyle} />
      case 'ETFResearch':
        return <QuoteCard type={'ETF'} symbol={item.symbol} moduleType={ModuleName.ETF_RESEARCH_SNAPSHOT} id={'quoteCard_' + item.venueXid} key={'quoteCard_' + item.venueXid} venueXid={item.venueXid} name={item.morningstarInvestmentName} style={{width: '227px', backgroundColor: 'transparent'}} spinnerStyle={spinnerStyle} />
      case 'MFSimilarFunds':
        return <QuoteCard type={'MF'} symbol={item.symbol} moduleType={ModuleName.MF_RESEARCH_SNAPSHOT} id={'quoteCard_' + item.venueXid} key={'quoteCard_' + item.venueXid} venueXid={item.venueXid} name={item.morningstarInvestmentName} style={{width: '227px', backgroundColor: 'transparent'}} spinnerStyle={spinnerStyle} />
      case 'SimilarModels':
        return <QuoteCard type={'TPMOC'} id={'quoteCardNews_' + (item.venueXid)} key={'quoteCardNews_' + (item.venueXid)} venueXid={item.venueXid} data={item} symbol={item.symbol} name={item.name} style={{width: '228px', backgroundColor: 'transparent'}} spinnerStyle={spinnerStyle} />
    }
  }
  return <div className={styles.similarFundsContainer} ref={containerWidth} style={moduleName === 'MFResearch' ? {marginTop: '20px'} : {}}>
    <Loader ready={isReady} spinnerSize='2x'>
      <div className={styles.headerContainer}>
        <div className={moduleName === 'MFResearch' ? styles.headerShareClassMF : styles.header} id='similarHeader' >{header}</div>
        <div className={styles.text} id='textLine'>{textLine}</div>
        {!isErr && <div className={styles.buttons}>
          <button className={styles.left} aria-label='Scroll Left' disabled={isLeftDisabe} onClick={leftClickHandler}><FontAwesomeIcon icon={['fal', 'chevron-left']} color={isLeftDisabe ? 'rgba(0, 0, 0, 0.3)' : 'black'} /></button>
          <div className={styles.bar} />
          <button className={styles.right} aria-label='Scroll Right' disabled={isRightDisabe} onClick={rightClickHandler}><FontAwesomeIcon icon={['fal', 'chevron-right']} color={isRightDisabe ? 'rgba(0, 0, 0, 0.3)' : 'black'} /></button>
        </div>}
      </div>
      {!isErr && !isLeftDisabe &&
        <div className={`${styles.shadow} ${styles.shadowLeft}`} aria-hidden='true' />
      }
      <div className={styles.groupcontainer} ref={divRef} onScroll={scrolled} data-testid='groupContainer' style={moduleName === 'MFResearch' ? {marginTop: '14px', paddingTop: '1px', paddingBottom: '3px'} : {}}>
        {isErr ? <Nodata msg='No peers available' /> : peerObj && peerObj.map((item, index) => <div className={styles.card} key={'card-' + index}>{getQuoteCard(item)}</div>
        )}
      </div>
      {!isErr && !isRightDisabe &&
      <div className={`${styles.shadow} ${styles.shadowRight}`} aria-hidden='true' />
      }
    </Loader>
  </div>
}

SimilarFunds.propTypes = {
  xid: PropTypes.number,
  header: PropTypes.string,
  textLine: PropTypes.string,
  moduleName: PropTypes.string,
  data: PropTypes.array
}
// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys = {37: 1, 39: 1}
function preventDefault (e) {
  if (e.type !== 'wheel' || e.deltaY === 0) { e.preventDefault() }
}

function preventDefaultForScrollKeys (e) {
  if (keys[e.keyCode]) {
    preventDefault(e)
    return false
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false
try {
  window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true }
  }))
} catch (e) {}

var wheelOpt = supportsPassive ? { passive: false } : false
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

// call this to Disable
function disableScroll (element) {
  element.addEventListener('DOMMouseScroll', preventDefault, false) // older FF
  element.addEventListener(wheelEvent, preventDefault, wheelOpt) // modern desktop
  element.addEventListener('touchmove', preventDefault, wheelOpt) // mobile
  element.addEventListener('keydown', preventDefaultForScrollKeys, false)
}
// call this to Enable
function enableScroll (element) {
  element.removeEventListener('DOMMouseScroll', preventDefault, false)
  element.removeEventListener(wheelEvent, preventDefault, wheelOpt)
  element.removeEventListener('touchmove', preventDefault, wheelOpt)
  element.removeEventListener('keydown', preventDefaultForScrollKeys, false)
}
export default SimilarFunds
