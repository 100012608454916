'use strict'

import React from 'react'
import PropTypes from 'prop-types'
import BasicDropDown from '../../Lib/Dropdown/BasicDropDown'
import { CHART_STYLES } from '../../../utils/appConstants'
import styles from './ChartType.module.scss'

export default function ChartType ({onSelect, resetDefaultValue, setRevertDefaultValue, chartTypeSelValue}) {
  return (
    <div className={styles.chartType} data-custom-select='custom-chart-menu'>
      <BasicDropDown data={CHART_STYLES} changeHandler={onSelect} selectedValue={chartTypeSelValue} resetDefaultValue={resetDefaultValue} setRevertDefaultValue={setRevertDefaultValue} />
    </div>
  )
}

ChartType.propTypes = {
  onSelect: PropTypes.func,
  resetDefaultValue: PropTypes.string,
  setRevertDefaultValue: PropTypes.func,
  chartTypeSelValue: PropTypes.string
}
