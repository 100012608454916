import DataRequest from '../../../../../services/DataRequest'
import { ModuleName, BetaRiskChartType, ENVIRONMENTS_DOMAINS, MOD_GOTO_PAGES, URLS, SearchTypes } from '../utils/appConstants'
import {removeDuplicateModelsData} from '../Helpers/CommonFunctions'

export function logDebugInfo (message) {
  console.log(message)
}

export function CreateUUID () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0
    let v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}
export const deviceType = {
  Desktop: 'DESKTOP',
  Ipad: 'IPAD',
  Mobile: 'MOBILE'
}
export const getDeviceType = () => {
  const screenWidth = document.documentElement.clientWidth
  let device = deviceType.Desktop
  if (screenWidth >= 1024) {
    device = deviceType.Desktop
  } else if (screenWidth >= 596 && screenWidth <= 1024) {
    device = deviceType.Ipad
  } else if (screenWidth < 596 && screenWidth > 0) {
    device = deviceType.Mobile
  }
  return device
}
export const debounce = (method, delay) => {
  clearTimeout(method._tId)
  method._tId = setTimeout(function () {
    method()
  }, delay)
}

export const handleWindowResize = (callBackHandler) => {
  window.addEventListener('resize', () => {
    callBackHandler(getDeviceType())
  })
}

export function splitNewsContentByDash (content) {
  const contentArray = content && content.split('--')
  if (contentArray) {
    return contentArray.length > 1 ? contentArray[1] : contentArray[0]
  }
  return ''
}

export function remvoveHTMLTags (fullText = '', charLimit) {
  fullText = fullText.replace('...', '')
  return {
    __html: charLimit ? truncateText(fullText, charLimit) : fullText
  }
}

export function truncateText (text, charLimit, endingText = '...') {
  let textToDisplay = text.length > charLimit ? (text.substring(0, charLimit - endingText.length) + endingText) : text
  return textToDisplay
}

export const addArgument = (field, values, operator) => {
  return {
    field: field,
    conditions: [{
      operator: (operator || 'EqualTo'),
      values: values
    }]
  }
}
export const closeModalPopup = (e) => {
  e.preventDefault()
  if ((e.target.getAttribute('id') === 'closeButton' || e.target.tagName === 'path' || e.target.tagName === 'svg') || (((e.key === 'Escape' && e.keyCode === 27) || e.type === 'click') && e.target.getAttribute('id') === 'outerModalPopUp')) {
    return true
  }
  return false
}
export const hideScroll = (isScroll) => {
  let overflow = isScroll ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
  return overflow
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value)
}

export const getAPIData = (input, apiPath, callback, paramInput = {}, isParam = false) => {
  DataRequest.execute(
    `${apiPath}/${input}`,
    isParam ? {
      params: paramInput
    } : {},
    callback,
    null
  )
}

export const isSnapshot = (moduleTypeName) => {
  return [ModuleName.ETF_RESEARCH_SNAPSHOT, ModuleName.MF_RESEARCH_SNAPSHOT, ModuleName.FUND_SCREENER].includes(moduleTypeName)
}

export const getRangeStep = (min, max) => {
  let diff = max - min

  if (diff < 1) {
    return '0.001'
  } else

    if (diff < 10) {
      return '0.01'
    } else { return 1 }
}
export const getBetaRiskChartType = (riskData) => {
  let maxPositive = 0
  let maxNegative = 0
  Object.keys(riskData).forEach(key => {
    let value = riskData[key]
    if (value > 0) {
      maxPositive = maxPositive > value ? maxPositive : value
    }
    if (value < 0) {
      maxNegative = maxNegative < value ? maxNegative : value
    }
  })
  let chartType
  let chartLimit = getChartLimit(maxPositive, maxNegative)
  let minLimit = 0
  let maxLimit = 0
  if (maxPositive <= 2 && maxNegative === 0) {
    chartType = BetaRiskChartType.DEFAULT_CHART
    maxLimit = chartLimit
  } else if (maxNegative >= -2 && maxPositive <= 2) {
    chartType = BetaRiskChartType.BIPOLAR_CHART_MINUS_2
    maxLimit = chartLimit
    minLimit = -chartLimit
  } else if (maxNegative !== 0) {
    chartType = BetaRiskChartType.BIPOLAR_CHART
    maxLimit = chartLimit
    minLimit = -chartLimit
  } else if (maxPositive <= 4) {
    chartType = BetaRiskChartType.DEFAULT_CHART4
    maxLimit = chartLimit
  } else {
    chartType = BetaRiskChartType.LONG_CHART
    maxLimit = chartLimit
  }
  return ({chartType: chartType, maxLimit: maxLimit, minLimit: minLimit})
}
const getChartLimit = (pos, neg) => {
  if (neg === 0) {
    return getLimit(pos)
  } else return Math.max(getLimit(pos), getLimit(neg))
}
const getLimit = (value) => {
  value = Math.abs(value)
  if (value <= 2) { return 2 }
  if (value <= 4) { return 4 }
  if (value < 10) { return 10 }
  if (value < 100) return 100
  if (value < 200) return 200
  // if (value < 500) return 500
  if (value < 1000) return 1000
  if (value < 2000) return 2000
}

export const isInternalEnvironment = () => {
  let environmentArr = []
  const host = window.location.hostname
  Object.keys(ENVIRONMENTS_DOMAINS).forEach((key) => {
    environmentArr.push(...ENVIRONMENTS_DOMAINS[key])
  })
  return environmentArr.includes(host)
}

export const getSymbolContext = (symbol, activeTabKey, type) => {
  return new Promise((resolve) => {
    const callback = (response) => {
      if (!response.isError) {
        let responsedata =
        response &&
        response.data &&
        response.data.results &&
        response.data.results.length > 0 &&
        type === 'model' ? removeDuplicateModelsData(response.data.results, response.data)[0] : response.data.results[0]
        let modContext = {
          activeTabKey: activeTabKey,
          Strategy: responsedata.StrategyType,
          CUSIP: responsedata.CUSIP,
          IsFVETF: responsedata.IsFVETF,
          ModelClientId: responsedata.ModelClientID,
          MorningstarID: responsedata.MorningstarID,
          Product: responsedata.Product,
          Program: responsedata.Program,
          symbol: responsedata.Symbol || responsedata.Name,
          name: responsedata.Name,
          type: parseClassificationType(responsedata.Classification, responsedata.isLocked, responsedata.StrategyType),
          exchange: responsedata.exchange,
          xid: responsedata.VenueXID,
          gotoPage: MOD_GOTO_PAGES.fund,
          VenueXid: responsedata.VenueXID,
          ibd: responsedata.IBDCode,
          mgrCode: responsedata.ManagerCode,
          strategyForModel: responsedata.Strategy,
          PKColumn: responsedata.PKColumn,
          isLocked: responsedata.isLocked,
          OffshoreFlex: responsedata.OffshoreFlex,
          fundType: responsedata.CategoryName
        }
        return resolve(modContext)
      }
      return resolve({})
    }
    DataRequest.execute(
      `${URLS.CUSTOM.SEARCH}`,
      {
        params: {
          searchText: symbol,
          productType: 'All'
        }
      },
      callback,
      null
    )
  })
}

export const pascalize = (str) => {
  if (!str) {
    return '--'
  }
  return str.replace(/(\w)(\w*)/g,
    function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase() })
}

const parseClassificationType = (_searchType, isLocked, strategyType) => {
  let searchType = _searchType
  switch (_searchType) {
    case 'Mutual Fund':
      searchType = 'MF'
      break
    case 'Stock':
      searchType = 'EQ'
      break
    case 'ETF':
      searchType = 'ETF'
      break
    case 'Model':
      searchType = strategyType === 'Traditional SMA' ? (isLocked ? 'SMAL' : 'SMA') : (isLocked ? 'ModL' : 'MOD')
      break
    case 'Alternative':
      searchType = strategyType === 'Non-Traded REIT' ? 'AIN' : 'AI'
      break
    default:
      break
  }
  return searchType
}

export const getContextBySymbol = (symbol) => {
  let modContext = null
  if (['FundVest', 'FundVest ETF', 'FundCenter'].includes(symbol)) {
    modContext = {
      'activeTabKey': 'screener',
      'symbol': symbol,
      'name': '',
      'type': symbol.includes('ETF') ? 'ETF' : 'MF',
      'screenerPillChange': Math.random(),
      'gotoPage': 'funds'
    }
  } else if (['AllMF', 'AllETF'].includes(symbol)) {
    modContext = {
      'gotoPage': 'funds',
      'symbol': symbol,
      'activeTabKey': 'screener',
      'navigationFromViewAllLinkFlag': Math.random()
    }
  } else if (['AllModL', 'AllMOD'].includes(symbol)) {
    modContext = {
      'gotoPage': 'model-marketplace',
      'symbol': symbol,
      'activeTabKey': 'screener',
      'navigationFromViewAllLinkFlag': Math.random()
    }
  } else if (['AllAI'].includes(symbol)) {
    modContext = {
      'gotoPage': 'alternative-investments',
      'symbol': symbol,
      'activeTabKey': 'screener',
      'navigationFromViewAllLinkFlag': Math.random()
    }
  }
  return modContext
}

export const setRecentSearchedData = (data) => {
  return new Promise((resolve) => {
    const callback = (response) => {
      if (!response.isError) {}
      return resolve({})
    }
    if (data) {
      DataRequest.execute(
        `${URLS.CUSTOM.SET_RECENT_SEARCH}`,
        {
          method: 'POST',
          postData: {
            data: {
              venueXid: (SearchTypes.indexOf(data.searchType) > -1) ? (data.VenueXID || data.CUSIP) : null,
              type: data.searchType,
              name: data.Name || data.name,
              symbol: data.Symbol || data.symbol || data.Name || data.name,
              key: data.VenueXID || data.venueXid || data.key || data.CUSIP || data.ModelClientID || data.modelClientId,
              program: data.Program || data.program,
              product: data.Product || data.product,
              strategyType: data.StrategyType || data.strategyType,
              CUSIP: data.CUSIP,
              strategyName: data.StrategyName || data.strategyName,
              modelClientId: data.ModelClientID || data.modelClientId,
              MorningstarID: data.MorningstarID || data.morningstarID,
              price: data.price,
              isLocked: data.isLocked
            }
          }
        },
        callback,
        null
      )
    }
  })
}

export const isMMF = (data) => {
  const categoryName = ['money market-tax-free', 'prime money market', 'money market-taxable', 'money market']
  if (data && data.CategoryName && categoryName.includes(data.CategoryName.toLowerCase())) {
    return true
  }
  return false
}
