/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import PropTypes from 'prop-types'
import styles from './StarRating.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default function StarRating (props) {
  return (
    <div>
      {[...Array(parseInt(props.starCount))].map((x, i) =>
        <FontAwesomeIcon key={'solidstar' + i} icon={['fa', 'star']} className={styles.solidStar} />
      )}
      {[...Array(5 - parseInt(props.starCount))].map((x, i) =>
        <FontAwesomeIcon key={'hollowstar' + i} icon={['fa', 'star']} className={styles.hollowStar} />
      )}
    </div>
  )
}

StarRating.propTypes = {
  starCount: PropTypes.number
}
