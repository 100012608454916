import React from 'react'
import PropTypes from 'prop-types'
import styles from './NewsForYouEmpty.module.scss'
import { EMPTY_NEWS_DESCRIPTION } from '../../../utils/appConstants'
import Button from '../../Lib/Button/Button'

function NewsForYouEmpty (props) {
  return (<div className={styles.emptyNewsContainer}>
    <div className={styles.header} aria-label='News for You'>
      News for You
    </div>
    <div className={styles.body}>
    <div className={styles.description} aria-label={EMPTY_NEWS_DESCRIPTION}>
      {EMPTY_NEWS_DESCRIPTION}
      </div>
      <div className={styles.selectPreferences}>
    <Button clickHandler={props.openSelectPreferencesModal} className={styles.preferencesBtn} labelText='Select Your Preferences' />
    </div>
    </div>
  </div>
  )
}
NewsForYouEmpty.propTypes = {
  openSelectPreferencesModal: PropTypes.func
}
export default NewsForYouEmpty
