import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './SymbolList.module.scss'
import Loader from '../../Lib/common/Loader'
import Symbol from './Symbol'
import { getDeviceType, deviceType } from '../../../utils/utilities'
const SymbolList = (props) => {
  const [device, setDevice] = useState(deviceType.Desktop)
  const [isShowMore, setIsShowMore] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [noOfItemsNotDrawn, setNoOfItemsNotDrawn] = useState(0)
  const indexListContainer = useRef(null)
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    resizeCalLogic()
    setIsLoading(false)
  }, [])

  const handleResize = (e) => {
    resizeCalLogic()
  }
  const resizeCalLogic = () => {
    setDevice(getDeviceType())
    if (indexListContainer && indexListContainer.current) {
      const width = indexListContainer.current.clientWidth
      const noOfTotalItems = props.symbols.length
      // FirstValue 90 is from .articleNewsSymbolItems>width, 75 is width of oneItem + 20px margin=125
      const noOfItemsDrawn = props.noOfSymbolsToDisplay || (device === deviceType.Desktop ? 5 : Math.trunc((width * 85 / 100) / 95))
      const newNoOfItemsNotDrawn = noOfTotalItems - noOfItemsDrawn
      if (newNoOfItemsNotDrawn > 0) {
        setIsShowMore(true)
        setNoOfItemsNotDrawn(newNoOfItemsNotDrawn)
      } else {
        setIsShowMore(false)
      }
    }
  }
  const renderQoutes = (quoteLimit) => {
    let counter = 0
    return (!isLoading && <div data-value={'Symbol container'} className={`${styles['articleNewsSymbolItems']}`}>
      {
        props.quoteData.map((item, index) => {
          if (counter < quoteLimit) {
            const xid = item && item.data && item.data.venueXid
            const symbolItem = props.symbolDetailList.find(symbolDetailItem => symbolDetailItem.venueXid === parseInt(xid))

            if (!symbolItem) return
            counter++
            const symbolItemDetails = {
              changePercent: item && item.data && item.data.changePercent && item.data.changePercent.today,
              symbol: symbolItem && symbolItem.Symbol
            }

            return (
              <Symbol item={symbolItemDetails} key={'Symbol' + index} />
            )
          }
        })
      }
    </div>
    )
  }
  return (<div ref={indexListContainer} className={`${styles['articleNewsSymbolContainerIndexList']}`} data-value={'Symbol List Container'}>
    {
      isLoading && <Loader />
    }
    {renderQoutes((props.noOfSymbolsToDisplay) || (device === deviceType.Desktop ? 5 : props.quoteData.length))}
    <div className={`${styles['articleNewsSymbolMore']}`}>
      {
        props.quoteData.length > 0 && isShowMore && <span>+{noOfItemsNotDrawn} More</span>
      }
    </div>
  </div>)
}
export default SymbolList
SymbolList.propTypes = {
  symbolDetailList: PropTypes.array,
  quoteData: PropTypes.array,
  symbols: PropTypes.array,
  noOfSymbolsToDisplay: PropTypes.number
}
