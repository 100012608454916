import React from 'react'
import styles from './StyleBox.module.scss'
import PropTypes from 'prop-types'
import Square from './Square/Square'
import StyleboxUtility from './StyleboxUtility'

const StyleBox = (props) => {
  const renderSquare = (i, isFocusedCell, rowCell, rowColumnLabel, columnCell, lastRowlabel) => {
    return <Square
      key={i}
      keyVal={i}
      isFocusedCell={isFocusedCell}
      rowCell={rowCell}
      rowColumnLabel={rowColumnLabel}
      columnCell={columnCell}
      lastRowlabel={lastRowlabel}
    />
  }

  const styleBox = []
  for (let i = 0; i < 4; i++) {
    const squareRows = []
    const rowColumnLabel = StyleboxUtility.getRowColumnLabel(i, props.isEquity)
    for (let j = 0; j < 4; j++) {
      const rowCell = StyleboxUtility.getRowCellStyle(j)
      const columnCell = (i === 3) ? StyleboxUtility.getColumnCellStyle(j) : ''
      let lastRowlabel = (i === 3) ? StyleboxUtility.getLastRowLabel(j, props.isEquity) : ''
      let [index] = StyleboxUtility.rowColumnDataMapping.filter(x => x.value === props.stats)
      const isFocusedCell = !!(index.row === i && index.column === j)
      squareRows.push(renderSquare((i * 4) + j, isFocusedCell, rowCell, rowColumnLabel, columnCell, lastRowlabel))
    }
    styleBox.push(<div className={styles.styleboxcontainer} key={i}>{squareRows}</div>)
  }

  return (
    <div className={styles.styleBoxMainContainer}>
      {styleBox}
    </div>
  )
}

StyleBox.propTypes = {
  stats: PropTypes.any,
  isEquity: PropTypes.bool
}
export default StyleBox
