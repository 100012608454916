import React from 'react'
import Toggle from '../../Lib/Toggle/Toggle'
const ToggleControl = () => {
  return (
    <div>
      <Toggle label='Realtime' />
    </div>
  )
}
export default ToggleControl
