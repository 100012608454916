import React, {useState} from 'react'
import Tabs from '../../Lib/Tabs/Tabs'
const TestComponentTabControl = () => {
  return (
    <div>
      <TabsSample />
    </div>
  )
  function TabsSample () {
    const [activeSelectedTab, setactiveSelectedTab] = useState('')
    return (
      <div>
        <Tabs id={'MainPage_tab'} ariaLabel={'Finanace tabs'} activeTab={'Market Overview'} onClick={k => setactiveSelectedTab(k)}>
          <div key='MarketOverview' label='Market Overview'>
            Slide 1 - {activeSelectedTab} This is Market Overview page
          </div>
          <div key='EconomicOverview' label='Economic Overview'>
            Slide 2 - {activeSelectedTab} This is Economic Overview page
          </div>
          <div key='NewsAndResearch' label='News & Research'>
            Slide 3 - {activeSelectedTab}
          </div>
        </Tabs>
      </div>
    )
  }
}
export default TestComponentTabControl
