const KEYCODE = {
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
  TAB: 9,
  ENTER: 13
}

export const onKeyDown = (event) => {
  switch (event.keyCode) {
    case (KEYCODE.DOWN):
      event.preventDefault()
      focusNextItem()
      break
    case (KEYCODE.UP):
      event.preventDefault()
      focusPreviousItem()
      break
    case (KEYCODE.RIGHT):
      event.preventDefault()
      focusNextItem()
      break
    case (KEYCODE.LEFT):
      event.preventDefault()
      focusPreviousItem()
      break
    case (KEYCODE.TAB):
      if (event.key === 'Tab' && event.shiftKey) {
        event.preventDefault()
        focusPreviousTabElement()
      } else {
        event.preventDefault()
        focusNextTabElement()
      }
      break
    default:
      break
  }
}

// set -1 value for all the siblings
export const onClick = (event, handler, focusOnElementId) => {
  const item = document.activeElement
  item.tabIndex = 0
  let nextSibling = item.nextElementSibling
  while (nextSibling) {
    nextSibling.tabIndex = -1
    nextSibling = nextSibling.nextElementSibling
  }
  let previousSibling = item.previousElementSibling
  while (previousSibling) {
    previousSibling.tabIndex = -1
    previousSibling = previousSibling.previousElementSibling
  }
  handler(event)
  if (focusOnElementId && document.getElementById(focusOnElementId)) {
    document.getElementById(focusOnElementId).focus()
  }
}

// Figure out if the current element has a next sibling.
// If so, moving focus to it.
export const focusNextItem = () => {
  const item = document.activeElement
  let nextSibling = item.nextElementSibling
  while (nextSibling && nextSibling.disabled) {
    nextSibling.tabIndex = -1
    nextSibling = nextSibling.nextElementSibling
  }
  if (nextSibling) {
    if (item.attributes['data-individual'] && item.attributes['data-individual'].value === '1') {
      item.tabIndex = 0
    } else {
      activate(nextSibling)
      item.tabIndex = -1
    }
  }
}

// Figure out if the current element has a previous sibling.
// If so, moving focus to it.
export const focusPreviousItem = () => {
  const item = document.activeElement
  let previousSibling = item.previousElementSibling
  while (previousSibling && previousSibling.disabled) {
    previousSibling = previousSibling.previousElementSibling
  }
  if (previousSibling) {
    if (item.attributes['data-individual'] && item.attributes['data-individual'].value === '1') {
      item.tabIndex = 0
    } else {
      activate(previousSibling)
      item.tabIndex = -1
    }
  }
}

// This is where the roving tabindex magic happens!
export const activate = (item) => {
  // Set all of the buttons to tabindex -1
  // eslint-disable-next-line no-return-assign
  // document.querySelectorAll('button').forEach((btn) => btn.tabIndex = -1)
  // Make the current button "active"
  item.tabIndex = 0
  item.focus()
}

// Figure out if we have tab element present
// If so, moving focus to it.
export const focusNextTabElement = () => {
  // add all elements we want to include in our selection
  var focussableElements = '[tabindex]:not([disabled]):not([tabindex="-1"])'
  if (document.activeElement) {
    var focussable = Array.prototype.filter.call(document.querySelectorAll(focussableElements),
      function (element) {
        // check for visibility while always include the current activeElement
        return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
      })
    var index = focussable.indexOf(document.activeElement)
    if (index > -1) {
      var nextElement = focussable[index + 1] || focussable[0]
      nextElement.focus()
    }
  }
}

// Figure out if the previous tab element present
// If so, moving focus to it.
export const focusPreviousTabElement = () => {
  // add all elements we want to include in our selection
  var focussableElements = '[tabindex]:not([disabled]):not([tabindex="-1"])'
  if (document.activeElement) {
    var focussable = Array.prototype.filter.call(document.querySelectorAll(focussableElements),
      function (element) {
        // check for visibility while always include the current activeElement
        return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
      })
    var index = focussable.indexOf(document.activeElement)
    if (index > 0) {
      var nextElement = focussable[index - 1] || focussable[0]
      nextElement.focus()
    }
  }
}

// Figure out if the element present
// If so, moving focus to it.
export const focusOnElement = (focusOnEnterID) => {
  // add all elements we want to include in our selection
  if (focusOnEnterID && document.getElementById(focusOnEnterID)) {
    document.getElementById(focusOnEnterID).focus()
  }
}

/**
 * Method used to take action for focus move or enter
 */
export const keyEvents = (e, handler, loadContent, focusOnEnterID, escapeEvent, doNotExecuteOnKeyDown) => {
  e.preventDefault()
  if (e.key === 'Enter' && e.keyCode === KEYCODE.ENTER) {
    if (handler && handler !== null) {
      handler(e)
    }
    if (focusOnEnterID && focusOnEnterID !== null) {
      focusOnElement(focusOnEnterID)
    }
  } else if (e.key === 'Escape' && escapeEvent && escapeEvent !== null) {
    escapeEvent(e)
  } else {
    if (!doNotExecuteOnKeyDown) {
      onKeyDown(e)
      if (loadContent && handler && handler !== null) {
        handler(e)
      }
    }
  }
}

export function enterKeyEvent (e, handler) {
  e.preventDefault()
  if (e.key === 'Enter' && e.keyCode === 13) {
    handler(e)
  }
}
