/* eslint-disable no-debugger */
import PropTypes from 'prop-types'
import React from 'react'
import * as F2Methods from '../../utils/f2Methods'

export default class F2AppContainer extends React.PureComponent {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    context: PropTypes.object,
    rootId: PropTypes.string,
    removeApp: PropTypes.bool
  }

  componentDidMount () {
    this.loadApp()
  }

  componentDidUpdate () {
    this.props.removeApp ? F2Methods.removeApp(this.instanceId) : this.loadApp()
  }

   encodeContext =(context = {}) => {
     let temp = {}
     Object.keys(context).map((key) => {
       if (typeof context[key] === 'object') {
         let list = context[key]
         Object.keys(list).map((item) => {
           list[item] = encodeURIComponent(list[item])
         })
         temp[key] = list
       } else { temp[key] = encodeURIComponent(context[key]) }
     })
     return temp
   }
   loadApp () {
     let data = {
       appId: this.props.appId,
       context: this.encodeContext(this.props.context),
       rootId: this.props.rootId
     }

     this.instanceId = F2Methods.loadApp(data.appId, data.rootId, data.context, this.instanceId)
   }

   render () {
     return <div id={this.props.rootId} />
   }
}
