import React, { useState, useEffect, Fragment } from 'react'
import styles from './SnapshotStrategy.module.scss'
import { URLS, DEFAULT_VALUE, StrategyTypes } from '../../utils/appConstants'
import Loader from '../../../../../Lib/common/Loader'
import NoData from '../../../../../Lib/NoData/NoData'
import ModalPopUp from '../../../../../Lib/ModalPopUp/ModalPopUp'
import SnapshotStrategyModalDescription from './SnapshotStrategyModalDescription/SnapshotStrategyModalDescription'
import SnapshotStrategyModalHeader from './SnapshotStrategyModalHeader/SnapshotStrategyModalHeader'
import { deviceType, handleWindowResize, closeModalPopup, hideScroll, getAPIData, getDeviceType } from '../../utils/utilities'
import ShowMoreText from 'react-show-more-text'
import PropTypes from 'prop-types'

const SnapshotStrategy = ({xid, moduleName, header = 'Fund Strategy', viewMoreText = 'Read Strategy'}) => {
  const [lineNumber, setLineNumber] = useState(5)
  const [investmentStrategy, setInvestmentStrategy] = useState('')
  const [modalOpen, setModalState] = useState(false)
  const [isLoadingAndError, setIsLoadingAndError] = useState({ isLoading: true, isError: false })
  const spinnerStyle = { 'minWidth': 'auto', 'height': 'auto' }
  const deafultStyle = {'height': 'auto'}
  const modelStyleCustom = (moduleName === 'ModelsSnapshot' ? { 'height': '382px', 'width': '419px' } : deafultStyle)
  const [modelStyle, setModelStyle] = useState(modelStyleCustom)

  useEffect(() => {
    let deviceItem = getDeviceType()
    if (deviceItem === deviceType.Mobile) {
      setModelStyle(deafultStyle)
    }
    handleWindowResize((response) => {
      setModelStyle(modelStyleCustom)
      if (response === deviceType.Desktop) {
        setLineNumber(5)
      } else if (response === deviceType.Ipad) {
        setLineNumber(3)
      } else if (response === deviceType.Mobile) {
        setModelStyle(deafultStyle)
        setLineNumber(4)
      }
    })
  }, [])

  useEffect(() => {
    getFundStrategyData()
    return () => {
      setIsLoadingAndError({ isError: false, isLoading: true })
    }
  }, [xid])

  const investmentStrategyDataByLine = () => {
    if (investmentStrategy.length > 0) {
      return <Fragment>
        <ShowMoreText
          lines={lineNumber}
          more={viewMoreText}
          less=''
          expanded={false}
          onClick={e => readFundStrategy(e)}
          expandByClick={false}
          anchorClass={styles.fundStrategyReadMore}
        >
          {investmentStrategy}
        </ShowMoreText>
      </Fragment>
    }

    return DEFAULT_VALUE
  }

  const getFundStrategyData = () => {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        const responseData = response.data
        if (responseData && responseData[StrategyTypes[header]]) {
          setInvestmentStrategy(responseData[StrategyTypes[header]])
          setIsLoadingAndError({ isError: false, isLoading: false })
        } else {
          setIsLoadingAndError({ isError: true, isLoading: false })
        }
      } else {
        setIsLoadingAndError({ isError: true, isLoading: false })
      }
    }

    getAPIData(xid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_STRATEGY, callback, `isCaseSensitive: false`, true)
  }

  const closePopup = (e) => {
    if (closeModalPopup(e)) {
      setModalState(false)
      hideScroll(false)
    }
  }

  const readFundStrategy = (e) => {
    if (!modalOpen) {
      setModalState(true)
      hideScroll(true)
    }
  }

  if (isLoadingAndError.isLoading) {
    return (
      <div className={styles.fundStrategyContainer}>
        <div className={styles.fundStrategyHeader} id='strategyHeader' >{header}</div>
        <Loader spinnerStyle={spinnerStyle} ready={false} msg={'Loading'} spinnerSize={'2x'} />
      </div>
    )
  }
  if (isLoadingAndError.isError) {
    return (
      <div className={styles.fundStrategyContainer}>
        <div className={styles.fundStrategyHeader} id='strategyHeader' >{header}</div>
        <NoData />
      </div>
    )
  }
  return (
    <div className={styles.fundStrategyContainer}>
      <div className={styles.fundStrategyHeader} id='strategyHeader' >{header}</div>
      <div className={styles.fundStrategyDetail}>{investmentStrategyDataByLine()}
      </div>
      {modalOpen &&
        <ModalPopUp stylesContent={modelStyle} stylesPopUpMain={{ 'top': '0' }} stylesPopUp={{ 'minHeight': 'auto' }} onClose={closePopup} customHeader >
          <SnapshotStrategyModalHeader header={header} onClose={closePopup} />
          <SnapshotStrategyModalDescription description={investmentStrategy} />
        </ModalPopUp>}
    </div>
  )
}

SnapshotStrategy.propTypes = {
  xid: PropTypes.any,
  header: PropTypes.string,
  viewMoreText: PropTypes.string,
  moduleName: PropTypes.string
}
export default SnapshotStrategy
