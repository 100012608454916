/* eslint-disable no-debugger */
import React, {useEffect, useState} from 'react'
import styles from './MarketHighlightsNews.module.scss'
import Loader from '../../../Lib/common/Loader'
import {deviceType, getDeviceType, handleWindowResize} from '../../../../utils/utilities'
import Carousel from '../../../Lib/Carousal/Carousel'
import {registerAccessTokenUpdatedF2OnEvent} from '../../../../utils/f2Methods'
import DataRequest from '../../../../services/DataRequest'
import {URLS} from '../../../../utils/appConstants'
import MarketHighlightsNewsItem from './MarketHighlightsNewsItem/MarketHighlightsNewsItem'
import {MARKET_HIGHLIGHTS_NEWS_ITEM} from './MarketHighlightsNewsConstants'

function MarketHighlightsNews () {
  const [device, setDevice] = useState(getDeviceType())
  const [marketHighlightsNewsData, setMarkethighlightsData] = useState(setInitialState())

  useEffect(() => {
    handleWindowResize((response) => { setDevice(response) })

    getMarketHighlightsNewsData()

    registerAccessTokenUpdatedF2OnEvent(() => {
      setMarkethighlightsData(setInitialState())
      getMarketHighlightsNewsData()
    })
  }, [])

  function setInitialState () {
    return {
      isLoading: true,
      data: null
    }
  }

  function getMarketHighlightsNewsData () {
    let callbackFunction = (response) => {
      if (!response.isError) {
        let data =
              response &&
              response.data &&
              response.data.researchItems
        setMarkethighlightsData({
          isLoading: false,
          data: data,
          isError: false
        })
      } else {
        setMarkethighlightsData({ isLoading: false, data: [], isError: true })
      }
    }

    DataRequest.execute(
      URLS.CUSTOM.RESEARCH,
      {
        params: {
          isMarketWatchNeeded: 'true'
        }
      },
      callbackFunction
    )
  }

  function getBody () {
    return marketHighlightsNewsData.data && marketHighlightsNewsData.data.map((item, idx) => {
      item.title = MARKET_HIGHLIGHTS_NEWS_ITEM.TITLE && MARKET_HIGHLIGHTS_NEWS_ITEM.TITLE[idx]
      return (
        <React.Fragment key={idx}>
          <MarketHighlightsNewsItem item={item} idx={idx} />
        </React.Fragment>
      )
    })
  }

  function marketHighlightsNewsBody () {
    if (marketHighlightsNewsData.isLoading) {
      return <Loader spinnerSize={'2x'} ready={false} />
    } else if (device === deviceType.Desktop || device === deviceType.Ipad) {
      return getBody()
    } else {
      return <Carousel id={styles.marketHighlightsNewsCarousel} isTiltEnable>{getBody()}</Carousel>
    }
  }

  return (
    <div id={'marketHighlightsNewsContainerParent'} className={`${styles.marketHighlightsNewsContainerParent}`}>
      <div className={`${styles.marketHighlightsNewsContainer} ${marketHighlightsNewsData.isLoading ? styles.marketHighlightsNewsLoader : ''} `}>
        { marketHighlightsNewsBody() }
      </div>
    </div>
  )
}

export default MarketHighlightsNews
