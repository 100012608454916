import React, {useState, useEffect, useRef} from 'react'
import Modal from '../Modal/components/Modal'
import ModalBody from '../Modal/components/ModalBody'
import ModalHeader from '../Modal/components/ModalHeader'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from '../../Shared/Modal/Modal.module.scss'
import Search from '../../Shared/Search/Search'
import DetailsCard from '../../Shared/DetailsCard/DetailsCard'
import { URLS, DEFAULT_PRE_SCREEN_INPUTS, MODEL_SCREEN_TYPE, FUND_SCREEN_TYPE, AI_SCREEN_TYPE, EQUITY_SCREEN_TYPE, PRESCREENER_EQUITYSCREENER } from '../../../components/utils/appConstants'
import DataRequest from '../../../../../../services/DataRequest'
import Loader from '../../../../../Lib/common/Loader'
import ScreenerHelper from '../../Helpers/ScreenerHelper'
import BasicDropDown from '../../../../../Lib/Dropdown/BasicDropDown'
import ConfirmationPopup from '../../../../../Watchlists/View/WatchlistStocks/ConfirmationPopup/ConfirmationPopup'
import { hideScroll } from '../../../../../../utils/utilities'
import { fireToastMessage } from '../../../../../Lib/CustomToast.js/CustomToast'
export default function OpenScreenerPopup (props) {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showKababMenu, setShowKebabMenu] = useState(props.showActionKebabMenu)
  const [errorMsgTxt, setErrorMsgTxt] = useState(props.errorMsg)
  const [id, setId] = useState(0)
  const [deleteScreenerId, setDeleteScreenerId] = useState(-1)
  let [noresultsSearch, setnoresultsSearch] = useState(false)
  const savedScreenName = useRef([])
  const mockdata = useRef([])
  const allScreenerData = useRef([])
  let [showLoader, setShowLoader] = useState(true)
  const itemCounter = useRef(0)
  const screenerSelectList = ['Saved Screeners', 'Predefined Screeners']

  useEffect(() => {
    setErrorMsgTxt(props.errorMsg)
    getScreenData()
  }, [props.errorMsg])

  const buildPreScreenInputs = (screenInput) => {
    const preScreenInputs = DEFAULT_PRE_SCREEN_INPUTS
    preScreenInputs.arguments = []
    let sortField = MODEL_SCREEN_TYPE
    switch (props.screenId) {
      case '449':
        sortField = 'PERMPrgramPrductMgrCodeStrtegy'
        break
      case '450':
        sortField = 'SPCFticker'
        break
      case '452':
        sortField = 'PEREQSymbol'
        break
      default:
        sortField = 'PERAICusip'
        break
    }
    preScreenInputs.sortArguments = [{
      direction: 'A',
      field: sortField
    }]
    screenInput.forEach(element => {
      if (element) {
        preScreenInputs.arguments.push(element)
      }
    })
    return {
      method: 'POST',
      postData: preScreenInputs
    }
  }

  const getSavedScreenerCount = (element, isPreScreen) => {
    return new Promise((resolve) => {
      let screenType = MODEL_SCREEN_TYPE
      switch (props.screenId) {
        case '449':
          screenType = MODEL_SCREEN_TYPE
          break
        case '450':
          screenType = FUND_SCREEN_TYPE
          break
        case '452':
          screenType = EQUITY_SCREEN_TYPE
          break
        default:
          screenType = AI_SCREEN_TYPE
          break
      }
      let callback = (response) => {
        let responseData = response && response.data && response.data.items
        let itemCount = response.data ? response.data.items.length : 0
        let nameMatchingCount = {
          'screenerName': element.name,
          'count': itemCount !== 0 ? (screenType === MODEL_SCREEN_TYPE ? response.data.items.length : response.data.items[itemCount - 1].cumulativeMatches) : 0,
          results: element,
          isPreScreener: isPreScreen
        }
        mockdata.current.push(nameMatchingCount)
        allScreenerData.current = mockdata.current
        if (itemCounter.current === allScreenerData.current.length) {
          setShowLoader(false)
        }
        setId(Math.random())
        resolve(responseData)
      }
      const inputs = (isPreScreen) ? getStaticPreScreenInput(screenType, element.value) : getPreScreenInput(screenType, element)
      DataRequest.execute(
        screenType === MODEL_SCREEN_TYPE ? `${URLS.CUSTOM.SCREEN + '?screenType=' + screenType}` : `${URLS.CUSTOM.PRESCREEN + '?screenType=' + screenType}`,
        inputs,
        callback,
        null)
    })
  }
  const getStaticPreScreenInput = (screenType, element) => {
    if (screenType === EQUITY_SCREEN_TYPE) {
      return {
        method: 'POST',
        postData: element
      }
    }
  }
  const getPreScreenInput = (screenType, element) => {
    if (screenType === MODEL_SCREEN_TYPE) {
      return ScreenerHelper.buildPreScreenModalInputs(props.screenId, element.arguments[0].screenArguments)
    } else if (screenType === FUND_SCREEN_TYPE || screenType === EQUITY_SCREEN_TYPE) {
      if (element.arguments[0].screenArguments.length === 0) { // hack to handle prescreen call when no condition is saved, bcoz of platform API issue
        return buildPreScreenInputs([{'field': screenType === FUND_SCREEN_TYPE ? 'SPCFticker' : 'PEREQSymbol', 'conditions': []}])
      } else {
        return buildPreScreenInputs(element.arguments[0].screenArguments)
      }
    } else {
      return buildPreScreenInputs(element.arguments[0].screenArguments)
    }
  }

  const getScreenData = () => {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        // to wriite logic to get filters and send to get the cumulative matches count
        savedScreenName.current = []
        let promiseArray = []
        let item = response.data
        if (response.data.length === 0) {
          setShowLoader(false)
        }
        item.forEach(element => {
          if (element.arguments[0] && element.arguments[0].screenArguments) {
            if (element.arguments.length > 0) {
              itemCounter.current++
              promiseArray.push(getSavedScreenerCount(element))
            }
          }
        })
      }
    }

    DataRequest.execute(
      `${URLS.PLATFORM.SAVEDSCREENS}/` + props.screenId,
      {},
      callback,
      null
    )
  }
  const getPreScreenData = (isPreScreen) => {
    // to wriite logic to get filters and send to get the cumulative matches count
    savedScreenName.current = []
    let promiseArray = []
    const item = PRESCREENER_EQUITYSCREENER
    item.forEach(element => {
      if (element.value.arguments.length > 0) {
        itemCounter.current++
        // promiseArray.push(getPreScreenerCount(element))
        promiseArray.push(getSavedScreenerCount(element, isPreScreen))
      }
    })
    setShowLoader(false)
  }

  function searchTextChanged (value) {
    let data = allScreenerData.current.filter(item => item.screenerName.toUpperCase().includes(value.toUpperCase()))
    if (data.length === 0) {
      setnoresultsSearch(true)
      mockdata.current = []
    }
    mockdata.current = data
    setId(Math.random())
  }

  function setNoresultsSearch (value) {
    mockdata.current = allScreenerData.current
    setnoresultsSearch(value)
    setId(Math.random())
  }

  const dropDownChangeHandler = (e) => {
    const selectedValue = e.getAttribute('data-value')
    mockdata.current = []
    allScreenerData.current = []
    itemCounter.current = 0
    setId(Math.random())
    if (selectedValue === screenerSelectList[0]) { // Saved Screeners Action method here
      setShowLoader(true)
      setShowKebabMenu(props.showActionKebabMenu)
      mockdata.current = []
      getScreenData()
    } else { // Predefined Screeners Action method here
      setShowKebabMenu(false)
      getPreScreenData(true)
    }
  }

  const screenerLabelOrSelect = () => {
    if (props.showSavedAndPredefinedScreener) {
      return <div className={styles.screenerSelect}>
        <BasicDropDown data={screenerSelectList} changeHandler={dropDownChangeHandler} onlyBottomBorder />
      </div>
    } else {
      return <div className={styles.lblSavedScreener} aria-label='Saved Screeners'>{mockdata.current.length > 0 ? 'Saved Screeners' : 'No Results Found'}</div>
    }
  }

  // Action handler for Kebab menu of Detail card component
  const detailCardActionHandler = (e, item) => {
    const selectedVal = e.currentTarget.innerHTML
    if (selectedVal === 'Delete Screener') {
      setDeleteScreenerId(item.id)
      setShowConfirmation(true)
    } else if (selectedVal === 'Edit Screener Details') {
      props.editScreenData(item)
    }
  }

  // Handler for confirmation of delete screener
  const deleteConfirmationHandler = (e) => {
    setShowConfirmation(false)
    hideScroll(false)
    setShowLoader(true)
    deleteScreener()
  }

  // Make api call to delete the saved screener
  const deleteScreener = () => {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        // to wriite logic to remove the saved filter as it has been removed
        allScreenerData.current = allScreenerData.current.filter(x => x.results.id !== deleteScreenerId)
        mockdata.current = allScreenerData.current
        setId(Math.random())
        props.deleteBtnHandler(deleteScreenerId)
        fireToastMessage({
          message: 'Screener has been deleted'
        })
      }
      setShowLoader(false)
    }
    DataRequest.execute(
      `${URLS.PLATFORM.SAVEDSCREENS}/` + props.screenId + '/' + deleteScreenerId,
      {
        method: 'DELETE'
      },
      callback,
      null
    )
  }

  return (
    <Modal close={props.close} id='openScreenModal' >
      <ModalHeader modalName={'openScreenModal'}>
        <div className={styles.header}>
          <div id={id} className={styles.openModalheaderText} aria-label={props.header}>{props.header}</div>
        </div>
        <div className={styles.closeSection}>
          <button className={styles.btnPopUpClose} style={{'marginTop': '13.5px', 'marginBottom': '13.5px'}} aria-label='Close' onClick={props.close}>
            <FontAwesomeIcon icon={['fal', 'times']} className={styles.btnPopUpClose} id='closeButtonFontAwsome' />
          </button>
        </div>
      </ModalHeader>
      <ModalBody modalName={'openScreenModal'}>
        {showLoader
          ? <Loader ready={false} spinnerStyle={{'minWidth': '220px', 'height': '395px'}} spinnerSize={'2x'} />
          : <div><div className={styles.errroMsg} aria-label={errorMsgTxt}>{errorMsgTxt}</div>
            <Search data={[]} setSearchText={mockdata.current} noresults={noresultsSearch} setNoResultsSearch={setNoresultsSearch} searchTextChanged={searchTextChanged} searchTextLabel='Search by screener name' />
            {screenerLabelOrSelect()}
            <DetailsCard searchResults={mockdata.current} openBtnHandler={props.openBtnHandler}
              showActionKebabMenu={showKababMenu} actionKebabHandler={detailCardActionHandler} />
          </div>
        }
      </ModalBody>
      {showConfirmation && <div id='DeleteConfirmation'><ConfirmationPopup headerInfo={'Delete Screener'} confirmationHandler={deleteConfirmationHandler}
        closeHandler={() => { setShowConfirmation(!showConfirmation) }} bodyContent={'Are you sure you want to delete your saved screener?<br/>This action cannot be undone.'} confirmButtonText={'Delete'} /></div>}
    </Modal>
  )
}

OpenScreenerPopup.propTypes = {
  close: PropTypes.func,
  errorMsg: PropTypes.string,
  header: PropTypes.string,
  openBtnHandler: PropTypes.func,
  screenId: PropTypes.string,
  showSavedAndPredefinedScreener: PropTypes.bool,
  showActionKebabMenu: PropTypes.bool,
  editScreenData: PropTypes.func,
  deleteBtnHandler: PropTypes.func
}
