
import React, {useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './SnapshotRatings.module.scss'
import { URLS, EtfSnapshotRating, DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS, FUND_SCREEN_TYPE } from '../../utils/appConstants'
import Loader from '../../../../../Lib/common/Loader'
import { ETF_RATINGS_CONSTANTS, OVERALL_MEDALIST_RATING } from './SnapshotRatingConstants'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { formatDate, getCustomRatingDescription } from '../../utils/formatter'
import { getAPIData } from '../../utils/utilities'
import DataRequest from '../../../../../../services/DataRequest'
import { DATE_FORMAT } from '../../../../../../utils/appConstants'
const SnapshotRatings = ({xid, analystRatingsData={}}) => {
  const [ratingsData, setRatingsData] = useState(setInitialState())
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  function resetStates () {
    setRatingsData(setInitialState())
    setIsError(false)
    setIsLoading(true)
  }
  useEffect(() => {
    // Whenever fund changes - resets states of each sub-component
    resetStates()
    getOverallRatings(xid)
    getScreenerData()
  }, [xid])

  useEffect(() => {
    analystRatingsData && Object.keys(analystRatingsData).length > 0 && getAnalystRatings()
  }, [analystRatingsData])

  const getScreenerData = () => {
    const screenDataCallback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError && response.data && response.data.items.length) {
        let item = response.data.items[0]
        let updatedState = {...ratingsData}
        Object.keys(ETF_RATINGS_CONSTANTS.LABEL_URL_MAPPING).forEach((key) => {
          if (key === OVERALL_MEDALIST_RATING) {
            updatedState[key].text = item.SPCFMedalistRating || '--'
            updatedState[key].asOf = formatDate(item.SPCFMedalistRatingDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true})
            updatedState[key].analystDriven = item.SPCFMedalistRatingAnalystDrivenPct || '--'
            updatedState[key].dataCoverage = item.SPCFMedalistRatingDataAvailabilityPct || '--'
          }
        })
        setRatingsData(updatedState)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        setIsError(response.error)
      }
    }
    let newScreenerArguments = [
      {
        field: 'SPCFWSODIssue',
        conditions: [
          {
            operator: 'EqualTo',
            values: [`${xid}`]
          }
        ]
      }
    ]

    DataRequest.execute(
      `${URLS.CUSTOM.SCREEN}?screenType=${FUND_SCREEN_TYPE}`,
      buildScreenInputs(newScreenerArguments),
      screenDataCallback
    )
  }
  const buildScreenInputs = (screenerArgs) => {
    let preScreenInputs = DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS
    preScreenInputs.arguments = screenerArgs || []
    preScreenInputs.resultFields = EtfSnapshotRating
    return {
      method: 'POST',
      postData: preScreenInputs
    }
  }

  const getOverallRatings = (xid) => {
    let callback = (response) => {
      if (!response.isDataRequestComplete) {
        return
      }
      if (!response.isError) {
        let updatedState = {...ratingsData}
        Object.keys(ETF_RATINGS_CONSTANTS.LABEL_URL_MAPPING).forEach((key) => {
          if (key === 'Overall Rating') {
            const valueMapping = response.data.items.filter(item => item.dataPoint === 'rating')
            const asOfDate = response.data.metaData.dateAsOfRating
            updatedState[key].rating = valueMapping[0].values[0]
            updatedState[key].asOf = asOfDate
          }
          if (key === 'Overall Risk Rating') {
            const valueMapping = response.data.items.filter(item => item.dataPoint === 'riskRating')
            const asOfDate = response.data.metaData.dateAsOfRating
            updatedState[key].text = getCustomRatingDescription(valueMapping[0].values[0])
            updatedState[key].asOf = asOfDate
          }
        })
        setRatingsData(updatedState)
        setIsLoading(false)
      } else {
        setIsError(true)
        setIsLoading(false)
      }
    }
    getAPIData(xid, URLS.PLATFORM.MANAGEDFUNDS_MORNINGSTAR_RATING_RISK, callback, {periodTypes: 'overall'}, true)
  }

  const getAnalystRatings = () => {
      const updatedState = {...ratingsData}
      updatedState['Overall Sustainability'].text = '--'
      updatedState['Overall Sustainability'].asOf = '--'
      if (!analystRatingsData?.isError) {
        Object.keys(ETF_RATINGS_CONSTANTS.LABEL_URL_MAPPING).forEach((key) => {
          if (key === 'Overall Sustainability') {
            const valueMapping = analystRatingsData.data.fund?.rating?.sustainabilityRating
            updatedState[key].text = getCustomRatingDescription(valueMapping)
            updatedState[key].asOf = analystRatingsData?.data?.ratingDate
          }
        })
      }
      setRatingsData(updatedState)
      setIsLoading(false)
  }
  function setInitialState () {
    let dataObj = {}
    Object.keys(ETF_RATINGS_CONSTANTS.LABEL_URL_MAPPING).forEach((key) => {
      dataObj[key] = {
        text: '',
        rating: '',
        asOf: ''
      }
      if (key === OVERALL_MEDALIST_RATING) {
        dataObj[key].analystDriven = ''
        dataObj[key].dataCoverage = ''
      }
    })
    return dataObj
  }
  const renderTiles = () => {
    let tiles = []
    Object.keys(ETF_RATINGS_CONSTANTS.LABEL_URL_MAPPING).forEach((key, index) => {
      if (key === OVERALL_MEDALIST_RATING) {
        tiles.push(renderMedalistTile(ratingsData[key], index, key))
      } else {
        tiles.push(renderTile(ratingsData[key], index, key))
      }
    })
    return tiles
  }

  const renderMedalistTile = (actualData, index, key) => {
    return (<div className={`${styles.breakTile} ${styles.tileContainer}`} key={index}>
      <div className={styles.tileHeader} id={`ratingHeader${index}`}>
        {key}<sup>&trade;</sup>
      </div>
      { isLoading ? <Loader msg={'Loading'} spinnerSize={'2x'} />
        : <React.Fragment>
          <div className={styles.tilesDetails}>
            <div className={styles.tilesSubDetails}>
              <div className={styles.textMaster} title={actualData.text}>
                {!isError ? actualData.rating > 0 ? renderRatings(parseInt(actualData.rating)) : actualData.text : '--'}
              </div>
              <div className={styles.asOfDate}>As of {!isError ? formatDate(actualData.asOf, {format: 'MMM DD, YYYY'}) : '--'}</div>
            </div>
            <div className={styles.tilesSubDetails}>
              <div className={styles.tilesRightSecDetails}>
                <div className={styles.tilesRightSubDetails}>
                  <div className={styles.titleText}>
                    {'Analyst'}
                  </div>
                  <div className={styles.titleText}>
                    {'-Driven'}
                  </div>
                  <div className={styles.titleValue}>
                    {!isError ? `${actualData.analystDriven}%` : '--'}
                  </div>
                </div>
                <div className={styles.tilesRightSubDetails}>
                  <div className={styles.titleText}>
                    {'Data'}
                  </div>
                  <div className={styles.titleText}>
                    {'Coverage'}
                  </div>
                  <div className={styles.titleValue}>
                    {!isError ? `${actualData.dataCoverage}%` : '--'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>

      }
    </div>)
  }

  const renderTile = (actualData, index, key) => {
    return (<div className={`${styles.tileContainer}`} key={index}>
      <div className={styles.tileHeader} id={`ratingHeader${index}`}>
        {key}
      </div>
      { isLoading ? <Loader msg={'Loading'} spinnerSize={'2x'} />
        : <React.Fragment>
          <div className={styles.textMaster} title={actualData.text}>
            {!isError ? actualData.rating > 0 ? renderRatings(parseInt(actualData.rating)) : actualData.text : '--'}
          </div>
          <div className={styles.asOfDate}>As of {!isError ? formatDate(actualData.asOf, {format: 'MMM DD, YYYY'}) : '--'}</div>
        </React.Fragment>

      }
    </div>)
  }

  const renderRatings = (ratings) => {
    let i = 1
    const ratingsArray = []
    while (i <= ratings) {
      ratingsArray.push(<span className={styles.rating} key={'rating_' + i}>
        <FontAwesomeIcon
          icon={['fas', 'star']}
          color='black'
        />
      </span>)
      i++
    }
    while (i <= 5) {
      ratingsArray.push(<span className={styles.rating} key={'rating_' + i}>
        <FontAwesomeIcon
          icon={['fal', 'star']}
          color='black'
        />
      </span>)
      i++
    }
    return <React.Fragment>{ratingsArray}</React.Fragment>
  }
  return (
    <div className={styles.etfRatingsContainer}>
      <div className={styles.header} id='RatingsHeader' >{ETF_RATINGS_CONSTANTS.HEADER}</div>
      <div className={styles.etfRatings}>
        {renderTiles()}
      </div>
      <div className={styles.text} id='additionalText' >Powered by Morningstar</div>
    </div>
  )
}

export default SnapshotRatings
SnapshotRatings.propTypes = {
  xid: PropTypes.any,
  analystRatingsData: PropTypes.object
}
