import React from 'react'
import styles from './Button.module.scss'
import PropTypes from 'prop-types'
import Button from './Button'

const PrimaryButton = ({buttonText, clickHandler, dataTestId, tabIndex, disabled, keyDownHandler, closeBtn, cancelBtn}) => {
  return (<Button
    className={styles.applyButton}
    tabIndex={tabIndex}
    buttonDataTestId={dataTestId}
    onKeyDownEnter={keyDownHandler || clickHandler}
    clickHandler={clickHandler}
    labelText={buttonText}
    closeBtn = {closeBtn}
    cancelBtn={cancelBtn}
    disabled={disabled}
    title={buttonText} />)
}
PrimaryButton.propTypes = {
  buttonText: PropTypes.string,
  clickHandler: PropTypes.func,
  dataTestId: PropTypes.string,
  tabIndex: PropTypes.string,
  disabled: PropTypes.bool,
  keyDownHandler: PropTypes.func,
  closeBtn: PropTypes.any,
  cancelBtn: PropTypes.any
}
export default PrimaryButton
