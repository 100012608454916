import React, { useState, useEffect } from 'react'
import styles from './HoldingBar.module.scss'
import { handleWindowResize, getDeviceType } from '../../../../../../utils/utilities'
import { URLS } from '../../utils/appConstants'
import PropTypes from 'prop-types'
import { getAPIData } from '../../utils/utilities'
import { formatNumber } from '../../utils/formatter'
import Loader from '../../../../../Lib/common/Loader'

function HoldingBar ({xid, module}) {
  const [isMobile, setIsMobile] = useState(false)
  let [holdingBarData, setHoldingBarData] = useState()
  let [isLoading, setisLoading] = useState(true)

  useEffect(() => {
    setIsMobile(getDeviceType() === 'MOBILE')
    handleWindowResize((deviceType) => {
      setIsMobile(deviceType === 'MOBILE')
    })
  }, [])
  useEffect(() => {
    setisLoading(true)
    if (module === 'models') {
      getRiskMeasuresBarData(xid)
    } else {
      getTopHoldingBarData(xid)
    }
  }, [xid])

  let getTopHoldingBarData = (venueXid) => {
    let callbackFunction = (response) => {
      if (!response.isError && response.data) {
        let data = response.data
        let holdingData = {
          TotalHolding: {
            label: 'Total Holdings',
            value: data.numberOfHoldings.all.net
          },
          StockHolding: {
            label: 'Stock Holdings',
            value: data.numberOfHoldings.stock.net
          },
          BondHolding: {
            label: 'Bond Holdings',
            value: data.numberOfHoldings.bond.net
          },
          Top10Weighting: {
            label: 'Top 10% Weighting',
            value: data.topTenHoldings.weighting + `${'%'}`
          }
        }
        setHoldingBarData(holdingData)
      }
      setisLoading(false)
    }

    getAPIData(venueXid, URLS.PLATFORM.TOP_HOLDING_BAR, callbackFunction)
  }

  let getRiskMeasuresBarData = (venueXid) => {
    let callbackFunction = (response) => {
      if (!response.isError && response.data) {
        if (response.data.fund.nav) {
          let data = response.data.fund.nav
          let riskMeasuresData = {
            Beta3Yr: {
              label: 'Beta (3Yr)',
              value: formatNumber(data.beta.primaryIndex.filter(item => item.temporalUnit === 'Year' && item.temporalPeriod === 3)[0].value, {precision: 2, parentheses: false, negSign: true})
            },
            StandardDeviation3Yr: {
              label: 'Standard Deviation (3 Yr)',
              value: formatNumber(data.standardDeviation.filter(item => item.temporalUnit === 'Year' && item.temporalPeriod === 3)[0].value, {precision: 2, postFix: true, postFixValue: '%', negSign: true})
            },
            SharpeRatio3Yr: {
              label: 'Sharpe Ratio (3Yr)',
              value: formatNumber(data.sharpeRatio.filter(item => item.temporalUnit === 'Year' && item.temporalPeriod === 3)[0].value, {precision: 2, parentheses: false, negSign: true})
            },
            UpsideDownsideCapture3Yr: {
              label: 'Upside/Downside Capture (3 Yr)',
              value: formatNumber(data.captureRatioUp.filter(item => item.temporalUnit === 'Year' && item.temporalPeriod === 3)[0].value, {precision: 2, postFix: true, postFixValue: '%', negSign: true}) + `${' / '}` +
                     formatNumber(data.captureRatioDown.filter(item => item.temporalUnit === 'Year' && item.temporalPeriod === 3)[0].value, {precision: 2, postFix: true, postFixValue: '%', negSign: true})
            }
          }
          setHoldingBarData(riskMeasuresData)
        }
      }
      setisLoading(false)
    }

    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_RISK_MEASURE, callbackFunction)
  }

  return (
    <div>
      {!isMobile && <div className={module === 'models' ? styles.headerContainerModel : styles.headerContainer}>
        {isLoading ? <Loader ready={false} spinnerSize={'2x'} msg={'Loading'} spinnerStyle={{'margin': 'auto'}} />
          : holdingBarData && Object.keys(holdingBarData).map((key) => {
            return (<div key={holdingBarData[key].label} className={styles.datapoint}>
              <div id={`heading-${holdingBarData[key].label}`} className={styles.heading}>{ holdingBarData[key].label}</div>
              <div key={key} id={`value-${holdingBarData[key].value}`} className={module === 'models' ? styles.modelValues : styles.values}>{holdingBarData[key].value} </div>
            </div>)
          })}
      </div>}
      {isMobile && <div className={styles.headerContainerMobile}>
        {isLoading ? <Loader ready={false} spinnerSize={'2x'} msg={'Loading'} spinnerStyle={{'margin': 'auto'}} />
          : holdingBarData && Object.keys(holdingBarData).map((key) => {
            return (<div key={holdingBarData[key].label} className={module === 'models' ? styles.modelDatapointMobile : styles.datapointMobile}>
              <div id={`heading-${holdingBarData[key].label}`} className={`${styles.headingMobile} ${module === 'models' ? styles.headingMobileModel : ''}`}>{ holdingBarData[key].label}</div>
              <div id={`value-${holdingBarData[key].value}`} className={`${styles.valuesMobile} ${module === 'models' ? styles.valuesMobileModel : ''}`}>{holdingBarData[key].value} </div>
            </div>)
          })}
      </div>}
    </div>
  )
}
HoldingBar.propTypes = {
  xid: PropTypes.any,
  module: PropTypes.string
}

export default HoldingBar
