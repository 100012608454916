import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './ESGScores.module.scss'
import ESGScoreBox from './ESGScoreBox/ESGScoreBox'
import ScoreBar from './ESGScoreBar/ScoreBar'
import { formatNumber } from '../../utils/formatter'
import { EVENT_NAMES } from '../../../../../../utils/f2Constants'
import { COLOR_DEFAULT_SET, TAB_NAMES } from '../../../../../../utils/appConstants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useXref } from '../../../../../../hooks/useXref'
import Loader from '../../../../../Lib/common/Loader'
import Nodata from '../../../../../Lib/NoData/NoData'
import LinkButton from '../../../../../Lib/LinkButton/LinkButton'
import { emitF2Event } from '../../utils/f2Methods'

function ESGScores ({symbol, analystRatingsData={}}) {
  const [scoreBoxData, setScoreBoxData] = useState({})
  const [scoreBarData, setScoreBarData] = useState([])
  const [isReady, setIsReady] = useState(false)
  let xref = useXref(symbol)

  useEffect(() => {
    xref.isError && setIsReady(true)
  }, [xref.isError])

  useEffect(() => {
    xref.callData(symbol)
  }, [symbol])

  useEffect(() => {
    analystRatingsData && Object.keys(analystRatingsData).length > 0 && getAnalystRatings()
  }, [analystRatingsData])

  const portfolioHandler = () => {
    emitF2Event(EVENT_NAMES.GOTO_FUND_PROFILE_TAB, {activeTabKey: TAB_NAMES.PORTFOLIO})
  }

  const checkData = (data) => {
    if (data && data.fund && data.fund.rating && data.fund.score && data.category && data.category.score) {
      return true
    } else {
      return false
    }
  }

  const getAnalystRatings = () => {
    if (!analystRatingsData.isError) {
      let orangeColor = COLOR_DEFAULT_SET.find(e => e.key === 'orange')
      orangeColor = orangeColor && orangeColor.value
      let data = analystRatingsData.data
      if (checkData(data)) {
        let scoreData = {
          sustainbilityRating: data.fund.rating.sustainabilityRating,
          environment: formatNumber(data.fund.score.enviornmentalScore),
          social: formatNumber(data.fund.score.socialScore),
          governance: formatNumber(data.fund.score.governanceScore)
        }
        setScoreBoxData(scoreData)
        let scoreBar = []
        let barData = {
          label: 'Environment',
          score0: formatNumber(data.fund.score.enviornmentalScore),
          score1: formatNumber(data.category.score.enviornmentalAverage),
          score1_color: orangeColor,
          ariaLabel: {
            ariaLabel0: symbol,
            ariaLabel1: 'Global Category Average'
          }
        }
        scoreBar.push(barData)
        barData = {
          label: 'Social',
          score0: formatNumber(data.fund.score.socialScore),
          score1: formatNumber(data.category.score.socialAverage),
          score1_color: orangeColor,
          ariaLabel: {
            ariaLabel0: symbol,
            ariaLabel1: 'Global Category Average'
          }
        }
        scoreBar.push(barData)
        barData = {
          label: 'Governance',
          score0: formatNumber(data.fund.score.governanceScore),
          score1: formatNumber(data.category.score.governanceAverage),
          score1_color: orangeColor,
          ariaLabel: {
            ariaLabel0: symbol,
            ariaLabel1: 'Global Category Average'
          }
        }
        scoreBar.push(barData)
        setScoreBarData([...scoreBar])
      }
    }
    setIsReady(true)
  }

  function bindData () {
    if (xref.isError || Object.keys(scoreBoxData).length === 0) {
      return <Nodata />
    }
    return (<React.Fragment>
      <div className={styles.scoreBox}>
        <ESGScoreBox data={scoreBoxData} />
      </div>
      <div className={styles.midLabels}>
        <div className={styles.symbol}>
          <FontAwesomeIcon icon={['fas', 'circle']} color='#004480' className={`${styles.icon}`} />
          {symbol}
        </div>
        <div className={styles.symbol} id='globalCategoryAverageText' >
          <FontAwesomeIcon icon={['fas', 'circle']} color='#D97E07' className={`${styles.icon}`} />
          Global Category Average
        </div>
      </div>
      <div className={styles.scoreBar}>
        {
          scoreBarData.map((item, idx) => {
            return <ScoreBar key={idx} data={item} length={2} />
          })
        }
      </div>
      <div className={styles.linkButton}>
        <LinkButton label={'Go to ESG Metrics'} onClick={portfolioHandler} />
      </div>
    </React.Fragment>)
  }
  return (
    !isReady ? <Loader ready={false} spinnerStyle={{}} spinnerSize={'2x'} />
      : <div className={styles.ESGScoresContainer} id={'nodatatesting'}>
        <div className={styles.heading} id='esgHeading'>ESG Scores</div>
        {bindData()}
      </div>

  )
}

ESGScores.propTypes = {
  symbol: PropTypes.string.isRequired,
  analystRatingsData: PropTypes.object
}
export default ESGScores
