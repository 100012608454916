/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import 'babel-polyfill'
import React from 'react'
import F2AppContainer from '../../../../F2AppContainer'
import { APP_IDS, EVENT_NAMES } from '../../../../../../utils/f2Constants'
import TokenEvents from '../../../TokenEvents'
import Tabs from '../../../../../Lib/Tabs/Tabs'
import stylesQuoteResearch from './Dividends.module.scss'
import SymbolSearch from '../../../../../Lib/SymbolSearch/SymbolSearch'
import { emitF2Event, registerF2OnEvent, removeAllApps } from '../../../../../../utils/f2Methods'
import { changeTabHandler, getSymbolFromPath } from '../../../../utils'

class Dividends extends React.Component {
  constructor (props) {
    super(props)
    this.symbolRecvd = this.props.match.params.symbol
    this.state = {
      symbol: 'MSFT',
      period: 'Annual'
    }
  }

  componentDidMount () {
    registerF2OnEvent(EVENT_NAMES.MOD_GOTO, (modGotoContext) => {
      emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, {symbol: modGotoContext.symbol})
    })
  }

  render () {
    return (
      <div data-page='F2AppContainer'>
        <div className={stylesQuoteResearch.header}>
          <span>Quotes & Research</span>
        </div>
        <div>
          <F2AppContainer
            appId={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH}
            rootId={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH} />
        </div>
        <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR} >
          <F2AppContainer
            removeApp
            appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR}
            context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
            rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR} />
        </div>
        <div className={stylesQuoteResearch.quoteAndResearch}>
          <Tabs id={'MainPage_tab'} ariaLabel={'Finanace tabs'} activeTab={'Dividends'} onClick={(k) => { changeTabHandler(k) }}>
            <div key='Snapshot' label='Snapshot' />
            <div key='charts' label='Charting' />
            <div key='Options' label='Options' />
            <div key='dividends' label='Dividends'>
              <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_DIVIDEND_DETAILS} >
                <F2AppContainer
                  appId={APP_IDS.COM_PERSHING_NETXWEALTH_DIVIDEND_DETAILS}
                  context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                  rootId={APP_IDS.COM_PERSHING_NETXWEALTH_DIVIDEND_DETAILS} />
              </div>
              <div className={stylesQuoteResearch.dividendPayoutContainer}>
                <div className={stylesQuoteResearch.leftSectionContainer}>
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_HISTORICAL_DIVIDEND_CHART} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_HISTORICAL_DIVIDEND_CHART}
                      context={{symbol: getSymbolFromPath(this.symbolRecvd)}}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_HISTORICAL_DIVIDEND_CHART} />
                  </div>
                </div>
                <div className={stylesQuoteResearch.rightSectionContainer}>
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_DIVIDEND_GROWTH} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_DIVIDEND_GROWTH}
                      context={{symbol: getSymbolFromPath(this.symbolRecvd)}}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_DIVIDEND_GROWTH} />
                  </div>
                </div>
              </div>
              <div className={stylesQuoteResearch.appGap} />
              <div className={stylesQuoteResearch.dividendPayoutContainer}>
                <div className={stylesQuoteResearch.leftSectionContainer} />
              </div>
              <div className={stylesQuoteResearch.appGap} />
              <div className={stylesQuoteResearch.peerAnnualYieldsContainer}>
                <div className={stylesQuoteResearch.leftSectionContainer} >
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_DIVIDEND_PAYOUT_CALENDAR_YEAR} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_DIVIDEND_PAYOUT_CALENDAR_YEAR}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_DIVIDEND_PAYOUT_CALENDAR_YEAR} />
                  </div>
                </div>
                <div className={stylesQuoteResearch.rightSectionContainer}>
                  <div className={stylesQuoteResearch.topSectionContainer}>
                    <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_DIVIDEND_PAYOUT} >
                      <F2AppContainer
                        appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_DIVIDEND_PAYOUT}
                        context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                        rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_DIVIDEND_PAYOUT} />
                    </div>
                  </div>
                  <div className={stylesQuoteResearch.bottomSectionContainer}>
                    <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_YEARLY_PAYMENT_TREND} >
                      <F2AppContainer
                        appId={APP_IDS.COM_PERSHING_NETXWEALTH_YEARLY_PAYMENT_TREND}
                        context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                        rootId={APP_IDS.COM_PERSHING_NETXWEALTH_YEARLY_PAYMENT_TREND} />
                    </div>
                    <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_PEER_ANNUAL_YIELDS} >
                      <F2AppContainer
                        appId={APP_IDS.COM_PERSHING_NETXWEALTH_PEER_ANNUAL_YIELDS}
                        context={{symbol: getSymbolFromPath(this.symbolRecvd)}}
                        rootId={APP_IDS.COM_PERSHING_NETXWEALTH_PEER_ANNUAL_YIELDS} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div key='NewsAndResearch' label='News & Research' />
            <div key='Valuations' label='Valuations' />
            <div key='EarningsAndEstimates' label='Earnings & Estimates' />
            <div key='Fundamentals' label='Fundamentals' />
            <div key='Ownership' label='Ownership' />
          </Tabs>
        </div>
        <TokenEvents />
      </div>
    )
  }
}

export default Dividends
