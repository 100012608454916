'use strict'

import React, {useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import NewsList from '../../News/NewsList/NewsList'
import { URLS } from '../../../utils/appConstants'
import DataRequest from '../../../services/DataRequest'
import Loader from '../../Lib/common/Loader'
import Nodata from '../../Lib/NoData/NoData'
import FilterBar from '../../News/NewsFilters/FilterBar'
import filterJson from '../../../data/StockNewsFilter.json'
import {EVENT_NAMES} from '../../../utils/f2Constants'
import {registerF2OnEvent, registerAccessTokenUpdatedF2OnEvent} from '../../../utils/f2Methods'
import styles from './StockNewsList.module.scss'

export default function StockNewsList ({symbol, filter, pagination}) {
  const [stockSymbol, setSymbol] = useState(symbol)
  const [venueXid, setVenueXid] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(false)
  const limit = pagination ? 10 : 3

  useEffect(() => {
    xrefCall(symbol)

    registerAccessTokenUpdatedF2OnEvent(() => {
      xrefCall(symbol)
    })

    registerF2OnEvent(EVENT_NAMES.SYMBOL_CHANGED_EVENT, (symbolUpdatedObject) => {
      setSymbol(symbolUpdatedObject.symbol)
      xrefCall(symbolUpdatedObject.symbol)
    })
  }, [])

  const xrefCall = (symbolParam) => {
    setLoading(true)
    let xrefCallback = (response) => {
      if (!response.isDataRequestComplete) return
      if (response.isError) {
        setError(true)
      } else {
        setError(false)
      }
      if (!response.isError) {
        const responseData = response.data && response.data.xids
        if (responseData.venue) {
          setVenueXid(responseData.venue)
        }
      }
      setLoading(false)
    }
    DataRequest.execute(
      `${URLS.PLATFORM.XREFGETSYMBOL}/${symbolParam}`,
      {
        params: {
          isCaseSensitive: false
        }
      },
      xrefCallback,
      null
    )
  }
  return (
    <div>
      { isLoading ? <Loader ready={false} spinnerSize={'2x'} msg={'Loading'} />
        : <div className={styles.StockNewsListContainer}>
          { isError ? <Nodata hideBellIcon={false} msg={'No news articles available'} />
            : <React.Fragment>
              <div className={styles.header}>
                <span className={styles.symbol}>{`${stockSymbol} `}</span>
                <span>News</span>
              </div>
              <div className={styles.filterBar}>
                {filter &&
                  <FilterBar data={filterJson.FilterDataNews} defaultFilters={filterJson.DefaultValuesFilter} filterOptions={filterJson} entitlementProviders={filterJson.ProvidersForEntitlement_128.Provider} />
                }
              </div>
              <NewsList wsodIssue={venueXid} limit={limit} showPagination={pagination} />
              {/* {!pagination &&
                <button className={styles.gotoNews}
                  onClick={() => {
                    emitF2Event(EVENT_NAMES.MOD_GOTO, {
                      gotoPage: PAGE_NAMES.NEWS,
                      symbol: symbol})
                  }}>Go to News</button>
              } */}
            </React.Fragment>
          }
        </div>
      }
    </div>
  )
}
StockNewsList.propTypes = {
  symbol: PropTypes.string,
  filter: PropTypes.bool,
  pagination: PropTypes.bool
}
