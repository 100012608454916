import React from 'react'
import PropTypes from 'prop-types'
import style from './RangeSelectorButton.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const RangeSelectorButton = (props) => {
  const handleClick = (e, buttonClicked) => {
    props.onChange(buttonClicked)
  }
  return (
    <div className={style.rangeSelectorContainer} style={{paddingTop: '20px', paddingLeft: '15px', ...props.styles}} >
      {props.item.arrayList.map((element, index) => {
        return <button id='button' key={index} aria-label={`${element}-${element === props.item.selectedValue ? 'Selected' : ''}`} data-testid={`button_search`}
          className={element !== props.item.selectedValue ? `${style.button} buttonRangeSelector` : `${style.button} buttonRangeSelector ${style.buttonClicked}`}
          onClick={(e) => handleClick(e, element)}>{element} <FontAwesomeIcon icon={['fal', 'check']} className={element !== props.item.selectedValue ? style.icon : style.iconOnClick} /></button>
      })}
    </div>
  )
}
RangeSelectorButton.propTypes = {
  onChange: PropTypes.func,
  styles: PropTypes.object,
  item: PropTypes.object
}
export default RangeSelectorButton
