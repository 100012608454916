export const addCards = (cardNumber, numberOfCards, screenWidth) => {
  let numberOfCardsAdded = 0
  if (cardNumber > 0) {
    let remainingSpace = screenWidth - cardNumber * 225
    numberOfCardsAdded = remainingSpace / (cardNumber * 225)
    if (numberOfCardsAdded > 4) {
      numberOfCardsAdded = Math.floor(numberOfCardsAdded)
    } else {
      numberOfCardsAdded = Math.ceil(numberOfCardsAdded)
    }
  } else {
    let remainingSpace = screenWidth - 225
    numberOfCardsAdded = remainingSpace / (225)
    numberOfCardsAdded = Math.ceil(numberOfCardsAdded)
  }
  if (numberOfCardsAdded < 0 || numberOfCardsAdded === 0) {
    numberOfCardsAdded = 1
  }
  if (screenWidth > 1024 && (cardNumber === 2 || cardNumber === 3)) {
    numberOfCardsAdded = numberOfCardsAdded + 1
  }
  if (cardNumber + numberOfCards > 10) {
    let extraCards = numberOfCards + cardNumber - 10
    numberOfCardsAdded = numberOfCards - extraCards
  }
  return numberOfCardsAdded
}

export const getSpinnerStyle = () => {
  let scrolltop = document.documentElement.scrollTop
  if (scrolltop > 0) {
    return {width: '220px', height: '136px'}
  } else {
    return {width: '220px', height: '261px'}
  }
}

export const scrollToTable = (value, adjustWidth = 180) => {
  // 180 by default for model compare page
  window.scroll({
    top: getTopPosition(value, adjustWidth),
    left: 0,
    behavior: 'smooth'
  })
}

export const getTopPosition = (id, adjustWidth = 180) => {
  // 180 by default for model compare page
  // adjust width for xat env for issue occuring due to header
  let dynamicAdjustWidth = window.location.href.includes('xat') ? (adjustWidth - 45) : adjustWidth
  return document.getElementById(id) && document.getElementById(id).offsetParent ? document.getElementById(id).offsetParent.offsetTop + document.getElementById(id).offsetTop - dynamicAdjustWidth : 0
}
