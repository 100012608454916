import React, {useState, useEffect, useRef} from 'react'
import styles from './RangeSlider.module.scss'
import Slider from '../Slider/Slider'
import {getDeviceType} from '../../utils/utilities'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const RangeSelector = props => {
  let [max, setMax] = useState(props.max)
  let [min, setMin] = useState(props.min)
  const [minErr, setMinErr] = useState(false)
  const [maxErr, setMaxErr] = useState(false)
  const [customStyleMargin, setCustomStyleMargin] = useState({})
  // const NumberRegEx = /^[0-9_-]*$/(PRW-463 and PRW-483 negative and decimal values are allowed.)
  const focusDiv = useRef()
  const invalidChars = [
    'e',
    'E'
  ]
  useEffect(() => {
    setMax(props.max)
    setMin(props.min)
    focusDiv.current.focus()
    customStyle()
  }, [props.max, props.min, props.item])

  const onChangeHandler = (miValue, maValue) => {
    if (miValue > props.lowest) setMinErr(false)
    if (maValue < props.highest) setMaxErr(false)
    setMax(maValue)
    setMin(Number(miValue))
    updateParent(miValue, maValue)
  }
  const onMinInput = (e) => {
    let val = e.target.value
    if (val === '') {
      setMin(null)
      setMinErr(false)
      updateParent(props.lowest, max)
      return
    }

    let value = Number(val)
    if (!isMinValid(value, max)) {
      setMinErr(true)
    } else {
      setMinErr(false)
    }

    setMin(value)
    if (isMaxValid(value, max)) {
      setMaxErr(false)
      setMax(max)
    }
    updateParent(value, max)
  }
  const keydownHandler = (e) => {
    let isMin = e.currentTarget.dataset['value'] === 'min'
    if (isMin && e.shiftKey && e.keyCode === 9) {
      e.preventDefault()
      let lielem = document.getElementById(`li-${props.selectedParent.parentVal}-${props.parentVal}`)
      lielem && lielem.focus()
    }
    if (invalidChars.includes(e.key)) {
      e.preventDefault()
    }
  }
  const onMaxInput = (e) => {
    let val = e.target.value
    if (val === '') {
      setMax(null)
      setMaxErr(false)
      updateParent(min, props.highest)
      return
    }
    let value = Number(val)

    if (!isMaxValid(min, value)) {
      setMaxErr(true)
    } else {
      setMaxErr(false)
    }

    setMax(value)
    if (isMinValid(min, value)) {
      setMinErr(false)
      setMin(min !== undefined && min !== null ? Number(min) : min)
    }
    updateParent(min, value)
  }

  const isMaxValid = (minValue, maxValue) => {
    let val = (minValue === null || minValue === '') ? props.lowest : minValue
    if (maxValue < props.lowest || maxValue > props.highest || maxValue < Number(val) || (!props.item.negativeAllowed && maxValue < 0)) {
      return false
    }
    return true
  }
  const isMinValid = (minValue, maxValue) => {
    let val = (maxValue === null || maxValue === '') ? props.highest : maxValue
    if (minValue < props.lowest || minValue > props.highest || (minValue > Number(val)) || (!props.item.negativeAllowed && minValue < 0)) {
      return false
    }
    return true
  }
  const isInputValid = (minValue, maxValue) => {
    if (minValue > maxValue || minValue == null || maxValue == null || minValue < props.lowest || maxValue > props.highest || minValue > props.highest || maxValue < props.lowest || (!props.item.negativeAllowed && minValue < 0) || (!props.item.negativeAllowed && maxValue < 0)) {
      return false
    }
    return true
  }
  const updateParent = (minValue, maxValue) => {
    props.onChange({max: maxValue, min: minValue, isValid: isInputValid(minValue, maxValue)})
  }
  const customStyle = () => {
    if ((getDeviceType() === 'DESKTOP') && (props.item.level === 'L3')) {
      setCustomStyleMargin({'marginLeft': '15px'})
    } else {
      setCustomStyleMargin({})
    }
  }
  return <div className={styles.rangeSelector}>
    <div className={styles.header}>
      {props.header}
    </div>
    <div className={styles.inputItem} >
      <div className={styles.firstdiv}>
        <label htmlFor='min' >Min</label><input ref={focusDiv} type='number'data-value='min'value={min} name='min' aria-label={`${props.header} min ${min}`} onInput={onMinInput} onKeyDown={keydownHandler} className={minErr && styles.err} />
        {minErr && <div className={styles.errmsg}><FontAwesomeIcon icon={['fas', 'exclamation-circle']} className={styles.icon} />Invalid input value</div>}

      </div>
      <div className={styles.seconddiv} style={customStyleMargin}>
        <label htmlFor='max'>Max</label><input type='number' data-value='max' value={max} name='max' aria-label={`${props.header} max ${max}`} onInput={onMaxInput} onKeyDown={keydownHandler} className={maxErr && styles.err} />

        {maxErr && <div className={styles.errmsg}><FontAwesomeIcon icon={['fas', 'exclamation-circle']} className={styles.icon} />Invalid input value</div>}
      </div>
    </div>
    <div className={styles.slider}style={{top: minErr || maxErr ? '27px' : '43px'}} >
      <Slider max={max} min={min} highest={props.highest} lowest={props.lowest} onChangeHandler={onChangeHandler} numFormatterReqd={props.item && props.item.numFormatterReqd} />
    </div>
  </div>
}
RangeSelector.propTypes = {
  max: PropTypes.number,
  selectedParent: PropTypes.object,
  parentVal: PropTypes.string,
  min: PropTypes.number,
  highest: PropTypes.number,
  lowest: PropTypes.number,
  onChange: PropTypes.func,
  header: PropTypes.string,
  item: PropTypes.object
}

export default RangeSelector
