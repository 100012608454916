import React, { useEffect, useState, useCallback } from 'react'
import { ModchartsInteractive, locales } from '@markit/modcharts/dist/modcharts.umd.js'
import '../../../../../Lib/ModChartsCss/ModCharts.scss'
import { ETF_STOCKS_PERFORMANCE } from '../../utils/appConstants'
import DataRequest from '../../../../../../services/DataRequest'
import styles from './SnapshotPerformanceChart.module.scss'
import PropTypes from 'prop-types'
import Loader from '../../../../../Lib/common/Loader'
import Nodata from '../../../../../Lib/NoData/NoData'
const SnapshotPerformanceChart = (props) => {
  const [chartLoading, setChartLoading] = useState(true)
  const [etfPerformanceChart, setChart] = useState(null)
  const [timePeriod, setTimePeriod] = useState(props.chartInputs.timePeriod)
  const [chartType, setChartType] = useState(props.chartInputs.chartType)
  const locale = locales.en_US
  const wsodIssueId = `${props?.chartInputs?.wsodIssue}`
  locale.periods = ['AM', 'PM']
  locale.days = []

  if (timePeriod !== props.chartInputs.timePeriod) {
    setTimePeriod(props.chartInputs.timePeriod)
    setChartType(props.chartInputs.chartType)
    if (etfPerformanceChart) {
      etfPerformanceChart.setDays(props.chartInputs.timePeriod)
      etfPerformanceChart.setDataPeriod(props.chartInputs.dataPeriod)
      etfPerformanceChart.setDataInterval(props.chartInputs.dataInterval)
      etfPerformanceChart.loadData()
    }
  }

  if (chartType !== props.chartInputs.chartType) {
    setTimePeriod(props.chartInputs.timePeriod)
    setChartType(props.chartInputs.chartType)
    let indicatorList = etfPerformanceChart.getIndicatorsById('price')
    if (indicatorList.length > 1) {
      etfPerformanceChart.panels[0].removeIndicator(indicatorList[1])
    }
    let newChartType
    if (props.chartInputs.chartType === ETF_STOCKS_PERFORMANCE.chartTypes.LINECHART) {
      newChartType = 'line'
    } else if (props.chartInputs.chartType === ETF_STOCKS_PERFORMANCE.chartTypes.Fill) {
      newChartType = 'fill'
    } else if (props.chartInputs.chartType === ETF_STOCKS_PERFORMANCE.chartTypes.CANDLESTICKCHART) {
      newChartType = 'candlestick'
    }
    etfPerformanceChart.setPriceMarkerType(newChartType)
    if (props.chartInputs.chartType === ETF_STOCKS_PERFORMANCE.chartTypes.Fill) {
      etfPerformanceChart.panels[0].addIndicator('price', {
        id: 'price',
        markerType: 'lineWithShapes',
        style: {
          color: ETF_STOCKS_PERFORMANCE.INDEXES.darkColor,
          shape: { type: 'circle', color: ETF_STOCKS_PERFORMANCE.INDEXES.darkColor, fillColor: ETF_STOCKS_PERFORMANCE.INDEXES.darkColor, length: 4 },
          width: 1
        },
        symbol: wsodIssueId
      })
    }
    etfPerformanceChart.loadData()
  }

  useEffect(() => {
    if (!etfPerformanceChart && document.getElementById('etfPerformanceChartDomElement')) {
      initNewChart()
    } else {
      resetChart()
    }
  }, [wsodIssueId])

  const initNewChart = useCallback(() => {
    const chart = new ModchartsInteractive('#etfPerformanceChartDomElement')
    const authToken = DataRequest.getAccessToken()
    chart.setAuthToken(authToken)
    const chartConfig = {
      params: {
        locale: locale,
        apiPath: window.MD.MOD_CHART_URL,
        symbol: wsodIssueId,
        days: timePeriod,
        dataInterval: props.chartInputs.dataInterval,
        dataPeriod: props.chartInputs.dataPeriod,
        showMarketCloseLabels: props.chartInputs.timePeriod === ETF_STOCKS_PERFORMANCE.dropDownDaysValues['1 Day'] ? 'all' : '',
        style: {
          panel: {
            border: {
              color: {
                bottom: ETF_STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.BORDERBOTTOMCOLOR,
                top: ETF_STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.GRIDHORIZONTALCOLOR,
                default: 'transparent'
              }
            },
            grid: {
              color: 'transparent',
              horizontal: {
                color: ETF_STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.GRIDHORIZONTALCOLOR,
                lineDash: ''
              },
              vertical: {
                alt: { color: 'transparent' }
              }
            },
            xAxis: {
              label: {
                padding: {
                  top: 12,
                  bottom: 5
                },
                textAlign: 'center',
                lineHeight: ETF_STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTSIZE
              },
              font: {
                color: ETF_STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTCOLOR,
                family: ETF_STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTFAMILY,
                size: ETF_STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTSIZE
              }
            },
            yAxis: {
              label: {
                padding: {
                  left: 12
                }
              },
              font: {
                color: ETF_STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTCOLOR,
                family: ETF_STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTFAMILY,
                size: ETF_STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTSIZE
              }
            },
            margin: {
              bottom: 30
            }
          }
        }
      },
      panels: perfChartPanel
    }
    setChart(null)
    chart.load(chartConfig, () => {
      chart.setZoomEnabled(false)
      setChartLoading(false)
      setChart(chart)
    })
    chart.eventEmitter.on('DATA_LOAD_STOP', () => {
      setChartLoading(false)
    })
  })

  const resetChart = () => {
    if (etfPerformanceChart) {
      etfPerformanceChart.destroy()
      setChart(null)
      initNewChart()
    }
  }
  const topIndicatorsList = {
    'fill': {
      id: 'price',
      markerType: 'fill',
      style: {
        fillColor: ETF_STOCKS_PERFORMANCE.INDEXES.lightColor,
        fillColorStop: ETF_STOCKS_PERFORMANCE.INDEXES.lightColor,
        shape: { type: 'circle', color: ETF_STOCKS_PERFORMANCE.INDEXES.lightColor, fillColor: ETF_STOCKS_PERFORMANCE.INDEXES.lightColor },
        width: 1
      },
      symbol: wsodIssueId
    },
    'lineWithShapes': {
      id: 'price',
      markerType: 'lineWithShapes',
      style: {
        color: ETF_STOCKS_PERFORMANCE.INDEXES.darkColor,
        shape: { type: 'circle', color: ETF_STOCKS_PERFORMANCE.INDEXES.darkColor, fillColor: ETF_STOCKS_PERFORMANCE.INDEXES.darkColor, length: 4 },
        width: 1
      },
      symbol: wsodIssueId
    },
    'line': {
      id: 'price',
      markerType: 'line',
      style: {
        color: ETF_STOCKS_PERFORMANCE.INDEXES.darkColor,
        shape: { type: 'circle', color: ETF_STOCKS_PERFORMANCE.INDEXES.darkColor, fillColor: ETF_STOCKS_PERFORMANCE.INDEXES.darkColor, length: 4 },
        width: 1
      },
      symbol: wsodIssueId
    },
    'candlestick': {
      id: 'price',
      markerType: 'candlestick',
      style: {
        color: ETF_STOCKS_PERFORMANCE.INDEXES.darkColor,
        shape: { type: 'circle', color: ETF_STOCKS_PERFORMANCE.INDEXES.darkColor, fillColor: ETF_STOCKS_PERFORMANCE.INDEXES.darkColor, length: 4 },
        width: 1
      },
      symbol: wsodIssueId
    }
  }
  const chartVsIndicators = {
    'chart-line': [topIndicatorsList['line']],
    'chart-candlestick': [topIndicatorsList['candlestick']],
    'chart-area': [topIndicatorsList['fill'], topIndicatorsList['lineWithShapes']]
  }
  const getPanel = () => {
    if (props.isMF) {
      return [{
        indicators: chartVsIndicators[chartType]
      }]
    }
    return [{
      indicators: chartVsIndicators[chartType]
    }, {
      indicators: [{
        id: 'volume',
        style: {
          color: ETF_STOCKS_PERFORMANCE.INDEXES.darkColor,
          yAxis: {
            padding: {
              bottom: 25,
              top: 20
            }
          }
        }
      }]
    }]
  }

  const perfChartPanel = getPanel()

  return (
    <div className={styles.etfPerformanceChartContainer} >
      {chartLoading && <div className={`${styles.modalPopUpContentContainer} ${styles.loaderContainer}`}>
        <Loader ready={!chartLoading}
          spinnerSize={'2x'}
          msg={'Loading'}
        />
      </div>
      }
      {!chartLoading && <div className={styles.topDivider} />}
      {!chartLoading && !etfPerformanceChart && <Nodata msg='Data not available' />}
      <div id={'etfPerformanceChartDomElement'} name={props.symbol} />
    </div>
  )
}

SnapshotPerformanceChart.propTypes = {
  chartInputs: PropTypes.object,
  isMF: PropTypes.bool,
  symbol: PropTypes.string
}

export default SnapshotPerformanceChart
