import React from 'react'
import PropTypes from 'prop-types'
import styles from './SnapshotHighlights.module.scss'
import Loader from '../../../../../Lib/common/Loader'
import StockHighlightsItem from '../../../../../Stocks/StockHighlights/StockHighlightsItem/StockHighlightsItem'
import { URLS, DATE_FORMAT, FUND_SCREEN_TYPE, DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS } from '../../utils/appConstants'
import DataRequest from '../../../../../../services/DataRequest'
import { formatNumber } from '../../../../../../utils/formatter'
import { formatDate, convertFromOADate } from '../../utils/formatter'
import { deviceType, getDeviceType, handleWindowResize } from '../../../../../../utils/utilities'
import { getAPIData } from '../../utils/utilities'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import MfSnapshotData from '../../FundResearch/FundResearchConstants.json'
import LinkButton from '../../../../../Lib/LinkButton/LinkButton'
import { emitF2Event } from '../../utils/f2Methods'
import { EVENT_NAMES } from '../../../../../../utils/f2Constants'
import { TAB_NAMES } from '../../../../../../utils/appConstants'

const TEXT_NULL_VALUE = '--'

class SnapshotHighlights extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isError: false,
      isLoading: true,
      symbol: props.symbol,
      venueXid: props.xid,
      type: props.type,
      data: props.data,
      header: props.header,
      trailingYieldColor: undefined,
      premiumDiscountColor: undefined,
      sECYieldColor: undefined,
      fundHighlightsData: {
        underlyingBenchmark: TEXT_NULL_VALUE,
        assetClass: TEXT_NULL_VALUE,
        netAssetValue: TEXT_NULL_VALUE,
        shareClassNetAssets: TEXT_NULL_VALUE,
        passiveActive: TEXT_NULL_VALUE,
        premiumDiscount: TEXT_NULL_VALUE,
        grossExpenseRatio: TEXT_NULL_VALUE,
        netExpenseRatio: TEXT_NULL_VALUE,
        sECYield: TEXT_NULL_VALUE,
        trailingYield: TEXT_NULL_VALUE,
        inceptionDate: TEXT_NULL_VALUE,
        legalStructure: TEXT_NULL_VALUE
      },
      fundVestData: {
        dateAdded: TEXT_NULL_VALUE,
        dateAddedRemoved: TEXT_NULL_VALUE,
        cusip: TEXT_NULL_VALUE
      },
      device: getDeviceType()
    }
    this.spinnerStyle = props.spinnerStyle || { 'minWidth': 'auto', 'height': 'auto' }
  }

  componentDidMount () {
    handleWindowResize((response) => {
      this.setState({ device: response })
    })
    if (this.state.type === 'ETF') this.getFundHighlightsData()
    if (this.state.type === 'MFOperationalIndicators') this.getMFOperationalIndicators()
    if (this.state.type === 'AIOverview' || this.state.type === 'AIHighlights' || this.state.type === 'AIDebtRate') this.getAiData()
  }

  getFundHighlightsData () {
    this.getUnderlyingBenchmark(this.state.venueXid)
    this.getShareClassData(this.state.venueXid)
    this.getExpenseRatio(this.state.venueXid)
    this.getAssetClass(this.state.venueXid)
    this.getYieldData(this.state.venueXid)
    this.getPassiveActiveData(this.state.venueXid)
    this.getPermiumDiscount(this.state.venueXid)
    this.getScreenerData()
  }

  componentWillReceiveProps (nextProps) {
    this.setState({
      isError: false,
      isLoading: true,
      symbol: nextProps.symbol,
      venueXid: nextProps.xid,
      fundHighlightsData: {
        underlyingBenchmark: TEXT_NULL_VALUE,
        assetClass: TEXT_NULL_VALUE,
        netAssetValue: TEXT_NULL_VALUE,
        shareClassNetAssets: TEXT_NULL_VALUE,
        passiveActive: TEXT_NULL_VALUE,
        premiumDiscount: TEXT_NULL_VALUE,
        grossExpenseRatio: TEXT_NULL_VALUE,
        netExpenseRatio: TEXT_NULL_VALUE,
        sECYield: TEXT_NULL_VALUE,
        trailingYield: TEXT_NULL_VALUE,
        inceptionDate: TEXT_NULL_VALUE,
        legalStructure: TEXT_NULL_VALUE
      },
      fundVestData: {
        dateAdded: TEXT_NULL_VALUE,
        dateAddedRemoved: TEXT_NULL_VALUE,
        cusip: TEXT_NULL_VALUE
      },
      device: getDeviceType()
    })
    setTimeout(() => {
      if (this.state.type === 'ETF') this.getFundHighlightsData()
      if (this.state.type === 'MFOperationalIndicators') this.getMFOperationalIndicators()
      if (this.state.type === 'AIOverview' || this.state.type === 'AIHighlights' || this.state.type === 'AIDebtRate') this.getAiData()
    }, 100)
  }

  getAiData () {
    this.setState({data: this.props.data})
    if (!Array.isArray(this.props.data)) {
      this.setState({ isLoading: false })
    }
  }

  portfolioHandler () {
    emitF2Event(EVENT_NAMES.GOTO_FUND_PROFILE_TAB, {activeTabKey: TAB_NAMES.PORTFOLIO})
  }
  getMFOperationalIndicators () {
    if (this.props.data) {
      this.setState({data: this.props.data})
      if (!Array.isArray(this.props.data)) {
        this.setState({ isLoading: false })
      }
    }
  }
  getUnderlyingBenchmark (venueXid) {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        const responseData = response.data.items.filter(item => item.type === 'ProspectusPrimaryBenchmark')
        let fundHighlightsDataUpdated = Object.assign({}, this.state.fundHighlightsData)
        fundHighlightsDataUpdated.underlyingBenchmark = responseData ? responseData[0].name : this.state.fundHighlightsData.underlyingBenchmark
        this.setState({ fundHighlightsData: fundHighlightsDataUpdated, isLoading: false })
      } else {
        this.setState({ isError: true, isLoading: false })
      }
    }
    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_BENCHMARK, callback, `venueXids: ${venueXid}`, true)
  }
  getAssetClass (venueXid) {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        const responseData = response.data.items.filter(item => item.type === 'MorningstarCategoryUSAssetClass')
        let fundHighlightsDataUpdated = Object.assign({}, this.state.fundHighlightsData)
        fundHighlightsDataUpdated.assetClass = responseData ? responseData[0].name : this.state.fundHighlightsData.assetClass
        this.setState({ fundHighlightsData: fundHighlightsDataUpdated, isLoading: false })
      } else {
        this.setState({ isError: true, isLoading: false })
      }
    }

    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_BENCHMARK_INFORMATION, callback, `venueXids: ${venueXid}`, true)
  }
  getScreenerData () {
    const screenDataCallback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError && response.data && response.data.items.length) {
        let item = response.data.items
        this.setState({
          fundVestData: {
            dateAdded: formatDate(new Date(item[0].SPCFDateAdded), { format: DATE_FORMAT.MONTH_DAY_YEAR_DATE }),
            dateAddedRemoved: item[0].hasOwnProperty('SPCFDateRemoved') ? convertFromOADate(item[0].SPCFDateRemoved) : 'Present',
            cusip: item[0].SPCFMStarOverrideCUSIP
          }

        })
      } else {
        this.setState({
          isLoading: false,
          error: response.error
        })
      }
    }
    let newScreenerArguments = [
      {
        field: 'SPCFWSODIssue',
        conditions: [
          {
            operator: 'EqualTo',
            values: [`${this.state.venueXid}`]
          }
        ]
      }
    ]

    DataRequest.execute(
      `${URLS.CUSTOM.SCREEN}?screenType=${FUND_SCREEN_TYPE}`,
      this.buildScreenInputs(newScreenerArguments),
      screenDataCallback
    )
  }
  buildScreenInputs (screenerArgs) {
    let preScreenInputs = DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS
    preScreenInputs.arguments = screenerArgs || []
    preScreenInputs.resultFields = ['SPCFDateRemoved', 'SPCFDateAdded', 'SPCFMStarOverrideCUSIP']
    return {
      method: 'POST',
      postData: preScreenInputs
    }
  }

  getYieldData (venueXid) {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        const responseData = response.data.items.filter(item => item.type === 'TrailingY1Yield')
        const responseSEC = response.data.items.filter(item => item.type === 'SECYield')
        let fundHighlightsDataUpdated = Object.assign({}, this.state.fundHighlightsData)
        if (responseData.length > 0) fundHighlightsDataUpdated.trailingYield = responseData.length > 0 ? `${'+'}` + responseData[0].value + `${'%'}` : `${'-'}` + responseData[0].value + `${'%'}`
        if (responseSEC.length > 0) fundHighlightsDataUpdated.sECYield = responseSEC.length > 0 ? `${'+'}` + responseSEC[0].value + `${'%'}` : `${'-'}` + responseSEC[0].value + `${'%'}`
        let color = responseData[0].value < 0 ? { color: '#FF0000' } : { color: '#008338' }
        let colorSEC = responseSEC[0].value < 0 ? { color: '#FF0000' } : { color: '#008338' }
        this.setState({ fundHighlightsData: fundHighlightsDataUpdated, isLoading: false })
        this.setState({trailingYieldColor: color})
        this.setState({sECYieldColor: colorSEC})
      } else {
        this.setState({ isError: true, isLoading: false })
      }
    }

    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_YIELD, callback, `venueXids: ${venueXid}`, true)
  }

  getPassiveActiveData (venueXid) {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        const responseData = response.data.characteristics.isIndex
        let fundHighlightsDataUpdated = Object.assign({}, this.state.fundHighlightsData)
        fundHighlightsDataUpdated.passiveActive = responseData === true ? 'Passive' : 'Active'
        this.setState({ fundHighlightsData: fundHighlightsDataUpdated, isLoading: false })
      } else {
        this.setState({ isError: true, isLoading: false })
      }
    }
    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_FUNDATTRIBUTE, callback, `venueXids: ${venueXid}`, true)
  }

  getPermiumDiscount (venueXid) {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        const responseData = response.data.fund.premiumDiscount.items
        let fundHighlightsDataUpdated = Object.assign({}, this.state.fundHighlightsData)
        if (responseData[0].temporalUnit === 'Year' && responseData[0].temporalPeriod === 1) {
          fundHighlightsDataUpdated.premiumDiscount = responseData[0].average + `${'%'}`
          let color = responseData[0].average < 0 ? { color: '#E40707' } : { color: '#008338' }
          this.setState({premiumDiscountColor: color})
        } else fundHighlightsDataUpdated.premiumDiscount = this.state.fundHighlightsData.premiumDiscount

        this.setState({ fundHighlightsData: fundHighlightsDataUpdated, isLoading: false })
      } else {
        this.setState({ isError: true, isLoading: false })
      }
    }
    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_RISKMEASURE, callback, `venueXids: ${venueXid}`, true)
  }
  getShareClassData (venueXid) {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        const responseData = response && response.data
        const responseDate = response.data.dates.filter(item => item.type === 'inception')
        let fundHighlightsDataUpdated = Object.assign({}, this.state.fundHighlightsData)
        fundHighlightsDataUpdated.netAssetValue = formatNumber(responseData.fundNetAssets, {
          precision: 2,
          showMagnitude: true
        })
        fundHighlightsDataUpdated.shareClassNetAssets = formatNumber(responseData.fundShareClassNetAssets, {
          precision: 2,
          showMagnitude: true
        })
        fundHighlightsDataUpdated.legalStructure = responseData.legalStructure
        fundHighlightsDataUpdated.inceptionDate = responseDate ? formatDate(new Date(responseDate[0].value), { format: DATE_FORMAT.MONTH_DAY_YEAR_WITHCOMMA }) : this.state.fundHighlightsData.inceptionDate
        this.setState({ fundHighlightsData: fundHighlightsDataUpdated, isLoading: false })
      } else {
        this.setState({ isError: true, isLoading: false })
      }
    }

    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_SHARECLASS, callback, `venueXids: ${venueXid}`, true)
  }

  getExpenseRatio (venueXid) {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        let fundHighlightsDataUpdated = Object.assign({}, this.state.fundHighlightsData)
        fundHighlightsDataUpdated.netExpenseRatio = response.data.items.fundsData[0].prospectus.netExpenseRatio + `${'%'}`
        fundHighlightsDataUpdated.grossExpenseRatio = response.data.items.fundsData[0].prospectus.grossExpenseRatio + `${'%'}`
        this.setState({ fundHighlightsData: fundHighlightsDataUpdated, isLoading: false })
      } else {
        this.setState({ isError: true, isLoading: false })
      }
    }
    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_PROSPECTUS, callback)
  }
  getHtmlForDataPoints (x) {
    return <StockHighlightsItem key={x.label} label={x.label} value={(this.state.data[x.fieldName] === null || this.state.data[x.fieldName] === undefined) ? TEXT_NULL_VALUE : this.state.data[x.fieldName]} />
  }

  fundHighlightIpadHtmlBody () {
    if (this.state.isLoading) {
      return <Loader spinnerStyle={this.spinnerStyle} ready={false} msg={'Loading'} spinnerSize={'2x'} />
    }
    return <React.Fragment>
      {this.state.type === 'ETF' ? <div className={styles.fundHighlightContent}>
        <div className={styles.fundHighlightIPadContainer}>
          <div className={styles.fundHighlightIPadDivLeft}>
            <StockHighlightsItem label={'CUSIP'} value={this.state.fundVestData.cusip} />
            <StockHighlightsItem label={'Underlying Benchmark'} value={this.state.fundHighlightsData.underlyingBenchmark} valueStyling={{'width': '50%'}} />
            <StockHighlightsItem label={'Asset Class'} value={this.state.fundHighlightsData.assetClass} />
            <StockHighlightsItem label={'Net Asset Value'} value={this.state.fundHighlightsData.netAssetValue} />
            <StockHighlightsItem label={'Share Class Net Assets'} value={this.state.fundHighlightsData.shareClassNetAssets} />
            <StockHighlightsItem label={'Passive/Active'} value={this.state.fundHighlightsData.passiveActive} />
            <StockHighlightsItem label={'Premium/Discount 1 Mon Avg.'} value={this.state.fundHighlightsData.premiumDiscount} valueStyling={this.state.premiumDiscountColor} keyStyling={{'width': '50%'}} />

          </div>
          <div className={styles.fundHighlightIPadDivRight}>
            <StockHighlightsItem label={'Gross Expense Ratio'} value={this.state.fundHighlightsData.grossExpenseRatio} valueStyling={this.state.dividendFrequencyStyle} />
            <StockHighlightsItem label={'Net Expense Ratio'} value={this.state.fundHighlightsData.netExpenseRatio} />
            <StockHighlightsItem label={'SEC Yield'} value={this.state.fundHighlightsData.sECYield}
              valueStyling={this.state.sECYieldColor}
            />
            <StockHighlightsItem label={'1 Yr Trailing Yield'} value={this.state.fundHighlightsData.trailingYield}
              valueStyling={this.state.trailingYieldColor}
            />
            <StockHighlightsItem label={'Inception Date'} value={this.state.fundHighlightsData.inceptionDate} />
            <StockHighlightsItem label={'FundVest History'} value={this.state.fundVestData.dateAdded !== TEXT_NULL_VALUE ? `${this.state.fundVestData.dateAdded}` + ' - ' + `${this.state.fundVestData.dateAddedRemoved}` : TEXT_NULL_VALUE} />
            <StockHighlightsItem label={'Legal Structure'} value={this.state.fundHighlightsData.legalStructure} />
          </div>
        </div>
      </div> : this.renderAiHtmlForIpad()}
    </React.Fragment>
  }
  getAImoduleIPAD (startIndex, endIndex) {
   if (this.state.type === 'MFOperationalIndicators') {
      return MfSnapshotData.MFOperationalIndicators.slice(startIndex, endIndex).map(x => this.getHtmlForDataPoints(x))
    }
  }
  renderAiHtmlForIpad () {
    return <div className={styles.fundHighlightContent}>
      <div className={`${styles.fundHighlightIPadContainer} ${this.state.type === 'AIOverview' ? styles.aiOverviewContainer : ''}`}>
        <div className={styles.fundHighlightIPadDivLeft}>
          {this.state.data && this.state.type === 'MFOperationalIndicators' ? this.getAImoduleIPAD(0, 7) : this.getAImoduleIPAD(0, 5)}
        </div>
        <div className={styles.fundHighlightIPadDivRight}>
          {this.state.data && this.state.type === 'MFOperationalIndicators' ? this.getAImoduleIPAD(7, 13) : this.getAImoduleIPAD(5, 10)}
        </div>
      </div>
    </div>
  }
  getAImoduleDesktop () {
    if (this.state.type === 'MFOperationalIndicators') {
      return MfSnapshotData.MFOperationalIndicators.map(x => this.getHtmlForDataPoints(x))
    }
  }
  fundHighlightHtmlBody () {
    if (this.state.isLoading) {
      return <Loader spinnerStyle={this.spinnerStyle} ready={false} msg={'Loading'} spinnerSize={'2x'} />
    }
    if (this.state.device === deviceType.Ipad) {
      return this.fundHighlightIpadHtmlBody()
    } else {
      return <React.Fragment>
        {this.state.type === 'ETF' ? <div className={styles.fundHighlightContent}>
          <StockHighlightsItem label={'CUSIP'} value={this.state.fundVestData.cusip} />
          <StockHighlightsItem label={'Underlying Benchmark'} value={this.state.fundHighlightsData.underlyingBenchmark} valueStyling={{'width': '50%'}} />
          <StockHighlightsItem label={'Asset Class'} value={this.state.fundHighlightsData.assetClass} />
          <StockHighlightsItem label={'Net Asset Value'} value={this.state.fundHighlightsData.netAssetValue} />
          <StockHighlightsItem label={'Share Class Net Assets'} value={this.state.fundHighlightsData.shareClassNetAssets} />
          <StockHighlightsItem label={'Passive/Active'} value={this.state.fundHighlightsData.passiveActive} />
          <StockHighlightsItem label={'Premium/Discount 1 Mon Avg.'} value={this.state.fundHighlightsData.premiumDiscount} valueStyling={this.state.premiumDiscountColor} />
          <StockHighlightsItem label={'Gross Expense Ratio'} value={this.state.fundHighlightsData.grossExpenseRatio} />
          <StockHighlightsItem label={'Net Expense Ratio'} value={this.state.fundHighlightsData.netExpenseRatio} />
          <StockHighlightsItem label={'SEC Yield'} value={this.state.fundHighlightsData.sECYield}
            valueStyling={this.state.sECYieldColor}
          />
          <StockHighlightsItem label={'1 Yr Trailing Yield'} value={this.state.fundHighlightsData.trailingYield}
            valueStyling={this.state.trailingYieldColor}
          />
          <StockHighlightsItem label={'Inception Date'} value={this.state.fundHighlightsData.inceptionDate} />
          <StockHighlightsItem label={'FundVest History'} value={this.state.fundVestData.dateAdded !== TEXT_NULL_VALUE
            ? `${this.state.fundVestData.dateAdded}` + ' - ' + `${this.state.fundVestData.dateAddedRemoved}` : TEXT_NULL_VALUE} />
          <StockHighlightsItem label={'Legal Structure'} value={this.state.fundHighlightsData.legalStructure} />

        </div> : <div className={styles.fundHighlightContent}>
          {this.state.data && this.getAImoduleDesktop()}
        </div> }
      </React.Fragment>
    }
  }

  render () {
    return (
      <div className={styles.fundHightlightContainer}>
        <div className={styles.fundHighlightHeader} id='Highlights' >{this.state.symbol} {this.state.header}</div>
        {this.fundHighlightHtmlBody()}
        {this.state.type === 'AIOverview' && this.props.data.fileName &&
          <div>
            <span className={styles.analystNoteLink} role='button' tabIndex='0' onKeyPress={(e) => this.props.handleAnalystNote()} onClick={this.props.handleAnalystNote}>
              <FontAwesomeIcon className={styles.pdfIcon} icon={['fal', 'file-pdf']} />
              <span className={styles.link}>Analyst Note</span>
            </span>
          </div>}
        {(this.state.type === 'MFOperationalIndicators' || this.state.type === 'ETF') && <div className={styles.linkButton}>
          <LinkButton label={'Go to ESG Metrics'} onClick={this.portfolioHandler} />
        </div>}
      </div>
    )
  }
}

SnapshotHighlights.propTypes = {
  spinnerStyle: PropTypes.object,
  xid: PropTypes.any,
  type: PropTypes.string,
  data: PropTypes.object,
  header: PropTypes.string,
  symbol: PropTypes.string,
  handleAnalystNote: PropTypes.func
}
export default React.memo(SnapshotHighlights)
