import React from 'react'
import PropTypes from 'prop-types'
import DataRequest from '../../../services/DataRequest'
import Loader from '../common/Loader'
import {URLS} from '../../../utils/appConstants'

import styles from './SparkChart.module.scss'
import DataUnavailable from '../DataUnavailable/DataUnavailable'

class SparkChartFill extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      sparkChart: {
        isLoading: true, isError: false, sparkImg: null
      }
    }
    this.chartInput = props.sparkChartInput
    this.spinnerStyle = props.spinnerStyle || {'minWidth': '220px', 'height': '72px'}
  }

  componentDidMount () {
    this.getSparkChart()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.venueXid && this.props.venueXid &&
      (prevProps.venueXid !== this.props.venueXid)) {
      this.getSparkChart()
    }
  }

  getSparkChart () {
    this.setState({sparkChart: {isLoading: true, isError: false, sparkImg: null}})
    let sparkDataRequestBody = {
      'data': {
        'realTime': this.props.isRealTime || false,
        'capture': ''
      },
      'imageFormat': 'png',
      'returnSprite': false
    }
    Object.assign(sparkDataRequestBody, this.chartInput)
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        let sparkData = response && response.data
        if (sparkData[0].values.includes('Message: No Chart Available')) {
          this.setState({sparkChart: {isLoading: false, isError: true, sparkImg: null}})
        } else {
          this.setState({sparkChart: {isLoading: false, isError: false, sparkImg: sparkData[0].image}})
        }
      } else {
        this.setState({sparkChart: {isLoading: false, isError: true, sparkImg: null}})
      }
    }
    DataRequest.execute(
      URLS.PLATFORM.SPARKCHART,
      {
        method: 'POST',
        postData: sparkDataRequestBody
      },
      callback,
      null,
      {
        realtime: this.props.isRealTime || false
      }
    )
  }

  getSparkChartBody () {
    if (this.state.sparkChart.isLoading) {
      return <Loader ready={false} spinnerStyle={this.spinnerStyle} spinnerSize={'2x'} />
    } else if (this.state.sparkChart.isError) {
      return <DataUnavailable />
    } else {
      return <img id={'chartDomElement' + this.props.symbol} className={styles.sparkChartDomElement} alt='' src={this.state.sparkChart.sparkImg} />
    }
  }

  render () {
    return (
      <div className={styles.sparkChartContainer} >
        {
          this.getSparkChartBody()
        }
      </div>
    )
  }
}
export default SparkChartFill
SparkChartFill.propTypes = {
  sparkChartInput: PropTypes.object,
  spinnerStyle: PropTypes.string,
  venueXid: PropTypes.number,
  symbol: PropTypes.number,
  isRealTime: PropTypes.bool
}
