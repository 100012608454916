import React, { useEffect, useState, useRef } from 'react'
import '../../../../../Lib/ModChartsCss/ModCharts.scss'
import { FUNDS_PERFORMANCE, DATE_FORMAT } from '../../utils/appConstants'
import DataRequest from '../../../../../../services/DataRequest'
import styles from './PerformanceComparisonChart.module.scss'
import PropTypes from 'prop-types'
import Loader from '../../../../../Lib/common/Loader'
import { ModchartsInteractive } from '@markit/modcharts/dist/modcharts.umd.js'
import lodash from 'lodash'
import { formatDate } from '../../utils/formatter'
import NoData from '../../../../../Lib/NoData/NoData'

const PerformanceComparisonChart = (props) => {
  const [chartLoading, setChartLoading] = useState(true)
  const [performanceChart, setChart] = useState(null)
  const [timePeriod, setTimePeriod] = useState(props.chartInputs['timePeriod'])
  const [chartType, setChartType] = useState(props.chartInputs.chartType)
  const refChartInput = useRef(props.chartInputs)
  const [chartData, setChartData] = useState([])
  if (timePeriod !== props.chartInputs.timePeriod || chartType !== props.chartInputs.chartType) {
    document.getElementById('performanceComparisonChartDomElement').innerHTML = ''
    setChart(null)
    setTimePeriod(props.chartInputs.timePeriod)
    setChartType(props.chartInputs.chartType)
    if (props.chartInputs['primaryVenueXid']) {
      setChartLoading(true)
    }
  }

  useEffect(() => {
    // Clean up the chart if primary xid is null
    if (!props.chartInputs['primaryVenueXid']) {
      document.getElementById('performanceComparisonChartDomElement').innerHTML = ''
      setChart(null)
      refChartInput.current = null
    }
    if (props.chartInputs && (props.modelCompareCardData.length > 0) && (!lodash.isEqual(props.chartInputs, refChartInput.current) || props.hasTabChanged) && props.chartInputs['primaryVenueXid']) {
      refChartInput.current = props.chartInputs
      InitChart()
    }
  }, [props.chartInputs])

  const chartInputs = () => {
    if (props.chartInputs['VenueXidsList'] && props.chartInputs['primaryVenueXid']) {
      return {
        params: {
          symbol: props.chartInputs['primaryVenueXid'].venueXid,
          symbolCompare: props.chartInputs['VenueXidsList'].map((data, index) => data.venueXid),
          zoomEnabled: false,
          days: timePeriod,
          dataPeriod: props.chartInputs['dataPeriod'],
          apiPath: window.MD.MOD_CHART_URL,
          style: {
            panel: {
              border: {
                color: {
                  bottom: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.BORDERBOTTOMCOLOR,
                  top: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.GRIDHORIZONTALCOLOR,
                  default: 'transparent'
                }
              },
              grid: {
                color: 'transparent',
                horizontal: {
                  color: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.GRIDHORIZONTALCOLOR,
                  lineDash: ''
                },
                vertical: {
                  alt: { color: 'transparent' }
                }
              },
              xAxis: {
                label: {
                  padding: {
                    top: 10,
                    bottom: 5
                  },
                  margin: {
                    top: 30
                  },
                  textAlign: 'center'
                },
                font: {
                  color: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTCOLOR,
                  family: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTFAMILY,
                  size: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTSIZE
                }
              },
              yAxis: {
                label: {
                  padding: {
                    left: 5
                  }
                },
                font: {
                  color: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTCOLOR,
                  family: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTFAMILY,
                  size: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTSIZE
                }
              },
              margin: {
                bottom: 30
              }
            }
          }
        },
        panels: [{
          indicators: buildPriceIndicator()
        }]
      }
    } else {
      return {
        params: {
          symbol: props.chartInputs['primaryVenueXid'] ? props.chartInputs['primaryVenueXid'].venueXid : 0,
          zoomEnabled: false,
          days: timePeriod,
          apiPath: window.MD.MOD_CHART_URL,
          style: {
            panel: {
              border: {
                color: {
                  bottom: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.BORDERBOTTOMCOLOR,
                  top: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.GRIDHORIZONTALCOLOR,
                  default: 'transparent'
                }
              },
              grid: {
                color: 'transparent',
                horizontal: {
                  color: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.GRIDHORIZONTALCOLOR,
                  lineDash: ''
                },
                vertical: {
                  alt: { color: 'transparent' }
                }
              },
              xAxis: {
                label: {
                  padding: {
                    top: 5
                  },
                  margin: {
                    top: 30
                  },
                  textAlign: 'center'
                },
                font: {
                  color: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTCOLOR,
                  family: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTFAMILY,
                  size: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTSIZE
                }
              },
              yAxis: {
                label: {
                  padding: {
                    left: 5
                  }
                },
                font: {
                  color: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTCOLOR,
                  family: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTFAMILY,
                  size: FUNDS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTSIZE
                }
              },
              margin: {
                bottom: 30
              }
            }
          }
        },
        panels: [{
          indicators: buildPriceIndicator()
        }]
      }
    }
  }
  const chartLoadComplete = (data) => {
    let customChartData = []
    let xidFundNameList = {}
    if (props.modelCompareCardData && props.modelCompareCardData.length > 0) {
      props.modelCompareCardData.forEach(element => {
        if ((!['FSCMF', 'MF'].includes(element.type)) || timePeriod > 7) {
          xidFundNameList[element.venueXid] = element.name
        }
      })

      let xidList = props.chartInputs['VenueXidsList'].map((data, index) => data.venueXid) || []
      xidList.push(props.chartInputs['primaryVenueXid'].venueXid)
      xidList.forEach(xidItem => {
        let matchedData = data.data[xidItem]
        if (matchedData) {
          for (var key in matchedData.price) {
            if (key && matchedData.price[key]) {
              let dataItems = matchedData.price[key]
              if (dataItems) {
                dataItems.forEach(dateValueItem => {
                  if (dateValueItem) {
                    customChartData.push({
                      'fundName': xidFundNameList[xidItem],
                      'date': formatDate(dateValueItem.date, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE}),
                      'value': dateValueItem.close
                    })
                  }
                })
              }
            }
          }
        }
      })
      setChartData(customChartData)
    }

    setChart(data)
    setChartLoading(false)
  }

  function buildPriceIndicator () {
    const primaryXid = props.chartInputs['primaryVenueXid']
    const indicators = props.chartInputs['VenueXidsList'] ? props.chartInputs['VenueXidsList'].map((data, idx) => {
      return {
        id: 'price',
        markerType: 'line',
        symbol: data.venueXid,
        style: {
          color: data.color,
          width: 1
        }
      }
    }) : {}
    return [
      {
        id: 'price',
        markerType: 'line',
        style: {
          color: primaryXid ? primaryXid.color : '',
          width: 1
        }
      },
      ...indicators
    ]
  }
  const InitChart = () => {
    if (performanceChart) {
      performanceChart.destroy()
    }
    const authToken = DataRequest.getAccessToken()
    let chartObject = new ModchartsInteractive()
    chartObject.setAuthToken(authToken)
    chartObject.mount('#performanceComparisonChartDomElement')
    setChartData([]) // Reinitialized before call so that it doesn't have old data if upcoming chart series calls gives an error.
    chartObject.load(chartInputs(), chartLoadComplete)
    chartObject.eventEmitter.on('DATA_LOAD_STOP', () => {
      setChartLoading(false)
    })
  }
  return (
    <div className={styles.performanceChartContainer} >
      {chartLoading && <div className={`${styles.modalPopUpContentContainer} ${styles.loaderContainer}`}>
        <Loader ready={!chartLoading}
          spinnerSize={'2x'}
          msg={'Loading'}
        />
      </div>
      }
      <div id={'performanceComparisonChartDomElement'} aria-hidden='true' className={props.chartInputs['primaryVenueXid'] ? styles.performanceComparisonChartDomElement : null} />
      {(!props.chartInputs['primaryVenueXid'] || !chartData || !chartData.length) && <div className={styles.noDataDiv}><NoData /></div>}

      {props.showChartDisclaimer ? <div className={styles.disclaimerChart}>
        *Mutual Funds are not available for 1D and 1W timeframes.
      </div> : <div />}
      {!chartLoading && props.modelCompareCardData && <div className={props.showChartDisclaimer ? styles.bottomDividerWithDisclaimer : styles.bottomDivider} /> }
      <div className='sr-only'>
        <table>
          <caption>Performance Comparison</caption>
          <thead>
            <tr>
              <th>Fund Name</th>
              <th>Date</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {
              chartData.map((item, index) => {
                return <tr key={'item-' + index}>
                  <td>{item.fundName}</td>
                  <td>{item.date}</td>
                  <td>{item.value}</td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

PerformanceComparisonChart.propTypes = {
  chartInputs: PropTypes.object,
  modelCompareCardData: PropTypes.object,
  showChartDisclaimer: PropTypes.bool,
  hasTabChanged: PropTypes.bool
}

export default PerformanceComparisonChart
