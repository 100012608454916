import React from "react";
import PropTypes from "prop-types";
import styles from "./TopCategories.module.scss";
import CheckBox from "../../../Lib/CheckBox/CheckBox";
import { formatDate } from "../../../../utils/formatter";
import ShowMoreText from 'react-show-more-text'

function TopCategories ({
  topMostCategories,
  handleChangeEvent,
  checkIsDisabled
}) {
  const card = () => {
    const cardHtml = topMostCategories.map((item) => {
      return (
        <div className={`${styles.topCategoryCard} ${checkIsDisabled(item.category) ? styles.checkDisabled : ''}`}>
          <div className={styles.categoryLabel}>
            <div className={styles.label} title={item.category} aria-label={item.category}>
            <ShowMoreText lines={2} more='' less='' expanded={false} width={130} >
            {item.category}
                  </ShowMoreText></div>
            <CheckBox
              ariaLabel={item.category}
              id={`chk_${item.category}`}
              onCheck={(e) => handleChangeEvent(e)}
              isChecked={item?.isSelected}
              value={item.category}
              isDisabled={checkIsDisabled(item.category)}
            />
          </div>
          <div className={styles.subLabel}>Last viewed on</div>
          <div className={styles.lastViewedDate}>
            {formatDate(item.lastViewedDttm, { format: "MMM, YYYY" })}
          </div>
        </div>
      );
    });
    return cardHtml;
  };

  return (
    <div className={styles.topCategoryContainer}>
      <div className={styles.headinglabel}>
        <span className={styles.headinglabel1}>Your Top Categories </span>
        <span className={styles.headinglabel2}>
          (Based on your reading history & news you interact with)
        </span>
      </div>
      {card()}
    </div>
  );
}

export default TopCategories;

TopCategories.propTypes = {
  topMostCategories: PropTypes.array.isRequired,
  handleChangeEvent: PropTypes.func,
  checkIsDisabled: PropTypes.func
};
