/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './MultiSelectDropDown.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
export default function MultiSelectDropDown (props) {
  let data = [...props.data]
  setOtherProperties(data)
  const [resetMode, setResetMode] = useState(!!(data && data.length && data[0].checked))
  const span = props.label && props.label.length > 0 ? (<span value={props.label}>{props.label} </span>) : null
  const [isListOpen, setListOpen] = useState(false)
  const [defaultLabel] = useState(props.placeholder || 'Select')
  const [selectedValue, setSelectedValue] = useState(
    props.selectedValue
      ? props.selectedValue
      : props.disableAllSelection ? '' : data[0].data
  )
  const [displayText, setDisplayText] = useState(generateDisplayText(selectedValue))
  const [selectedElements, setSelectedElements] = useState(selectedValue)
  const [isInnerButtonClicked, setIsInnerButtonClicked] = useState(false)
  let liIndex = -1
  document.addEventListener('click', handleClickOutside)

  useEffect(() => {
    setSelectedValue(props.selectedValue)
    setDisplayText(generateDisplayText(props.selectedValue))
  }, [props.selectedValue])

  const onApply = (e) => {
    props.onApply(selectedElements)
    setListOpen(!isListOpen)
  }

  const onReset = (e) => {
    setIsInnerButtonClicked(true)
    data.forEach(element => {
      if (!element.isDisabled) {
        element.checked = false
      }
    })
    props.onApply([])
    let textToFormat = getTextToFormat()
    setSelectedElements(textToFormat)
    setDisplayText(formatDisplayText())
    setListOpen(true)
  }

  return (
    <React.Fragment>
      <div className={`${styles['main-container']}`}>
        <div className={`${styles['label-text']}`}>
          {span}
        </div>
        <ul
          className={`${styles['basicDropDownContainer']} ${
            isListOpen ? styles['open'] : ''
          } DropDownUL`} style={props.styles} onBlur={handleOnBlur}
        >
          <li tabIndex='0' onClick={openCloseMenu} onKeyDown={handleKeyPressOnMainDisplayItem}
            className={`${styles['select-section']}`}>
            <div className={`${styles['display-text']}`}>
              <span
                className={`${styles['name']}`}
                title={selectedValue || selectedElements}
                value={displayText}
              >
                {displayText || defaultLabel}
              </span>
            </div>
            <span className={`${styles['icon']} ${styles['float-right']}`}>
              {
                isListOpen ? <FontAwesomeIcon icon={['fas', 'caret-up']} color='black' />
                  : <FontAwesomeIcon icon={['fas', 'caret-down']} color='black' />
              }
            </span>
          </li>
          <div className={`${isListOpen ? styles['main-section'] : ''}`}>
            <div
              className={`${isListOpen ? '' : styles['hide']} ${
                styles['basicDropDownValueContainer']
              }`}
            >
              {BindDropDownValues()}
            </div>
            {props.showApplyResetButtons && isListOpen
              ? <div className={`${styles.buttons} ${styles.applyAndResetButtons}`}>
                <button
                  type='button'
                  tabIndex={0}
                  className={`${styles.apply}`}
                  onMouseDown={onApply}
                  disabled={selectedElements === ''}>
                  Apply
                </button>
                <button
                  type='button'
                  tabIndex={0}
                  className={`${styles.reset}`}
                  onMouseDown={onReset} >
                  Reset
                </button>
              </div> : ''}
          </div>
        </ul>
      </div>
    </React.Fragment>
  )

  function handleOnBlur (e) {
    if (!e.currentTarget.contains(e.relatedTarget) && isListOpen && !isInnerButtonClicked) {
      setListOpen(false)
    }
    setIsInnerButtonClicked(false)
  }

  function generateDisplayText (textToFormat) {
    if (textToFormat) {
      let splitItems = textToFormat.split(',').map(item => item.trim())
      if (splitItems === data[0].data) {
        textToFormat = data[0].data
      } else {
        let firstSelectedItem = splitItems[0]
        textToFormat = splitItems?.length > 1
        ? `${firstSelectedItem?.substring(0, 14)}, +${(splitItems?.length - 1)}`
        : firstSelectedItem
      }
    }
    return textToFormat
  }

  function handleSelection () {
    if (selectedValue && selectedValue !== data[0].data) {
      data[0].checked = false
      // split by comma (,) and trim each item
      let splitValues = selectedValue && selectedValue.length > 0 ? selectedValue.split(',').map(item => item.trim()) : []
      data.forEach(element => {
        if (!element.isDisabled) {
          element.checked = false
        }
        if (splitValues.includes(element.data)) {
          element.checked = true
        }
      })
    } else if (selectedValue === data[0].data) {
      data[0].checked = true
      for (let i = 1; i < data.length; i++) {
        data[i].checked = false
      }
    }
  }

  // Open Close handler
  function openCloseMenu (e) {
    e && e.stopPropagation()
    handleSelection()
    setListOpen(!isListOpen)
  }

  function onCheckedChange (e) {
    e && e.stopPropagation()
  }

  function handleKeyPressOnMainDisplayItem (e) {
    handleSelection()
    let sibling = e.currentTarget.nextElementSibling
    let renderedLi = sibling.querySelectorAll('li')
    let lenrenderedLi = renderedLi.length - 1
    if (e.key === 'Enter') {
      handleEnterKeyOnMainDisplayItem()
    } else if (e.which === 40 || e.which === 38) {
      handleUpDownArrowOnMainDisplayItem()
    } else {
      if (e.key === 'Tab' && isListOpen) { openCloseMenu() }
    }
    //* ***********end main method definition, below are related inner functions***************//

    // inner function to handle enter key on main Li display item
    function handleEnterKeyOnMainDisplayItem () {
      const value = renderedLi[liIndex] && renderedLi[liIndex].getAttribute('data-value')
      if (value && value !== selectedElements && !data[liIndex].isDisabled) {
        if (liIndex === 0) {
          if (data[liIndex].checked) {
            // unselect all
            data.forEach(element => {
              element.checked = false
            })
          } else {
            // select all
            data.forEach(element => {
              if (!element.isDisabled) {
                element.checked = true
              }
            })
          }
        } else {
          if (!data[liIndex].isDisabled) {
            data[liIndex].checked = !data[liIndex].checked
          }
        }
      }
      setListOpen(true)
    }

    // inner function to handle up/down arrow key on main Li display item
    function handleUpDownArrowOnMainDisplayItem () {
      let keyCode = e.which
      renderedLi && renderedLi.forEach((item) => {
        item.classList.remove(styles['selected'])
      })
      if (keyCode === 40) {
        liIndex++
        if (liIndex > lenrenderedLi) { liIndex = 0 }
      } else if (keyCode === 38) {
        liIndex--
        if (liIndex < 0) { liIndex = lenrenderedLi }
      }
      if (liIndex === 0) {
        document.getElementById(data[0].id).focus()
      } else {
        document.getElementById(data[liIndex].id).focus()
      }
      let ci = renderedLi[liIndex]
      ci && ci.classList.add(styles['selected'])
    }
  }

  // change handler for every select item during click
  function handleChangeEvent (e) {
    e && e.stopPropagation()
    if (e.which === 9) { // Tab key press
      return
    }
    let index = parseInt(e.currentTarget.getAttribute('tabIndex')) - 1
    // pressing enter then arrow key, liIndex becomes -1
    // this check will make up/down arrow action on item smooth
    if (liIndex === -1) {
      liIndex = index
    }
    let keyCode = e.which
    if (keyCode === 40 || keyCode === 38) {
      handleUpDownArrowOnSelectedItem()
      return
    }
    handleCheckUncheckBehaviorOnSelectedItem()
    let formattedtext = formatDisplayText()
    if (!props.showApplyResetButtons) {
      setDisplayText(formattedtext)
    }
    if (props.changeHandler) {
      let textToFormat = ''
      data.forEach(element => {
        if (element.checked && !element.isDisabled) {
          textToFormat = textToFormat + ', ' + element.data
        }
      })
      textToFormat = textToFormat.substring(1).trim()
      props.changeHandler(textToFormat, e)
    }

    // end main method definition, below are related inner functions
    function handleCheckUncheckBehaviorOnSelectedItem () {
      if (resetMode) {
        setResetMode(false)
      }
      if (index === 0 && !props.disableAllSelection) {
        data[index].checked = !data[index].checked
        if (!resetMode) {
          // set all checked
          setResetMode(true)
          data.forEach(element => {
            if (!element.isDisabled) {
              element.checked = true
            }
          })
        } else {
          // all were checked, now all should be unchecked when first option unchecked
          data.forEach(element => {
            if (!element.isDisabled) {
              element.checked = false
            }
          })
        }
      } else { // case : index != 0
        if (!data[index].isDisabled) {
          if (!props.disableAllSelection) {
            data[0].checked = false
          }
          // including/excluding option
          data[index].checked = !data[index].checked
        }
      }
    }

    function handleUpDownArrowOnSelectedItem () {
      if (keyCode === 40) {
        liIndex++
        if (liIndex > data.length - 1) { liIndex = 0 }
      } else if (keyCode === 38) {
        liIndex--
        if (liIndex < 0) { liIndex = data.length - 1 }
      }
      let element = document.getElementById(data[liIndex].id)
      element.focus()
      if (liIndex === 0) {
        element.scrollTop = 0
      }
    }
  }

  function getTextToFormat () {
    let textToFormat = ''
    if (!props.disableAllSelection) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].checked && !data[i].isDisabled && i > 0) {
          textToFormat = textToFormat + ', ' + data[i].data
        }
      }
      textToFormat = textToFormat.substring(1).trim().replace(data[0].data + ', ', '')
      if (data[0].checked) {
        textToFormat = data[0].data
      }
    } else {
      for (var index = 0; index < data.length; index++) {
        if (data[index].checked && !data[index].isDisabled) {
          textToFormat = textToFormat + ', ' + data[index].data
        }
      }
      textToFormat = textToFormat.substring(0).trim().replace(', ', '')
    }
    return textToFormat
  }

  function formatDisplayText () {
    let textToFormat = getTextToFormat()
    setSelectedElements(textToFormat)
    textToFormat = generateDisplayText(textToFormat)
    return textToFormat
  }

  function handleClickOutside (e) {
    if (isListOpen && !e.target.closest('ul.DropDownUL') && e.target.tagName !== 'BUTTON') {
      openCloseMenu(e)
    }
  }

  // Returns html of dropdown with selection
  function BindDropDownValues () {
    if (data && data.length > 0) {
      return data.map((item, index) => (
        <li
          tabIndex={index + 1}
          id={item.id}
          data-testid={item.id}
          key={index}
          onClick={handleChangeEvent}
          onKeyDown={handleChangeEvent}
          className={`
            ${item.isDisabled ? styles['disabled'] : ''}
            ${isListOpen ? '' : styles['hide']}
            `}
          data-value={item.value || item.data}
          title={item.data}
        >
          <label className={`${styles['container']}`} htmlFor='null' aria-label='null'>
            <input type='checkbox' checked={item.checked} index={index} onChange={onCheckedChange} aria-label={item.data} />
            <span className={` ${styles['checkmark']}`} />
          </label>
          <div className={`${styles['option-text']}`}>
            <span className={`${styles['name']}`} >
              {item.data}
            </span>
          </div>
          <div className={`
              ${item.isDisabled ? '' : styles['hide']}
              ${item.isDisabled ? styles['lock-icon'] : ''}
              `} >
            <FontAwesomeIcon icon={['far', 'lock']} />
          </div>
        </li>
      ))
    } else {
      return null
    }
  }

  function setOtherProperties (data) {
    if (data && !data[0].id) {
      data.forEach((item, index) => {
        item.id = index + 1
        if (!item.data) {
          item.data = item.text
        }
      })
    }
  }
}

MultiSelectDropDown.propTypes = {
  data: PropTypes.array,
  changeHandler: PropTypes.func,
  selectedValue: PropTypes.string,
  styles: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showApplyResetButtons: PropTypes.bool,
  onApply: PropTypes.func,
  disableAllSelection: PropTypes.bool
}
