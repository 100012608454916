import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import styles from './WatchlistTable.module.scss'
import NoData from '../Lib/NoData/NoData'
import CheckBox from '../Lib/CheckBox/CheckBox'
import { getDeviceType, deviceType, handleWindowResize } from '../../utils/utilities'
const WatchlistTable = (props) => {
  const filtered = props.data
  const [device, setDevice] = useState(getDeviceType())
  useEffect(() => {
    handleWindowResize(resizeHandler)
  }, [])
  useEffect(() => {
    rendereventsTable(props.data)
  }, [props.data])
  function resizeHandler (device) {
    setDevice(device)
  }
  const onRemoveSymbol = (e) => {
    props.onTableSelect(e.target.name)
  }
  const renderDataColumnHeaders = () => {
    return props.header.map((header, index) => {
      if (props.firstColFroze && index === 0) {
        return <th
          key={'watchlistTableHeader_' + header.CellValue}
          scope='row'
          className={styles.headcol}>
          <span>{header.CellValue}</span>
        </th>
      }
      return <th
        key={'watchlistTableHeader_' + header.CellValue}
        scope='row'>
        <div className={styles.shortLine}>{header.CellValue}</div>
      </th>
    })
  }
  const rendereventsTable = (data) => {
    if (!data) {
      return null
    }
    const header = props.header.map(x => x.CellMap)
    return data.map((item, index) => renderTableRow(item, index, header))
  }

  const getStyles = (val) => {
    if (val && val !== undefined && val.includes('-')) {
      return styles.red
    } else {
      return styles.green
    }
  }

  const renderTableRow = (item, parentIndex, header) => {
    return <tr key={parentIndex} >
      {header.map((tdKey, index) => {
        if (props.firstColFroze && index === 0) {
          return <td key={'td_' + {index}} className={styles.headcol}>
            <span>{item[tdKey]}</span>
          </td>
        }
        let val = item[tdKey]
        if (item.selectedSymbol === undefined) {
          return <td key={'td_' + {index}} />
        } else {
          return <td key={'td_' + {index}} className={styles.rowcol}>
            <span title={val} className={`${index !== 0 && typeof val === 'string' && val.indexOf('%') > -1 ? getStyles(val) : ''}`}>
              {index === 0 ? <CheckBox label={val} isChecked onCheck={onRemoveSymbol} /> : val }</span>
          </td>
        }
      })
      }
    </tr>
  }
  const IsNoData = () => {
    return ((props.data && props.data.length === 0) || false)
  }
  const isNoData = IsNoData()
  const getEventsNoDataRowStyle = () => {
    return {
      padding: '30px 0px'
    }
  }
  return (
    <div className={`${styles.watchlistContainer} ${props.isSplitStyle &&
        device === deviceType.Desktop ? styles['maxWidth'] : ''}`}>
      {<React.Fragment>
        <table className={styles.watchlistTable}>
          <caption className='sr-only'>Watchlist Symbol Table</caption>
          <thead>
            <tr key='heading'>
              {renderDataColumnHeaders()}
            </tr>
          </thead >
          {!isNoData && <tbody>
            {rendereventsTable(filtered)}
          </tbody>}
        </table>
        {isNoData && <table className={styles.watchlistNoDataTable}>
          <tbody><tr><td><NoData msg={'No results for selected criteria'} hideBellIcon style={getEventsNoDataRowStyle()} /></td></tr></tbody>
        </table>}
      </React.Fragment>}
    </div>
  )
}
export default WatchlistTable
WatchlistTable.propTypes = {
  data: PropTypes.array,
  header: PropTypes.array,
  firstColFroze: PropTypes.bool,
  isSplitStyle: PropTypes.bool,
  onTableSelect: PropTypes.func
}
