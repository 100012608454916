/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popper } from 'react-popper'
import styles from './Popover.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

class Popover extends Component {
  constructor (props) {
    super(props)
    this.focusPopover = this.focusPopover.bind(this)
  }

  componentDidMount () {
    this.focusPopover()
  }

  componentDidUpdate () {
    this.focusPopover()
  }

  focusPopover () {
    this.popperRef.focus({ preventScroll: true })
  }
  closePopover (e) {
    e && e.stopPropagation()
    if (!e.currentTarget.contains(e.relatedTarget)) {
      this && this.props.closePopover(e)
    }
  }
  render () {
    return (
      <Popper
        referenceElement={this.props.referenceElement}
        placement={this.props.placement}
        modifiers={{ offset: { offset: '10px, 10px' } }}
        innerRef={node => (this.popperRef = node)}
      >
        {({ ref, style, placement, arrowProps }) => (
          <div
            tabIndex='0'
            className={styles['popover']}
            id={this.props.id}
            ref={ref}
            style={style}
            data-placement={placement}
            onBlur={(e) => this.closePopover(e)}
          >
            {
              this.props.showCloseIcon &&
              <div tabIndex='0' className={styles['popover-close-icon']} onClick={this.props.closePopover}>
                <FontAwesomeIcon icon={['fal', 'times']} />
              </div>
            }

            <div className={styles['popover-body-container']} data-popover-body-container='true' style={{height: this.props.height, width: this.props.width}}>
              {this.props.body}
            </div>

            {
              this.props.showPointingArrow &&
              <div className={styles['popover-arrow'] + ' ' + styles[placement]} ref={arrowProps.ref} style={arrowProps.style} />
            }

          </div>
        )}
      </Popper>
    )
  }
}

Popover.propTypes = {
  // id attribute that will be applied to the outermost div of the popoover
  id: PropTypes.string.isRequired,
  // function to be called when the popover is closed
  closePopover: PropTypes.func.isRequired,
  // content to render in the popover body
  body: PropTypes.node.isRequired,
  // element that the popover should be pointing towards and positioned relative to
  referenceElement: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  // String used to denote how popover should be positioned relative to the reference element (see react-popper docs for list of accepted values)
  placement: PropTypes.string,
  // Boolean value to show/hide the pointing arrow
  showPointingArrow: PropTypes.bool,
  // Boolean value to show/hide the close icon
  showCloseIcon: PropTypes.bool,
  // Height of the popover
  height: PropTypes.string,
  // width of the popover
  width: PropTypes.string
}

export default Popover
