/* eslint-disable no-unused-vars */
'use strict'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './NewsList.module.scss'
import Pagination from '../../Lib/Pagination/Pagination'
import ModalPopUp from '../../Lib/ModalPopUp/ModalPopUp'
import NewsArticle from '../NewsArticle/NewsArticle'
import {EVENT_NAMES} from '../../../utils/f2Constants'
import { URLS, DEFAULT_NEWS_INPUTS } from '../../../utils/appConstants'
import DataRequest from '../../../services/DataRequest'
import Loader from '../../Lib/common/Loader'
import SymbolList from './SymbolList'
import News from '../News/News'
import { convertUTCToEST } from '../../../utils/formatter'
import {addArgument, closeModalPopup, hideScroll} from '../../../utils/utilities'
import {registerF2OnEvent, registerAccessTokenUpdatedF2OnEvent} from '../../../utils/f2Methods'
import Nodata from '../../Lib/NoData/NoData'
import {makeXrefCall} from '../../../services/XrefAPICall'
import { getQuoteData } from '../../../shared/dataMethods'
import { GetTagsFromCategories } from '../../Lib/common/CommonMethods'

class NewsList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pageLimit: props.limit || 10,
      rowStart: 0,
      totalItems: 0,
      newsList: [],
      xRefDataList: [],
      quoteData: [],
      articleNewsModalItem: {},
      availableSymbolList: [],
      articleId: 0,
      isOpenModal: false,
      isLoading: false,
      rawSearchFilters: '',
      newsWidth: 594,
      searchFilters: {
        'Asset Class': '',
        Events: '',
        Ideas: '',
        Provider: props.provider || 'All Providers',
        Region: '',
        'Research Reports': '',
        Sectors: '',
        Time: '',
        FreeText: '',
        'Custom Date': {
          startDate: props.startDate || '',
          endDate: props.endDate || ''
        }
      },
      isDefaultLoadingDisabled: this.props.stopDefaultLoading,
      setFilterOperator: false,
      topCategories: props.topCategories || []
    }
    this.readNewsArticle = this.readNewsArticle.bind(this)
    this.setNewsRefs = this.setNewsRefs.bind(this)
    this.newsRef = []
    this.articleNewsDetailsAnchor = []
  }

  getCommaSeparatedValues = (arr) => {
    if (!arr) return ''
    return arr.map(function (elem) {
      return elem.value
    }).join(',')
  }

  processFilterInputs = (filterData) => {
    this.setState({searchFilters: {
      'Asset Class': this.getCommaSeparatedValues(filterData['Asset Class']),
      Events: this.getCommaSeparatedValues(filterData.Events),
      Ideas: this.getCommaSeparatedValues(filterData.Ideas),
      Provider: this.getCommaSeparatedValues(filterData.Provider),
      Region: this.getCommaSeparatedValues(filterData.Region),
      Sectors: this.getCommaSeparatedValues(filterData.Sectors),
      Time: this.getCommaSeparatedValues(filterData.Time),
      FreeText: this.getCommaSeparatedValues(filterData.FreeText),
      'Research Reports': this.getCommaSeparatedValues(filterData['Research Reports']),
      'Custom Date': filterData['Custom Date']
    },
    rowStart: 0,
    setFilterOperator: ('isFilterOperatorAvl' in filterData) && filterData.isFilterOperatorAvl,
    isDefaultLoadingDisabled: false}, () => {
      this.getNews(this.state.rowStart)
    })
  }

  componentDidMount () {
    registerF2OnEvent(EVENT_NAMES.NEWS_FILTER_CHANGED_EVENT, (filterData) => {
      this.processFilterInputs(filterData)
    })

    registerAccessTokenUpdatedF2OnEvent(() => {
      this.getNews(this.state.startingItem)
    })

    this.getNews(this.state.startingItem)
    window.addEventListener('resize', this.handleResize)
  }

  handleResize = (e) => {
    this.setState({newsWidth: screen.width})
  }

  readNewsArticle = async (newsArticle, availableSymbolList, index) => {
    if (!this.state.isOpenModal) {
      this.setState({isOpenModal: true, articleNewsModalItem: newsArticle, availableSymbolList: availableSymbolList, articleId: newsArticle.articleId}, this.setExpandedFalse(index))
      hideScroll(true)
    }
  }
  setExpandedFalse (index) {
    if (this.articleNewsDetailsAnchor[index] && this.articleNewsDetailsAnchor[index].state) {
      this.articleNewsDetailsAnchor[index].state.expanded = false
    }
  }

  setNewsRefs (ref, articleId) {
    if (ref && articleId) {
      this.newsRef[articleId] = ref
    }
  }

  closePopup = (e) => {
    if (closeModalPopup(e)) {
      const articleId = this.state.articleId
      this.setState({isOpenModal: false}, this.newsRef[articleId].focus())
      hideScroll(false)
    }
  }

  multipleSelection = (values) => {
    const inputs = values.split(',')
    let inputArray = []
    inputs.map((input) => {
      inputArray.push(`${input}`)
    })
    return inputArray
  }

  buildNewsInputs = (offset) => {
    const newsInputs = DEFAULT_NEWS_INPUTS
    const {wsodIssue} = this.props
    const {searchFilters, setFilterOperator} = this.state
    newsInputs.arguments = [
      addArgument('Asset Class', this.multipleSelection(searchFilters['Asset Class'])),
      addArgument('Events', this.multipleSelection(searchFilters.Events)),
      addArgument('Ideas', this.multipleSelection(searchFilters.Ideas)),
      addArgument('Region', this.multipleSelection(searchFilters.Region)),
      addArgument('Sectors', this.multipleSelection(searchFilters.Sectors)),
      addArgument('Time', this.multipleSelection(searchFilters.Time)),
      addArgument('FreeText', this.multipleSelection(searchFilters.FreeText)),
      addArgument('Research Reports', this.multipleSelection(searchFilters['Research Reports']))
    ]
    if (wsodIssue) {
      newsInputs.arguments.push(addArgument('WsodIssue', [`${wsodIssue}`]))
    }
    newsInputs.limit = this.state.pageLimit
    if (setFilterOperator) {
      newsInputs.argsOperator = 'OR'
    } else {
      newsInputs.arguments = [
        ...newsInputs.arguments,
        addArgument('Provider', this.multipleSelection(searchFilters.Provider)),
        addArgument('DocumentDate', [convertUTCToEST(searchFilters['Custom Date'].startDate)], 'GreaterThanEqualTo'),
        addArgument('DocumentDate', [convertUTCToEST(searchFilters['Custom Date'].endDate)], 'LessThanEqualTo')
      ]
    }
    newsInputs.offset = offset
    return {
      method: 'POST',
      postData: newsInputs
    }
  }

  getNews = (newRowStart = 0) => {
    if (!this.state.isDefaultLoadingDisabled) {
      this.setState({newsList: [], totalItems: 100, isLoading: true, rowStart: newRowStart})
      const newsDataCallback = (response) => {
        if (!response.isDataRequestComplete) return

        if (!response.isError) {
          const responseData = response.data || {}

          let xrefSymbols = []
          const symbolsList = responseData.news.map(item => {
            let newsAllSymbols = item.WSODIssue.split('|')

            // get 5 symbols only
            let counter = 1
            for (let symbol of newsAllSymbols) {
              if (counter > 5) {
                break
              }

              if (!isNaN(symbol) && symbol !== '') {
                xrefSymbols.push(symbol)
                counter++
              }
            }

            return item.WSODIssue
          }).join()

        const symbolsListFiltered = symbolsList.split('|').filter(item => (!isNaN(item) && (item !== ''))).join()
        this.setState({newsList: responseData.news, totalItems: responseData.pagination.totalItems})
        xrefSymbols?.length > 0 && this.getXrefData(xrefSymbols.join())
        symbolsListFiltered?.length > 0 && this.getQuote(symbolsListFiltered)
      }
      this.setState({isLoading: false})
    }
      DataRequest.execute(
        `${URLS.CUSTOM.NEWS}`,
        this.buildNewsInputs(newRowStart),
        newsDataCallback
      )
    } else {
      this.setState({isLoading: true})
    }
  }

  getXrefData = (symbols) => {
    let xrefCallback = (response) => {
      if (!response.isError) {
        const responseData = response.data || {}
        this.setState({xRefDataList: responseData.items})
      }
    }
    makeXrefCall(null, xrefCallback, true, symbols)
  }
  getQuote = (symbols) => {
    let callback = (response) => {
      if (!response.isError) {
        const responseData = response.data || {}
        this.setState({quoteData: responseData.quotes})
      }
    }
    getQuoteData(symbols, callback, false)
  }

  pageChangeHandler = (newStartingIndex) => {
    this.setState({ rowStart: newStartingIndex })
    this.getNews(newStartingIndex)
  };

  getQuoteItemsData = (quoteData, issueList) => {
    let list = []
    if (quoteData) {
      const wsodIssues = issueList.split('|')
      wsodIssues.forEach(function (wsodIssue) {
        if (wsodIssue !== '') {
          const itemQuoteData = quoteData.find(item => (item.data && !item.error) && item.data.venueXid === parseInt(wsodIssue))
          if (itemQuoteData) {
            list.push(itemQuoteData)
          }
        }
      })
    }
    return list
  }

  getSymbolDetailList = (symbolList, issueList, quoteData) => {
    let list = []
    if (symbolList && symbolList.length > 0) {
      const wsodIssues = issueList.split('|')
      wsodIssues.forEach(function (wsodIssue) {
        if (wsodIssue !== '') {
          const itemQuoteData = quoteData.find(item => (item.data && !item.error) && item.data.venueXid === parseInt(wsodIssue))
          if (itemQuoteData) {
            const xrefItem = symbolList.find(item => item.xids && item.xids.venue === parseInt(wsodIssue))
            xrefItem && list.push({venueXid: parseInt(wsodIssue), Symbol: xrefItem && xrefItem.symbol})
          }
        }
      })
    }
    return list
  }

  render () {
    return (
      <div className={`${styles.articleNewsListContainer} ${styles.loaderContainer}`}>
        { this.state.isLoading &&
        <Loader
          ready={false}
          spinnerSize={'2x'}
          msg={'Loading'}
        />
        }
        {!this.state.isLoading && this.state.newsList.length <= 0 &&
        <div className={styles.NoData}>
          <Nodata msg={this.props.noDataMsg || 'No News Articles Available for selected filters'} />
        </div>
        }
        {!this.state.isLoading && this.state.newsList.map((item, index) => {
          const quoteDataPerNews = this.getQuoteItemsData(this.state.quoteData, item.WSODIssue)
          const symbolDL = this.getSymbolDetailList(this.state.xRefDataList, item.WSODIssue, this.state.quoteData)
          const categoryList = Object.keys(GetTagsFromCategories(item?.Category))
          const isTopCategory = categoryList?.some(category => this.props.topCategories?.includes(category))
          return (
            <div key={index} data-testid='news-article' >
              <News
                articleId={item.articleId}
                provider={item.Provider}
                date={item.DocumentDate}
                headline={item.Headline}
                teaser={item.Teaser}
                onReadArticle={() => this.readNewsArticle(item, symbolDL, index)}
                setRefs={this.setNewsRefs}
                isTopCategory={isTopCategory}
              />
              { symbolDL.length > 0 &&
              <SymbolList
                key={index}
                symbolDetailList={symbolDL}
                quoteData={quoteDataPerNews}
                symbols={
                  (
                    item.WSODIssue.split('|').filter(symbol => (!isNaN(symbol) && (symbol !== '')))
                  ) || []
                }
              />
              }
              <hr className={styles.articleNewsSeprator} />
            </div>
          )
        })}
        {!this.state.isLoading && this.state.newsList.length > 0 && this.props.showPagination &&
        <div className={styles.articleNewsListPagination}>
          <Pagination
            pageLimit={this.state.pageLimit}
            startingItem={this.state.rowStart}
            totalItems={this.state.totalItems}
            pageChangeHandler={this.pageChangeHandler}
          />
        </div>
        }
        {this.state.isOpenModal &&
        <ModalPopUp
          onClose={this.closePopup}>
          <NewsArticle articleNewsDetailsData={this.state.articleNewsModalItem} availableSymbolList={this.state.availableSymbolList} isFund={this.props.isFund} />
        </ModalPopUp>
        }
      </div>
    )
  }
}

export default NewsList

NewsList.propTypes = {
  limit: PropTypes.number,
  showPagination: PropTypes.bool,
  isFund: PropTypes.bool,
  wsodIssue: PropTypes.number,
  provider: PropTypes.string,
  noDataMsg: PropTypes.string,
  startDate: PropTypes.oneOfType([
    () => [null, undefined],
    () => PropTypes.instanceOf(Date)
  ]),
  endDate: PropTypes.oneOfType([
    () => [null, undefined],
    () => PropTypes.instanceOf(Date)
  ]),
  stopDefaultLoading: PropTypes.bool,
  topCategories: PropTypes.array
}
