/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import 'babel-polyfill'
import React from 'react'
import styles from './F2ContainerPage.module.scss'
import { APP_IDS, EVENT_NAMES } from '../../../utils/f2Constants'
import { KEY_ACCESS_TOKEN } from '../../../utils/appConstants'
import { getAccessToken } from '../../../services/DataRequest'

class TestTokenEvents extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      token: getAccessToken() || ''
    }
  }

  componentDidMount () {
    document.getElementById('btnTestTokenEvents').addEventListener('click', function () {
	  let elCtlCon = document.getElementById('token-test-controls-container')
      let isVisible = elCtlCon.getAttribute('data-visible')

	  if (isVisible === 'false') {
        elCtlCon.setAttribute('data-visible', 'true')
	  } else {
        elCtlCon.setAttribute('data-visible', 'false')
	    }
    })

    document.getElementById('btnInvalidateToken').addEventListener('click', function () {
      sessionStorage.removeItem(KEY_ACCESS_TOKEN)
	    location.reload()
    })

    document.getElementById('btnSetToken').addEventListener('click', function () {
      let token = document.getElementById('txtAccessToken').value
      if (!token) {
        alert('Please enter a valid token!')
      }
      document.getElementById('token-test-controls-container').setAttribute('data-visible', 'false')
      sessionStorage.setItem(KEY_ACCESS_TOKEN, token)
      window.F2.Events.emit(EVENT_NAMES.ACCESS_TOKEN_UPDATED, token)
    })
  }

  setToken = (e) => {
    this.setState({
      token: e.target.value || ''
    })
  }

  render () {
    return (
      <div data-test-token-events='TestF2Events'>
        <button id='btnTestTokenEvents'>Token Events</button>

        <div data-id='token-test-controls' id='token-test-controls-container' data-visible='false'>
          <button data-id='btnInvalidateToken' id='btnInvalidateToken'>Invalidate Token</button>

          <span>Please enter a valid access token!</span>
          <input type='text' aria-label='Access Token' id='txtAccessToken' placeholder='Access Token' onChange={this.setToken} value={this.state.token} />

          <button data-id='btnSetToken' id='btnSetToken'>Set Token</button>
        </div>
      </div>
    )
  }
}

export default TestTokenEvents
