'use strict'

import React from 'react'
import PropTypes from 'prop-types'
import styles from './LinkButton.module.scss'

const LinkButton = ({label, onClick, onKeyDown, btnRef}) => {
  return <button ref={btnRef} aria-label={label} type='button' onClick={onClick} onKeyDown={onKeyDown} className={styles.link}>{label}</button>
}

LinkButton.propTypes = {
  label: PropTypes.node,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  btnRef: PropTypes.object
}

export default LinkButton
