import React, {useState} from 'react'
import Loader from '../../../../Lib/common/Loader'
import {toFormattedDateWithET} from '../../../../../utils/formatter'
import DataRequest from '../../../../../services/DataRequest'
import {URLS} from '../../../../../utils/appConstants'
import {enterKeyEvent, openPDFInNewTab} from '../../../../../utils/utilities'
import PropTypes from 'prop-types'
import styles from '../MarketHighlightsNews.module.scss'

function MarketHighlightsNewsItem ({item, idx}) {
  const [fetchingReport, setFetchingReport] = useState(false)

  function buttonClickHandler (e) {
    e && e.stopPropagation()
    setFetchingReport(true)
    getPDFData(e.currentTarget.getAttribute('data-value'))
  }
  function getPDFData (docKey) {
    const headers = {
      Accept: 'application/pdf'
    }
    DataRequest.execute(
      `${URLS.CUSTOM.RESEARCH_PDF}`,
      {
        params: {
          documentKey: docKey
        },
        responseType: 'blob'
      },
      getPDFDataCallBack,
      null,
      headers
    )
  }

  function getPDFDataCallBack (response) {
    setFetchingReport(false)
    openPDFInNewTab(response)
  }
  return (
    <React.Fragment>
      <div id={'marketHighlightsNews_' + idx} key={idx} className={`${styles.marketHighlightsNewsItem}`}>
        <div className={styles.marketHighlightsNewsProvider}>
          <span className={styles.highlightExtra}>{`${item.provider}`}</span> {`| ${toFormattedDateWithET(item.date)}`}
        </div>

        <div className={styles.marketHighlightsNewsTitle}>{item.title}</div>
        { fetchingReport ? <div className={styles.loaderContainer}><Loader spinnerSize={'1x'} ready={false} /></div> : <button data-testid={`buttonPDF_${idx}`} className={styles.viewPDFButton} data-value={item.documentKey} onClick={buttonClickHandler} onKeyDown={(e) => { enterKeyEvent(e, buttonClickHandler) }}>View PDF</button> }
      </div>
      <div className={styles.ipadSpecificFill} />
    </React.Fragment>
  )
}

export default MarketHighlightsNewsItem

MarketHighlightsNewsItem.propTypes = {
  item: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired
}
