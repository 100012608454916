import { useEffect } from 'react'

export default function PopupAccessibilty (element) {
  useEffect(() => {
    function handleKeyDownPopup (event) {
      if (event.key === 'Escape') {
        element.close()
        return
      }
      let isTabPressed = event.key === 'Tab'
      if (!isTabPressed) {
        return
      }
      if (event.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus() // add focus for the last focusable element
          event.preventDefault()
        }
      } else {
        if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
          firstFocusableElement.focus() // add focus for the first focusable element
          event.preventDefault()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDownPopup)

    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    const modal = document.querySelector('#' + element.id) // select the modal by it's id
    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0] // get first element to be focused inside modal
    const focusableContent = modal.querySelectorAll(focusableElements)
    const lastFocusableElement = focusableContent[focusableContent.length - 1]
    firstFocusableElement.focus()

    return () => window.removeEventListener('keydown', handleKeyDownPopup)
  }, [])
}
