/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-debugger */
import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styles from '../LeftFilterSection/LeftFilterSection.module.scss'
import Filter from '../Filter/Filter'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

function LeftFilterSection (props) {
  const {filterData, filterOptions} = props
  const {selectedValues} = props
  const [isFilterHover, setIsFilterHover] = useState(false)
  const [isFilterActive, setIsFilterActive] = useState(false)
  function toggleFilterState (e) {
    e && e.stopPropagation()
    setIsFilterActive(!isFilterActive)
  }
  function toggleHover () {
    setIsFilterHover(!isFilterHover)
  }
  function handleKeyDown (e) {
    checkIfEnter(e) && toggleFilterState(e)
  }
  function checkIfEnter (e) {
    return e && e.key === 'Enter'
  }
  function handleFilter (dataFromApply, type) {
    toggleFilterState()
    props.handleFilter && props.handleFilter(dataFromApply, type)
  }
  function crossButtonHandle (e) {
    toggleFilterState(e)
  }
  return (
    <div className={styles.leftFilterSectionContainer}>
      <div aria-label='filterIcon' tabIndex='0' role='button' className={` ${styles.filterIcon}`}
        onClick={toggleFilterState} onKeyDown={handleKeyDown} id='filterIcon' data-testid={'filterIcon'}>
        <FontAwesomeIcon icon={isFilterHover ? ['fas', 'filter'] : ['fal', 'filter']} className={styles.iconSize} color={styles.filterHoverColor} onMouseEnter={toggleHover} onMouseLeave={toggleHover} />
        <div className={styles.hoverActiveFilterIcon}>Open Filters</div>
      </div>
      <Filter filterData={filterData} handleClear={props.handleClear} selectedValues={selectedValues} isFilterActive={isFilterActive} handleFilter={handleFilter} crossButtonHandle={crossButtonHandle} filterOptions={filterOptions} />
    </div>
  )
}

export default LeftFilterSection
LeftFilterSection.propTypes = {
  filterData: PropTypes.object,
  handleClear: PropTypes.bool,
  selectedValues: PropTypes.object,
  handleFilter: PropTypes.func,
  filterOptions: PropTypes.object
}
