/* eslint-disable no-debugger */
import { getEnvironment, getSelectedSecurity, logDebugInfo, setSelectedSecurity } from './utilities'
import {EVENT_NAMES, F2_APPS_INPUTS} from './f2Constants'
import {KEY_ACCESS_TOKEN, ISSUE_TYPES} from './appConstants'

const F2 = window.F2

export const loadApp = (appId, rootId, context, suppliedInstanceId) => {
  if (!window.f2token) window.f2token = F2.AppHandlers.getToken()
  if (!F2.isInit()) F2.init()

  const handleRootCreate = appConfig => {
    const sacrificialRootElem = document.createElement('div') // F2 destroys this element
    document.getElementById(rootId).appendChild(sacrificialRootElem)
    appConfig.root = sacrificialRootElem
  }

  F2.AppHandlers.off(
    window.f2token,
    F2.Constants.AppHandlers.APP_CREATE_ROOT
  ).on(
    window.f2token,
    F2.Constants.AppHandlers.APP_CREATE_ROOT,
    handleRootCreate
  )

  const handleAppRender = (appConfig, appHtml) => {
    appConfig.root.innerHTML = appHtml
  }

  F2.AppHandlers.off(
    window.f2token,
    F2.Constants.AppHandlers.APP_RENDER
  ).on(
    window.f2token,
    F2.Constants.AppHandlers.APP_RENDER,
    handleAppRender
  )

  // rootId should be unique but it is not guaranteed
  const instanceId = suppliedInstanceId || rootId

  const f2AppConfig = [{
    appId,
    context,
    instanceId,
    manifestUrl: getManifestUrl(appId)
  }]

  F2.registerApps(f2AppConfig)

  return instanceId
}

export const getManifestUrl = appId =>
  window.MD.CDN_WEB_APP_URL + 'f2/manifests/' + getEnvironment() + '/' + appId + '.manifest.js'

export const removeApp = instanceId => {
  F2.removeApp(instanceId)
}

export const removeAllApps = () => {
  F2.removeAllApps()
}

export const registerF2OnEvent = (eventName, eventHandler) => {
  if (window.F2) {
    window.F2.Events.on(eventName, (data) => {
      logDebugInfo('MOD - Executing F2 On Event: ' + eventName)
      eventHandler(data)
    })
  } else {
    logDebugInfo('MOD - ERROR: F2 is not in the window context')
  }
}

export const registerAccessTokenUpdatedF2OnEvent = (eventHandler) => {
  registerF2OnEvent(EVENT_NAMES.ACCESS_TOKEN_UPDATED, (accessToken) => {
    accessToken && sessionStorage.setItem(KEY_ACCESS_TOKEN, accessToken)
    logDebugInfo('MOD - ACCESS TOKEN UPDATED!')
    eventHandler()
  })
}

export const emitF2Event = (eventName, data = {}) => {
  if (window.F2) {
    logDebugInfo(['MOD - Emitting F2 Event: ' + eventName, data])

    window.F2.Events.emit(
      eventName,
      data
    )

    if ([EVENT_NAMES.MOD_GOTO, EVENT_NAMES.SYMBOL_CHANGED_EVENT].includes(eventName)) {
      let oldSelSecurity = getSelectedSecurity()

      let selectedSecurity = {
        eqSymbol: (data && data.issueType && data.issueType === ISSUE_TYPES.EQ) ? data.symbol : oldSelSecurity.eqSymbol,
        nonEqSymbol: (data && data.issueType && data.issueType !== ISSUE_TYPES.EQ) ? data.symbol : oldSelSecurity.nonEqSymbol
      }

      setSelectedSecurity(selectedSecurity)
    }
  } else {
    logDebugInfo('MOD - ERROR: F2 is not in the window context')
  }
}

export const isValidValue = (value) => {
  return !(value === null || value === undefined || value.trim() === '')
}

export const checkF2AppRequiredInputs = (appName, context) => {
  // get app required inputs
  let appInputs = F2_APPS_INPUTS[appName] || {}

  let missingInputs = []

  // app input is required and value is missing, than add to missing list
  Object.keys(appInputs).forEach((input) => {
    if (appInputs[input].required && !isValidValue(context[input])) {
      missingInputs.push(input)
    }
  })

  return missingInputs
}
