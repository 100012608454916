/* eslint-disable react/prop-types */
import 'babel-polyfill'
import React from 'react'
import F2AppContainer from '../../../../F2AppContainer'
import { APP_IDS, EVENT_NAMES } from '../../../../../../utils/f2Constants'
import TokenEvents from '../../../TokenEvents'
import Tabs from '../../../../../Lib/Tabs/Tabs'
import styles from './FundamentalsPage.module.scss'
import { changeTabHandler, getSymbolFromPath } from '../../../../utils'
import { registerF2OnEvent, emitF2Event } from '../../../../../../utils/f2Methods'

class FundamenatalsPage extends React.Component {
  constructor (props) {
    super(props)
    this.symbolRecvd = this.props.match.params.symbol
    this.state = {
      period: 'Annual'
    }
  }

  componentDidMount () {
    registerF2OnEvent(EVENT_NAMES.MOD_GOTO, (modGotoContext) => {
      emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, {symbol: modGotoContext.symbol})
    })
  }
  render () {
    return (
      <div data-page='F2AppContainer'>
        <div className={styles.header}>
          <h3>Quotes & Research</h3>

        </div>
        <div>
          <F2AppContainer
            appId={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH}
            rootId={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH} />
        </div>
        <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR} >
          <F2AppContainer
            removeApp
            appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR}
            context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
            rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR} />
        </div>
        <div className={styles.quoteAndResearch}>
          <Tabs id={'MainPage_tab'} ariaLabel={'Finanace tabs'} activeTab={'Fundamentals'} onClick={(k) => { changeTabHandler(k) }}>
            <div key='Snapshot' label='Snapshot' />
            <div key='charts' label='Charting' />
            <div key='Options' label='Options' />
            <div key='dividends' label='Dividends' />
            <div key='NewsAndResearch' label='News & Research' />
            <div key='Valuations' label='Valuations' />

            <div key='EarningsAndEstimates' label='Earnings & Estimates' />

            <div key='Fundamentals' label='Fundamentals'>
              <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_Fundamenatals} >
                <F2AppContainer
                  removeApp
                  appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_Fundamenatals}
                  context={{ symbol: getSymbolFromPath(this.symbolRecvd), period: this.state.period }}
                  rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_Fundamenatals} />
              </div>
            </div>
            <div key='Ownership' label='Ownership' />
          </Tabs>
        </div>
        <TokenEvents />
      </div>
    )
  }
}

export default FundamenatalsPage
