import React from 'react'
import PropTypes from 'prop-types'
import {fundTypeWithStockHiglights} from '../../../components/utils/appConstants'
import styles from './StockHighlightsItem.module.scss'
import fundStyles from '../../Shared/QuoteCardWithStockHighlights/ProductTypeCard/FundQuoteCardWithStockHiglights.module.scss'

const StockHighlightsItem = (props) => {
  let style = (fundTypeWithStockHiglights.includes(props.type)) ? fundStyles : styles
  return (
    <React.Fragment>
      <div className={style.stockHighlightDataDiv}>
        <div className={style.stockHighlightCol1} style={props.keyStyling}>{props.label}
          {props.asofDate && <div>{props.asofDate}</div> }
        </div>
        <div className={style.stockHighlightCol2} style={Object.assign({}, props.keyStyling, props.valueStyling)}>{props.value}</div>
      </div>
    </React.Fragment>
  )
}
export default StockHighlightsItem
StockHighlightsItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  valueStyling: PropTypes.object,
  keyStyling: PropTypes.object,
  type: PropTypes.string,
  asofDate: PropTypes.string
}
