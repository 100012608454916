import React from 'react'
import Tooltip from '../../Overlay/ToolTip'
import styles from './AIProductCard.module.scss'
import PropTypes from 'prop-types'
import { emitF2Event } from '../../../../../../../utils/f2Methods'
import { EVENT_NAMES } from '../../../../../../../utils/f2Constants'
import { MOD_GOTO_PAGES, PROFILE } from '../../../utils/appConstants'

const AIProductCard = (props) => {
  function overlayKeyHandler (venueXid) {
    props.overlayKeyHandler(venueXid)
  }

  function viewProfile () {
    let dataEmit = {
      gotoPage: MOD_GOTO_PAGES.AI,
      activeTabKey: PROFILE,
      symbol: props.symbol
    }
    emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, dataEmit)
  }

  return (
    <React.Fragment>
      <div className={styles.AICardHeader}>
        <div className={styles.AICardHeaderMain} id={'aiSymbol'} aria-label={`Symbol: ${props.symbol}`} role='button' tabIndex='0' onClick={viewProfile} onKeyDown={viewProfile}>
          {props.symbol}
        </div>
        <div className={styles.AICardHeaderDesc}>
          {props.name}
        </div>
        <div className={styles.toolTip}>
          <Tooltip id={props.id} type={props.type} data={{...props}} IsFVETF={props.IsFVETF} symbol={props.symbol}
            viewShareClassModalHandler={props.viewShareClassModalHandler} overlayKeyHandler={(venueXid) => overlayKeyHandler(venueXid)} />
        </div>
      </div>
      <div className={styles.strategyTypediv}>
        <div id={'buttonFund' + props.id} name='AI' className={styles.AI} aria-label={`Type: ${props.StrategyType}`} role='button'>{props.StrategyType}</div>
        <div className={styles.price} aria-label={`Price: ${props.price}`} role='button'>{props.price}</div>
      </div>
    </React.Fragment>
  )
}
export default AIProductCard

AIProductCard.propTypes = {
  type: PropTypes.string,
  viewShareClassModalHandler: PropTypes.func,
  symbol: PropTypes.string,
  name: PropTypes.string,
  IsFVETF: PropTypes.bool,
  overlayKeyHandler: PropTypes.func,
  id: PropTypes.string,
  StrategyType: PropTypes.string,
  price: PropTypes.number
}
