export const NewsPreferencesModalTitle = 'News For You'
export const NewsPreferencesModalBodyMainTitle = 'What news are you interested in?'
export const NewsPreferencesModalBodySubTitle = `<p>Select your preferences for news that you would like to see. <b>Please select at least one category to add to your News & Research.</b>You can always edit your preferences later. Select up to 10 from the list below.</p>`
export const EditNewsPreferencesModalBodySubTitle = `<div style="font-size:14px">Below are the current news categories you’ve been reading and showing interest in. You may change your preferences so we can better suggest articles for you. <b>Please select at least one category.</b> You can always edit these preferences later.</div>`
export const NewsPreferencesUpdatedMessage = 'News Preferences updated successfully'
export const NewsPreferencesSavedMessage = 'News Preferences saved successfully'
export const AllCategories = 'All Categories'
export const ClearAllPrefrences = 'Clear All Selected Preferences'
export const SelectedCount = '/10 selected'
export const MaxLimit = '/10 selected. Please deselect some categories to add more.'
export const ClearAll = 'Clear All'
