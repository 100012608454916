import React, {useEffect, useState, useRef } from 'react'
import styles from './TechnicalIndicator.module.scss'
import PropTypes from 'prop-types'
import BasicDropDown from '../Dropdown/BasicDropDownFixed'
import RangeSelectorButton from '../RangeSelector/RangeSelectorButton'
import {deviceType, getDeviceType, handleWindowResize} from '../../../../../../utils/utilities'

const TechnicalIndicator = props => {
  let dropDownElement = useRef()
  const [device, setDevice] = useState(getDeviceType())
  const [selectedValues, setSelectedValues] = useState(props.item.selectedValue)

  useEffect(() => {
    setSelectedValues(props.item.selectedValue)
  }, [props.item.selectedValue])

  useEffect(() => {
    handleWindowResize((deviceType) => {
      setDevice(deviceType)
    })
  })
  const isMobile = device === deviceType.Ipad || device === deviceType.Mobile

  const getLiStyle = (width) => {
    const obj = {
      left: 0,
      width: width,
      position: 'fixed',
      background: '#fff',
      top: 0
    }
    if (isMobile) {
    }

    return obj
  }

  const dropDownListOpen = () => {
  }

  const handleDropdownChange = (e, item, select) => {
    if (!(props.item.buttonValues && props.item.buttonValues.length > 0)) {
      item.isSelected = true
    }
    item.selectedValue[select.screenerName] = select.options.filter(x => x.val === e.getAttribute('data-value'))[0].name
    props.handleClick(e, item, props.selectedParent, item.isSelected ? undefined : 'no')
  }

  const handleOnButtonClicked = (buttonClicked, item, buttonBand) => {
    item.isSelected = true
    const selectedButton = buttonBand.options.filter(x => x.val === buttonClicked)[0]
    const buttonBandName = buttonBand.screenerName
    item.selectedValue[buttonBandName] = selectedButton.name
    setSelectedValues({...selectedValues, [buttonBandName]: selectedButton.name})
    props.handleClick(buttonClicked, item, props.selectedParent)
  }

  const getSelectedButtonValue = (x) => {
    let value = x.options.filter(y => y.name === selectedValues[x.screenerName])
    return value.length > 0 && value[0].val
  }
  function setBasicDropdownStyle (select) {
    let style = { float: 'none', position: 'relative', marginLeft: 0, height: 'auto', overflowY: 'initial', width: select.width }
    if (select.lineHeight) {
      style['lineHeight'] = select.lineHeight
    }
    if (select.height) {
      style['height'] = select.height
    }
    return style
  }

  return <div className={styles.technicalIndicator}>
    <div className={styles.inputItem} >
      <div className={styles.firstdiv}>
        <div className={`${styles.technicalIndicatorDropdown} dvDropDown`} ref={dropDownElement}>
          {props.item.selects.map((select, index) =>
            <div key={index}>
              <BasicDropDown label={select.title} dropDownListOpen={dropDownListOpen}
                customStyle
                data={select.options.map(x => x.val)}
                selectedValue={select.options.filter(y => y.name === selectedValues[select.screenerName])[0].val}
                changeHandler={(val) => handleDropdownChange(val, props.item, select)}
                liStyle={getLiStyle(select.width)}
                styles={setBasicDropdownStyle(select)} />
            </div>
          )}
        </div>
      </div>
      { props.item.buttonValues && props.item.buttonValues.map(x =>
        <div key={x.screenerName} className={`${styles.technicalIndicatorButtons}`}>
          <RangeSelectorButton
            item={{arrayList: x.options.map(y => y.val), selectedValue: getSelectedButtonValue(x)}}
            styles={{ paddingLeft: 0, display: 'flex', width: x.width }}
            onChange={(buttonClicked) => handleOnButtonClicked(buttonClicked, props.item, x)} />
        </div>
      )}
    </div>
  </div>
}
TechnicalIndicator.propTypes = {
  item: PropTypes.object,
  handleClick: PropTypes.func,
  selectedParent: PropTypes.object
}
export default TechnicalIndicator
