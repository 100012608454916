'use strict'
import React from 'react'
import PropTypes from 'prop-types'
import Table from './Table'
import NoResults from '../NoResults/NoResults'
import styles from './TableWithShowMore.module.scss'
import { AI_TABS_LIST } from '../../Screener/AI/AIConstants'
function TableWithShowMore ({headers, moduleType, chkboxSelectedIds, ModelScreenerData, maxSelectionLength, fixedColumnCount, handleOnCheckboxChange, defaultSortColumn, moduleName, errorMsg, startIndex, syncFixedColumns, helperMesssage, showMoreClick, showMoreClickRows, sortColumnData, deviceType}) {
  let moduleTypeName = moduleType
  let data = ModelScreenerData.data
  // Sorting first time by column with SortDirection flag set in Header json

  function handleShowMoreEnterClick (e) {
    if (e.key === 'Enter') {
      showMoreTableRows()
    }
  }
  function showMoreTableRows () {
    showMoreClick()
  }
  function datamsg (moduleTypeName) {
    let msgtext = []
    switch (moduleTypeName) {
      case 'ModelScreener':
        msgtext = ['No Models Meet the Selected Criteria', 'Try Expanding to a Broader Search']
        break
      case 'FundScreener':
        msgtext = ['No Funds Meet the Selected Criteria', 'Try Expanding to a Broader Search']
        break
      case 'EquityScreener':
        msgtext = ['No Equity Meet the Selected Criteria', 'Try Expanding to a Broader Search']
        break
      case AI_TABS_LIST.Profile:
        msgtext = [`No ${moduleTypeName} Data Available`]
        break
      case 'NonAlternativeInvestmentScreener' :
        msgtext = ['No Non-Traded REIT Data Available']
        break
    }
    return msgtext
  }
  return (
    <div className={styles.container} id={moduleName}>
      {(ModelScreenerData && ModelScreenerData.data.length > 0) && <div>
        <Table _data={data} deviceType={deviceType} moduleTypeName={moduleTypeName} chkboxSelectedIds={chkboxSelectedIds} headers={headers} handleOnCheckboxChange={handleOnCheckboxChange} maxSelectionLength={maxSelectionLength} fixedColumnCount={fixedColumnCount} moduleName={moduleName} errorMsg={errorMsg} startIndex={startIndex} syncFixedColumns={syncFixedColumns} sortColumnData={sortColumnData} />
      </div>}
      {((!ModelScreenerData) || (ModelScreenerData && ModelScreenerData.data.length === 0)) && <div className={styles.setNoResultMargin}>
        <NoResults iconName={['fas', 'exclamation-triangle']} data={datamsg(moduleTypeName)} iconStyles={'modelScreenerIcon'} msgStyles={'modelScreenermsg'} containerHeight={{'height': '484px'}} moduleTypeName={moduleTypeName} />
      </div>
      }
      <div role={'button'} tabIndex={'0'} id='showMore' className={styles.showMoreTblRows} onKeyDown={(e) => handleShowMoreEnterClick(e)} onClick={showMoreTableRows}>
        {((showMoreClickRows > 0)) &&
            `${'Show More ('} ${showMoreClickRows} ${')'} `}
      </div>
      <div className={styles.benchmarkDisclaimer} style={moduleTypeName === 'ModelScreener' ? {marginTop: '0px'} : {}}>
        {helperMesssage && helperMesssage !== '' &&
          <span role='contentinfo' aria-label={'*' + helperMesssage} className={styles.helperMessage}>
            <sup>*</sup>{helperMesssage[0]}
            {helperMesssage.length > 1 && <sup style={{marginLeft: '10px'}}>**</sup> } {helperMesssage[1]}
          </span>
        }
      </div>

    </div>
  )
}

export default TableWithShowMore

TableWithShowMore.propTypes = {
  headers: PropTypes.array.isRequired,
  moduleType: PropTypes.string,
  ModelScreenerData: PropTypes.object.isRequired,
  maxSelectionLength: PropTypes.number,
  fixedColumnCount: PropTypes.number,
  handleOnCheckboxChange: PropTypes.func,
  defaultSortColumn: PropTypes.string,
  chkboxSelectedIds: PropTypes.array,
  moduleName: PropTypes.string,
  errorMsg: PropTypes.object,
  startIndex: PropTypes.number,
  syncFixedColumns: PropTypes.object,
  helperMesssage: PropTypes.array,
  showMoreClick: PropTypes.func,
  showMoreClickRows: PropTypes.number,
  sortColumnData: PropTypes.func,
  deviceType: PropTypes.string
}
