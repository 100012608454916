import React, { useState, useEffect, useRef } from 'react'
import { CSVLink } from 'react-csv'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './TopHolding.module.scss'
import HoldingTable from './HoldingTable'
import ChartistChart from '../../../../../Lib/Chartist/ChartistChart'
import { URLS } from '../../utils/appConstants'
import Loader from '../../../../../Lib/common/Loader'
import NoData from '../../../../../Lib/NoData/NoData'
import HoldingBar from './HoldingBar'
import PropTypes from 'prop-types'
import { getAPIData } from '../../utils/utilities'
import LinkButton from '../../../../../Lib/LinkButton/LinkButton'
import { EVENT_NAMES } from '../../../../../../utils/f2Constants'
import { TAB_NAMES } from '../../../../../../utils/appConstants'
import { emitF2Event } from '../../utils/f2Methods'
import {makeXrefCall} from '../../../../../../services/XrefAPICall'
function TopHoldings ({ xid, model = null, isLocked = false, type = null, isPortfolioEnabled = false }) {
  let [piedata, setPieData] = useState([])
  const [isLoadingAndError, setIsLoadingAndError] = useState({ isLoading: true, isError: false })
  let color = ['#004480', '#0095FF', '#D97E07', '#00A695', '#AA01AA', '#FF573F', '#9085FF', '#BF360C', '#00AC00', '#093055']
  const spinnerStyle = { 'minWidth': 'auto', 'height': 'auto' }
  const [csvHoldingData, setHoldingData] = useState({data: [], fileName: 'HoldingsAndAllocations.csv'})
  const csvHoldingInstance = useRef()
  let allholdingsData = useRef([])
  let isDataFromQid = useRef(false)
  function resetState () {
    setIsLoadingAndError({ isLoading: true, isError: false })
  }
  useEffect(() => {
    // Whenever fund changes - resets states of each sub-component
    resetState()
    if (!isLocked) {
      getTopHoldingData()
      donutChartPlugin(piedata.map(({ allocation }) => (allocation)))
    } else {
      setIsLoadingAndError({ isError: false, isLoading: false })
    }
  }, [xid, (model && model.modelClientId)])

  const getTopHoldingData = () => {
    const callback = (response) => {
      if (!response.isError && response.data) {
        let data = response.data
        let tempPiedata = []
        if (data && data.items && data.items.length) {
          if (model) {
            if (model.strategyCode.toLowerCase() === 'third party model' && data.isDataFromQid) {
              data.items.forEach((item, index) => {
                tempPiedata.push({
                  color: color[index],
                  allocation: item.weightingAllocation,
                  symbol: item.symbol,
                  name: item.name,
                  xid: item.venueXid,
                  cusip: item.cusip
                })
              })
              isDataFromQid.current = data.isDataFromQid
              setPieData(tempPiedata.slice(0, 10))
            }
          } else if (data.items) {
            data.items.forEach((item, index) => {
              tempPiedata.push({
                color: color[index],
                allocation: item.shareDetails.weighting,
                symbol: item.securityIdentifiers.ticker,
                name: item.securityIdentifiers.name,
                xid: item.securityIdentifiers.venueXid
              })
            })
            setPieData(tempPiedata)
          }
          allholdingsData.current = tempPiedata
          setIsLoadingAndError({ isError: tempPiedata.length === 0, isLoading: false })
        } else {
          setIsLoadingAndError({ isError: true, isLoading: false })
        }
      } else {
        setIsLoadingAndError({ isError: true, isLoading: false })
      }
    }
    getAPIData(xid, URLS.PLATFORM.TOP_HOLDINGS, callback)
  }

  const piedata1 = piedata.map(({ allocation }) => (allocation))

  if (piedata1.length > 1) {
    const sum = 100 - (piedata1.reduce((total, allocatedValue) => total + allocatedValue))
    piedata1.push(sum)
  }
  const data = {
    series: piedata1
  }

  const options = {
    donut: true,
    donutWidth: 25,
    startAngle: 0,
    total: 100,
    plugins: [
      donutChartPlugin(data)
    ],
    width: '160px',
    height: '160px'
  }
  function donutChartPlugin (chartData) {
    return function (chart) {
      chart.on('draw', function (data) {
        if (data.type === 'slice') {
          // Get the total path length in order to use for dash array animation
          var pathLength = data.element._node.getTotalLength()

          // Set a dasharray that matches the path length as prerequisite to animate dashoffset
          data.element.attr({
            'stroke-dasharray': (pathLength - 1) + 'px'
          })
        }
      })
    }
  }

  function DownloadHoldingsCsv () {
    if (isDataFromQid.current) {
      holdingDataForExport({})
    } else {
      let holdingVenueXids = allholdingsData.current.map(itemData => itemData.xid)
      let callback = (response) => {
        if (!response.isDataRequestComplete || response.isError) {
          return
        }
        if (!response.isError) {
          if (response && response.data) {
            let xidCusips = {}
            response.data.items.forEach(xrefItem => {
              if (xrefItem && xrefItem.xids && xrefItem.supplementalData) {
                let matchItem = xrefItem.supplementalData.filter(itm => itm.name === 'cusip')
                if (matchItem.length > 0) {
                  xidCusips[xrefItem.xids.venue] = matchItem[0].value
                }
              }
            })
            holdingDataForExport(xidCusips)
          }
        }
      }
      makeXrefCall(null, callback, true, holdingVenueXids.filter(xid => xid))
    }
  }

  function holdingDataForExport (xidCusips) {
    let exportHoldingItems = []
    allholdingsData.current.map(holdingItem => {
      exportHoldingItems.push({
        'Model/Strategy Name': model.strategyName,
        'Model/Strategy Code': model.strategy,
        'Ticker': holdingItem.symbol,
        'CUSIP': holdingItem.cusip || (xidCusips[holdingItem.xid] || ''),
        'Allocation (%)': holdingItem.allocation
      })
    })
    setHoldingData({data: exportHoldingItems, fileName: `HoldingsAndAllocations_${model.strategy}.csv`})
    csvHoldingInstance.current.link.click()
  }

  function handleDownloadHoldingsCsv (e) {
    if (e.key === 'Enter') {
      debounce(DownloadHoldingsCsv, 1000)
    }
  }

  let debounce = (method, delay) => {
    clearTimeout(method._tId)
    method._tId = setTimeout(function () {
      method()
    }, delay)
  }

  const portfolioHandler = () => {
    emitF2Event(EVENT_NAMES.GOTO_FUND_PROFILE_TAB, {activeTabKey: TAB_NAMES.PORTFOLIO})
  }

  if (isLoadingAndError.isLoading) {
    return (
      <div className={styles.topHoldingsContainer}>
        <div className={styles.header}>Top 10 Holdings</div>
        <Loader spinnerStyle={spinnerStyle} ready={false} msg={'Loading'} spinnerSize={'2x'} />
      </div>
    )
  }
  if (isLoadingAndError.isError) {
    return (
      <div className={styles.topHoldingsContainer}>
        <div className={styles.header}>Top 10 Holdings</div>
        <NoData />
      </div>
    )
  }
  return (
    <div className={styles.topHoldingsContainer}>
      <div className={styles.header} id='Top10Holdings' >Top 10 Holdings</div>
      {['pershingModelsSnapshot', 'modelsSnapshot'].includes(type) && !isLocked && piedata.length > 0 &&
        <div className={styles.downloadHoldingsButtonDiv}>
          <span aria-label='Download Holdings CSV' onKeyDown={(e) => handleDownloadHoldingsCsv(e)} onClick={() => debounce(DownloadHoldingsCsv, 1000)} role='button' tabIndex='0'>
            <FontAwesomeIcon icon={['fas', 'download']} className={styles.downloadIcon} />
          </span>
          <span style={{display: 'none'}}><CSVLink ref={csvHoldingInstance} data={csvHoldingData.data} filename={csvHoldingData.fileName} /></span>
          <div className={styles.exportTooltip}>Export Holdings & Allocations of this Model</div>
        </div>
      }
      {!model && <HoldingBar xid={xid} />}
      {!isLocked && <div className={`${styles.topHoldings} ${['pershingModelsSnapshot', 'modelsSnapshot'].includes(type) ? styles.modelsTopHoldings : ''}`}>
        <div className={styles.chart} aria-hidden='true'>
          <ChartistChart data={data} type={'Pie'} options={options} className={styles.donutchart} />
        </div>
        <div className={`${styles.table} ${type === 'modelsSnapshot' ? styles.modelsTable : ''}`}> <HoldingTable tableData={piedata} />
        </div>
      </div>}
      { isPortfolioEnabled && <div className={styles.linkBtn}>
        <LinkButton label={'Go to ESG Metrics'} onClick={portfolioHandler} />
      </div>
      }
    </div>
  )
}

TopHoldings.propTypes = {
  xid: PropTypes.any,
  model: PropTypes.object,
  isLocked: PropTypes.bool,
  type: PropTypes.string,
  isPortfolioEnabled: PropTypes.bool
}

export default TopHoldings
