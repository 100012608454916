/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import 'babel-polyfill'
import React from 'react'
import F2AppContainer from '../../../../F2AppContainer'
import { APP_IDS, EVENT_NAMES } from '../../../../../../utils/f2Constants'
import TokenEvents from '../../../TokenEvents'
import Tabs from '../../../../../Lib/Tabs/Tabs'
import styles from '../../../../ClientApplication/F2ContainerPage.module.scss'
import stylesQuoteResearch from './Snapshot.module.scss'
import { changeTabHandler, setAccessToken, getSymbolFromPath } from '../../../../utils'
import SymbolSearch from '../../../../../Lib/SymbolSearch/SymbolSearch'
import { emitF2Event, registerF2OnEvent, removeAllApps } from '../../../../../../utils/f2Methods'

class Snapshot extends React.Component {
  constructor (props) {
    super(props)
    this.symbolRecvd = this.props.match.params.symbol
    this.state = {
      symbol: 'MSFT',
      period: 'Annual'
    }
  }

  componentDidMount () {
    registerF2OnEvent(EVENT_NAMES.MOD_GOTO, (modGotoContext) => {
      emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, {symbol: modGotoContext.symbol})
    })
  }

  emilPeriodUpdate = event => {
    emitF2Event(EVENT_NAMES.PERIOD_CHANGED_EVENT, event.currentTarget.getAttribute('data-value'))
  }

  emitSymbolChangedEvent = () => {
  }

  render () {
    return (
      <div data-page='F2AppContainer'>
        <div className={stylesQuoteResearch.header}>
          <span>Quotes & Research</span>
        </div>
        <div>
          <F2AppContainer
            appId={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH}
            rootId={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH} />
        </div>
        <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR} >
          <F2AppContainer
            removeApp
            appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR}
            context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
            rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR} />
        </div>

        <div className={stylesQuoteResearch.quoteAndResearch}>
          <Tabs id={'MainPage_tab'} ariaLabel={'Finanace tabs'} activeTab={'Snapshot'} onClick={(k) => { changeTabHandler(k) }}>
            <div key='Snapshot' label='Snapshot'>
              <div className={stylesQuoteResearch.newsResearchContainer}>
                <div className={stylesQuoteResearch.leftSectionContainer} >
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PERFORMANCE} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PERFORMANCE}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PERFORMANCE} />
                  </div>
                </div>
                <div className={stylesQuoteResearch.rightSectionContainer} >
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_RATINGS} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_RATINGS}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_RATINGS} />
                  </div>
                </div>
              </div>
              <div className={stylesQuoteResearch.snapshotevents}>
                <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_SNAPSHOT_EVENTS} >
                  <F2AppContainer
                    appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_SNAPSHOT_EVENTS}
                    context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                    rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_SNAPSHOT_EVENTS} />
                </div>
              </div>
              <div className={stylesQuoteResearch.earningsAndEstimateApp}>
                <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_EARNINGS_AND_ESTIMATES} >
                  <F2AppContainer
                    appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_EARNINGS_AND_ESTIMATES}
                    context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                    rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_EARNINGS_AND_ESTIMATES} />
                </div>
                <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_HIGHLIGHTS} >
                  <F2AppContainer
                    appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_HIGHLIGHTS}
                    context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                    rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_HIGHLIGHTS} />
                </div>
              </div>
              <div className={styles.esgScoresAndRatingsApp}>
                <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_ESG_SCORES} >
                  <F2AppContainer
                    appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_ESG_SCORES}
                    context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                    rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_ESG_SCORES} />
                </div>
              </div>
              <div className={styles.esgScoresAndRatingsApp}>
                <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_OTHER_WAYS_TO_INVEST} >
                  <F2AppContainer
                    appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_OTHER_WAYS_TO_INVEST}
                    context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                    rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_OTHER_WAYS_TO_INVEST} />
                </div>
              </div>
              <div className={stylesQuoteResearch.stockValuationContainer}>
                <div className={stylesQuoteResearch.leftSectionContainer} >
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_REVENUE} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_REVENUE}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_REVENUE} />
                  </div>
                </div>
                <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_VALUATION} >
                  <F2AppContainer
                    appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_VALUATION}
                    context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                    rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_VALUATION} />
                </div>
              </div>
              <div className={stylesQuoteResearch.newsResearchContainer}>
                <div className={stylesQuoteResearch.leftSectionContainer} >
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_NEWS_ARTICLELIST} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_NEWS_ARTICLELIST}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_NEWS_ARTICLELIST} />
                  </div>
                </div>
                <div className={stylesQuoteResearch.rightSectionContainer}>
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_COMPANY_DESCRIPTION} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_COMPANY_DESCRIPTION}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_COMPANY_DESCRIPTION} />
                  </div>
                </div>
              </div>
            </div>
            <div key='Charting' label='Charting' />
            <div key='Options' label='Options' />
            <div key='Dividends' label='Dividends' />
            <div key='NewsAndResearch' label='News & Research' />
            <div key='Valuations' label='Valuations' />
            <div key='EarningsAndEstimates' label='Earnings & Estimates' />
            <div key='Fundamentals' label='Fundamentals' />
            <div key='Ownership' label='Ownership' />
          </Tabs>
        </div>
        <TokenEvents />
      </div>
    )
  }
}

export default Snapshot
