/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react'
import styles from './ErrorMsg.module.scss'
import PropTypes from 'prop-types'

function ErrorMsg (props) {
  return (
    <div id={'errormsg' + props.type + props.index} style={{ width: props.width }} className={styles.errormsg} aria-label={props.data}>
      {props.data}
    </div>
  )
}
ErrorMsg.propTypes = {
  data: PropTypes.string,
  width: PropTypes.string,
  index: PropTypes.number,
  type: PropTypes.string
}
export default ErrorMsg
