import 'babel-polyfill'
import React from 'react'
import F2AppContainer from '../../../F2AppContainer'
import { APP_IDS } from '../../../../../utils/f2Constants'
import TokenEvents from '../../TokenEvents'

class PageEquityScreener extends React.Component {
  render () {
    return (
      <React.Fragment>
        <div data-page='F2AppContainer'>
          <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH}>
            <F2AppContainer
              appId={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH}
              context={{ symbol: 'MSFT' }}
              rootId={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH} />
          </div>
          <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_EQUITY_SCREENER}>
            <F2AppContainer
              appId={APP_IDS.COM_PERSHING_NETXWEALTH_EQUITY_SCREENER}
              context={{}}
              rootId={APP_IDS.COM_PERSHING_NETXWEALTH_EQUITY_SCREENER} />
          </div>
        </div>
        <TokenEvents />
      </React.Fragment>
    )
  }
}

export default PageEquityScreener
