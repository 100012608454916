import moment from 'moment-timezone'
import { DEFAULT_PRE_SCREEN_INPUTS, ModelPreScreenInput, MODEL_SCREEN_TYPE } from '../utils/appConstants'
import { screenerIndicatorDictionary, screenerDivFreqDictionary, replaceAllChars, replaceAllCharsStrict } from './CommonFunctions'

const ScreenerHelper = {}

ScreenerHelper.getMinMaxValues = (itemlist) => {
  let selectedVal = ''
  let calcMin = ''
  let calcMax = ''
  if (itemlist.selectedVal.indexOf('>') > -1) {
    selectedVal = replaceAllChars(itemlist.selectedVal, ' >%', '')
    calcMin = selectedVal
  } else if (itemlist.selectedVal.indexOf('<') > -1) {
    selectedVal = replaceAllChars(itemlist.selectedVal, ' <%', '')
    calcMax = selectedVal
  } else if (itemlist.selectedVal.indexOf('to') > -1) {
    selectedVal = replaceAllChars(itemlist.selectedVal, ' %', '')
    calcMin = selectedVal.split('to')[0]
    calcMax = selectedVal.split('to')[1]
  } else {
    if (itemlist.selectedVal.indexOf('(') === -1) {
      selectedVal = replaceAllChars(itemlist.selectedVal, ' %', '')
      calcMin = selectedVal.split('-')[0]
      calcMax = selectedVal.split('-')[1]
    } else {
      // "(-10.957)-6"
      // "(-10.957)-(-6.4)"
      let selectedVal = itemlist.selectedVal
      selectedVal = replaceAllCharsStrict(selectedVal, '(-', '~') // ~10.957-6
      selectedVal = replaceAllCharsStrict(selectedVal, ')', '')
      calcMin = selectedVal.split('-')[0].replace('~', '-')
      calcMax = selectedVal.split('-')[1].replace('~', '-')
    }
  }
  return { calcMin, calcMax }
}
// buils screener arguments for conrol like slider, rangebutton
ScreenerHelper.buildControlArgument = (name, minValue, maxValue, isSelected, screenerArguments, itemlist) => {
  let screenArguments = screenerArguments
  let min = 0
  let max = 0
  var fields = ['PERMInceptionMsDate', 'SPCFInceptionDate']
  if (fields.includes(name)) {
    min = maxValue !== '' ? formatValue(name, maxValue) : ''
    max = minValue !== '' ? formatValue(name, minValue) : ''
  } else {
    min = minValue !== '' ? (name === 'PERMLastUpdatedMsDate' || name === 'PERAIAnticipatedOfferingCloseMsDate' || name === 'PERAIInitialOfferingMsDate' ? Math.floor(formatValue(name, minValue)) : formatValue(name, minValue)) : ''
    max = maxValue !== '' ? (name === 'PERMLastUpdatedMsDate' || name === 'PERAIAnticipatedOfferingCloseMsDate' || name === 'PERAIInitialOfferingMsDate' ? Math.ceil(formatValue(name, maxValue)) : formatValue(name, maxValue)) : '' // Changes for PRW-999 , DB values are stored in date time and we pass date with current calendar time
  }
  for (var index = 0; index < screenArguments.length; index++) {
    let screenArgument = screenArguments[index]
    if (itemlist.control === 'rangeSelectorCheckbox' && Array.isArray(itemlist.selectedValue)) {
      // screenArguments.splice(index, 1)

      // foundArgument = true
    } else if (screenArgument.field === name || screenArgument.field === itemlist.rangeSelectorVal) {
      screenArguments.splice(index, 1)
    }
  }
  // PRW-993
  if (itemlist.control === 'rangeSelector') {
    if (itemlist.selectedValue !== '' && itemlist.rangeSelectorVal) {
      name = itemlist.rangeSelectorVal
    }
  } else if (itemlist.control === 'rangeSelectorCheckbox' && itemlist.selectedValue && itemlist.selectedValue.length > 0 && itemlist.rangeSelectorVal) {
    name = itemlist.rangeSelectorVal
  }

  let baseArgument = {
    field: name,
    valueOperator: 'AND',
    conditions: []
  }
  // There was a greater than and a less than provided so use the GreaterThanOrEqualTo and LessThanOrEqualTo
  if (min !== '' && max !== '') {
    if (baseArgument.field === 'basics-select' && max === undefined) {
      max = min
    }
    baseArgument.conditions = [
      {
        operator: 'GreaterThanEqualTo',
        values: [
          min.toString()
        ]
      },
      {
        operator: 'LessThanEqualTo',
        values: [
          max.toString()
        ]
      }
    ]
  } else if (min === '' && max !== '') { // This is an argument for only the bottom end so use the LessThan
    baseArgument.conditions = [
      {
        operator: 'LessThan',
        values: [
          max.toString()
        ]
      }
    ]
  } else if (min !== '' && max === '') { // This is an argument for only the top end so use the GreaterThan
    baseArgument.conditions = [
      {
        operator: 'GreaterThan',
        values: [
          min.toString()
        ]
      }
    ]
  }
  if (isSelected) {
    if (itemlist.control === 'rangeSelectorCheckbox') {
      return ScreenerHelper.addRemoveEquityScreenArgument(screenArguments, baseArgument)
    } else {
      screenArguments.push(baseArgument)
    }
  }
}

ScreenerHelper.addRemoveEquityScreenArgument = (screenArguments, argument) => {
  let foundArgument = false
  let allEquityArgsHaveBeenRemoved = false
  let equityArgIndex
  for (let index = 0; index < screenArguments.length; index++) {
    let screenArgument = screenArguments[index]

    if (screenArgument.field === '' && screenArgument != null && screenArgument.arguments.length > 0 && screenArgument.arguments[0].field === argument.field) {
      equityArgIndex = index
      let equityArguments = screenArgument

      foundArgument = true
      let foundSubArgument = false

      for (let indexA = 0; indexA < equityArguments.arguments.length; indexA++) {
        let equityArgument = equityArguments.arguments[indexA]
        foundSubArgument = JSON.stringify(equityArgument) === JSON.stringify(argument)

        if (foundSubArgument) {
          screenArguments[index].arguments.splice(indexA, 1)
          break
        }
      }

      if (!foundSubArgument) {
        screenArguments[index].arguments.push(argument)
      }

      allEquityArgsHaveBeenRemoved = screenArguments[index] != null && screenArguments[index].length === 0

      // Remove the equity parent argument if there are no more child arguments
      if (allEquityArgsHaveBeenRemoved) {
        screenArguments.splice(equityArgIndex, 1)
      }
    }
  }

  if (!foundArgument) {
    const args = {
      arguments: [argument],
      argsOperator: 'OR',
      field: '',
      conditions: []
    }
    screenArguments.push(args)
  }

  return screenArguments
}

ScreenerHelper.addArgument = (field, values, operator) => {
  return {
    field: field,
    conditions: [{
      operator: (operator || 'EqualTo'),
      values: values
    }]
  }
}

ScreenerHelper.buildScreenArguments = (name, value, item, screenerArguments) => {
  // TODO break out into smaller functions
  let newScreenerArguments = screenerArguments
  let foundArgumentsForThisField = false

  for (let argIndex = 0; argIndex < newScreenerArguments.length; argIndex++) {
    let screenArgument = newScreenerArguments[argIndex]

    if (screenArgument.field === name) {
      foundArgumentsForThisField = true
      let conditions = screenArgument.conditions
      if (item.control === 'checkbox') {
        handleCheckboxControl(conditions, argIndex)
      } else {
        foundArgumentsForThisField = false
        newScreenerArguments.splice(argIndex, 1)
      }
    } else if (item.control === 'technicalIndicator' && screenArgument.field.indexOf(item.val) > -1) {
      foundArgumentsForThisField = false
      newScreenerArguments.splice(argIndex, 1)
    }
  }
  // isScreenerArgumentReqd = false when handling different type Screener field with multi-checkbox control.
  // Ex- all checkbox bind with 1 screener field, however, 1 checkbox is bing with other screener field in
  // multi-checkbox
  let isScreenerArgumentReqd = true
  // handling different type Screener field with multi-checkbox control when unchecking checkbox from
  // multi-checkbox control having 1 different screener field
  if ((item.parentVal === 'SPCFFVProductType' || item.useDifferentScreenerField) && !item.isSelected) {
    if (Array.isArray(newScreenerArguments) && newScreenerArguments.length) {
      newScreenerArguments[0].arguments && newScreenerArguments[0].arguments.forEach((x, index) => {
        let isRemoveReqd = false
        // with other type screener field, here separate argument will be set
        if (item.useDifferentScreenerField) {
          isRemoveReqd = (item.val === x.field)
        } else { // with same type screener field - here values are removed from array
          isRemoveReqd = (item.parentVal === x.field)
        }
        if (isRemoveReqd) {
          if (x.field !== 'SPCFFVProductType') {
            x.field = 'SPCFOffshoreFlex'
          }
          handleCheckboxControl(x.conditions, index)
        }
      })
    }
  } else if (!foundArgumentsForThisField && item.isSelected) {
    let operator = 'Like'
    let val = formatValue(name, value, item)
    let arr = []
    if (item.control === 'technicalIndicator') {
      if (val.indexOf('|') > -1) {
        let values = val.split('|')
        let arrValues = []
        values.map(function (x) {
          if (x) {
            let fieldValue = x.split(':')[1] * (item.selectedValue.argumentMultiplier === 'Positive' ? +1 : -1)
            arrValues.push(fieldValue.toString())
            operator = x.split(':')[0]
          }
        })
        arr = arrValues
      } else if (val.indexOf(':') > -1) {
        operator = val.split(':')[0]
        arr.push(val.split(':')[1])
      } else {
        arr.push(val)
      }
    } else {
      if (Array.isArray(val)) {
        arr.push(...val)
      } else {
        arr.push(val)
      }
      // handling different type Screener field with multi-checkbox control when unchecking checkbox from
      // multi-checkbox control having 1 different screener field
      if (item.control === 'checkbox' && item.parentVal === 'SPCFFVProductType') {
        let tempArr = []
        if (item.useDifferentScreenerField) {
          name = item.useDifferentScreenerField
          tempArr.push(item.useDifferentValue)
        } else {
          tempArr = arr
        }
        let baseArgument = {
          field: name,
          valueOperator: 'OR',
          conditions: []
        }

        baseArgument.conditions = [
          {
            operator: 'Like',
            values: tempArr
          }
        ]
        let temp = ScreenerHelper.addRemoveEquityScreenArgument([], baseArgument)
        let found = false // used for same screener field which is to be added in same array
        if (newScreenerArguments.length) {
          newScreenerArguments[0].arguments.forEach((item, index) => {
            if (item.field === 'SPCFFVProductType' && baseArgument.field === 'SPCFFVProductType') {
              handleCheckboxControl(item.conditions, index)
              found = true
            }
          })
          // when both same screener and other screener field to be added to arguments
          if (!found) {
            newScreenerArguments[0].arguments.push(temp[0].arguments[0])
          }
        } else { // when only other screener field to be added to arguments
          newScreenerArguments.push(temp[0])
        }
        isScreenerArgumentReqd = false
      }
    }

    if (isScreenerArgumentReqd) {
      let newArgument =
      {
        // arguments: [
        // {}
        // ],
        // argsOperator: 'AND',
        // valueOperator: 'OR',
        field: name,
        conditions: [
          {
            operator: operator,
            values: arr
          }
        ]
      }
      newScreenerArguments.push(newArgument)
    }
  }

  function hasSubArray (master, sub) {
    const indexOfMatch = ('|' + master.join('|') + '|').indexOf('|' + sub.join('|') + '|')
    if (indexOfMatch > -1) {
      return ('|' + master.join('|') + '|').substring(0, indexOfMatch).split('|').length - 1
    } else return -1
  }

  function getIndexOfFormattedVal (name, valuesForThisField, formattedVal) {
    if (name === 'SPCFFVProductType') {
      return hasSubArray(valuesForThisField, formattedVal)
    } else if (Array.isArray(formattedVal)) {
      return valuesForThisField.indexOf(formattedVal[0])
    } else {
      return valuesForThisField.indexOf(formattedVal)
    }
  }

  function handleCheckboxControl (conditions, argIndex) {
    if (conditions != null && conditions.length && conditions[0].values != null && conditions[0].values.length) {
      let valuesForThisField = conditions[0].values
      let formattedVal = formatValue(name, value, item)
      // Converted formatted value to array if it is not already an array to handle the case where the value is a string
      // for eg. in case of fundType we have FVETF, FVMF - ['R', 'I', 'FVETF'] and need to push All MF - ['R', 'I', 'MMF', 'MF'] then need to check and push only MF and MMF
      // Iterate on each element in the formatted value array and check if it is in the values for this field is already added

      let indexOfFormattedVal = getIndexOfFormattedVal(name, valuesForThisField, formattedVal)
      if (indexOfFormattedVal === -1 && item.isSelected) {
        // To handle different type Screener field with multi-checkbox control
        if (name === 'SPCFFVProductType') {
          if (Array.isArray(formattedVal) && formattedVal.length > 0) {
            newScreenerArguments[0].arguments[argIndex].conditions[0].values.push(...formattedVal)
          } else {
            newScreenerArguments[0].arguments[argIndex].conditions[0].values.push(formattedVal)
          }
        } else {
          if (Array.isArray(formattedVal) && formattedVal.length > 0) {
            newScreenerArguments[argIndex].conditions[0].values.push(...formattedVal)
          } else {
            newScreenerArguments[argIndex].conditions[0].values.push(formattedVal)
          }
        }
      } else if (!item.isSelected && newScreenerArguments[argIndex] && name !== 'SPCFFVProductType') {
        if (Array.isArray(formattedVal) && formattedVal.length > 0) {
          newScreenerArguments[argIndex].conditions[0].values.splice(indexOfFormattedVal, formattedVal.length) // Remove only checked value from the array if it is already present
        } else if (name === 'PERAIProductFeeIndicator') {
          newScreenerArguments[argIndex].conditions[0].values = newScreenerArguments[argIndex].conditions[0].values.filter(val => !formattedVal.includes(val))
        } else {
          newScreenerArguments[argIndex].conditions[0].values.splice(indexOfFormattedVal, 1)
        }

        // To handle different type Screener field with multi-checkbox control
      } else if (!item.isSelected && newScreenerArguments[0].arguments[argIndex] && name === 'SPCFFVProductType') {
        if (Array.isArray(formattedVal) && formattedVal.length > 0) {
          newScreenerArguments[0].arguments[argIndex].conditions[0].values.splice(indexOfFormattedVal, formattedVal.length) // Remove only checked value from the array if it is already present
        }
      }
      if (name === 'SPCFFVProductType') {
        if (newScreenerArguments[0].arguments[argIndex].conditions[0].values.length === 0) {
          newScreenerArguments[0].arguments.splice(argIndex, 1)
        }
      } else if (newScreenerArguments[argIndex] && newScreenerArguments[argIndex].conditions[0].values.length === 0) {
        newScreenerArguments.splice(argIndex, 1)
      }
    }
  }
}

function formatValue (name, value, item) {
  if (item && item.control === 'technicalIndicator') {
    return item.selectedValue.argumentsValue
  }
  switch (name) {
    case 'PERMInceptionMsDate':
    case 'SPCFInceptionDate':
      return moment().subtract(value, 'years').toOADate()
    case 'SPCFMStarOverrideLoadType':
      return value === 'Front Load' ? ['Front Load', 'L']
        : value === 'Back Load' ? ['Back Load', 'B']
          : value === 'No Load' ? ['No Load', 'N'] : [value]
    case 'PERMAverageMarketCap':
    case 'SPCFAverageMarketCap':
      return MagnitudeToNumber(value, true)
    case 'PERMFundNetAssets':
    case 'SPCFFundNetAssets':
    case 'PERMMStarOverrideMinimumInitialInvestment':
      return MagnitudeToNumber(value, false)
    case 'PERAIAnticipatedOfferingCloseMsDate':
    case 'PERAIInitialOfferingMsDate':
    case 'PERMLastUpdatedMsDate':
      let dt = moment(value, 'MM/DD/YYYY').toOADate()
      if (isNaN(dt)) {
        return moment(new Date(value), 'MM/DD/YYYY').toOADate()
      } else {
        return moment(value, 'MM/DD/YYYY').toOADate()
      }
    case 'PERMFundofFunds':
    case 'PERMMStarOverrideClosedToNewInvestors':
    case 'PERMClosedToNewInvestors':
    case 'SPCF529Fund':
    case 'SPCFLeveragedFundFlag':
    case 'SPCFInverseFundFlag':
    case 'SPCFHasOptionsFlag':
    case 'SPCFIsFVETF':
    case 'SPCFNew52WeekHigh':
    case 'PEREQWSODADRIssue':
    case 'PEREQHasOptionsFlag':
    case 'SPCFNew52WeekLow':
      return value === 'Yes' ? '1' : '0'
    case 'SPCFIsIndexFund':
      return value === 'Passively Managed' ? '1' : '0'
    case 'SPCFMStarOverrideClosedToNewInvestors':
      return value === 'Yes' ? ['1', 'Y'] : ['0', 'N']
    case 'SPCFDealerAgreementRequiredForLoadTrades':
    case 'SPCFDealerAgreementRequiredForLoadTransfers':
    case 'SPCFDealerAgreementRequiredForNo_LoadTrades':
    case 'SPCFDealerAgreementRequiredForNo_LoadTransfers':
    case 'SPCFFundservIndicator':
    case 'SPCFClosedToSellsIndicator':
    case 'SPCFTenderIndicator':
    case 'SPCFSURCHARGE':
    case 'SPCFIndicator12b1':
    case 'PERAISecurityHeldIndicator':
      return value === 'Yes' ? 'Y' : 'N'
    case 'PERAIDepositoryID':
      return value === 'Retirement & Non-Retirement' ? 'CUSTDTC' : ''
    case 'PERAISecRegistered':
      return value === 'Yes' ? ['Y'] : ['N', 'NULL']
    case 'SPCFBNYMellon':
      return value === 'No' ? ['Y'] : ['N']
    case 'SPCFNoTransactonFeeEligibilityIndicator':
      return Object.keys(screenerIndicatorDictionary).find(key => screenerIndicatorDictionary[key] === value)
    case 'SPCFMStarOverrideDividendFrequency':
      let keyValue = Object.keys(screenerDivFreqDictionary).find(key => screenerDivFreqDictionary[key] === value)
      return keyValue ? [value, Object.keys(screenerDivFreqDictionary).find(key => screenerDivFreqDictionary[key] === value)] : [value]
    case 'PEREQExchange':
      if (value.split(',').length > 1) {
        return value.split(',')
      }
      return value
    case 'SPCFFVProductType':
      switch (value) {
        // All arrays should return unique combinations of values
        case 'FundVestMutualFunds':
          return ['R', 'I']
        case 'AllETF':
          return ['ETF', 'FVETF']
        case 'AllMutualFunds':
          return ['MMF', 'R', 'MF', 'I']
        case 'FVETF':
          return ['FVETF', '999'] // hack added random number to make array unique, FVETF is in AllETF as well
        default:
          return [value]
      }
    case 'PERAIMinorProductCode':
      return value === '040' ? ['040', '041'] : [value]
  }
  return value
}

ScreenerHelper.updateColumnUIMapping = (columnName, value) => {
  switch (columnName) {
    case 'SPCFMStarOverrideLoadType':
      return value === 'Front Load' || value === 'L' ? 'Front-End'
        : value === 'Back Load' || value === 'B' ? 'Back-End'
          : value === 'D' ? 'Dual'
            : value === 'No Load' || value === 'N' ? 'No Load' : value
    case 'SPCFOffShoreProcessingIndicator':
      return value === 'N' ? 'NSCC'
        : value === 'D' ? 'Direct to Fund'
          : value === 'F' ? 'FundSettle'
            : value === 'A' ? 'Direct To Fund and FundSettle' : value
    case 'SPCFOffShoreIndicator':
      return value === 'Y' ? 'Offshore'
        : value === 'N' ? 'Domestic' : value
  }
}

function MagnitudeToNumber (val, adjust) { // changes for PRW-1011
  if (/[a-zA-Z]/.test(val)) {
    let multiplier = 1
    let number = val.replace(/[^0-9]/g, '')
    let magnitude = val.replace(/[\d., ]/g, '')
    multiplier = magnitude !== '' ? (adjust ? dict[magnitude] / 1000000 : dict[magnitude]) : multiplier
    return (number) * multiplier
  }
  return val
}

const dict =
{ 'K': 1000,
  'M': 1000000,
  'B': 1000000000,
  'T': 1000000000000
}

ScreenerHelper.buildPreScreenModalInputs = (screenId, screenerArguments) => {
  const preScreenInputs = DEFAULT_PRE_SCREEN_INPUTS
  preScreenInputs.limit = 10000
  preScreenInputs.arguments = screenerArguments || []
  let sortField = MODEL_SCREEN_TYPE
  switch (screenId) {
    case '449':
      sortField = 'PERMPrgramPrductMgrCodeStrtegy'
      break
    case '450':
      sortField = 'SPCFticker'
      break
    default:
      sortField = 'PERAICusip'
      break
  }
  preScreenInputs.sortArguments = [{
    direction: 'A',
    field: sortField
  }]
  preScreenInputs.resultFields = ModelPreScreenInput
  return {
    method: 'POST',
    postData: preScreenInputs
  }
}

export default ScreenerHelper
