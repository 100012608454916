import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import styles from './SubMenu.module.module.scss'
import SubMenuListItem from './SubMenuListItem'
import {deviceType, getDeviceType} from '../../../../../../../utils/utilities'
const SubMenu = ({data, selectedItems, handleMouseLeave, handleClick, reset, deviceTypeItem}) => {
  let [dataItem, setDataItem] = useState(data)
  let [customStyles, setCustomStyles] = useState('')
  let [customWidth, setCustomWidth] = useState({})

  useEffect(() => {
    switch (deviceTypeItem) {
      case deviceType.Desktop:
        setCustomWidthForFilters(deviceType.Desktop)
        break
      case deviceType.Mobile:
      case deviceType.Ipad:
        setCustomWidthForFilters('')
        break
    }
  }, [deviceTypeItem])

  function setCustomWidthForFilters (type) {
    if (type === deviceType.Desktop) {
      if (data.items && data.items.find(f => f.level === 'L3')) {
        setCustomWidth({'width': '100%'}) // PRW-960
      } else {
        setCustomWidth({'width': '100%'}) // PRW-960
      }
    } else {
      if (data.items && data.items.find(f => f.level === 'L3')) {
        // setCustomWidth({'width': 'calc(100vw - 82px)'})
      } else {
        setCustomWidth({})
      }
    }
  }
  useEffect(() => {
    setDataItem(data)
    setCustomWidthForFilters(getDeviceType())
    if (data.items.length <= 5 && data.items.find(f => f.control === 'checkbox')) handleStyleChange()
    else setCustomStyles(`${styles.sxSubDropdownList}`)
  }, [data.items])

  const handleStyleChange = () => {
    setCustomStyles(`${styles.sxSubDropdownList}`)
    setCustomWidthForFilters(getDeviceType())
  }

  return (<ul role='presentation' className={customStyles} onMouseLeave={handleMouseLeave} style={customWidth}>
    {
      dataItem.items.map((item, index) => {
        const isSelected = item.isSelected
        const {selectedParent} = data
        const itemProps = {item, isSelected, handleClick, selectedParent, reset, index}
        return (<SubMenuListItem id={item.parentVal} key={index} {...itemProps} onSubMenuStyleChange={handleStyleChange} />)
      })
    }
  </ul>
  )
}
SubMenu.propTypes = {
  data: PropTypes.shape({
    selectedParent: PropTypes.object,
    items: PropTypes.array
  }),
  selectedItems: PropTypes.array,
  handleMouseLeave: PropTypes.func,
  handleClick: PropTypes.func,
  reset: PropTypes.func,
  deviceTypeItem: PropTypes.string
}
export default SubMenu
