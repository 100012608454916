import { TYPE_OF_VALUE } from '../../../../utils/appConstants'

export const TOP_INSTITUTIONAL_HOLDER_CONSTANTS = {
  HEADERS_TABLE: [{
    label: 'Holder <br><br>',
    map: 'name',
    defaultValue: 'N/A',
    isLink: true,
    isFixed: true
  },
  {
    label: '% of Shares <br> Outstanding',
    map: 'netPercentOfSharesOut',
    type: TYPE_OF_VALUE.PERCENTAGE,
    hasSeparator: true
  },
  {
    label: 'Shares Held <br><br>',
    map: 'numberOfShares',
    type: TYPE_OF_VALUE.NUMBER,
    hasSeparator: true
  },
  {
    label: 'Changes in <br> Shares Held',
    map: 'shareChange',
    type: TYPE_OF_VALUE.NUMBER,
    isNegative: true,
    hasSeparator: true
  },
  {
    label: 'Total Value <br><br>',
    map: 'value',
    type: TYPE_OF_VALUE.NUMBER,
    hasSeparator: true
  }
  ],
  LIMIT_RECORDS: 10,
  PRECISION: 2,
  HEADER: 'Top Institutional Holders'
}

export const TOP_INSTITUTIONAL_HOLDER_TEST_LABEL = {
  HEADERS_TABLE: [{
    label: 'Holder'
  },
  {
    label: '% of Shares Outstanding'
  },
  {
    label: 'Shares Held'
  },
  {
    label: 'Changes in Shares Held'
  },
  {
    label: 'Total Value'
  }
  ]
}
