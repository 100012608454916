import React from 'react'
import PerformanceChart from '../../../QuoteCard/PerformanceChart/PerformanceChart'
import Tooltip from '../../Overlay/ToolTip'
import StockHighlightsItem from '../../../StockHighlights/StockHighlightsItem/StockHighlightsItem'
import styles from './FundQuoteCardWithStockHiglights.module.scss'
import PropTypes from 'prop-types'
import {ModuleName, PRICECOLOR} from '../../../../../../../utils/appConstants'
import { isSnapshot } from '../../../utils/utilities'
import {fundVestTag} from '../../../Helpers/CommonFunctions'
import { emitF2Event } from '../../../../../../../utils/f2Methods'
import { EVENT_NAMES } from '../../../../../../../utils/f2Constants'
import { MOD_GOTO_PAGES, PROFILE } from '../../../utils/appConstants'

const FundQuoteCard = (props) => {
  const fundType = fundVestTag(props.screenerData)
  const screenerAsOfDate = props.screenerData && props.screenerData.SPCFPerformanceAsOfDate
  const screenerAsOfDateStyle = {'height': 'auto'}

  let positive = { color: PRICECOLOR.GREEN }
  let negative = { color: PRICECOLOR.RED }
  let spinnerStyle = {
    height: props.moduleTypeName === 'FundScreener' ? '60.34px' : '55px',
    width: '220px'
  }
  const AddSign = (value) => {
    if (value >= 0 && value !== null) {
      return `+ ${value}`
    } else return value
  }

  function overlayKeyHandler (venueXid) {
    props.overlayKeyHandler(venueXid)
  }
  function getNetAssets () {
    if (props.screenerData) { return props.screenerData.netAssets }
    return null
  }
  function viewProfile () {
    let dataEmit = {
      gotoPage: MOD_GOTO_PAGES.fund,
      symbol: props.symbol || props.screenerData.symbol,
      activeTabKey: PROFILE
    }
    emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, dataEmit)
  }
  return (
    <React.Fragment>
      <div className={styles.quoteCardHeader}>
        <div className={styles.quoteCardHeaderMain} id='fundSymbol' aria-label={`Symbol: ${props.symbol}`} role='button' tabIndex='0' onClick={viewProfile} onKeyDown={viewProfile}>
          {props.symbol || props.screenerData.symbol}
        </div>
        <div title={props.name || props.screenerData.name} className={styles.quoteCardHeaderDesc}>
          {props.name || props.screenerData.name}
        </div>
        {!props.standAlone && <div className={styles.toolTip}>
          <Tooltip id={props.id} type={props.type} data={{...props}} IsFVETF={fundType} symbol={props.symbol} venueXid={props.venueXid} xrefData={props.xrefData} viewShareClassModalHandler={props.viewShareClassModalHandler} moduleTypeName={props.moduleTypeName} overlayKeyHandler={(venueXid) => overlayKeyHandler(venueXid)} showAddToWatchlist={props.showAddToWatchlist} />
        </div>}
      </div>
      <div className={styles.dvTags}>
        {!fundType ? <div className={styles.noTag} />
          : <div id={'buttonFund' + props.id} name='ETF' className={styles.ETF} aria-label={`Type: ${fundType}`} role='button'>{fundType}</div>}
        {!(props.screenerData.SPCFOffshoreFlex === 'Yes') ? <div className={styles.noTag} />
          : <div id={'buttonFund' + props.id} name='Offshore Flex' className={styles.ETF} aria-label={'Offshore Flex'} role='button'>{'Offshore Flex'}</div>}
      </div>
      <div className={styles.quoteCardModeChart} style={props.moduleTypeName !== 'FundScreener' && !isSnapshot(props.moduleTypeName) && document.documentElement.scrollTop > 0 ? {display: 'none'} : {display: ''}} name='hideScroll'>
        <PerformanceChart id={props.id} symbol={parseInt(props.venueXid)} days={props.days} spinnerStyle={spinnerStyle} />
      </div>
      <div className={`${styles.quoteCardChart} ${props.moduleTypeName !== ModuleName.ETF_RESEARCH_SNAPSHOT ? styles.stockHighlightsAlign : ''}`} style={props.moduleTypeName !== 'FundScreener' && !isSnapshot(props.moduleTypeName) && document.documentElement.scrollTop > 0 ? {display: 'none'} : {display: ''}} name='hideScroll'>
        <div className={styles.quoteCardChartMain} aria-label={`Price: ${props.quoteData.price}`}>
          {props.quoteData.price}
        </div>
        <div className={` ${styles.quoteCardChartDesc} ${props.quoteData.priceChange < 0 ? styles.red : styles.green} `} role='contentinfo' aria-label={`Price Change: ${props.quoteData.priceChange}, Price Change %: ${props.quoteData.priceChangePct}`}>
          {AddSign(props.quoteData.priceChange)} (
          {(props.quoteData.priceChangePct)}%)
        </div>
      </div>
      <div className={styles.stockHighlightContent}>
        <div className={styles.stockHighlightIPadContainer}>
          <div className={`${styles.stockHighlightIPadDivLeft}`} style={props.moduleTypeName !== 'FundScreener' && !isSnapshot(props.moduleTypeName) && document.documentElement.scrollTop > 0 ? {display: 'none'} : {display: ''}} name='hideScroll'>
            <StockHighlightsItem label={'YTD Change'} type={props.type} value={props.quoteData.ytdChange && props.quoteData.ytdChange !== '--' ? props.quoteData.ytdChange + '%' : '--'} valueStyling={props.quoteData.ytdChange > 0 ? positive : (props.quoteData.ytdChange < 0) ? negative : {}} />
            <StockHighlightsItem label={'NAV Return (5Yr – Ann)'} keyStyling={screenerAsOfDate && screenerAsOfDateStyle} asofDate={screenerAsOfDate && `As of ${screenerAsOfDate}`} type={props.type} value={props.screenerData.y5Change && props.screenerData.y5Change !== '--' ? props.screenerData.y5Change + '%' : '--'} valueStyling={props.screenerData.y5Change > 0 ? positive : (props.screenerData.y5Change < 0) ? negative : {}} />
            {isSnapshot(props.moduleTypeName) && props.moduleTypeName === ModuleName.ETF_RESEARCH_SNAPSHOT && <StockHighlightsItem label={'Volume'} type={props.type} value={props.quoteData.volume} />}
            <StockHighlightsItem label={'52 Wk Range'} type={props.type} value={props.quoteData._52WkRange} />
            <StockHighlightsItem label={'Net Assets'} type={props.type} value={getNetAssets() || '--'} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default FundQuoteCard

FundQuoteCard.propTypes = {
  quoteData: PropTypes.object,
  venueXid: PropTypes.number,
  days: PropTypes.number,
  type: PropTypes.string,
  moduleTypeName: PropTypes.string,
  viewShareClassModalHandler: PropTypes.func,
  symbol: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  xrefData: PropTypes.object,
  screenerData: PropTypes.object,
  overlayKeyHandler: PropTypes.func,
  standAlone: PropTypes.bool,
  showAddToWatchlist: PropTypes.func
}
