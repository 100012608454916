import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './AUMControversies.module.scss'
import { formatNumber } from '../../../../../../utils/formatter'
import ValueBar from '../MarketCap/ValueBar/ValueBar'
import ValueTable from '../MarketCap/ValueTable/ValueTable'
import { deviceType, getDeviceType, handleWindowResize } from '../../utils/utilities'
import StockHighlightsItem from '../../../../../Stocks/StockHighlights/StockHighlightsItem/StockHighlightsItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TableHeader from './TableHeader'
import Nodata from '../../../../../Lib/NoData/NoData'
import Loader from '../../../../../Lib/common/Loader'

export default function AUMControversies ({xid, isNXI=false, analystRatingsData={}}) {
  const [aumControversiesBarData, setAumControversiesBarData] = useState([])
  const [aumControversiesTableData, setAumControversiesTableData] = useState([])
  const [isReady, setIsReady] = useState(false || !xid)

  const [device, setDevice] = useState(getDeviceType())
  useEffect(() => {
    handleWindowResize(resizeHandler)
  }, [device])

  useEffect(() => {
    analystRatingsData && Object.keys(analystRatingsData).length > 0 && getData()
  }, [analystRatingsData])

  function resizeHandler (device) {
    setDevice(device)
  }

  const getData = () => {
      setIsReady(false)
      if (!analystRatingsData.isError) {
        let data = analystRatingsData?.data?.fund?.controversy?.percentAUMBreakdown
        if (data) {
          let barData = []
          let noneModerate = formatNumber(data.noToSignificantControversies && data.noToSignificantControversies)
          let significantSevere = formatNumber(data.highServereControversies && data.highServereControversies)
          if (!(isNaN(noneModerate) || isNaN(significantSevere))) {
            barData.push(noneModerate)
            barData.push(significantSevere)
          }
          setAumControversiesBarData([...barData])
          let tableData = []
          tableData.push({
            name: 'None',
            value: data.noControversies && formatNumber(data.noControversies),
            color: '#004480',
            controversyRating: 'Moderate'
          })
          tableData.push({
            name: 'Low',
            value: data.lowControversies && formatNumber(data.lowControversies),
            color: '#004480',
            controversyRating: 'Moderate'
          })
          tableData.push({
            name: 'Moderate',
            value: data.moderateControversies && formatNumber(data.moderateControversies),
            color: '#004480',
            controversyRating: 'Moderate'
          })
          tableData.push({
            name: 'Significant',
            value: data.signficiantControversies && formatNumber(data.signficiantControversies),
            color: '#004480',
            controversyRating: 'Severe'
          })
          tableData.push({
            name: 'High',
            value: data.highControversies && formatNumber(data.highControversies),
            color: isNXI ? '#58595A': '#0095FF',
            controversyRating: 'Severe'
          })
          tableData.push({
            name: 'Severe',
            value: data.servereControversies && formatNumber(data.servereControversies),
            color: isNXI ? '#58595A': '#0095FF',
            controversyRating: 'Severe'
          })
          setAumControversiesTableData([...tableData])
        }
      } else {
        setAumControversiesBarData([])
        setAumControversiesTableData([])
      }
      setIsReady(true)
  }

  function IpadDataHtmlBody () {
    return <React.Fragment>
      <Loader ready={isReady} spinnerSize='2x' >
        <div className={styles.tableContainer}>
          <div className={styles.ipadTable}>
            <div className={styles.tableLeftDiv}>
              <TableHeader leftLabel={'Controversy Rating'} rightLabel={'AUM (%)'} />
              {
                (aumControversiesTableData.length > 0 && !aumControversiesTableData.every(item => typeof item.value === 'undefined'))
                  ? aumControversiesTableData.map((item, idx) => {
                    return item.controversyRating === 'Moderate' && <div className={`${styles.row} ${idx < 2 ? styles.divider : ''} `}>
                      {isNXI?(<div className={styles.circle} style={{ background: item.color }} />):<FontAwesomeIcon icon={['fas', 'circle']} color={`${item.color}`} className={styles.icon} />}
                      <StockHighlightsItem label={item.name} value={item.value} />
                    </div>
                  }) : <Nodata />
              }
            </div>
            <div className={styles.tableRightDiv}>
              <TableHeader leftLabel={'Controversy Rating'} rightLabel={'AUM (%)'} />
              {
                (aumControversiesTableData.length > 0 && !aumControversiesTableData.every(item => typeof item.value === 'undefined'))
                  ? aumControversiesTableData.map((item, idx) => {
                    return item.controversyRating === 'Severe' && <div className={`${styles.row} ${idx < aumControversiesTableData.length - 1 ? styles.divider : ''} `}>
                      {isNXI?(<div className={styles.circle} style={{ background: item.color }} />):<FontAwesomeIcon icon={['fas', 'circle']} color={`${item.color}`} className={styles.icon} />}
                      <StockHighlightsItem label={item.name} value={item.value} />
                    </div>
                  }) : <Nodata />
              }
            </div>
          </div>
        </div>
      </Loader>
    </React.Fragment>
  }

  return (
    <Loader ready={isReady} spinnerSize='2x' >
      <div data-testid='AUMControversiesContainer' className={isNXI? styles.NXIControversiesContainer:styles.controversiesContainer}>
        {isNXI
        ? <React.Fragment><div className={styles.header}>AUM Controversies</div>
        {aumControversiesBarData.length > 0 && (
          <div className={styles.bar}>
            <ValueBar data={aumControversiesBarData} height={16} isNXI={isNXI} />
          </div>
        )}
        {device === deviceType.Ipad ? (
          IpadDataHtmlBody()
        ) : (
          <div className={styles.table}>
            <TableHeader
              leftLabel={"Controversy Rating"}
              rightLabel={"AUM (%)"}
              headerStyle
            />
            {aumControversiesTableData.length > 0 &&
            !aumControversiesTableData.every(
              (item) => typeof item.value === "undefined"
            ) ? (
              <ValueTable
                data={aumControversiesTableData}
                separator
                customMark
                isNXI={isNXI}
              />
            ) : (
              <Nodata />
            )}
          </div>
        )}</React.Fragment>

        : <React.Fragment> <span className={styles.header}>AUM Controversies
          <span className={`${styles.popup}`} id='headerPopover'>
            The AUM Distribution of holdings by controversy level
          </span>
        </span>
        {
          aumControversiesBarData.length > 0 && <div className={styles.bar}>
            <ValueBar data={aumControversiesBarData} height={16} />
          </div>
        }
        {device === deviceType.Ipad ? IpadDataHtmlBody()
          : <div className={styles.table}>
            <TableHeader leftLabel={'Controversy Rating'} rightLabel={'AUM (%)'} />
            {(aumControversiesTableData.length > 0 && !aumControversiesTableData.every(item => typeof item.value === 'undefined')) ? <ValueTable data={aumControversiesTableData} separator customMark /> : <Nodata />}
          </div>}
          </React.Fragment>}
      </div>
    </Loader>
  )
}

AUMControversies.propTypes = {
  xid: PropTypes.number.isRequired,
  isNXI: PropTypes.bool,
  analystRatingsData: PropTypes.object
}
