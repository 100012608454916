/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'

import F2AppContainer from './F2AppContainer'
import { TEST_CENTER_F2_APPS } from '../../utils/f2Constants'
import styles from './F2AppsMarketPlace.module.module.scss'

// import '../../styles/css-modules/bootstrap.css'
// import '../../styles/css-modules/bootstrap-overrides.css'
import { removeAllApps } from '../../utils/f2Methods'
import DataRequest from '../../services/DataRequest'
import { deviceType, getDeviceType } from '../../utils/utilities'

class F2AppsMarketPlace extends React.Component {
  constructor (props) {
    super(props)

    let tempApps = {}
    Object.keys(TEST_CENTER_F2_APPS).map((category) => {
      tempApps[category] = {}
      let categoryApps = TEST_CENTER_F2_APPS[category]
      let filteredCatApps = {}
      Object.keys(categoryApps).map((appId) => {
        if (categoryApps[appId].availableInEnvironments.includes(window.MD.ENV)) {
          filteredCatApps[appId] = categoryApps[appId]
        }
      })
      tempApps[category] = filteredCatApps
    })
    this.state = {
      showAppsList: false,
      apps: tempApps,
      selectedApps: {},
      containerWidth: '1024px'
    }
  }

  handleAppRemoval = event => {
    let appId = event.target.getAttribute('data-appid')
    delete this.state.selectedApps[appId]

    this.setState({
      selectedApps: this.state.selectedApps
    })
  }

  showHideAppsList = event => {
    this.setState({ showAppsList: !this.state.showAppsList })
  }

  handleAppSelection = event => {
    let appId = event.currentTarget.getAttribute('data-appid')
    let appCategory = event.currentTarget.getAttribute('data-app-category')

    let appConfig = {}
    let categoryApps = this.state.apps[appCategory]

    for (let index = 0; index < Object.keys(categoryApps).length; index++) {
      const app = Object.keys(categoryApps)[index]
      if (app === appId) {
        appConfig = {
          appId: appId,
          context: categoryApps[app].context
        }
        break
      }
    }

    let tempSelectedApps = this.state.selectedApps

    tempSelectedApps[appId] = appConfig

    this.setState({
      selectedApps: tempSelectedApps,
      showAppsList: false
    })
  }

  searchApps = event => {
    let filterValue = event.target.value.toLowerCase()

    document.querySelectorAll('ul > li > ul > li').forEach(function (li) {
      if (li.innerHTML.toLowerCase().indexOf(filterValue) > -1) {
        li.style.display = 'block'
      } else {
        li.style.display = 'none'
      }
    })
  }

  setAppContainerWidth = (e) => {
    this.setState(
      {
        containerWidth: e.target.innerHTML
      }
    )
  }

  updateApps = () => {
    removeAllApps()

    let tempSelectedApps = this.state.selectedApps
    var elConfigs = document.querySelectorAll('[data-app-updated-config]')

    elConfigs.forEach((el) => {
      let appId = el.getAttribute('data-appid')
      let appConfig = JSON.parse(el.value)

      tempSelectedApps[appId] = appConfig
    })

    this.setState({
      selectedApps: tempSelectedApps
    })
  }

  render () {
    return (
      <div data-f2-app-conatiner='MOD_F2_APP_CONTAINER' className={styles.f2TestCenterContainer}>
        <div className={styles['top-header']}>
          Pershing | NetX-Wealth F2 Apps MarketPlace
        </div>
        <div className={styles['apps-config-container']}>

          <div className={styles['select-apps-container']}>
            <div className={styles['header']}>Select Apps</div>

            <div className={styles['select-apps']}>
              <button className={styles['search-apps']} onClick={this.showHideAppsList}>
                Select an App
              </button>
              {
                this.state.showAppsList &&
                <div className={styles['search-box']}>
                  <ul>
                    <li>
                      <input type='text' placeholder='Enter app name to search...' className={styles['txt-search-app']} onChange={this.searchApps} />
                      <hr />
                    </li>
                    {
                      Object.keys(this.state.apps).map((appCategory) => {
                        return <li key={`${appCategory}`}>
                          <div className={styles['app-category']}>{ appCategory }</div>
                          {
                            <ul>
                              {
                                Object.keys(this.state.apps[appCategory]).length > 0 && Object.keys(this.state.apps[appCategory]).map((appId) => {
                                  return <li
                                    key={`${appCategory}-${appId}`}
                                    data-appid={appId}
                                    data-app-category={appCategory}
                                    onClick={this.handleAppSelection}>
                                    <span>
                                      {
                                        getDeviceType() === deviceType.Mobile ? appId.replace('com_pershing_netxwealth_', '') : appId
                                      }
                                    </span>
                                  </li>
                                })
                              }
                            </ul>
                          }
                        </li>
                      })
                    }
                  </ul>
                </div>
              }

              <ul className={styles['selected-apps']}>
                {
                  Object.keys(this.state.selectedApps).map((app) => {
                    return <li key={app}>
                      <span>{app}</span>
                      <button data-appid={app} onClick={this.handleAppRemoval}>remove</button>
                    </li>
                  })
                }
              </ul>
            </div>

          </div>

          <div className={styles['app-configurations-container']}>
            <div className={styles['header']}>
              <div>Apps Configurations</div>
              <button className={styles['btnSelected']} onClick={this.updateApps}>Update</button>
            </div>
            <div className={styles['config-container']}>
              <ul>
                {
                  Object.keys(this.state.selectedApps).map((app) => {
                    return <li key={app}>
                      <div>
                        {app}
                      </div>
                      <textarea data-appid={app} data-app-updated-config={app}>
                        {
                          JSON.stringify(this.state.selectedApps[app], undefined, 4)
                        }
                      </textarea>
                    </li>
                  })
                }
              </ul>
            </div>
          </div>
        </div>

        <div className={styles['appView']}>
          <div className={styles['header']}>
            <div>App View</div>
            <div className={styles['resolutions']}>
              {/* <button onClick={this.setAppContainerWidth} className={this.state.containerWidth === '375px' ? styles['btnSelected'] : styles['btnSetContainerWidth']}>375px</button>
              <button onClick={this.setAppContainerWidth} className={this.state.containerWidth === '596px' ? styles['btnSelected'] : styles['btnSetContainerWidth']}>596px</button>
              <button onClick={this.setAppContainerWidth} className={this.state.containerWidth === '1024px' ? styles['btnSelected'] : styles['btnSetContainerWidth']}>1024px</button> */}
            </div>
          </div>
          <div id='appOuterContainer' className={styles['appOuterContainer']}>
            {
              Object.keys(this.state.selectedApps).length > 0 &&
                Object.keys(this.state.selectedApps).map((appId, idx) => {
                  return <div key={`${appId} - ${idx}`} style={{'border': '1px dashed #00485e'}}>
                    <div style={{'padding': '5px', 'background': '#00485e', 'color': '#fff', 'display': 'flex', 'justifyContent': 'space-between'}}>
                      <div>
                        {appId}
                      </div>
                      <div>
                        <a target='_blank' style={{color: 'white'}} href={`${location.origin + '/view-f2-app.html?appConfig=' +
                         encodeURIComponent((JSON.stringify(this.state.selectedApps[appId])) + '&access_token=' + DataRequest.getAccessToken())}`}>View</a>
                      </div>
                    </div>
                    <div style={{width: this.state.containerWidth}} id={appId} className={styles['appContainer']}>
                      <F2AppContainer
                        key={appId}
                        appId={appId}
                        context={this.state.selectedApps[appId].context}
                        rootId={appId}
                      />
                    </div>
                  </div>
                })
            }

          </div>
        </div>
      </div>
    )
  }
}

export { F2AppsMarketPlace as default }
