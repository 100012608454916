export const COST_COMPARE_CONSTANTS = {
  COST_COMPARE_SECTION: [{
    key: 'Initial Investment',
    value: [{
      type: 'Inputbox',
      placeholder: '$0.00',
      stateName: 'initial_investment',
      defaultValue: 10000
    }]
  },
  {
    key: 'Reoccuring Investments',
    value: [{
      type: 'Inputbox',
      placeholder: '$0.00',
      stateName: 'reoccuring_investment'
    },
    {
      type: 'dropdown',
      values: ['Monthly', 'Quarterly', 'Annually'],
      stateName: 'reoccuring_investment_dropdown',
      defaultValue: 'Monthly'
    }
    ],
    noDividerNeededIpad: true
  }, {
    key: 'Broker Commission',
    value: [
      {
        type: 'inputbox',
        placeholder: '$0.00',
        stateName: 'broker_commision'
      }],
    noDividerNeeded: true,
    mergeNextInMobile: true
  },
  {
    key: 'Annual Return',
    value: [
      {
        type: 'dropdown',
        values: ['3.0%', '5.0%', '10.0%', '15.0%'],
        stateName: 'annual_return',
        defaultValue: '3.0%'

      }],
    noDividerNeeded: true
  },
  {
    key: 'Time Horizon',
    value: [
      {
        type: 'dropdown',
        values: ['1 Year', '3 Years', '5 Years', '10 Years'],
        stateName: 'time_horizon',
        defaultValue: '1 Year'
      }]
  }
  ],
  COST_COMPARE_LINES_IPAD: 2,
  IMPORTANT_DISCLOUSERS_TEXT: 'Important Disclousers'
}
