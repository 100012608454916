import DataRequest from './DataRequest'
import { URLS } from '../utils/appConstants'

export const makeEntitlementsCall = (callbackHandler) => {
  DataRequest.execute(
    `${URLS.CUSTOM.PRODUCTACCESS}/`,
    {

    },
    callback,
    null
  )
  function callback (response) {
    callbackHandler(response)
  }
}
