import React, {useState, useEffect, useRef} from 'react'
import styles from './RangeSelector.module.scss'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import LinkButton from '../LinkButton/LinkButton'

const RangeSelector = props => {
  let [max, setMax] = useState(props.item.max)
  let [min, setMin] = useState(props.item.min)
  let [subMenu, setSubMenu] = useState(props.selectedParent)
  const [minErr, setMinErr] = useState(false)
  const [maxErr, setMaxErr] = useState(false)
  const [showRangeSelector, setShowRangeSelector] = useState(false)
  const [selectedRadioBtn, setSelectedRadioBtn] = useState(props.item.selectedValue)
  const [isApplyDisabled, setIsApplyDisabled] = useState(false)

  let focusElem = useRef()
  // let isScreenerFilterRadio = true

  useEffect(() => {
    setMax(props.item.max)
    setMin(props.item.min)
    setSubMenu(props.selectedParent)
    if (props.selectedParent.selectedItemCount && props.selectedParent.selectedItemCount !== 0) {
      props.item.selectedValue === '' ? setShowRangeSelector(true) : setShowRangeSelector(false)
    }
    setSelectedRadioBtn(props.item.selectedValue)
    focusElem
      .current
      .focus()
  }, [props.max, props.min, props.item])

  const onMinInput = (e) => {
    let val = e.target.value
    if (val === '') {
      setMin(null)
      setMinErr(false)
      setMaxErr(false)
      setIsApplyDisabled(false)
      return
    }

    setMin(Number(val))
    setMinErr(false)
    setMaxErr(false)
    setIsApplyDisabled(false)
  }

  const onMaxInput = (e) => {
    let val = e.target.value
    if (val === '') {
      setMax(null)
      setMinErr(false)
      setMaxErr(false)
      setIsApplyDisabled(false)
      return
    }
    setMax(Number(val))
    setMinErr(false)
    setMaxErr(false)
    setIsApplyDisabled(false)
  }
  const isInputValid = (minValue, maxValue) => {
    if (minValue === '' || maxValue === '' || minValue > maxValue || minValue == null || maxValue == null || (!props.item.negativeAllowed && minValue < 0) || (!props.item.negativeAllowed && maxValue < 0)) {
      return false
    }
    return true
  }

  const isMaxValid = (minValue, maxValue) => {
    let val = minValue === null
      ? props.item.lowest
      : minValue
    if (maxValue === null || maxValue < Number(val) || (!props.item.negativeAllowed && maxValue < 0)) {
      return false
    }
    return true
  }
  const isMinValid = (minValue, maxValue) => {
    let val = maxValue === null
      ? props.item.highest
      : maxValue
    if (minValue === null || (minValue > Number(val)) || (!props.item.negativeAllowed && minValue < 0)) {
      return false
    }
    return true
  }
  const handleMinValidation = (minValue) => {
    let value = Number(minValue)
    if (!isMinValid(value, max)) {
      setMinErr(true)
      setIsApplyDisabled(true)
    } else {
      setMinErr(false)
    }

    setMin(value)
    if (isMaxValid(value, max)) {
      setMaxErr(false)
      setMax(Number(max))
    }
  }
  const handleMaxValidation = (maxValue) => {
    let value = Number(maxValue)

    if (!isMaxValid(min, value)) {
      setMaxErr(true)
      setIsApplyDisabled(true)
    } else {
      setMaxErr(false)
    }

    setMax(value)
    if (isMinValid(min, value)) {
      setMinErr(false)
      setMin(Number(min))
    }
  }
  const keyPressHandler = (e) => {
    if (e.shiftKey && e.keyCode === 9) {
      e.preventDefault()
      let lielem = document.getElementById(`li-${props.selectedParent.parentVal}-${props.item.parentVal}`)
      lielem
        ? lielem.focus()
        : document
          .getElementById(`li-${props.item.parentVal}-${props.item.val}`)
          .focus()
    }
    if (e.keyCode === KEYCODE.DOWN) {
      e.preventDefault()
      let el = e.currentTarget.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild.firstElementChild
      el.focus()
    }
  }

  const onApply = () => {
    if (!isApplyDisabled) {
      if (min === null) {
        setMin(props.item.lowest)
        min = props.item.lowest
      }
      if (max === null) {
        setMax(props.item.highest)
        max = props.item.highest
      }
      handleMinValidation(min)
      handleMaxValidation(max)
      updateParent(min, max)
      setSelectedRadioBtn([])
    }
  }
  const updateParent = (minValue, maxValue) => {
    props.onChange({
      max: maxValue,
      min: minValue,
      isValid: isInputValid(minValue, maxValue)
    }, props.item.selectedValue = '')
  }
  let onClickHandler = (handleChangeStyle) => {
    setShowRangeSelector(!showRangeSelector)
    if (handleChangeStyle) { handleChangeStyle() }
  }
  let handleOnButtonClicked = (e, element, item, selectedParent, handleClick) => {
    item.isSelected = true
    // If item selectedValue was from Manual entry then item.selectedValue returns string
    if (!item.selectedValue) {
      item.selectedValue = []
    }
    item.selectedValue = item.selectedValue.filter(x => x !== element)
    if (e.target.checked) {
      item.selectedValue.push(element)
    }
    if (!e.target.checked && !item.selectedValue.length) {
      item.isSelected = false
    }
    item.val = `${''}${element}`
    setMax('')
    setMin('')
    setSelectedRadioBtn(item.selectedValue)
    handleClick(e, item, selectedParent)
  }

  return <div
    className={styles.rangeSelector}
    style={{paddingTop: '0px', paddingLeft: '0px'}}>
    <div
      className={`${styles.header}`}
      style={(subMenu.control && subMenu.control === 'submenu') ? {
        paddingTop: '6px',
        paddingLeft: '15px'
      }
        : {
          paddingTop: '15px',
          paddingLeft: '15px'
        }}>
      <span>
        {!showRangeSelector
          ? props.item.headerRangeSelector
          : (props.item.headerManualEntry
            ? props.item.headerManualEntry
            : props.item.headerRangeSelector)}
      </span>
      <div
        className={styles.rangeSelectorLink}
        style={(subMenu.control && subMenu.control === 'submenu') ? {paddingTop: '0px', paddingLeft: '15px'}
          : { }}>
        {showRangeSelector
          ? <LinkButton btnRef={focusElem} label='Range Selector' onClick={() => onClickHandler()} onKeyDown={keyPressHandler} />
          : <LinkButton btnRef={focusElem} label='Manual Entry' onClick={() => onClickHandler(props.onSubMenuStyleChange)} onKeyDown={keyPressHandler} />}
      </div>
    </div>
    {showRangeSelector
      ? <div className={styles.inputItem}>
        <div style={{
          marginLeft: '0px',
          paddingTop: '20px',
          paddingLeft: '15px'
        }}>
          <label htmlFor='min'>Min</label>
          <input type='number'
            data-value='min'
            value={min}
            name='min'
            aria-label={`${props.item.header} min ${min}`}
            onChange={onMinInput}
            className={minErr && styles.err}
            style={{ marginRight: '18px' }} />
          <label htmlFor='max'>Max</label><input
            type='number'
            data-value='max'
            value={max}
            name='max'
            aria-label={`${props.item.header} max ${max}`}
            onChange={onMaxInput}
            className={maxErr && styles.err}
            style={{
              marginRight: '15px'
            }} />
          {subMenu.control && subMenu.control === 'submenu'
            ? <span />
            : <button className={styles.button} onClick={onApply} style={isApplyDisabled ? {paddingRight: '0px', paddingLeft: '18px', color: 'grey'} : { paddingRight: '0px', paddingLeft: '18px'
            }}>Apply</button>}
          <div
            style={{ display: 'flex' }}>
            {minErr && <div style={{ marginLeft: '1px' }}>
              <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className={styles.icon} />Invalid input value</div>}
            {maxErr && <div
              className={(subMenu.control && subMenu.control === 'submenu')
                ? `${styles.maxerrmsgforL3}`
                : `${styles.maxerrmsg}`}
              style={{
              }}><FontAwesomeIcon icon={['fas', 'exclamation-circle']} className={styles.icon} />Invalid input value</div>}
          </div>
        </div>
        {subMenu.control && subMenu.control === 'submenu'
          ? <div style={{ float: 'right', marginLeft: '0px' }}>
            <button className={styles.button} onClick={onApply} style={isApplyDisabled ? {paddingRight: '15px', paddingLeft: '0px', paddingTop: '15px', color: 'grey'} : {
              paddingRight: '15px',
              paddingLeft: '0px',
              paddingTop: '15px'
            }}>Apply</button>
          </div>
          : <span />}
      </div>
      : <ul className={styles.listRange}>
        {props
          .item
          .arrayList
          .map((element, index) => {
            return <li className={styles.listCheckbox} key={`rsChkbox-${index}-${element}`}>
              <div>
                <label className={styles.checkboxLabel} htmlFor={`chk-${`${props.item.parentVal}-${element}`}`} >
                  <input id={`chk-${`${props.item.parentVal}-${element}`}`} type='checkbox'
                    checked={selectedRadioBtn && selectedRadioBtn.indexOf(element) > -1}
                    onChange={(e) => handleOnButtonClicked(e, element, props.item, props.selectedParent, props.handleClick)}
                    value={element}
                    label={element} />
                  <span className={`${styles['checkmark']}`} />
                  <span className={styles.chkLabel}>{element}</span>
                </label>
              </div>
            </li>
          })
        }
      </ul>
    }
  </div>
}
RangeSelector.propTypes = {
  onChange: PropTypes.func,
  item: PropTypes.object,
  handleClick: PropTypes.func,
  selectedParent: PropTypes.oneOfType([
    PropTypes.string, PropTypes.object]),
  onSubMenuStyleChange: PropTypes.func,
  max: PropTypes.string,
  min: PropTypes.string
}
const KEYCODE = {
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
  TAB: 9,
  ENTER: 13
}
export default RangeSelector
