import React from 'react'
import styles from './SnapshotStrategyModalHeader.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

export default function SnapshotStrategyModalHeader (props) {
  return (
    <div className={styles.fundStrategyModalHeader}>
      <div className={styles.fundStrategyModalHeaderText}>{props.header}</div>
      <button className={styles.fundStrategyModalPopUpClose} alt='Close' id='closeButton' onClick={props.onClose}>
        <FontAwesomeIcon icon={['fal', 'times']} className={styles.fundStrategyModalPopUpClose} id='closeButtonFontAwsome' />
      </button>
    </div>
  )
}

SnapshotStrategyModalHeader.propTypes = {
  header: PropTypes.string,
  onClose: PropTypes.func
}
