/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../Lib/Button/Button'
import styles from './ChartTimePeriods.module.scss'
import { enterKeyEvent } from '../../../utils/utilities'
import timePeriods from '../../../data/ChartTimePeriods.json'
import Calendar from '../../Lib/Calendar/Calendar'
import { DATE_FORMAT } from '../../../utils/appConstants'

class ChartTimePeriods extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      timeFilters: timePeriods,
      inputDates: this.setInitialDates(),
      isCustom: false
    }
  }

  setInitialDates () {
    let date = new Date()
    let startMonthDate = new Date()
    startMonthDate = new Date(startMonthDate.setMonth(startMonthDate.getMonth() - 1))
    let startDateAPI = new Date()
    startDateAPI = new Date(startDateAPI.setMonth(startDateAPI.getMonth() - 1))
    return {
      calendarStartDate: this.props.startDate !== undefined ? this.props.startDate : startMonthDate,
      calendarEndDate: this.props.endDate !== undefined ? this.props.endDate : date,
      endDate: date.toISOString(),
      startDate: new Date(startDateAPI.setDate(startDateAPI.getDate() - 1)).toISOString(),
      isCorrect: true
    }
  }
  onDateChange (e, key) {
    let inputDatesCopy = { ...this.state.inputDates }
    inputDatesCopy[key] = e
    this.setState({ inputDates: {...inputDatesCopy} })
  }

  handleTimeSelection = (e) => {
    let data = e.currentTarget.innerText === 'Apply' ? { isCustom: true, startDate: this.state.inputDates.calendarStartDate, endDate: this.state.inputDates.calendarEndDate } : Object.assign({},
      JSON.parse(e.currentTarget.getAttribute('data-values')),
      { currentDate: new Date().toString() })
    this.props.onSelection(data)
    e.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.style.display = 'none'
  }

  handleTimePeriodFocus = (e) => {
    if (e.currentTarget.innerText === 'Custom') {
      this.setState({isCustom: true})
    } else {
      this.setState({isCustom: false})
      e.currentTarget.getElementsByTagName('ul')[0].style.display = 'inline-block'
    }
  }

    handleTimePeriodBlur = (e) => {
      if (e.shiftKey && e.keyCode === 9) {
        e.currentTarget.getElementsByTagName('ul')[0].style.display = 'none'
      }
    }

    handleTimeIntervalBlur = (e) => {
      e.stopPropagation()

      if (e.currentTarget.getAttribute('data-is-last-option') === 'true') {
        e.currentTarget.parentElement.style.display = 'none'
      }
    }

    render () {
      return <div className={styles.timePeriodsContainer} tabIndex='0' data-testid='chartTimePeriodsContainer'>
        <ul tabIndex='0'> {
          this.state.timeFilters.map((period, index) => {
            return <li key={index} tabIndex='0'
              onFocus={this.handleTimePeriodFocus}
              onKeyDown={this.handleTimePeriodBlur}>
              {
                <span className={styles.periodLabel} tabIndex='-1'>
                  {
                    period.text
                  }
                </span>
              }
              {
                <span className={styles.iconArrowRightContainer} tabIndex='-1'>
                  <FontAwesomeIcon icon={['fas', 'caret-right']} color='black' className={styles.iconArrowRight} tabIndex='-1' />
                </span>
              }
              {
                Array.isArray(period.value) && <ul tabIndex='0' data-time-period={period.text} className={`${period.text === 'Custom' ? '' : styles['timestampDiv']}`}>
                  {
                    period.text === 'Custom' ? <div className={styles.custom}>
                      <div className={styles.calenderDiv} >
                        <div className={styles.customCalender}>
                          <div className={styles.calendarMaker}>
                            <Calendar selectDateRange={false} onChange={(date) => { this.onDateChange(date, 'calendarStartDate') }} startDate={this.state.inputDates.calendarStartDate} dateFormatCalendar={DATE_FORMAT.MONTH_DAY_YEAR_DATE} noApplyButton />
                          </div>
                          <span className={styles.toContainer}>to</span>
                          <div className={styles.calendarMaker}>
                            <Calendar selectDateRange={false} onChange={(date) => { this.onDateChange(date, 'calendarEndDate') }} startDate={this.state.inputDates.calendarEndDate} dateFormatCalendar={DATE_FORMAT.MONTH_DAY_YEAR_DATE} noApplyButton />
                          </div>
                        </div>
                        <div className={styles.line} />
                        <Button buttonDataTestId='applyButton' clickHandler={(e) => { this.handleTimeSelection(e) }} className={styles.applyButton} labelText='Apply' />
                      </div>
                    </div>
                      : period.value.map((item, i) => {
                        return <li
                          key={i}
                          tabIndex='0'
                          data-is-last-option={(period.value.length - i) === 1}
                          data-values={JSON.stringify(item.value)}
                          onKeyPress={this.handleTimeSelection}
                          onClick={this.handleTimeSelection}
                          onBlur={this.handleTimeIntervalBlur}
                          onKeyUp={(e) => enterKeyEvent(e, this.handleTimeSelection)}>
                          <span className={styles.periodLabel} tabIndex='-1'>
                            {
                              item.text
                            }
                          </span>
                        </li>
                      })
                  }
                </ul>
              }
            </li>
          })
        }
        </ul>
      </div>
    }
}

ChartTimePeriods.propTypes = {
  onSelection: PropTypes.func,
  startDate: PropTypes.Date,
  endDate: PropTypes.Date
}

export default ChartTimePeriods
