
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import ChartistGraph from 'react-chartist'
import Chartist from 'chartist'
import styles from './EsgAUMRiskScoreChart.module.scss'
import DataUnavailable from '../../../../../Lib/DataUnavailable/DataUnavailable'
import { deviceType, getDeviceType } from '../../../../../../utils/utilities'
import AUMRiskScoreHoverPopup from './AUMRiskScoreHoverPopup'
import { NXI_COLOR_CHARTIST_SET } from '../../../../../../utils/appConstants'
import { COLOR_CHARTIST_SET } from '../../utils/appConstants'

function EsgAUMRiskScoreChart ({ data, isNXI = false }) {
  const [dataChartOptions, setDataChartOptions] = useState({})
  const [isReady, setReady] = useState(false)
  const [showToolTip, setShowToolTip] = useState(false)
  const [hoverBackground, setHoverBackground] = useState({top: 0, left: 0, height: 0, width: 0})
  const [aumRiskScorePopupPosition, setAumRiskScorePopupPosition] = useState({top: 110, left: 0})
  const [aumRiskScorePopupData, setAumRiskScorePopupData] = useState([{label: 'Corporate AUM ESG Risk', color: COLOR_CHARTIST_SET.DARK_BLUE, value: ''},
    {label: 'Sovereign AUM ESG Risk', color: isNXI? NXI_COLOR_CHARTIST_SET.GREY: COLOR_CHARTIST_SET.LIGHT_BLUE, value: ''}])
  useEffect(() => {
    setChartData()
  }, [data])

  function setChartData () {
    setReady(false)
    let dataChart = { ...dataChartOptions }
    let chartData = {
      data: {
        labels: [],
        series: []
      },
      chartOptions: setchartOptions()
    }
    chartData.data.series.push([])
    chartData.data.series.push([])
    if (data && data.corporate && data.sovereign) {
      chartData.data.labels.push('Negligible')
      chartData.data.series[0].push(data.corporate.negligibleRisk)
      chartData.data.series[1].push(data.sovereign.negligibleRisk)
      chartData.data.labels.push('Low')
      chartData.data.series[0].push(data.corporate.lowRisk)
      chartData.data.series[1].push(data.sovereign.lowRisk)
      chartData.data.labels.push('Medium')
      chartData.data.series[0].push(data.corporate.mediumRisk)
      chartData.data.series[1].push(data.sovereign.mediumRisk)
      chartData.data.labels.push('High')
      chartData.data.series[0].push(data.corporate.highRisk)
      chartData.data.series[1].push(data.sovereign.highRisk)
      chartData.data.labels.push('Severe')
      chartData.data.series[0].push(data.corporate.servereRisk)
      chartData.data.series[1].push(data.sovereign.servereRisk)
      setReady(true)
    }
    dataChart.data = chartData
    setDataChartOptions(dataChart)
  }

  function setchartOptions () {
    return {
      seriesBarDistance: barDistanceResponsive(),
      showLine: true,
      width: '100%',
      height: '100%',
      offset: 0,
      axisX: {
        showGrid: true,
        showLabel: true,
        labelOffset: {
          x: 0,
          y: 0
        }
      },
      axisY: {
        showGrid: true,
        showLabel: true,
        scaleMinSpace: 40,
        labelOffset: {
          x: 10,
          y: 0
        },
        labelInterpolationFnc: function (value) {
          return isNaN(value) ? '0%' : value.toFixed(2).toString() + '%'
        },
        position: 'end'
      },
      chartPadding: {
        bottom: 0,
        left: 0,
        top: 12,
        right: 20
      }
    }
  }

  function barDistanceResponsive () {
    switch (getDeviceType()) {
      case deviceType.Desktop: return 27
      case deviceType.Ipad: return 27
      case deviceType.Mobile: return 17
    }
  }

  // listen for chart events
  const chartListener = {
    draw: (data) => {
      if (data.type === 'grid') {
        handleChartGrid(
          data.axis.units.dir,
          data.index,
          data.element,
          data.y1,
          data.y2,
          data.x1,
          data.x2
        )
      } else if (data.type === 'bar') {
        handleDataPoints(
          data
        )
        if (data.group._node.className.baseVal === 'ct-series ct-series-a') {
          data.element._node.id = 'series-a_' + data.index
        } else if (data.group._node.className.baseVal === 'ct-series ct-series-b') {
          data.element._node.id = 'series-b_' + data.index
        }
      }
    },
    created: function () {
      const aumRiskScoreContainer = document.getElementById('aum_risk_score')
      const chartBars = document.querySelectorAll('line.ct-bar')
      if (aumRiskScoreContainer) {
        aumRiskScoreContainer.onmouseover = (event) => {
          event.stopPropagation()
          setShowToolTip(false)
        }
      }
      if (chartBars) {
        chartBars.forEach(bar => {
          bar.onmouseover = (event) => {
            event.stopPropagation()
            if (bar.id && !showToolTip) {
              const barId = bar.id.split('_')[1]
              let initialData = aumRiskScorePopupData
              initialData[0].label = `Corporate ${dataChartOptions.data.data.labels[barId]} AUM ESG Risk`
              initialData[1].label = `Sovereign ${dataChartOptions.data.data.labels[barId]} AUM ESG Risk`
              initialData[0].value = dataChartOptions.data.data.series[0][barId]
              initialData[1].value = dataChartOptions.data.data.series[1][barId]
              setAumRiskScorePopupData(initialData)
              let leftPosition = 0
              const barDistance = barDistanceResponsive()
              let hoverLeft = 0
              let hoverWidth = 0
              let topPosition = 0
              if (getDeviceType() === deviceType.Mobile) {
                hoverWidth = (barDistance * 2) + 25
                topPosition = bar.y1.baseVal.value + 150
                if (bar.id.includes('a')) {
                  hoverLeft = bar.x2.baseVal.value - 22
                } else if (bar.id.includes('b')) {
                  hoverLeft = bar.x2.baseVal.value - 22 - barDistance
                }
                if (event.x > 198) {
                  leftPosition = bar.x2.baseVal.value - 230
                } else {
                  leftPosition = bar.x2.baseVal.value + 7
                }
              } else {
                hoverWidth = (barDistance * 2) + 35
                topPosition = bar.y1.baseVal.value
                if (bar.id.includes('a')) {
                  hoverLeft = bar.x2.baseVal.value - 32
                } else if (bar.id.includes('b')) {
                  hoverLeft = bar.x2.baseVal.value - 32 - barDistance
                }
                if (event.x > 550) {
                  leftPosition = bar.x2.baseVal.value - 247
                } else {
                  leftPosition = bar.x2.baseVal.value + 30
                }
              }
              setHoverBackground({top: getDeviceType() === deviceType.Mobile ? (isNXI ? '220px' : '275px') : isNXI ? '158px' : '207px', left: hoverLeft, height: 258, width: hoverWidth})
              setAumRiskScorePopupPosition({top: topPosition, left: leftPosition})
              setShowToolTip(true)
            } else {
              setShowToolTip(false)
            }
          }
        })
      }
    }
  }
  // line logic
  // draw and style chart grid x and y axis lines
  const handleChartGrid = (direction, index, element, y1, y2, x1, x2) => {
    if (direction === 'vertical' && index === 0) {
      const roundedLine = new Chartist.Svg(
        'line',
        {
          x1: x1,
          x2: x2,
          y1: y1.toFixed(),
          y2: y2.toFixed()
        },
        'chartGridLineSolid'
      )
      element.replace(roundedLine)
    } else if (direction === 'horizontal' && index === 3) {
      const roundedLine = new Chartist.Svg(
        'line',
        {
          x1: x1,
          x2: x2,
          y1: y1.toFixed(),
          y2: y2 + 55
        }
      )
      element.replace(roundedLine)
    } else if (direction === 'horizontal') {
      element.addClass('hiddenChartGridLine')
    }
  }

  const handleDataPoints = (data) => {
    if (dataChartOptions.data && dataChartOptions.data.data && !dataChartOptions.data.data.series[0][data.index]) {
      let x = data.element._node
      x.setAttribute('x1', (x.getAttribute('x1') - 12))
      x.setAttribute('x2', (x.getAttribute('x2') - 12))
    }
  }

  return (
    <div className={styles.esgAumRiskScoreChartContainer}>
      {
        isReady
          ? <div className={styles.AUMRiskScoreChartContainer} id='aum_risk_score' data-testid='AUM Risk Score Chart'>
            {
              <ChartistGraph
                listener={chartListener}
                data={dataChartOptions.data.data}
                options={dataChartOptions.data.chartOptions}
                type='Bar'
                style={{ width: '100%', height: '100%' }}
                className={`${isNXI? styles.nxiChartContainer :styles.chartContainer}`}
              />
            }
          </div>
          : <DataUnavailable />
      }
      {showToolTip && <AUMRiskScoreHoverPopup aumRiskScore={aumRiskScorePopupData} popupPosition={aumRiskScorePopupPosition} /> }
      {showToolTip && <div className={styles.chartBarContainer} style={{left: hoverBackground.left, height: hoverBackground.height, top: hoverBackground.top, width: hoverBackground.width}} />}
    </div>
  )
}

export default EsgAUMRiskScoreChart
EsgAUMRiskScoreChart.propTypes = {
  data: PropTypes.object,
  isNXI: PropTypes.bool
}
