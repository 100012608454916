import React from 'react'
import styles from './MaxSelectErrorModel.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
function MaxSelectErrorModel (props) {
  return (
    <div id='maxErrorModel' style={{display: 'none'}}>
      <div>
        {<React.Fragment><div className={styles.maxCompareContainer}>
          <div className={styles.upperdiv}>
            <div className={styles.icon}>
              <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
            </div>
            <div className={styles.maxCompareText}>
              <div>You can only compare up to 10 investments at one time. Please remove an investment in order to add more. </div>
            </div>
          </div>
          <button className={`${styles.buttons}`} onClick={props.errorModelClose} id={'maxSelectError'} aria-label='You can only compare up to 10 investments at one time.Please remove an investment before searching.Click ok to dismiss.'>
            Okay
          </button>
        </div> </React.Fragment>}
      </div>
    </div>
  )
}
MaxSelectErrorModel.propTypes = {
  errorModelClose: PropTypes.func
}
export default MaxSelectErrorModel
