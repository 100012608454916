import React from 'react'
import styles from './AUMRiskScoreHoverPopup.module.scss'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Nodata from '../../../../../Lib/NoData/NoData'
import { formatNumber } from '../../../../../../utils/formatter'

export default function AUMRiskScoreHoverPopup ({aumRiskScore, popupPosition}) {
  return (
    !aumRiskScore ? <div className={styles.noData} style={{ top: popupPosition.top, left: popupPosition.left }} >
      <Nodata />
    </div>
      : <div className={styles.box} style={{ top: popupPosition.top, left: popupPosition.left }}>
        {
          aumRiskScore.map((item, index) => {
            return <div className={styles.internalBox} key={'aumRiskScore' + index}>
              {<React.Fragment>
                <FontAwesomeIcon icon={'circle'} style={{color: item.color}} className={`${styles.icon}`} />
                <div className={styles.key}>
                  {
                    item.label
                  }
                </div>
                <div className={`${styles.value}`}>
                  {
                    ` ${formatNumber(item.value, { precision: 2, postFix: true, postFixValue: '%', negSign: true })} `
                  }
                </div>
              </React.Fragment>
              }
            </div>
          })
        }
      </div>
  )
}
AUMRiskScoreHoverPopup.propTypes = {
  aumRiskScore: PropTypes.array,
  popupPosition: PropTypes.object
}
