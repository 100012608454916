import React from 'react'
import styles from './HoldingTable.module.scss'
import { formatNumber } from '../../utils/formatter'
import PropTypes from 'prop-types'
import {EVENT_NAMES} from '../../utils/f2Constants'
import { emitF2Event } from '../../../../../../utils/f2Methods'
function HoldingTable ({tableData}) {
  const handleClick = (item) => {
    emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, item)
  }

  return (
    <div className={styles.holdingTableContainer}>
      <table>
        <thead>
          <tr className={styles.srOnly}>
            <th scope='col' aria-hidden='true'> </th>
            <th scope='col'>Fund Symbol with Name</th>
            <th scope='col'>Allocation in %</th>
          </tr>
        </thead>
        <tbody>
          {tableData &&
          tableData.map((item, index) => {
            return (<tr key={index}>
              <td aria-hidden='true' className={styles.chartCircle}><span style={{background: item.color}} className={styles.circle}> </span>
              </td>
              <td className={styles.flexPos}>

                <button className={styles.symbol} onClick={() => handleClick(item)}>{item.symbol} </button>
                <span className={styles.name}> {item.name} </span>
              </td>
              <td aria-label='Allocation' className={styles.allocation}>{formatNumber(item.allocation, {
                negSign: false,
                postFix: true,
                postFixValue: '%',
                precision: 2,
                parentheses: false
              })} </td>
            </tr>)
          })
          }
        </tbody>
      </table>
    </div>
  )
}
HoldingTable.propTypes = {
  tableData: PropTypes.any
}
export default HoldingTable
