/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useRef} from 'react'
import styles from './DetailsCard.module.scss'
import PropTypes from 'prop-types'
import { formatNumber } from '../../utils/formatter'
import ShowMoreText from 'react-show-more-text'
import ActionMenu from '../../../../../Lib/ActionMenu/ActionMenu'
export default function DetailsCard (props) {
  const actionMenuRef = useRef()
  function openBtnHandler (name, isPreScreener) {
    props.openBtnHandler(name, isPreScreener)
  }

  const scrolled = (e) => {
    e.preventDefault()
    actionMenuRef.current.closePopover()
  }
  return (
    <div className={styles.indexCardContainer} onScroll={scrolled}>
      {props.searchResults.map((_item, idx) => (
        <div id={'details_cards_' + idx} className={styles.cardContainer} role='button' tabIndex='0' key={idx} onClick={() => openBtnHandler(_item.results, _item.isPreScreener)}>
          <hr className={styles.styleLine} />
          <div className={styles.indexInformationContainer} aria-label={_item.screenerName}>
            <div className={styles.header}>
              <div title={_item.screenerName} className={styles.headerName}>
                <ShowMoreText
                  lines={2}
                  more=''
                  less=''
                  expanded={false}
                >
                  {_item.screenerName}
                </ShowMoreText>
              </div>
              {props.showActionKebabMenu && <div className={styles.headerActionMenu}>
                <ActionMenu ref={actionMenuRef} actionsList={['Edit Screener Details', 'Delete Screener']} actionHandler={(e) => props.actionKebabHandler(e, _item.results)} dataValue={'Edit Screener Details'} />
              </div>}
            </div>
            <div className={styles.symbolContainer} role='button' tabIndex='0' key={idx}>
              <span title={_item.results.description} className={styles.symbolDescription} aria-label={`Description ${_item.text}`}>
                <ShowMoreText
                  lines={3}
                  more=''
                  less=''
                  expanded={false}
                >
                  {_item.results.description}
                </ShowMoreText>
              </span>
            </div>
            <div className={styles.symbolInfoContainer} aria-label={`ResultsCount${_item.count}`} role='button'>
              {formatNumber(_item.count, {precision: 0})}
              {' Results'}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

DetailsCard.propTypes = {
  searchResults: PropTypes.array,
  openBtnHandler: PropTypes.func,
  showActionKebabMenu: PropTypes.bool,
  actionKebabHandler: PropTypes.func
}
