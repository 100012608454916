/* eslint-disable no-debugger */
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import styles from './Filter.module.scss'
import Button from '../../../Lib/Button/Button'
import MultiSelectDropDown from '../../../Lib/Dropdown/multi-select-dropdown/MultiSelectDropDown'
import Calendar from '../../../Lib/Calendar/Calendar'
import { FilterConstants } from '../FilerConstants'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {handleScrollChange, getDeviceType, deviceType, getCompleteHeightPage, getScrollPositionsFromTop} from '../../../../utils/utilities'

function Filter (props) {
  const {selectedValues, filterOptions} = props
  const [selectedDates, setSelectedDates] = useState({...selectedValues['Custom Date']})
  const [freeTextSearch, setFreeTextSearch] = useState(selectedValues['FreeText'] && selectedValues['FreeText'].length > 0 ? selectedValues['FreeText'][0].value : '')
  const [filterStyle, setFilterStyle] = useState({})
  let elementsToBind = bindDropDownFilters()

  useEffect(() => {
    setFilterStyleBasedOnScrollPosition(getDeviceType())
    handleScrollChange(setFilterStyleBasedOnScrollPosition)
  }, [])

  useEffect(() => {
    props.isFilterActive && setFilterStyleBasedOnScrollPosition(getDeviceType())
  }, [props.isFilterActive])

  function setFilterStyleBasedOnScrollPosition (device) {
    if (device && (device === deviceType.Desktop || device === deviceType.Ipad)) {
      let filterIcon = document.getElementById('filterIcon')
      let filterContainer = document.getElementById('filterContainer')
      if (filterIcon && filterContainer) {
        let filterIconDimensions = filterIcon.getBoundingClientRect()
        let heightfilterContainer = filterContainer.offsetHeight
        let completeDocumentHeight = getCompleteHeightPage()
        if ((((completeDocumentHeight - filterIconDimensions.bottom) < heightfilterContainer) || (document.documentElement.clientHeight < filterIconDimensions.bottom)) && (filterIconDimensions.top + getScrollPositionsFromTop(filterContainer)) >= (heightfilterContainer + 5)) {
          setFilterStyle({
            'top': (-1 * (heightfilterContainer + 5))
          })
        } else {
          setFilterStyle({})
        }
      }
    }
  }

  function applyFilterButton (e) {
    e && e.stopPropagation()
    props.handleFilter(selectedValues)
  }

  useEffect(() => {
    setSelectedDates({...props.selectedValues['Custom Date']})
    setFreeTextSearch(props.selectedValues['FreeText'].length > 0 ? props.selectedValues['FreeText'][0].value : '')
  }, [props.selectedValues])

  function resetFilterButton (e) {
    e && e.stopPropagation()
    props.handleFilter(null, FilterConstants.RESET)
  }

  function handleKeyDownApplyButton (e) {
    checkIfEnter(e) && applyFilterButton(e)
  }

  function handleKeyDownResetButton (e) {
    checkIfEnter(e) && resetFilterButton(e)
  }

  function checkIfEnter (e) {
    return e && e.key === 'Enter'
  }

  function crossKeyDown (e) {
    checkIfEnter(e) && props.crossButtonHandle && props.crossButtonHandle()
  }

  const freeTextChanged = (e) => {
    selectedValues['FreeText'] = [{'text': e.target.value, 'value': e.target.value}]
    setFreeTextSearch(e.target.value)
  }

  function bindDropDownFilters () {
    if (props.filterData === undefined || props.filterData == null) {
      return []
    }

    const filterData = JSON.parse(JSON.stringify(props.filterData))
    const selectedKeys = Object.keys(filterData)
    const elements = []
    let element = null
    selectedKeys && selectedKeys.forEach((key, idx) => {
      if (!filterData[key].type) {
        element = (filterData[key]) ? (<div key={idx} className={`${styles.multiDropDownContentContainer} multidropDownContent`} value={key}> <MultiSelectDropDown
          data={filterData[key]}
          changeHandler={changeHandlerMultiSelect}

          selectedValue={selectedValues[key] && selectedValues[key].length > 0 && selectedValues[key].map(item => { return item.text }).join(', ')}
        /> </div>) : null
      } else if (filterData[key].type && filterData[key].type === FilterConstants.CALENDAR) {
        element = (filterData[key] && filterData)
          ? (<div key={idx} className={`${styles.dateRangeCalendarContentContainer} dateRangeCalendarContent`} value={key}>
            <Calendar
              selectDateRange onChange={onMultiDateChange}
              handleClear={props.handleClear}
              startDate={selectedDates.startDate}
              endDate={selectedDates.endDate} /></div>) : null
      }
      elements.push(<div key={'FilterContent_' + idx} className={styles.multiDropDown}>
        <div key={'FilterContentChild_' + idx} className={styles.labelmultiDropDown}>{filterOptions.DropDownLabelMapping[key]}</div>
        {element}
      </div>)
    })
    return elements
  }
  return (
    <div id='filterContainer' className={` ${styles.filterContainer} ${!props.isFilterActive ? styles.hide : ''}`} style={filterStyle}>
      <div className={styles.filterContainerHeading}><div className={styles.headerLabel}>Filters</div> <span role='button' tabIndex='0' title={'Close Filter'} className={styles.crossButtonFilter} onKeyDown={crossKeyDown} onClick={props.crossButtonHandle}><FontAwesomeIcon icon={['fal', 'times']} /></span></div>
      <div className={styles.searchFilterContainer}>
        <input className={styles.searchBoxFilter} type='text' placeholder='Search By Keyword' onChange={freeTextChanged} value={freeTextSearch} />
        <span className={`${styles.searchFilterIcon} ${styles['float-right']}`}>
          {
            <FontAwesomeIcon icon={['fal', 'search']} color='black' />
          }
        </span>
        <div key={-1} className={styles.filterDropDownContainer}>
          {elementsToBind}
        </div>
      </div>
      <div className={` ${styles.buttons} ${styles.buttonsFilter}`}>
        <Button tabIndex='0' onKeyDownEnter={handleKeyDownApplyButton} clickHandler={applyFilterButton} className={` ${styles.apply} ${styles.commonButton} applyButtonFilter`} labelText='Apply' />
        <Button tabIndex='0' onKeyDownEnter={handleKeyDownResetButton} clickHandler={resetFilterButton} className={` ${styles.reset} ${styles.commonButton} resetButtonFilter`} labelText='Reset' />
      </div>
    </div>
  )
  function onMultiDateChange (selectedValue, e) {
    const filterSelected = e.currentTarget.closest('div .dateRangeCalendarContent') && e.currentTarget.closest('div .dateRangeCalendarContent').getAttribute('value')
    selectedValues[filterSelected] = {
      'startDate': selectedValue[0],
      'endDate': selectedValue[1],
      'isExcludedFromBar': true
    }
    setSelectedDates({...selectedValues[filterSelected]})
  }
  function changeHandlerMultiSelect (valuesFromDropDown, e) {
    const filterSelected = e.currentTarget.closest('div .multidropDownContent') && e.currentTarget.closest('div .multidropDownContent').getAttribute('value')
    valuesFromDropDown = valuesFromDropDown.split(', ')
    let valuesSelected = []
    if (valuesFromDropDown && valuesFromDropDown.length > 0 && valuesFromDropDown[0] !== '') {
      valuesFromDropDown.forEach(item => {
        valuesSelected.push({
          text: item,
          value: filterOptions.FilterDataNews[filterSelected].find(e => e.text === item).value,
          checked: true
        })
      })
      let allSelectedOption = valuesSelected.filter(x => x.value === filterOptions.FilterDataNews[filterSelected][0].value)
      if (allSelectedOption.length > 0) {
        valuesSelected = allSelectedOption
      }
    }
    selectedValues[filterSelected] = valuesSelected
  }
}

export default Filter
Filter.propTypes = {
  filterData: PropTypes.object,
  isFilterActive: PropTypes.bool,
  handleFilter: PropTypes.func,
  handleClear: PropTypes.bool,
  selectedValues: PropTypes.object,
  crossButtonHandle: PropTypes.func,
  filterOptions: PropTypes.object
}
