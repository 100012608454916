import React, {useEffect} from 'react'
import styles from './CompareSection.module.scss'
import { COST_COMPARE_CONSTANTS } from '../CostCompareConstants'
import BasicDropDown from '../../../../../Lib/Dropdown/BasicDropDown'
import PrimaryButton from '../../../../../Lib/Button/PrimaryButton'
import { deviceType, getDeviceType } from '../../utils/utilities'
import PropTypes from 'prop-types'

function CompareSection (props) {
  useEffect(() => {

  }, [props.compareState])
  let ipad = getDeviceType() === deviceType.Ipad
  function checkIfANumber (value) {
    return isNaN(value) ? '' : value
  }

  function getStateBody (dataPassed) {
    let isCustomStyleInputs = dataPassed && dataPassed.value && dataPassed.value.length > 1
    return dataPassed.value.map((data, idx) => {
      let customClass = isCustomStyleInputs && idx < dataPassed.value.length - 1 ? styles.customSpacing : ''
      if (data.type === 'dropdown') {
        return (<div key={data.stateName} data-value={data.stateName} className={`${styles.dropdown} ${customClass}`}>
          <BasicDropDown
            customStyle
            selectedValue={props.compareState[dataPassed.stateName]}
            setRevertDefaultValue={() => {}}
            data={data.values}
            changeHandler={(e) => { props.dropDownChangeHandler(e) }} />
        </div>)
      } else {
        return (<div key={data.stateName} className={`${styles.dropdown} ${customClass}`}>
          <input type='number' step='1' placeholder={data.placeholder} aria-label={data.stateName} className={styles.inputstyle} value={props.compareState[data.stateName] && checkIfANumber(props.compareState[data.stateName])} onChange={props.onChangeFromInput} data-value={data.stateName} />
        </div>)
      }
    })
  }

  function getBody (data) {
    let arrayData = data || COST_COMPARE_CONSTANTS.COST_COMPARE_SECTION
    return arrayData.map((constants, idx) => {
      let noSeparator = constants.noDividerNeeded || (ipad && constants.noDividerNeededIpad)
      return (<div key={idx} className={styles.stateContainer}>
        <div className={styles.header}>
          {constants.key}
        </div>
        <div className={styles.stateBody}>
          {getStateBody(constants)}
          <div className={`${styles.line} ${noSeparator ? styles.noBorder : ''}`} />
        </div>
      </div>)
    })
  }

  function prepareBody () {
    let body = []
    if (ipad) {
      let i = 0
      // In ipad there will be 2 lines
      while (i < COST_COMPARE_CONSTANTS.COST_COMPARE_LINES_IPAD) {
        // 2 items content in first row & other 3 in second
        let slicePoint = i === 0 ? i : i + 1

        // If first line only 2 will come
        let lengthToBeSliced = i === 0 ? 2 : COST_COMPARE_CONSTANTS.COST_COMPARE_SECTION.length
        body.push((<div key={i} className={styles.ipadBody}>
          {getBody(COST_COMPARE_CONSTANTS.COST_COMPARE_SECTION.slice(slicePoint, lengthToBeSliced))}
          {i === 1 && getButton() }
        </div>))
        i++
      }
      return (<div className={`${ipad && styles.ipadSpecific}`}>{body}</div>)
    } else {
      body.push(getBody())
      return body
    }
  }

  function checkIfButtonIsActive () {
    if (props.compareState.initial_investment === undefined ||
      props.compareState.initial_investment === '') {
      return false
    }
    return true
  }

  function getButton () {
    let isButtonActive = checkIfButtonIsActive()
    return (<div className={`${styles.compareButton} ${!isButtonActive && styles.disableButton}`}>
      <PrimaryButton buttonText='Compare' clickHandler={props.onCompareClick} />
    </div>)
  }
  return (<div className={styles.compareSection} >{prepareBody()}
    {!ipad && getButton()}
  </div>)
}

CompareSection.propTypes = {
  compareState: PropTypes.object,
  dropDownChangeHandler: PropTypes.func,
  onChangeFromInput: PropTypes.func,
  onCompareClick: PropTypes.func
}
export default CompareSection
