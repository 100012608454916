import { DEFAULT_VALUE } from '../../../../../../utils/appConstants'

export const QUOTE_DEFAULT_STATE = {
  showEyeIcon: false,
  categoryType: null,
  delayedQuoteDate: null,
  showRefreshIcon: false,
  isLoading: true,
  isError: false,
  RangeData: {
    label: '52 Week Range',
    min: null,
    max: null,
    value: null,
    showProgressBar: true
  },
  DayChangeInfo: {
    label: 'Day Change',
    price: null,
    percentChange: null
  },
  Volume: {
    label: 'Volume',
    value: DEFAULT_VALUE
  },
  lastTradeDate: null,
  LastNASDAQData: {
    label: `Last Price: ${DEFAULT_VALUE}`,
    price: null,
    multiplyFactor: null
  },
  BidNASDAQData: {
    label: `Bid: ${DEFAULT_VALUE}`,
    price: null,
    multiplyFactor: null
  },
  AskNASDAQData: {
    label: `Ask: ${DEFAULT_VALUE}`,
    price: null,
    multiplyFactor: null
  }
}
