import React from 'react'
import styles from './EducationResourceFeatureCard.module.scss'
import PropTypes from 'prop-types'
import LinkButton from '../../../../../../Lib/LinkButton/LinkButton'

const EducationResourceFeatureCard = (props) => {
  const actionViewDetails = (e) => {
    if (props.viewMoreText === 'View PDF') {
      window.open(props.pdf_link)
    } else {
      window.open(props.startleraningitem)
    }
  }

  return (
    <div className={styles.quoteCard}>
      <div className={styles.quoteCardHeader}>
        <img id={'ERLogo'} className={styles.sparkChartDomElement} alt={`Featured Logo ${props.cardType || ''}`} src={props.quoteCardLogo} />
      </div>
      <div className={styles.appGap} />
      <div className={styles.quoteCardBody}>
        <div id='ER_Header' className={styles.quoteHeaderText}>{props.header}</div>
        <div id='ER_IndentDetails' className={styles.quoteIndentDetailText}>{props.hangingIndentDetails}</div>
        {props.viewMoreText && <div className={styles.linkContainer}>
          <LinkButton dataTestId='ER_ViewMore' label={props.viewMoreText} onClick={actionViewDetails} />
        </div>}
      </div>
    </div>
  )
}

EducationResourceFeatureCard.propTypes = {
  header: PropTypes.string,
  viewMoreText: PropTypes.string,
  hangingIndentDetails: PropTypes.string,
  quoteCardLogo: PropTypes.any,
  pdf_link: PropTypes.string,
  startleraningitem: PropTypes.string,
  cardType: PropTypes.string
}
export default EducationResourceFeatureCard
