import React from 'react'
import styles from './ValueTable.module.scss'
import PropTypes from 'prop-types'
import { COLOR_DEFAULT_SET } from '../../../../../../../utils/appConstants'
import StockHighlightsItem from '../../../../../../Stocks/StockHighlights/StockHighlightsItem/StockHighlightsItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ValueTable ({ data, style, separator, customMark, isNXI=false }) {
  return (
    <div className={styles.tableContainer}>
      {
        data.map((item, idx) => {
          return <div className={`${styles.row} ${separator && idx < data.length - 1 && !isNXI ? styles.divider : ''}`}>
            {isNXI ? <div
                  className={styles.circle}
                  style={{ background: item.color }}
                />
            : <FontAwesomeIcon icon={['fas', 'circle']} color={`${customMark ? item.color : COLOR_DEFAULT_SET[idx].value}`} className={styles.icon} />}
            <StockHighlightsItem label={item.name} value={item.value} keyStyling={style} />
          </div>
        })
      }
    </div>
  )
}

ValueTable.propTypes = {
  data: PropTypes.array,
  style: PropTypes.object,
  separator: PropTypes.bool,
  customMark: PropTypes.bool,
  isNXI: PropTypes.bool
}
