/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import PropTypes from 'prop-types'
import styles from './PriceChange.module.scss'

export default function PriceChange (props) {
  let valueItem = props.value.toString().split('(')
  let baseValue = valueItem[0].trim()
  let riseInValue = valueItem[1] ? valueItem[1] : valueItem[0]
  const valueDisplayed = () => {
    let value = ''
    if (valueItem[1]) {
      value = '(' + riseInValue
    } else {
      value = riseInValue + '%'
    }
    return value
  }
  return (
    <div className={styles.priceChangeContainer}>
      {valueItem[1] ? baseValue : ''}
      <div className={riseInValue.indexOf('-') !== -1 ? styles.riseInValue : styles.fallInValue}>{valueDisplayed()}</div>
    </div>
  )
}

PriceChange.propTypes = {
  value: PropTypes.string
}
