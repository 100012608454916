'use strict'
import React, { useEffect, useState } from 'react'
import ChartSection from './ChartSection/ChartSection'
import CompareSection from './CompareSection/CompareSection'
import styles from './CostCompare.module.scss'
import { COST_COMPARE_CONSTANTS } from './CostCompareConstants'
import PropTypes from 'prop-types'
import DataRequest from '../../../../../services/DataRequest'
import { URLS } from '../utils/appConstants'
import Loader from '../../../../Lib/common/Loader'
function CostCompare (props) {
  const [compareState, setCompareState] = useState(getInitialState())
  const [chartData, setChartData] = useState(null)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    props.modelCompareCardData && props.modelCompareCardData.length > 0 && prepareCallAPI()
  }, [props.modelCompareCardData])

  function getInitialState () {
    let stateObj = {}
    COST_COMPARE_CONSTANTS.COST_COMPARE_SECTION.forEach(constants => {
      constants.value.forEach(data => {
        stateObj[data.stateName] = data.defaultValue
      })
    })
    return stateObj
  }

  function dropDownChangeHandler (e) {
    let stateName = e && e.closest('ul') && e.closest('ul').parentElement && e.closest('ul').parentElement.getAttribute('data-value')
    stateName && updateState(stateName, e.getAttribute('data-value'))
  }

  function onChangeFromInput (e) {
    e && updateState(e.target.getAttribute('data-value'), e.target.value)
  }

  function updateState (key, value) {
    let updatedState = {...compareState}
    updatedState[key] = value
    setCompareState(updatedState)
  }

  function callback (response) {
    let chartData = {
      chartLabels: [],
      chartValues: [],
      smartSymbols: []
    }
    if (!response.isError) {
      let responseData = response && response.data
      props.modelCompareCardData.forEach(data => {
        let item = responseData && responseData.items.find(e => e.wsodIssue === data.venueXid)
        chartData.chartLabels.push(data.symbol)
        if (item) {
          chartData.chartValues.push(Math.round(item.totalCost || 0))
        } else {
          chartData.chartValues.push(undefined)
        }
      })
      chartData.smartSymbols = responseData && responseData.SmartTextSymbolIds
      setChartData(chartData)
      setIsReady(true)
    }
  }
  function prepareCallAPI () {
    setIsReady(false)
    const wsodIssues = props.modelCompareCardData.map(e => e.venueXid).join()
    let refineYearValue = compareState.time_horizon && parseInt(compareState.time_horizon.split(' ')[0])
    let annualReturnRate = compareState.annual_return && parseInt(compareState.annual_return.split('%')[0])
    DataRequest.execute(
      `${URLS.CUSTOM.COST_ANALYZER}`,
      {
        params: {
          WsodIssue: wsodIssues,
          CompoundGrowthRate: annualReturnRate / 100,
          InitialInvestment: compareState.initial_investment,
          SystematicInvestment: compareState.reoccuring_investment,
          TransactionFee: compareState.broker_commision,
          PeriodsPerCompound: getPeriodCompound(),
          TotalPeriods: refineYearValue * 12,
          TotalPeriodsSmartText: compareState.time_horizon
        }
      },
      callback,
      null,
      {
        realtime: false
      }
    )
  }

  function getPeriodCompound () {
    switch (compareState.reoccuring_investment_dropdown) {
      case 'Monthly':
        return 1
      case 'Quarterly':
        return 3
      case 'Annually':
        return 12
    }
  }

  function onCompareClick () {
    prepareCallAPI()
  }

  function getSmartText () {
    let symbols = []
    if (chartData && chartData.smartSymbols) {
      chartData.smartSymbols.forEach(venueXid => {
        let item = props.modelCompareCardData.find(e => e.venueXid === venueXid)
        if (item) {
          symbols.push(item.symbol)
        }
      })
    }
    if (symbols.length > 1) {
      return (<div className={styles.descriptiveText}><a className={styles.link}>{symbols[0]}</a> and <a className={styles.link}>{symbols[1]}</a> would have been the most effective funds selected over a {compareState.time_horizon} horizon.</div>)
    }
    return symbols.length > 0 && (<div className={styles.descriptiveText}><a className={styles.link}>{symbols[0]}</a> would have been the most effective funds selected over a {compareState.time_horizon} horizon.</div>)
  }
  return (<div className={styles.costCompareContainer}>
    <header>
      <CompareSection
        compareState={compareState}
        dropDownChangeHandler={dropDownChangeHandler}
        onChangeFromInput={onChangeFromInput}
        onCompareClick={onCompareClick} />
    </header> {
      isReady === false ? <Loader spinnerSize={'2x'} ready={false} />
        : <section>
          {getSmartText()}
          <div className={styles.chart}>
            <ChartSection chartData={chartData} />
          </div>
        </section>
    }
  </div>)
}

CostCompare.propTypes = {
  modelCompareCardData: PropTypes.array
}
export default CostCompare
