import React from 'react'
import PropTypes from 'prop-types'
import styles from './TableHeader.module.scss'

export default function TableHeader ({leftLabel, rightLabel, headerStyle=false}) {
  return (
    <div className={`${headerStyle ? styles.headerSpacing:''} ${styles.label}`}>
      <div>{leftLabel}</div>
      <div>{rightLabel}</div>
    </div>
  )
}

TableHeader.propTypes = {
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  headerStyle: PropTypes.bool
}
