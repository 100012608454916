import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import styles from './ModalPopUp.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { deviceType, getDeviceType } from '../../../utils/utilities'

const ModalPopUp = props => {
  const { onClose } = props
  const modalRef = useRef(null)
  let firstFocusableElement

  useEffect(() => {
    const focusableElements = modalRef.current.querySelectorAll(`button:not([disabled])`) // Selecting close button as first focusable element
    firstFocusableElement = focusableElements.length > 1 && focusableElements[0] // Check lenght > 1 as lastfocusable element (defined at line: 76) will always be avaialble in modal popup
    if (!props.addEventListener) {
      modalRef.current.addEventListener('keydown', onClose, false)
    }
    if (!props.isBodyScrollDisabled && getDeviceType() === deviceType.Mobile) {
      addRemoveBodyScroll()
    }
    firstFocusableElement && firstFocusableElement.addEventListener('keydown', setFocustoLastFocusableElement)
    modalRef.current.focus()
    return () => {
      addRemoveBodyScroll(true)
      firstFocusableElement && firstFocusableElement.removeEventListener('keydown', setFocustoLastFocusableElement)
    }
  }, [])

  function addRemoveBodyScroll (enableBodyScroll) {
    let classNameBody = 'hideBodySroll'
    if (document.body.classList.contains(styles[classNameBody]) && enableBodyScroll) {
      document.body.classList.remove(styles[classNameBody])
    } else if (!document.body.classList.contains(styles[classNameBody]) && !enableBodyScroll) {
      document.body.classList.add(styles[classNameBody])
    }
  }

  function setFocustoLastFocusableElement (e) {
    if (isTabPressed && e.shiftKey) { // If shift key pressed for shift + tab combination
      modalRef.current.querySelector('button#lastFocusableElement').focus() // Move focus to last focusable element
      e.preventDefault()
    }
  }

  function isTabPressed (e) {
    return (e.key === 'Tab' || e.keyCode === 9)
  }

  return <div
    className={styles.outerModalPopUp}
    id='outerModalPopUp'
    role='presentation'
    tabIndex='-1'
    onKeyUp={onClose}
    onClick={onClose}
    name={props.name || ''}
    style={props.stylesPopUpMain}
    ref={modalRef}>
    <div
      className={styles.modalPopUp}
      role='presentation'
      style={props.stylesPopUp}
    >
      {!props.hideClose && !props.customHeader && <div className={`${styles['modalCloseIcon']} ${props.header && styles['modalHeaderCloseIcon']}`} >
        {props.header && <div className={styles.modelHeader}>{props.header}</div>}
        <button className={styles.btnPopUpClose} alt='Close' id='closeButton' data-testid='closeButton' onClick={onClose}>
          <FontAwesomeIcon icon={['fal', 'times']} className={styles.btnPopUpClose} id='closeButtonFontAwsome' />
        </button>
      </div>}
      {props.header && <hr />}
      {props.customHeader && props.children[0]}
      <div id='modalPopUpContentContainer' className={`${props.customBody ? '' : styles['modalPopUpContentContainer']}`} style={props.stylesContent}>
        {props.customHeader && props.children[1]}
        {!props.customHeader && props.children}
        <button id='lastFocusableElement' aria-hidden='true' style={{position: 'absolute', top: '-100px'}} onKeyDown={(e) => {
          if (isTabPressed && !e.shiftKey) {
            firstFocusableElement && firstFocusableElement.focus()
            e.preventDefault()
          }
        }} />
      </div>
    </div>
  </div>
}

ModalPopUp.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  customHeader: PropTypes.bool,
  customBody: PropTypes.bool,
  hideClose: PropTypes.bool,
  header: PropTypes.string,
  name: PropTypes.string,
  stylesPopUp: PropTypes.object,
  stylesContent: PropTypes.object,
  addEventListener: PropTypes.bool,
  isBodyScrollDisabled: PropTypes.bool,
  stylesPopUpMain: PropTypes.object
}

export default ModalPopUp
