import React, { useEffect } from 'react'
import styles from './Snapshot.module.scss'
import F2AppContainer from '../../../../../TestCenter/F2AppContainer'
import { APP_IDS } from '../../../../../../utils/f2Constants'

const Snapshot = () => {
  useEffect(() => {
  }, [])

  return (
    <div className={styles.etfResearchContainer}>
      <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH}>
        <F2AppContainer
          appId={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH}
          rootId={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH} />
      </div>
      <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_ETF_SNAPSHOT}>
        <F2AppContainer
          appId={APP_IDS.COM_PERSHING_NETXWEALTH_ETF_SNAPSHOT}
          context={{ symbol: 'IVV', issueType: 'ETF', exchange: 'NASDAQ', xid: '171184', isETF: true, shareName: 'Ishares S&P 500' }}
          rootId={APP_IDS.COM_PERSHING_NETXWEALTH_ETF_SNAPSHOT} />
      </div>
    </div>
  )
}

export default Snapshot
