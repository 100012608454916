/* eslint-disable */
import React, { Suspense, lazy, Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "../src/assets/Icons";
import "../src/assets/Fonts.js";
import Loader from "../src/components/Lib/common/Loader";
import DevelopComponentPage from "./components/TestCenter/DevelopComponentPage";
import ComponentTestPage from "./components/TestCenter/ComponentTestPage";
import F2AppsMarketPlace from "./components/TestCenter/F2AppsMarketPlace";
import EarningsAndEstimates from "./components/TestCenter/ClientApplication/Pages/QuotesAndResearch/EarningsAndEstimates/EarningsAndEstimates";
import FundamenatalsPage from "./components/TestCenter/ClientApplication/Pages/QuotesAndResearch/Fundamentals/FundamentalsPage";
import OptionsPage from "./components/TestCenter/ClientApplication/Pages/QuotesAndResearch/Options/OptionsPage";
import Snapshot from "./components/TestCenter/ClientApplication/Pages/QuotesAndResearch/Snapshot/Snapshot";
import Charting from "./components/TestCenter/ClientApplication/Pages/QuotesAndResearch/Charting/Charting";
import Dividends from "./components/TestCenter/ClientApplication/Pages/QuotesAndResearch/Dividends/Dividends";
import StockNewsAndResearch from "./components/TestCenter/ClientApplication/Pages/QuotesAndResearch/StockNewsAndResearch/StockNewsAndResearch";
import Valuations from "./components/TestCenter/ClientApplication/Pages/QuotesAndResearch/Valuations/Valuations";
import Watchlists from "./components/TestCenter/ClientApplication/Pages/Watchlists/Watchlists";
import OwnershipPage from "./components/TestCenter/ClientApplication/Pages/QuotesAndResearch/Ownership/Ownership";
import ETFResearchPage from "./components/TestCenter/ClientApplication/Pages/ETFResearch/Snapshot/Snapshot";
import PageEquityScreener from "./components/TestCenter/ClientApplication/Pages/EquityScreener/PageEquityScreener";
const CompareEquities = lazy(() => import("../src/components/WorkstreamII/pershing-netxwealth-universalsearch/components/TestCenter/ClientApplication/Pages/Compare/Compare"));
const NewsAndResearch = lazy(() => import("../src/components/TestCenter/ClientApplication/Pages/MarketsAndSectors/NewsAndResearch"));
const MarketOverview = lazy(() => import("../src/components/TestCenter/ClientApplication/Pages/MarketsAndSectors/MarketOverview"));
const QuotesAndResearch = lazy(() => import("../src/components/TestCenter/ClientApplication/Pages/QuotesAndResearch/QuotesAndResearch"));
const MarketEvents = lazy(() => import("../src/components/TestCenter/ClientApplication/Pages/MarketsAndSectors/MarketEvents"));
const FundScreener = lazy(() => import("../src/components/WorkstreamII/pershing-netxwealth-universalsearch/components/TestCenter/ClientApplication/Pages/FundScreener"));
const AIScreener = lazy(() => import("../src/components/WorkstreamII/pershing-netxwealth-universalsearch/components/Screener/AI/AIScreener"));

const Compare = lazy(() => import("../src/components/WorkstreamII/pershing-netxwealth-universalsearch/components/TestCenter/ClientApplication/Pages/Comparison/Comparison"));
const Search = lazy(() => import("./components/WorkstreamII/pershing-netxwealth-universalsearch/components/TestCenter/ClientApplication/Pages/Search/Search"));
const ETFResearch = lazy(() => import("./components/WorkstreamII/pershing-netxwealth-universalsearch/components/ETFResearch/ETFResearch"))
const AIMarketplace =  lazy(() => import("./components/WorkstreamII/pershing-netxwealth-universalsearch/components/TestCenter/ClientApplication/Pages/AiMarketPlace/AiMarketPlace"));
const F2TestCenter = lazy(() =>
  import("../src/components/TestCenter/F2TestCenter")
);
const FundCompare = lazy(() => import("../src/components/WorkstreamII/pershing-netxwealth-universalsearch/components/FundCompare/FundCompare"));
const FundMarketPlace = lazy(() => import("../src/components/WorkstreamII/pershing-netxwealth-universalsearch/components/TestCenter/ClientApplication/Pages/FundMarketPlace/FundMarketPlace"));
const LandingPage = lazy(() => import("./components/WorkstreamII/pershing-netxwealth-universalsearch/components/TestCenter/ClientApplication/Pages/LandingPage/LandingPage"));
const WatchlistEquity = lazy(() => import('./components/TestCenter/WatchlistEquityCompareTest'))
const AdminFeaturedLandingPage = lazy(() => import("../src/components/WorkstreamII/pershing-netxwealth-universalsearch/components/TestCenter/ClientApplication/Pages/Admin/AdminFeaturedLandingPage"));
export default class App extends Component {
  constructor(props) {
    super(props);
    this.spinnerStyle = { "min-height": "250px" };
  }

  render() {
    return (
      <BrowserRouter>
        <Suspense
          fallback={
            <Loader
              style={this.spinnerStyle}
              ready={false}
              spinnerSize={"2x"}
              msg={"Loading"}
            />
          }
        >
          <Switch>
            <Route exact path="/" component={F2AppsMarketPlace} />
            <Route exact path="/old" component={F2TestCenter} />
            <Route exact path="/components" component={ComponentTestPage} />
            <Route exact path="/develop" component={DevelopComponentPage} />
            <Route exact path="/markets-sectors/news-research" component={NewsAndResearch} />
            <Route exact path="/markets-sectors/market-overview" component={MarketOverview} />
            <Route exact path="/quotes-research" component={QuotesAndResearch} />
            <Route exact path="/quotes-research/snapshot/:symbol?" component={Snapshot} />
            <Route exact path="/quotes-research/charting/:symbol?" component={Charting} />
            <Route exact path="/quotes-research/dividends/:symbol?" component={Dividends} />
            <Route exact path="/quotes-research/stock-news-research/:symbol?" component={StockNewsAndResearch} />
            <Route exact path="/quotes-research/valuations/:symbol?" component={Valuations} />
            <Route exact path="/markets-sectors/market-events" component={MarketEvents} />
            <Route exact path="/quotes-research/earnings-estimates/:symbol?" component={EarningsAndEstimates} />
            <Route exact path="/quotes-research/fundamentals/:symbol?" component={FundamenatalsPage} />
            <Route exact path="/quotes-research/options/:symbol?" component={OptionsPage} />
            <Route exact path="/quotes-research/ownership/:symbol?" component={OwnershipPage} />
            <Route exact path="/FundScreener" component={FundScreener} />
            <Route exact path="/AIScreener" component={AIScreener} />
            <Route exact path="/Compare" component={Compare} />            
            <Route exact path="/FundMarketPlace" component={FundMarketPlace} />
            <Route exact path="/FundCompare" component={FundCompare} />
            <Route exact path="/search" component={Search} />
            <Route exact path="/ETFResearch" component={ETFResearch} />
            <Route exact path="/ETFResearch/snapshot" component={ETFResearchPage} />
            <Route exact path="/AIMarketplace" component={AIMarketplace} />
            <Route exact path="/Watchlists" component={Watchlists} />
            <Route exact path="/landing-page-overview" component={LandingPage} />
            <Route exact path="/EquityScreener" component={PageEquityScreener} />
            <Route exact path="/CompareEquities" component={CompareEquities} />
            <Route exact path='/WatchlistEquity' component={WatchlistEquity} />
            <Route exact path="/EducationAndResources/:section?" component={AdminFeaturedLandingPage} />
		  </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}
