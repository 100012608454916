import React, { Fragment, useEffect } from 'react'
import ShowMoreText from 'react-show-more-text'
import PropTypes from 'prop-types'
const InvestmentStrategyDecription = (props) => {
  useEffect(() => {
    props.readMoreHandle()
  }, [props.investmentStrategyDecription])

  return <Fragment>
    <ShowMoreText
      lines={2}
      more=''
      less=''
      expanded={false}
      className={'strategy'}
    >
      {props.investmentStrategyDecription}
    </ShowMoreText>

  </Fragment>
}

InvestmentStrategyDecription.propTypes = {
  investmentStrategyDecription: PropTypes.any,
  readMoreHandle: PropTypes.any
}
export default InvestmentStrategyDecription
