/* eslint-disable no-eval */
import format from '@markit/format'
import moment from 'moment-timezone'
import numeral from 'numeral'

export const TEXT_NULL_VALUE = '--'

export const FORMAT_TYPE = {
  BILLION: 'B',
  MILLION: 'M',
  THOUSAND: 'K',
  MILLION_VALUE: 1000000
}

function isNull (val) {
  return typeof val === 'undefined' || val === null || val === '-32768' || val === -32768
}

export const formatDate = (date, options = {}) => {
  const DAY_MILLISECONDS = 86400000
  const MS_DAY_OFFSET = 25569
  let formatOptions = {
    format: options.format || 'MM/DD/YYYY, hh:mm A',
    postFix: options.postFix || '',
    preFix: options.preFix || '',
    UTCtoEST: options.UTCtoEST || false,
    isoDateString: options.isoDateString || false,
    timeOnlyFormat: options.timeOnlyFormat,
    noTimeZoneConversion: options.noTimeZoneConversion,
    isMsDate: options.isMsDate,
    isShortDateFormat: options.isShortDateFormat
  }
  if (formatOptions.UTCtoEST) {
    date = convertUTCToEST(date)
  }
  if (formatOptions.isoDateString) {
    date = format().moment(date, formatOptions.format)
  }
  if (formatOptions.isMsDate) {
    let jO = new Date(((date - MS_DAY_OFFSET) * DAY_MILLISECONDS))
    let tz = jO.getTimezoneOffset()
    jO = new Date(((date - MS_DAY_OFFSET + (tz / (60 * 24))) * DAY_MILLISECONDS))
    if (formatOptions.isShortDateFormat) {
      date = moment(jO)
    } else {
      date = moment(jO).format(formatOptions.format)
    }
  }
  let formattedDateTime
  if (formatOptions.timeOnlyFormat) {
    formattedDateTime = format().time(date, formatOptions.timeOnlyFormat)
  } else if (formatOptions.noTimeZoneConversion) {
    formattedDateTime = moment.utc(date).format(formatOptions.format)
  } else {
    formattedDateTime = format().date(date, formatOptions.format)
  }

  if (formattedDateTime !== TEXT_NULL_VALUE) {
    if (options.preFix) {
      formattedDateTime = `${options.preFix} ${formattedDateTime}`
    }
    if (options.postFix) {
      formattedDateTime = `${formattedDateTime} ${formatOptions.postFix}`
    }
  }
  return formattedDateTime
}

export const calculateTimeDiffFromTimeZone = (tradingTime, timeZone) => {
  const trading = format().moment.tz(tradingTime, timeZone).utcOffset(0, true) // Get trading time in passed time zone
  const now = moment().tz(timeZone).utcOffset(0, true).format() // Get current time in passed time zone
  const diff = trading.diff(now) // Difference of two time
  const duration = moment.duration(diff)
  let hours = Math.floor(duration.asHours())
  return `${hours > 0 ? hours + moment.utc(diff).format(':mm') + ' hrs' : moment.utc(diff).format('mm') + ' mins'}`
}

/*
Time Input Format:- hh:mm:ss
Usage Example:- formatTime(10:02:34, {format: 'hh:mm A'})
*/
export const formatTime = (time, options = {}) => {
  let formatOptions = {
    format: options.format || 'hh:mm A',
    postFix: options.postFix || '',
    preFix: options.preFix || ''
  }
  let formattedTime = format().moment(time, 'HH:mm:ss').format(formatOptions.format)
  if (formatOptions.postFix) {
    formattedTime = `${formattedTime} ${formatOptions.postFix}`
  }
  if (formatOptions.preFix) {
    formattedTime = `${formattedTime} ${formatOptions.postFix}`
  }
  return formattedTime
}

export const toFormattedDate = (date) => {
  let fulldate = ''
  if (date && (date instanceof Date)) {
    let dateSplitWithComma = format().date(date, 'LLL').split(',')
    let monthAndDay = dateSplitWithComma[0].split(' ')
    let month = monthAndDay[0].substring(0, 3)
    fulldate = `${month} ${monthAndDay[1]},${dateSplitWithComma[1]} EST`
  }
  return fulldate
}

export const toFormattedDateWithET = (date) => {
  let fullDate = ''
  let dateString = format().date(date, 'MMMM DD')
  let timeString = format().time(date, 'h:mm A')
  fullDate = `${timeString} ET, ${dateString}`
  return fullDate
}

export const toFormattedTimeWithET = (date, options = {}) => {
  let formatOptions = {
    format: options.format || 'hh:mm:ss A'
  }
  let timeString = format().time(date, formatOptions.format || 'h:mm A')
  return `${timeString} ET`
}

export const convertUTCToEST = (date) => {
  if (date) {
    const utc = format().moment.utc(date).toISOString()
    return format().moment.tz(utc, 'America/New_York')
  }
  return ''
}

export const getESTBackDate = (numOfDay) => {
  return format().moment.tz('America/New_York').subtract(numOfDay, 'days')
}

export const formatNumber = (number, options = {}) => {
  let inputs = {
    number: options,
    currencyPreFix: options.currencyPreFix || false,
    currencyPreFixValue: options.currencyPreFixValue || '$',
    postFix: options.postFix,
    postFixValue: options.postFixValue || '%',
    negSign: options.negSign || true,
    posSign: options.posSign || false,
    precision: options.precision !== undefined ? options.precision : 2,
    parentheses: options.parentheses,
    color: options.color,
    invalidDataDefaultValue: options.invalidDataDefaultValue || TEXT_NULL_VALUE,
    showMagnitude: options.showMagnitude || false
  }

  let preFix = ''
  let postFix = ''
  let formatString = `0,0.${Array(inputs.precision + 1).join('0')}`

  let formattedValue = numberFormatter(number, formatString)

  if (formattedValue === '--') {
    return inputs.invalidDataDefaultValue
  }

  formattedValue = formattedValue.replace('-', '')

  if (inputs.negSign && number < 0) {
    preFix += '-'
  } else if (inputs.posSign && number !== 0) {
    preFix += '+'
  }

  if (inputs.currencyPreFix) {
    preFix += inputs.currencyPreFixValue
  }

  if (inputs.postFix) {
    postFix += inputs.postFixValue
  }

  if (inputs.showMagnitude) {
    formattedValue = format().number(formattedValue, inputs.precision, {showMagnitude: inputs.showMagnitude}).replace(' ', '').toUpperCase()
  }
  formattedValue = preFix + formattedValue + postFix

  if (inputs.parentheses) {
    formattedValue = '(' + formattedValue + ')'
  }

  return formattedValue
}

export const formatPriceNumber = (price) => {
  return price < 1 ? formatNumber(price, {precision: 4}) : formatNumber(price, {precision: 2})
}

export const renderColorClass = (priceChange) => {
  if (isNaN(priceChange)) {
    return ''
  }
  return priceChange < 0 ? 'negative' : 'positive'
}

export const numberFormatter = (val, formatString) => {
  let formattedValue = numeral(val).format(formatString)

  return isNull(val)
    ? TEXT_NULL_VALUE
    : (formattedValue === 'NaN' ? TEXT_NULL_VALUE : formattedValue)
}

export function getCurrentMonthName (value, monthFormat) {
  return value.format(monthFormat)
}

export function diffInDays (startDate, endDate, format) {
  return moment(endDate, format).diff(moment(startDate, format), 'day')
}

export function addIntoDateString (date, noToAdd, type, format) {
  return moment(date, format).add(noToAdd, type)
}

export function getCurrentDate () {
  return moment()
}

export function getMomentDateUsingFormat (date, format) {
  return moment(date, format)
}

export function getFirstLastDayOfWeek () {
  const date = getCurrentDate()
  return {
    startDate: formatDate(date.clone().startOf('week'), {format: 'YYYY-MM-DD'}),
    endDate: formatDate(addIntoDateString(date.clone().endOf('week'), 7, 'days'), {format: 'YYYY-MM-DD'})
  }
}

// Get all dates between the start date and end date
export function getDates (startingDate, endingDate) {
  let dateArray = []
  let currentDate = moment(startingDate)
  let stopDate = moment(endingDate)
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
    currentDate = moment(currentDate).add(1, 'days')
  }
  return dateArray
}

// Return all months short name ex - ['Jan','Feb','Mar'...]
export function getAllMonthsShortName () {
  return moment.monthsShort()
}

export function getEvalValue (value, data) {
  let val = ''
  try {
    val = eval(value)
  } catch (ex) {
    val = null
  }
  return val
}

// Get values in billion million thousands
export function getFormattedValuesInMeasures (value, measure) {
  if (value) {
    switch (measure) {
      case FORMAT_TYPE.BILLION:
        return parseFloat(value) / 1000000000
      case FORMAT_TYPE.MILLION:
        return parseFloat(value) / 1000000
      case FORMAT_TYPE.THOUSAND:
        return parseFloat(value) / 1000
      default:
        return value
    }
  }
  return value
}

export function getPeriod (numOfdays, isEarningPeriods) {
  if (numOfdays < 30) {
    return 1
  } else if (numOfdays >= 30 && numOfdays <= 90) {
    return 2
  } else if (numOfdays > 90 && numOfdays <= 180) {
    return 3
  } else if (numOfdays > 180 && numOfdays <= 365) {
    return 5
  } else if (numOfdays > 365 && numOfdays <= 1095) {
    return 13
  } else if (numOfdays > 1095 && numOfdays <= 1825) {
    return isEarningPeriods ? 20 : 21
  } else if (numOfdays > 1825 && numOfdays <= 3650) {
    return isEarningPeriods ? 20 : 41
  } else if (numOfdays > 3650) {
    return isEarningPeriods ? 20 : Math.floor((numOfdays / 90)) + 1
  }
  return 1
}

export const convertToInternationalCurrencySystem = (labelValue) => {
  if (!labelValue) {
    return '--'
  }
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9
    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + 'B'
  // Six Zeroes for Millions
    : Math.abs(Number(labelValue)) >= 1.0e+6
      ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + 'M'
    // Three Zeroes for Thousands
      : Math.abs(Number(labelValue)) >= 1.0e+3
        ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + 'K'
        : Math.abs(Number(labelValue))
}

export function convertToNumeric (value) {
  const lastChar = value && value.slice(-1)
  const numericValue = parseFloat(value)
  if (lastChar === 'M') {
    return numericValue * 1000000
  } else if (lastChar === 'B') {
    return numericValue * 1000000000
  } else if (lastChar === 'K') {
    return numericValue * 1000
  }

  return numericValue
}
