import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './CarbonRiskHighlights.module.scss'
import StockHighlightsItem from '../../../../../Stocks/StockHighlights/StockHighlightsItem/StockHighlightsItem'
import Loader from '../../../../../Lib/common/Loader'
import { formatDate, formatNumber } from '../../utils/formatter'

export default function CarbonRiskHighlights ({xid, carbonRiskData, isNXI=false}) {
  const [isReady, setIsReady] = useState(false || !xid)
  const [highlightsData, setHighlightsData] = useState({
    dateAllCarbonMetrics: '',
    percentAUMCovered: '',
    overallRiskScorePercentRankInCategory: ''
  })

  useEffect(() => {
    setESGCarbonMetricsData(carbonRiskData)
  }, [carbonRiskData])

  const setESGCarbonMetricsData = (carbonRiskData) => {
      setIsReady(false)
      if (!carbonRiskData?.isError && carbonRiskData?.data) {
        let data = carbonRiskData.data
        let percentAUMCovered = ''
        let overallRiskScorePercentRankInCategory = ''
        const dateAllCarbonMetrics = data.carbonDate ? formatDate(data.carbonDate, {format: 'MM/DD/YYYY'}) : '--'
        if (data.fund && data.fund.risk) {
          percentAUMCovered = data.fund.risk.coverage?.pctEligiblePortfolioCovered !== null ? formatNumber(data.fund.risk.coverage.pctEligiblePortfolioCovered, {precision: 2, postFix: true, postFixValue: '%'}) : '--'
          overallRiskScorePercentRankInCategory = data.fund.risk.score?.percentRank !== null ? data.fund.risk.score?.percentRank : '--'
        }
        setHighlightsData({dateAllCarbonMetrics, percentAUMCovered, overallRiskScorePercentRankInCategory})
      } else {
        setHighlightsData({
          dateAllCarbonMetrics: '--',
          percentAUMCovered: '--',
          overallRiskScorePercentRankInCategory: '--'
        })
      }
      setIsReady(true)
  }

  return (
    <Loader spinnerSize={'2x'} ready={isReady} >
      <div className={`${isNXI ? styles.nxiIndexDataContainer : styles.indexDataContainer}`} data-testid='CarbonRiskHighlightsContainer'>
        <div className={styles.header}>Carbon Risk Highlights</div>
        <div className={styles.indexDataContent}>
          <StockHighlightsItem label={'Carbon Metrics Date'} value={highlightsData.dateAllCarbonMetrics} hideBorder={isNXI} />
          <StockHighlightsItem label={'AUM Covered Carbon'} value={highlightsData.percentAUMCovered} hideBorder={isNXI} />
          <StockHighlightsItem label={'Percent Rank in Category'} value={highlightsData.overallRiskScorePercentRankInCategory} hideBorder={isNXI} />
        </div>
      </div>
    </Loader>
  )
}

CarbonRiskHighlights.propTypes = {
  xid: PropTypes.number,
  carbonRiskData: PropTypes.object,
  isNXI: PropTypes.bool
}
