/* eslint-disable jsx-a11y/no-onchange */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styles from './Pagination.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Pagination = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(0)
  function handleExpandCollapse (e) {
    setIsCollapsed(!isCollapsed)
  }
  // calculate total pages
  const totalPages = Math.ceil(props.totalItems / props.pageLimit)
  const defaultStyleIcons = {
    'height': '16px',
    'width': '6px',
    'verticalAlign': 'bottom'
  }
  let page = Math.floor(props.startingItem / props.pageLimit) + 1
  // ensure current page isn't out of range
  if (page < 1) {
    page = 1
  } else if (page > totalPages) {
    page = totalPages
  }

  // calculate start and end item indexes
  let startIndex = (page - 1) * props.pageLimit
  const startingItem = startIndex + 1
  const endingItem = Math.min(startIndex + props.pageLimit - 1, props.totalItems - 1) + 1

  const totalRecords = !isNaN(totalPages) ? Array.from(Array(parseInt(totalPages)), (_, x) => x + 1) : []

  const handlePageClick = (type) => {
    if (type === 'nextPage') {
      if (page !== totalRecords[totalRecords.length - 1] && props.pageChangeHandler) {
        paginationPageChangeHandler('nextPage')
      }
    } else if (type === 'previousPage') {
      if (page !== totalRecords[0] && props.pageChangeHandler) {
        paginationPageChangeHandler('previousPage')
      }
    }
  }

  const HandleOnChange = (val) => {
    paginationPageChangeHandler(val)
  }

  const handlePageEnter = (pageType, event) => {
    if (pageType === 'previousPage' && event.charCode === 13) {
      event.preventDefault()
      if (page !== totalRecords[0] && props.pageChangeHandler) {
        paginationPageChangeHandler('previousPage')
      }
    } else if (pageType === 'nextPage' && event.charCode === 13) {
      event.preventDefault()
      if (page !== totalRecords[totalRecords.length - 1] && props.pageChangeHandler) {
        paginationPageChangeHandler('nextPage')
      }
    }
  }

  const paginationPageChangeHandler = (behaviour) => {
    let startingIndex = 0
    switch (behaviour) {
      case 'previousPage':
        startingIndex = (page - 2) * props.pageLimit
        break
      case 'nextPage':
        startingIndex = (page) * props.pageLimit
        break
      default:
        startingIndex = (behaviour - 1) * props.pageLimit
        break
    }
    props.pageChangeHandler(startingIndex)
  }

  return (
    <nav aria-label={props.ariaLabel} className={styles.pagination}>
      <div className={styles.pageInfoText}>
        <span >{startingItem} - {endingItem} of {props.totalItems}</span>
      </div>
      <div
        id='paginationPreviousPage'
        className={styles.paginationNav}
        data-value='previousPage'
        aria-label='Previous Page'
      >
        <button className={styles.paginationButton} onClick={() => handlePageClick('previousPage')} onKeyPress={(event) => handlePageEnter('previousPage', event)} aria-label='Previous Page' data-value='previousPage' tabIndex='0'>
          <FontAwesomeIcon style={props.style || defaultStyleIcons} icon={['far', 'chevron-left']} className={styles.pagePreviousIcon} color={page === totalRecords[0] ? 'rgba(0,0,0,0.26)' : '#000000'} />
        </button>
      </div>
      <select className={isCollapsed ? styles.paginationSelectOpen : styles.paginationSelect} data-testid='paginationSelect' aria-label='Select Page'
        value={page}
        onClick={handleExpandCollapse}
        onChange={({ target: { value } }) =>
          HandleOnChange(value)
        }
      >
        {totalRecords.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div
        id='paginationNextPage'
        className={styles.paginationNavNext}
        data-value='nextPage'
        aria-label='Next Page'
      >
        <button className={styles.paginationButton} onClick={() => handlePageClick('nextPage')} onKeyPress={(event) => handlePageEnter('nextPage', event)} aria-label='Next Page' data-value='nextPage' tabIndex='0'>
          <FontAwesomeIcon style={props.style || defaultStyleIcons} data-value='nextPage' icon={['far', 'chevron-right']} className={styles.pagePreviousIcon} color={page === totalRecords[totalRecords.length - 1] ? 'rgba(0,0,0,0.26)' : '#000000'} />
        </button>
      </div>
    </nav>
  )
}

export default Pagination

Pagination.propTypes = {
  pageLimit: PropTypes.number.isRequired,
  startingItem: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  ariaLabel: PropTypes.string,
  pageChangeHandler: PropTypes.func.isRequired,
  style: PropTypes.object
}
