import React, { useEffect, useState } from 'react'
import Loader from '../../../../../Lib/common/Loader'
import NoData from '../../../../../Lib/NoData/NoData'
import { URLS } from '../../utils/appConstants'
import styles from './BetaRisk.module.scss'
import BetaRiskChart from './BetaRiskChart/BetaRiskChart'
import PropTypes from 'prop-types'
import { getAPIData, getBetaRiskChartType } from '../../utils/utilities'

const BetaRisk = ({xid, riskTitle, type, symbol, shareName}) => {
  let [chartData, setChartData] = useState({ labels: [], series: [] })
  const [isLoadingAndError, setIsLoadingAndError] = useState({ isLoading: true, isError: false })
  const spinnerStyle = { 'minWidth': 'auto', 'height': 'auto' }
  const [chartInfo, setChartInfo] = useState({chartType: 'DefaultChart', maxLimit: 2, minLimit: 0})
  const betaRiskChartLabels = ['1 Year', '3 Year', '5 Year']
  const sharpeRatioChartLabels = ['3 Year', '5 Year', '10 Year']

  useEffect(() => {
    setIsLoadingAndError({ isLoading: true, isError: false })
    getChartData()
  }, [xid])
  // Ratings button click day 2 item
  // const handleClick = () => {
  //   let emitData = {
  //     xid: xid,
  //     symbol: symbol,
  //     shareName: shareName
  //   }
  //   emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, emitData)
  // }
  function isEmpty (data) {
    const isEmpty = Object.values(data).every(x => x === null || x === '' || x === undefined)
    return isEmpty
  }

  function getChartData () {
    const callback = (response) => {
      if (response.isDataRequestComplete && !response.isError && response.data) {
        let data = response.data
        let seriesData = {1: null, 3: null, 5: null, 10: null}
        switch (type) {
          case 'MF':
          case 'MODEL':
            if (data.fund.nav.sharpeRatio) {
              data.fund.nav.sharpeRatio.filter(x => [3, 5, 10].includes(x.temporalPeriod)).forEach(item => {
                seriesData[item.temporalPeriod] = item.value
              })
            }
            break
          case 'ETF':
            if (data.fund.nav.beta.primaryIndex) {
              data.fund.nav.beta.primaryIndex.filter(x => [1, 3, 5].includes(x.temporalPeriod)).forEach(item => {
                seriesData[item.temporalPeriod] = item.value
              })
            }
            break
        }
        if (!isEmpty(seriesData)) {
          settingChartData(seriesData)
          setIsLoadingAndError({ isError: false, isLoading: false })
        } else {
          setIsLoadingAndError({ isError: true, isLoading: false })
        }
      } else {
        setIsLoadingAndError({ isError: true, isLoading: false })
      }
    }

    getAPIData(xid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_RISK_MEASURE, callback)
  }
  const getTitle = () => {
    let html = <div className={styles.header} id='riskTitleHeader' >{riskTitle}</div>
    if (type === 'MODEL') {
      html = <div className={`${styles.header} ${styles.headerModel}`}>{riskTitle}</div>
    }
    return html
  }
  const settingChartData = (seriesData) => {
    setChartInfo(getBetaRiskChartType(seriesData))
    switch (type) {
      case 'MF':
      case 'MODEL':
        setChartData({ labels: sharpeRatioChartLabels, series: [[seriesData['3'], seriesData['5'], seriesData['10']]] })
        break
      case 'ETF':
        setChartData({ labels: betaRiskChartLabels, series: [[seriesData['1'], seriesData['3'], seriesData['5']]] })
        break
    }
  }
  if (isLoadingAndError.isLoading) {
    return (
      <div className={styles.betaRisk}>
        {getTitle()}
        <Loader spinnerStyle={spinnerStyle} ready={false} msg={'Loading'} spinnerSize={'2x'} />
      </div>
    )
  }
  if (isLoadingAndError.isError) {
    return (
      <div className={styles.betaRisk}>
        {getTitle()}
        <NoData />
      </div>
    )
  }

  return <Loader spinnerSize='2x' ready={!isLoadingAndError.isLoading && !isLoadingAndError.isError}>
    <div className={styles.betaRisk}>
      {getTitle()}
      <div className={styles.legends}>
        <div className={styles.color_blue} />
        <div className={styles.legendtext} id='thisFund' >{type === 'MODEL' ? `${"This Model's Sharpe Ratio"}` : 'This Fund'}</div>
        {(type === 'ETF')
          ? <React.Fragment>
            <div className={styles.dashed} />
            <div className={styles.legendtext}>S&P 500</div>
          </React.Fragment>
          : ''
        }
      </div>
      <BetaRiskChart type={type} data={chartData} chartInfo={{...chartInfo}} />
      <div className='sr-only'>
        <table>
          <caption>This Model's Sharpe Ratio</caption>
          <thead>
            <tr>
              {chartData && chartData.labels.map((item, index) => {
                return <th key={item + '-' + index}> {item} </th>
              })}
            </tr>
          </thead>
          <tbody>
            <tr key={'ModelSharpeRatioChartDataTable'}>
              {
                chartData && Array.isArray(chartData.series) && (chartData.series.length === 1) &&
                chartData.series[0].map((item, index) => {
                  return <td>{item || 'Not available'}</td>
                })
              }
            </tr>
          </tbody>
        </table>
      </div>
      {/* Day 2 item
       <button className={styles.betaRiskButton} onClick={() => handleClick()}>Go to Risk & Ratings</button> */}
    </div>
  </Loader>
}

BetaRisk.propTypes = {
  symbol: PropTypes.string,
  xid: PropTypes.any,
  shareName: PropTypes.string,
  riskTitle: PropTypes.string,
  type: PropTypes.string
}

export default BetaRisk
