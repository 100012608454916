import React, { useEffect } from 'react'

// parentId is the Id of container where slider is to be implement
export default function useSlider (parentId, screenerName) {
  useEffect(() => {
    const slider = document.getElementById(parentId)
    let mouseDown = false
    let startX, scrollLeft

    let startDragging = function (e) {
      mouseDown = true
      if (slider) {
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      }
    }
    let stopDragging = function (event) {
      mouseDown = false
    }
    if (slider) {
      slider.addEventListener('mousemove', (e) => {
        if (e.target.type === 'range') {
          return
        }
        e.preventDefault()
        if (!mouseDown) { return }
        const x = e.pageX - slider.offsetLeft
        const scroll = x - startX
        slider.scrollLeft = scrollLeft - scroll
      })

      // Add the event listeners
      slider.addEventListener('mousedown', startDragging, false)
      slider.addEventListener('mouseup', stopDragging, false)
      slider.addEventListener('mouseleave', stopDragging, false)
    }

    return () => {
      document.removeEventListener('mousedown', startDragging)
      document.addEventListener('mouseup', startDragging)
      document.addEventListener('mouseleave', startDragging)
    }
  }, [screenerName])
}

export function sliderHOC (Component) {
  return function WrappedComponent (props) {
    const sliderHookValue = useSlider(({...props}['id']))
    return <Component {...props} sliderHookValue={sliderHookValue} />
  }
}
