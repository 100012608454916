import React from "react";
import PropTypes from "prop-types";

function Polygone ({ color }) {
  return (
    <React.Fragment><svg
              width="12"
              height="12"
              viewBox="0 0 9 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Polygon"
                d="M4.5 0L9 6L4.5 12L0 6L4.5 0Z"
                fill={color || "#58595A"}
              />
            </svg>
    </React.Fragment>
  );
}

Polygone.propTypes = {
  color: PropTypes.string
};
export default Polygone;
