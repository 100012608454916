import React from 'react'
import PropTypes from 'prop-types'
import ESGScoreTop from '../../../../../../Stocks/ESGScores/ESGCommon/ESGScoreTop'

function CarbonRiskScoreHeader ({ data, isNXI=false }) {
  const getTopData = () => {
    let topData = {
      value1: '',
      value2: '',
      value3: '',
      subText1: '',
      subText2: '',
      subText3: ''
    }
    if (data?.fund?.risk?.score) {
      const {carbonRisk, carbonRiskLevelClassification, lowCarbonDesignation} = data.fund.risk.score
      topData = {
        value1: carbonRisk,
        value2: carbonRiskLevelClassification,
        value3: lowCarbonDesignation === false ? 'No' : 'Yes',
        subText1: isNXI ? '':'Below Average'
      }
    }
    return topData
  }
  const getHeaders = () => {
    return {
      header1: {
        header: 'Carbon Risk Score',
        headerDesc: 'Carbon Risk Score is the asset-weighted carbon-risk score of the equity or corporate-bond holdings in a portfolio (long positions only), averaged over the trailing 12 months. To calculate the portfolio carbon-risk scores, Morningstar uses Sustainalytics’ company carbon-risk ratings, which indicate the risk that companies face from the transition to a low-carbon economy.'
      },
      header2: {
        header: 'Carbon Risk Classification',
        headerDesc: `Carbon Risk Score grouped into categories based on severity of risk; assigned based on the overall carbon risk score for the portfolio. Note that The current score carbon-risk level distribution is as follows (but may be updated in early 2020 to reflect a change made by Sustainalytics) : 0 Negligible, 0.1-9.99 Low, 10-34.99 Medium, 35-59.99 High, 60+ Severe.`
      },
      header3: {
        header: 'Low Carbon Designation',
        headerDesc: `Based on two of these metrics—Carbon Risk Score and Fossil Fuel Involvement %—funds may receive the Low Carbon designation, which allows investors to easily identify low-carbon funds within the global universe. To receive the Low Carbon designation, a fund must have a 12-month average Portfolio Carbon Risk Score below 10 and a 12-month average Fossil Fuel Involvement of less than 7% of assets.`
      }
    }
  }
  return (
    <React.Fragment>
      <ESGScoreTop data={getTopData()} headers={getHeaders()} isNXI={isNXI}/>
    </React.Fragment>
  )
}
CarbonRiskScoreHeader.propTypes = {
  data: PropTypes.object,
  isNXI: PropTypes.bool
}

export default CarbonRiskScoreHeader
