/* eslint-disable no-eval */
import React, {useEffect, useState} from 'react'
import { TYPE_OF_VALUE } from '../../../../../utils/appConstants'
import { formatNumber } from '../../../../../utils/formatter'
import CommonTabularStructure from '../../../../Lib/CommonTabularStructure/CommonTabularStructure'
import PropTypes from 'prop-types'

export default function PremiumPriceMovementTable ({tableData, testId}) {
  const PREMIUM_PRICE_TABLE = {
    DATAMAP: [
      {'key': 'Delta',
        'value': 'data && data.delta',
        'type': TYPE_OF_VALUE.NUMBER
      }, {
        'key': 'Theta',
        'value': 'data && data.theta',
        'type': TYPE_OF_VALUE.NUMBER
      },
      {
        'key': 'Vega',
        'value': 'data && data.vega',
        'type': TYPE_OF_VALUE.NUMBER
      },
      {
        'key': 'Gamma',
        'value': 'data && data.gamma',
        'type': TYPE_OF_VALUE.NUMBER
      },
      {
        'key': 'Rho',
        'value': 'data && data.rho',
        'type': TYPE_OF_VALUE.NUMBER
      }
    ],
    PRECISION: 2,
    HEADER: ''
  }
  const [premiumPriceTableData, setPremiumPriceTableData] = useState(setPremiumPriceTableState(null))
  const premiumPriceTablestyles = {'padding': '7px 15px 15px 15px'}
  const premiumPriceTableRedStyle = {'color': '#ED0808'}

  useEffect(() => {
    setPremiumPriceTableData(setPremiumPriceTableState(tableData))
  }, [tableData])

  function setPremiumPriceTableState (data) {
    let obj = {}
    let arrayObj = []
    PREMIUM_PRICE_TABLE.DATAMAP.forEach((item) => {
      obj = {}
      obj['label'] = item.key
      obj['value'] = data && !data.isError ? getComputedValue(item, data) : '--'
      obj['valueStyling'] = data && !data.isError && getComputedValue(item, data) < 0 ? premiumPriceTableRedStyle : {}
      arrayObj.push(obj)
    })
    return {
      isError: data ? data.isError : null,
      isLoading: data ? data.isLoading : true,
      data: arrayObj
    }
  }

  function getComputedValue (item, data) {
    let expressionValue = getEvalValue(item.value, data)
    return formatNumber(expressionValue, {
      precision: PREMIUM_PRICE_TABLE.PRECISION,
      negSign: true
    })
  }

  function getEvalValue (value, data) {
    let val = ''
    try {
      val = eval(value)
    } catch (ex) {
      val = null
    }
    return val
  }

  return (<CommonTabularStructure dataObj={premiumPriceTableData} containerStyle={premiumPriceTablestyles} testId={testId} />)
}
PremiumPriceMovementTable.propTypes = {
  tableData: PropTypes.object,
  testId: PropTypes.string
}
