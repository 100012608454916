import React from 'react'
import styles from './ValueBar.module.scss'
import PropTypes from 'prop-types'
import { COLOR_DEFAULT_SET, NXI_COLOR_DEFAULT_SET } from '../../../../../../../utils/appConstants'

export default function ValueBar ({data, height, isNXI=false}) {
  function getStyle (index, value) {
    let borderRadius
    if (index === 0) {
      borderRadius = '3px 0px 0px 3px'
    } else if (index === data.length - 1) {
      borderRadius = '0px 3px 3px 0px'
    } else {
      borderRadius = '0px'
    }
    let style = {
      'background': isNXI ? NXI_COLOR_DEFAULT_SET[index].value : COLOR_DEFAULT_SET[index].value,
      'width': value + '%',
      borderRadius,
      'height': height || 20
    }
    return style
  }

  return (
    <div className={styles.tableContainer}>
      {
        data.map((item, idx) => {
          return <span style={getStyle(idx, item)} className={styles.bar} />
        })
      }
    </div>
  )
}

ValueBar.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
  isNXI: PropTypes.bool
}
