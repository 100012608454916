import React from 'react'
import ChartistGraph from 'react-chartist'
import PropTypes from 'prop-types'
import '../../../styles/css-modules/Chartist.css'

export default function ChartistChart (props) {
  return (
    <ChartistGraph
      data={props.data}
      options={props.options}
      type={props.type}
      className={props.className}
      style={props.chartistStyle}
      listener={props.chartistListner}
    />
  )
}
ChartistChart.propTypes = {
  data: PropTypes.object,
  options: PropTypes.object,
  type: PropTypes.string,
  className: PropTypes.string,
  chartistStyle: PropTypes.object,
  chartistListner: PropTypes.object
}
