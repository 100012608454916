import React from 'react'
import styles from './MaxComparePopUp.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Modal from '../Modal/components/Modal'
import ModalBody from '../Modal/components/ModalBody'
import PropTypes from 'prop-types'

function MaxComparePopUp (props) {
  return (
    <Modal close={props.close} id='maxCompare' styles={{height: 'auto'}}>
      <ModalBody>
        {<React.Fragment><div className={styles.maxCompareContainer}>
          <div className={styles.upperdiv}>
            <div className={styles.icon}>
              <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
            </div>
            <div className={styles.maxCompareText}>
              <div>You can only compare up to 10 investments at one time. Please remove an investment before searching.</div>
            </div>
          </div>
          <button className={`${styles.buttons}`} onClick={props.close} id={'textmsg'} aria-label='You can only compare up to 10 investments at one time.Please remove an investment before searching.Click ok to dismiss.'>
            Okay
          </button>
        </div> </React.Fragment>}
      </ModalBody>
    </Modal>
  )
}
MaxComparePopUp.propTypes = {
  close: PropTypes.func
}
export default MaxComparePopUp
