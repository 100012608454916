import React, { useState, useEffect } from 'react'
import SnapshotPerformanceChart from './SnapshotPerformanceChart'
import styles from './SnapshotPerformance.module.scss'
import { ETF_STOCKS_PERFORMANCE, URLS, CHART_STYLES } from '../../utils/appConstants'
import { handleWindowResize, getDeviceType, deviceType, getAPIData } from '../../utils/utilities'
import BasicDropDown from '../../../../../../components/Lib/Dropdown/BasicDropDown'
import ChartType from '../../../../../Charts/ChartType/ChartType'
import Loader from '../../../../../Lib/common/Loader'
import NoData from '../../../../../Lib/NoData/NoData'
import PropTypes from 'prop-types'
import PerformanceBar from './PerformanceBar/PerformanceBar'

const SnapshotPerformance = ({symbol, isMF = false}) => {
  const [device, setDeviceType] = useState(getDeviceType())
  const [intervalMapping] = useState(ETF_STOCKS_PERFORMANCE.intervalMapping)
  const [periodMapping] = useState(ETF_STOCKS_PERFORMANCE.periodMapping)
  const [selectedChartType, setSelectedChartType] = useState(ETF_STOCKS_PERFORMANCE.chartTypes.Fill)
  const [dropDownDataDays] = useState(Object.keys(ETF_STOCKS_PERFORMANCE.dropDownDaysValues))
  const [selectedDays, setSelectedDays] = useState(ETF_STOCKS_PERFORMANCE.dropDownDaysValues['1 Year'])
  const [wsodIssue, setWsodIssue] = useState(null)
  const [isLoadingAndError, setIsLoadingAndError] = useState({ isLoading: true, isError: false })
  const spinnerStyle = { 'minWidth': 'auto', 'height': 'auto' }
  const [headerText, setHeaderText] = useState('Price & Performance')
  function resetStates () {
    setSelectedDays(ETF_STOCKS_PERFORMANCE.dropDownDaysValues['1 Year'])
    setWsodIssue(null)
    setIsLoadingAndError({ isLoading: true, isError: false })
  }
  useEffect(() => {
    handleWindowResize((response) => {
      setDeviceType(response)
    })
  }, [device])
  useEffect(() => {
    if (isMF) {
      setHeaderText('NAV Performance')
    }
    // Whenever fund changes - resets states of each sub-component
    resetStates()
    getVenueXidFromXref()
  }, [symbol])

  const getVenueXidFromXref = () => {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        const responseData = response.data && response.data.xids
        if (responseData.venue) {
          setWsodIssue(responseData.venue)
          setSelectedDays(365)
          setIsLoadingAndError({ isError: false, isLoading: false })
        } else {
          setWsodIssue(null)
          setIsLoadingAndError({ isError: true, isLoading: false })
        }
      } else {
        setIsLoadingAndError({ isError: true, isLoading: false })
      }
    }

    getAPIData(symbol, URLS.PLATFORM.XREFGETSYMBOL, callback, `isCaseSensitive: false`, true)
  }
  const callChart = e => {
    if (!e) return
    e.preventDefault()
    setSelectedDays(ETF_STOCKS_PERFORMANCE.TIME_PERIOD[e.target.innerText])
  }
  const dropDownChangeHandler = (e) => {
    const value = e.getAttribute('data-value')
    const item = CHART_STYLES.filter(item => {
      if (item.Text === value) {
        return item
      }
    })
    setSelectedChartType((item && item[0] && item[0].Icon) || 'lineWithShapes')
  }
  const dropDownDaysChangeHandler = (e) => {
    setSelectedDays(ETF_STOCKS_PERFORMANCE.dropDownDaysValues[e.getAttribute('data-value')])
  }
  const getChartSelection = () => {
    let body = []
    let bodyHtml
    if (device === deviceType.Desktop || device === deviceType.Ipad) {
      bodyHtml =
        <div className={styles.itemsContainer} key={device}>
          <div className={`${selectedDays === 1 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart} >1D</button>
          </div>
          <div className={`${selectedDays === 5 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>5D</button>
          </div>
          <div className={`${selectedDays === 30 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>1M</button>
          </div>
          <div className={`${selectedDays === 90 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>3M</button>
          </div>
          <div className={`${selectedDays === 365 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>1Y</button>
          </div>
          <div className={`${selectedDays === 1825 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>5Y</button>
          </div>
          <div className={`${selectedDays === 3650 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>10Y</button>
          </div>
          <ChartType chartTypeSelValue={selectedChartType} onSelect={dropDownChangeHandler} />
        </div>
    } else if (device === deviceType.Mobile) {
      bodyHtml =
        <div className={styles.itemsContainer} key={device}>
          <div className={styles['row1FirstDD']}>
            <BasicDropDown data={dropDownDataDays} changeHandler={dropDownDaysChangeHandler} />
          </div>
          <div className={styles['row1SecondDD']}>
            <ChartType chartTypeSelValue={selectedChartType} onSelect={dropDownChangeHandler} />
          </div>
        </div>
    }
    body.push(bodyHtml)
    return body
  }
  const getChartInputs = () => {
    const dataInterval = intervalMapping[selectedDays]
    const dataPeriod = periodMapping[selectedDays]
    return {
      'timePeriod': selectedDays,
      'dataInterval': dataInterval,
      'dataPeriod': dataPeriod,
      'wsodIssue': wsodIssue,
      'chartType': selectedChartType
    }
  }
  const chartInputs = getChartInputs()

  const getMFChartSelection = () => {
    return <div className={styles.itemsContainer} key={device}>
      <div className={`${selectedDays === 365 ? styles['selectedItem'] : ''} ${styles['item']}`}>
        <button className={styles.button} onClick={callChart} onKeyPress={callChart}>1Y</button>
      </div>
      <div className={`${selectedDays === 1825 ? styles['selectedItem'] : ''} ${styles['mf5YItemAdjust']} ${styles['item']}`}>
        <button className={styles.button} onClick={callChart} onKeyPress={callChart}>5Y</button>
      </div>
      <div className={`${selectedDays === 3650 ? styles['selectedItem'] : ''} ${styles['item']}`}>
        <button className={styles.button} onClick={callChart} onKeyPress={callChart}>10Y</button>
      </div>
    </div>
  }

  const getMFSelection = () => {
    return <React.Fragment>
      <div className={styles.chartSelections}>
        {getMFChartSelection()}
      </div>
      <div>
        { wsodIssue && <SnapshotPerformanceChart chartInputs={chartInputs} isMF={isMF} symbol={symbol} />}
      </div>
    </React.Fragment>
  }

  const getETFSelection = () => {
    return <React.Fragment>
      <div className={styles.performanceBar}> <PerformanceBar xid={wsodIssue} /></div>
      <div className={styles.chartSelections}>
        {getChartSelection()}
      </div>
      <div className={styles.chart}>
        { wsodIssue && <SnapshotPerformanceChart chartInputs={chartInputs} symbol={symbol} />}
      </div>
    </React.Fragment>
  }

  if (isLoadingAndError.isLoading) {
    return (
      <div className={styles.performanceContainer}>
        <span className={styles.TopText}>{headerText}
        </span>
        <Loader spinnerStyle={spinnerStyle} ready={false} msg={'Loading'} spinnerSize={'2x'} />
      </div>
    )
  }
  if (isLoadingAndError.isError) {
    return (
      <div className={styles.performanceContainer}>
        <span className={styles.TopText}>{headerText}
        </span>
        <NoData />
      </div>
    )
  }
  return (
    <div className={styles.performanceContainer}>
      <span className={styles.TopText}>{headerText}</span>
      {isMF ? getMFSelection() : getETFSelection()}
      {/* TO BE DONE AS PART OF DAY-2
        {!isMF && <div className={styles.AdvChtLink}>Go to Advanced Charting</div>}
      */}

    </div>
  )
}

SnapshotPerformance.propTypes = {
  symbol: PropTypes.string,
  isMF: PropTypes.bool
}

export default SnapshotPerformance
