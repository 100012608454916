import ChartistChart from '../../../../../../Lib/Chartist/ChartistChart'
import styles from './BetaRiskChart.module.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { getDeviceType, deviceType } from '../../../../../../../utils/utilities'
import { getChartConfig, BetaRiskChartClass } from './ChartConfig'

const BetaRiskChart = ({data, chartInfo, type}) => {
  const [showToolTip, setShowToolTip] = useState(false)
  const [toolTipPosition, setToolTipPosition] = useState({top: '', left: ''})
  const [hoverBackground, setHoverBackground] = useState({top: 0, left: 0, height: 0})
  const [tooltipValue, setTooltipvalue] = useState(null)
  const chartOptions = getChartConfig(chartInfo.chartType, chartInfo.minLimit, chartInfo.maxLimit, type)
  const handleHoverPopup = (data) => {
    setShowToolTip(data.show)
    if (data.position) {
      let _left = 0
      // Since Hover text is large for Model
      if (type === 'MODEL' && getDeviceType() !== deviceType.Mobile) {
        _left = 50
      }
      setToolTipPosition({top: data.position.top, left: data.position.left - _left})
    }
    if (data.tooltipValue) {
      setTooltipvalue(data.tooltipValue)
    }
    if (data.hoverBackground) {
      setHoverBackground({left: data.hoverBackground.left})
    }
  }
  const listener = {
    created: (data) => {
      const chartGrid = document.querySelectorAll(`.${styles.chartContainer} .ct-grids`)
      // This css is applied to ETF only
      if (type === 'ETF') {
        chartGrid[0]?.classList?.add('betaRiskBarChart')
      }
      const chartBars = document.querySelectorAll(`.${styles.chartContainer} line.ct-bar`)
      const chartBarConatiner = document.querySelector(`.${styles.chartContainer} svg.ct-chart-bar`)
      if (chartBarConatiner) {
        chartBarConatiner.onmouseover = (event) => {
          event.stopPropagation()
          handleHoverPopup(
            {
              show: false
            })
        }
      }
      if (chartBars) {
        chartBars.forEach(bar => {
          bar.onmouseover = (event) => {
            event.stopPropagation()
            let leftPosition = 0
            let backgroundLeftPosition = 0
            if (getDeviceType() === deviceType.Mobile) {
              if (event.x > 190) {
                leftPosition = bar.x2.baseVal.value - 193
              } else {
                leftPosition = bar.x2.baseVal.value + 12
              }
              backgroundLeftPosition = bar.x2.baseVal.value - 24
              // Since Hover text is large for Model
              if (type === 'MODEL') {
                leftPosition -= 45
              }
            } else {
              if (event.x > 600) {
                leftPosition = bar.x2.baseVal.value - 194
              } else if (event.x > 400) {
                leftPosition = bar.x2.baseVal.value - 40
              } else {
                leftPosition = bar.x2.baseVal.value + 16
              }
              backgroundLeftPosition = bar.x2.baseVal.value - 35
            }
            handleHoverPopup(
              {
                show: true,
                position: {top: event.layerY + 50, left: leftPosition},
                tooltipValue: bar.attributes['ct:value'].value,
                hoverBackground: {left: backgroundLeftPosition}
              })
          }
        })
      }
    }
  }
  return <div className={styles.chartContainer} data-testid='betariskChart'>
    {showToolTip && <div className={type === 'MODEL' ? `${styles.chartBarContainer} ${styles.chartBarContainerModel}` : `${styles.chartBarContainer}`} style={{left: hoverBackground.left}} />}

    <ChartistChart data={data} options={chartOptions} type='Bar' chartistListner={listener} chartistStyle={{width: '100%', position: 'absolute', zIndex: 1}} className={styles[BetaRiskChartClass[chartInfo.chartType]]} />
    {showToolTip && <div style={{top: toolTipPosition.top, left: toolTipPosition.left}} className={type === 'MODEL' ? `${styles.tooltip} ${styles.tooltipModel}` : `${styles.tooltip}`} role='tooltip' >
      <div className={type === 'MODEL' ? `${styles.color} ${styles.colorModel}` : `${styles.color}`}><div className={styles.color_blue} /> {type === 'MODEL' ? `${"This Model's Sharpe Ratio"}` : 'This Fund'}</div>
      <div className={type === 'MODEL' ? `${styles.text} ${styles.textModel}` : `${styles.text}`}>{tooltipValue}</div>
    </div>
    }

  </div>
}
BetaRiskChart.propTypes = {
  data: PropTypes.object,
  chartInfo: PropTypes.object,
  type: PropTypes.string
}

export default BetaRiskChart
