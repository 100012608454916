import React, { useEffect, useState } from 'react'
import styles from './SnapshotNewsList.module.scss'
import NewsList from '../../../../../News/NewsList/NewsList'
import PropTypes from 'prop-types'

const SnapshotNewsList = (props) => {
  const [xid, setXid] = useState(props.xid)
  const NewsLimit = 3

  useEffect(() => {
    setXid(props.xid)
  }, [props.xid])
  return <div className={styles.newsContainer} >
    <div className={styles.header}>
      <span className={styles.symbol} id='newsHeader' >{`${props.symbol && props.symbol.toUpperCase()} News`}</span>
    </div>
    <div className={styles.newsList}>
      <NewsList key={xid} wsodIssue={xid} limit={NewsLimit} provider='MT Newswire' noDataMsg='There is no news available for this fund.' isFund />
    </div>
  </div>
}
SnapshotNewsList.propTypes = {
  xid: PropTypes.number,
  symbol: PropTypes.string
}
export default SnapshotNewsList
