/* eslint-disable  jsx-a11y/click-events-have-key-events */
/* eslint-disable  jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react'
import styles from './ComponentTestPage.module.scss'
import TabControl from './TestComponentsList/TabControl'
import ToggleControl from './TestComponentsList/ToggleControl'
import ModalPopUp from '../Lib/ModalPopUp/ModalPopUp'
import BasicDropDown from '../Lib/Dropdown/BasicDropDown'
import CheckBox from '../Lib/CheckBox/CheckBox'
import Carousal from '../Lib/Carousal/Carousel'
import Performance from '../Stocks/Performance/Performance'
import StockNewsList from '../Stocks/StockNewsList/StockNewsList'
import ColorPalette from '../Lib/ColorPalette/ColorPalette'
import { COLOR_DEFAULT_SET } from '../../utils/appConstants'
import Loader from '../Lib/common/Loader'
import MarketHighlightsNews from '../MarketsOverview/MarketHighlights/MarketHighlightsNews/MarketHighlightsNews'

const ComponentTestPage = () => {
  const [activeSelectedTab, setActiveSelectedTab] = useState('')

  const renderItems = () => {
    return (
      <div className={styles.liContainer}>
        <li className={`${styles.liStyle}`} data-filter-type='Lib' data-value='TabControl' onClick={handleSelection}>
          Tab control
        </li>
        <li className={`${styles.liStyle}`} data-filter-type='Lib' data-value='ToggleControl' onClick={handleSelection}>
          Toggle control
        </li>
        <li className={`${styles.liStyle}`} data-filter-type='Lib' data-value='ModalPopup' onClick={handleSelection}>
          Modal Popup
        </li>
        <li className={`${styles.liStyle}`} data-filter-type='Lib' data-value='Dropdown' onClick={handleSelection}>
          Dropdown
        </li>
        <li className={`${styles.liStyle}`} data-filter-type='Lib' data-value='Checkbox' onClick={handleSelection}>
          Checkbox
        </li>
        <li className={`${styles.liStyle}`} data-filter-type='Lib' data-value='Carousal' onClick={handleSelection}>
          Carousal
        </li>
        <li className={`${styles.liStyle}`} data-filter-type='Lib' data-value='StocksPerformance' onClick={handleSelection}>
          Stocks Performance
        </li>
        <li className={`${styles.liStyle}`} data-filter-type='Lib' data-value='StockNewsList' onClick={handleSelection}>
          StockNewsList
        </li>
        <li className={`${styles.liStyle}`} data-filter-type='Lib' data-value='ColorPalette' onClick={handleSelection}>
          ColorPalette
        </li>
        <li className={`${styles.liStyle}`} data-filter-type='Lib' data-value='Loader' onClick={handleSelection}>
          Loader
        </li>
        <li className={`${styles.liStyle}`} data-filter-type='Lib' data-value='MarketHighlightsNews' onClick={handleSelection}>
          MarketHighlightsNews
        </li>
      </div>
    )
  }

  const handleSelection = (e) => {
    e.preventDefault()
    if (e.currentTarget.getAttribute('data-filter-type') === 'Lib') {
      setActiveSelectedTab(e.currentTarget.getAttribute('data-value'))
    }
  }

  return (
    <div className={`${styles.testPageContainer}`}>
      <div className={`${styles.itemList}`}>
        <ul className={`${styles.ulStyle}`} >
          {renderItems()}
        </ul>
      </div>
      <div className={`${styles.itemDetail}`}>
        {(activeSelectedTab === 'TabControl') && <TabControl />}
        {(activeSelectedTab === 'ToggleControl') && <ToggleControl />}
        {
          (activeSelectedTab === 'ModalPopup') && <ModalPopUp isOpen>
            hello modal popup, press escape key to close
          </ModalPopUp>
        }
        {(activeSelectedTab === 'Dropdown') && <BasicDropDown data={['Apple', 'Orange', 'Lemon']} selectedValue={'Lemon'} changeHandler={() => {}} onlyBottomBorder />}
        {(activeSelectedTab === 'Checkbox') && <CheckBox />}
        {(activeSelectedTab === 'Carousal') && <Carousal style={{width: '500px'}} id='test'>
          <div className={styles.textCtr}>
            Slide 1 Slide 1 Slide 1 Slide 1 Slide 1 Slide 1 Slide 1 Slide 1 Slide 1 Slide 1
            Slide 1 Slide 1 Slide 1 Slide 1 Slide 1 Slide 1 Slide 1 Slide 1 Slide 1 Slide 1
            Slide 1 Slide 1 Slide 1 Slide 1 Slide 1 Slide 1 Slide 1
          </div>
          <div className={styles.textCtr}>
            Slide 2 Slide 2 Slide 2 Slide 2 Slide 2 Slide 2 Slide 2 Slide 2 Slide 2 Slide 2
            Slide 2 Slide 2 Slide 2 Slide 2 Slide 2 Slide 2 Slide 2 Slide 2 Slide 2 Slide 2
            Slide 2 Slide 2 Slide 2 Slide 2 Slide 2 Slide 2 Slide 2
          </div>
          <div className={styles.textCtr}>
            Slide 3 Slide 3 Slide 3 Slide 3 Slide 3 Slide 3 Slide 3 Slide 3 Slide 3 Slide 3
            Slide 3 Slide 3 Slide 3 Slide 3 Slide 3 Slide 3 Slide 3 Slide 3 Slide 3 Slide 3
            Slide 3 Slide 3 Slide 3 Slide 3 Slide 3 Slide 3 Slide 3
          </div>
        </Carousal>}
        {(activeSelectedTab === 'StocksPerformance') &&
          <div style={{width: '800px'}}>
            <Performance symbol='INFO' />
          </div>
        }
        {
          (activeSelectedTab === 'StockNewsList') &&
          <div style={{width: '800px'}}>
            <StockNewsList symbol='MSFT' />
          </div>
        }
        {
          (activeSelectedTab === 'ColorPalette') &&
          <ColorPalette colors={COLOR_DEFAULT_SET} selectedValue={''} onSelection={() => {}} />
        }
        {
          (activeSelectedTab === 'Loader') &&
          <Loader spinnerSize={'2x'} />
        }
        {
          (activeSelectedTab === 'MarketHighlightsNews') &&
          <MarketHighlightsNews />
        }
      </div>
    </div>
  )
}
export default ComponentTestPage
