import React from 'react'
import PropTypes from 'prop-types'
import styles from './RadioButton.module.scss'

export default function RadioButton ({label, isChecked, onChange, tabIndex, name, isScreenerFilterRadio, onKeyDown, id, isSelected}) {
  let customClass = isScreenerFilterRadio ? `${styles.radioButtonContainer} ${styles.screenerFilterRadio}` : `${styles.radioButtonContainer}`

  const handleOnChange = (e) => {
    onChange(e)
  }

  return (
    <div className={customClass}>
      <div className={styles.radio}>
        <input id={id} data-testid={label} name={name} type='radio' checked={isChecked === true} onKeyDown={onKeyDown} onChange={(e) => handleOnChange(e)} tabIndex={tabIndex} />
        <label htmlFor={id} className={styles.radiolabel}>{label}</label>
      </div>
    </div>
  )
}
RadioButton.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  tabIndex: PropTypes.number,
  name: PropTypes.string,
  isScreenerFilterRadio: PropTypes.bool,
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  onKeyDown: PropTypes.func
}
