import React from 'react'
import styles from './Divider.module.scss'
import PropTypes from 'prop-types'

function Divider (props) {
  return (
    <hr className={styles.divider} style={props.style} />
  )
}

export default Divider

Divider.propTypes = {
  style: PropTypes.object
}
