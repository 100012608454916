'use strict'

import { useState, useEffect } from 'react'
import DataRequest from '../services/DataRequest'
import { URLS } from '../utils/appConstants'

export const useXref = (symbol) => {
  const [symbolForXref, setSymbol] = useState(symbol)
  const [venueXid, setVenueXid] = useState(null)
  const [entityXid, setEntityXid] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(false)

  useEffect(() => {
    callData(symbolForXref)
  }, [symbolForXref])

  const updateSymbol = (updatedSymbol) => {
    setSymbol(updatedSymbol)
  }

  const callData = (symbolParam) => {
    setLoading(true)
    setError(false)
    setVenueXid(0)
    let xrefCallback = (response) => {
      if (response.isError) {
        setError(true)
      }
      if (response.data) {
        const responseData = response.data && response.data.xids
        if (responseData.venue) {
          setVenueXid(responseData.venue)
          setError(false)
        }
        if (responseData.entity) {
          setEntityXid(responseData.entity)
          setError(false)
        }
      }
      setLoading(false)
    }
    DataRequest.execute(
      `${URLS.PLATFORM.XREFGETSYMBOL}/${symbolParam}`,
      {
        params: {
          isCaseSensitive: false
        }
      },
      xrefCallback,
      null
    )
  }

  return {
    venueXid,
    entityXid,
    updateSymbol,
    callData,
    isLoading,
    isError
  }
}
