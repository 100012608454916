import React from 'react'
import styles from './Button.module.scss'
import PropTypes from 'prop-types'

const Button = (props) => {
  const handleFocus = (event) => {
    if (event.key === 'Tab' && props.closeBtn && props.cancelBtn) {
      event.preventDefault()
      event.shiftKey ? props.cancelBtn[0].focus() : props.closeBtn.focus()
    } else if (event.key === 'Enter' && event.keyCode === 13) {
      props.onKeyDownEnter(event)
    }
  }
  return (<button
    type='button'
    tabIndex={props.tabIndex}
    className={`${styles.custombutton} ${props.className}`}
    title={props.labelText}
    data-testid={props.buttonDataTestId}
    onClick={props.clickHandler}
    onKeyDown={handleFocus}
    disabled={props.disabled}>
    {props.labelText}
  </button>)
}
Button.propTypes = {
  clickHandler: PropTypes.func,
  className: PropTypes.string,
  labelText: PropTypes.string,
  tabIndex: PropTypes.string,
  buttonDataTestId: PropTypes.string,
  onKeyDownEnter: PropTypes.func,
  disabled: PropTypes.bool,
  closeBtn: PropTypes.any,
  cancelBtn: PropTypes.any
}
export default Button
