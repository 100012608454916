import React from 'react'
import PropTypes from 'prop-types'
import Table from '../../Lib/Table/Table'
import CheckBox from '../../Lib/CheckBox/CheckBox'
import styles from './WatchlistTable.module.scss'

function WatchlistTable ({watchlists, handleWatchlistSelection, isOption}) {
  const headers = [
    { name: 'Name', textAlign: 'left', seprator: true, hideInMobile: false },
    { name: 'Description', textAlign: 'left', seprator: false, hideInMobile: false }
  ]

  const renderWatchlistTable = () => {
    return watchlists.map(item => {
      return {Name: <div>
        <CheckBox id={item.id} label={item.name} value={item.id} onCheck={handleWatchlistSelection} />
        {item.hasSymbol && <span className={styles.alreadyExistsError}>{isOption ? 'Option' : 'Symbol'} already exists</span>}</div>,
      Description: (item['description'] || '')}
    })
  }
  return (
    <div className={styles.watchlistTableContainer}>
      <Table headers={headers} data={renderWatchlistTable()} />
    </div>
  )
}

export default WatchlistTable

WatchlistTable.propTypes = {
  watchlists: PropTypes.array,
  handleWatchlistSelection: PropTypes.func,
  isOption: PropTypes.bool
}
