/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './Tag.module.scss'
import DynamicPopOver from '../DynamicPopOver/DynamicPopOver'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const Tag = (props) => {
  document.addEventListener('click', handleClickOutside)
  const [isBottomPopoverOpen, setIsBottomPopoverOpen] = useState(0)
  const renderChildren = props.moreChildren && Object.keys(props.moreChildren).length > 0 ? (
    <div className={'dynamicPopOverFilter'}><DynamicPopOver remainingValues={props.moreChildren} onClearClick={props.onClear} /></div>
  ) : null
  function toggleBottomPopover (e) {
    e && e.stopPropagation()
    if (props.moreChildren) {
      setIsBottomPopoverOpen(!isBottomPopoverOpen)
    }
  }
  function handleEnterClick (e) {
    if (e.key === 'Enter') {
      toggleBottomPopover(e)
    }
  }
  function handleClickOutside (e) {
    if (isBottomPopoverOpen && !e.target.closest('div.dynamicPopOverFilter') && !e.target.closest(`div.${styles.moreTagFilter}`)) {
      setIsBottomPopoverOpen(!isBottomPopoverOpen)
    }
  }
  const spanElement = !props.moreChildren ? (<span title={`Remove ${props.value}`} role='button' aria-label={`Remove ${props.value}`} tabIndex={'0'} className={styles.crossButtonTag}
    tag={props.filterType} onClick={props.onClear} onKeyPress={props.onClear} value={props.value}><FontAwesomeIcon icon={['fal', 'times']} /></span>) : null
  return (
    <div tabIndex={props.moreChildren ? '0' : '-1'} className={`${styles.tag} ${props.moreChildren ? styles.moreTagFilter : ''}`} key={props.idx} onClick={toggleBottomPopover} onKeyDown={handleEnterClick} style={props.style}>
      <span className={styles.formatTag} title={props.value}>{props.name}</span>
      {spanElement}
      { isBottomPopoverOpen ? renderChildren : null }
    </div>
  )
}
export default Tag

Tag.propTypes = {
  name: PropTypes.string,
  onClear: PropTypes.func,
  filterType: PropTypes.string,
  idx: PropTypes.number,
  moreChildren: PropTypes.object,
  value: PropTypes.string,
  style: PropTypes.object
}
