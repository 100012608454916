import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './EsgAUMRiskScore.module.scss'
import Loader from '../../../../../Lib/common/Loader'
import { handleWindowResize, getDeviceType, deviceType } from '../../../../../../utils/utilities'
import ESGScoreTop from '../../../../../Stocks/ESGScores/ESGCommon/ESGScoreTop'
import EsgAUMRiskScoreChart from './EsgAUMRiskScoreChart'
import Nodata from '../../../../../Lib/NoData/NoData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { COLOR_DEFAULT_SET } from '../../../../../../utils/appConstants'
// import { getAnalystRatings } from '../../Shared/CompareProgressBar/ESGCommonMethods'
import {AUMCoveredESG, AUMCoveredControversy, AUMHighControveries} from '../../utils/appConstants'

export default function EsgAUMRiskScore ({xid, isNXI=false, analystRatingsData={}}) {
  const [topData, setTopData] = useState({})
  const [chartData, setChartData] = useState({})
  const [isReady, setIsReady] = useState(false || !xid)
  const [device, setDeviceType] = useState(getDeviceType())

  useEffect(() => {
    handleWindowResize((response) => {
      setDeviceType(response)
    })
  }, [])
  useEffect(() => {
    analystRatingsData && Object.keys(analystRatingsData).length > 0 && getData()
  }, [analystRatingsData])

  const checkData = (data) => {
    if (data && data.fund && data.fund.rating && data.fund.score && data.category && data.category.score && data.category.score.coverage) {
      return true
    } else {
      return false
    }
  }

  const getData = () => {
      setIsReady(false)
      if (!analystRatingsData?.isError) {
        let data = analystRatingsData?.data;
        if (checkData(data)) {
          let topDataObj = {
            value1: data.fund.rating.coverage.corporate.percentAUMPortfolioCovered,
            value2: data.fund.rating.coverage.sovereign.percentAUMPortCovered,
            value3: data.fund.controversy.percentAUMBreakdown.highServereControversies
          }
          setTopData(topDataObj)
          let chartDataObj = data.fund.score.percentAUMCovered
          setChartData(chartDataObj)
        }
      } else {
        setTopData({})
        setChartData({})
      }
      setIsReady(true)
  }

  const getHeaders = () => {
    return {
      header1: {
        header: device === deviceType.Mobile ? '% of AUM Covered ESG' : 'Corporate % of AUM Covered',
        headerDesc: AUMCoveredESG
      },
      header2: {
        header: device === deviceType.Mobile ? '% of AUM Covered Controversy' : 'Sovereign % of AUM Covered',
        headerDesc: AUMCoveredControversy
      },
      header3: {
        header: '% of AUM with High/Severe Controveries',
        headerDesc: AUMHighControveries
      }
    }
  }

  return (
    <Loader ready={isReady} spinnerSize='2x' >
      <div className={isNXI? styles.NXIAUMRiskScoreContainer : styles.AUMRiskScoreContainer}>
        <div className={styles.header}>ESG AUM Risk Score</div>
        {(Object.keys(topData).length > 0 && Object.keys(chartData).length)
          ?!isNXI? (<div>
            <ESGScoreTop data={topData} headers={getHeaders()} formatOptions={{postFix: true, postFixValue: '%'}} />
            <div className={styles.types}>
              <div className={styles.type}>
                <FontAwesomeIcon icon={'circle'} style={{color: COLOR_DEFAULT_SET[0].value}} className={styles.icon} />
              Corporate
              </div>
              <div className={styles.type}>
                <FontAwesomeIcon icon={'circle'} style={{color: COLOR_DEFAULT_SET[1].value}} className={styles.icon} />
              Sovereign
              </div>
            </div>
            <EsgAUMRiskScoreChart data={chartData} />
          </div>)
          : (
            <div>
              <ESGScoreTop
                data={topData}
                headers={getHeaders()}
                formatOptions={{ postFix: true, postFixValue: "%" }}
                isNXI
              />
              <EsgAUMRiskScoreChart data={chartData} isNXI={isNXI} />
              <div className={styles.lowerSection}>
                <span>Key</span>
                <div className={styles.categoryLabels}>
                  <div className={styles.chartLabels}>
                    <div
                      className={styles.circle}
                      style={{ background: "#004480" }}
                    />
                    Corporate
                  </div>
                  <div className={styles.chartLabels}>
                    <div
                      className={styles.circle}
                      style={{ background: "#58595A" }}
                    />
                    Sovereign
                  </div>
                </div>
              </div>
            </div>
          )
          : <Nodata />
        }
      </div>
    </Loader>
  )
}

EsgAUMRiskScore.propTypes = {
  xid: PropTypes.number.isRequired,
  isNXI: PropTypes.bool,
analystRatingsData: PropTypes.object
}
