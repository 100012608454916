import React from 'react'
import PropTypes from 'prop-types'
import styles from './CompareSearchResult.module.scss'
import { keyEvents } from '../../Helpers/accessibilityUtilities'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
const CompareSearchResultRows = (props) => {
  const modelOperators = () => {
    return (props.item.searchType === 'ModL' || props.item.searchType === 'SMAL' || props.item.searchType === 'MOD' || props.item.searchType === 'SMA')
  }

  const isLocked = (value) => {
    const lockedList = ['ModL', 'SMAL']
    return lockedList.includes(value)
  }
  // var isModel = (value) => {
  //   const modelList = ['ModL', 'SMAL', 'SMA', 'MOD']
  //   return modelList.includes(value)
  // }
  const toolTipText = (value) => {
    var toolTip = ''
    switch (value) {
      case 'SMA':
        toolTip = 'Seperately Managed Account'
        break
      case 'MOD':
        toolTip = 'Model'
        break
      case 'SMAL':
        toolTip = 'Restricted Access Model'
        break
      case 'ModL':
        toolTip = 'Restricted Access Model'
        break
      case 'MF':
        toolTip = 'Mutual Fund'
        break
      case 'AI':
        toolTip = 'AI'
        break
      case 'ETF':
        toolTip = 'ETF'
    }
    return toolTip !== '' ? <div className={styles.tooltiptextIcon}>{toolTip}</div> : ''
  }

  const replaceLock = (type, value) => {
    var newValue = ''
    switch (type) {
      case 'SMAL':
        newValue = 'SM'
        break
      case 'ModL':
        newValue = 'M'
        break
      case 'MF':
        newValue = 'MF'
        break
      case 'ETF':
        newValue = 'ETF'
        break
      case 'AI':
        newValue = 'AI'
        break
      case 'EQ':
        newValue = 'EQ'
        break
      case 'MOD':
        newValue = 'MOD'
        break
      default:
        newValue = value
        break
    }
    return newValue
  }

  let isSymbolAvailable = props.item.Symbol && props.item.Symbol.length > 0
  return (
    <div className={`${styles.bottomBorder} ${props.index === 0 ? styles.topBorder : ''}`}>
      <div id={`results${props.index}`} key={props.index} tabIndex='0' data-individual='1' role='button' aria-label={`Name: ${props.item.Name}, Type: ${props.item.searchType} `} className={modelOperators() ? `${styles.resultsMOD} ${props.index === 0 ? styles.topBorder : ''}` : `${styles.results}`} >
        <div className={styles.resultsRow}>
          <div id={`productIcon${props.item.searchType}${props.index}`}
            className={`${styles[`productIcon` + props.item.searchType]} 
            ${isSymbolAvailable && styles['adjustPositionLock']}`}>
            <div className={styles.productIconText}>{replaceLock(props.item.searchType, props.item.Type)}{isLocked(props.item.searchType) ? <div className={styles.lockIcon}><FontAwesomeIcon icon={['fas', 'lock']} /></div> : ''}{toolTipText(props.item.searchType)}</div>
          </div>
          <div className={styles.investMentInfoConatiner}>
            {isSymbolAvailable && <div id={`resultsSymbol${props.index}`} className={styles.investmentSymbol} tabIndex='0' data-individual='1' role='button' onKeyDown={(e) => keyEvents(e)} onClick={(e) => props.searchTableResult(props.item)}>  {props.item.Symbol} </div>}
            <div className={styles.investmentName} tabIndex='0' data-individual='1' role='button' onKeyDown={(e) => keyEvents(e)} onClick={(e) => props.searchTableResult(props.item)}>  {props.item.Name} </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CompareSearchResultRows.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  searchTableResult: PropTypes.func
}

export default CompareSearchResultRows
