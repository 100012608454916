import React from 'react'
import ShowMoreText from 'react-show-more-text'
import Tooltip from '../../Overlay/ToolTip'
import styles from '../QuoteCardWithStockHighlights.module.scss'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import StockHighlightsItem from '../../../StockHighlights/StockHighlightsItem/StockHighlightsItem'
import { emitF2Event } from '../../../../../../../utils/f2Methods'
import { EVENT_NAMES } from '../../../../../../../utils/f2Constants'
import { MOD_GOTO_PAGES, PROFILE, PRICECOLOR } from '../../../utils/appConstants'
import { formatNumber } from '../../../utils/formatter'

const ModelQuoteCard = (props) => {
  let positive = { color: PRICECOLOR.GREEN, padding: '0', width: 'auto' }
  let negative = { color: PRICECOLOR.RED, padding: '0', width: 'auto' }
  let keyStyle = { padding: '0', 'lineHeight': '16px', width: 'auto' }
  let style = { padding: '0', 'lineHeight': '16px', width: '90%' }
  let categoryStyle = { padding: '0', 'lineHeight': '16px', 'whiteSpace': 'break-spaces' }
  const fromSimilarModels = props.data && props.data.moduleName === 'SimilarModels'
  const percentFormatter = {negSign: true, posSign: true, precision: 2, parentheses: false, postFix: true}
  function overlayKeyHandler (venueXid) {
    props.overlayKeyHandler(venueXid)
  }
  function viewProfile () {
    let dataEmit = {
      gotoPage: MOD_GOTO_PAGES.model,
      symbol: props.name,
      activeTabKey: PROFILE
    }

    emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, dataEmit)
  }
  function getCardStyle () {
    let style = {display: ''}
    if (fromSimilarModels) {
      return style
    }
    if (document.documentElement.scrollTop > 0) {
      style = {display: 'none'}
    }
    return style
  }
  function getColorStyle (value) {
    let style = {}

    if (value > 0) {
      style = positive
    } else if (value < 0) {
      style = negative
    } else if (fromSimilarModels) {
      style = {width: 'auto'}
    }
    return style
  }
  const isLocked = props.data && props.data.isLocked
  return (
    <React.Fragment>
      <div className={props.type === 'SMAL' ? styles.quoteLckCardHeader : props.type === 'ModL' ? styles.quoteLckCardHeader : styles.quoteCardHeader}>
        <div className={styles.toolTip}>
          <Tooltip id={props.id} type={props.type} moduleTypeName={props.data && props.data.moduleTypeName} data={{...props}} overlayKeyHandler={(venueXid) => overlayKeyHandler(venueXid)} />
        </div>
        <div>
          <div className={styles.quoteCardHeaderMain} id='modelName' role='button' tabIndex='0' onClick={viewProfile} onKeyDown={viewProfile}>
            <ShowMoreText
              lines={2}
              more=''
              less=''
              expanded={false}>
              {props.name}
            </ShowMoreText>
          </div>
          {(() => {
            switch (props.type) {
              case 'MOC':
                return (
                  <div role='button' id={'button-MOC' + props.id} name='MOC' className={styles.MOC} tabIndex='0'>{ isLocked && <FontAwesomeIcon icon={['fas', 'lock']} /> }{props.data && props.data.strategyType}{ isLocked && <span className={styles.tooltiptext}>Restricted Access Model</span> }</div>
                )
              case 'TPMOC':
                return (
                  <div role='button' id={'button-TPMOC' + props.id} name='TPMOC' className={styles.MOC} tabIndex='0'>{ isLocked && <FontAwesomeIcon icon={['fas', 'lock']} /> }{props.data && props.data.strategyType}{ isLocked && <span className={styles.tooltiptext}>Restricted Access Model</span> }</div>
                )
            }
          })()}
        </div>
        {(() => {
          switch (props.type) {
            case 'MOC':
              return (
                <div style={getCardStyle()} name='hideScroll' className={`${styles.stockHighlightIPadDivLeft} ${styles.stockHighlightIPadDivLeftOverideForCompare}`} id={'hideScroll_MOC' + props.id} >
                  {!fromSimilarModels && <StockHighlightsItem label={'Category'} value={props.data && props.data.category} keyStyling={categoryStyle} />}
                  <StockHighlightsItem label={'Fees(bps)'} value={(props.data && props.data.feesBPS) ? props.data.feesBPS : '--'} keyStyling={keyStyle} />
                  <StockHighlightsItem label={'3 Yr Return(Annualized)'} value={(props.data && props.data.return) ? formatNumber(props.data.return, percentFormatter) : '--'} valueStyling={getColorStyle(props.data && props.data.return)} keyStyling={fromSimilarModels ? style : keyStyle} />
                  <StockHighlightsItem label={'3 Yr Std. Dev.'} value={(props.data && props.data.stdDev) ? props.data.stdDev : '--'} keyStyling={keyStyle} />
                  <StockHighlightsItem label={'3 Yr Beta'} value={(props.data && props.data.beta) ? props.data.beta : '--'} keyStyling={keyStyle} />
                </div>
              )
            case 'TPMOC':
              return (
                <div style={getCardStyle()} name='hideScroll' className={`${styles.stockHighlightIPadDivLeft} ${styles.stockHighlightIPadDivLeftOverideForCompare}`} id={'hideScroll_TPMOC' + props.id} >
                  {!fromSimilarModels && <StockHighlightsItem keyStyling={categoryStyle} label={'Category'} value={props.data && props.data.category} />}
                  <StockHighlightsItem keyStyling={keyStyle} label={'Fees(bps)'} value={(props.data && props.data.feesBPS) ? props.data.feesBPS : '--'} />
                  <StockHighlightsItem label={'3 Yr Return(Annualized)'} value={(props.data && props.data.return) ? formatNumber(props.data.return, percentFormatter) : '--'} valueStyling={getColorStyle(props.data && props.data.return)} keyStyling={fromSimilarModels ? style : keyStyle} />
                  <StockHighlightsItem keyStyling={keyStyle} label={'3 Yr Std. Dev.'} value={(props.data && props.data.stdDev) ? props.data.stdDev : '--'} />
                  <StockHighlightsItem keyStyling={keyStyle} label={'3 Yr Beta'} value={(props.data && props.data.beta) ? props.data.beta : '--'} />
                </div>
              )
            case 'ModL':
              return (
                <div>
                  <div className={styles.quoteCardHeaderMain} role='button' tabIndex='0'>
                    {props.name}
                  </div>
                  <div id={'button-ModL' + props.id} name='ModL' className={styles.Modellocked} aria-label={'Model Marketplace'}><FontAwesomeIcon icon={['fas', 'lock']} /> Model Marketplace<span className={styles.tooltiptext}>Restricted Access Model</span></div>
                </div>
              )
            case 'AI':
              return (
                <div>
                  <div className={styles.quoteCardHeaderDesc} role='button' tabIndex='0'>
                    {props.name}
                  </div>
                </div>
              )
            case 'AIN':
              return (
                <div>
                  <div className={styles.quoteCardHeaderDesc} role='button' tabIndex='0'>
                    {props.name}
                  </div>
                  <div className={styles.altInvPrice} aria-label={`Price: ${props.quoteData.price}`}> {props.quoteData.price} </div>
                </div>
              )
            case 'SMA':
              return (
                <div id={'button-SMA' + props.id} name='SMA' className={styles.SMA} aria-label={'SMA'}>SMA</div>
              )
            case 'SMAL':
              return (
                <div>
                  <div id={'button-SMAL' + props.id} name='SMAL' className={styles.SMAL} aria-label={'SMA'}><FontAwesomeIcon icon={['fas', 'lock']} /> SMA<span className={styles.tooltiptext}>Restricted Access Model</span></div>
                </div>
              )
          }
        })()}
      </div>
      {(() => {
        switch (props.type) {
          case 'ModL':
            return (
              <div className={styles.circleIcon}> <FontAwesomeIcon icon={['fal', 'info-circle']} />  Request Access from home office </div>
            )
          case 'SMAL':
            return (
              <div className={styles.circleIcon}> <FontAwesomeIcon icon={['fal', 'info-circle']} />  Request Access from home office </div>
            )
        }
      })()}
    </React.Fragment>
  )
}

ModelQuoteCard.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  quoteData: PropTypes.object,
  id: PropTypes.string,
  overlayKeyHandler: PropTypes.func,
  data: PropTypes.object,
  moduleTypeName: PropTypes.string
}

export default ModelQuoteCard
