import React, { useState, useEffect } from 'react'
import styles from './EquityAndFixedIncome.module.scss'
import { URLS, DATE_FORMAT } from '../../utils/appConstants'
import Loader from '../../../../../Lib/common/Loader'
import StyleBox from '../../Shared/StyleBox/StyleBox'
import { formatDate } from '../../utils/formatter'
import PropTypes from 'prop-types'
import { deviceType, getDeviceType, handleWindowResize } from '../../../../../../utils/utilities'
import { getAPIData } from '../../utils/utilities'

const EquityAndFixedIncome = ({ xid, showStyleBoxHandle }) => {
  const DEFAULT_STATE = {
    equity: {
      title: 'Equity Style Box',
      value: 0,
      isEquity: true,
      datePortfolio: null
    },
    bond: {
      title: 'Fixed Income Style Box',
      value: 0,
      isEquity: false,
      datePortfolio: null
    }
  }

  const [portfolioStats, setPortfolioStats] = useState(DEFAULT_STATE)
  const [device, setDevice] = useState(getDeviceType())
  const [isLoadingAndError, setIsLoadingAndError] = useState({ isLoading: true, isError: false })
  const spinnerStyle = { 'minWidth': 'auto', 'height': 'auto' }

  function resetStates () {
    setPortfolioStats(DEFAULT_STATE)
    setIsLoadingAndError({ isLoading: true, isError: false })
  }
  useEffect(() => {
    // Whenever fund changes - resets states of each sub-component
    resetStates()
    getPortfolioStats()
  }, [xid])

  useEffect(() => {
    handleWindowResize((response) => { setDevice(response) })
  }, [])

  const getPortfolioStats = () => {
    let callback = (response) => {
      let isbothStyleBoxAvailable = false
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        const responseData = response.data
        let datePortfolio = responseData.datePortfolio
        let styleBoxForEquity = responseData.styleBox.equity || {}
        let styleBoxForBond = responseData.styleBox.bond || {}
        isbothStyleBoxAvailable = !!(!styleBoxForEquity && !styleBoxForBond)
        if (isbothStyleBoxAvailable) {
          setIsLoadingAndError({ isError: false, isLoading: false })
          showStyleBoxHandle(isbothStyleBoxAvailable)
        }

        if (!isbothStyleBoxAvailable && datePortfolio && (styleBoxForEquity || styleBoxForBond)) {
          setPortfolioStats(...portfolioStats,
            {
              equity: {
                ...portfolioStats.equity,
                datePortfolio: datePortfolio,
                isEquity: true,
                value: (Object.keys(styleBoxForEquity).length !== 0) ? styleBoxForEquity.long.value : 0
              },
              bond: {
                ...portfolioStats.bond,
                datePortfolio: datePortfolio,
                isEquity: false,
                value: (Object.keys(styleBoxForBond).length !== 0) ? styleBoxForBond.long.value : 0
              }
            })
          setIsLoadingAndError({ isError: false, isLoading: false })
          showStyleBoxHandle(true)
        } else {
          setIsLoadingAndError({ isError: true, isLoading: false })
          showStyleBoxHandle(false)
        }
      } else {
        setIsLoadingAndError({ isError: true, isLoading: false })
        showStyleBoxHandle(false)
      }
    }

    getAPIData(xid, URLS.PLATFORM.MANAGEDFUNDS_MORNINGSTAR_PORTFOLIO_STATS, callback, `isCaseSensitive: false`, true)
  }

  const getStyleBoxContainerStyle = (type) => {
    if (device === deviceType.Desktop || device === deviceType.Mobile) {
      return portfolioStats[type].isEquity ? styles.styleBoxContainer : styles.fixedIncomeStyleBox
    } else {
      return styles.styleBoxContainer
    }
  }

  if (isLoadingAndError.isLoading) {
    return (
      <div className={styles.styleBoxContainer}>
        <div className={styles.styleBoxHeader} id='styleBoxHeader' >Equity Style Box</div>
        <Loader spinnerStyle={spinnerStyle} ready={false} msg={'Loading'} spinnerSize={'2x'} />
      </div>
    )
  }

  return (
    Object.keys(portfolioStats).map((type) => {
      if ((Object.keys(portfolioStats[type]).length !== 0) && portfolioStats[type].value === 0) return

      return (
        <div key={type} className={getStyleBoxContainerStyle(type)}>
          <div className={styles.styleBoxHeader} id='styleBoxHeader' >{portfolioStats[type].title}</div>
          <React.Fragment>
            <StyleBox stats={portfolioStats[type].value} isEquity={portfolioStats[type].isEquity} />
            <div className={styles.styleBoxBottomDetail}>
              <span>As of {formatDate(new Date(portfolioStats[type].datePortfolio), { format: DATE_FORMAT.MONTH_DAY_YEAR_DATE })}</span>
            </div>
          </React.Fragment>
        </div >

      )
    })
  )
}

EquityAndFixedIncome.propTypes = {
  xid: PropTypes.any,
  showStyleBoxHandle: PropTypes.func
}
export default EquityAndFixedIncome
