import React, {useState, useEffect} from 'react'
import styles from '../RangeSelector/RangeSelector.module.scss'
import PropTypes from 'prop-types'
import RadioButton from '../RadioButton/RadioButton'
import Calendar from '../Calendar/Calendar'
import { addDays } from 'date-fns'
const todayDate = new Date()
const PureRadioButton = props => {
  const [selectedRadioBtn, setSelectedRadioBtn] = useState(props.item.selectedValue)
  let isScreenerFilterRadio = true
  // let focusElem = useRef()
  useEffect(() => {
    setSelectedRadioBtn(props.item.selectedValue)
  })

  let handleOnButtonClicked = (e, item, selectedParent, handleClick) => {
    // Changes for PRW-999
    switch (e) {
      case 'Last Week':
        item.startDate = addDays(todayDate, -7)
        item.endDate = todayDate
        item.isCalender = false
        break
      case 'Last Month':
        item.startDate = addDays(todayDate, -30)
        item.endDate = todayDate
        item.isCalender = false
        break
      case 'Last Quarter':
        item.startDate = addDays(todayDate, -90)
        item.endDate = todayDate
        item.isCalender = false
        break
      case 'Custom':
        item.isCalender = true
        break
      case 'Within Last Year (last 365 days)':
        item.startDate = addDays(todayDate, -365)
        item.endDate = todayDate
        item.isCalender = false
        break
      case 'In Last 3 Years':
        item.startDate = addDays(todayDate, -(365 * 3))
        item.endDate = todayDate
        item.isCalender = false
        break
      case 'In Last 5 Years':
        item.startDate = addDays(todayDate, -(365 * 5))
        item.endDate = todayDate
        item.isCalender = false
        break
      case 'More Than 5 Years Ago':
        item.startDate = addDays(todayDate, -(365 * 50))
        item.endDate = todayDate
        item.isCalender = false
        break
    }

    item.isSelected = true
    item.selectedValue = e
    item.val = `${''}${e}`
    setSelectedRadioBtn(e)
    handleClick(e, item, selectedParent)
  }
  const keyPressHandlerRadio = (e, element) => {
    if (e.currentTarget.parentElement.parentElement.parentElement.previousElementSibling !== null) {
      if (e.keyCode === KEYCODE.UP) {
        let previousSibling = e.currentTarget.parentElement.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild.firstElementChild
        e.preventDefault()
        if (previousSibling) {
          previousSibling.focus()
        }
      }
    }
    if (e.currentTarget.parentElement.parentElement.parentElement.nextElementSibling !== null) {
      if (e.keyCode === KEYCODE.DOWN) {
        let nextSibling = e.currentTarget.parentElement.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild.firstElementChild
        e.preventDefault()
        if (nextSibling) {
          nextSibling.focus()
        }
      }
    }
    if (e.keyCode === KEYCODE.LEFT) {
      e.preventDefault()
      document.getElementById(`li-${props.selectedParent.parentVal}-${props.selectedParent.val}`)
        ? document.getElementById(`li-${props.selectedParent.parentVal}-${props.selectedParent.val}`).focus()
        : e.preventDefault()
    }
    if (e.keyCode === KEYCODE.RIGHT) {
      e.preventDefault()
    }
    if (e.keyCode === KEYCODE.SPACE || e.keyCode === KEYCODE.ENTER) {
      e.preventDefault()
      handleOnButtonClicked(element, props.item, props.selectedParent, props.handleClick)
    }
  }

  return <div className={styles.rangeSelector} id='radiobtnDiv' style={{paddingTop: '0px', paddingLeft: '0px'}}>
    {props.item.arrayList.map(element => {
      return <div key={`rdo-${props.selectedParent.parentVal}-${props.selectedParent.val}-${element}`} className={styles.radioListItem}>
        <RadioButton
          id={`rdo-${props.selectedParent.parentVal}-${props.selectedParent.val}-${element}`}
          label={element}
          isChecked={element === selectedRadioBtn}
          onKeyDown={(e) => keyPressHandlerRadio(e, element)}
          onChange={() => handleOnButtonClicked(element, props.item, props.selectedParent, props.handleClick)}
          name={props.selectedParent.val} isScreenerFilterRadio={isScreenerFilterRadio} />
        {element === 'Custom' && props.item.isSelected && props.item.isCalender ? <span className={styles.dateRangeCalendarContentContainer}>
          <Calendar
            selectDateRange onChange={props.onMultiDateChange}
            startDate={props.selectedDates.startDate}
            endDate={props.selectedDates.endDate}
            parentVal={props.item.parentVal}
          /></span> : ''}
      </div>
    })

    }
  </div>
}
PureRadioButton.propTypes = {
  item: PropTypes.object,
  handleClick: PropTypes.func,
  selectedParent: PropTypes.object,
  selectedDates: PropTypes.oneOfType([
    PropTypes.string, PropTypes.object]),
  onMultiDateChange: PropTypes.func
}
const KEYCODE = {
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
  TAB: 9,
  ENTER: 13,
  SPACE: 32
}
export default PureRadioButton
