import React, { Component } from 'react'
import PropTypes from 'prop-types'
import tabStyle from './Tabs.module.module.scss'
import classNames from 'classnames'

class Tabs extends Component {
  references = {}
  constructor (props) {
    super(props)
    this.state = {
      activeTab: this.props.activeTab || this.props.children || this.props.children[0].props.label,
      numberOfTabs: this.props.children.length,
      navigationCursor: 0
    }
    this.references = Array(this.props.children.length)
      .fill()
      .map(() => React.createRef())
  }

  onClickTabItem = tab => {
    this.setState({ activeTab: tab })
    this.props.onClick && this.props.onClick(tab)
  }

  onKeyPressHandler = (tab, e) => {
    if (e.charCode === 13) {
      e.preventDefault()
      this.onClickTabItem(tab)
    }
  }
  handleKeyDown = e => {
    let newNavigationCursor = this.state.navigationCursor
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 || e.keyCode === 37) {
      if (newNavigationCursor === 0) {
        newNavigationCursor = this.state.numberOfTabs - 1
      } else {
        newNavigationCursor--
      }
      this.setState({ navigationCursor: newNavigationCursor })
      this.focusById(newNavigationCursor)
    } else if (e.keyCode === 40 || e.keyCode === 39) {
      if (newNavigationCursor === this.state.numberOfTabs - 1) {
        newNavigationCursor = 0
      } else {
        newNavigationCursor++
      }
      this.setState({ navigationCursor: newNavigationCursor })
      this.focusById(newNavigationCursor)
    }
  }

  getOrCreateRef = id => {
    if (!this.references.hasOwnProperty(id)) {
      this.references[id] = React.createRef()
    }
    return this.references[id]
  }

  focusById = id => {
    this.references[id].current && this.references[id].current.focus()
  }
  renderTabs = activeTab => {
    return (
      <ul
        data-testid='lib_tab_test'
        id={this.props.id}
        role='tablist'
        aria-label={this.props.ariaLabel}
        className={`${tabStyle.tab_list} ${this.props.noBackground ? tabStyle.noBackground : ''} ${this.props.style ? this.props.style : ''}`}
        onKeyDown={e => this.handleKeyDown(e)}
      >
        {this.props.children.map((child, index) => {
          if (child) {
            const { label } = child.props
            return (
              <li
                ref={this.references[index]}
                className={classNames(tabStyle.tab_list_item, {
                  [tabStyle.tab_list_active]: activeTab === label
                })}
                title={label}
                tabIndex={0}
                role='tab'
                aria-selected={activeTab === label ? 'true' : 'false'}
                key={index}
                onClick={() => this.onClickTabItem(label)}
                onKeyPress={e => this.onKeyPressHandler(label, e)}
              >
                <span className={`${tabStyle.tab_text} `}>{label}</span>
              </li>
            )
          }
        })}
      </ul>
    )
  }

  render () {
    const {
      props: { children },
      state: { activeTab }
    } = this
    return (
      <div className={`${tabStyle.tabs}`}>
        {this.renderTabs(activeTab)}
        <div
          className={`${tabStyle.tab}`}
          role='tabpanel'
          tabIndex='0'
          aria-hidden='false'
          aria-live='polite'
        >
          {children.map(child => {
            if (typeof child !== 'boolean') {
              if (child.props.label !== activeTab) return undefined
              return child.props.children
            }
          })}
        </div>
      </div>
    )
  }
}
Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  activeTab: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  style: PropTypes.string,
  noBackground: PropTypes.bool
}

export default Tabs
