import { TYPE_OF_VALUE, URLS } from '../../../utils/appConstants'

export const WATCHLISTS_LANDING_CONSTANTS = {
  TITLE: 'Watchlists',
  ALL_WATCHLISTS_HEADER: 'All Watchlists',
  RECENTLY_VIEW_WATCHLIST: 'Recently Viewed',
  VIEW_ALL: 'View all',
  WATCHLIST_FUNDAMENTAL_FOOTER: '* International stocks represent delayed quote data.',
  CREATE_WATCHLIST_BUTTON_TITLE: '+ Create Watchlist',
  CREATE_WATCHLIST_BUTTON: 'Create Watchlist',
  SORT_BY_DATA: [
    'Alphabetical',
    'Favorited',
    'Recently Created'
  ],
  NEW_WATCHLIST_TEXT: 'Create a New Watchlist',
  MAX_LIMIT: 50,
  LIMIT_LARGE_CARDS: 11,
  EDIT_BUTTON: 'Edit',
  FAVORITE_BUTTON: 'Favorite',
  DELETE_BUTTON: 'Delete',
  ERROR_MESSAGE: 'A watchlist with the same name already exists. Please pick a different name.',
  DUPLICATE_MESSAGE_API: 'store the modified container',
  DELETE_ERROR_MESSAGE: 'An error occurred. Please try again later',
  DELETE_SUCCESS_MESSAGE: 'Watchlist has been deleted'
}

export const WATCHLISTS_STOCK_DETAILS = {
  PERFORMANCE: {
    PRCESION: 2,
    LABEL_DATA_MAP: [{
      label: 'Symbol',
      map: 'symbol',
      isExcludedFromPricing: true,
      isSortable: true,
      isDefaultSort: true
    },
    {
      label: 'Company',
      map: 'name',
      isExcludedFromPricing: true,
      isSortable: true
    },
    {
      label: '3 Mon Return',
      map: 'total13WeeksCumulative',
      type: TYPE_OF_VALUE.PERCENTAGE,
      isSortable: true,
      periodType: 'm',
      period: 3
    },
    {
      label: '6 Mon Return',
      map: 'total26WeeksCumulative',
      type: TYPE_OF_VALUE.PERCENTAGE,
      isSortable: true,
      periodType: 'm',
      period: 6
    },
    {
      label: '1 Yr Return',
      map: 'total1YearCumulative',
      type: TYPE_OF_VALUE.PERCENTAGE,
      isSortable: true,
      periodType: 'y',
      period: 1
    },
    {
      label: '5 Yr Return',
      map: 'total5YearsCumulative',
      type: TYPE_OF_VALUE.PERCENTAGE,
      isSortable: true,
      periodType: 'y',
      period: 5,
      style: {
        borderLeft: 'none'
      }
    },
    {
      label: 'YTD Return',
      map: 'totalYearToDateCumulative',
      type: TYPE_OF_VALUE.PERCENTAGE,
      isSortable: true,
      periodType: 'ytd'
    }
    ]
  },
  TECHNICALS: {
    PRCESION: 2,
    LABEL_DATA_MAP: [{
      label: 'Symbol',
      map: 'PEREQSymbol',
      isExcludedFromPricing: true,
      isSortable: true,
      isDefaultSort: true
    },
    {
      label: 'Company',
      map: 'PEREQCompanyName',
      isExcludedFromPricing: true,
      isSortable: true
    },
    {
      label: 'Beta vs S&P 500 - 3 Yr',
      map: 'PEREQBeta',
      type: TYPE_OF_VALUE.NUMBER,
      isSortable: true
    },
    {
      label: 'Std Deviation - 3 Yr',
      map: 'PEREQStdDev',
      type: TYPE_OF_VALUE.NUMBER,
      isSortable: true
    },
    {
      label: 'Last Volume',
      map: 'PEREQLastVolume',
      type: TYPE_OF_VALUE.NUMBER,
      isSortable: true,
      volume: true
    },
    {
      label: 'Volume - 90 Day Avg',
      map: 'PEREQVolume90dAvg',
      type: TYPE_OF_VALUE.NUMBER,
      isSortable: true,
      volume: true
    }
    ]
  },
  FUNDAMENTALS: {
    SPECIALCOLUMNS: ['lastPrice']
  }
}

export const RECENTLY_VIEWED_CARD_CONSTANTS = {
  EVENTS: {
    DATE_FORMAT: 'YYYY-MM-DD',
    EVENTS_TYPE: {
      'Earnings Events': {
        'apiUrl': URLS.PLATFORM.EARNINGEVENTSCOUNTBYDATE,
        'additionalParameters': {
          'eventName': 'Earnings Events'
        },
        isVenueXidRequired: true,
        map: 'date'
      },
      'Dividend Events': {
        'apiUrl': URLS.PLATFORM.DIVIDENDEVENTSBYDATE,
        'additionalParameters': {
          'eventName': 'Dividend Events'
        },
        isVenueXidRecieved: true,
        endDateNotSupported: true,
        map: 'exDivDate'
      },
      'Split Events': {
        'apiUrl': URLS.PLATFORM.SPLITSENTSBYDATE,
        'additionalParameters': {
          'eventName': 'Split Events',
          'includeSplits': true
        },
        map: 'exDate',
        isVenueXidRecieved: true
      }
    },
    EVENTS_THIS_WEEK_TITLE: 'Events This Week',
    EVENTS_UPCOMING_WEEK_TITLE: 'Upcoming Events'
  }
}
export const WATCHLIST_INSIGHTS_CONSTANTS = {
  TITLE: 'Watchlist Insights',
  TOP_NEWS_TITLE: 'Watchlist News',
  TOP_NEWS_CONSTANTS: {
    IPAD_SYMBOL_COUNT: 4,
    DESKTOP_SYMBOL_COUNT: 2
  }
}

export const WATCHLIST_ACTIONS = {
  VIEW_CHART: 'View Chart',
  ADD_NOTE: 'Add a Note',
  REMOVE_FROM_WATCHLIST: 'Remove from Watchlist'
}

export const WATCHLIST_ACTIONS_OPTIONS = {
  ADD_NOTE: 'Add a Note',
  REMOVE_FROM_WATCHLIST: 'Remove from Watchlist'
}

export const WATCHLIST_OPTION_DETAILS = {
  SPECIALCOLUMNS: ['lastPrice']
}

export const WATCHLIST_NOTE_CONSTANTS = {
  WATCHLIST_VIEW_NOTE: 'View Notes',
  WATCHLIST_NOTE_DELETED_MESSAGE: 'Note has been deleted.',
  WATCHLIST_NOTE_SAVED_MESSAGE: 'A note has been added.',
  WATCHLIST_NOTE_DELETE_CONFIRM_MESSAGE: 'Are you sure you want to delete your note?'
}

export const WATCHLIST_DETAILS_TAB_NAMES = {
  FUNDAMENTALS: 'Fundamentals',
  PERFORMANCE: 'Returns',
  RISK: 'Risk',
  RATINGS: 'Ratings',
  TECHNICALS: 'Tehnicals',
  DEFAULT: 'Default'
}

export const WATCHLIST_SORT_OBJECT_STORENAME = 'WATCHLIST_SORT_OBJECT'

const DEFAULT_SORT_OBJ = {
  colName: 'symbol',
  isAscending: true
}

export const WATCHLIST_DETAILS_HOLDINGS_TYPES = {
  EQUITY: 'Equity',
  FUNDS: 'Funds',
  OPTIONS: 'Options'
}

/**
 * Performance and Returns are same tab names and can be used interchangebly
*/
export const WATCHLIST_SORT_OBJECT = {
  [WATCHLIST_DETAILS_HOLDINGS_TYPES.EQUITY]: {
    [WATCHLIST_DETAILS_TAB_NAMES.FUNDAMENTALS]: getDefaultSortColumn(),
    [WATCHLIST_DETAILS_TAB_NAMES.PERFORMANCE]: getDefaultSortColumn(WATCHLISTS_STOCK_DETAILS.PERFORMANCE.LABEL_DATA_MAP),
    [WATCHLIST_DETAILS_TAB_NAMES.RISK]: getDefaultSortColumn(),
    [WATCHLIST_DETAILS_TAB_NAMES.RATINGS]: getDefaultSortColumn(),
    [WATCHLIST_DETAILS_TAB_NAMES.TECHNICALS]: getDefaultSortColumn(WATCHLISTS_STOCK_DETAILS.TECHNICALS.LABEL_DATA_MAP)
  },
  [WATCHLIST_DETAILS_HOLDINGS_TYPES.FUNDS]: {
    [WATCHLIST_DETAILS_TAB_NAMES.FUNDAMENTALS]: getDefaultSortColumn(),
    [WATCHLIST_DETAILS_TAB_NAMES.PERFORMANCE]: getDefaultSortColumn(WATCHLISTS_STOCK_DETAILS.PERFORMANCE.LABEL_DATA_MAP),
    [WATCHLIST_DETAILS_TAB_NAMES.RISK]: getDefaultSortColumn(),
    [WATCHLIST_DETAILS_TAB_NAMES.RATINGS]: getDefaultSortColumn()
  },
  [WATCHLIST_DETAILS_HOLDINGS_TYPES.OPTIONS]: {
    [WATCHLIST_DETAILS_TAB_NAMES.DEFAULT]: getDefaultSortColumn()
  }
}

function getDefaultSortColumn (defaultData) {
  if (defaultData) {
    return {
      colName: defaultData.find(e => e.isDefaultSort).label,
      isAscending: true
    }
  }
  return DEFAULT_SORT_OBJ
}
