/* eslint-disable no-debugger */
import React from 'react'
import PropTypes from 'prop-types'
import styles from './QuoteCardWithStockHighlights.module.scss'
import { URLS, ChartTimeFrames, FUND_SCREEN_TYPE, DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS, QuoteCardScreenerResultField, DATE_FORMAT } from '../../utils/appConstants'
import DataRequest from '../../../../../../services/DataRequest'
import Loader from '../../../../../Lib/common/Loader'
import NoData from '../../../../../Lib/NoData/NoData'
import FundQuoteCard from './ProductTypeCard/FundQuoteCardWithStockHighlights'
import EducationResourceFeatureCard from './ProductTypeCard/EducationResourceFeatureCard'
import ModelQuoteCard from './ProductTypeCard/ModelQuoteCardWithStockHighlights'
import { convertToInternationalCurrencySystem, formatNumber, formatDate } from '../../utils/formatter'
import AIProductCard from './ProductTypeCard/AIProductCard'
import AiQuoteCard from './ProductTypeCard/AiQuoteCardWithStockHIghlights'
import { isMMF } from '../../utils/utilities'
import { getQuoteData } from '../../../../../../shared/dataMethods'

const TEXT_NULL_VALUE = '--'
// To discard quote calls
const DATA_FROM_PARENT = ['AIN', 'AICOMPARE', 'MFSnapshot', 'AINSnapshot', 'TPMOC']
class QuoteCardWithStockHighlights extends React.Component {
  constructor (props) {
    super(props)
    this.venueXid = props.venueXid
    this.id = props.id
    this.style = props.style
    this.overlayKeyHandler = props.overlayKeyHandler
    this.state = {
      quoteData: {
        price: null,
        priceChange: null,
        priceChangePct: null,
        volume: null
      },
      xrefData: {
        symbol: null,
        name: null,
        exchange: null
      },
      screenerData: {
        netAssets: null,
        CUSIP: null,
        SPCFFundType: null,
        SPCFIsFVETF: null,
        SPCFNoTransactonFeeEligibilityIndicator: null,
        SPCFPerformanceAsOfDate: null
      },
      shareClass: {
        fundNetAssets: null
      },
      isLoading: true,
      isError: false,
      error: {}
    }
    this.bottomPopoverRef = React.createRef()
    this.spinnerStyle = props.spinnerStyle || {'minWidth': '220px', 'height': '395px'}
  }
  createCancelToken () { return DataRequest.getCancelToken() }
  componentDidMount () {
    if (!DATA_FROM_PARENT.includes(this.props.type) && this.venueXid) {
      this.token = this.createCancelToken()
      this.getQuote(this.venueXid, this.token)
      this.getScreenerData(this.token)
    } else if (DATA_FROM_PARENT.includes(this.props.type) || !this.venueXid) {
      this.setState({
        isLoading: false})
    }
  }
  componentWillUnmount () {
    this.token && this.token.cancel()
  }
  getScreenerData (token) {
    const screenDataCallback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError && response.data && response.data.items.length) {
        let item = response.data.items[0]
        this.setState({
          screenerData: {
            netAssets: convertToInternationalCurrencySystem(item.SPCFFundNetAssets, {showMagnitude: true}),
            CUSIP: item.SPCFMStarOverrideCUSIP,
            SPCFFundType: item.SPCFFundType,
            SPCFIsFVETF: item.SPCFIsFVETF,
            SPCFOffshoreFlex: item.SPCFOffshoreFlex,
            SPCFNoTransactonFeeEligibilityIndicator: item.SPCFNoTransactonFeeEligibilityIndicator,
            symbol: item.SPCFticker,
            name: item.SPCFMStarOverrideFundName,
            y5Change: formatNumber(item.SPCFTrailingReturnY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            SPCFPerformanceAsOfDate: formatDate(item.SPCFPerformanceAsOfDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true})
          }
        })
      } else {
        this.setState({
          isLoading: false,
          error: response.error
        })
      }
    }
    let newScreenerArguments = [
      {
        field: 'SPCFWSODIssue',
        conditions: [
          {
            operator: 'EqualTo',
            values: [`${this.venueXid}`]
          }
        ]
      }
    ]

    DataRequest.execute(
      `${URLS.CUSTOM.SCREEN}?screenType=${FUND_SCREEN_TYPE}`,
      this.buildScreenInputs(newScreenerArguments),
      screenDataCallback,
      token
    )
  }
  buildScreenInputs (screenerArgs) {
    let preScreenInputs = DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS
    preScreenInputs.arguments = screenerArgs || []
    preScreenInputs.resultFields = QuoteCardScreenerResultField
    return {
      method: 'POST',
      postData: preScreenInputs
    }
  }

  getQuote (venueXid, token) {
    let callback = (response) => {
      if (!response.isError) {
        let data =
          response &&
          response.data.quotes &&
          response.data.quotes.length > 0 &&
          response.data.quotes[0].data

        this.setState({
          isLoading: false,
          quoteData: {
            price: formatNumber(data.lastTrade.last, {
              precision: 2,
              negSign: true
            }),
            volume: ((data.volume.last) ? formatNumber(data.volume.last, {
              showMagnitude: true
            }) : '--'),
            priceChange: formatNumber(data.lastTrade.change, {
              precision: 2,
              negSign: true
            }),
            priceChangePct: formatNumber(data.changePercent.today, {
              precision: 2,
              negSign: true
            }),
            ytdChange: formatNumber(data.changePercent.yearToDate, {
              precision: 2,
              negSign: true,
              posSign: true
            }),
            _52WkRange: `${formatNumber(
              data.price52Week.low,
              2
            )} - ${formatNumber(data.price52Week.high, 2)}`
          },
          xrefData: {
            exchange: data.exchange.name || TEXT_NULL_VALUE
          }
        })
      } else {
        this.setState({
          isLoading: false,
          error: response.error,
          quoteData: {
            price: '--',
            priceChange: '--',
            priceChangePct: '--',
            ytdChange: '--',
            _52WkRange: '--'
          },
          xrefData: {
            exchange: TEXT_NULL_VALUE
          }
        })
      }
    }
    if (venueXid !== null) {
      getQuoteData(venueXid, callback, false, null, isMMF({CategoryName: this.props.fundType}), token)
    } else {
      this.setState({
        isLoading: false,
        quoteData: {
          price: '--',
          priceChange: '--',
          priceChangePct: '--',
          ytdChange: '--',
          _52WkRange: '--'
        },
        xrefData: {
          exchange: TEXT_NULL_VALUE
        }
      })
    }
  }

  handleClick (e) {
    if (e.cancelable) {
      e.preventDefault()
    }
    e && e.stopPropagation()
  }

  handleKeyDown (e) {
    e.keyCode === 'Enter' && this.handleClick(e)
  }

  overlayKeyHandler (venueXid) {
    this.overlayKeyHandler(venueXid)
  }

  setOptions () {
    const type = this.props.type
    switch (type) {
      case 'MOD':
      case 'MOC':
      case 'TPMOC':
        return <ModelQuoteCard {...this.props} data={this.props.data} xrefData={this.state.xrefData} days={ChartTimeFrames.Week} quoteData={this.state.quoteData} overlayKeyHandler={(venueXid) => this.overlayKeyHandler(venueXid)} />
      case 'ModL':
        return <ModelQuoteCard {...this.props} data={this.props.data} xrefData={this.state.xrefData} days={ChartTimeFrames.Week} quoteData={this.state.quoteData} overlayKeyHandler={(venueXid) => this.overlayKeyHandler(venueXid)} />
      case 'ETF':
      case 'CEF':
      case 'FSCETF':
        return <FundQuoteCard {...this.props} CUSIP={this.state.screenerData ? this.state.screenerData.CUSIP : ''} moduleTypeName={this.props.moduleType} xrefData={this.state.xrefData} days={ChartTimeFrames.Week} quoteData={this.state.quoteData} screenerData={this.state.screenerData} showAddToWatchlist={this.props.showAddToWatchlist} />
      case 'MF':
      case 'FSCMF':
        return <FundQuoteCard {...this.props} CUSIP={this.state.screenerData ? this.state.screenerData.CUSIP : ''} moduleTypeName={this.props.moduleType} xrefData={this.state.xrefData} days={ChartTimeFrames.Month} quoteData={this.state.quoteData} screenerData={this.state.screenerData} showAddToWatchlist={this.props.showAddToWatchlist} />
      case 'AICOMPARE':
        return <AIProductCard {...this.props} />
      case 'AI':
        return <ModelQuoteCard {...this.props} data={this.props.data} xrefData={this.state.xrefData} days={ChartTimeFrames.Week} quoteData={this.state.quoteData} />
      case 'AIN':
      case 'MFSnapshot':
      case 'AINSnapshot':
        return <AiQuoteCard {...this.props} moduleTypeName={this.props.moduleType} />
      case 'SMA':
        return <ModelQuoteCard {...this.props} data={this.props.data} xrefData={this.state.xrefData} days={ChartTimeFrames.Week} quoteData={this.state.quoteData} />
      case 'SMAL':
        return <ModelQuoteCard {...this.props} data={this.props.data} xrefData={this.state.xrefData} days={ChartTimeFrames.Week} quoteData={this.state.quoteData} overlayKeyHandler={(venueXid) => this.overlayKeyHandler(venueXid)} />
      case 'ER':
        return <EducationResourceFeatureCard {...this.props} />
    }
  }

  setOptionArr (options) {
    this.setState({QuoteCardItems: options, isLoading: true})
  }

  render () {
    if (this.state.isLoading) {
      return <Loader ready={false} spinnerStyle={this.spinnerStyle} spinnerSize={'2x'} />
    }
    return (
      <div className={styles.QuoteCardParentContainer}>
        <div data-testid={'QuoteCardContainer'} id={this.id + '_'} className={styles.quoteCardContainer} style={this.style}>
          {this.state.isError ? <NoData style={this.spinnerStyle} />
            : <React.Fragment>
              {this.setOptions()}
            </React.Fragment>}
        </div>
      </div>
    )
  }
}
QuoteCardWithStockHighlights.propTypes = {
  venueXid: PropTypes.number,
  id: PropTypes.string,
  viewShareClassModalHandler: PropTypes.func,
  style: PropTypes.object,
  spinnerStyle: PropTypes.object,
  type: PropTypes.string,
  moduleType: PropTypes.string,
  symbol: PropTypes.string,
  name: PropTypes.string,
  overlayKeyHandler: PropTypes.func,
  standAlone: PropTypes.bool,
  data: PropTypes.object,
  fundType: PropTypes.string,
  showAddToWatchlist: PropTypes.func
}
export default QuoteCardWithStockHighlights
