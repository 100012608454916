import 'babel-polyfill'
import React from 'react'
import F2AppContainer from '../../../F2AppContainer'
// import stylesF2 from '../../../ClientApplication/F2ContainerPage.module.scss'
import { APP_IDS } from '../../../../../utils/f2Constants'
import TokenEvents from '../../TokenEvents'
import styles from './Watchlists.module.scss'

class WatchlistsLanding extends React.Component {
  render () {
    return (
      <React.Fragment>
        <div className={styles.watchlistContainer}>
          <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_RECENTLY_VIEW_WATCHLIST}>
            <F2AppContainer
              appId={APP_IDS.COM_PERSHING_NETXWEALTH_RECENTLY_VIEW_WATCHLIST}
              context={{}}
              rootId={APP_IDS.COM_PERSHING_NETXWEALTH_RECENTLY_VIEW_WATCHLIST} />
          </div>
        </div>
        <div className={styles.watchlistContainer}>
          <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_WATCHLISTS}>
            <F2AppContainer
              appId={APP_IDS.COM_PERSHING_NETXWEALTH_WATCHLISTS}
              context={{}}
              rootId={APP_IDS.COM_PERSHING_NETXWEALTH_WATCHLISTS} />
          </div>
        </div>
        <TokenEvents />
      </React.Fragment>
    )
  }
}

export default WatchlistsLanding
