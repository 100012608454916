export const handleAsterisk = (displayLabel) => {
  if (displayLabel.includes('**')) {
    let stars = '**'
    displayLabel = `${displayLabel.replace(stars, stars.sup())}`
    return displayLabel
  } else if (displayLabel.includes('*')) {
    let star = '*'
    displayLabel = `${displayLabel.replace(star, star.sup())}`
    return displayLabel
  } else {
    return displayLabel
  }
}
export const screenerBoolDictionary = {
  'Y': 'Yes',
  'N': 'No',
  '1': 'Yes',
  '0': 'No'
}
export const screenerIndicatorDictionary = {
  'I': 'Institutional',
  'N': '--',
  'R': 'Focus'
}
export const screenerDivFreqDictionary = {
  'A': 'Annually',
  'D': 'Daily',
  'Q': 'Quarterly',
  'Semi-Annually': 'SemiAnnually',
  'M': 'Monthly'
}

export const fundVestTag = (item) => {
  // return props.type === 'ETF' ? 'FundVest ETF' : 'FundVest 200'
  if (!item) return false
  let fundVestTag = ''
  let FundVestETF = item.SPCFIsFVETF
  let FundVest = item.SPCFNoTransactonFeeEligibilityIndicator
  if (item.SPCFFundType === 'CEF' || item.SPCFFundType === 'Money Market' || item.SPCFFundType === 'Open-End Mutual Fund') {
    fundVestTag = (FundVest === 'R' || FundVest === 'I') ? 'FundVest' : false
  } else if (item.SPCFFundType === 'ETF') {
    fundVestTag = FundVestETF ? 'FundVest ETF' : false
  } else {
    fundVestTag = false
  }
  return fundVestTag
}

export const fundVestTagSearchResult = (props) => {
  // return props.type === 'ETF' ? 'FundVest ETF' : 'FundVest 200'
  if (props.data) {
    let fundVestTag = ''
    let FundVest = props.data.FundVest
    let FundVestETF = props.data.IsFVETF
    if (props.data.searchType === 'MF') {
      fundVestTag = (FundVest === 'R' || FundVest === 'I') ? 'FundVest' : false
    } else if (props.data.searchType === 'ETF') {
      fundVestTag = FundVestETF ? 'FundVest ETF' : false
    } else {
      fundVestTag = false
    }
    return fundVestTag
  }
}

export const ScreenerFundVestTag = (item) => {
  // return props.type === 'ETF' ? 'FundVest ETF' : 'FundVest 200'
  if (!item) return false
  let fundVestTag = ''
  let fundVestETF = item['FundVest ETF']
  let fundVest = item['FundVest (Focus/Institutional)']
  if (item.type === 'CEF' || item.type === 'Money Market' || item.type === 'Open-End Mutual Fund') {
    fundVestTag = (fundVest === 'R' || fundVest === 'I') ? 'fundVest' : false
  } else if (item.type === 'ETF') {
    fundVestTag = fundVestETF ? 'FundVest ETF' : false
  } else {
    fundVestTag = false
  }
  return fundVestTag
}

export const updateMarginLeftForScreenerFilters = () => {
  var screenerFiltersContainer = document.querySelector('#screenerFiltersContainer')
  var sxSelectListsWrapper = document.getElementsByName('sxSelectListsWrapper')

  // update margin left
  if (screenerFiltersContainer && sxSelectListsWrapper) {
    sxSelectListsWrapper.forEach((child, index) => {
      if (child.style.display !== 'none') {
        child.style.marginLeft = screenerFiltersContainer.scrollLeft + 'px'
      }
    })
  }
}

export const isElementInViewport = (element, component = null) => {
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}
// Replacing exact match
export const replaceAllCharsStrict = (item, find, replaceChar) => {
  return item.replace(new RegExp(find.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g'), replaceChar)
  // $& means the whole matched string
}

// Ex - convert "<5%" to 5 if charsToRemove = '<%' & replaceChar = ''
export const replaceAllChars = (item, charsToRemove, replaceChar) => {
  return item.replace(new RegExp('[' + charsToRemove + ']', 'g'), replaceChar)
}

export const getGotoPage = (searchType) => {
  let gotoPage = 'funds'
  switch (searchType) {
    case 'MF':
    case 'ETF':
    case 'Open-End Mutual Fund':
      gotoPage = 'funds'
      break
    case 'AI':
    case 'AIN':
    case 'AIScreener':
    case 'AINSnapshot':
      gotoPage = 'alternative-investments'
      break
    case 'EQ':
      gotoPage = 'equities'
      break
  }
  return gotoPage
}

export const moveScrollToTopById = (id) => {
  window.setTimeout(function () {
    const parentObj = document.getElementById(id)
    const _top = parentObj && parentObj.offsetParent ? (parentObj.offsetParent.offsetTop + parentObj.offsetTop) : 0
    window.scroll({
      top: _top,
      left: 0,
      behavior: 'smooth'
    })
  }, 500)
}

export const getClassification = (classification) => {
  switch (classification) {
    case 'Mutual Fund':
      return 'MF'
    case 'Alternative':
      return 'AI'
    case 'Stock':
      return 'EQ'
    default:
      return 'ETF'
  }
}

export const removeDuplicateModelsData = (data, response) => {
  const modelAccess = response.modelAccess
  let modelResults = data && data.filter(el => el.Classification && el.Classification.toLowerCase() === 'model' && el.isLocked !== '')
  if (modelResults.length > 0 && modelAccess.removeDuplicates) {
    modelResults.map((el, i) => {
      return modelResults.find((element, index) => {
        if (element.Strategy === el.Strategy && el.IBDCode.toLowerCase() !== modelAccess.inputUserIbdCode.toLowerCase() && element.IBDCode.toLowerCase() === modelAccess.inputUserIbdCode.toLowerCase() && el.MarketPlace === true && el.OpenToAll === true) {
          // TODO - to refactor with more better way , if in future if we need to remove the unlock results as well
          modelResults = modelResults.filter(x => x.PKColumn !== el.PKColumn)
          return el
        }
      })
    })
  }
  return modelResults
}
