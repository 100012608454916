import React from 'react'
import PropTypes from 'prop-types'
import styles from './AddText.module.scss'
import ColorPalette from '../../../Lib/ColorPalette/ColorPalette'
import { COLOR_DEFAULT_SET } from '../../../../utils/appConstants'
import Button from '../../../Lib/Button/Button'
import PrimaryButton from '../../../Lib/Button/PrimaryButton'
import Divider from '../../../Lib/Divider/Divider'

class AddText extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: props.toolValues.value || '',
      color: {value: (props.toolValues.color && props.toolValues.color.value) || '#000'},
      fontSize: props.toolValues.fontSize || '12'
    }
  }

  render () {
    return <React.Fragment>
      <div className={styles.container}>
        <div className={styles.valuesContainer}>
          <div className={styles.label}> Text </div>
          <div className={styles.valueContainer}> <input type='text' value={this.state.value} onChange={(e) => this.setState({ value: e.target.value })} /> </div>
        </div>

        <div className={styles.valuesContainer}>
          <div className={styles.label}> Font Size </div>
          <div className={styles.valueContainer}> <input type='number' value={this.state.fontSize} onChange={(e) => this.setState({ fontSize: e.target.value })} /> </div>
        </div>

        <div className={styles.valuesContainer}>
          <div className={styles.label}> Font Color </div>
          <div className={styles.valueContainer}>
            <ColorPalette
              colors={COLOR_DEFAULT_SET}
              selectedValue={this.state.color.value}
              name='btnChartColorPalatte'
              onSelection={(data) => {
                this.setState({ color: data })
              }} />
          </div>
        </div>
      </div>
      <Divider style={{margin: '0px', 'padding-top': '15px'}} />
      <div className={styles.buttonContainer} style={{ textAlign: 'center', justifyContent: 'center' }}>
        <div style={{ marginRight: '15px' }}> <PrimaryButton dataTestId='applyButton' buttonText='Apply' clickHandler={() => { this.props.onApply(this.state) }} /> </div>
        <div> <Button buttonDataTestId='cancelButton' labelText='Cancel' clickHandler={() => { this.props.onCancel() }} /> </div>
      </div>
    </React.Fragment>
  }
}

AddText.propTypes = {
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  toolValues: PropTypes.object
}

export default AddText
