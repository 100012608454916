import React, { Component } from 'react'
import PropTypes from 'prop-types'
import tabStyle from './TabControl.module.scss'
import classNames from 'classnames'
import {sliderHOC} from '../../Shared/CustomHook/Slider'

class TabControl extends Component {
  references = {}
  constructor (props) {
    super(props)
    this.state = {
      activeTab: this.props.activeTab || this.props.children || this.props.children[0].props.label,
      numberOfTabs: this.props.children.length,
      navigationCursor: 0,
      isTabClick: false
    }
    this.references = Array(this.props.children.length)
      .fill()
      .map(() => React.createRef())
  }

  onClickTabItem = tab => {
    this.setState({ activeTab: tab, isTabClick: true })
    this.props.onClick && this.props.onClick(tab)
    if (this.props.changeHandler) {
      this.props.changeHandler(tab)
    }
  }

  onKeyPressHandler = (tab, e) => {
    e.preventDefault()
    if (e.charCode === 13) {
      this.onClickTabItem(tab)
    }
  }
  handleKeyDown = e => {
    let newNavigationCursor = this.state.navigationCursor
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 || e.keyCode === 37) {
      if (newNavigationCursor === 0) {
        newNavigationCursor = this.state.numberOfTabs - 1
      } else {
        newNavigationCursor--
      }
      this.setState({ navigationCursor: newNavigationCursor })
    } else if (e.keyCode === 40 || e.keyCode === 39) {
      if (newNavigationCursor === this.state.numberOfTabs - 1) {
        newNavigationCursor = 0
      } else {
        newNavigationCursor++
      }
      this.setState({ navigationCursor: newNavigationCursor })
    }
    this.focusById(newNavigationCursor)
  }

  getOrCreateRef = id => {
    if (!this.references.hasOwnProperty(id)) {
      this.references[id] = React.createRef()
    }
    return this.references[id]
  }

  focusById = id => {
    this.references[id].current && this.references[id].current.focus()
  }

  static getDerivedStateFromProps (nextProps, state) {
    if (nextProps.activeTab !== state.activeTab && !state.isTabClick) {
      // Change in props
      return {
        activeTab: nextProps.activeTab
      }
    }
    return {
      activeTab: state.activeTab,
      isTabClick: false
    }
  }

  renderTabs = activeTab => {
    const children = React.Children.toArray(this.props.children)
    return (
      <ul
        data-testid='lib_tab_test'
        id={this.props.id}
        role='tablist'
        aria-label={this.props.ariaLabel}
        className={`${this.props.isStickyTabControl ? tabStyle.tab_list : tabStyle.tab_list_nonsticky} ${this.props.className} `} // Add condition for Non sticky tab. by default - its sticky.
        onKeyDown={e => this.handleKeyDown(e)}
      >
        {children.map((child, index) => {
          if (child) {
            const { label } = child.props
            return (
              <li
                ref={this.references[index]}
                className={classNames(tabStyle.tab_list_item, {
                  [tabStyle.tab_list_active]: activeTab === label
                })}
                title={label}
                tabIndex={index === this.state.navigationCursor ? 0 : -1}
                role='tab'
                aria-selected={activeTab === label ? 'true' : 'false'}
                key={index}
                onClick={() => this.onClickTabItem(label)}
                onKeyPress={e => this.onKeyPressHandler(label, e)}
              >
                <span className={`${tabStyle.tab_text} `}>{label}</span>
              </li>
            )
          }
        })}
      </ul>
    )
  }

  render () {
    const {
      state: { activeTab }
    } = this
    const children = React.Children.toArray(this.props.children);
    return (
      <div className={`${tabStyle.tabs}`}>
        {this.renderTabs(activeTab)}
        <div
          className={`${tabStyle.tab}`}
          role='tabpanel'
        >
          {children.map(child => {
            if (typeof child !== 'boolean') {
              if (child.props.index && child.props.label !== activeTab) return undefined
              return child.props.children
            }
          })}
        </div>
      </div>
    )
  }
}

TabControl.defaultProps = {
  isStickyTabControl: true
}

TabControl.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  activeTab: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  changeHandler: PropTypes.func,
  isStickyTabControl: PropTypes.bool,
  className: PropTypes.string
}

export default sliderHOC(TabControl)
