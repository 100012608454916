'use strict'

import React from 'react'
import PropTypes from 'prop-types'
import styles from './LinkButton.module.scss'

const LinkButton = ({label, onClick, disabled, customStyle, dataTestId}) => {
  return <button
    aria-label={label}
    id={'linkBtn'}
    type='button'
    onClick={onClick}
    onKeyPress={onClick}
    className={styles.link}
    disabled={disabled}
    style={customStyle}
    data-testid={dataTestId}>
    {label}
  </button>
}

LinkButton.propTypes = {
  label: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  customStyle: PropTypes.object,
  dataTestId: PropTypes.string
}

export default LinkButton
