'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fas';
var iconName = 'chart-candlestick';
var width = 512;
var height = 512;
var aliases = [];
var unicode = 'e0e2';
var svgPathData = 'M400 320H416v48c0 8.836 7.164 16 16 16c8.838 0 16-7.164 16-16V320h16c8.838 0 16-7.164 16-16v-96C480 199.2 472.8 192 464 192H448V144C448 135.2 440.8 128 432 128C423.2 128 416 135.2 416 144V192h-16C391.2 192 384 199.2 384 208v96C384 312.8 391.2 320 400 320zM272 256H288v48c0 8.836 7.164 16 16 16c8.838 0 16-7.164 16-16V256h16C344.8 256 352 248.8 352 240v-128C352 103.2 344.8 96 336 96H320V48C320 39.16 312.8 32 304 32C295.2 32 288 39.16 288 48V96h-16C263.2 96 256 103.2 256 112v128C256 248.8 263.2 256 272 256zM480 416H64V63.1C64 46.33 49.67 32 32 32S0 46.33 0 63.1V448c0 17.6 14.4 32 32 32h448C497.7 480 512 465.7 512 448S497.7 416 480 416zM144 320H160v48C160 376.8 167.2 384 176 384C184.8 384 192 376.8 192 368V320h16C216.8 320 224 312.8 224 304v-160C224 135.2 216.8 128 208 128H192V80C192 71.16 184.8 64 176 64C167.2 64 160 71.16 160 80V128H144C135.2 128 128 135.2 128 144v160C128 312.8 135.2 320 144 320z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    aliases,
    unicode,
    svgPathData
  ]};

exports.faChartCandlestick = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;