import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import styles from './PerformanceBar.module.scss'
import { getDeviceType, deviceType, handleWindowResize, getRangeStep } from '../../../utils/utilities'
import { DEFAULT_VALUE } from '../../../utils/appConstants'
import { formatNumber } from '../../../utils/formatter'
import Loader from '../../../../../../Lib/common/Loader'
import { getQuoteData } from '../../../../../../../shared/dataMethods'
const DEFAULT_STATE = {
  DayRange: {
    label: 'Day Range',
    low: DEFAULT_VALUE,
    high: DEFAULT_VALUE,
    value: '0.0'
  },
  PreviousClose: {
    label: 'Previous Close',
    value: DEFAULT_VALUE
  },
  Open: {
    label: 'Open',
    value: DEFAULT_VALUE
  },
  TrailingPerformance:
  {
    label: '3Yr Trailing Performance',
    value: DEFAULT_VALUE
  }
}

const PerformanceBar = ({ xid }) => {
  const [device, setdevice] = useState(getDeviceType())
  const [isReady, setIsReady] = useState(false)
  const [quoteData, setQuoteData] = useState(DEFAULT_STATE)

  useEffect(() => {
    handleWindowResize(setdevice)
  }, [])
  useEffect(() => {
    if (xid) {
      getQuote()
      return () => {
        setIsReady(false)
      }
    } else {
      setQuoteData(DEFAULT_STATE)
    }
  }, [xid])

  const getQuote = () => {
    let callback = (response) => {
      if (!response.isError) {
        let quotesData = response &&
          response.data.quotes &&
          response.data.quotes.length > 0 &&
          response.data.quotes[0].data
        setQuoteData((prevState) => {
          return {
            ...prevState,
            DayRange: {
              label: 'Day Range',
              low: quotesData.lastTrade.low ? formatNumber(quotesData.lastTrade.low, 2) : DEFAULT_VALUE,
              high: quotesData.lastTrade.high ? formatNumber(quotesData.lastTrade.high, 2) : DEFAULT_VALUE,
              value: quotesData.lastTrade.last ? formatNumber(quotesData.lastTrade.last, 2) : '0.0'
            },
            PreviousClose: {
              label: `${quotesData.exchange.sessionIn ? 'Previous Close' : 'Close'}`,
              value: quotesData.lastTrade.close ? formatNumber(quotesData.lastTrade.close, 2) : DEFAULT_VALUE
            },
            Open: {
              label: 'Open',
              value: quotesData.lastTrade.open ? formatNumber(quotesData.lastTrade.open, 2) : DEFAULT_VALUE
            },
            TrailingPerformance: {
              label: `3Yr Trailing Performance`,
              value: quotesData.changePercent.threeYear
            }
          }
        })
        setIsReady(true)
      } else {
        setQuoteData(DEFAULT_STATE)
        setIsReady(true)
      }
    }
    getQuoteData(xid, callback, false)
  }
  const getOpen = () => {
    return <div className={styles.childContainer}>
      <div className={styles.labels} id='Open' >
        {quoteData.Open.label}
      </div>
      <div className={styles.value}>
        {quoteData.Open.value}
      </div>
    </div>
  }
  const getPreviousClose = () => {
    return <div className={styles.childContainer}>
      <div className={styles.labels} id='PreviousClose' >
        {quoteData.PreviousClose.label}
      </div>
      <div className={styles.value}>
        {quoteData.PreviousClose.value}
      </div>
    </div>
  }
  const rangeChange = () => {}
  const getDayRange = () => {
    return <div className={styles.childContainer}>
      <div className={styles.labels} id='DayRange' >
        {quoteData.DayRange.label}
      </div>
      <div className={styles.value}>
        {`${quoteData.DayRange.low} - ${quoteData.DayRange.high}`}
      </div>
      <div className={`${styles['progress-bar']}`}>
        <input type='range' min={quoteData.DayRange.low} max={quoteData.DayRange.high}
          value={quoteData.DayRange.value} onChange={rangeChange} className={`${styles['slider']}`} step={getRangeStep(quoteData.DayRange.low, quoteData.DayRange.high)} aria-label={`${quoteData.DayRange.label}: ${quoteData.DayRange.value}`} disabled />
      </div>
    </div>
  }
  const get3yrTrailing = () => {
    let value = quoteData.TrailingPerformance.value
    let style = { color: '#000000' }
    let selectedValue = '0.00'
    if (value > 0) {
      style.color = '#008338'
      selectedValue = formatNumber(value, { negSign: true, posSign: true })
    }
    if (value < 0) {
      style.color = '#E40707'
      selectedValue = formatNumber(value, { negSign: true, posSign: true })
    }
    return <div className={styles.childContainer}>
      <div className={styles.labels} id='TrailingPerformance' >
        {quoteData.TrailingPerformance.label}
      </div>
      <div className={styles.value} style={value && style}>
        {value ? `${selectedValue}%` : DEFAULT_VALUE}
      </div>
    </div>
  }
  return <Loader ready={isReady} spinnerSize='2x'>
    <div className={styles.container}> {
      device === deviceType.Desktop || device === deviceType.Ipad
        ? <Fragment>
          {getOpen()}
          {getPreviousClose()}
          {getDayRange()}
          {get3yrTrailing()}
        </Fragment>
        : <Fragment>
          <div>
            <div className={styles.mobileDisplay}>
              {getOpen()}
            </div>
            {getDayRange()}
          </div>
          <div> <div className={styles.mobileDisplay}>
            {getPreviousClose()}
          </div>{get3yrTrailing()}
          </div>
        </Fragment>
    }
    </div>
  </Loader>
}
PerformanceBar.propTypes = {
  xid: PropTypes.number
}
export default PerformanceBar
