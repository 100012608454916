import styles from './CarbonRiskScoreBreakdownChart.module.scss'
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { deviceType, getDeviceType } from '../../../utils/utilities'
import ChartistChart from '../../../../../../Lib/Chartist/ChartistChart'
import Chartist from 'chartist'
import { formatNumber } from '../../../utils/formatter'
import Popover from '../../../../../../Lib/Popover/Popover'

const CarbonRiskScoreBreakdownChart = ({data, isNXI=false}) => {
  const chartLabels = ['Negligible', 'Low', 'Medium', 'High', 'Severe']
  let [chartData, setChartData] = useState({ labels: [], series: [] })
  const [showToolTip, setShowToolTip] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const ref = useRef(null)
  const [toolTipPosition, setToolTipPosition] = useState({top: '', left: ''})
  const [hoverBackground, setHoverBackground] = useState({top: 0, left: 0, height: 0})
  const [tooltipValue, setTooltipvalue] = useState(null)
  const [tooltipLabel, setTooltipLabel] = useState(null)
  const chartOptions = {
    seriesBarDistance: 18,
    showLine: true,
    width: '100%',
    height: '100%',
    offset: 0,
    axisX: {
      showGrid: true,
      showLabel: true,
      labelOffset: {
        x: 0,
        y: 0
      }
    },
    high: 100,
    low: 0,
    axisY: {
      showGrid: true,
      showLabel: true,
      labelOffset: {
        x: 10,
        y: 7
      },
      scaleMinSpace: 50,
      labelInterpolationFnc: (value) => {
        return formatNumber(value) + '%'
      },
      position: 'end'
    },
    chartPadding: {
      bottom: 0,
      left: 0,
      top: 20,
      right: 10
    }
  }

  useEffect(() => initChartData(), [])

  const initChartData = () => {
    if (data?.fund?.risk?.aumbreakdown) {
      let seriesdata = {}
      const {pctNegligibleCarbonRiskScores,
        pctLowCarbonRiskScores,
        pctMediumCarbonRiskScores,
        pctHighCarbonRiskScores,
        pctSevereCarbonRiskScores
      } = data.fund.risk.aumbreakdown
      seriesdata['Negligible'] = formatNumber(pctNegligibleCarbonRiskScores)
      seriesdata['Low'] = formatNumber(pctLowCarbonRiskScores)
      seriesdata['Medium'] = formatNumber(pctMediumCarbonRiskScores)
      seriesdata['High'] = formatNumber(pctHighCarbonRiskScores)
      seriesdata['Severe'] = formatNumber(pctSevereCarbonRiskScores)
      setChartData({labels: chartLabels,
        series: [
          [seriesdata['Negligible'], seriesdata['Low'], seriesdata['Medium'], seriesdata['High'], seriesdata['Severe']]
        ]})
    }
  }

  const handleHoverPopup = (data) => {
    setShowToolTip(data.show)
    if (data.position) {
      let _left = 0
      setToolTipPosition({top: data.position.top, left: isNXI && deviceType.Mobile && data.tooltipLabel === 'Medium' ? 75 : data.position.left - _left})
    }
    if (data.tooltipValue) {
      setTooltipvalue(data.tooltipValue)
    }
    if (data.tooltipLabel) {
      setTooltipLabel(data.tooltipLabel)
    }
    if (data.hoverBackground) {
      setHoverBackground({left: data.hoverBackground.left})
    }
  }

  const handleChartGrid = (direction, index, element, y1, y2, x1, x2) => {
    if (direction === 'horizontal' && index === 3) {
      const roundedLine = new Chartist.Svg(
        'line',
        {
          x1: x1,
          x2: x2,
          y1: y1.toFixed(),
          y2: y2 + 55
        }
      )
      element.replace(roundedLine)
    } else if (direction === 'horizontal') {
      element.addClass('hiddenChartGridLine')
    }
  }

  const listener = {
    created: () => {
      const chartBars = document.querySelectorAll(`.${styles.chartContainer} line.ct-bar`)
      const chartBarContainer = document.querySelector(`.${styles.chartContainer} svg.ct-chart-bar`)
      const chartBarLabels = document.querySelectorAll(`.${styles.chartContainer} .ct-label.ct-horizontal`)
      if (chartBarContainer) {
        chartBarContainer.onmouseover = (event) => {
          event.stopPropagation()
          handleHoverPopup(
            {
              show: false
            })
        }
      }
      if (chartBars) {
        chartBars.forEach((bar, index) => {
          bar.onmouseenter = (event) => {
            event.stopPropagation()
            let leftPosition = 0
            let backgroundLeftPosition = 0
            let leftPos = bar.x2.baseVal.value
            if (getDeviceType() === deviceType.Mobile) {
              if (event.x > 190) {
                leftPosition = leftPos - 193
              } else {
                leftPosition = leftPos + 12
              }
              backgroundLeftPosition = leftPos - 24
            } else if (getDeviceType() === deviceType.Ipad) {
              leftPosition = leftPos + 12
              backgroundLeftPosition = leftPos - 24
            } else {
              leftPosition = leftPos + 16
              backgroundLeftPosition = leftPos - 40
            }
            handleHoverPopup(
              {
                show: true,
                position: {top: '100px', left: leftPosition},
                tooltipValue: formatNumber(bar.attributes['ct:value'].value) + '%',
                tooltipLabel: chartBarLabels[index].innerHTML,
                hoverBackground: {left: backgroundLeftPosition}
              })
          }
        })
      }
    },
    draw: (data) => {
      switch (data.type) {
        case 'grid':
          handleChartGrid(
            data.axis.units.dir,
            data.index,
            data.element,
            data.y1,
            data.y2,
            data.x1,
            data.x2
          )
          break
        default:
          break
      }
    }
  }

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen)
  }

  const header = () => {
    return (
      <React.Fragment>
        <button className={styles.header} ref={ref}
          onFocus={togglePopover}
          onClick={togglePopover}
          onMouseOver={togglePopover}
          onMouseOut={togglePopover}
          onBlur={togglePopover}> Carbon Risk Breakdown</button>
        {isPopoverOpen && (
          <Popover
            id='bottom-popover'
            closePopover={togglePopover}
            body={<div className={styles.popOverBody}> <span> {'Sustainalytics assigns companies to one of five carbon risk levels. This is the percent breakdown of assets under management with these ratings:Negligble, Low, Medium, High and Severe.'} </span></div>}
            referenceElement={ref.current}
            placement={'bottom'}
          />
        )}
      </React.Fragment>
    )
  }

  return <div className={styles.chartContainer} data-testid='carbonRiskScoreBreakdownChart'>
    {<React.Fragment>
      {header()}
      {showToolTip && <div className={`${styles.chartBarHoverContainer} ${isNXI? styles.nxiChartBarHoverContainer : ''}`}
        data-testid='chartBarHoverContainer' style={{left: hoverBackground.left}} />}
      <div className={`${isNXI? styles.nxiChartistChartContainer: styles.chartistChartContainer}`} ><ChartistChart data={chartData} options={chartOptions} type='Bar' chartistListner={listener} /></div>
      {showToolTip && <div style={{top: toolTipPosition.top, left: toolTipPosition.left}} className={`${styles.tooltip}`} role='tooltip' >
        <div className={`${styles.color}`}>{`${tooltipLabel} Carbon Risk`}</div>
        <div className={`${styles.text}`}>{tooltipValue}</div>
      </div>
      }
    </React.Fragment>}
  </div>
}
CarbonRiskScoreBreakdownChart.propTypes = {
  data: PropTypes.object,
  isNXI: PropTypes.bool
}

export default CarbonRiskScoreBreakdownChart
