import DataRequest from '../../../../../../services/DataRequest'
import { FUND_SCREEN_TYPE, URLS } from '../../utils/appConstants'
import {deviceType, getDeviceType} from '../../../../../../utils/utilities'

export const ScreenerOptionsConstants = {
  NewScreener: 'New Screener',
  SaveThisScreener: 'Save this Screener',
  SavedScreeners: 'Saved Screeners',
  UpdateThisScreener: 'Update this Screener',
  EsgGlossary: 'ESG Glossary'
}

export const UpdateScreenerConfirmationDialogConstants = {
  HeaderInfo: `Save Changes`,
  BodyContent: `You've made changes to the selected screener criteria. Would you like to save these changes?`,
  ConfirmButtonText: `Save`,
  CancelButtonText: `Don't Save`
}

export const makeUpdateScreenerApiCall = (screenerID, postData, callback) => {
  DataRequest.execute(
    `${URLS.PLATFORM.SAVEDSCREENS}/` + screenerID,
    {
      method: 'PUT',
      postData: postData
    },
    callback,
    null
  )
}

export const screenerDropdownOptions = (selectedScreenerId, type, isPreScreener = false) => {
  const options = []
  options.push(ScreenerOptionsConstants.NewScreener)
  if (!isPreScreener) {
    if (selectedScreenerId) {
      options.push(ScreenerOptionsConstants.UpdateThisScreener)
    } else {
      options.push(ScreenerOptionsConstants.SaveThisScreener)
    }
  }
  options.push(ScreenerOptionsConstants.SavedScreeners)
  if (getDeviceType() === deviceType.Mobile && type === FUND_SCREEN_TYPE) {
    options.push(ScreenerOptionsConstants.EsgGlossary)
  }
  return options
}

export const UpdateScreenerConfirmationMessage = 'Screener has been updated'
export const ScreenerCreatedMessage = 'Screener created successfully'
export const ScreenerDetailsUpdatedMessage = 'Screener details has been updated'
