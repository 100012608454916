import React from 'react'
import PropTypes from 'prop-types'
import styles from './Table.module.scss'

export default function Table ({headers, data}) {
  return (
    <div className={styles.genericTableContainer}>
      <table
        className={styles.genericTable}>
        <thead>
          <tr key='header' className={styles.header}>
            {headers.map(header => {
              if (!header.hideInMobile) {
                return <th key={header.name} className={styles[header.textAlign]}>
                  <div className={header.seprator ? styles.seprator : undefined}>{header.name}</div>
                </th>
              }
            })}
          </tr>
        </thead >
        <tbody>
          {data.map((item, index) => {
            if (item) {
              return <tr key={index} className={styles.body}>
                {
                  headers.map(header => {
                    if (!header.hideInMobile) {
                      return <td key={header.name + index} className={styles[header.textAlign]}>
                        <div className={header.seprator ? styles.valueSeprator : undefined}>{item[header.name]}</div>
                      </td>
                    }
                  })
                }
              </tr>
            }
          })}
        </tbody>
      </table>
    </div>
  )
}
Table.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
}
