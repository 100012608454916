/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import PropTypes from 'prop-types'
import styles from '../Modal.module.scss'
export default function ModalFooter (props) {
  return (
    <div className={styles.footerContainer}>
      { props.children }
    </div>
  )
}

ModalFooter.propTypes = {
  children: PropTypes.node
}
