/* eslint-disable jsx-a11y/no-autofocus */
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './SearchBox.module.scss'

export default function SearchBox ({onQuery, placeholderText = 'Search'}) {
  const [query, setQuery] = useState('')

  useEffect(() => {
    onQuery(query)
  }, [query])

  const onChange = (e) => {
    setQuery(e.target.value)
  }
  const onClear = (e) => {
    setQuery('')
  }
  return (
    <div className={styles.searchBoxContainer}>
      <input id='symbol_search_box' data-testid='symbol_search_box' className={styles.symbolSearchBox} type='text' autoComplete='off' value={query} onChange={onChange} placeholder={placeholderText} />
      <span className={styles.searchIcon}>
        {query ? <FontAwesomeIcon icon={['fal', 'times']} color='black' onClick={onClear} />
          : <FontAwesomeIcon icon={['fal', 'search']} />
        }
      </span>
    </div>
  )
}
SearchBox.propTypes = {
  onQuery: PropTypes.func,
  placeholderText: PropTypes.string
}
