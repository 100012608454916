import React, { useState, useEffect } from 'react'
import PerformanceChart from './PerformanceChart'
import styles from './Performance.module.scss'
import { STOCKS_PERFORMANCE, URLS, CHART_STYLES } from '../../../utils/appConstants'
import DataRequest from '../../../services/DataRequest'
import { handleWindowResize, getDeviceType, deviceType, getSymbolToDisplay } from '../../../utils/utilities'
import { registerF2OnEvent, registerAccessTokenUpdatedF2OnEvent } from '../../../utils/f2Methods'
import {EVENT_NAMES} from '../../../utils/f2Constants'
import BasicDropDown from '../../../components/Lib/Dropdown/BasicDropDown'
import ChartType from '../../Charts/ChartType/ChartType'
import PropTypes from 'prop-types'
import Loader from '../../Lib/common/Loader'
import DataUnavailable from '../../Lib/DataUnavailable/DataUnavailable'

const Performance = (props) => {
  const [device, setDeviceType] = useState(getDeviceType())
  const [intervalMapping] = useState(STOCKS_PERFORMANCE.intervalMapping)
  const [periodMapping] = useState(STOCKS_PERFORMANCE.periodMapping)
  const [selectedChartType, setSelectedChartType] = useState(STOCKS_PERFORMANCE.chartTypes.Fill)
  const [dropDownDataDays] = useState(Object.keys(STOCKS_PERFORMANCE.dropDownDaysValues))
  const [selectedDays, setSelectedDays] = useState(STOCKS_PERFORMANCE.dropDownDaysValues['1 Day'])
  const [symbol, setSymbol] = useState(props.symbol)
  const [wsodIssue, setWsodIssue] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isData, setIsData] = useState(false)
  useEffect(() => {
    handleWindowResize((response) => {
      setDeviceType(response)
    })
    registerAccessTokenUpdatedF2OnEvent(() => {
      getVenueXidFromXref()
    })
    registerF2OnEvent(EVENT_NAMES.SYMBOL_CHANGED_EVENT, (symbolUpdatedObject) => {
      setSymbol(symbolUpdatedObject.symbol)
    })
  }, [])
  useEffect(() => {
    getVenueXidFromXref()
  }, [symbol])

  const getVenueXidFromXref = () => {
    if (symbol) {
      setIsData(true)
      setIsLoading(true)
      let callback = (response) => {
        if (!response.isDataRequestComplete) return
        if (!response.isError) {
          const responseData = response.data && response.data.xids
          if (responseData.venue) {
            setWsodIssue(responseData.venue)
            setSelectedDays(STOCKS_PERFORMANCE.dropDownDaysValues['1 Day'])
          } else {
            setWsodIssue(null)
          }
        } else {
          setIsData(false)
          // error
        }
        setIsLoading(false)
        setIsData(true)
      }

      DataRequest.execute(
        `${URLS.PLATFORM.XREFGETSYMBOL}/` + symbol,
        {
          params: {
            isCaseSensitive: false
          }
        },
        callback,
        null
      )
    }
  }
  const callChart = e => {
    if (!e) return
    e.preventDefault()
    setSelectedDays(STOCKS_PERFORMANCE.TIME_PERIOD[e.target.innerText])
  }
  const dropDownChangeHandler = (e) => {
    const value = e.getAttribute('data-value')
    const item = CHART_STYLES.filter(item => {
      if (item.Text === value) {
        return item
      }
    })
    setSelectedChartType((item && item[0] && item[0].Icon) || 'lineWithShapes')
  }
  const dropDownDaysChangeHandler = (e) => {
    setSelectedDays(STOCKS_PERFORMANCE.dropDownDaysValues[e.getAttribute('data-value')])
  }
  function getChartSelection () {
    let body = []
    let bodyHtml
    if (device === deviceType.Desktop || device === deviceType.Ipad) {
      bodyHtml =
        <div className={styles.itemsContainer} key={device}>
          <div className={`${selectedDays === 1 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart} >1D</button>
          </div>
          <div className={`${selectedDays === 5 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>5D</button>
          </div>
          <div className={`${selectedDays === 30 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>1M</button>
          </div>
          <div className={`${selectedDays === 90 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>3M</button>
          </div>
          <div className={`${selectedDays === 365 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>1Y</button>
          </div>
          <div className={`${selectedDays === 1825 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>5Y</button>
          </div>
          <div className={`${selectedDays === 3650 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>10Y</button>
          </div>
          <div className={`${selectedDays === 10000 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>Max</button>
          </div>
          <ChartType chartTypeSelValue={selectedChartType} onSelect={dropDownChangeHandler} />
        </div>
    } else if (device === deviceType.Mobile) {
      bodyHtml =
        <div className={styles.itemsContainer} key={device}>
          <div className={styles['row1FirstDD']}>
            <BasicDropDown selectedValue={'1 Day'} data={dropDownDataDays} changeHandler={dropDownDaysChangeHandler} />
          </div>
          <div className={styles['row1SecondDD']}>
            <ChartType chartTypeSelValue={selectedChartType} onSelect={dropDownChangeHandler} />
          </div>
        </div>
    }
    body.push(bodyHtml)
    return body
  }
  const getChartInputs = () => {
    const dataInterval = intervalMapping[selectedDays]
    const dataPeriod = periodMapping[selectedDays]
    return {
      'timePeriod': selectedDays,
      'dataInterval': dataInterval,
      'dataPeriod': dataPeriod,
      'wsodIssue': wsodIssue,
      'chartType': selectedChartType
    }
  }
  const chartInputs = getChartInputs()
  return (
    <div className={styles.performanceContainer}>
      <span className={styles.TopText}>{getSymbolToDisplay(symbol)}Performance
      </span>
      <div className={styles.chartSelections}>
        {getChartSelection()}
      </div>
      {
        !isData &&
        <div>
          <DataUnavailable />
        </div>
      }
      {isLoading && isData &&
      <div className={styles.loaderContainer}>
        <Loader ready={!isLoading}
          spinnerSize={'2x'}
          msg={'Loading'} />
      </div>
      }
      <div className={styles.chart}>
        {!isLoading && wsodIssue && <PerformanceChart chartInputs={chartInputs} />}
      </div>
      {/* {
        !isLoading && <button className={styles.AdvChtLink} onClick={() => {
          emitF2Event(EVENT_NAMES.MOD_GOTO, {
            gotoPage: PAGE_NAMES.CHARTING,
            symbol: symbol})
        }
        }>
          Go to Charting</button>
      } */}
    </div>
  )
}
Performance.propTypes = {
  symbol: PropTypes.string
}
export default Performance
