'use strict'
import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import Popover from '../Popover/Popover'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './ColorPalette.module.scss'
import { COLOR_DEFAULT_SET } from '../../../utils/appConstants'

const ColorPalette = ({onSelection, id, selectedValue, popOverStyle, colors, name}) => {
  const uniqueId = `colorpalette_${(id)}`
  const [toggle, setToggle] = useState(false)
  const [isVisible, setIsVisible] = useState(true)
  const ref = useRef(null)
  const [colorsList] = useState(colors || COLOR_DEFAULT_SET)
  const [selectedColor, setSelectedColor] = useState(selectedValue || colorsList[0].value)
  const togglePopover = () => {
    setToggle(!toggle)
  }

  useEffect(() => {
    togglePopover()
  }, [isVisible])

  const setSelection = e => {
    setSelectedColor(e)
    const data = {
      id: id,
      value: e
    }
    setIsVisible(isVisible => !isVisible)
    onSelection(data)
  }

  const getColorPaletteBody = <div className={styles.paletteContainer}>
    {colorsList.map(item => {
      return <div key={item.key} className={`${styles['box']} ${selectedColor === item.key ? styles['selected'] : ''}`} style={{backgroundColor: item.value}} >
        <button data-testid={item.key} className={styles.hide} onClick={e => setSelection(item.value)} tabIndex='0' />
      </div>
    })}
  </div>

  const setColorBar = () => {
    return (
      <span className={styles.box} style={{backgroundColor: selectedColor}} />
    )
  }
  return (
    <React.Fragment>
      <button
        data-testid={`button_${uniqueId}`}
        id={id}
        aria-label={id}
        type='button'
        ref={ref}
        onClick={togglePopover}
        tabIndex='0'
        name={name}
        className={styles.customSelectContainer} >
        {setColorBar()}
        {
          toggle ? <FontAwesomeIcon icon={[('fas'), ('caret-down')]} color='black' className={styles.icon} />
            : <FontAwesomeIcon icon={[('fas'), ('caret-up')]} color='black' className={styles.icon} />
        }
      </button>
      { !toggle &&
        <div className={popOverStyle || styles.popoverContainer}>
          <Popover
            id={uniqueId}
            closePopover={togglePopover}
            body={getColorPaletteBody}
            referenceElement={ref.current}
            showPointingArrow={false}
            placement={'bottom'}
          />
        </div>
      }
    </React.Fragment>
  )
}

ColorPalette.propTypes = {
  popOverStyle: PropTypes.string,
  selectedValue: PropTypes.string,
  name: PropTypes.string,
  colors: PropTypes.array,
  id: PropTypes.string,
  onSelection: PropTypes.func
}

export default ColorPalette
