import React, {useState, useRef} from 'react'
import styles from './ESGScoreTop.module.scss'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatNumber } from '../../../../utils/formatter'
import Popover from '../../../Lib/Popover/Popover'

function ESGScoreTop ({ data, headers, showGlobe, formatOptions, isNXI=false }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const ref = useRef(null)

  const [isPopoverOpen2, setIsPopoverOpen2] = useState(false)
  const ref2 = useRef(null)

  const [isPopoverOpen3, setIsPopoverOpen3] = useState(false)
  const ref3 = useRef(null)

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen)
  }
  const togglePopover2 = () => {
    setIsPopoverOpen2(!isPopoverOpen2)
  }
  const togglePopover3 = () => {
    setIsPopoverOpen3(!isPopoverOpen3)
  }

  const togglePopoverBlur = () => {
    setIsPopoverOpen(!isPopoverOpen)
  }
  const togglePopoverBlur2 = () => {
    setIsPopoverOpen2(!isPopoverOpen2)
  }
  const togglePopoverBlur3 = () => {
    setIsPopoverOpen3(!isPopoverOpen3)
  }
  const popOver1 = () => {
    return (
      <React.Fragment>
        <div className={styles.subheading} id='subHeading1'>
          <button
            ref={ref}
            onClick={togglePopover}
            onFocus={togglePopover}
            onMouseOver={togglePopover}
            onMouseOut={togglePopoverBlur}
            onBlur={togglePopoverBlur}
            data-testid={headers?.header1?.headerTestId} >
            {headers.header1.header}
          </button>
        </div>
        {isPopoverOpen && (
          <Popover
            id='bottom-popover'
            closePopover={togglePopover}
            body={<div className={styles.popOverBody}> <span id='heading1' data-testid={headers?.header1?.headerDescTestId}> {headers.header1.headerDesc} </span></div>}
            referenceElement={ref.current}
            placement={'bottom'}
          />
        )}
      </React.Fragment>
    )
  }
  const popOver2 = () => {
    return (
      <React.Fragment>
        <div className={styles.subheading} id='subHeading2'>
          <button
            ref={ref2}
            onClick={togglePopover2}
            onFocus={togglePopover2}
            onMouseOver={togglePopover2}
            onMouseOut={togglePopoverBlur2}
            onBlur={togglePopoverBlur2}
            data-testid={headers?.header2?.headerTestId} >
            {headers.header2.header}
          </button>
        </div>
        {isPopoverOpen2 && (
          <Popover
            id='bottom-popover-2'
            closePopover={togglePopover2}
            body={<div className={styles.popOverBody}> <span id='heading2' data-testid={headers?.header2?.headerDescTestId}> {headers.header2.headerDesc} </span></div>}
            referenceElement={ref2.current}
            placement={'bottom'}
          />
        )}
      </React.Fragment>
    )
  }
  const popOver3 = () => {
    return (
      <React.Fragment>
        <div className={styles.subheading} id='subHeading3'>
          <button
            ref={ref3}
            onClick={togglePopover3}
            onFocus={togglePopover3}
            onMouseOver={togglePopover3}
            onMouseOut={togglePopoverBlur3}
            onBlur={togglePopoverBlur3}
            data-testid={headers?.header3?.headerTestId} >
            {headers.header3.header}
          </button>
        </div>
        {isPopoverOpen3 && (
          <Popover
            id='bottom-popover-3'
            closePopover={togglePopover3}
            body={<div className={styles.popOverBody}> <span id='heading3'data-testid={headers?.header3?.headerDescTestId}> {headers.header3.headerDesc} </span></div>}
            referenceElement={ref3.current}
            placement={'bottom'}
          />
        )}
      </React.Fragment>
    )
  }
  const isValidNumber = (num) => {
    if (num === 0) {
      return true
    }
    return (num && true) || false
  }
  return (
    <div className={styles.top}>
      <div className={`${isNXI?styles.nxiRiskScoreBox : ''} ${styles.riskScoreBox}`}>
        <div className={styles.riskScoreBoxItems}>
          <div className={styles.subheading} id='subHeading'>{popOver1()}</div>
          <div>
            {(isValidNumber(data.value1) && showGlobe) && <div className={styles.icon}>
              {[...Array(5)].map((el, idx) =>
                <FontAwesomeIcon key={'globeIcon' + idx} icon={['fas', 'globe']} color={(data.value1 ? data.value1 : 0) > idx ? '#004480' : '#999999'} />)}
            </div>}
            { !isValidNumber(data.value1) ? <div className={styles.subText}>{'--'}</div> : ''}
            {(isValidNumber(data.value1) && !showGlobe) && <div className={styles.subText}><span className={styles.subText}>{isValidNumber(data.value1) ? formatNumber(data.value1, formatOptions) : ''}</span> <span className={styles.subText2}>{data.subText1}</span></div>
            }
          </div>
        </div>
        <div className={styles.riskScoreBoxItems}>
          <div className={styles.subheading}>{popOver2()}</div>
          { (!isValidNumber(data.value2)) ? <div className={styles.subText}>{'--'}</div> : <div className={styles.subText}>
            <span className={styles.subText}>{isValidNumber(data.value2) ? (isNaN(data.value2) ? data.value2 : formatNumber(data.value2, formatOptions)) : ''}</span>
            <span className={styles.subText2}>{data.subText2}</span>
          </div>
          }
        </div>
        <div className={styles.riskScoreBoxItems}>
          <div className={styles.subheading}>{popOver3()}</div>
          { (!isValidNumber(data.value3)) ? <div className={styles.subText}>{'--'}</div> : <div className={styles.subText}>
            <span className={styles.subText}>{isValidNumber(data.value3) ? (isNaN(data.value3) ? data.value3 : formatNumber(data.value3, formatOptions)) : ''}</span>
            <span className={styles.subText2}>{data.subText3}</span>
          </div>
          }
        </div>
      </div>
    </div>
  )
}
ESGScoreTop.propTypes = {
  data: PropTypes.object,
  headers: PropTypes.object,
  showGlobe: PropTypes.bool,
  formatOptions: PropTypes.object,
  isNXI: PropTypes.bool
}
export default ESGScoreTop
