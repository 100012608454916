/* eslint-disable no-debugger */
/* eslint-disable padded-blocks */
import CategoryTags from '../../../data/CategoryTags.json'
import CategoryFieldTags from '../../../data/CategoryFieldTags.json'

export function GetTagsFromCategories (categories) {
  try {
    if (!categories) {
      return {}
    }
    let arr = categories.replace(/\n/g, '|').split('|').filter(item => (item !== ''))
    let tagsObj = {}
    arr.forEach((category) => {
      if (CategoryTags.CategoriesTagsMap[category] && !tagsObj.hasOwnProperty(CategoryTags.CategoriesTagsMap[category])) {
        tagsObj[CategoryTags.CategoriesTagsMap[category]] = ''
      }
    })
    return tagsObj

  } catch (err) {
    return {}
  }
}
export function GetFieldNameFromCategory (category) {
  try {
    if (CategoryFieldTags.CategoriesTagsMap[category]) {
      return CategoryFieldTags.CategoriesTagsMap[category]
    }
  } catch (err) {
    return ''
  }
}
export function sanitizeTeaser (text) {
  if (text) {
    let refinedStory = text.split('-- ')
    if (refinedStory[0].length > 0 && refinedStory.length > 1) {
      const tag = refinedStory[0].match((/(<([^>]+)>)/ig), '') // Regex to match the tag html tag <> </>
      refinedStory[0] = tag && tag[0]
    }
    refinedStory = refinedStory.join('')
    return refinedStory
  }
  return null
}
