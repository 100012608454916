/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from 'react'
import ModalService from '../service/ModalService'
import PropTypes from 'prop-types'
import styles from '../Modal.module.scss'

export default function ModalRoot (props) {
  const [modal, setModal] = useState({})
  useEffect(() => {
    ModalService.on('open', ({ component, props }) => {
      setModal({
        component,
        props,
        close: value => {
          // if (document.getElementById('textmsg')) {
          //   document.getElementById('navModel Basics').focus()
          // }
          resetParentDropdown()
          setModal({})
        }
      })
    })
  }, [])

  function resetParentDropdown () {
    props.closeHandler()
  }

  function saveBtn () {
    let screenNameToSave = document.getElementById('screenNameText').value
    let screenDescriptionToSave = (document.getElementById('screenDescriptionText') && document.getElementById('screenDescriptionText').value) || ''
    let oldScreenName = (document.getElementById('defaultscreenNameText') && document.getElementById('defaultscreenNameText').value) || ''
    let oldScreenDescription = (document.getElementById('defaultScreenDescriptionText') && document.getElementById('defaultScreenDescriptionText').value) || ''
    let isEditMode = (document.getElementById('isEditMode') && document.getElementById('isEditMode').value) || 'false' === 'true'
    let alreadyExixts = Object.keys(props.savedScreenName).some(function (item) {
      return props.savedScreenName[item].trim().toLocaleLowerCase() === screenNameToSave.trim().toLocaleLowerCase()
    })
    if (isEditMode === 'false') { // Create new screener
      props.saveBtnHandler(!alreadyExixts, screenNameToSave, screenDescriptionToSave, false)
    } else { // Update existing screener
      if (oldScreenName.trim() !== '' && oldScreenName === screenNameToSave && oldScreenDescription === screenDescriptionToSave && alreadyExixts) {
        props.saveBtnHandler(true, screenNameToSave, screenDescriptionToSave, true)
      } else if (oldScreenName !== '' && oldScreenName !== screenNameToSave && alreadyExixts) {
        props.saveBtnHandler(!alreadyExixts, screenNameToSave, screenDescriptionToSave, true)
      } else {
        props.saveBtnHandler(true, screenNameToSave, screenDescriptionToSave, true)
      }
    }
  }

  const ModalComponent = modal.component ? modal.component : null

  return (
    <section className={modal.component ? styles.modalRoot : ''}>

      { ModalComponent && (
        <ModalComponent
          {...modal.props}
          errorMsg={props.errorMsg}
          close={modal.close}
          header={props.header}
          bodyLabel={props.bodyLabel}
          bodyDescription={props.bodyDescription}
          saveBtn={saveBtn}
          deleteBtnHandler={props.deleteBtnHandler}
          openBtnHandler={props.openBtnHandler}
          compareBtnHandler={props.compareBtnHandler}
          cardCount={props.cardCount}
          screenId={props.screenId}
          textChange={props.textChange}
          showSavedAndPredefinedScreener={props.showSavedAndPredefinedScreener}
          showActionKebabMenu={props.showActionKebabMenu}
          editScreenData={props.editScreenData}
        />
      )}

    </section>
  )
}

ModalRoot.propTypes = {
  header: PropTypes.string,
  bodyLabel: PropTypes.string,
  bodyDescription: PropTypes.string,
  errorMsg: PropTypes.string,
  saveBtnHandler: PropTypes.func,
  deleteBtnHandler: PropTypes.func,
  openBtnHandler: PropTypes.func,
  compareBtnHandler: PropTypes.func,
  closeHandler: PropTypes.func,
  cardCount: PropTypes.number,
  savedScreenName: PropTypes.array,
  screenId: PropTypes.string,
  textChange: PropTypes.func,
  showSavedAndPredefinedScreener: PropTypes.bool,
  showActionKebabMenu: PropTypes.bool,
  editScreenData: PropTypes.func
}
