import React from 'react'
import styles from './Button.module.scss'
import PropTypes from 'prop-types'

const Button = (props) => {
  return (<button type='button' tabIndex={props.tabIndex} className={`${styles.custombutton} ${props.className}`} title={props.labelText} data-testid={props.buttonDataTestId} onClick={props.clickHandler} onKeyDown={props.onKeyDownEnter}>{props.labelText}</button>)
}
Button.propTypes = {
  clickHandler: PropTypes.func,
  className: PropTypes.string,
  labelText: PropTypes.string,
  tabIndex: PropTypes.string,
  buttonDataTestId: PropTypes.string,
  onKeyDownEnter: PropTypes.func
}
export default Button
