/* eslint-disable no-debugger */
import React from 'react'
import PropTypes from 'prop-types'
import styles from './DynamicPopOver.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

function DynamicPopOver (props) {
  const getOtherValues = getValuesFromProps()
  function onClearClick (e) {
    e && e.stopPropagation()
    props.onClearClick(e)
  }
  function onFiringEnter (e) {
    if (e.key === 'Enter') {
      onClearClick(e)
    }
  }
  function getValuesFromProps () {
    const selectedValues = props.remainingValues
    const selectedKeys = Object.keys(selectedValues)
    let tags = []
    let counter = 0
    selectedValues && selectedKeys && selectedKeys.forEach((key) => {
      if (selectedValues[key].length > 0) {
        selectedValues[key].forEach((item) => {
          tags.push(<div key={counter} title={item} className={styles.childPopOverContainer}>
            <div className={styles.popOverText}>{item}</div>
            <div className={` ${styles.clearPopOverText} crossButtonPopOver`} tag={key} value={item} tabIndex='0' role='button' onClick={onClearClick} onKeyDown={onFiringEnter}><FontAwesomeIcon icon={['fal', 'times']} /></div>
          </div>)
          counter++
        })
      }
    })

    return tags
  }
  return (
    <div className={styles.dynamicPopOverContainer}>
      {getOtherValues}
    </div>
  )
}

export default DynamicPopOver

DynamicPopOver.propTypes = {
  remainingValues: PropTypes.object,
  onClearClick: PropTypes.func
}
