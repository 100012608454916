import React from 'react'
import styles from './Button.module.scss'
import PropTypes from 'prop-types'
import Button from './Button'

const SecondryButton = ({buttonText, clickHandler, dataTestId, tabIndex, customClass, keyDownHandler}) => {
  return (<Button
    className={`${styles.resetButton} ${customClass}`}
    tabIndex={tabIndex}
    buttonDataTestId={dataTestId}
    onKeyDownEnter={keyDownHandler || clickHandler}
    clickHandler={clickHandler}
    labelText={buttonText}
    title={buttonText} />)
}
SecondryButton.propTypes = {
  buttonText: PropTypes.string,
  clickHandler: PropTypes.func,
  dataTestId: PropTypes.string,
  tabIndex: PropTypes.string,
  customClass: PropTypes.string,
  keyDownHandler: PropTypes.func
}
export default SecondryButton
