import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './SimpleModal.module.scss'
import PopupAccessibilty from '../../CustomHook/PopupAccessibilty'

const SimpleModal = props => {
  PopupAccessibilty(props)

  return (<div className={styles.modalConatainer}>
    <div className={styles[props.id]}>
      <div id={props.id} className={styles.modalPopUp} >
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            <div className={styles.headerText} aria-label={props.header}>{props.header}</div>
          </div>
          <div className={styles.closeSection}>
            <button className={styles.btnPopUpClose} data-testid='closeModal' aria-label='Close' onClick={props.close}>
              <FontAwesomeIcon icon={['fal', 'times']} className={styles.btnPopUpClose} data-icon-style='fa-compare-close' id='closeButtonFontAwsome' />
            </button>
          </div>
        </div>
        <div className={styles.mainModal}>
          {props.children}
        </div>
      </div>
    </div>
  </div>)
}
SimpleModal.propTypes = {
  close: PropTypes.func,
  id: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.node
}

export default SimpleModal
