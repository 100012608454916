import React from 'react'
import styles from './NoData.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
function Nodata (props) {
  return (
    <div className={styles.noData} style={props.style} data-value='No Data'>
      {!props.hideBellIcon ? <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className={`${styles.iconNoData}`} /> : ''}

      <div className={`${styles.noactivity}`}>
        {!props.msg ? 'Data not available' : props.msg}
      </div>
    </div>
  )
}

export default Nodata
Nodata.propTypes = {
  style: PropTypes.object,
  msg: PropTypes.string,
  hideBellIcon: PropTypes.bool
}
