import React from 'react'
import PropTypes from 'prop-types'
import ModalPopUp from '../../../../Lib/ModalPopUp/ModalPopUp'
import { hideScroll } from '../../../../../utils/utilities'
import CompanyDetailsHeader from '../../../../Stocks/CompanyDescription/CompanyDetailsHeader/CompanyDetailsHeader'
import styles from './ConfirmationPopup.module.scss'
import Divider from '../../../../Lib/Divider/Divider'
import PrimaryButton from '../../../../Lib/Button/PrimaryButton'
import SecondryButton from '../../../../Lib/Button/SecondryButton'

export default function ConfirmationPopup ({headerInfo, bodyContent, closeHandler, confirmationHandler, confirmButtonText = 'Confirm', cancelButtonText = 'Cancel'}) {
  hideScroll(true)
  const closePopup = (e) => {
    hideScroll(false)
    closeHandler()
  }

  return (
    <ModalPopUp stylesContent={{'height': 'auto', width: '480px'}} stylesPopUp={{'minHeight': 'auto'}} customHeader customBody>
      <CompanyDetailsHeader companyName={headerInfo} onClose={closePopup} />
      <React.Fragment>
        <div className={styles.confirmationMsg}><div dangerouslySetInnerHTML={{ __html: bodyContent }} /></div>
        <div className={styles.confirmationFooter}>
          <Divider style={{color: 'rgba(0,0,0,0.30)', borderBottom: 'none', marginTop: '0px', height: '1px'}} />
          <div className={styles.confirmationBtnContainer}>
            <PrimaryButton buttonText={confirmButtonText} clickHandler={confirmationHandler} />
            <SecondryButton dataTestId='cancelButton' buttonText={cancelButtonText} clickHandler={closePopup} />
          </div>
        </div>
      </React.Fragment>
    </ModalPopUp>
  )
}
ConfirmationPopup.propTypes = {
  headerInfo: PropTypes.string,
  bodyContent: PropTypes.string,
  closeHandler: PropTypes.func,
  confirmationHandler: PropTypes.func,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string
}
