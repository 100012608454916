/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import PerformanceComparisonChart from '../PerformanceComparison/PerformanceComparisonChart'
import styles from './PerformanceOnComparePage.module.scss'
import { FUNDS_PERFORMANCE, TIME_PERIOD, dropDownDaysValues, dropDownDaysValuesText, CHART_STYLES, CardTopBorderColors } from '../../../components/utils/appConstants'
import { handleWindowResize, getDeviceType, deviceType } from '../../utils/utilities'
import BasicDropDown from '../../../../../Lib/Dropdown/BasicDropDown'
import TabControl from '../TabControl/TabControl'
import PropTypes from 'prop-types'
import CostCompare from '../../CostCompare/CostCompare'

const DefaultActiveTabKey = 'Fundamental Comparison'
const PerformanceOnComparePage = (props) => {
  const [device, setDeviceType] = useState(getDeviceType())
  const [intervalMapping] = useState(FUNDS_PERFORMANCE.intervalMapping)
  const [periodMapping] = useState(FUNDS_PERFORMANCE.periodMapping)
  const [dropDownDataDays] = useState(Object.keys(dropDownDaysValues))
  const [dropDownDaysValuesConstant] = useState(dropDownDaysValuesText)
  const [selectedDays, setSelectedDays] = useState(dropDownDaysValues['1 Year'])
  const [activeTabKey, setActiveTabKey] = useState(DefaultActiveTabKey)
  const [hasTabChanged, setHasTabChanged] = useState(false)
  const primaryVenueXid = useRef(0)
  const mutualFundConstant = ['FSCMF', 'MF']
  useEffect(() => {
    handleWindowResize((response) => {
      setDeviceType(response)
    })
  }, [])

  const callChart = e => {
    if (!e) return
    e.preventDefault()
    setSelectedDays(TIME_PERIOD[e.target.innerText])
  }
  const dropDownDaysChangeHandler = (e) => {
    setSelectedDays(dropDownDaysValues[e.getAttribute('data-value')])
  }
  function changeHandlerTab (e) {
    setActiveTabKey(e)
    setHasTabChanged(activeTabKey !== DefaultActiveTabKey)
  }
  function getChartSelection () {
    let body = []
    let bodyHtml
    if (device === deviceType.Desktop || device === deviceType.Ipad) {
      bodyHtml =
        <div className={styles.itemsContainer} key={device}>
          <div className={`${selectedDays === 1 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart} >1D</button>
          </div>
          <div className={`${selectedDays === 7 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>1W</button>
          </div>
          <div className={`${selectedDays === 30 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>1M</button>
          </div>
          <div className={`${selectedDays === 180 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>6M</button>
          </div>
          <div className={`${selectedDays === 365 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>1Y</button>
          </div>
          <div className={`${selectedDays === 1825 ? styles['selectedItem'] : ''} ${styles['item']}`}>
            <button className={styles.button} onClick={callChart} onKeyPress={callChart}>5Y</button>
          </div>
        </div>
    } else if (device === deviceType.Mobile) {
      bodyHtml =
        <div className={styles.itemsContainer} key={device}>
          <div className={styles['row1FirstDD']}>
            <BasicDropDown selectedValue={dropDownDaysValuesConstant[selectedDays.toString()]} data={dropDownDataDays} changeHandler={dropDownDaysChangeHandler} />
          </div>
        </div>
    }
    body.push(bodyHtml)
    return body
  }
  const getChartInputs = () => {
    const dataInterval = intervalMapping[selectedDays]
    const dataPeriod = periodMapping[selectedDays]

    return {
      'timePeriod': selectedDays,
      'dataInterval': dataInterval,
      'dataPeriod': dataPeriod,
      'chartType': CHART_STYLES[0].value,
      'primaryVenueXid': getVenueXids('primary'),
      'VenueXidsList': getVenueXids('secondary')
    }
  }
  const hasMF = () => {
    for (let key in props.modelCompareCardData) {
      if (mutualFundConstant.includes(props.modelCompareCardData[key].type)) {
        return true
      }
    }
    return false
  }
  const getVenueXids = (type) => {
    if (selectedDays > 7 || !(hasMF())) {
      if (type === 'primary') {
        const xid = props.modelCompareCardData && props.modelCompareCardData[0] ? props.modelCompareCardData[0].venueXid : null
        return {venueXid: xid, color: CardTopBorderColors[0]}
      } else {
        const xids = props.modelCompareCardData && props.modelCompareCardData.length > 1 ? (props.modelCompareCardData.map((s, i) => s.venueXid)).slice(1) : null
        const VenueXidList = []
        if (xids) {
          for (var i = 0; i < xids.length; i++) {
            VenueXidList.push({ venueXid: xids[i], color: CardTopBorderColors[i + 1] })
          }
        }
        return VenueXidList
      }
    } else if (hasMF()) {
      if (type === 'primary') {
        let i = 0
        // eslint-disable-next-line no-unused-vars
        for (let key in props.modelCompareCardData) {
          if (!mutualFundConstant.includes(props.modelCompareCardData[key].type)) {
            primaryVenueXid.current = props.modelCompareCardData[key].venueXid
            return { venueXid: primaryVenueXid.current, color: CardTopBorderColors[i] }
          }
          i++
        }
      } else {
        const VenueXidList = []
        let i = 0
        // eslint-disable-next-line no-unused-vars
        for (let key in props.modelCompareCardData) {
          if (!mutualFundConstant.includes(props.modelCompareCardData[key].type) && props.modelCompareCardData[key].venueXid !== primaryVenueXid.current) {
            VenueXidList.push({ venueXid: props.modelCompareCardData[key].venueXid, color: CardTopBorderColors[i] })
          }
          i++
        }
        return VenueXidList
      }
    }
  }
  const getLegendWidth = () => {
    var noOfItem = 1
    if (device === deviceType.Desktop) {
      noOfItem = 5
    } else if (device === deviceType.Ipad) {
      noOfItem = 3
    }
    return {width: (97 / noOfItem) + '%'}
  }
  const getLegends = () => {
    return props.modelCompareCardData.map((item, index) => {
      if (selectedDays > 7 || !(hasMF())) {
        return <div className={styles.blockDiv} style={getLegendWidth()}>
          <div className={styles.circleLegend} style={{ backgroundColor: CardTopBorderColors[index] }} />
          <div className={styles.internalBlockDiv}>{item.name}</div>
        </div>
      } else if (hasMF()) {
        return (!mutualFundConstant.includes(item.type)) ? <div className={styles.blockDiv} style={getLegendWidth()}>
          <div className={styles.circleLegend} style={{ backgroundColor: CardTopBorderColors[index] }} />
          <div className={styles.internalBlockDiv}>{item.name}</div>
        </div> : null
      }
    })
  }

  const chartInputs = getChartInputs()
  return (
    <TabControl id={'ComparePage_tab'} ariaLabel={'tabs'} activeTab={activeTabKey} changeHandler={(e) => changeHandlerTab(e)}>
      <div key={'Fundamental Comparison'} label='Fundamental Comparison'>
        { activeTabKey === 'Fundamental Comparison' && <div className={styles.performanceContainer}>
          <span className={styles.TopText}>Performance Comparison
          </span>
          <div className={styles.chartSelections}>
            {getChartSelection()}
          </div>
          <div className={styles.legendsChart}>
            {getLegends()}
          </div>
          <div className={chartInputs && chartInputs.primaryVenueXid ? styles.chart : null}>
            {props.modelCompareCardData && <PerformanceComparisonChart showChartDisclaimer={hasMF() && selectedDays <= 7} chartInputs={chartInputs} modelCompareCardData={props.modelCompareCardData} hasTabChanged={hasTabChanged} />}
          </div>
        </div>}
      </div>
      <div key={'Cost Comparison'} label='Cost Comparison'>
        { activeTabKey === 'Cost Comparison' && <CostCompare modelCompareCardData={props.modelCompareCardData} />}
      </div>
    </TabControl>
  )
}
PerformanceOnComparePage.propTypes = {
  modelCompareCardData: PropTypes.object
}
export default PerformanceOnComparePage
