import React from 'react'
import styles from './Square.module.scss'
import PropTypes from 'prop-types'
import StyleboxUtility from '../StyleboxUtility'

const Square = (props) => {
  if (props.rowCell === StyleboxUtility.ROW_COLUMN_LABEL) {
    return (
      <div className={styles[props.rowCell]} >
        <div
          key={props.keyVal}
        >
          <span>{props.rowColumnLabel}</span>
        </div>
      </div>
    )
  }

  if (props.columnCell && Object.values(StyleboxUtility.columnStyle).includes(props.columnCell)) {
    return (
      <div className={styles[props.columnCell]} >
        <div className={styles[StyleboxUtility.LAST_ROW_LABEL]}
          key={props.keyVal}
        >
          <span>{props.lastRowlabel}</span>
        </div>
      </div>
    )
  }

  return (
    <div className={styles[props.rowCell]} >
      <div className={styles.square}
        key={props.keyVal}
      >
        { props.isFocusedCell &&
        <div className={styles[StyleboxUtility.FOCUSED_CELL]} />
        }
      </div>
    </div>
  )
}

Square.propTypes = {
  rowCell: PropTypes.any,
  keyVal: PropTypes.any,
  rowColumnLabel: PropTypes.any,
  columnCell: PropTypes.any,
  lastRowlabel: PropTypes.any,
  isFocusedCell: PropTypes.bool
}
export default Square
