
const ROW_COLUMN_LABEL = 'rowColumnLabel'
const FOCUSED_CELL = 'focusedCell'
const LAST_ROW_LABEL = 'lastRowlabel'

const rowStyle = {
  firstRow: 'firstRow',
  middleRow: 'middleRow',
  lastRow: 'lastRow',
  rowWithLabel: 'rowColumnLabel'
}

const columnStyle = {
  firstLastLabelCell: 'firstLastLabelCell',
  middleLastLabelCell: 'middleLastLabelCell',
  lastLabelCell: 'lastLabelCell',
  lastRowlabel: 'lastRowlabel'
}

const getColumnCellStyle = (key) => {
  switch (key) {
    case 0:
      return columnStyle.firstLastLabelCell
    case 1:
      return columnStyle.middleLastLabelCell
    case 2:
      return columnStyle.lastLabelCell
    case 3:
      return columnStyle.lastRowlabel
  }
}

const getRowCellStyle = (key) => {
  switch (key) {
    case 0:
      return rowStyle.firstRow
    case 1:
      return rowStyle.middleRow
    case 2:
      return rowStyle.lastRow
    case 3:
      return rowStyle.rowWithLabel
  }
}

const getRowColumnLabel = (key, isEquityStylebox) => {
  switch (key) {
    case 0:
      return isEquityStylebox ? RowLabel.equity.Large : RowLabel.bond.High
    case 1:
      return isEquityStylebox ? RowLabel.equity.Medium : RowLabel.bond.Medium
    case 2:
      return isEquityStylebox ? RowLabel.equity.Small : RowLabel.bond.Small
  }
}

const getLastRowLabel = (key, isEquityStylebox) => {
  switch (key) {
    case 0:
      return isEquityStylebox ? ColumnLabel.equity.Value : ColumnLabel.bond.Short
    case 1:
      return isEquityStylebox ? ColumnLabel.equity.Blend : ColumnLabel.bond.Interm
    case 2:
      return isEquityStylebox ? ColumnLabel.equity.Growth : ColumnLabel.bond.Long
  }
}

const RowLabel = {
  equity: {
    Large: 'Large',
    Medium: 'Medium',
    Small: 'Small'
  },
  bond: {
    High: 'High',
    Medium: 'Medium',
    Small: 'Low'
  }
}

const ColumnLabel = {

  equity: {
    Value: 'Value',
    Blend: 'Blend',
    Growth: 'Growth'
  },
  bond: {
    Short: 'Limited',
    Interm: 'Moderate',
    Long: 'Extensive'
  }
}

const rowColumnDataMapping = [
  {
    row: 0,
    column: 0,
    value: 1
  },
  {
    row: 0,
    column: 1,
    value: 2

  },
  {
    row: 0,
    column: 2,
    value: 3
  },
  {
    row: 1,
    column: 0,
    value: 4

  },
  {
    row: 1,
    column: 1,
    value: 5

  },
  {
    row: 1,
    column: 2,
    value: 6

  },
  {
    row: 2,
    column: 0,
    value: 7

  },
  {
    row: 2,
    column: 1,
    value: 8

  },
  {
    row: 2,
    column: 2,
    value: 9

  }
]

export default {
  ROW_COLUMN_LABEL,
  FOCUSED_CELL,
  LAST_ROW_LABEL,
  columnStyle,
  getColumnCellStyle,
  getRowCellStyle,
  getRowColumnLabel,
  getLastRowLabel,
  rowColumnDataMapping
}
