import React, {useState, useEffect, useRef} from 'react'
import Modal from '../Modal/components/Modal'
import ModalBody from '../Modal/components/ModalBody'
import ModalHeader from '../Modal/components/ModalHeader'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from '../Modal/Modal.module.scss'
import TableWithShowMore from '../Table/TableWithShowMore'
import ViewShareClassTableHeaders from './ViewShareClassTableHeaders.json'
import {URLS, DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS, FundShareClassScreenerResultArr} from '../../../components/utils/appConstants'
import DataRequest from '../../../../../../services/DataRequest'
import { textFormatter, formatNumber, convertToInternationalCurrencySystem } from '../../utils/formatter'
// import data from '../../FundScreener/FundScreenerMockData/Profile/ProfileScreenerData.json'
import MaxSelectErrorModel from '../MaxSelectErrorModel/MaxSelectErrorModel'
import Loader from '../../../../../Lib/common/Loader'
import {screenerBoolDictionary, fundVestTag} from '../../Helpers/CommonFunctions'
import { CSVLink } from 'react-csv'
export default function ViewShareClassModal (props) {
  const [modelCompare, setModelCompare] = useState([])
  const [tableData, setTableData] = useState({data: []})
  const [csvTableData, setCsvTableData] = useState([])
  const [isLoading, setLoader] = useState(true)
  const [maxSelectionLength, setmaxSelectionLength] = useState(9)
  const ViewShareClassTableHeader = ViewShareClassTableHeaders.headers
  const symbolCompareText = 'Select symbols to add them to the comparison.'
  const totalSymbolText = 'You can add up to ten symbols total.'
  useEffect(() => {
    getSimilarShareClasses(props.venueXid)
  }, [props.venueXid])
  const [csvData, setCsvData] = useState({exportData: [], fileName: 'ViewShareClasses.csv'})
  const csvInstance = useRef()
  function modelComparisonErrorPopup () {
    document.getElementById('maxErrorModel').style.display = ''
    document.getElementById('maxSelectError').focus()
    document.getElementById('ViewShareClassModal').style.pointerEvents = 'none'
  }

  function errorModelClose () {
    document.getElementById('compareBtn').focus()
    document.getElementById('maxErrorModel').style.display = 'none'
    document.getElementById('ViewShareClassModal').style.pointerEvents = 'revert'
  }
  function handleOnCheckboxChange (modelSelection) {
    let maxSelection = 10 - props.cardCount
    setmaxSelectionLength(maxSelection)
    if (modelSelection.length > 9 || props.cardCount === 10 || ((modelSelection.length + props.cardCount) > 10)) {
      modelComparisonErrorPopup()
    } else {
      if (modelSelection.indexOf(props.venueXid) > -1) {
        let index = modelSelection.indexOf(props.venueXid)
        modelSelection.splice(index, 1)
      }
      setModelCompare(modelSelection)
    }
  }
  function sortColumnDataHandler (_columnName, _sortDirection, comingFromChild, defaultSorting = false) {
  }
  function compareBtn () {
    if (modelCompare.length > 0 && tableData) {
      let checkedItems = [...modelCompare]
      let checkedItemsData = []
      for (let i = 0; i < modelCompare.length; i++) {
        let checkedFilterItemsData = tableData.data.filter(x => x.xid === checkedItems[i])
        checkedFilterItemsData && checkedItemsData.push(checkedFilterItemsData[0])
      }
      props.compareBtnHandler(checkedItemsData)
      props.close()
    }
  }
  useEffect(() => {
    const tableDivId = document.getElementById('tableBodyViewShareClassModal')
    const viewShareclassModalNode = document.querySelectorAll('#ViewShareClassModal') && document.querySelectorAll('#ViewShareClassModal')[1]
    if (document.getElementById('tableBodyViewShareClassModal') && viewShareclassModalNode) {
      viewShareclassModalNode.style.margin = '0px 10px'
      tableDivId.style.overflowX = 'scroll'
      tableDivId.style.maxHeight = '400px'
      // Hack to handle y-axis scrollbar, here scrollbar comes after 400(as per above line)
      if (tableDivId.scrollHeight >= 400) {
        tableDivId.style.width = 'calc(100% + 4px)'
      } else {
        tableDivId.style.width = '100%'
      }
    }
  }, [tableData])

  function getSimilarShareClasses (venueXid) {
    let callback = (response) => {
      if (!response.isDataRequestComplete || response.isError) props.close()
      if (!response.isError) {
        let shareClassesData = response &&
          response.data
        if (shareClassesData.relatedShareclasses && shareClassesData.relatedShareclasses.length > 0) {
          getTableData(shareClassesData.relatedShareclasses)
        }
      }
    }

    DataRequest.execute(
      URLS.PLATFORM.VIEWSHARECLASSES,
      {
        params: {
          venueXid: [venueXid]
        }
      },
      callback
    )
  }

  function getTableData (relatedShareclasses) {
    setLoader(true)
    const screenDataCallback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        const responseData = response.data || {}
        let tableData = []
        let csvTableData = []
        responseData.items && responseData.items.map(item => {
          tableData.push({
            xid: item.SPCFWSODIssue,
            Ticker: textFormatter(item.SPCFticker),
            type: item.SPCFFundType,
            searchType: item.SPCFFundType,
            symbol: item.SPCFticker,
            Type: item.SPCFFundType,
            CUSIP: item.SPCFMStarOverrideCUSIP,
            ...getCsvTableData(item)
          })
          csvTableData.push(getCsvTableData(item))
        })
        tableData.sort((x, y) => { return x.xid === props.venueXid ? -1 : y.xid === props.venueXid ? 1 : 0 })
        setTableData({data: [...tableData]})
        setCsvTableData(csvTableData)
      }
      setLoader(false)
    }
    let xidValues = []
    relatedShareclasses.map(item => {
      xidValues.push(item.venueXID.toString())
    })
    let screenerArguments = []
    let newArgument =
    {
      field: 'SPCFWSODIssue',
      conditions: [
        {
          operator: 'Like',
          values: xidValues
        }
      ]
    }
    screenerArguments.push(newArgument)
    DataRequest.execute(
      `${URLS.CUSTOM.SCREEN + '?screenType=MF_ETF'}`,
      buildScreenInputs(0, false, screenerArguments),
      screenDataCallback
    )
  }
  const buildScreenInputs = (_offset, isExportToCsv, screenerArguments, sortArguments) => {
    const screenInputs = DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS
    screenInputs.offset = _offset
    screenInputs.arguments = screenerArguments || []
    screenInputs.sortArguments = sortArguments || [
      {
        direction: 'A',
        field: 'SPCFGrossExpenseRatio'
      }
    ]
    screenInputs.limit = 100
    screenInputs.resultFields = FundShareClassScreenerResultArr
    return {
      method: 'POST',
      postData: screenInputs
    }
  }

  function getCsvTableData (item) {
    return {
      'Symbol': textFormatter(item.SPCFticker),
      'Name': textFormatter(item.SPCFMStarOverrideFundName),
      'FundVest': textFormatter(fundVestTag(item)),
      'Share Class Net Assets': item.SPCFFundNetAssets && '$' + convertToInternationalCurrencySystem(item.SPCFFundNetAssets),
      // 'Leveraged funds': numberFormatter(item.SPCFLeveragedFundFlag),
      'Performance (3Yr)': formatNumber(item.SPCFTrailingReturnY3, { precision: 2, parentheses: false }),
      'Gross Expense Ratio': formatNumber(item.SPCFGrossExpenseRatio, { postFix: true, postFixValue: '%' }),
      'Net Expense Ratio': formatNumber(item.SPCFProspectusNetExpenseRatio, { postFix: true, postFixValue: '%' }),
      'Purchase Minimum': formatNumber(item.SPCFMinimumInitialPurchaseAmount),
      'Closed to Buyer': textFormatter(screenerBoolDictionary[item.SPCFClosedToBuysIndicator]),
      'Mgmt Fee': formatNumber(item.SPCFManagementFee),
      'Reported 12b-1 Fee': formatNumber(item.SPCFActual12B1Fee),
      'Surcharge': textFormatter(screenerBoolDictionary[item.SPCFSURCHARGE]),
      'Load Type': textFormatter(item.SPCFLoadType),
      'Load': formatNumber(item.SPCFSalesLoadMaxFrontLoad, { postFix: true, postFixValue: '%' })
    }
  }

  function getExportTableData () {
    setCsvData({exportData: csvTableData, fileName: 'ShareClasses' + '.csv'})
    csvInstance.current.link.click()
  }

  function DownloadCsv () {
    getExportTableData()
  }

  function handleDownloadCsv (e) {
    if (e.key === 'Enter') {
      debounce(DownloadCsv, 1000)
    }
  }

  let debounce = (method, delay) => {
    clearTimeout(method._tId)
    method._tId = setTimeout(function () {
      method()
    }, delay)
  }
  return (
    <div className={styles.ViewShareClass}>
      <Modal close={props.close} id='ViewShareClassModal' >
        <ModalHeader modalName={'ViewShareClassModal'}>
          <div className={styles.viewShareClassModalheaderText}>
            <div aria-label={props.header}>{props.header}</div>
          </div>
          <div className={styles.closeSection}>
            <button className={styles.shareClassBtnPopUpClose} aria-label='Close' onClick={props.close}>
              <FontAwesomeIcon icon={['fal', 'times']} id='closeButtonFontAwsome' />
            </button>
          </div>
          <span style={{display: 'none'}}><CSVLink ref={csvInstance} data={csvData.exportData} filename={csvData.fileName} /></span>
        </ModalHeader>
        <ModalBody modalName={'ViewShareClassModal'}>
          <div className={styles.topContainer}>
            <div className={`${styles.shareClassHeading}`}>
              <div className={styles.desktopViewText} aria-label={symbolCompareText + totalSymbolText}>{symbolCompareText + ' ' + totalSymbolText}</div>
              <div className={styles.mobileViewText} aria-label={symbolCompareText + totalSymbolText}>
                <span className={styles.compareSymbolText}>{symbolCompareText}</span>
                <span>{totalSymbolText}</span>
              </div>
            </div>
            <span aria-label='Download CSV' className={styles.displayHidden} id='DownloadCsvBtn' onKeyDown={(e) => handleDownloadCsv(e)} onClick={() => debounce(DownloadCsv, 1000)} role='button' tabIndex='0'>
              <FontAwesomeIcon icon={['fas', 'download']} className={styles.downloadIcon} />
            </span>
            <button className={`${styles.addToComparisonBtn} ${modelCompare.length < 1 ? styles['disabled'] : ''}`} id='compareBtn' tabIndex='0' onClick={compareBtn}>
              Add to Comparison
            </button>
          </div>
          {isLoading && <Loader spinnerSize={'2x'} msg={'Loading'} />}
          <div className={styles.shareClassTableSection}>
            <TableWithShowMore
              key='ViewShareClassModalTable'
              chkboxSelectedIds={modelCompare}
              ModelScreenerData={tableData}
              headers={ViewShareClassTableHeader}
              handleOnCheckboxChange={handleOnCheckboxChange}
              maxSelectionLength={maxSelectionLength}
              moduleType={'ViewShareClassModalTable'}
              fixedColumnCount={2}
              moduleName={'ViewShareClassModal'}
              startIndex={0}
              sortColumnData={sortColumnDataHandler} />
          </div>
        </ModalBody>
      </Modal>
      <MaxSelectErrorModel errorModelClose={errorModelClose} />
    </div>
  )
}

ViewShareClassModal.propTypes = {
  close: PropTypes.func,
  compareBtnHandler: PropTypes.func,
  header: PropTypes.string,
  cardCount: PropTypes.number,
  venueXid: PropTypes.number
}
