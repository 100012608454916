import React, { } from 'react'
import PropTypes from 'prop-types'
import styles from './CarbonRiskScoreRating.module.scss'
import ScoreBar from '../../../Shared/ESGScores/ESGScoreBar/ScoreBar'
import { COLOR_DEFAULT_SET, NXI_COLOR_DEFAULT_SET } from '../../../../../../../utils/appConstants'
import Divider from '../../../../../../Lib/Divider/Divider'
import { formatNumber } from '../../../utils/formatter'
import {carbonScore} from "../../../utils/appConstants"
import ESGScoreBar from '../../../../../../NXIEsgComponents/ESG/ESGScoreBar/ESGScoreBar'
import Polygone from '../../../../../../../assets/@Icons/Polygone'

const CARBON_RISK_SCORE_RATING_CONSTANTS = {
  CURRENT_CARBON_SCORE: {
    label: 'Current Carbon Score'
  },
  HISTORICAL_AVERAGE: {
    label: '12 Mon Historical Avg.'
  },
  CATEGORY_AVERAGE: {
    label: 'Category Avg.'
  }
}

const GLOBAL_CATEGORY_RANGE = {
  label: 'Global Category Range'
}

function CarbonRiskScoreRating ({data, isNXI=false}) {
  function setRefineData () {
    let result = []
    if (data?.fund?.risk?.score) {
      const {carbonRisk, historicalCarbonRisk} = data.fund.risk.score
      result.push({
        score: carbonRisk,
        color: isNXI ? NXI_COLOR_DEFAULT_SET[0].value : COLOR_DEFAULT_SET[0].value
      })
      result.push({
        score: historicalCarbonRisk,
        color: isNXI ? NXI_COLOR_DEFAULT_SET[1].value : COLOR_DEFAULT_SET[1].value
      })
    }
    if (data?.category?.risk?.score) {
      const {carbonRisk: categoryAvg} = data.category.risk.score
      result.push({
        score: categoryAvg,
        color: isNXI ? NXI_COLOR_DEFAULT_SET[2].value : COLOR_DEFAULT_SET[2].value
      })
    }
    return result
  }

  function getCarbonRiskScoreData () {
    let resultObj = {}
    let resultObjLength = 0
    if (data?.category?.risk?.score) {
      const {lowest, highest} = data.category.risk.score
      const result = setRefineData()
      if (result) {
        resultObj = result.reduce((accumulator, val, idx) => {
          accumulator['score' + idx] = formatNumber(val['score'])
          accumulator['score' + idx + '_color'] = val['color']
          return accumulator
        }, {})
        resultObj.ariaLabel = {
          ariaLabel0: CARBON_RISK_SCORE_RATING_CONSTANTS.CURRENT_CARBON_SCORE.label,
          ariaLabel1: CARBON_RISK_SCORE_RATING_CONSTANTS.HISTORICAL_AVERAGE.label,
          ariaLabel2: CARBON_RISK_SCORE_RATING_CONSTANTS.CATEGORY_AVERAGE.label
        }
        resultObj.label = ''
        resultObj.minMax = {
          low: formatNumber(lowest),
          high: formatNumber(highest),
          lowLabel: 'Low Risk',
          highLabel: 'High Risk'
        }
        resultObjLength = result.length
      }
    }
    return (<div className={styles.scoreBarContainer}>
      {isNXI? <ESGScoreBar data={resultObj} length={resultObjLength} />: <ScoreBar data={resultObj} length={resultObjLength} />}
    </div>)
  }

  function Header () {
    return (<div className={styles.headerContainer}>
      {Object.keys(CARBON_RISK_SCORE_RATING_CONSTANTS).map((key, idx) => {
        return <div className={styles.labelContainer} key={idx}>
          <div className={styles.circle} style={{background: COLOR_DEFAULT_SET[idx].value}} />
          <div className={styles.labelDesc}>{CARBON_RISK_SCORE_RATING_CONSTANTS[key].label}</div>
        </div>
      })}
      <div className={styles.labelContainer}>
        <div className={styles.line} />
        <div className={styles.labelDesc}>{GLOBAL_CATEGORY_RANGE.label}</div>
      </div>
    </div>)
  }
  function NXIHeader () {
    return (
      <div className={styles.headerContainer}>
        <span className={styles.header}>
          Carbon Score
          <span className={`${styles.popup}`}>
            <span>{carbonScore}</span>
          </span>
        </span>
      </div>
    );
  }

  function Footer () {
    return (
      <div className={styles.headerContainer}>
        {Object.keys(CARBON_RISK_SCORE_RATING_CONSTANTS).map((key, idx) => {
          return (
            <div className={styles.labelContainer} key={idx}>
              {idx === 1 && (
                <span className={styles.icon2}><Polygone /></span>
              )}
              {idx === 2 && <span className={styles.icon3} />}
              {idx === 0 && (
                <div
                  className={styles.circle}
                  style={{ background: NXI_COLOR_DEFAULT_SET[idx].value }}
                />
              )}
              <div className={styles.labelDesc}>
                {CARBON_RISK_SCORE_RATING_CONSTANTS[key].label}
              </div>
            </div>
          );
        })}
        <div className={styles.labelContainer}>
          <div className={styles.line} />
          <div className={styles.labelDesc}>{GLOBAL_CATEGORY_RANGE.label}</div>
        </div>
      </div>
    );
  }

  return (<div className={isNXI ? styles.NXICarbonRiskScoreRatingContainer :styles.carbonRiskScoreRatingContainer} data-testid='carbonRiskScoreRatingContainer'>
    <div>
      { isNXI? <NXIHeader />:<Header />}
      <div>
        {getCarbonRiskScoreData()}
      </div>
      { isNXI && <Footer />}
    </div>
    <Divider />
  </div>)
}

export default CarbonRiskScoreRating

CarbonRiskScoreRating.propTypes = {
  data: PropTypes.object,
  isNXI: PropTypes.bool
}
