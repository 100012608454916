/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'throttle-debounce'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import DataRequest from '../../../../services/DataRequest'
import { URLS } from '../../../../utils/appConstants'

import styles from './ChartToolsMenu.module.scss'
import { logDebugInfo } from '../../../../utils/utilities'
import Loader from '../../../Lib/common/Loader'

class ChartToolsMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      noFavorites: true,
      tools: {
        'Arrow': {
          value: 'arrow',
          favorite: false
        },
        'Ellipse': {
          value: 'ellipse',
          favorite: false
        },
        'Extended Line': {
          value: 'extended',
          favorite: false
        },
        'Fibonacci Arc': {
          value: 'fibarc',
          favorite: false
        },
        'Fibonacci Circle': {
          value: 'fibcircle',
          favorite: false
        },
        'Fibonacci Fan': {
          value: 'fibfan',
          favorite: false
        },
        'Fibonacci Retracement': {
          value: 'fibonacci',
          favorite: false
        },
        'Gann Fan': {
          value: 'gannfan',
          favorite: false
        },
        'Horizontal Line': {
          value: 'horizontal',
          favorite: false
        },
        'Line': {
          value: 'line',
          favorite: false
        },
        'Measurement Line': {
          value: 'measurementline',
          favorite: false
        },
        'Ray': {
          value: 'ray',
          favorite: false
        },
        'Rectangle': {
          value: 'rect',
          favorite: false
        },
        'Resistance Line': {
          value: 'extendedhorizontal-resistance',
          favorite: false
        },
        'Support Line': {
          value: 'extendedhorizontal-support',
          favorite: false
        },
        'Text Annotation': {
          value: 'text',
          favorite: false
        }
      }
    }
  }

  componentDidMount () {
    this.getFavoriteTools()
  }

  favoritesClickHandler = (isFavorite, e) => {
    let tool = e.currentTarget.getAttribute('data-value')
    let tempTools = this.state.tools

    tempTools[tool].favorite = isFavorite

    this.setState({
      tools: tempTools,
      noFavorites: this.isNoFavoriteText()
    })

    this.setFavoriteTools(tempTools)
  }
  isNoFavoriteText = () => {
    let noFavorites = true
    Object.keys(this.state.tools).forEach(a => {
      if (this.state.tools[a].favorite === true) {
        noFavorites = false
      }
    })
    return noFavorites
  }

  getFavoriteTools = () => {
    let callback = (response) => {
      let tempTools = this.state.tools

      if (response.data && response.data.value) {
        try {
          Object.assign(tempTools, JSON.parse(response.data.value))
        } catch (error) {
          tempTools = this.state.tools
        }
      }

      this.setState({
        tools: tempTools,
        loading: false,
        noFavorites: this.isNoFavoriteText()
      })
    }

    DataRequest.execute(
      URLS.PLATFORM.USER_MGMT_PREFERENCES + '/CHART_FAVORITE_TOOLS', null, callback
    )
  }

  setFavoriteTools = debounce(1000, (tools) => {
    let callback = (response) => {
      logDebugInfo(response)
    }

    DataRequest.execute(
      URLS.PLATFORM.USER_MGMT_PREFERENCES + '/CHART_FAVORITE_TOOLS',
      {
        method: 'POST',
        postData: JSON.stringify(tools)
      },
      callback
    )
  })

  render () {
    return <div className={styles.chartToolsMenu} tabIndex='0' data-testid='chartToolsMenu'>
      {
        this.state.loading && <Loader spinnerStyle={{'margin-top': '80px'}} />
      }

      {
        !this.state.loading &&
          <React.Fragment>
            <div className={styles.favoritesContainer}>
              <div className={styles.heading}>
                Favorites
              </div>
              <ul>
                {
                  Object.keys(this.state.tools).map((tool) => {
                    if (this.state.tools[tool].favorite) {
                      return <li key={tool} onClick={() => this.props.onSelection && this.props.onSelection({
                        tool: this.state.tools[tool].value,
                        toolName: tool
                      })}>
                        <span className={styles.toolLabel}>
                          {tool}
                        </span>
                        <span className={styles.starIcon} data-testid={`Favorites_${tool}`}>
                          <FontAwesomeIcon
                            icon={['fas', 'star']}
                            color='black'
                            data-value={tool}
                            className={styles.iconArrowRight} tabIndex='-1' onClick={(e) => { this.favoritesClickHandler(false, e) }} />
                        </span>
                      </li>
                    }
                  })
                }
                {this.state.noFavorites === true ? <span className={styles.noFavourites}> No favorites selected </span> : ''}
              </ul>
            </div>

            <div className={styles.allToolsContainer}>
              <div className={styles.heading}>
                All Tools
              </div>
              <ul>
                {
                  Object.keys(this.state.tools).map((tool) => {
                    return <li key={tool} onClick={() => this.props.onSelection && this.props.onSelection({
                      tool: this.state.tools[tool].value,
                      toolName: tool
                    })}>
                      <span className={styles.toolLabel}>
                        {tool}
                      </span>
                      <span className={styles.starIcon} data-testid={tool}>
                        <FontAwesomeIcon
                          icon={[this.state.tools[tool].favorite ? 'fas' : 'fal', 'star']}
                          color='black'
                          data-value={tool}
                          className={styles.iconArrowRight} tabIndex='-1' onClick={(e) => { this.favoritesClickHandler(true, e) }} />
                      </span>
                    </li>
                  })
                }
              </ul>
            </div>
          </React.Fragment>
      }

    </div>
  }
}

ChartToolsMenu.propTypes = {
  onSelection: PropTypes.func
}

export default ChartToolsMenu
