import React, { useEffect, useState, useRef } from 'react'
import { closeModalPopup } from '../../../../../../../utils/utilities'
import Button from '../../../../../../Lib/Button/Button'
import ModalPopUp from '../../../../../../Lib/ModalPopUp/ModalPopUp'
import { ImportantDisclosureData, thirdPartyData } from '../disclosureData'
import styles from './ImportantDisclosure.module.scss'

const ImportantDisclosure = () => {
  const [showModalPopUp, setShowModalPopUp] = useState(false)
  const [showThirdPartyPopUp, setShowThirdPartyPopUp] = useState(false)
  const [currentLink, setCurrentLink] = useState(null)
  const textRef = useRef(null)
  const clickHandler = (e) => {
    e.preventDefault()
    setShowModalPopUp(true)
  }
  useEffect(() => {
    if (textRef.current) {
      Array.from(textRef.current.getElementsByClassName('linkBtn')).forEach(el => {
        el.onclick = thirdPartyLink
      })
    }
  }, [textRef.current, showModalPopUp])
  const closeHandler = (e) => {
    if (closeModalPopup(e)) {
      e.preventDefault()
      setShowModalPopUp(false)
    }
  }
  const closeThirdPartyHandler = (e) => {
    if (closeModalPopup(e)) {
      e.preventDefault()
      setShowThirdPartyPopUp(false)
    }
  }
  const thirdPartyLink = (e) => {
    e && e.preventDefault()
    let url = e.target.dataset['link']
    setCurrentLink(url)
    setShowThirdPartyPopUp(true)
  }
  const continueClickHandler = (e) => {
    e.preventDefault()
    window.open(`${currentLink}`, '_blank')
  }
  return <div className={styles.container}>
    <button type='button' onClick={clickHandler} className={styles.btn} data-testid='OpenModel' >
      Important Disclosures
    </button>
    {showModalPopUp && <div className={styles.popupContainer} data-testid='showModalPopUp' >
      <ModalPopUp onClose={closeHandler} header='Important Disclosures'>
        <div dangerouslySetInnerHTML={{__html: ImportantDisclosureData}} ref={textRef} className={styles.text} />
      </ModalPopUp> </div>
    }
    {
      showThirdPartyPopUp && <div className={styles.thirdPartyLink} data-testid='showThirdPartyPopUp' >
        <ModalPopUp onClose={closeThirdPartyHandler} header='Third-party site'>
          <div dangerouslySetInnerHTML={{__html: thirdPartyData}} className={styles.text} />
          <div className={styles.btnContainer}>
            <Button labelText='Continue' clickHandler={continueClickHandler} className={styles.continue} />
          </div>
        </ModalPopUp>
      </div>
    }
  </div>
}
export default ImportantDisclosure
