import React from 'react'
import PropTypes from 'prop-types'
import DataRequest from '../../../../../../services/DataRequest'
import Loader from '../../../../../Lib/common/Loader'
import {URLS} from '../../../../../../utils/appConstants'

import styles from './SparkChart.module.scss'

class SparkChartFill extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      sparkChart: {
        isLoading: true, isError: false, sparkImg: null
      },
      emptyImgData: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANoAAABICAYAAACQs+uTAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK2SURBVHhe7dc/jtNAFMfxeT4FoljOgESRlfhzAGiggIokIqGhggokRIm0VNCToMRUUEDDCQCJLRBnAAq4hYf3G89kvYs3QZCVKL4fKfL4zR/b0rw8OwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA/sJwOB2OxpNv+dTPb10cjSYf8ukfGY4nT4fj6f18+htdIzdPRLn/7nMc1de3bjywTVV7sB0lS9veLiWZVfFyPj0Ry8X8TG4C/yXrJMIgNvFmCpo9Xi7nF1SlLIQ9xWIID+rF7InaxWg89bCL8WM0exea8NOqsEwxF5vmklX2Uoms+RbjFV/8fO597Qlyo22XhDwy1+8jRnte17NaVVbX6FtDlUnJtjqqIveM8Yf4rngM8Vm9mN8r4zc9J/CvckXTxrZH2tj5NNHmWy5mpl/ZiIUqoDal+nLINV/LeG3mUFW7WlebXZs3xviw9PvgQTun6Jlr4XOo4tnU7QmyeY3WcWOUtIp58FoOJRbinTJe7RwGtmaVaKoavuN+hMqu6lzfaqpUqVO8nWKZZ8O50DSf1E4J4er6xXtVCVU6C3ZXsUM8edS3qoQdvXOb+FZJkb7xyr2sWWPlmDHpGVv75Vnao+0cjPfX6M5zAtuwSjTRK1bZ5Nr4B69fThVFsaytNtWu2p4Mp3X0RHmlCpYqSTdJnTavXvvUF5swyuGVvrnlembxtq63aQ1ZN6aTQIOydjr69TS+/LrPCWzDoUQTvQ7mZmqXf/puXPSN4wm2l6tGej2Lwb7oOyvFVB1DPKVXQk+V6ykpLaTKocTR+K7+ucl+SnivbikB1qwh68boe1Fxv+E3OZTo20/x8sthAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJCH8AulciimzbSVqAAAAAElFTkSuQmCC'
    }
    this.chartInput = props.sparkChartInput
    this.spinnerStyle = props.spinnerStyle || {'minWidth': '220px', 'height': '72px'}
  }
  createCancelToken () { return DataRequest.getCancelToken() }
  componentDidMount () {
    this.token = this.createCancelToken()
    this.getSparkChart(this.token)
  }
  componentWillUnmount () {
    this.token && this.token.cancel()
  }
  componentDidUpdate (prevProps) {
    if (prevProps.venueXid && this.props.venueXid &&
      (prevProps.venueXid !== this.props.venueXid)) {
      this.getSparkChart(this.token)
    }
  }

  getSparkChart (token) {
    this.setState({sparkChart: {isLoading: true, isError: false, sparkImg: null}})
    let sparkDataRequestBody = {
      'data': {
        'realTime': false,
        'capture': ''
      },
      'imageFormat': 'png',
      'returnSprite': false
    }
    Object.assign(sparkDataRequestBody, this.chartInput)
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        let sparkData = response && response.data
        this.setState({sparkChart: {isLoading: false, isError: false, sparkImg: sparkData[0].image}})
      } else {
        this.setState({sparkChart: {isLoading: false, isError: true, sparkImg: null}})
      }
    }
    DataRequest.execute(
      URLS.PLATFORM.SPARKCHART,
      {
        method: 'POST',
        postData: sparkDataRequestBody
      },
      callback,
      token,
      {
        realtime: false
      }
    )
  }

  getSparkChartBody () {
    if (this.state.sparkChart.isLoading) {
      return <Loader ready={false} spinnerStyle={this.spinnerStyle} spinnerSize={'2x'} />
    } else if (this.state.sparkChart.isError || (this.state.sparkChart.sparkImg + '').includes(this.state.emptyImgData)) {
      return <div style={this.spinnerStyle} data-value='No Data' />
    } else {
      return <img id={'chartDomElement' + this.props.symbol} className={styles.sparkChartDomElement} alt='Spark Chart' src={this.state.sparkChart.sparkImg} />
    }
  }

  render () {
    return (
      <div className={styles.sparkChartContainer} >
        {
          this.getSparkChartBody()
        }
      </div>
    )
  }
}
export default SparkChartFill
SparkChartFill.propTypes = {
  sparkChartInput: PropTypes.object,
  spinnerStyle: PropTypes.object,
  venueXid: PropTypes.number,
  symbol: PropTypes.number
}
