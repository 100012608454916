import React from 'react'
import PropTypes from 'prop-types'
import styles from './Table.module.scss'

export default function Table ({headers, data, className, isETF, isOnlyChart}) {
  return (
    <div className={`${styles.genericTableContainer} ${className}`}>
      <table
        className={styles.genericTable}>
        <thead className={isETF ? styles.headerforETF : ''}>
          <tr key='header' className={styles.header}>
            {headers.map(header => {
              if (!header.hideInMobile) {
                return <th key={header.name} className={styles[header.textAlign]} style={{width: header.width}}>
                  <div className={header.seprator ? styles.seprator : undefined}>{header.name}</div>
                </th>
              }
            })}
          </tr>
        </thead >
        <tbody>
          {data.map((item, index) => {
            if (item) {
              if (isETF) { // for ETF Assest Allocation
                return <tr key={index} className={styles.chartBody}>
                  { !isOnlyChart &&
                  <td aria-hidden='true' style={{width: item.width}}><span style={{background: item.color}} className={`${styles.circle} ${styles[item.textAlign]}`}> </span>
                  </td> }
                  {
                    headers.map(header => {
                      if (header.value !== 'Color') {
                        if (!header.hideInMobile) {
                          return <td key={item[header.name]} className={styles[header.textAlign]} style={{width: header.width}}>
                            <div className={`${header.seprator ? styles.valueSeprator : undefined} ${item[header.value] < 0 ? styles.red : ''}`}>{ item[header.value]}</div>
                          </td>
                        }
                      }
                    })
                  }
                </tr>
              }

              return <tr key={index} className={styles.body}>
                {
                  headers.map(header => {
                    if (!header.hideInMobile) {
                      return <td key={item[header.name]} className={styles[header.textAlign]} style={{width: header.width}}>
                        <div className={header.seprator ? styles.valueSeprator : undefined}>{ item[header.name]}</div>
                      </td>
                    }
                  })
                }
              </tr>
            }
          })}
        </tbody>
      </table>
    </div>
  )
}
Table.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  isETF: PropTypes.bool,
  isOnlyChart: PropTypes.bool
}
