/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './Loader.module.scss'

const Loader = (props) => {
  return (
    <div className={styles.spinnerContainer} style={props.spinnerStyle}>
      <div data-testid='loader-container' className={classNames('spinnerContent', { hide: props.ready })}>
        <FontAwesomeIcon icon={['fad', 'spinner-third']} className={'fa-pulse fa-wifi'} size={props.spinnerSize} />
        {props.msg ? <p className={styles.loadingTextHide}> {props.msg}</p> : null}
      </div>
      {props.ready &&
      <div className={classNames('childContent', { show: props.ready })}>
        {props.children}
      </div>
      }
    </div>
  )
}

export default Loader
Loader.propTypes = {
  spinnerStyle: PropTypes.object,
  ready: PropTypes.bool,
  spinnerSize: PropTypes.string,
  children: PropTypes.node,
  msg: PropTypes.string
}
