export const actionList = [
  'Download Selected Tab'
]

export const TabKeysAI = ['Profile']

export const MinorProductCodeToTextMapping = {
  '041': 'Limited Partnerships',
  '040': 'Limited Partnerships',
  '042': 'Hedge Fund',
  '043': 'Exchange Fund',
  '044': 'Private Equity',
  '045': 'Real Estate',
  '046': 'Collateralized Debt Obligations',
  '047': 'Group Annuity Contract',
  '048': 'Fund of Funds',
  '049': 'Managed Futures',
  '04A': 'Private Debt',
  '04B': 'Managed Debt',
  '166': 'Non-Traded REIT',
  '167': 'Business Development Company'
}

// eslint-disable-next-line camelcase
export const Y_N_MappingTo_Yes_No = {
  'Y': 'Yes',
  'N': 'No'
}

export const PershingIBDCode = ['511', 'PERS']
export const PershingPropFeeIndicator = 'PROP'
export const DefaultProductFeeIndicator = ['LPPP', 'NULL', 'PASS', 'PROP', 'BANC', 'NTFP']

export const RetirementMapping = {
  'CUSTDTC': 'Retirement & Non-Retirement',
  'Retirement': 'Retirement'
}
export const FeeNonFeeMapping = {
  'LPPP': 'Fee',
  'PASS': 'Fee',
  'BANC': 'Fee',
  'PROP': 'Fee',
  'NULL': 'Fee',
  'NTFP': 'No Fee'
}

export const BASICS_FILTER_OPTIONS = [
  {
    'val': 'PERAIProductFeeIndicator',
    'label': 'Fee',
    'parentVal': 'PERAIProductFeeIndicator',
    'rootFilterId': 'basics-select',
    'level': 'L1',
    'items': [
      {
        'val': ['LPPP', 'PASS', 'BANC'],
        'label': 'Fee',
        'pillLabel': 'Fee',
        'control': 'checkbox',
        'level': 'L2'
      },
      {
        'val': 'NTFP',
        'label': 'No Fee',
        'pillLabel': 'Fee',
        'control': 'checkbox',
        'level': 'L2'
      }
    ]
  },
  {
    'val': 'PERAIMinorProductCode',
    'label': 'Product Type',
    'parentVal': 'PERAIMinorProductCode',
    'rootFilterId': 'basics-select',
    'level': 'L1',
    'items': [
      {
        'val': '166',
        'label': 'Non-Traded REIT',
        'pillLabel': 'Product Type',
        'control': 'checkbox',
        'level': 'L2'
      },
      {
        'val': '044',
        'label': 'Private Equity',
        'pillLabel': 'Product Type',
        'control': 'checkbox',
        'level': 'L2'
      },
      {
        'val': '042',
        'label': 'Hedge Fund',
        'pillLabel': 'Product Type',
        'control': 'checkbox',
        'level': 'L2'
      },
      {
        'val': '167',
        'label': 'Business Development Company',
        'pillLabel': 'Product Type',
        'control': 'checkbox',
        'level': 'L2'
      },
      {
        'val': '040',
        'label': 'Limited Partnerships',
        'pillLabel': 'Product Type',
        'control': 'checkbox',
        'level': 'L2'
      },
      {
        'val': '046',
        'label': 'Collateralized Debt Obligations',
        'pillLabel': 'Product Type',
        'control': 'checkbox',
        'level': 'L2'
      },
      {
        'val': '043',
        'label': 'Exchange Fund',
        'pillLabel': 'Product Type',
        'control': 'checkbox',
        'level': 'L2'
      },
      {
        'val': '048',
        'label': 'Fund of Funds',
        'pillLabel': 'Product Type',
        'control': 'checkbox',
        'level': 'L2'
      },
      {
        'val': '047',
        'label': 'Group Annuity Contract',
        'pillLabel': 'Product Type',
        'control': 'checkbox',
        'level': 'L2'
      },
      {
        'val': '04B',
        'label': 'Managed Debt',
        'pillLabel': 'Product Type',
        'control': 'checkbox',
        'level': 'L2'
      },
      {
        'val': '049',
        'label': 'Managed Futures',
        'pillLabel': 'Product Type',
        'control': 'checkbox',
        'level': 'L2'
      },
      {
        'val': '04A',
        'label': 'Private Debt',
        'pillLabel': 'Product Type',
        'control': 'checkbox',
        'level': 'L2'
      },
      {
        'val': '045',
        'label': 'Real Estate',
        'pillLabel': 'Product Type',
        'control': 'checkbox',
        'level': 'L2'
      }
    ]
  },
  {
    'val': 'PERAIDepositoryID',
    'label': 'Account Eligibility',
    'parentVal': 'PERAIDepositoryID',
    'rootFilterId': 'basics-select',
    'level': 'L1',
    'items': [
      {
        'label': 'Retirement',
        'pillLabel': 'Acct Eligibility',
        'control': 'radio',
        'level': 'L2',
        'isSelected': false,
        'arrayList': ['Retirement', 'Retirement & Non-Retirement']
      }
    ]
  },
  {
    'val': 'PERAISecRegistered',
    'label': 'SEC Registration',
    'parentVal': 'PERAISecRegistered',
    'rootFilterId': 'basics-select',
    'level': 'L1',
    'items': [
      {
        'label': 'SEC Registration',
        'pillLabel': 'SEC Reg',
        'control': 'radio',
        'level': 'L2',
        'isSelected': false,
        'arrayList': ['Yes', 'No']
      }
    ]
  },
  {
    'val': 'PERAIFundStructure',
    'label': 'Fund Structure',
    'parentVal': 'PERAIFundStructure',
    'rootFilterId': 'basics-select',
    'level': 'L1',
    'items': [
      {
        'val': 'Unitized',
        'label': 'Unitized',
        'pillLabel': 'Fund Structure',
        'control': 'checkbox',
        'level': 'L2'
      },
      {
        'val': 'Capital Balance',
        'label': 'Capital Balance',
        'pillLabel': 'Fund Structure',
        'control': 'checkbox',
        'level': 'L2'
      }
    ]
  }
]

export const AI_TABS_LIST = {
  Profile: 'Profile'
}

export const NUM_OF_ROWS_LIMIT = 10
export const DEFAULT_PRE_SCREEN_AI_SCREENER_INPUTS = {
  argsOperator: 'AND',
  arguments: [ {
    field: 'PERAICusip',
    conditions: []
  }
  ],
  offset: 0,
  limit: 10,
  sortArguments: [
    {
      direction: 'A',
      field: 'PERAICusip'
    }
  ],
  resultFields: [
    'PERAICusip'
  ]
}

export const AITABLE_FIELDS = {
  'PERAISecurityDescription': 'Name',
  'PERAIPershingIdentifier': '',
  'PERAICusip': 'CUSIP',
  'PERAIProductFeeIndicator': 'Fee',
  'PERAIMinorProductCode': 'Product Type',
  'PERAIDepositoryID': 'Account Eligibility',
  'PERAISecRegistered': 'SEC Registration',
  'PERAIFundStructure': 'Fund Structure'
}

export const PROFILE_FIELDS = {
  'PERAISecurityDescription': 'Name',
  'PERAICusip': 'CUSIP',
  'PERAIPershingIdentifier': '',
  'PERAIProductFeeIndicator': 'Fee',
  'PERAIMinorProductCode': 'Product Type',
  'PERAIDepositoryID': 'Account Eligibility',
  'PERAISecRegistered': 'SEC Registration',
  'PERAIFundStructure': 'Fund Structure'
}

export const FIELD_DISPLAYFIELDS_MAPPING = {
  [AI_TABS_LIST.Profile]: PROFILE_FIELDS
}

export const AIScreenerKeyMeasuresArr = [
]
