/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Popover from '../Popover/Popover'
import styles from './ActionMenu.module.scss'

export const ActionMenu = forwardRef((props, refProp) => {
  const ref = useRef(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const togglePopover = (e) => {
    if (e) {
      e.stopPropagation()
    }
    setIsPopoverOpen(!isPopoverOpen)
  }

  const actionClickHandler = (element) => {
    props.actionHandler(element)
    togglePopover(element)
  }

  useImperativeHandle(refProp, () => ({
    closePopover () {
      setIsPopoverOpen(false)
    }
  }))

  return (
    <div className={styles.actionMenuContainer}>
      <button
        ref={ref}
        onClick={togglePopover}>
          {props.screener ? <FontAwesomeIcon icon={['fas', 'download']} className={styles.downLoadIcon} />
            : <FontAwesomeIcon icon={['far', 'ellipsis-v']} data-icon-style='ellipsis-v' />}
      </button>
      {isPopoverOpen && (
        <Popover
          id='popover'
          closePopover={togglePopover}
          body={
            <div>
              <ul>
                {
                  props.actionsList.map((item, index) => {
                    return <li key={index} onClick={actionClickHandler} data-value={props.dataValue}>
                      {item}
                    </li>
                  })
                }
              </ul>
            </div>
          }
          referenceElement={ref.current}
          placement={'left'}
        />
      )}

    </div>
  )
})

ActionMenu.propTypes = {
  actionsList: PropTypes.array,
  actionHandler: PropTypes.func,
  dataValue: PropTypes.string,
  screener: PropTypes.bool
}

export default ActionMenu
