import React from 'react'
import Tooltip from '../../Overlay/ToolTip'
import StockHighlightsItem from '../../../StockHighlights/StockHighlightsItem/StockHighlightsItem'
import styles from './FundQuoteCardWithStockHiglights.module.scss'
import PropTypes from 'prop-types'
import FundSnapshotData from '../../../FundResearch/FundResearchConstants.json'
import { emitF2Event } from '../../../../../../../utils/f2Methods'
import { EVENT_NAMES } from '../../../../../../../utils/f2Constants'
import { MFSnapshot, PROFILE, MOD_GOTO_PAGES } from '../../../utils/appConstants'

const AiQuoteCard = (props) => {
  const fundType = props.type === 'MFSnapshot' ? props.data.label : (props.data.label === 'Non-Traded REIT' ? props.data.label : false)

  function overlayKeyHandler (venueXid) {
    props.overlayKeyHandler(venueXid)
  }
  function emitEventHandler () {
    let dataEmit = {}
    switch (props.type) {
      case MFSnapshot:
        dataEmit = {
          gotoPage: MOD_GOTO_PAGES.fund, // emitEventObj.gotoPage, // Option can be funds, model-marketplace, alternative-investments  ---  3rd Level Navigation tabs own by Pershing
          activeTabKey: PROFILE, // emitEventObj.activeTabKey, // Option can be 'screener', 'compare', 'profile'  -- 4th level Navigation tabs own by Markit Digital
          symbol: props.data.SPCFticker
        }
        break
    }
    emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, dataEmit)
  }
  function getDataPoints () {
    let obj = {}
    if (props.type === 'MFSnapshot') {
      obj = {name: props.data.SPCFMStarOverrideFundName,
        symbol: props.data.SPCFticker,
        dataPoints: FundSnapshotData.ShareClassCardData,
        type: 'MF',
        dataForTooltip: {'name': props.data.SPCFMStarOverrideFundName, 'symbol': props.data.SPCFticker, 'type': 'MF', 'xid': props.data.SPCFWSODIssue, 'CUSIP': props.data.SPCFMStarOverrideCUSIP}
      }
    }
    return obj
  }
  return (
    <React.Fragment>
      <div className={styles.quoteCardHeader}>
        <div className={`${styles.quoteCardHeaderMain} ${props.icon === '' ? styles.fixedCardSymbol : ''}`} id='symbol' aria-label={`Symbol:`} role='button' tabIndex='0' onClick={() => emitEventHandler()} onKeyDown={() => emitEventHandler()}>
          {getDataPoints().symbol}
        </div>
        <div className={styles.quoteCardHeaderDesc} style={props.icon === '' ? {width: '100%'} : {}}>
          {getDataPoints().name}
        </div>
        <div className={styles.toolTip}>
          <Tooltip id={props.id} type={getDataPoints().type} moduleTypeName={props.moduleTypeName} icon={props.icon} data={getDataPoints().dataForTooltip} symbol={getDataPoints().symbol} overlayKeyHandler={(venueXid) => overlayKeyHandler(venueXid)} />
        </div>
      </div>
      {!fundType ? <div className={styles.noTag} />
        : <div id={'buttonFund' + props.id} name='ETF' className={`${styles.ETF} ${props.icon === '' ? styles.custom : ''}`} aria-label={`Type: ${fundType}`} role='button'>{fundType}</div>}
      <div className={styles.stockHighlightContent}>
        <div className={styles.stockHighlightIPadContainer}>
          <div className={`${styles.stockHighlightIPadDivLeft}`} style={{padding: '7px 10px 10px 10px'}} name='hideScroll'>
            {getDataPoints().dataPoints.map(x => <StockHighlightsItem key={x.label} label={x.label} value={props.data[x.field]} type={props.type} />)}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default AiQuoteCard

AiQuoteCard.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  overlayKeyHandler: PropTypes.func,
  moduleTypeName: PropTypes.string
}
