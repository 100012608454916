import React from 'react'

const Minus = props => {
  return (
    <span role='presentation' className={'add'} >
      <svg width='13' height='24' viewBox='0 0 13 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect width='13' height='24' fill='white' />
        <path d='M11.3125 6.5C11.5312 6.5 11.75 6.71875 11.75 6.9375V16.5625C11.75 16.8086 11.5312 17 11.3125 17H1.6875C1.44141 17 1.25 16.8086 1.25 16.5625V6.9375C1.25 6.71875 1.44141 6.5 1.6875 6.5H11.3125ZM11.3125 5.625H1.6875C0.949219 5.625 0.375 6.22656 0.375 6.9375V16.5625C0.375 17.3008 0.949219 17.875 1.6875 17.875H11.3125C12.0234 17.875 12.625 17.3008 12.625 16.5625V6.9375C12.625 6.22656 12.0234 5.625 11.3125 5.625ZM9.67188 11.2578H6.99219V8.57812C6.99219 8.41406 6.82812 8.25 6.66406 8.25H6.33594C6.14453 8.25 6.00781 8.41406 6.00781 8.57812V11.2578H3.32812C3.13672 11.2578 3 11.4219 3 11.5859V11.9141C3 12.1055 3.13672 12.2422 3.32812 12.2422H6.00781V14.9219C6.00781 15.1133 6.14453 15.25 6.33594 15.25H6.66406C6.82812 15.25 6.99219 15.1133 6.99219 14.9219V12.2422H9.67188C9.83594 12.2422 10 12.1055 10 11.9141V11.5859C10 11.4219 9.83594 11.2578 9.67188 11.2578Z' fill='#0572D6' />
        <line x1='6.5' y1='-2.18557e-08' x2='6.5' y2='4' stroke='#0572D6' />
        <line x1='6.5' y1='20' x2='6.5' y2='24' stroke='#0572D6' />
      </svg>

    </span >
  )
}

export default Minus
