'use strict'
import React from 'react'
import PropTypes from 'prop-types'
import styles from './SnapshotStrategyModalDescription.module.scss'

export default function SnapshotStrategyModalDescription ({description}) {
  return (
    <div>
      <div className={styles.fundStrategyModalDescription}>{description}</div>
    </div>
  )
}
SnapshotStrategyModalDescription.propTypes = {
  description: PropTypes.string
}
