import React, { useEffect, useState } from 'react'
import DataRequest from '../../../../../../services/DataRequest'
import StocksQuote from '../../../../../Lib/StocksQuote/StocksQuote'
import { formatNumber, convertToInternationalCurrencySystem } from '../../../../../../utils/formatter'
import { DEFAULT_VALUE, URLS, DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS, FundScreenerKeyMeasuresArr } from '../../utils/appConstants'
import { QUOTE_DEFAULT_STATE } from './QuoteDefaultState'
import PropTypes from 'prop-types'
import { getAPIData } from '../../utils/utilities'
import { getQuoteData } from '../../../../../../shared/dataMethods'

const QuoteBar = (props) => {
  let [stockData, setStockData] = useState({
    ...QUOTE_DEFAULT_STATE,
    symbol: props.symbol,
    shareName: props.shareName,
    shareCode: props.symbol + ' : ' + props.exchange,
    isFundvestEtf: props.isFundvestEtf
  })

  function resetStates () {
    setStockData({
      ...QUOTE_DEFAULT_STATE,
      symbol: props.symbol,
      shareName: props.shareName,
      shareCode: props.symbol + ' : ' + props.exchange,
      isFundvestEtf: props.isFundvestEtf
    })
  }
  useEffect(() => {
    // Whenever fund changes - resets state of sub-component
    resetStates()
    if (props.moduleName === 'AISnapShot') {
      getAISnapShotData()
    } else {
      if (props?.xid) {
        getQuote(props.xid)
        getCategoryType(props.xid)
        if (props.isFundvestEtf === undefined) { // in case of view profile from recent search we dont get FundVest flag, so we need to get it from fund screenner
          getFundVestDetail(props.xid)
        }
      }
    }
  }, [props.xid])

  useEffect(() => {
    if (props.moduleName === 'AISnapShot') {
      getAISnapShotData()
    }
  }, [props.data, props.cusip, props.symbol])

  const getAISnapShotData = () => {
    setStockData({
      asset: {
        label: 'Asset',
        val: props.data.assetType || DEFAULT_VALUE
      },
      shareClass: {
        label: 'Share Class',
        val: props.data.shareClass || DEFAULT_VALUE
      },
      quarter: {
        label: 'Quarter',
        val: props.data.quarter || DEFAULT_VALUE
      },
      year: {
        label: 'Year',
        val: props.data.year || DEFAULT_VALUE
      },
      lifeStage: {
        label: 'Life Stage',
        val: props.data.lifeStage || DEFAULT_VALUE
      },
      realStateAssetSize: {
        label: 'Real Estate Asset Size',
        val: props.data.realEstateAssetSize ? convertToInternationalCurrencySystem(props.data.realEstateAssetSize) + ' Sq Ft' : DEFAULT_VALUE
      },
      numberOfInvestment: {
        label: 'Number of Investments',
        val: props.data.numberOfInvestments || DEFAULT_VALUE
      },
      percentLeased: {
        label: 'Percent Leased',
        val: formatNumber(props.data.percentLeasedPct, {postFix: true, postFixValue: '%'}) || DEFAULT_VALUE
      },
      weightageAverage: {
        label: 'Weighted Average Lease Expiration',
        val: props.data.weightedAvgAnlLeaseExpirationInYears ? props.data.weightedAvgAnlLeaseExpirationInYears + ' Years' : DEFAULT_VALUE
      },
      annualYield: {
        label: 'Current Annual Distribution Yield',
        val: formatNumber(props.data.anlDistributionYieldCurrentQtrPct, {postFix: true, postFixValue: '%'}) || DEFAULT_VALUE
      },
      cusip: props.cusip || DEFAULT_VALUE,
      shareName: props.shareName || DEFAULT_VALUE,
      symbol: props.symbol || DEFAULT_VALUE,
      isLoading: false
    })
  }

  const getQuote = (venueXid) => {
    let callback = (response) => {
      if (!response.isError) {
        let quotesData = response &&
          response.data.quotes &&
          response.data.quotes.length > 0 &&
          response.data.quotes[0].data
        setStockData((prevState) => {
          return {...prevState,
            delayedQuoteDate: new Date(quotesData.lastTrade.date),
            showRefreshIcon: true,
            RangeData: {
              label: '52 Week Range',
              min: quotesData.price52Week.low,
              max: quotesData.price52Week.high,
              value: quotesData.lastTrade.last,
              showProgressBar: true
            },
            DayChangeInfo: {
              label: 'Day Change',
              price: quotesData.lastTrade.change,
              percentChange: quotesData.changePercent.today
            },
            Volume: {
              label: 'Volume',
              value: formatNumber(quotesData.volume.last, {
                precision: 2,
                showMagnitude: true
              })
            },
            lastTradeDate: new Date(quotesData.lastTrade.date),
            LastNASDAQData: {
              label: `Last Price: ${quotesData.exchange.name || DEFAULT_VALUE}`,
              price: quotesData.lastTrade.last,
              multiplyFactor: quotesData.lastTrade.size
            },
            BidNASDAQData: {
              label: `Bid: ${quotesData.orderBook.bid || DEFAULT_VALUE}`,
              price: quotesData.bid.price,
              multiplyFactor: quotesData.bid.size
            },
            AskNASDAQData: {
              label: `Ask: ${quotesData.orderBook.ask || DEFAULT_VALUE}`,
              price: quotesData.ask.price,
              multiplyFactor: quotesData.ask.size
            },
            isLoading: false,
            symbol: props.symbol,
            shareName: props.shareName,
            shareCode: props.symbol + ' : ' + quotesData.exchange.name,
            isETF: props.isETF,
            isError: false,
            isFundvestEtf: props.isFundvestEtf
          }
        })
      } else {
        setStockData((prevState) => {
          return {...prevState,
            ...QUOTE_DEFAULT_STATE,
            isLoading: false,
            isError: true
          }
        })
      }
    }
    getQuoteData(venueXid, callback, false)
  }
  const getCategoryType = (venueXid) => {
    let callbackFunction = (response) => {
      if (!response.isError && response.data) {
        let data = response.data
        if (data.items) {
          setStockData((prevState) => {
            return {...prevState,
              categoryType: data.items.find(x => x.type === 'MorningstarCategory').name || DEFAULT_VALUE
            }
          })
        } else {
          setDefaultStateSector()
        }
      } else {
        setDefaultStateSector()
      }
    }
    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_BENCHMARK_INFORMATION, callbackFunction)
  }
  const getFundVestDetail = (venueXid) => {
    const screenDataCallback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        const responseData = response.data || {}
        responseData.items && responseData.items.map(x => {
          x.isFundvestEtf = x.SPCFIsFVETF
        })
        setStockData((prevState) => {
          return {...prevState,
            isFundvestEtf: responseData.items[0].isFundvestEtf
          }
        })
      }
    }
    let screenerArguments = []
    let newArgument =
        {
          field: 'SPCFWSODIssue',
          conditions: [
            {
              operator: 'EqualTo',
              values: [venueXid + '']
            }
          ]
        }
    screenerArguments.push(newArgument)
    DataRequest.execute(
      `${URLS.CUSTOM.SCREEN + '?screenType=MF_ETF'}`,
      buildScreenInputs(0, screenerArguments),
      screenDataCallback
    )
  }
  const buildScreenInputs = (_offset, screenerArguments) => {
    const screenInputs = {...DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS,
      offset: _offset,
      arguments: screenerArguments || [],
      sortArguments: [
        {
          direction: 'A',
          field: 'SPCFticker'
        }
      ],
      limit: 100,
      resultFields: FundScreenerKeyMeasuresArr
    }

    return {
      method: 'POST',
      postData: screenInputs
    }
  }
  const onRefresh = (e) => {
    e && e.preventDefault()
    if (props?.xid) {
      getQuote(props.xid)
      getCategoryType(props.xid)
    }
  }
  const setDefaultStateSector = () => {
    setStockData((prevState) => {
      return {
        ...prevState,
        categoryType: DEFAULT_VALUE
      }
    })
  }
  return (
    <React.Fragment>
      <StocksQuote moduleName={props.moduleName} stocksData={stockData} refresh={onRefresh} isETF />
    </React.Fragment>
  )
}

QuoteBar.propTypes = {
  xid: PropTypes.any,
  symbol: PropTypes.string,
  shareName: PropTypes.string,
  isETF: PropTypes.bool,
  isFundvestEtf: PropTypes.bool,
  exchange: PropTypes.string,
  moduleName: PropTypes.string,
  data: PropTypes.object,
  cusip: PropTypes.string
}
export default QuoteBar
