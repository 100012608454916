/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import 'babel-polyfill'
import React from 'react'
import F2AppContainer from '../../../../F2AppContainer'
import { APP_IDS, EVENT_NAMES } from '../../../../../../utils/f2Constants'
import TokenEvents from '../../../TokenEvents'
import Tabs from '../../../../../Lib/Tabs/Tabs'
import styles from './EarningsAndEstimates.module.scss'
import { changeTabHandler, setAccessToken, getSymbolFromPath } from '../../../../utils'
import SymbolSearch from '../.././../../../Lib/SymbolSearch/SymbolSearch'
import { emitF2Event, registerF2OnEvent, removeAllApps } from '../../../../../../utils/f2Methods'
import { getQueryStringParameterValue } from '../../../../../../utils/utilities'

class EarningsAndEstimates extends React.Component {
  constructor (props) {
    super(props)
    this.symbolRecvd = this.props.match.params.symbol
    this.state = {
      period: 'Annual'
    }
  }

  componentDidMount () {
    registerF2OnEvent(EVENT_NAMES.MOD_GOTO, (modGotoContext) => {
      emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, {symbol: modGotoContext.symbol})
    })
  }

  emilPeriodUpdate = event => {
    emitF2Event(EVENT_NAMES.PERIOD_CHANGED_EVENT, event.currentTarget.getAttribute('data-value'))
  }

  emitSymbolChangedEvent = () => {
  }

  render () {
    return (
      <div data-page='F2AppContainer'>
        <div className={styles.header}>
          <h3>Quotes & Research</h3>

        </div>
        <div>
          <F2AppContainer
            appId={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH}
            rootId={APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH} />
        </div>
        <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR} >
          <F2AppContainer
            removeApp
            appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR}
            context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
            rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR} />
        </div>
        <div className={styles.quoteAndResearch}>
          <Tabs id={'MainPage_tab'} ariaLabel={'Finanace tabs'} activeTab={'Earnings & Estimates'} onClick={(k) => { changeTabHandler(k) }}>
            <div key='Snapshot' label='Snapshot' />
            <div key='charts' label='Charting' />
            <div key='Options' label='Options' />
            <div key='dividends' label='Dividends' />
            <div key='NewsAndResearch' label='News & Research' />
            <div key='Valuations' label='Valuations' />

            <div key='EarningsAndEstimates' label='Earnings & Estimates'>
              <div className={styles.earningsAndEstimatesContainer}>
                <div className={styles.barContainer} >
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_EARNINGS_ESTIMATES} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_EARNINGS_ESTIMATES}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_EARNINGS_ESTIMATES} />
                  </div>
                </div>
              </div>
              <div className={styles.stockValuationContainer}>
                <div className={styles.leftSectionContainer} >
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_EARNINGS_ESTIMATES_ACTUAL} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_EARNINGS_ESTIMATES_ACTUAL}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_EARNINGS_ESTIMATES_ACTUAL} />
                  </div>
                  {/* <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_MIND_EVENTS} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_MIND_EVENTS}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_MIND_EVENTS} />
                  </div> */}
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_REVENUE_ESTIMATES_AND_ACTUALS} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_REVENUE_ESTIMATES_AND_ACTUALS}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_REVENUE_ESTIMATES_AND_ACTUALS} />
                  </div>
                </div>
                <div className={styles.rightSectionContainer} >
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_ONE_YEAR_TARGET_PRICE} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_ONE_YEAR_TARGET_PRICE}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_ONE_YEAR_TARGET_PRICE} />
                  </div>
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_LONG_TERM_GROWTH_RATES} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_LONG_TERM_GROWTH_RATES}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_LONG_TERM_GROWTH_RATES} />
                  </div>
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PEER_GROWTH_RATES} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PEER_GROWTH_RATES}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PEER_GROWTH_RATES} />
                  </div>
                  <div data-ipc-container={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PEER_PE} >
                    <F2AppContainer
                      appId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PEER_PE}
                      context={{ symbol: getSymbolFromPath(this.symbolRecvd) }}
                      rootId={APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PEER_PE} />
                  </div>
                </div>
              </div>
            </div>

            <div key='Fundamentals' label='Fundamentals' />
            <div key='Ownership' label='Ownership' />
          </Tabs>
        </div>
        <TokenEvents />
      </div>
    )
  }
}

export default EarningsAndEstimates
