import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './TableStyles.module.scss'
import CheckBox from '../CheckBox/CheckBox'
import ToolTip from '../Overlay/ToolTip'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import cloneDeep from 'lodash/cloneDeep'
import MinusIcon from '../Icons/Minus'
import PlusIcon from '../Icons/Plus'
import StarRating from '../../Shared/StarRating/StarRating'
import PriceChange from '../../Shared/PriceChange/PriceChange'
import { emitF2Event } from '../../../../../../utils/f2Methods'
import { EVENT_NAMES } from '../../../../../../utils/f2Constants'
import FundVestTag from '../FundVestTag/FundVestTag'
import { MOBILE_WIDTH_LIMIT, ModuleName, PROFILE, AI_SEARCH_TYPE_LIST, AISearchType } from '../../utils/appConstants'
import { deviceType as deviceTypeConstant } from '../../utils/utilities'
import { replaceAllChars } from '../../Helpers/CommonFunctions'
import AddToWatchlists from '../../../../../Watchlists/AddToWatchlists/AddToWatchlists'
// negativeNumberStyleFieldsForRowHeader={[]} -  To show negative number in red color for row header columns ex. in fund comapare page for performace table we make -0.32% to show red
export default function Table ({
  mainHeaders,
  chkboxSelectedIds,
  moduleTypeName,
  headers,
  _data,
  maxSelectionLength,
  fixedColumnCount,
  handleOnCheckboxChange,
  rowHeaders,
  moduleName,
  errorMsg,
  startIndex,
  removeComapreRow,
  syncFixedColumns,
  sortColumnData,
  deviceType,
  zIndexHandlingReqd = true,
  isETF,
  isOnlyChart,
  negativeNumberStyleFieldsForRowHeader = []
}
) {
  const [data, setData] = useState(_data)

  // Add to watchlist
  const [addToWatchlistData, setAddToWatchlistData] = useState(null)
  // Add to watchlist

  const shadowColor = '1px solid #B2B2B2'
  let dataRef = useRef(null)
  dataRef.current = _data

  let isLocked = (value) => {
    const lockedList = ['ModL', 'SMAL']
    return lockedList.includes(value)
  }
  let replaceLock = (type, value) => {
    var newValue = ''
    switch (type) {
      case 'SMAL':
        newValue = 'SM'
        break
      case 'ModL':
        newValue = 'M'
        break
      default:
        newValue = value
        break
    }
    return newValue
  }
  let toolTipText = (value, type) => {
    var toolTip = ''
    switch (value) {
      case 'SMA':
        toolTip = 'Seperately Managed Account'
        break
      case 'MOD':
        toolTip = 'Model'
        break
      case 'SMAL':
        toolTip = 'Restricted Access Model'
        break
      case 'ModL':
        toolTip = 'Restricted Access Model'
        break
      case 'ETF':
        toolTip = 'ETF'
        break
      case 'MF':
        toolTip = 'Mutual Fund'
        break
      case 'AI':
        toolTip = 'Alternative Investment'
        break
    }
    if (type === 'div') {
      return toolTip !== '' ? <div className={styles.tooltiptextIcon}>{toolTip}</div> : ''
    } else {
      return toolTip
    }
  }

  const [checkboxSelection, setCheckboxSelection] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)
  const widthRef = useRef(0)

  useEffect(() => {
    if (syncFixedColumns) {
      syncFixedColumn()
      syncFixedColumns.current = syncFixedColumn
    }
  }, [deviceType])

  useEffect(() => {
    setData(_data)
    syncFixedColumn()
  }, [_data])

  useEffect(() => {
    if (chkboxSelectedIds && chkboxSelectedIds.length === 0) {
      setCheckboxSelection([])
      setIsDisabled(false)
    } else {
      if (moduleTypeName === 'EquityScreener') {
        setCheckboxSelection([...chkboxSelectedIds])
        if (chkboxSelectedIds && chkboxSelectedIds.length >= 10) {
          setIsDisabled(true)
        } else {
          setIsDisabled(false)
        }
      }
    }
  }, [chkboxSelectedIds])

  let syncFixedColumn = () => {
    setTimeout(() => {
      var tableBodyName = 'tableBody' + moduleName

      var width = 0
      var leftAlignment = []
      if (fixedColumnCount) {
        var tdWidth = document.querySelectorAll('#' + tableBodyName + ' > table > tbody > tr')
        var thWidth = document.querySelectorAll('#' + tableBodyName + ' > table > thead > tr')
        if (tdWidth.length > 0) {
          for (let index = 0; index < fixedColumnCount; index++) {
            if (index === 0) {
              leftAlignment.push(0)
            } else {
              leftAlignment.push(width)
            }
            if (tdWidth[0].children.length > 0 && tdWidth[0].children[index]) {
              width += tdWidth[0].children[index].clientWidth
            }
          }
          widthRef.current = width
          for (let index = 0; index < tdWidth.length; index++) {
            for (let index2 = 0; index2 < leftAlignment.length; index2++) {
              if (tdWidth[index].children[index2]) {
                tdWidth[index].children[index2].style.left = leftAlignment[index2] + 'px'
              }
              if (tdWidth[index].children[index2]) {
                // this is not required everywhere, this code should run when first 3 columns to be freezed
                if (zIndexHandlingReqd) {
                  if (tdWidth[index].children[index2].children[0] && tdWidth[index].children[index2].children[0].id.includes('overlay')) {
                    tdWidth[index].children[index2].style.zIndex = !isETF ? (9998 - index) : 0
                  } else {
                    if (moduleTypeName === 'EquityScreener') {
                      tdWidth[index].children[index2].style.zIndex = 99
                    } else {
                      tdWidth[index].children[index2].style.zIndex = !isETF ? 9999 - (index2 * 100) : 0
                    }
                  }
                }
                if (index === 0) {
                  thWidth[index].children[index2].style.left = leftAlignment[index2] + 'px'
                  thWidth[index].children[index2].style.position = 'sticky'
                }
              }
            }
          }
        }
      }
    }, 200)
  }

  useEffect(() => {
    syncFixedColumn()
    handleScrolling()
    if (document.querySelector('#tableBody' + moduleName)) {
      if (document.querySelector('#tableBody' + moduleName).scrollWidth > document.querySelector('#tableBody' + moduleName).clientWidth) {
        document.getElementById('tableBody' + moduleName).addEventListener('scroll', handleScrolling)
        return () => document.querySelector('#tableBody' + moduleName) && document.getElementById('tableBody' + moduleName).removeEventListener('scroll', handleScrolling)
      }
    }
  })

  function handleScrolling (e) {
    var targetElement = document.querySelector('#tableBody' + moduleName)
    var tableColumnShadow = document.getElementById('tableColumnShadow' + moduleName)
    var tableColumnShadowRight = document.getElementById('tableColumnShadowRight' + moduleName)
    var nameCol = document.getElementById('NameCol')

    if (targetElement) {
      var scrollLeft = targetElement.scrollLeft
      var scrollWidth = targetElement.scrollWidth
      var clientWidth = targetElement.clientWidth
      // for left side box-shadow
      if (scrollLeft !== 0) {
        tableColumnShadow.style.background = 'linear-gradient(90deg, #949494 -541.2%, rgba(25, 25, 25, 0.148231) -37.87%, rgba(0, 0, 0, 0.0001) 28.43%)'
        tableColumnShadow.style.borderLeft = shadowColor
        tableColumnShadow.style.marginLeft = widthRef.current + 'px'
        tableColumnShadow.style.width = '40px'
        if (nameCol && nameCol.style) {
          nameCol.style.borderRight = 'none'
        }
      } else {
        tableColumnShadow.style.boxShadow = 'none'
        tableColumnShadow.style.borderLeft = '0px'
        tableColumnShadow.style.marginLeft = '0px'
        tableColumnShadow.style.width = '0px'
        if (nameCol && nameCol.style) {
          nameCol.style.borderRight = shadowColor
        }
      }
      // for right side box-shadow
      if ((clientWidth !== Math.floor((scrollWidth - scrollLeft))) && (clientWidth - 1 !== Math.floor((scrollWidth - scrollLeft)))) {
        tableColumnShadowRight.style.background = 'linear-gradient(270deg, #949494 -541.2%, rgba(25, 25, 25, 0.148231) -37.87%, rgba(0, 0, 0, 0.0001) 28.43%)'
        tableColumnShadowRight.style.borderRight = shadowColor
        tableColumnShadowRight.style.width = '40px'
      } else {
        tableColumnShadowRight.style.boxShadow = 'none'
        tableColumnShadowRight.style.borderRight = '0px'
        tableColumnShadowRight.style.width = '0px'
      }
    }
  }

  function handleOnChange (e, id, symbol) {
    let selection = []
    if (Array.isArray(checkboxSelection)) {
      selection = checkboxSelection
    } else {
      selection = [...checkboxSelection]
    }
    if (e.target.checked) {
      selection.push(id)
    } else {
      selection = selection.filter(x => x !== id)
      if (moduleTypeName === 'EquityScreener') {
        let storedSymbols = JSON.parse(sessionStorage.getItem('equityCompareData') || '[]')
        if (symbol && storedSymbols.includes(symbol)) {
          sessionStorage.setItem('equityCompareData', JSON.stringify(storedSymbols.filter(item => item !== symbol)))
        }
      }
    }

    if (selection.length > maxSelectionLength) {
      e.target.checked = false
    } else if (selection.length === maxSelectionLength) {
      setCheckboxSelection(new Set([...selection]))
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
      setCheckboxSelection(new Set([...selection]))
    }
    handleOnCheckboxChange([...new Set([...selection])])
  }

  function setFocus (index) {
    if (index) {
      var tableBodyName = 'tableBody' + moduleName
      var tableBody = document.getElementById(tableBodyName)
      if (!tableBody) {
        return
      }
      var tablehead = document.querySelectorAll('#' + tableBodyName + ' > table > thead > tr > th > span')[index + 1]
      tablehead.focus()
    }
  }
  function SortData (colInfo, index) {
    if (colInfo.isSortable) {
      sortColumnData(colInfo.name, colInfo['SortDirection'], true)
      let temp = cloneDeep(data)
      if (!colInfo['SortDirection']) {
        colInfo['SortDirection'] = true
      } else {
        colInfo['SortDirection'] = false
      }
      setData(temp)
      syncFixedColumn()
    }
    setFocus(index)
  }
  function getHeadCellStyle (header) {
    var styles = {}
    if (header.noWrap) {
      styles.whiteSpace = 'nowrap'
    } else {
      styles.overflow = 'hidden'
      styles.textOverflow = 'ellipsis'
      styles.display = '-webkit-box'
      styles.WebkitLineClamp = header.lines ? header.lines : '1'
      styles.WebkitBoxOrient = 'vertical'
    }
    return styles
  }
  // Handling special case when Header contains text to be shown with ellipsis and tooltip
  // this function is written as a copy of getHeadCellStyle(header),
  // since getHeadCellStyle(header) is being called from some place which is overriding tooltip functionality
  // TODO - need to refactor and handle this hack
  // textWidth - dynamic width for text to adjust inside the cell header of the table, this is required to add in json (reference: EsgCarbonMetrics.json)
  function getTableHeaderStyle (header) {
    var styles = {}
    if (header.isSymbolWithName) {
      styles.WebkitBoxOrient = 'vertical'
    } else {
      if (header.noWrap) {
        styles.whiteSpace = 'nowrap'
      } else {
        styles.textOverflow = 'ellipsis'
        styles.WebkitLineClamp = header.lines ? header.lines : '1'
        styles.WebkitBoxOrient = 'vertical'
      }
    }
    if (header.textWidth) {
      styles.maxWidth = `${header.textWidth}px`
      styles.display = `inline-flex`
      styles.whiteSpace = `nowrap`
      styles.cursor = 'pointer'
    }

    return styles
  }
  function getCellStyle (header) {
    var styles = {}
    let isFundScreenerNonMobile = screen.width >= MOBILE_WIDTH_LIMIT && moduleTypeName === 'FundScreener'
    if (header.showEllipsis && header.name === 'Name' && !isFundScreenerNonMobile) {
      styles.overflow = 'hidden'
      styles.textOverflow = 'ellipsis'
      styles.whiteSpace = 'nowrap'
      styles.maxWidth = '215px'
    } else if (header.showEllipsis) {
      styles.overflow = 'hidden'
      styles.textOverflow = 'ellipsis'
      styles.display = '-webkit-box'
      styles.WebkitLineClamp = header.lines ? header.lines : '1'
      styles.WebkitBoxOrient = 'vertical'
      if (header.name === 'Name' && isFundScreenerNonMobile) {
        styles.width = '215px'
      }
    }
    return styles
  }
  function userActionOnLink (emitEventObj) {
    if (!emitEventObj) {
      return
    }
    let gotoPage = 'funds'
    switch (emitEventObj.searchType) {
      case 'MF':
      case 'ETF':
      case 'Open-End Mutual Fund':
        gotoPage = 'funds'
        break
      case 'SMA':
      case 'MOD':
      case 'TPMOC':
      case 'MOC':
      case 'ModL':
      case 'SMAL':
        gotoPage = 'model-marketplace'
        break
      case 'AI':
      case 'AIN':
      case 'AIScreener':
      case 'AINSnapshot':
        gotoPage = 'alternative-investments'
        break
      case 'EQ':
        gotoPage = 'equities'
        break
    }
    let dataEmit = {
      gotoPage: gotoPage, // emitEventObj.gotoPage, // Option can be funds, model-marketplace, alternative-investments  ---  3rd Level Navigation tabs own by Pershing
      symbol: emitEventObj.symbol || emitEventObj.name,
      activeTabKey: PROFILE
    }
    emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, dataEmit)
  }
  let checkForEllipsis = (e, hoverText = null) => {
    var target = e.target
    var container = target
    var overflowed = ((container.scrollWidth > container.clientWidth) ||
                       (container.scrollHeight > container.clientHeight))
    if (container.nextElementSibling) { // null handling
      if (overflowed || hoverText) {
        container.nextElementSibling.className = `${styles.tooltiptextNameDisplay} ${styles.tooltiptextNameDisplayPosition}`
      } else {
        container.nextElementSibling.className = styles.tooltiptextNameHidden
      }
    }
  }
  function displayCell (headerName, cellValue, rowHeader, header, emitEventObj, fieldType) {
    if (cellValue === '--') {
      return cellValue
    }
    if (Array.isArray(negativeNumberStyleFieldsForRowHeader) && negativeNumberStyleFieldsForRowHeader.length > 0 &&
      fieldType && cellValue && negativeNumberStyleFieldsForRowHeader.includes(replaceAllChars(fieldType, ' ', ''))) {
      const textValue = (cellValue && cellValue.toString()) || '--'
      return <div className={textValue.indexOf('-') > -1 && !isNaN(textValue.replace(/[%+-]/g, '')) ? styles.fallInValue : ''}>{cellValue}</div>
    }
    if (emitEventObj || (moduleTypeName === 'FundScreener' && headerName === 'Name')) {
      return <span className={styles.nameCell} id='nameCell'><div role={'button'} tabIndex={'0'} aria-label={`navigate to ${cellValue} profile`} className={rowHeader ? styles.rowHeader : ''} style={getCellStyle(header)} onClick={() => userActionOnLink(emitEventObj)} onKeyDown={() => userActionOnLink(emitEventObj)} onFocus={(e) => checkForEllipsis(e)} onMouseOver={(e) => checkForEllipsis(e)}>{cellValue}</div><span className={styles.tooltiptextNameHidden}>{cellValue}</span></span>
    }
    if (emitEventObj || (moduleTypeName === 'EquityScreener' && headerName === 'Symbol')) {
      return <span className={styles.nameCell} id='nameCell'><div role={'button'} tabIndex={'0'} aria-label={`navigate to ${cellValue} profile`} className={rowHeader ? styles.rowHeader : ''} style={getCellStyle(header)} onClick={() => userActionOnLink(emitEventObj)} onKeyDown={() => userActionOnLink(emitEventObj)} onFocus={(e) => checkForEllipsis(e)} onMouseOver={(e) => checkForEllipsis(e)}>{cellValue}</div><span className={styles.tooltiptextNameHidden}>{cellValue}</span></span>
    }
    if (header.showColoredCircle) {
      // this value contains color with cell value separated by |
      let value = cellValue.split('|')
      let _color = value[0]
      return <div className={rowHeader ? styles.rowHeader : ''} style={getCellStyle(header)}><span style={{background: _color}} className={styles.circle} />
        {value[1].includes('*') ? <React.Fragment>{value[1].replace('*', '')}<span className={styles.showSuffixAsterisk}>*</span></React.Fragment> : value[1]}
      </div>
    }
    if (header.showValuesWithColor && cellValue) {
      return <div className={cellValue.indexOf('+') > -1 ? styles.riseInValue : styles.fallInValue}>{cellValue}</div>
    }

    // PRW-1345 handled to show red color for negative numbers only ex. see NAV % Change Last in fund screener under performance tab
    if (header.showNegativeValuesWithRedColor && cellValue) {
      return <div className={cellValue.indexOf('-') > -1 ? styles.fallInValue : ''}>{cellValue}</div>
    }

    // Added fieldType to get row header when rendering column wise PRW-550
    if (fieldType && fieldType !== cellValue) {
      headerName = fieldType
    }
    switch (headerName) {
      case 'Morningstar Rating':
      case 'Morningstar Rating (Overall)':
      case 'Morningstar Rating - Overall':
      case 'Morningstar Rating (3Yr)':
      case 'Morningstar Rating - 3 Year':
      case 'Morningstar Rating (5Yr)':
      case 'Morningstar Rating - 5 Year':
      case 'Morningstar Rating (10Yr)':
      case 'Morningstar Rating - 10 Year':
      case 'Portfolio Corporate Sustainability Rating':
      case 'Portfolio Sovereign Sustainability Rating':
        return cellValue && <StarRating starCount={cellValue} />
      case 'Morningstar Sustainability Rtg':
      case 'Morningstar Sustainability Rating':
        return cellValue && (<div>{[...Array(parseInt(cellValue))].map((x, i) =>
          <FontAwesomeIcon key={'globe' + i} icon={['fas', 'globe']} color={'#004480'} className={styles.globeRating} />
        )}</div>)
      case 'Morningstar Risk Score (Overall)':
      case 'Morningstar Risk Score - Overall':
      case 'Morningstar Performance Score (Overall)':
      case 'Morningstar Performance Score - Overall':
        return cellValue && <div className={rowHeader ? styles.rowHeader : ''} style={getCellStyle(header)}>{getRatingDescription(cellValue)}</div>
      case 'Last Price/Change':
      case 'Performance (3Yr)':
        return cellValue && <PriceChange value={cellValue} />
      case 'FundVest':
        return cellValue && <FundVestTag value={cellValue} />
      case 'Name':
        return <span className={styles.nameCell} id='nameCell'><div className={rowHeader ? styles.rowHeader : ''} style={getCellStyle(header)} onFocus={(e) => checkForEllipsis(e)} onMouseOver={(e) => checkForEllipsis(e)}>{cellValue}</div><span className={styles.tooltiptextNameHidden}>{cellValue}</span></span>
      default:
        return emitEventObj
          ? <div className={rowHeader ? styles.rowHeader : ''} style={getCellStyle(header)} tabIndex={0} role='button' onClick={() => userActionOnLink(emitEventObj)} onKeyDown={() => userActionOnLink(emitEventObj)}>{cellValue}</div>
          : <div className={rowHeader ? styles.rowHeader : ''} {...(header.showEllipsis ? {title: cellValue} : {})} style={getCellStyle(header)}>{cellValue}</div>
    }
  }
  function getRatingDescription (value) {
    if (value) {
      switch (value.toString()) {
        case '1':
          return 'Low'
        case '2':
          return 'Below Average'
        case '3':
          return 'Average'
        case '4':
          return 'Above Average'
        case '5':
          return 'High'
      }
    }
  }
  function getItemvalue (item, header, isRowHeader) {
    if (isRowHeader) {
      return item.name
    }
    if (header.value) {
      return item[header.value]
    } else {
      return item[header.name]
    }
  }
  function getDisableStatus (item) {
    let isDisabled = false
    if ((item.type === 'AI' && item.searchType === 'AI') || item.searchType === 'AIScreener') {
      if (item['Product Type'] === 'Non-Traded REIT') {
        isDisabled = false
      } else {
        isDisabled = true
      }
    }
    return isDisabled
  }
  function getErrorMsgObj (errorMsg, item) {
    let temp = errorMsg
    if (errorMsg) {
      if (getDisableStatus(item)) {
        if (item.searchType === 'ModL' || item.searchType === 'SMAL') {
          temp = {msg: ''}
        } else if (isDisabled && ![...checkboxSelection].includes(item.xid)) {
          temp = {msg: errorMsg.maxSelectionMsg, width: errorMsg.maxSelectionMsgWidth}
        } else {
          temp = errorMsg
        }
      } else if (isDisabled && ![...checkboxSelection].includes(item.xid)) {
        temp = { msg: errorMsg.maxSelectionMsg ? errorMsg.maxSelectionMsg : errorMsg.msg, width: errorMsg.maxSelectionMsgWidth ? errorMsg.maxSelectionMsgWidth : errorMsg.width }
      }
    }
    return temp
  }
  function renderCell (index, item, header, isRowHeader, rowHeaderVal) {
    // PRW-1968 special handling for AI to put ticker for binding symbols
    const raiseEvent = (header.textType === 'link' && (AI_SEARCH_TYPE_LIST.indexOf(item.searchType) > -1))
    let emitEventObj
    if (moduleTypeName === 'EquityScreener') {
      emitEventObj = raiseEvent ? { Program: item.program, Product: item.product, strategy: (item['Product Type'] || item.strategy), ModelClientId: item.modelClientId, MorningstarID: item.MorningstarID, searchType: item.searchType, venueXid: item.xid, CUSIP: item.CUSIP, name: item.Name, symbol: item.Symbol } : null
    } else {
      emitEventObj = raiseEvent ? { Program: item.program, Product: item.product, strategy: (item['Product Type'] || item.strategy), ModelClientId: item.modelClientId, MorningstarID: item.MorningstarID, searchType: item.searchType, venueXid: item.xid, CUSIP: item.CUSIP, name: item.Name, symbol: (AISearchType.indexOf(item.searchType) > -1 ? item.CUSIP : item.Ticker) } : null
    }
    let div = <div>
      {isETF ? displayCell(header.name, getItemvalue(item, header, isRowHeader), item['rowHeader'], header, emitEventObj)
        : displayCell(header.name, getItemvalue(item, header, isRowHeader), item['rowHeader'], header, emitEventObj, rowHeaderVal)
      }    </div>
    if (rowHeaderVal === 'hid') {
      div = <div className={(header.showEllipsis) ? `${styles.truncate}` : ''} >
        {displayCell(header.name, getItemvalue(item, header, isRowHeader), item['rowHeader'], header, emitEventObj)

        }
      </div>
      if (header.fixedWidth) {
        div = <div style={{ width: header.fixedWidth + 'px' }} className={(header.showEllipsis) ? `${styles.truncate}` : ''} >

          {displayCell(header.name, getItemvalue(item, header, isRowHeader), item['rowHeader'], header, emitEventObj)
          }
        </div>
      }
    } else {
      let _style = {}
      if (header.fixed || header.isFundCompare || header.isModelCompare || ((header.isSymbolWithName || header.showColoredCircle) && header.fixedWidth) || header.fixedSmallDeviceWidth) {
        if (header.fixedSmallDeviceWidth && deviceType !== deviceTypeConstant.Desktop) {
          /* set fixedSmallDeviceWidth = 130px in json files for all tables
          and substract parentElementPadding i.e. 15px from it to make overall column width = 130px */
          let parentElementPadding = 15
          _style['width'] = (header.fixedSmallDeviceWidth - parentElementPadding) + 'px'
        } else {
          _style['width'] = header.fixedWidth + 'px'
        }
      }
      if (header.textAlign === 'right' && !isETF && moduleName !== 'ModelsSnapshotAssetAllocation') {
        _style['float'] = 'right'
        _style['marginRight'] = '10px'
      }
      if (header.textType === 'link' && ((item.CUSIP && item.CUSIP === '--') || (item.Ticker && item.Ticker === '--'))) {
        _style['cursor'] = 'default'
        _style['color'] = '#000000'
      }
      div = <div style={_style} className={item.searchType === 'AI' ? styles.ai : undefined}>
        {isETF ? displayCell(header.name, getItemvalue(item, header, isRowHeader), item['rowHeader'], header, emitEventObj)
          : displayCell(header.name, getItemvalue(item, header, isRowHeader), item['rowHeader'], header, emitEventObj, rowHeaderVal)

        }</div>
    }
    return div
  }
  let getClassNameBySearchType = (item, header) => {
    return `${styles[header.textAlign]} ${styles[header.textType]}`
  }
  let getStylesForHeader = () => {
    if (moduleTypeName === 'Alternative') {
      return styles.fixedHeaderAI
    } else {
      return styles.fixedHeader
    }
  }
  let getClassNameByFixColumnCount = (colIndex) => {
    return colIndex < fixedColumnCount ? getStylesForHeader() : ''
  }

  const addToWatchlistHandler = (item) => {
    setAddToWatchlistData(item)
  }

  function renderTd (index, item, header, isRowHeader, colIndex, rowHeaderVal) {
    if (!startIndex) {
      startIndex = 0
    }
    index += startIndex
    let td = ''
    if (header.name !== '' || header.control === '') {
      td = (
        <td
          role='gridcell'
          key={header.name}
          style={getStaticColumnWidthAndLeftPos(header)}
          className={`${getClassNameBySearchType(
            item,
            header
          )} ${getClassNameByFixColumnCount(colIndex)}`}
        >
          {renderCell(index, item, header, isRowHeader, rowHeaderVal)}
        </td>
      )
    } else {
      switch (header.control) {
        case 'chk':
          td = (
            <td
              key={'chkbox-' + index}
              role='gridcell'
              style={getStaticColumnWidthAndLeftPos(header)}
              className={`${
                styles['rightborder' + header.control]
              } ${getClassNameByFixColumnCount(colIndex)}`}
            >
              <div className={styles.chkPosition}>
                <CheckBox
                  xid={item.xid}
                  id={`${moduleName}-checkbox-${index}`}
                  moduleName={moduleName}
                  itemIndex={index}
                  type={'vis'}
                  index={0}
                  isChecked={chkboxSelectedIds.includes(item.xid || item.CUSIP)}
                  value={'chkbox'}
                  onCheck={(e) => handleOnChange(e, item.xid || item.CUSIP, item.Symbol)}
                  label='compare model'
                  isDisabled={
                    (isDisabled &&
                      ![...checkboxSelection].includes(item.xid || item.CUSIP)) ||
                    getDisableStatus(item)
                  }
                  errorMsg={getErrorMsgObj(errorMsg, item)}
                  moduleTypeName={moduleTypeName}
                />
              </div>
            </td>
          )
          break
        case 'overlay':
          td = (
            <td
              key={'overlay-' + index}
              role='gridcell'
              style={getStaticColumnWidthAndLeftPos(header)}
              className={`${
                collapsed ? styles.hiddencolumns : ''
              } ${getClassNameByFixColumnCount(colIndex)}`}
            >
              <ToolTip
                id={`${'overlay' + item.xid}`}
                IsFVETF={item.IsFundVestETF}
                data={{ ...item }}
                type={item.searchType}
                moduleTypeName={moduleTypeName}
                icon={'downCircle'}
                className={styles.centeralign}
                openRight
                addToWatchlistHandler={() => addToWatchlistHandler(item)}
              />
            </td>
          )
          break
        case 'button':
          td = (
            <td
              key={'btn-' + index}
              role='gridcell'
              style={getStaticColumnWidthAndLeftPos(header)}
              className={`${
                collapsed ? styles.hiddencolumns : ''
              } ${getClassNameByFixColumnCount(colIndex)}`}
              aria-label={'Type: ' + toolTipText(item.searchType, 'text')}
            >
              <div
                id={`productIcon${item.searchType}${index}`}
                className={`${styles[`productIcon` + item.searchType]}`}
              >
                <div className={styles.productIconText}>
                  {replaceLock(item.searchType, item.type)}
                  {isLocked(item.searchType) ? (
                    <div className={styles.lockIcon}>
                      <FontAwesomeIcon icon={['fas', 'lock']} />
                    </div>
                  ) : (
                    ''
                  )}
                  {toolTipText(item.searchType, 'div')}
                </div>
              </div>
            </td>
          )
          break
        case 'close-button' :
          td = (
            <td
              key={'closebtn-' + index}
              role='gridcell'
              style={getStaticColumnWidthAndLeftPos(header)}
              className={`${
                collapsed ? styles.hiddencolumns : ''
              } ${getClassNameByFixColumnCount(colIndex)}`}
            >
              <button
                className={`${styles.btnPopUpClose} ${
                  item.searchType === 'AI' ? styles.btnPopUpCloseAI : undefined
                }`}
                alt='Close'
                aria-label='remove-row'
                id='closeButton'
                tabIndex='0'
                onClick={onClose}
              >
                <FontAwesomeIcon
                  icon={['fal', 'times']}
                  className={styles.btnPopUpClose}
                  id='closeButtonFontAwsome'
                />
              </button>
            </td>
          )
          break
      }
    }
    return td
  }

  function getStaticColumnWidthAndLeftPos (header, isHeaderRow) {
    let _style = {}

    if (header.color !== '' && isHeaderRow) {
      _style['color'] = header.color
    }
    if (header.paddingLeft) {
      _style['paddingLeft'] = header.paddingLeft + 'px'
    }
    if (header.textAlign) {
      _style['textAlign'] = header.textAlign
    }
    if (header.fixedWidth && (header.isSymbolWithName || header.showColoredCircle)) {
      _style['width'] = header.fixedWidth
    }
    // using for ai when space with symbol link
    if (header.symbolWithNameHeaderWithSpace) {
      _style['minWidth'] = header.fixedWidth + 'px'
    }

    return _style
  }

  function getHeaderCssClass (header) {
    let th = ''
    if (header.staticColWidth > 0) {
      if (!header.isToggledInMobile) {
        th = `${styles.header} ${styles.textAlign} ${styles['rightborder' + header.control]}`
      } else if (header.isToggledInMobile) {
        if (collapsed) {
          th = `${styles.header} ${styles.hiddencolumns} ${styles.textAlign} ${styles['rightborder' + header.control]}`
        } else if (!collapsed) {
          th = `${styles.header} ${styles.textAlign} ${styles['rightborder' + header.control]}`
        }
      }
    } else {
      th = `${styles.header} ${styles.textAlign}`
    }
    return th
  }
  function getSortHeaderStatus (header) {
    let msg = 'none'
    if (header.SortDirection) {
      msg = 'ascending'
    } else if (header.SortDirection === false) {
      msg = 'descending'
    }
    return msg
  }
  function handleKeyEvents (e, header) {
    if (e.key === 'Enter') {
      SortData(header)
    }
  }
  const [collapsed, setCollapsed] = useState(true)
  function toggleHiddenCol () {
    setCollapsed(!collapsed)
    syncFixedColumn()
    // PRW-1297 - handled mobile device issue when user expand the ticker column then vertical line does not set as per width of the column
    setTimeout(() => {
      handleScrolling()
    }, 200)
  }
  function onClose (e) {
    e.currentTarget.focus()
    removeComapreRow(e)
  }
  let getMainHeader = () => {
    // This is used for trailing return chart on model profile only
    return <thead>
      <tr key='header' className={styles.header} role='row'>
        {mainHeaders && mainHeaders.map((header, index) => {
          if (!header.hideInMobile) {
            return <th colSpan={header.colSpan} key={'key_header_' + index + header.name} aria-label={(header.name === '' ? `${'No column header provided'}` : `${header.name}`)} scope={'colgroup'} style={getStaticColumnWidthAndLeftPos(header, true)}
              className={`${getHeaderCssClass(header)} ${getClassNameByFixColumnCount(index)}`}>
              <span className={header.separator ? styles.separatorHeader : styles.separatorSpan} style={getHeadCellStyle(header)}>
                {
                  <div className={header.separator ? `${styles.setWidth} ${styles.separator}` : `${styles.setWidth} ${styles.noSeparator}`} style={getHeadCellStyle(header)}>{header.name}</div>
                }
              </span>
            </th>
          }
        })}
      </tr>
    </thead >
  }

  function getHeaderText (header) {
    let headerNode = ''
    if (header.name.includes('**')) {
      headerNode = <React.Fragment>{header.name.replace('**', '')}<sup>**</sup></React.Fragment>
    } else if (header.name.includes('*')) {
      headerNode = <React.Fragment>{header.name.replace('*', '')}<sup>*</sup></React.Fragment>
    } else {
      headerNode = header.name
    }
    return headerNode
  }

  function setHeaderCssClass (header) {
    let cssClass = `${styles.setWidth} ${styles.separator}`
    if (!header.separator) {
      cssClass = `${styles.setWidth} ${styles.noSeparator}`
    }
    if (header.symbolWithNameHeaderWithSpace && header.isSymbolWithName) {
      cssClass += ` ${styles.symbolWithNameHeaderWithSpace}`
    } else if (header.isSymbolWithName) {
      cssClass += ` ${styles.symbolWithNameHeader}`
    }
    return cssClass
  }
  const getSymbolStyle = (header) => {
    if (header.symbolLinkWithSpace) {
      return styles.symbolLinkforaitable
    } else {
      return styles.symbolLink
    }
  }
  function getHeaderHtml (header) {
    let headerHtml = ''
    let mobileRelatedHtml = ''
    let headerHtmlETF = ''
    // Handling of collapsing/expand icon to show in mobile
    if (header.showCollapseIconInMobile) {
      mobileRelatedHtml = <span role='button' tabIndex='-1' aria-hidden='true' onClick={toggleHiddenCol} className={styles.plusIcon}>
        <span className={styles.collapseExpandIcon}>
          {collapsed ? <PlusIcon /> : <MinusIcon />}
        </span>
      </span>
    }
    // Handling of sorting icon
    let sortIconHtml = ''
    if (!(header.SortDirection === null || header.SortDirection === undefined)) {
      let sortingDirection = header.SortDirection ? 'ascending' : 'descending'
      let fontAwesomeIcon = header.SortDirection ? 'long-arrow-down' : 'long-arrow-up'
      sortIconHtml = <div role={'button'} aria-label={`'Sorting ${header.name} in ${sortingDirection} order'`} className={styles.sortingIcon}><FontAwesomeIcon icon={['fas', fontAwesomeIcon]} /></div>
    }
    let symbol = header.isSymbolWithName ? <span className={getSymbolStyle(header)} role={'button'} tabIndex={header.symbol && header.symbol.length > 0 ? '0' : '-1'} onClick={() => userActionOnLink(header)} onKeyDown={() => userActionOnLink(header)} >{header.isModelCompare ? '' : header.symbol} </span> : ''
    // Handling to show tooltip
    // hoverText: To show different on hover
    let headerTextHtml = <React.Fragment>
      <div className={setHeaderCssClass(header)}
        style={getTableHeaderStyle(header)}
        onFocus={(e) => checkForEllipsis(e, header.hoverText)}
        onMouseOver={(e) => checkForEllipsis(e, header.hoverText)}>
        { getHeaderText(header)}
      </div>
      <span className={styles.tooltiptextNameHidden}>{header.hoverText ? header.hoverText : header.name }</span>
    </React.Fragment>

    headerHtml = <span role={'button'} tabIndex={header.name !== '' ? '0' : '-1'}
      aria-label={header.name === '' ? 'Blank header' : ''}
      onKeyDown={(e) => handleKeyEvents(e, header)}
      onClick={() => SortData(header)}
      className={header.separator ? `${styles.headerCell} ${styles.separatorHeader}` : `${styles.headerCell} ${styles.separatorSpan}`}
      id={header.name === 'Name' ? 'NameCol' : ''}
      style={getHeadCellStyle(header)}>
      {/* Below condition is to show Sorting icon on left or right depending whether header text to be shown on right or left */}
      { (header.textAlign === 'right')
        ? <React.Fragment>{sortIconHtml} {headerTextHtml}</React.Fragment>
        : <React.Fragment>{headerTextHtml} {sortIconHtml}</React.Fragment>}
    </span>

    // Disable blue color focus on header cells for ETF Research Snapshot -> Asset Allocation
    headerHtmlETF = <span aria-hidden='true'
      aria-label={header.name === '' ? 'Blank header' : ''}
      onKeyDown={(e) => handleKeyEvents(e, header)}
      onClick={() => SortData(header)}
      className={header.separator ? `${styles.headerCell} ${styles.separatorHeader}` : `${styles.headerCell} ${styles.separatorSpan}`}
      id={header.name === 'Name' ? 'NameCol' : ''}
      style={getHeadCellStyle(header)}>
      {/* Below condition is to show Sorting icon on left or right depending whether header text to be shown on right or left */}
      { (header.textAlign === 'right')
        ? <React.Fragment>{sortIconHtml} {headerTextHtml}</React.Fragment>
        : <React.Fragment>{headerTextHtml} {sortIconHtml}</React.Fragment>}
    </span>

    let getHeaderHTML = (moduleName === !ModuleName.ETF_RESEARCH_SNAPSHOT_ASSET_ALLOCATION) ? headerHtml : headerHtmlETF

    if (header.searchType === 'AI') {
      return <React.Fragment>{mobileRelatedHtml}{ getHeaderHTML}</React.Fragment>
    } else {
      return <React.Fragment>{mobileRelatedHtml}{symbol}{ getHeaderHTML}</React.Fragment>
    }
  }
  let getHeader = () => {
    return <thead>
      <tr key='header' className={styles.header} role='row'>
        {headers.map((header, index) => {
          if (!header.hideInMobile) {
            return <th
              data-sortable={header.isSortable}
              key={'key_header_' + index + header.name}
              aria-sort={getSortHeaderStatus(header)}
              aria-label={
                header.isSortable
                  ? `${'Order '} ${
                    header.name
                  }, sortable column, currently  ${getSortHeaderStatus(header)} sorting`
                  : header.name === ''
                    ? `${'No column header provided'}`
                    : `${header.name}`
              }
              scope={'col'}
              style={getStaticColumnWidthAndLeftPos(header, true)}
              className={`${getHeaderCssClass(header)} ${getClassNameByFixColumnCount(
                index
              )}`}
            >
              {getHeaderHtml(header)}
            </th>
          }
        })}
      </tr>
    </thead >
  }
  let getBody = () => {
    return <tbody>
      {dataRef.current && dataRef.current.map((item, index) => {
        return !item.isGroupedCol ? <tr key={'key_body_' + index} className={isETF ? styles.bodyWithoutMargin : styles.body}role='row'>
          { isETF && !isOnlyChart &&
          <td aria-hidden='true'><span style={{background: item.color}} className={styles.circle}> </span>
          </td> }
          {
            headers.map((header, colIndex) => {
              if (header.value !== 'Color') {
                if (!header.hideInMobile) {
                  if (colIndex === 0 && rowHeaders && rowHeaders.length > 0) {
                    return renderTd(index, rowHeaders[index], header, true, colIndex)
                  } else {
                    return renderTd(index, item, header, false, colIndex, item[''])
                  }
                }
              }
            })
          }
        </tr>
          : <React.Fragment>
            <tr key={'key_body' + index} className={`${styles.body} ${styles.groupedHeaderRow}`} role='row' onClick={() => handleExpandCollapse(item, index)}>
              <td role='gridcell' className={styles.fixedHeader}>
                {item[''] &&
                  <React.Fragment>
                    <span style={{display: 'none'}} className={`${replaceAllChars(item[''], ' ', '') + 'Expand'} ${styles.expandCollapseBtn}`}>
                      <FontAwesomeIcon icon={['far', 'chevron-down']} />
                    </span>
                    <span style={{display: ''}} className={`${replaceAllChars(item[''], ' ', '') + 'Collapse'} ${styles.expandCollapseBtn}`}>
                      <FontAwesomeIcon icon={['far', 'chevron-right']} />
                    </span>
                  </React.Fragment>
                }
                {item['']}
              </td>
            </tr>
            {
              item.data.map((childItem, childIndex) => {
                return <tr id={replaceAllChars(item[''], ' ', '')} style={{display: 'none'}} className={styles.body} role='row'>
                  {
                    headers.map((header, colIndex) => {
                      return renderChildTableData(childItem, childIndex, header, false, colIndex, childItem[''])
                    })
                  }
                </tr>
              })
            }
          </React.Fragment>
      })}
    </tbody>
  }
  function handleExpandCollapse (item, index) {
    let name = replaceAllChars(item[''], ' ', '')
    let isExpanded = false
    if (document.querySelectorAll("[id^='" + name + "']")[0].style.display === '') {
      isExpanded = true
    }
    let hideRows = isExpanded ? 'none' : ''
    if (isExpanded) {
      document.getElementsByClassName(name + 'Expand')[0].style.display = 'none'
      document.getElementsByClassName(name + 'Collapse')[0].style.display = ''
    } else {
      document.getElementsByClassName(name + 'Expand')[0].style.display = ''
      document.getElementsByClassName(name + 'Collapse')[0].style.display = 'none'
    }
    for (let i = 0; i < document.querySelectorAll("[id^='" + name + "']").length; i++) {
      document.querySelectorAll("[id^='" + name + "']")[i].style.display = hideRows
    }
  }
  function renderChildTableData (childItem, childIndex, header, isRowHeader, colIndex, rowHeaderVal) {
    if (!startIndex) {
      startIndex = 0
    }
    childIndex += startIndex
    let td = ''
    td = <td
      role='gridcell'
      key={header.name}
      style={getStaticColumnWidthAndLeftPos(header)}
      className={`${getClassNameBySearchType(
        childItem,
        header
      )} ${getClassNameByFixColumnCount(colIndex)} ${styles.adjustGroupedRow}`}
    >
      {renderCell(childIndex, childItem, header, isRowHeader, rowHeaderVal)}
    </td>

    return td
  }
  let getShadowRightStyle = () => {
    if (isETF || moduleTypeName === 'Alternative') return ''
    else {
      return styles.genericTableColumnShadowRight
    }
  }

  return (
    <div className={styles.genericTableContainer} id='table'>
      {/* header and body */}
      <div className={isETF ? styles.genericTableETF : styles.genericTable} id={'tableBody' + moduleName} aria-live='polite' aria-atomic='true'>
        <table className={styles.tableStyle}>
          {mainHeaders && mainHeaders.length > 0 && getMainHeader()}
          {getHeader()}
          {getBody()}
        </table>
      </div>
      <div className={styles.genericTableColumnShadow} id={'tableColumnShadow' + moduleName} aria-hidden='true' />
      <div className={getShadowRightStyle()} id={'tableColumnShadowRight' + moduleName} aria-hidden='true' />

      {/* Moving this add to watchlist component here to fix the z-Index issue on table cell */}
      {
        addToWatchlistData &&
        <AddToWatchlists symbol={addToWatchlistData.Symbol || addToWatchlistData.Ticker} xid={addToWatchlistData.xid} onClose={() => { setAddToWatchlistData(null) }} />
      }
    </div>
  )
}

Table.propTypes = {
  mainHeaders: PropTypes.array,
  headers: PropTypes.array.isRequired,
  _data: PropTypes.array.isRequired,
  maxSelectionLength: PropTypes.number,
  fixedColumnCount: PropTypes.number,
  rowHeaders: PropTypes.array,
  moduleTypeName: PropTypes.string,
  moduleName: PropTypes.string,
  errorMsg: PropTypes.object,
  chkboxSelectedIds: PropTypes.array,
  startIndex: PropTypes.number,
  handleOnCheckboxChange: PropTypes.func,
  removeComapreRow: PropTypes.func,
  syncFixedColumns: PropTypes.object,
  sortColumnData: PropTypes.func,
  isETF: PropTypes.bool,
  isOnlyChart: PropTypes.bool,
  deviceType: PropTypes.string,
  zIndexHandlingReqd: PropTypes.bool,
  negativeNumberStyleFieldsForRowHeader: PropTypes.array
}
