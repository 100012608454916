export const showDebugInfoTransform = (data) => {
  const origData = data

  try {
    // We do not want to reset the debug url for web
    data = data.replace(/Debug.url[^;]+;/, '')
    // eslint-disable-next-line
          eval(`const o=${data};data=o;`);
  } catch (e) {
    e.message = `Error writing to debug: ${e.message}`
    console.error(e.message)
    throw e
  }

  // Fallback if response did not serialize correctly; with a little luck this code never gets hit.
  if (!data || !Object.keys(data).length) {
    // First line of string contains JSON
    const firstLine = origData.split('\n')[0]
    let parsedData

    try {
      parsedData = JSON.parse(firstLine)
    } catch (e) {
      console.error(e)
      throw new Error('Unable to convert response to JSON object')
    }

    data = parsedData
  }

  return data
}
